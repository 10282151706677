<ng-container *ngIf="visible || (customizeService.enabled$ | async)">
  <h1 class="back">
    <ng-container *ngIf="backEnabled">
      <a href="javascript:void(0)" class="back__link" (click)="back()">
        <span class="back__arrow icon-arrow_backward_3"></span> {{ previousPageTitle }}
      </a>
      –
    </ng-container>
    <ng-container>{{ title }}</ng-container>
  </h1>
</ng-container>
