import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { AfterViewInit, ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, timer } from 'rxjs';
import { debounce, debounceTime } from 'rxjs/operators';
import { UniversalAnalyticsService } from '@modules/analytics';
import { CustomViewsStore } from '@modules/custom-views';
import { TintStyle, ViewContext, ViewContextElement } from '@modules/customize';
import { ListModelDescriptionDataSource } from '@modules/data-sources';
import { CurrentProjectStore } from '@modules/projects';
export var dropdownPositions = [
    {
        panelClass: ['overlay_position_bottom-right'],
        originX: 'end',
        overlayX: 'end',
        originY: 'bottom',
        overlayY: 'top',
        offsetX: 8
    },
    {
        panelClass: ['overlay_position_bottom-left'],
        originX: 'start',
        overlayX: 'start',
        originY: 'bottom',
        overlayY: 'top',
        offsetX: -8
    },
    {
        panelClass: ['overlay_position_top-right'],
        originX: 'end',
        overlayX: 'end',
        originY: 'top',
        overlayY: 'bottom',
        offsetX: 8
    },
    {
        panelClass: ['overlay_position_top-left'],
        originX: 'start',
        overlayX: 'start',
        originY: 'top',
        overlayY: 'bottom',
        offsetX: -8
    },
    {
        panelClass: ['overlay_position_left-center'],
        originX: 'start',
        overlayX: 'end',
        originY: 'center',
        overlayY: 'center'
    },
    {
        panelClass: ['overlay_position_right-center'],
        originX: 'end',
        overlayX: 'start',
        originY: 'center',
        overlayY: 'center'
    }
];
var ListActionsComponent = /** @class */ (function () {
    function ListActionsComponent(analyticsService, cd, currentProjectStore, customViewsStore) {
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.currentProjectStore = currentProjectStore;
        this.customViewsStore = customViewsStore;
        this.filtersEnabled = false;
        this.filters = [];
        this.filterFields = [];
        this.layouts = false;
        this.searchEnabled = false;
        this.searchLive = false;
        this.inputs = [];
        this.parameters = [];
        this.focus = false;
        this.actions = [];
        this.compactActions = false;
        this.layoutAdd = new EventEmitter();
        this.layoutCustomize = new EventEmitter();
        this.paramsChanged = new EventEmitter();
        this.filtersUpdated = new EventEmitter();
        this.searchChanged = new EventEmitter();
        this.pinnedActions = [];
        this.otherActions = [];
        this.otherActionsHover$ = new BehaviorSubject(false);
        this.otherActionsDropdownHover$ = new BehaviorSubject(false);
        this.otherActionsDropdownOpened = false;
        this.otherActionsDropdownPositions = dropdownPositions;
        this.tintStyles = TintStyle;
    }
    ListActionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(this.otherActionsHover$.pipe(debounce(function (origin) { return timer(origin ? 0 : 100); })), this.otherActionsDropdownHover$)
            .pipe(debounceTime(10), untilDestroyed(this))
            .subscribe(function (_a) {
            var hover = _a[0], dropdownHovered = _a[1];
            if (hover && !_this.otherActionsDropdownOpened) {
                _this.openOtherActionsDropdown();
            }
            else if (!hover && !dropdownHovered && _this.otherActionsDropdownOpened) {
                _this.closeOtherActionsDropdown();
            }
        });
    };
    ListActionsComponent.prototype.ngOnDestroy = function () { };
    ListActionsComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.filterFields) {
            this.optionsFilter =
                this.filterFields && this.filterFields.length
                    ? function (option) { return !!_this.filterFields.find(function (item) { return item.name == option.name; }); }
                    : undefined;
        }
        if (changes.actions) {
            this.updateActions();
        }
    };
    ListActionsComponent.prototype.ngAfterViewInit = function () {
        this.setPositionObserver();
    };
    ListActionsComponent.prototype.updateActions = function () {
        this.pinnedActions = this.actions.filter(function (item) { return item.pinned; });
        this.otherActions = this.actions.filter(function (item) { return !item.pinned; });
        this.cd.markForCheck();
        this.setPositionObserver();
    };
    ListActionsComponent.prototype.openOtherActionsDropdown = function () {
        this.otherActionsDropdownOpened = true;
        this.cd.markForCheck();
    };
    ListActionsComponent.prototype.closeOtherActionsDropdown = function () {
        this.otherActionsDropdownOpened = false;
        this.cd.markForCheck();
        this.otherActionsDropdownHover$.next(false);
    };
    ListActionsComponent.prototype.setPositionObserver = function () {
        var _this = this;
        if (this.otherActionsDropdownSubscription) {
            this.otherActionsDropdownSubscription.unsubscribe();
        }
        if (!this.cdkConnectedOverlay) {
            return;
        }
        this.otherActionsDropdownSubscription = this.cdkConnectedOverlay.positionChange
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            var propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
            var position = _this.otherActionsDropdownPositions.find(function (item) {
                return propsEqual.every(function (prop) { return (item[prop] || undefined) == e.connectionPair[prop]; });
            });
            var otherPosition = _this.otherActionsDropdownPositions.filter(function (item) { return item !== position; });
            if (position) {
                _this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
            }
            otherPosition.forEach(function (item) { return _this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass); });
        });
    };
    return ListActionsComponent;
}());
export { ListActionsComponent };
