<div *ngIf="visible" class="sidebar__submit-stub"></div>
<div *ngIf="visible" class="sidebar__submit sidebar-submit">
  <div class="field">
    <div *ngIf="errors?.length" class="field__errors sidebar-submit__errors">
      <div *ngFor="let error of errors" class="field__error">
        {{ error }}
      </div>
    </div>
  </div>
  <div class="sidebar-submit__buttons">
    <button
      *ngIf="cancelEnabled"
      class="button button_orange-alternative sidebar-submit__buttons-item"
      type="button"
      [disabled]="saveLoading"
      (click)="cancelClick.emit()"
    >
      <span class="button__label">{{ cancelLabel }}</span>
    </button>
    <button
      *ngIf="backEnabled"
      class="button button_primary button_orange sidebar-submit__buttons-item"
      type="submit"
      [disabled]="saveLoading"
    >
      <span class="button__label">{{ backLabel }}</span>
    </button>
    <button
      *ngIf="deleteEnabled"
      class="button button_danger sidebar-submit__buttons-item"
      type="button"
      [disabled]="saveLoading"
      (click)="deleteClick.emit()"
    >
      <span class="button__label">Delete</span>
    </button>
    <button
      *ngIf="saveEnabled"
      class="button button_primary button_orange sidebar-submit__buttons-item"
      type="submit"
      [disabled]="saveDisabled || saveLoading"
    >
      <app-loader-small *ngIf="saveLoading" class="button__icon button__icon_left"></app-loader-small>
      <span class="button__label">{{ saveLabel }}</span>
    </button>
  </div>
</div>
