import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgGxScrollableModule } from 'ng-gxscrollable';

import { DragDropModule } from '@common/drag-drop';
import { LocalizeModule } from '@common/localize';
import { TipsModule } from '@common/tips';
import { ActionsComponentsModule } from '@modules/actions-components';
import { ColorsSharedModule } from '@modules/colors-shared';
import { FieldComponentsModule } from '@modules/field-components';
import { FieldsModule } from '@modules/fields';
import { FiltersComponentsModule } from '@modules/filters-components';
import { FiltersEditModule } from '@modules/filters-edit';
import { RoutingModule } from '@modules/routing';
import { ThemeComponentsModule } from '@modules/theme-components';
import { ViewsComponentsModule } from '@modules/views-components';
import { SharedModule } from '@shared';

import { DotPaginationComponent } from './components/dot-pagination/dot-pagination.component';
import { ListActionsItemComponent } from './components/list-actions/list-actions-item/list-actions-item.component';
import { ListActionsComponent } from './components/list-actions/list-actions.component';
import { ListFooterComponent } from './components/list-footer/list-footer.component';
import { ListHeaderComponent } from './components/list-header/list-header.component';
import { ListLayoutsComponent } from './components/list-layouts/list-layouts.component';
import { ListRelatedFieldComponent } from './components/list-related-field/list-related-field.component';
import { ListSearchComponent } from './components/list-search/list-search.component';
import { ListStoreFooterComponent } from './components/list-store-footer/list-store-footer.component';
import { ListStubFooterComponent } from './components/list-stub-footer/list-stub-footer.component';
import { ModelCardActionComponent } from './components/model-card-action/model-card-action.component';
import { ModelCardItemActionComponent } from './components/model-card-item-action/model-card-item-action.component';
import { ModelCardItemComponent } from './components/model-card/model-card-item/model-card-item.component';
import { ModelCardComponent } from './components/model-card/model-card.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { RefreshDataComponent } from './components/refresh-data/refresh-data.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgGxScrollableModule,
    SharedModule,
    TipsModule,
    DragDropModule,
    FieldsModule,
    FieldComponentsModule,
    FiltersEditModule,
    RoutingModule,
    ActionsComponentsModule,
    LocalizeModule,
    FiltersComponentsModule,
    OverlayModule,
    ThemeComponentsModule,
    ViewsComponentsModule,
    ColorsSharedModule
  ],
  declarations: [
    ListLayoutsComponent,
    ListSearchComponent,
    ModelCardComponent,
    ListActionsComponent,
    ListRelatedFieldComponent,
    RefreshDataComponent,
    ListHeaderComponent,
    ListFooterComponent,
    ListStoreFooterComponent,
    ListStubFooterComponent,
    PaginationComponent,
    ModelCardItemComponent,
    ModelCardActionComponent,
    ModelCardItemActionComponent,
    ListActionsItemComponent,
    DotPaginationComponent
  ],
  exports: [
    ListLayoutsComponent,
    ListSearchComponent,
    ModelCardComponent,
    ModelCardActionComponent,
    ModelCardItemActionComponent,
    ListActionsComponent,
    RefreshDataComponent,
    ListHeaderComponent,
    ListFooterComponent,
    ListStoreFooterComponent,
    ListStubFooterComponent,
    PaginationComponent,
    DotPaginationComponent
  ],
  entryComponents: [ListRelatedFieldComponent]
})
export class ListComponentsModule {}
