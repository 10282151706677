import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { ActionControllerService } from '@modules/action-queries';
import { ApiType } from '@modules/api';
import { CustomizeService, ElementType, ImageElementItem, registerElementComponent } from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { applyParamInput$, ImageOutputFormat, Input as FieldInput } from '@modules/fields';
import { CurrentEnvironmentStore } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { isSet } from '@shared';

interface ElementState {
  valueInput?: FieldInput;
  storageResource?: string;
}

function getElementStateValue(state: ElementState): Object {
  return {
    valueInput: state.valueInput ? state.valueInput.serialize() : undefined,
    storageResource: state.storageResource
  };
}

@Component({
  selector: 'app-image-element',
  templateUrl: './image-element.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageElementComponent extends BaseElementComponent<ImageElementItem>
  implements OnInit, OnDestroy, OnChanges {
  state: ElementState = {};

  value: string;
  preview: string;
  valueSubscription: Subscription;

  customizeEnabled$: Observable<boolean>;

  constructor(
    private customizeService: CustomizeService,
    private cd: ChangeDetectorRef,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private resourceControllerService: ResourceControllerService,
    private actionControllerService: ActionControllerService,
    private notificationService: NotificationService,
    private injector: Injector
  ) {
    super();
  }

  ngOnInit() {
    this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(item => !!item));

    this.elementOnChange(this.element);
    this.trackChanges();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['element']) {
      this.elementOnChange(this.element);
    }
  }

  trackChanges() {
    this.element$
      .pipe(
        map(element => this.getElementState(element)),
        untilDestroyed(this)
      )
      .subscribe(state => {
        this.onStateUpdated(state);
        this.state = state;
      });
  }

  getElementState(element: ImageElementItem): ElementState {
    return {
      valueInput: element.valueInput,
      storageResource: element.storageResource
    };
  }

  onStateUpdated(state: ElementState) {
    if (!isEqual(getElementStateValue(state), getElementStateValue(this.state))) {
      this.updateValue(state);
    }
  }

  updateValue(state: ElementState) {
    if (this.valueSubscription) {
      this.valueSubscription.unsubscribe();
      this.valueSubscription = undefined;
    }

    let value$: Observable<any>;

    if (state.valueInput) {
      value$ = applyParamInput$<any>(state.valueInput, {
        context: this.context,
        handleLoading: true
      }).pipe(distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)));
    } else {
      value$ = of(undefined);
    }

    this.valueSubscription = value$.pipe(untilDestroyed(this)).subscribe(value => {
      this.value = value;
      this.preview = this.getPreview(state, value);
      this.cd.markForCheck();
    });
  }

  getPreview(state: ElementState, value: string): string {
    if (!isSet(value)) {
      return;
    }

    if (this.element.outputFormat == ImageOutputFormat.Storage) {
      const resource = state.storageResource
        ? this.currentEnvironmentStore.resources.find(item => item.uniqueName == state.storageResource)
        : undefined;
      const apiInfo = resource ? resource.apiInfo : undefined;

      if (apiInfo && apiInfo.type == ApiType.JetDjango && apiInfo.versionLessThan('0.7.7')) {
        value = value['value'];
      }

      const controller = resource ? this.resourceControllerService.get(resource.type) : undefined;

      if (controller && controller.fileUrl) {
        return controller.fileUrl(resource, value);
      }
    }

    return value;
  }

  onClick() {
    if (this.element.clickAction) {
      this.actionControllerService
        .execute(this.element.clickAction, {
          context: this.context,
          injector: this.injector
        })
        .subscribe();
    }
  }
}

registerElementComponent({
  type: ElementType.Image,
  component: ImageElementComponent,
  label: 'Image',
  actions: []
});
