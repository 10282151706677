import {
  CdkConnectedOverlay,
  CdkOverlayOrigin,
  ConnectedOverlayPositionChange,
  ConnectedPosition
} from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, merge, Subscription, timer } from 'rxjs';
import { debounce, debounceTime, filter } from 'rxjs/operators';

import { ButtonMenuItem, MenuGeneratorService, MenuItem, MenuItemActionService } from '@modules/menu';
import { isSet, TypedChanges } from '@shared';

import { BaseMenuItemComponent } from '../base-menu-item/base-menu-item.component';
import { dropdownPositionsHorizontal, dropdownPositionsVertical } from '../menu-item/menu-item.component';

@Component({
  selector: 'app-button-menu-item',
  templateUrl: './button-menu-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonMenuItemComponent extends BaseMenuItemComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() menuItem: ButtonMenuItem;

  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;

  link: any[];
  href: string;
  queryParams: Object;
  handler: () => void;
  children: MenuItem[] = [];
  menuItemHovered$ = new BehaviorSubject<CdkOverlayOrigin>(undefined);
  menuDropdownHovered$ = new BehaviorSubject<boolean>(false);
  menuChildDropdownOpened$ = new BehaviorSubject<boolean>(false);
  dropdownOpened = false;
  origin: CdkOverlayOrigin;
  popoverPositions: ConnectedPosition[] = [];
  popoverPositionsSubscription: Subscription;
  menuDropdownSubscription: Subscription[] = [];

  trackMenuItemFn(i, item: MenuItem) {
    return item.id;
  }

  constructor(
    private menuGeneratorService: MenuGeneratorService,
    private menuItemActionService: MenuItemActionService,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.updateChildren();
    this.updateLink();
    this.initMenuDropdown();

    this.popoverPositions = this.childrenVertical ? dropdownPositionsVertical : dropdownPositionsHorizontal;
    this.cd.markForCheck();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<ButtonMenuItemComponent>): void {
    if (changes.menuItem && !changes.menuItem.firstChange) {
      this.updateChildren();
      this.updateLink();
      this.initMenuDropdown();
    }
  }

  ngAfterViewInit(): void {
    this.setPositionObserver();
  }

  updateChildren() {
    this.children = this.menuGeneratorService.cleanMenuItemsAppMode(this.menuItem.children);
    this.cd.markForCheck();
  }

  updateLink() {
    this.menuItemActionService
      .getActionExecution(this.menuItem.action, { context: this.context })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.link = result.link;
        this.href = result.href;
        this.queryParams = result.queryParams;
        this.handler = result.handler;
        this.cd.markForCheck();
      });
  }

  isIconButton(): boolean {
    return (this.primary && !this.horizontal) || !isSet(this.menuItem.title);
  }

  initMenuDropdown() {
    this.menuDropdownSubscription.forEach(item => item.unsubscribe());
    this.menuDropdownSubscription = [];

    if (!this.menuItem.children.length) {
      return;
    }

    this.menuDropdownSubscription.push(
      combineLatest(
        this.menuItemHovered$.pipe(debounce(origin => timer(origin ? 0 : 100))),
        this.menuDropdownHovered$,
        this.menuChildDropdownOpened$
      )
        .pipe(debounceTime(10), untilDestroyed(this))
        .subscribe(([itemOrigin, dropdownHovered, childDropdownOpened]) => {
          if (itemOrigin && !this.dropdownOpened) {
            this.openDropdown(itemOrigin);
          } else if (!itemOrigin && !dropdownHovered && !childDropdownOpened && this.dropdownOpened) {
            this.closeDropdown();
          }
        })
    );

    this.menuDropdownSubscription.push(
      merge(this.router.events.pipe(filter(item => item instanceof ActivationEnd)))
        .pipe(untilDestroyed(this))
        .subscribe(() => this.closeDropdown())
    );
  }

  openDropdown(origin: CdkOverlayOrigin) {
    this.origin = origin;
    this.dropdownOpened = true;
    this.cd.markForCheck();
    this.dropdownOpen.emit();
  }

  closeDropdown() {
    this.dropdownOpened = false;
    this.cd.markForCheck();
    this.dropdownClose.emit();
  }

  setPositionObserver() {
    if (this.popoverPositionsSubscription) {
      this.popoverPositionsSubscription.unsubscribe();
    }

    if (!this.cdkConnectedOverlay) {
      return;
    }

    this.popoverPositionsSubscription = this.cdkConnectedOverlay.positionChange
      .pipe(untilDestroyed(this))
      .subscribe((e: ConnectedOverlayPositionChange) => {
        const propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
        const position = this.popoverPositions.find(item =>
          propsEqual.every(prop => (item[prop] || undefined) == e.connectionPair[prop])
        );
        const otherPosition = this.popoverPositions.filter(item => item !== position);

        if (position) {
          this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
        }

        otherPosition.forEach(item => this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass));
      });
  }

  onClick() {
    if (this.handler) {
      this.handler();
    }
  }
}
