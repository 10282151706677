/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/cdk/overlay";
import * as i3 from "../auto-menu-item/auto-menu-item.component.ngfactory";
import * as i4 from "../auto-menu-item/auto-menu-item.component";
import * as i5 from "../../../data/menu-context";
import * as i6 from "@angular/router";
import * as i7 from "../../../../routing/directives/is-link-active/is-link-active.directive";
import * as i8 from "../../../../routing/services/routing/routing.service";
import * as i9 from "../../../../../shared/pipes/is-set/is-set.pipe";
import * as i10 from "@angular/cdk/bidi";
import * as i11 from "./menu-item.component";
import * as i12 from "../../../../projects/stores/current-project.store";
import * as i13 from "../../../../menu/services/menu-generator/menu-generator.service";
var styles_MenuItemComponent = [];
var RenderType_MenuItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MenuItemComponent, data: {} });
export { RenderType_MenuItemComponent as RenderType_MenuItemComponent };
function View_MenuItemComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "menu2-primary-item__icon"]], [[2, "menu2-primary-item__icon_left", null]], null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵppd(2, 1)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "menu2-primary-item__icon"; var currVal_2 = ("icon-" + _co.icon); _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 0, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent.parent, 0), _co.icon)); _ck(_v, 0, 0, currVal_0); }); }
function View_MenuItemComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "menu2-primary-item__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_MenuItemComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "menu2-primary-item__arrow icon-arrow_down_2"]], null, null, null, null, null))], null, null); }
function View_MenuItemComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "menu2-primary-item-tip"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_MenuItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 16, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 12, "div", [["cdkOverlayOrigin", ""], ["class", "menu2-primary-item"]], [[2, "menu2-primary-item_icon", null], [2, "menu2-primary-item_active", null]], [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.menuItemHovered$.next(i0.ɵnov(_v, 2)) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.menuItemHovered$.next(undefined) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, [["primary_item_origin", 4]], 0, i2.CdkOverlayOrigin, [i0.ElementRef], null, null), i0.ɵppd(3, 1), (_l()(), i0.ɵeld(4, 0, null, null, 9, "div", [["class", "menu2-primary-item__inner"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_MenuItemComponent_3)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(7, 1), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_MenuItemComponent_4)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(10, 1), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_MenuItemComponent_5)), i0.ɵdid(12, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(13, 1), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_MenuItemComponent_6)), i0.ɵdid(15, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(16, 1), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i0.ɵnov(_v.parent.parent, 0), _co.icon)); _ck(_v, 6, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i0.ɵnov(_v.parent.parent, 0), _co.title)); _ck(_v, 9, 0, currVal_3); var currVal_4 = (_co.displayChildren.length && i0.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i0.ɵnov(_v.parent.parent, 0), _co.title))); _ck(_v, 12, 0, currVal_4); var currVal_5 = (i0.ɵunv(_v, 15, 0, _ck(_v, 16, 0, i0.ɵnov(_v.parent.parent, 0), _co.title)) && !_co.displayChildren.length); _ck(_v, 15, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !i0.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent.parent, 0), _co.title)); var currVal_1 = ((_v.parent.context.active || _co.forceActive) || _co.dropdownOpened); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_MenuItemComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "menu2-secondary-item__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_MenuItemComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "menu2-secondary-item__arrow icon-arrow_down_2"]], null, null, null, null, null))], null, null); }
function View_MenuItemComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["cdkOverlayOrigin", ""], ["class", "menu2-secondary-item"]], [[2, "menu2-secondary-item_icon", null], [2, "menu2-secondary-item_active", null]], [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.menuItemHovered$.next(i0.ɵnov(_v, 1)) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.menuItemHovered$.next(undefined) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, [["primary_item_origin", 4]], 0, i2.CdkOverlayOrigin, [i0.ElementRef], null, null), i0.ɵppd(2, 1), (_l()(), i0.ɵeld(3, 0, null, null, 10, "div", [["class", "menu2-secondary-item__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "menu2-secondary-item__icon"]], [[2, "menu2-secondary-item__icon_empty", null]], null, null, null, null)), i0.ɵdid(5, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵppd(6, 1), i0.ɵppd(7, 1), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_MenuItemComponent_8)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(10, 1), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_MenuItemComponent_9)), i0.ɵdid(12, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(13, 1)], function (_ck, _v) { var _co = _v.component; var currVal_3 = "menu2-secondary-item__icon"; var currVal_4 = (i0.ɵunv(_v, 5, 1, _ck(_v, 6, 0, i0.ɵnov(_v.parent.parent, 0), _co.icon)) ? ("icon-" + _co.icon) : "icon-circle"); _ck(_v, 5, 0, currVal_3, currVal_4); var currVal_5 = i0.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i0.ɵnov(_v.parent.parent, 0), _co.title)); _ck(_v, 9, 0, currVal_5); var currVal_6 = (_co.displayChildren.length && i0.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i0.ɵnov(_v.parent.parent, 0), _co.title))); _ck(_v, 12, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !i0.ɵunv(_v, 0, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent, 0), _co.title)); var currVal_1 = ((_v.parent.context.active || _co.forceActive) || _co.dropdownOpened); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = !i0.ɵunv(_v, 4, 0, _ck(_v, 7, 0, i0.ɵnov(_v.parent.parent, 0), _co.icon)); _ck(_v, 4, 0, currVal_2); }); }
function View_MenuItemComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "menu2-dropdown-item__icon"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "menu2-dropdown-item__icon"; var currVal_1 = ("icon-" + _co.icon); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_MenuItemComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "menu2-dropdown-item__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_MenuItemComponent_13(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "menu2-dropdown-item__arrow icon-arrow_forward_2"]], null, null, null, null, null))], null, null); }
function View_MenuItemComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 12, "div", [["cdkOverlayOrigin", ""], ["class", "menu2-dropdown-item"]], [[2, "menu2-dropdown-item_icon", null], [2, "menu2-dropdown-item_active", null]], [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.menuItemHovered$.next(i0.ɵnov(_v, 2)) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.menuItemHovered$.next(undefined) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, [["dropdown_item_origin", 4]], 0, i2.CdkOverlayOrigin, [i0.ElementRef], null, null), i0.ɵppd(3, 1), (_l()(), i0.ɵeld(4, 0, null, null, 9, "div", [["class", "menu2-dropdown-item__inner"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_MenuItemComponent_11)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(7, 1), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_MenuItemComponent_12)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(10, 1), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_MenuItemComponent_13)), i0.ɵdid(12, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(13, 1)], function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i0.ɵnov(_v.parent.parent, 0), _co.icon)); _ck(_v, 6, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i0.ɵnov(_v.parent.parent, 0), _co.title)); _ck(_v, 9, 0, currVal_3); var currVal_4 = (_co.displayChildren.length && i0.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i0.ɵnov(_v.parent.parent, 0), _co.title))); _ck(_v, 12, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !i0.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent.parent, 0), _co.title)); var currVal_1 = ((_v.parent.context.active || _co.forceActive) || _co.dropdownOpened); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_MenuItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuItemComponent_2)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuItemComponent_7)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuItemComponent_10)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.primary; _ck(_v, 1, 0, currVal_0); var currVal_1 = (!_co.primary && !_co.dropdown); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.dropdown; _ck(_v, 5, 0, currVal_2); }, null); }
function View_MenuItemComponent_15(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-auto-menu-item", [], null, [[null, "dropdownOpen"], [null, "dropdownClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dropdownOpen" === en)) {
        var pd_0 = (_co.menuChildDropdownOpened$.next(true) !== false);
        ad = (pd_0 && ad);
    } if (("dropdownClose" === en)) {
        var pd_1 = (_co.menuChildDropdownOpened$.next(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_AutoMenuItemComponent_0, i3.RenderType_AutoMenuItemComponent)), i0.ɵdid(1, 770048, null, 0, i4.AutoMenuItemComponent, [i5.MenuContext, i0.ChangeDetectorRef], { menuItem: [0, "menuItem"], menuBlock: [1, "menuBlock"], dropdown: [2, "dropdown"] }, { dropdownOpen: "dropdownOpen", dropdownClose: "dropdownClose" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.menuBlock; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_MenuItemComponent_14(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "popover2 overlay-content overlay-content_animated"]], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.menuDropdownHovered$.next(true) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.menuDropdownHovered$.next(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "popover2__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "popover2__viewport popover2__viewport_scrollable"], ["style", "min-width: 220px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "popover2__content popover2__content_padding_sm menu2-dropdown menu2-block menu2-block_unset"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuItemComponent_15)), i0.ɵdid(5, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayChildren; var currVal_1 = _co.trackMenuItemFn; _ck(_v, 5, 0, currVal_0, currVal_1); }, null); }
function View_MenuItemComponent_17(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_MenuItemComponent_16(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "a", [["class", "menu2-link menu2-item-wrapper"]], [[2, "menu2-link_active", null], [1, "target", 0], [8, "href", 4]], [[null, "appIsLinkActiveActivate"], [null, "appIsLinkActiveDeactivate"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("appIsLinkActiveActivate" === en)) {
        var pd_1 = (_co.onActivate() !== false);
        ad = (pd_1 && ad);
    } if (("appIsLinkActiveDeactivate" === en)) {
        var pd_2 = (_co.onDeactivate() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.onClick() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, null, 0, i6.RouterLinkWithHref, [i6.Router, i6.ActivatedRoute, i1.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i0.ɵdid(2, 737280, [["app_is_link_active", 4]], 0, i7.IsLinkActiveDirective, [i6.Router, i8.RoutingService, i0.ChangeDetectorRef], { appIsLinkActiveRouterLink: [0, "appIsLinkActiveRouterLink"] }, { appIsLinkActiveActivate: "appIsLinkActiveActivate", appIsLinkActiveDeactivate: "appIsLinkActiveDeactivate" }), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_MenuItemComponent_17)), i0.ɵdid(4, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(5, { active: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.queryParams; var currVal_4 = _co.link; _ck(_v, 1, 0, currVal_3, currVal_4); var currVal_5 = _co.link; _ck(_v, 2, 0, currVal_5); var currVal_6 = _ck(_v, 5, 0, i0.ɵnov(_v, 2).active); var currVal_7 = i0.ɵnov(_v.parent, 2); _ck(_v, 4, 0, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).active; var currVal_1 = i0.ɵnov(_v, 1).target; var currVal_2 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_MenuItemComponent_19(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_MenuItemComponent_18(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "a", [["class", "menu2-link menu2-item-wrapper"], ["target", "_blank"]], [[8, "href", 4], [2, "menu2-link_active", null]], [[null, "appIsLinkActiveActivate"], [null, "appIsLinkActiveDeactivate"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("appIsLinkActiveActivate" === en)) {
        var pd_0 = (_co.onActivate() !== false);
        ad = (pd_0 && ad);
    } if (("appIsLinkActiveDeactivate" === en)) {
        var pd_1 = (_co.onDeactivate() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.onClick() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 737280, [["app_is_link_active", 4]], 0, i7.IsLinkActiveDirective, [i6.Router, i8.RoutingService, i0.ChangeDetectorRef], { appIsLinkActiveHref: [0, "appIsLinkActiveHref"] }, { appIsLinkActiveActivate: "appIsLinkActiveActivate", appIsLinkActiveDeactivate: "appIsLinkActiveDeactivate" }), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_MenuItemComponent_19)), i0.ɵdid(3, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(4, { active: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.href; _ck(_v, 1, 0, currVal_2); var currVal_3 = _ck(_v, 4, 0, i0.ɵnov(_v, 1).active); var currVal_4 = i0.ɵnov(_v.parent, 2); _ck(_v, 3, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.href; var currVal_1 = i0.ɵnov(_v, 1).active; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_MenuItemComponent_21(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_MenuItemComponent_20(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "menu2-item-wrapper"]], [[2, "menu2-link", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuItemComponent_21)), i0.ɵdid(2, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_1 = i0.ɵnov(_v.parent, 2); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.handler; _ck(_v, 0, 0, currVal_0); }); }
export function View_MenuItemComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i9.IsSetPipe, []), i0.ɵqud(402653184, 1, { cdkConnectedOverlay: 0 }), (_l()(), i0.ɵand(0, [["menu_item", 2]], null, 0, null, View_MenuItemComponent_1)), (_l()(), i0.ɵand(16777216, null, null, 2, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backdropClick" === en)) {
        var pd_0 = (_co.closeDropdown() !== false);
        ad = (pd_0 && ad);
    } if (("detach" === en)) {
        var pd_1 = (_co.closeDropdown() !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_MenuItemComponent_14)), i0.ɵdid(4, 671744, [[1, 4]], 0, i2.CdkConnectedOverlay, [i2.Overlay, i0.TemplateRef, i0.ViewContainerRef, i2.ɵc, [2, i10.Directionality]], { origin: [0, "origin"], positions: [1, "positions"], backdropClass: [2, "backdropClass"], panelClass: [3, "panelClass"], open: [4, "open"], hasBackdrop: [5, "hasBackdrop"], growAfterOpen: [6, "growAfterOpen"], push: [7, "push"] }, { backdropClick: "backdropClick", detach: "detach" }), i0.ɵpad(5, 2), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuItemComponent_16)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_MenuItemComponent_18)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(10, 1), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_MenuItemComponent_20)), i0.ɵdid(12, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(13, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.origin; var currVal_1 = _co.popoverPositions; var currVal_2 = "popover2-backdrop"; var currVal_3 = _ck(_v, 5, 0, "overlay", "overlay_horizontal_center"); var currVal_4 = _co.dropdownOpened; var currVal_5 = false; var currVal_6 = true; var currVal_7 = true; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = _co.link; _ck(_v, 7, 0, currVal_8); var currVal_9 = (!_co.link && i0.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i0.ɵnov(_v, 0), _co.href))); _ck(_v, 9, 0, currVal_9); var currVal_10 = (!_co.link && !i0.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i0.ɵnov(_v, 0), _co.href))); _ck(_v, 12, 0, currVal_10); }, null); }
export function View_MenuItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-menu-item", [], null, null, null, View_MenuItemComponent_0, RenderType_MenuItemComponent)), i0.ɵdid(1, 4964352, null, 0, i11.MenuItemComponent, [i12.CurrentProjectStore, i13.MenuGeneratorService, i6.Router, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MenuItemComponentNgFactory = i0.ɵccf("app-menu-item", i11.MenuItemComponent, View_MenuItemComponent_Host_0, { icon: "icon", title: "title", link: "link", queryParams: "queryParams", href: "href", exactActive: "exactActive", handler: "handler", menuBlock: "menuBlock", children: "children", childrenVertical: "childrenVertical", primary: "primary", dropdown: "dropdown", forceActive: "forceActive", forceOpened: "forceOpened" }, { dropdownOpen: "dropdownOpen", dropdownClose: "dropdownClose" }, []);
export { MenuItemComponentNgFactory as MenuItemComponentNgFactory };
