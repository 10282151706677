import isArray from 'lodash/isArray';
import { DataSourceType, ListModelDescriptionDataSource } from '@modules/data-sources';
import { FieldOutput, Input, ParameterField } from '@modules/fields';
import { ActionQuery, ListModelDescriptionQuery, QueryType } from '@modules/queries';
import { ascComparator, generateAlphanumeric, isSet, sortUsingAfterItem, splitmax } from '@shared';
// TODO: Refactor import
import { Workflow } from '../../workflow/data/workflow';
import { ActionType } from './action-type';
export function sortActionDescriptions(items) {
    if (items.length <= 1) {
        return items;
    }
    return sortUsingAfterItem({
        items: items,
        getAfterItem: function (item) { return item.orderAfter; },
        getItemId: function (item) { return item.name; },
        defaultSort: function (lhs, rhs) {
            return ascComparator(lhs.verboseName.toLowerCase(), rhs.verboseName.toLowerCase());
        }
    });
}
var QueryAction = /** @class */ (function () {
    function QueryAction() {
    }
    QueryAction.prototype.deserialize = function (data) {
        if (data['query']) {
            this.query = new ActionQuery().deserialize(data['query']);
        }
        return this;
    };
    QueryAction.prototype.serialize = function () {
        return {
            query: this.query ? this.query.serialize() : undefined
        };
    };
    return QueryAction;
}());
export { QueryAction };
export var DownloadActionType;
(function (DownloadActionType) {
    DownloadActionType["Input"] = "input";
    DownloadActionType["Query"] = "query";
})(DownloadActionType || (DownloadActionType = {}));
var DownloadAction = /** @class */ (function () {
    function DownloadAction() {
    }
    DownloadAction.prototype.deserialize = function (data) {
        this.type = data['type'] || DownloadActionType.Query;
        this.fileColumn = data['file_column'];
        if (data['query']) {
            this.query = new ActionQuery().deserialize(data['query']);
        }
        if (data['input']) {
            this.input = new Input().deserialize(data['input']);
        }
        return this;
    };
    DownloadAction.prototype.serialize = function () {
        return {
            type: this.type,
            query: this.query ? this.query.serialize() : undefined,
            file_column: this.fileColumn,
            input: this.input ? this.input.serialize() : undefined
        };
    };
    return DownloadAction;
}());
export { DownloadAction };
export var SegueType;
(function (SegueType) {
    SegueType["Page"] = "page";
    SegueType["PreviousPage"] = "previous_page";
    SegueType["ModelCreate"] = "model_create";
    SegueType["ModelChange"] = "model_change";
    SegueType["ModelMassEdit"] = "model_mass_edit";
    SegueType["ModelActivityLog"] = "model_activity_log";
    SegueType["ModelDelete"] = "model_delete";
})(SegueType || (SegueType = {}));
export var modelSegueTypes = [
    SegueType.ModelCreate,
    SegueType.ModelChange,
    SegueType.ModelMassEdit,
    SegueType.ModelActivityLog,
    SegueType.ModelDelete
];
var LinkAction = /** @class */ (function () {
    function LinkAction() {
    }
    LinkAction.prototype.deserialize = function (data) {
        this.type = data['type'];
        this.page = data['page'];
        this.model = data['model'];
        return this;
    };
    LinkAction.prototype.serialize = function () {
        return {
            type: this.type,
            page: this.page,
            model: this.model
        };
    };
    return LinkAction;
}());
export { LinkAction };
var NotificationAction = /** @class */ (function () {
    function NotificationAction() {
        this.closeTimeoutEnabled = true;
    }
    NotificationAction.prototype.deserialize = function (data) {
        this.type = data['type'];
        this.icon = data['icon'];
        this.color = data['color'];
        if (data['title']) {
            // Backward compatibility
            if (typeof data['title'] == 'string') {
                this.title = new Input().deserializeFromStatic('value', data['title']);
            }
            else {
                this.title = new Input().deserialize(data['title']);
            }
        }
        if (data['description']) {
            // Backward compatibility
            if (typeof data['description'] == 'string') {
                this.description = new Input().deserializeFromStatic('value', data['description']);
            }
            else {
                this.description = new Input().deserialize(data['description']);
            }
        }
        if (data['close_timeout_enabled'] !== undefined) {
            this.closeTimeoutEnabled = data['close_timeout_enabled'];
        }
        this.closeTimeout = data['close_timeout'];
        return this;
    };
    NotificationAction.prototype.serialize = function () {
        return {
            title: this.title ? this.title.serialize() : null,
            description: this.description ? this.description.serialize() : null,
            icon: this.icon,
            color: this.color,
            type: this.type,
            close_timeout_enabled: this.closeTimeoutEnabled,
            close_timeout: this.closeTimeout
        };
    };
    return NotificationAction;
}());
export { NotificationAction };
var SetPropertyAction = /** @class */ (function () {
    function SetPropertyAction() {
    }
    SetPropertyAction.prototype.deserialize = function (data) {
        this.property = data['property'];
        if (data['value']) {
            this.value = new Input().deserialize(data['value']);
        }
        return this;
    };
    SetPropertyAction.prototype.serialize = function () {
        return {
            property: this.property,
            value: this.value ? this.value.serialize() : null
        };
    };
    return SetPropertyAction;
}());
export { SetPropertyAction };
var RunJavaScriptAction = /** @class */ (function () {
    function RunJavaScriptAction() {
    }
    RunJavaScriptAction.prototype.deserialize = function (data) {
        this.js = data['js'];
        return this;
    };
    RunJavaScriptAction.prototype.serialize = function () {
        return {
            js: this.js
        };
    };
    return RunJavaScriptAction;
}());
export { RunJavaScriptAction };
var CopyToClipboardAction = /** @class */ (function () {
    function CopyToClipboardAction() {
    }
    CopyToClipboardAction.prototype.deserialize = function (data) {
        if (data['value']) {
            this.value = new Input().deserialize(data['value']);
        }
        return this;
    };
    CopyToClipboardAction.prototype.serialize = function () {
        return {
            value: this.value ? this.value.serialize() : null
        };
    };
    return CopyToClipboardAction;
}());
export { CopyToClipboardAction };
var ExportAction = /** @class */ (function () {
    function ExportAction() {
    }
    ExportAction.prototype.deserialize = function (data) {
        if (data['data_source']) {
            this.dataSource = new ListModelDescriptionDataSource().deserialize(data['data_source']);
        }
        return this;
    };
    ExportAction.prototype.serialize = function () {
        return {
            data_source: this.dataSource ? this.dataSource.serialize() : undefined
        };
    };
    ExportAction.prototype.getModelId = function () {
        return this.dataSource &&
            this.dataSource.type == DataSourceType.Query &&
            this.dataSource.query &&
            this.dataSource.query.queryType == QueryType.Simple &&
            this.dataSource.query.simpleQuery
            ? [this.dataSource.queryResource, this.dataSource.query.simpleQuery.model].join('.')
            : undefined;
    };
    return ExportAction;
}());
export { ExportAction };
var ImportAction = /** @class */ (function () {
    function ImportAction() {
    }
    ImportAction.prototype.deserialize = function (data) {
        this.resource = data['resource'];
        this.model = data['model'];
        return this;
    };
    ImportAction.prototype.serialize = function () {
        return {
            resource: this.resource,
            model: this.model
        };
    };
    ImportAction.prototype.getModelId = function () {
        if (!isSet(this.resource) || !isSet(this.model)) {
            return;
        }
        return [this.resource, this.model].join('.');
    };
    return ImportAction;
}());
export { ImportAction };
var OpenPopupAction = /** @class */ (function () {
    function OpenPopupAction() {
    }
    OpenPopupAction.prototype.deserialize = function (data) {
        this.popup = data['popup'];
        return this;
    };
    OpenPopupAction.prototype.serialize = function () {
        return {
            popup: this.popup
        };
    };
    return OpenPopupAction;
}());
export { OpenPopupAction };
var ClosePopupAction = /** @class */ (function () {
    function ClosePopupAction() {
    }
    ClosePopupAction.prototype.deserialize = function (data) {
        this.popup = data['popup'];
        return this;
    };
    ClosePopupAction.prototype.serialize = function () {
        return {
            popup: this.popup
        };
    };
    return ClosePopupAction;
}());
export { ClosePopupAction };
var WorkflowAction = /** @class */ (function () {
    function WorkflowAction() {
    }
    WorkflowAction.prototype.deserialize = function (data) {
        if (data['workflow']) {
            this.workflow = new Workflow().deserialize(data['workflow']);
        }
        return this;
    };
    WorkflowAction.prototype.serialize = function () {
        return {
            workflow: this.workflow ? this.workflow.serialize() : undefined
        };
    };
    return WorkflowAction;
}());
export { WorkflowAction };
var ActionDescription = /** @class */ (function () {
    function ActionDescription() {
        this.actionParams = [];
        this.outputs = [];
        this.arrayOutput = false;
        this.protected = false;
        this.virtual = false;
        this.featured = false;
        this.orderAfter = undefined;
        this.draft = false;
        this.deleted = false;
        this.deprecatedDynamicStatus = false;
    }
    ActionDescription.generateName = function () {
        return generateAlphanumeric(8);
    };
    ActionDescription.prototype.deserialize = function (data) {
        this.resource = data['resource'];
        this.name = data['name'];
        this.verboseName = data['verbose_name'] || this.name;
        if (data['icon']) {
            this.icon = data['icon'];
        }
        if (data['params']) {
            if (isArray(data['params'])) {
                this.actionParams = data['params'].map(function (item) { return new ParameterField().deserialize(item); });
                this.params = {};
            }
            else {
                this.params = data['params'];
                this.type = this.params['type'];
                this.verboseName = this.params['verbose_name'] || this.name;
                this.icon = this.params['icon'];
                this.arrayOutput = this.params['array_output'];
                if (this.params['protected'] != undefined) {
                    this.protected = this.params['protected'];
                }
                if (this.params['action_params']) {
                    this.actionParams = this.params['action_params'].map(function (item) { return new ParameterField().deserialize(item); });
                }
                if (this.params['outputs']) {
                    this.outputs = this.params['outputs'].map(function (item) { return new FieldOutput().deserialize(item); });
                }
                this.description = this.params['description'];
                if (this.params['query_action']) {
                    this.queryAction = new QueryAction().deserialize(this.params['query_action']);
                }
                else if (this.params['query']) {
                    this.queryAction = new QueryAction().deserialize({
                        query: this.params['query']
                    });
                }
                if (this.params['download_action']) {
                    this.downloadAction = new DownloadAction().deserialize(this.params['download_action']);
                }
                if (this.params['link_action']) {
                    this.linkAction = new LinkAction().deserialize(this.params['link_action']);
                }
                if (this.params['element_action']) {
                    if (isArray(this.params['element_action'])) {
                        this.elementAction = this.params['element_action'];
                    }
                    else if (typeof this.params['element_action'] == 'string') {
                        this.elementAction = this.params['element_action'].split('.');
                    }
                }
                if (this.params['notification_action']) {
                    this.notificationAction = new NotificationAction().deserialize(this.params['notification_action']);
                }
                if (this.params['export_action']) {
                    // Backward compatibility
                    if (this.params['export_action']['model']) {
                        this.exportAction = new ExportAction();
                        this.exportAction.dataSource = new ListModelDescriptionDataSource();
                        this.exportAction.dataSource.type = DataSourceType.Query;
                        this.exportAction.dataSource.queryResource = data['resource'];
                        this.exportAction.dataSource.query = new ListModelDescriptionQuery();
                        this.exportAction.dataSource.query.queryType = QueryType.Simple;
                        this.exportAction.dataSource.query.simpleQuery = new this.exportAction.dataSource.query.simpleQueryClass();
                        this.exportAction.dataSource.query.simpleQuery.model = this.params['export_action']['model'];
                    }
                    else {
                        this.exportAction = new ExportAction().deserialize(this.params['export_action']);
                    }
                }
                if (this.params['import_action']) {
                    this.importAction = new ImportAction().deserialize(this.params['import_action']);
                }
                if (this.params['open_popup_action']) {
                    this.openPopupAction = new OpenPopupAction().deserialize(this.params['open_popup_action']);
                }
                if (this.params['close_popup_action']) {
                    this.closePopupAction = new ClosePopupAction().deserialize(this.params['close_popup_action']);
                }
                if (this.params['workflow_action']) {
                    this.workflowAction = new WorkflowAction().deserialize(this.params['workflow_action']);
                }
                if (this.params['set_property_action']) {
                    this.setPropertyAction = new SetPropertyAction().deserialize(this.params['set_property_action']);
                }
                if (this.params['run_javascript_action']) {
                    this.runJavaScriptAction = new RunJavaScriptAction().deserialize(this.params['run_javascript_action']);
                }
                if (this.params['copy_to_clipboard_action']) {
                    this.copyToClipboardAction = new CopyToClipboardAction().deserialize(this.params['copy_to_clipboard_action']);
                }
                if (this.params['virtual']) {
                    this.virtual = this.params['virtual'];
                }
                if (this.params['featured']) {
                    this.featured = this.params['featured'];
                }
                if (this.params['order_after'] !== undefined) {
                    this.orderAfter = this.params['order_after'];
                }
            }
        }
        // Backward compatibility
        if (!this.type) {
            if (this.queryAction) {
                this.type = ActionType.Query;
            }
            else if (this.linkAction) {
                this.type = ActionType.Link;
            }
            else if (this.linkAction && this.linkAction.type == 'model_export') {
                // Backward compatibility (<2.2.6)
                var model = this.linkAction.model ? splitmax(this.linkAction.model, '.', 2) : undefined;
                this.type = ActionType.Export;
                if (model && model.length == 2) {
                    this.exportAction = new ExportAction();
                    this.exportAction.dataSource = new ListModelDescriptionDataSource();
                    this.exportAction.dataSource.type = DataSourceType.Query;
                    this.exportAction.dataSource.query = new ListModelDescriptionQuery();
                    this.exportAction.dataSource.query.queryType = QueryType.Simple;
                    this.exportAction.dataSource.query.simpleQuery = new this.exportAction.dataSource.query.simpleQueryClass();
                    this.exportAction.dataSource.queryResource = model[0], this.exportAction.dataSource.query.simpleQuery.model = model[1];
                }
            }
        }
        if (data['draft'] !== undefined) {
            this.draft = data['draft'];
        }
        if (data['deleted'] !== undefined) {
            this.deleted = data['deleted'];
        }
        // Backward compatibility
        if (data['dynamic_status'] != undefined) {
            this.deprecatedDynamicStatus = data['dynamic_status'];
        }
        if (data['model_action'] != undefined) {
            this.deprecatedModelAction = data['model_action'];
        }
        return this;
    };
    Object.defineProperty(ActionDescription.prototype, "id", {
        get: function () {
            return [this.resource, this.name].join('.');
        },
        enumerable: true,
        configurable: true
    });
    ActionDescription.prototype.isSame = function (action) {
        if (!action) {
            return false;
        }
        if (action instanceof ActionDescription) {
            return action.resource == this.resource && action.name == this.name;
        }
        else {
            var id = action;
            var params = splitmax(id, '.', 2);
            return params.length == 2
                ? (!this.resource || this.resource == params[0]) && this.name == params[1]
                : this.name == params[0];
        }
    };
    ActionDescription.prototype.serialize = function () {
        return {
            resource: this.resource,
            name: this.name,
            params: {
                type: this.type,
                verbose_name: this.verboseName,
                icon: this.icon,
                action_params: this.actionParams.map(function (item) { return item.serialize(); }),
                outputs: this.outputs.map(function (item) { return item.serialize(); }),
                array_output: this.arrayOutput,
                description: this.description,
                protected: this.protected,
                query_action: this.queryAction ? this.queryAction.serialize() : undefined,
                download_action: this.downloadAction ? this.downloadAction.serialize() : undefined,
                link_action: this.linkAction ? this.linkAction.serialize() : undefined,
                element_action: this.elementAction,
                notification_action: this.notificationAction ? this.notificationAction.serialize() : undefined,
                export_action: this.exportAction ? this.exportAction.serialize() : undefined,
                import_action: this.importAction ? this.importAction.serialize() : undefined,
                open_popup_action: this.openPopupAction ? this.openPopupAction.serialize() : undefined,
                close_popup_action: this.closePopupAction ? this.closePopupAction.serialize() : undefined,
                workflow_action: this.workflowAction ? this.workflowAction.serialize() : undefined,
                set_property_action: this.setPropertyAction ? this.setPropertyAction.serialize() : undefined,
                run_javascript_action: this.runJavaScriptAction ? this.runJavaScriptAction.serialize() : undefined,
                copy_to_clipboard_action: this.copyToClipboardAction ? this.copyToClipboardAction.serialize() : undefined,
                virtual: this.virtual,
                featured: this.featured,
                order_after: this.orderAfter
            },
            draft: this.draft,
            deleted: this.deleted
        };
    };
    ActionDescription.prototype.getLink = function (modelId) {
        if (this.queryAction) {
            return ['action', this.id];
        }
        else if (this.linkAction) {
            // return [this.linkAction.link];
        }
    };
    Object.defineProperty(ActionDescription.prototype, "queryLink", {
        get: function () {
            return ['action', this.id];
        },
        enumerable: true,
        configurable: true
    });
    ActionDescription.prototype.clone = function () {
        return new ActionDescription().deserialize(this.serialize());
    };
    Object.defineProperty(ActionDescription.prototype, "typeStr", {
        get: function () {
            if (this.type == ActionType.Query) {
                return 'Run Operation';
            }
            else if (this.type == ActionType.Download) {
                return 'Download File';
            }
            else if (this.type == ActionType.Link) {
                return 'Navigate to Page';
            }
            else if (this.type == ActionType.ExternalLink) {
                return 'Open URL';
            }
            else if (this.type == ActionType.ElementAction) {
                return 'Component Action';
            }
            else if (this.type == ActionType.ShowNotification) {
                return 'Show Notification';
            }
            else if (this.type == ActionType.SetProperty) {
                return 'Set Variable';
            }
            else if (this.type == ActionType.RunJavaScript) {
                return 'Run JavaScript';
            }
            else if (this.type == ActionType.CopyToClipboard) {
                return 'Copy to Clipboard';
            }
            else if (this.type == ActionType.Export) {
                return 'Export Data';
            }
            else if (this.type == ActionType.Import) {
                return 'Import Data';
            }
            else if (this.type == ActionType.OpenPopup) {
                return 'Open Modal';
            }
            else if (this.type == ActionType.ClosePopup) {
                return 'Close Modal';
            }
            else if (this.type == ActionType.ScanCode) {
                return 'Scan QR/Bar code';
            }
            else if (this.type == ActionType.Workflow) {
                return 'Run Workflow';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ActionDescription.prototype, "typeIcon", {
        get: function () {
            if (this.type == ActionType.Query) {
                return 'cloud_upload';
            }
            else if (this.type == ActionType.Download) {
                return 'save';
            }
            else if (this.type == ActionType.Link) {
                return 'redo';
            }
            else if (this.type == ActionType.ExternalLink) {
                return 'model_link';
            }
            else if (this.type == ActionType.ElementAction) {
                return 'components';
            }
            else if (this.type == ActionType.ShowNotification) {
                return 'notification';
            }
            else if (this.type == ActionType.SetProperty) {
                return 'variable';
            }
            else if (this.type == ActionType.RunJavaScript) {
                return 'console';
            }
            else if (this.type == ActionType.CopyToClipboard) {
                return 'documents';
            }
            else if (this.type == ActionType.Export) {
                return 'download';
            }
            else if (this.type == ActionType.Import) {
                return 'upload';
            }
            else if (this.type == ActionType.OpenPopup) {
                return 'copy';
            }
            else if (this.type == ActionType.ClosePopup) {
                return 'windows';
            }
            else if (this.type == ActionType.Workflow) {
                return 'workflow';
            }
            else if (this.type == ActionType.ScanCode) {
                return 'qr_code';
            }
            else {
                return 'power';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ActionDescription.prototype, "editLink", {
        get: function () {
            return ['resources', this.resource, 'actions', this.name];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ActionDescription.prototype, "anyName", {
        get: function () {
            return isSet(this.verboseName) ? this.verboseName : this.name;
        },
        enumerable: true,
        configurable: true
    });
    return ActionDescription;
}());
export { ActionDescription };
