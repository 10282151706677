var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { of } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { ProjectInvite } from '../../data/project-invite';
import { ProjectUser } from '../../data/project-user';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
var ProjectUserService = /** @class */ (function () {
    function ProjectUserService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
    }
    ProjectUserService.prototype.getProject = function (projectName, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.projectMethodURL(projectName, 'users/');
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({ fromObject: params });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return result.map(function (item) { return new ProjectUser().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectUserService.prototype.get = function (projectName, environmentName, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'users/');
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({ fromObject: params });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return result.map(function (item) { return new ProjectUser().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectUserService.prototype.getPaginate = function (projectName, environmentName, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'users/');
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({
                fromObject: __assign({ _paginate: '1' }, params)
            });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return new ProjectUserService.GetResponse().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectUserService.prototype.getDetail = function (projectName, environmentName, id, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, "users/" + id + "/");
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({ fromObject: params });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return new ProjectUser().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectUserService.prototype.create = function (projectName, environmentName, user) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'users/');
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, user.serialize(), { headers: headers });
        }), map(function (result) { return new ProjectInvite().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectUserService.prototype.update = function (projectName, environmentName, user) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, "users/" + user.uid + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.patch(url, user.serialize(), { headers: headers });
        }), map(function (result) { return new ProjectUser().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectUserService.prototype.delete = function (projectName, environmentName, user) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, "users/" + user.uid + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.delete(url, { headers: headers });
        }), map(function (result) { return true; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectUserService.prototype.stubTextsMentions = function (projectName, environmentName, messages) {
        var regex = /{{user\.(.*?)}}/g;
        return messages.map(function (message) {
            return message.replace(regex, "<span class=\"mention stub-text\">user</span>");
        });
    };
    ProjectUserService.prototype.stubTextMentions = function (projectName, environmentName, messages) {
        return this.stubTextsMentions(projectName, environmentName, [messages])[0];
    };
    ProjectUserService.prototype.replaceTextsMentions = function (projectName, environmentName, messages) {
        var regex = /{{user\.(.*?)}}/g;
        var userIds = [];
        messages.forEach(function (message) {
            var match;
            while ((match = regex.exec(message))) {
                var userId = match[1];
                if (!userIds.includes(userId)) {
                    userIds.push(userId);
                }
            }
        });
        if (!userIds.length) {
            return of(messages);
        }
        var params = { user_uid_in: userIds.join(',') };
        return this.get(projectName, environmentName, params).pipe(map(function (projectUsers) {
            return messages.map(function (message) {
                return message.replace(regex, function (str, userId) {
                    var projectUser = projectUsers.find(function (item) { return item.user && item.user.uid == userId; });
                    var userStr = projectUser ? projectUser.user.email.split('@')[0] : 'Unknown user';
                    return "<span class=\"mention\">@" + userStr + "</span>";
                });
            });
        }));
    };
    ProjectUserService.prototype.replaceTextMentions = function (projectName, environmentName, messages) {
        return this.replaceTextsMentions(projectName, environmentName, [messages]).pipe(map(function (result) { return result[0]; }));
    };
    ProjectUserService.ngInjectableDef = i0.defineInjectable({ factory: function ProjectUserService_Factory() { return new ProjectUserService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService)); }, token: ProjectUserService, providedIn: "root" });
    return ProjectUserService;
}());
export { ProjectUserService };
(function (ProjectUserService) {
    var GetResponse = /** @class */ (function () {
        function GetResponse() {
        }
        GetResponse.prototype.deserialize = function (data) {
            this.results = data['results'].map(function (item) { return new ProjectUser().deserialize(item); });
            this.count = data['count'];
            this.next = data['next'];
            this.previous = data['previous'];
            return this;
        };
        return GetResponse;
    }());
    ProjectUserService.GetResponse = GetResponse;
})(ProjectUserService || (ProjectUserService = {}));
