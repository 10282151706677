import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';

import { FieldType, registerFieldComponent } from '@modules/fields';

import { FieldComponent } from '../field/field.component';

@Component({
  selector: 'app-icon-field',
  templateUrl: 'icon-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconFieldComponent extends FieldComponent {
  editPopoverOpened = false;

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  openEditPopover(value) {
    this.editPopoverOpened = value;
    this.cd.markForCheck();
  }

  getLabel(value: string) {
    if (typeof value == 'string') {
      value = value.replace(/_/g, ' ');
    }

    return value;
  }

  setIcon(icon: string) {
    const patch = {};
    patch[this.field.name] = icon;
    this.form.patchValue(patch);
  }
}

registerFieldComponent(FieldType.Icon, IconFieldComponent);
