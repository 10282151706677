
const APP_ENV = window['APP_ENV'] || {};
export const environment = {
  production: true,
  environment: 'prod',
  version: '2.43.2',
  deployUrl: '/v2.43.2/',
  webBaseUrl: APP_ENV['WEB_BASE_URL'] || '',
  subdomainsBaseDomain: APP_ENV['SUBDOMAINS_BASE_DOMAIN'] || '',
  serverBaseUrl: APP_ENV['SERVER_BASE_URL'] || '',
  serverNodeBaseUrl: APP_ENV['SERVER_NODE_BASE_URL'] || '',
  serverNodeDirectBaseUrl: APP_ENV['SERVER_NODE_DIRECT_BASE_URL'] || '',
  dataSyncBaseUrl: APP_ENV['DATA_SYNC_BASE_URL'] || '',
  workflowsBaseUrl: APP_ENV['WORKFLOWS_BASE_URL'] || '',
  dataSourcesBaseUrl: APP_ENV['DATA_SOURCES_BASE_URL'] || '',
  dataSourcesDirectBaseUrl: APP_ENV['DATA_SOURCES_DIRECT_BASE_URL'] || '',
  jetBridgeCloudBaseUrl: APP_ENV['JET_BRIDGE_CLOUD_BASE_URL'] || '',
  jetBridgeRegions: APP_ENV['JET_BRIDGE_REGIONS'] || '',
  deploymentTypeEnabled: APP_ENV['DEPLOYMENT_TYPE_ENABLED'] || '',
  centrifugoUrl: APP_ENV['CENTRIFUGO_URL'] || '',
  ravenDSN: APP_ENV['RAVEN_DSN'] || '',
  airtableApiKey: APP_ENV['AIRTABLE_API_KEY'] || '',
  paddleVendorId: APP_ENV['PADDLE_VENDOR_ID'] || '',
  paddleSandbox: APP_ENV['PADDLE_SANDBOX'] || '',
  socialAuthFacebookEnabled: APP_ENV['SOCIAL_AUTH_FACEBOOK_ENABLED'] || '',
  socialAuthGoogleEnabled: APP_ENV['SOCIAL_AUTH_GOOGLE_ENABLED'] || '',
  defaultEmailFrom: APP_ENV['DEFAULT_EMAIL_FROM'] || '',
  googleTagManager: APP_ENV['GOOGLE_TAG_MANAGER'] || '',
  snapshotsEnabled: APP_ENV['SNAPSHOTS_ENABLED'] || '',
};
