var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { map } from 'rxjs/operators';
import { FieldType, GeographyOutputFormat, MultipleSelectOutputFormat, NumberValueFormat, OptionsType } from '@modules/fields';
import { SMART_SUITE_ID_FIELD_NAME, SMART_SUITE_SYSTEM_APPLICATION_MEMBERS_SLUG, SmartSuiteApplicationFieldType, SOURCE_FIELD_PARAMS } from '@modules/resources';
import { forceObservable } from '@shared';
var smartSuiteDateRangeStructure = function () {
    return {
        type: 'object',
        name: null,
        label: null,
        params: {
            items: [
                {
                    type: 'object',
                    name: 'from_date',
                    label: 'from date',
                    params: {
                        items: [
                            {
                                type: 'field',
                                name: 'date',
                                label: 'date',
                                params: {
                                    field: FieldType.DateTime,
                                    params: {},
                                    required: true,
                                    name: 'date',
                                    label: 'date'
                                }
                            }
                        ]
                    }
                },
                {
                    type: 'object',
                    name: 'to_date',
                    label: 'to date',
                    params: {
                        items: [
                            {
                                type: 'field',
                                name: 'date',
                                label: 'date',
                                params: {
                                    field: FieldType.DateTime,
                                    params: {},
                                    required: true,
                                    name: 'date',
                                    label: 'date'
                                }
                            }
                        ]
                    }
                }
            ]
        }
    };
};
var ɵ0 = smartSuiteDateRangeStructure;
var smartSuiteByStructure = function () {
    return {
        type: 'object',
        name: null,
        label: null,
        params: {
            items: [
                {
                    type: 'field',
                    name: 'by',
                    label: 'by',
                    params: {
                        field: FieldType.RelatedModel,
                        params: {
                            related_model: {
                                model: "{{resource}}." + SMART_SUITE_SYSTEM_APPLICATION_MEMBERS_SLUG
                            },
                            custom_primary_key: SMART_SUITE_ID_FIELD_NAME,
                            custom_display_field: 'full_name'
                        },
                        required: true,
                        name: 'by',
                        label: 'by'
                    }
                },
                {
                    type: 'field',
                    name: 'on',
                    label: 'on',
                    params: {
                        field: FieldType.DateTime,
                        params: {},
                        required: true,
                        name: 'on',
                        label: 'on'
                    }
                }
            ]
        }
    };
};
var ɵ1 = smartSuiteByStructure;
export var smartSuiteFieldMapping = [
    {
        type: SmartSuiteApplicationFieldType.TextField,
        mapping: { field: FieldType.Text }
    },
    {
        type: SmartSuiteApplicationFieldType.TextAreaField,
        mapping: { field: FieldType.Text, params: { multiline: true } }
    },
    {
        type: SmartSuiteApplicationFieldType.RichTextAreaField,
        mapping: { field: FieldType.RichText }
    },
    {
        type: SmartSuiteApplicationFieldType.HiddenTextField,
        mapping: { field: FieldType.Text }
    },
    {
        type: SmartSuiteApplicationFieldType.EmailField,
        mapping: { field: FieldType.Text }
    },
    {
        type: SmartSuiteApplicationFieldType.AddressField,
        mapping: {
            field: FieldType.Location,
            getParams: function (options) {
                return {
                    output_format: GeographyOutputFormat.Object,
                    object_lat_field: 'location_longitude',
                    object_lng_field: 'location_latitude'
                };
            }
        }
    },
    {
        type: SmartSuiteApplicationFieldType.PhoneField,
        mapping: { field: FieldType.Text }
    },
    {
        type: SmartSuiteApplicationFieldType.LinkField,
        mapping: { field: FieldType.URL }
    },
    {
        type: SmartSuiteApplicationFieldType.IpAddressField,
        mapping: { field: FieldType.Text }
    },
    {
        type: SmartSuiteApplicationFieldType.DateField,
        mapping: {
            field: FieldType.DateTime,
            getParams: function (options) {
                return {
                    date: true,
                    time: options.field.params.include_time
                };
            }
        }
    },
    {
        type: SmartSuiteApplicationFieldType.DateRangeField,
        mapping: {
            field: FieldType.JSON,
            params: {
                store_object: true,
                display_fields: true,
                structure: smartSuiteDateRangeStructure()
            }
        }
    },
    {
        type: SmartSuiteApplicationFieldType.DueDateField,
        mapping: { field: FieldType.DateTime }
    },
    {
        type: SmartSuiteApplicationFieldType.NumberField,
        mapping: {
            field: FieldType.Number,
            getParams: function (options) {
                return {
                    value_format: {
                        number_fraction: options.field.params.precision > 0 ? options.field.params.precision : 2
                    }
                };
            }
        }
    },
    {
        type: SmartSuiteApplicationFieldType.AutoNumberField,
        mapping: { field: FieldType.Number }
    },
    {
        type: SmartSuiteApplicationFieldType.RatingField,
        mapping: {
            field: FieldType.Rating,
            getParams: function (options) {
                return {
                    max_value: options.field.params.max_value
                };
            }
        }
    },
    {
        type: SmartSuiteApplicationFieldType.NumberSliderField,
        mapping: {
            field: FieldType.Slider,
            getParams: function (options) {
                return {
                    min_value: options.field.params.min_value,
                    max_value: options.field.params.max_value,
                    step: options.field.params.value_increment
                };
            }
        }
    },
    {
        type: SmartSuiteApplicationFieldType.PercentCompleteField,
        mapping: {
            field: FieldType.Number,
            getParams: function (options) {
                return {
                    value_format: {
                        number_format: NumberValueFormat.Percentage,
                        number_fraction: options.field.params.precision > 0 ? options.field.params.precision : 2
                    }
                };
            }
        }
    },
    {
        type: SmartSuiteApplicationFieldType.YesNoField,
        mapping: { field: FieldType.Boolean }
    },
    {
        type: SmartSuiteApplicationFieldType.SingleSelectField,
        mapping: {
            field: FieldType.Select,
            getParams: function (options) {
                return __assign({ options_type: OptionsType.Static }, (options.field.params.choices && {
                    options: options.field.params.choices.map(function (item, i) {
                        return {
                            value: item.value,
                            name: item.label,
                            color: item.value_color
                        };
                    })
                }));
            }
        }
    },
    {
        type: SmartSuiteApplicationFieldType.MultipleSelectField,
        mapping: {
            field: FieldType.MultipleSelect,
            getParams: function (options) {
                return __assign({ options_type: OptionsType.Static }, (options.field.params.choices && {
                    options: options.field.params.choices.map(function (item, i) {
                        return {
                            value: item.value,
                            name: item.label,
                            color: item.value_color
                        };
                    })
                }));
            }
        }
    },
    {
        type: SmartSuiteApplicationFieldType.LinkedRecordField,
        mapping: {
            field: FieldType.RelatedModel,
            getField: function (options) {
                var _a, _b;
                var application = options.field.params.linked_application
                    ? options.applications.find(function (item) { return item.id == options.field.params.linked_application; })
                    : undefined;
                if (options.field.params.entries_allowed == 'multiple') {
                    return {
                        field: FieldType.MultipleSelect,
                        params: (_a = {},
                            _a[SOURCE_FIELD_PARAMS] = {
                                entries_allowed: options.field.params.entries_allowed
                            },
                            _a.output_format = MultipleSelectOutputFormat.Array,
                            _a.options_type = OptionsType.Query,
                            _a.resource = '{{resource}}',
                            _a.query = {
                                query_type: 'simple',
                                simple_query: {
                                    model: application.slug
                                }
                            },
                            _a.value_field = SMART_SUITE_ID_FIELD_NAME,
                            _a.label_field = application.primary_field,
                            _a)
                    };
                }
                else {
                    return {
                        field: FieldType.RelatedModel,
                        params: __assign((_b = {}, _b[SOURCE_FIELD_PARAMS] = {
                            entries_allowed: options.field.params.entries_allowed
                        }, _b), (application && {
                            related_model: {
                                model: "{{resource}}." + application.slug
                            },
                            custom_primary_key: SMART_SUITE_ID_FIELD_NAME,
                            custom_display_field: application.primary_field
                        }))
                    };
                }
            }
        }
    },
    {
        type: SmartSuiteApplicationFieldType.TagsField,
        mapping: {
            field: FieldType.MultipleSelect,
            getParams: function (options) {
                return __assign({ options_type: OptionsType.Static }, (options.field.params.choices && {
                    options: options.field.params.choices.map(function (item, i) {
                        return {
                            value: item.value,
                            name: item.label,
                            color: item.value_color
                        };
                    })
                }));
            }
        }
    },
    {
        type: SmartSuiteApplicationFieldType.StatusField,
        mapping: {
            field: FieldType.Select,
            getParams: function (options) {
                return __assign({ options_type: OptionsType.Static }, (options.field.params.choices && {
                    options: options.field.params.choices.map(function (item, i) {
                        return {
                            value: item.value,
                            name: item.label,
                            color: item.value_color
                        };
                    })
                }));
            }
        }
    },
    {
        type: SmartSuiteApplicationFieldType.FileField,
        mapping: {
            field: FieldType.File,
            params: { storage_resource: 'demo_rest_api', storage_name: 'temporary', multiple: true }
        }
    },
    {
        type: SmartSuiteApplicationFieldType.ColorPickerField,
        mapping: { field: FieldType.Color }
    },
    {
        type: SmartSuiteApplicationFieldType.UserField,
        mapping: {
            getField: function (options) {
                var _a, _b;
                if (options.field.params.entries_allowed == 'multiple') {
                    return {
                        field: FieldType.MultipleSelect,
                        params: (_a = {},
                            _a[SOURCE_FIELD_PARAMS] = {
                                entries_allowed: options.field.params.entries_allowed
                            },
                            _a.output_format = MultipleSelectOutputFormat.Array,
                            _a.options_type = OptionsType.Query,
                            _a.resource = '{{resource}}',
                            _a.query = {
                                query_type: 'simple',
                                simple_query: {
                                    model: SMART_SUITE_SYSTEM_APPLICATION_MEMBERS_SLUG
                                }
                            },
                            _a.value_field = SMART_SUITE_ID_FIELD_NAME,
                            _a.label_field = 'full_name',
                            _a)
                    };
                }
                else {
                    return {
                        field: FieldType.RelatedModel,
                        params: (_b = {},
                            _b[SOURCE_FIELD_PARAMS] = {
                                entries_allowed: options.field.params.entries_allowed
                            },
                            _b.related_model = {
                                model: "{{resource}}." + SMART_SUITE_SYSTEM_APPLICATION_MEMBERS_SLUG
                            },
                            _b.custom_primary_key = SMART_SUITE_ID_FIELD_NAME,
                            _b.custom_display_field = 'full_name',
                            _b)
                    };
                }
            }
        }
    },
    {
        type: SmartSuiteApplicationFieldType.FullNameField,
        mapping: { field: FieldType.Text }
    },
    {
        type: SmartSuiteApplicationFieldType.RecordTitleField,
        mapping: { field: FieldType.Text }
    },
    {
        type: SmartSuiteApplicationFieldType.AvailabilityStatusField,
        mapping: { field: FieldType.JSON }
    },
    {
        type: SmartSuiteApplicationFieldType.ChecklistField,
        mapping: {
            field: FieldType.MultipleSelect,
            getParams: function (options) {
                return __assign({ options_type: OptionsType.Static }, (options.field.params.choices && {
                    options: options.field.params.choices.map(function (item, i) {
                        return {
                            value: item.value,
                            name: item.label,
                            color: item.value_color
                        };
                    })
                }));
            }
        }
    },
    {
        type: SmartSuiteApplicationFieldType.CommentsCountField,
        mapping: { field: FieldType.Number }
    },
    {
        type: SmartSuiteApplicationFieldType.FirstCreatedField,
        mapping: {
            field: FieldType.JSON,
            params: {
                store_object: true,
                display_fields: true,
                structure: smartSuiteByStructure()
            }
        }
    },
    {
        type: SmartSuiteApplicationFieldType.LastUpdatedField,
        mapping: {
            field: FieldType.JSON,
            params: {
                store_object: true,
                display_fields: true,
                structure: smartSuiteByStructure()
            }
        }
    },
    {
        type: SmartSuiteApplicationFieldType.FormulaField,
        mapping: { field: FieldType.Text }
    },
    {
        type: SmartSuiteApplicationFieldType.CountField,
        mapping: { field: FieldType.Number }
    },
    {
        type: SmartSuiteApplicationFieldType.LookupField,
        mapping: {
            getField: function (options) {
                var _a, _b;
                var mapping = options.field.params.target_field_structure
                    ? smartSuiteFieldMapping.find(function (i) { return i.type == options.field.params.target_field_structure.field_type; })
                    : undefined;
                if (mapping) {
                    if (mapping.mapping.getField) {
                        return forceObservable(mapping.mapping.getField(options)).pipe(map(function (fieldWithParams) {
                            var _a;
                            return __assign({}, fieldWithParams, { params: __assign((_a = {}, _a[SOURCE_FIELD_PARAMS] = options.field.params, _a), fieldWithParams.params) });
                        }));
                    }
                    else if (mapping.mapping.getParams) {
                        return forceObservable(mapping.mapping.getParams(options)).pipe(map(function (params) {
                            var _a;
                            return {
                                field: mapping.mapping.field || FieldType.Text,
                                params: __assign((_a = {}, _a[SOURCE_FIELD_PARAMS] = options.field.params, _a), params)
                            };
                        }));
                    }
                    else {
                        return {
                            field: mapping.mapping.field || FieldType.Text,
                            params: __assign((_a = {}, _a[SOURCE_FIELD_PARAMS] = options.field.params, _a), mapping.mapping.params)
                        };
                    }
                }
                else {
                    return {
                        field: FieldType.Text,
                        params: (_b = {},
                            _b[SOURCE_FIELD_PARAMS] = options.field.params,
                            _b)
                    };
                }
            }
        }
    },
    {
        type: SmartSuiteApplicationFieldType.RollupField,
        mapping: { field: FieldType.Number }
    }
];
export var defaultSmartSuiteFieldMapping = {
    field: FieldType.Text
};
export { ɵ0, ɵ1 };
