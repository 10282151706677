import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import values from 'lodash/values';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { PopoverService } from '@common/popover';
import { PopupService } from '@common/popups';
import { ActionControllerService } from '@modules/action-queries';
import { UniversalAnalyticsService } from '@modules/analytics';
import {
  getModelAttributesByColumns,
  getModelBulkAttributesByColumns,
  KanbanBoardSettings,
  KanbanBoardStage,
  ListDefaultSelection,
  rawListViewSettingsColumnsToViewContextOutputs,
  ViewContextOutput,
  ViewSettingsService,
  ViewSettingsStore
} from '@modules/customize';
import { CustomizeBarContext, CustomizeBarService } from '@modules/customize-bar';
import { DataSourceType } from '@modules/data-sources';
import {
  applyParamInput$,
  applyParamInputs$,
  DisplayField,
  DisplayFieldType,
  FieldType,
  getFieldDescriptionByType,
  LOADING_VALUE,
  NOT_SET_VALUE
} from '@modules/fields';
import { EMPTY_FILTER_VALUES, FilterItem2, Sort } from '@modules/filters';
import { ListLayoutType } from '@modules/layouts';
import {
  AFTER_ITEM_OUTPUT,
  BEFORE_ITEM_OUTPUT,
  CHECKED_ITEMS_OUTPUT,
  COLUMN_FROM_OUTPUT,
  COLUMN_OUTPUT,
  COLUMN_TO_OUTPUT,
  EMPTY_OUTPUT,
  HAS_SELECTED_ITEM_OUTPUT,
  ITEM_OUTPUT,
  ListItem,
  NO_SELECTED_ITEM_OUTPUT,
  ORDER_OUTPUT,
  PREV_AFTER_ITEM_OUTPUT,
  PREV_BEFORE_ITEM_OUTPUT,
  PREV_COLUMN_OUTPUT,
  PREV_ORDER_OUTPUT,
  SELECTED_ITEM_OUTPUT
} from '@modules/list';
import { ListLayoutComponent } from '@modules/list-components';
import { MenuSettingsStore } from '@modules/menu';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { Model, ModelDescription, NEXT_PAGE_SCROLL_PARAM, PAGE_PARAM } from '@modules/models';
import { InputService } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { GetQueryOptions } from '@modules/resources';
import { RoutingService } from '@modules/routing';
import { isSet } from '@shared';

import { KanbanBoardColumnComponent } from '../kanban-board-column/kanban-board-column.component';
import {
  getBaseStateFetch,
  getListStateColumns,
  getListStateDefaultSelection,
  getListStateFetchNewParams,
  KanbanBoardState
} from './kanban-board-state';

export interface ItemOrdering {
  columnIndex: number;
  beforeItem: Model;
  afterItem: Model;
}

export function getListStateFetch(state: KanbanBoardState): Object {
  return {
    ...getBaseStateFetch(state),
    stageField: state.stageField,
    stages: state.stages ? state.stages.map(item => ({ name: item.name })) : undefined
  };
}

function getListStateSelection(state: KanbanBoardState): Object {
  return {
    multipleSelection: state.multipleSelection
  };
}

@Component({
  selector: 'app-kanban-board',
  templateUrl: './kanban-board.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KanbanBoardComponent extends ListLayoutComponent<KanbanBoardSettings, KanbanBoardState>
  implements OnInit, OnDestroy, OnChanges {
  @ViewChildren(KanbanBoardColumnComponent) columnComponents = new QueryList<KanbanBoardColumnComponent>();

  layout = ListLayoutType.KanbanBoard;
  visibleColumns: DisplayField[] = [];
  loading = true;
  configured = true;
  selectedItem: ListItem;
  checkedItems: { [k: string]: Model } = {};
  itemMove: { model: Model; fromStage: number; fromOrdering: ItemOrdering; toStage: number; toOrdering: ItemOrdering };

  constructor(
    private modelService: ModelService,
    private actionControllerService: ActionControllerService,
    private notificationService: NotificationService,
    injector: Injector,
    cd: ChangeDetectorRef,
    customizeBarContext: CustomizeBarContext,
    customizeBarService: CustomizeBarService,
    analyticsService: UniversalAnalyticsService,
    viewSettingsService: ViewSettingsService,
    viewSettingsStore: ViewSettingsStore,
    menuSettingsStore: MenuSettingsStore,
    modelDescriptionStore: ModelDescriptionStore,
    inputService: InputService,
    routing: RoutingService,
    currentProjectStore: CurrentProjectStore,
    currentEnvironmentStore: CurrentEnvironmentStore,
    popupService: PopupService,
    popoverService: PopoverService
  ) {
    super(
      injector,
      cd,
      customizeBarContext,
      customizeBarService,
      analyticsService,
      viewSettingsService,
      viewSettingsStore,
      menuSettingsStore,
      modelDescriptionStore,
      inputService,
      routing,
      currentProjectStore,
      currentEnvironmentStore,
      popupService,
      popoverService
    );
  }

  ngOnInit() {
    super.ngOnInit();

    this.initContext();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  getListState(
    settings: KanbanBoardSettings,
    params: Object,
    filters: FilterItem2[],
    search: string,
    sort: Sort[]
  ): Observable<KanbanBoardState> {
    params = cloneDeep(params);

    delete params[PAGE_PARAM];
    delete params[NEXT_PAGE_SCROLL_PARAM];

    if (!sort.length && isSet(settings.sortingField)) {
      sort = [{ field: settings.sortingField, desc: !settings.sortingAsc }];
    }

    const staticData$ =
      settings.dataSource && settings.dataSource.type == DataSourceType.Input && settings.dataSource.input
        ? applyParamInput$<Object[]>(settings.dataSource.input, {
            context: this.context,
            defaultValue: [],
            handleLoading: true,
            ignoreEmpty: true
          }).pipe(distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)))
        : of([]);
    const inputParams$ = settings.dataSource
      ? applyParamInputs$({}, settings.dataSource.queryInputs, {
          context: this.context,
          parameters: settings.dataSource.queryParameters,
          handleLoading: true,
          ignoreEmpty: true,
          emptyValues: EMPTY_FILTER_VALUES
        }).pipe(distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)))
      : of({});

    return combineLatest(staticData$, inputParams$, this.getQueryModelDescription(settings.dataSource)).pipe(
      map(([staticData, inputParams, modelDescription]) => {
        const resource = settings.dataSource
          ? this.currentEnvironmentStore.resources.find(item => item.uniqueName == settings.dataSource.queryResource)
          : undefined;

        return {
          settings: settings,
          dataSource: settings.dataSource,
          dataSourceStaticData: staticData,
          dataSourceParams: {
            ...inputParams,
            ...params
          },
          userParams: params,
          filters: filters,
          search: search,
          sort: sort,
          resource: resource,
          modelDescription: modelDescription,
          inputsLoading: [inputParams, staticData].some(obj => {
            return obj == LOADING_VALUE || values(obj).some(item => item === LOADING_VALUE);
          }),
          inputsNotSet: [inputParams, staticData].some(obj => {
            return obj == NOT_SET_VALUE || values(obj).some(item => item === NOT_SET_VALUE);
          }),
          perPage: settings ? settings.perPage : undefined,
          stageField: settings ? settings.stageField : undefined,
          stages: settings ? settings.stages : [],
          sortingField: settings ? settings.sortingField : undefined,
          sortingAsc: settings ? settings.sortingAsc : undefined,
          multipleSelection: settings.multipleSelection,
          defaultSelection: settings ? settings.defaultSelection : undefined
        };
      })
    );
  }

  onStateUpdated(state: KanbanBoardState) {
    if (
      !isEqual(getListStateColumns(state), getListStateColumns(this.listState)) ||
      !isEqual(getListStateSelection(state), getListStateSelection(this.listState))
    ) {
      this.updateContextOutputs(state);
    }

    if (!isEqual(getListStateColumns(state), getListStateColumns(this.listState))) {
      this.updateVisibleColumns(state);
    }

    if (!isEqual(getListStateFetch(state), getListStateFetch(this.listState))) {
      const newParams = !isEqual(getListStateFetchNewParams(state), getListStateFetchNewParams(this.listState));

      if (newParams) {
        this.setSelectedItem(undefined, false);
        this.setChecked([], false);
        this.updateSelectedContext();
      }

      this.fetch(state);
    } else {
      if (
        !isEqual(getListStateDefaultSelection(state), getListStateDefaultSelection(this.listState)) &&
        state.defaultSelection == ListDefaultSelection.First
      ) {
        const component = this.columnComponents.toArray()[0];
        const componentItems = component ? component.getItems() : undefined;
        const firstItem = componentItems ? componentItems[0] : undefined;

        this.setSelectedItem(firstItem, false);
        this.setChecked(firstItem ? [firstItem.model] : [], false);
        this.updateSelectedContext();
      }
    }
  }

  getStateQueryOptions(state: KanbanBoardState, extraParams = {}): GetQueryOptions {
    const component = this.columnComponents.toArray()[0];
    return component ? component.getBaseStateQueryOptions() : {};
  }

  fetch(state: KanbanBoardState) {
    this.configured = state.dataSource && state.dataSource.isConfigured() && state.settings.isConfigured();
    this.parameters = this.getParameters(state);
    this.inputs = this.getInputs(state);
    this.selectedItem = undefined;
    this.loading = false;
    this.cd.markForCheck();
  }

  onFetch() {
    this.contextElement.setOutputValue(EMPTY_OUTPUT, false, { loading: true });
  }

  onFetched() {
    const loading = this.columnComponents.toArray().some(item => item.loading);

    if (!loading) {
      const isEmpty = this.columnComponents.toArray().every(item => {
        const groupItems = item.getItems();
        return groupItems && !groupItems.length;
      });
      this.contextElement.setOutputValue(EMPTY_OUTPUT, isEmpty, { loading: false });
    }
  }

  reloadData() {
    super.reloadData();
    this.columnComponents.forEach(item => item.reloadData());
  }

  getStageName(index: number): string {
    return [this.viewId, 'stage', index].join('_');
  }

  getStageCount(index: number): number {
    const component = this.columnComponents.toArray()[index];
    return component ? component.getCount() : undefined;
  }

  setStageCount(index: number, value: number) {
    const component = this.columnComponents.toArray()[index];
    if (component) {
      component.setCount(value);
    }
  }

  get droppable(): string[] {
    if (!this.listState.stages) {
      return [];
    }

    return this.listState.stages.map((item, i) => this.getStageName(i));
  }

  onItemAdded(i: number, item: ListItem) {
    this.itemMove.toStage = i;
  }

  getItemOrdering(stage: number, item: ListItem): ItemOrdering {
    const items = this.columnComponents.toArray()[stage].dropList.data;
    const itemIndex = items.findIndex(i => i === item);

    return {
      columnIndex: itemIndex,
      beforeItem: items[itemIndex + 1] ? items[itemIndex + 1].model : undefined,
      afterItem: items[itemIndex - 1] ? items[itemIndex - 1].model : undefined
    };
  }

  onDragStarted(i: number, item: ListItem) {
    const fromOrdering = this.getItemOrdering(i, item);

    this.itemMove = {
      model: item.model,
      fromStage: i,
      fromOrdering: fromOrdering,
      toStage: i,
      toOrdering: fromOrdering
    };
  }

  onDragFinished(i, item: ListItem) {
    if (!this.itemMove) {
      return;
    }

    const fromStageCount = this.getStageCount(this.itemMove.fromStage);
    if (fromStageCount !== undefined) {
      this.setStageCount(this.itemMove.fromStage, fromStageCount - 1);
    }

    const toStageCount = this.getStageCount(this.itemMove.toStage);
    if (toStageCount !== undefined) {
      this.setStageCount(this.itemMove.toStage, toStageCount + 1);
    }

    this.itemMove.toOrdering = this.getItemOrdering(this.itemMove.toStage, item);

    this.changeStageIfNeeded();
    this.reorderIfNeeded();

    this.itemMove = undefined;
  }

  changeStageIfNeeded() {
    if (!this.itemMove) {
      return;
    }

    if (this.itemMove.toStage === this.itemMove.fromStage) {
      return;
    }

    const stageColumn = this.settings.dataSource.columns.find(item => item.name == this.settings.stageField);
    const stages = this.listState.stages || [];
    let toStage = stages[this.itemMove.toStage].value;
    let fromStage = stages[this.itemMove.fromStage].value;

    const fieldDescription = getFieldDescriptionByType(stageColumn ? stageColumn.field : undefined);

    if (fieldDescription.deserializeValue) {
      toStage = fieldDescription.deserializeValue(toStage, stageColumn);
      fromStage = fieldDescription.deserializeValue(fromStage, stageColumn);
    }

    // const model = this.itemMove.model;
    //
    // if (model.getAttribute(this.settings.stageField) != stage.value) {
    //   model.setAttribute(this.settings.stageField, stage.value);
    //
    //   this.modelService.update(this.params.modelDescription.modelId, model, [this.settings.stageField])
    //     .pipe(untilDestroyed(this))
    //     .subscribe();
    // }

    if (this.settings.cardColumnChangeAction) {
      const model = this.itemMove.model;

      this.actionControllerService
        .execute(this.settings.cardColumnChangeAction, {
          context: this.contextElement.context,
          contextElement: this.contextElement,
          localContext: {
            [COLUMN_FROM_OUTPUT]: fromStage,
            [COLUMN_TO_OUTPUT]: toStage,
            [ITEM_OUTPUT]: model.getAttributes()
          },
          injector: this.injector
        })
        .subscribe();
    }
  }

  reorderIfNeeded() {
    if (!this.itemMove) {
      return;
    }

    if (
      this.itemMove.toStage === this.itemMove.fromStage &&
      this.itemMove.toOrdering.columnIndex === this.itemMove.fromOrdering.columnIndex
    ) {
      return;
    }

    const stageColumn = this.settings.dataSource.columns.find(item => item.name == this.settings.stageField);
    const stages = this.listState.stages || [];
    let toStage = stages[this.itemMove.toStage].value;
    let fromStage = stages[this.itemMove.fromStage].value;

    const fieldDescription = getFieldDescriptionByType(stageColumn ? stageColumn.field : undefined);

    if (fieldDescription.deserializeValue) {
      toStage = fieldDescription.deserializeValue(toStage, stageColumn);
      fromStage = fieldDescription.deserializeValue(fromStage, stageColumn);
    }

    // const ordering = [
    //   this.params.modelDescription.orderingField,
    //   this.params.modelDescription.primaryKeyField
    // ].join(',');
    // const valueOrderingValues = this.viewSettings.kanbanBoardSettings.stages.map(stage => stage.value).join(',');
    // const valueOrdering = `${this.viewSettings.kanbanBoardSettings.stageField}-${valueOrderingValues}`;
    //
    // this.modelService.resetOrder(
    //   this.params.modelDescription.modelId,
    //   ordering,
    //   valueOrdering
    // )
    //   .pipe(
    //     switchMap(() => {
    //       if (move.toOrdering == move.fromOrdering) {
    //         return of(false);
    //       }
    //
    //       let forward, segmentFrom, segmentTo;
    //
    //       if (this.itemMove.toOrdering >= this.itemMove.fromOrdering) {
    //         forward = true;
    //         segmentFrom = this.itemMove.fromOrdering + 1;
    //         segmentTo = this.itemMove.toOrdering;
    //       } else {
    //         forward = false;
    //         segmentFrom = this.itemMove.toOrdering;
    //         segmentTo = this.itemMove.fromOrdering - 1;
    //       }
    //
    //       return this.modelService.reorder(
    //         this.params.modelDescription.modelId,
    //         forward,
    //         segmentFrom,
    //         segmentTo,
    //         move.model.primaryKey,
    //         true
    //       );
    //     }),
    //     untilDestroyed(this)
    //   )
    //   .subscribe();

    if (this.settings.cardOrderChangeAction) {
      const model = this.itemMove.model;

      this.actionControllerService
        .execute(this.settings.cardOrderChangeAction, {
          context: this.contextElement.context,
          contextElement: this.contextElement,
          localContext: {
            [ORDER_OUTPUT]: this.itemMove.toOrdering.columnIndex,
            [BEFORE_ITEM_OUTPUT]: this.itemMove.toOrdering.beforeItem
              ? this.itemMove.toOrdering.beforeItem.getAttributes()
              : undefined,
            [AFTER_ITEM_OUTPUT]: this.itemMove.toOrdering.afterItem
              ? this.itemMove.toOrdering.afterItem.getAttributes()
              : undefined,
            [COLUMN_OUTPUT]: toStage,
            [PREV_ORDER_OUTPUT]: this.itemMove.fromOrdering.columnIndex,
            [PREV_BEFORE_ITEM_OUTPUT]: this.itemMove.fromOrdering.beforeItem
              ? this.itemMove.fromOrdering.beforeItem.getAttributes()
              : undefined,
            [PREV_AFTER_ITEM_OUTPUT]: this.itemMove.fromOrdering.afterItem
              ? this.itemMove.fromOrdering.afterItem.getAttributes()
              : undefined,
            [PREV_COLUMN_OUTPUT]: fromStage,
            [ITEM_OUTPUT]: model.getAttributes()
          },
          injector: this.injector
        })
        .subscribe();
    }
  }

  get isAvailable(): boolean {
    if (!this.resource || !this.resource.apiInfo) {
      return true;
    }

    const apiInfo = this.resource.apiInfo;
    return apiInfo.isCompatibleJetBridge({ jetBridge: '0.1.5', jetDjango: '0.4.8' }) || apiInfo.customType;
  }

  initContext() {
    this.contextElement.setActions([
      {
        uniqueName: 'update_data',
        name: 'Update Data',
        icon: 'repeat',
        parameters: [],
        handler: () => this.reloadData()
      },
      {
        uniqueName: 'clear_selected_item',
        name: 'Reset Selected Card',
        icon: 'deselect',
        parameters: [],
        handler: () => {
          this.setSelectedItem(undefined);
          this.setChecked([]);
        }
      },
      {
        uniqueName: 'clear_filters',
        name: 'Reset Filters',
        icon: 'delete',
        parameters: [],
        handler: () => this.resetParams()
      }
    ]);
  }

  updateContextOutputs(state: KanbanBoardState) {
    const stageColumn = state.dataSource
      ? state.dataSource.columns.find(item => item.name == state.settings.stageField)
      : undefined;
    const columnOutputs = (modelDescription: ModelDescription, ignoreFlex?: boolean): ViewContextOutput[] => {
      if (!state.dataSource) {
        return [];
      }

      return rawListViewSettingsColumnsToViewContextOutputs(
        state.dataSource.columns.filter(item => !ignoreFlex || (ignoreFlex && item.type != DisplayFieldType.Computed)),
        modelDescription
      );
    };

    this.contextElement.setOutputs([
      {
        uniqueName: COLUMN_FROM_OUTPUT,
        name: 'Move from Column',
        icon: 'undo',
        internal: true,
        byPathOnly: true,
        ...(stageColumn && {
          fieldType: stageColumn.field,
          fieldParams: stageColumn.params
        })
      },
      {
        uniqueName: COLUMN_TO_OUTPUT,
        name: 'Move to Column',
        icon: 'redo',
        internal: true,
        byPathOnly: true,
        ...(stageColumn && {
          fieldType: stageColumn.field,
          fieldParams: stageColumn.params
        })
      },
      {
        uniqueName: ORDER_OUTPUT,
        name: 'Move to Order (1...)',
        icon: 'filter_down',
        internal: true,
        byPathOnly: true,
        fieldType: FieldType.Number
      },
      {
        uniqueName: BEFORE_ITEM_OUTPUT,
        name: 'Move before Card',
        icon: 'arrow_down',
        internal: true,
        byPathOnly: true,
        children: columnOutputs(state.modelDescription)
      },
      {
        uniqueName: AFTER_ITEM_OUTPUT,
        name: 'Move after Card',
        icon: 'arrow_up',
        internal: true,
        byPathOnly: true,
        children: columnOutputs(state.modelDescription)
      },
      {
        uniqueName: COLUMN_OUTPUT,
        name: 'Move to Column',
        icon: 'versions',
        internal: true,
        byPathOnly: true,
        ...(stageColumn && {
          fieldType: stageColumn.field,
          fieldParams: stageColumn.params
        })
      },
      {
        uniqueName: PREV_ORDER_OUTPUT,
        name: 'Was with Order (1...)',
        icon: 'filter_down',
        internal: true,
        byPathOnly: true,
        fieldType: FieldType.Number
      },
      {
        uniqueName: PREV_BEFORE_ITEM_OUTPUT,
        name: 'Was before Card',
        icon: 'arrow_down',
        internal: true,
        byPathOnly: true,
        children: columnOutputs(state.modelDescription)
      },
      {
        uniqueName: PREV_AFTER_ITEM_OUTPUT,
        name: 'Was after Card',
        icon: 'arrow_up',
        internal: true,
        byPathOnly: true,
        children: columnOutputs(state.modelDescription)
      },
      {
        uniqueName: PREV_COLUMN_OUTPUT,
        name: 'Was in Column',
        icon: 'versions',
        internal: true,
        byPathOnly: true,
        ...(stageColumn && {
          fieldType: stageColumn.field,
          fieldParams: stageColumn.params
        })
      },
      {
        uniqueName: ITEM_OUTPUT,
        name: 'Current Card',
        icon: 'duplicate_2',
        internal: true,
        byPathOnly: true,
        allowSkip: true,
        children: columnOutputs(state.modelDescription, true)
      },
      {
        uniqueName: SELECTED_ITEM_OUTPUT,
        name: 'Selected Card',
        icon: 'hand',
        children: columnOutputs(state.modelDescription)
      },
      {
        uniqueName: HAS_SELECTED_ITEM_OUTPUT,
        name: 'Is any Card selected',
        icon: 'select_all',
        fieldType: FieldType.Boolean,
        defaultValue: false
      },
      {
        uniqueName: NO_SELECTED_ITEM_OUTPUT,
        name: 'No Card selected',
        icon: 'deselect',
        fieldType: FieldType.Boolean,
        defaultValue: true
      },
      {
        uniqueName: EMPTY_OUTPUT,
        name: 'Is Empty',
        icon: 'uncheck',
        fieldType: FieldType.Boolean,
        defaultValue: false
      },
      ...(state.multipleSelection
        ? [
            {
              uniqueName: CHECKED_ITEMS_OUTPUT,
              name: 'Checked Cards',
              icon: 'check',
              children: columnOutputs(state.modelDescription)
            }
          ]
        : [])
    ]);

    this.updateSelectedContext();
  }

  updateVisibleColumns(state: KanbanBoardState) {
    this.visibleColumns = state.dataSource.columns.filter(item => item.visible);
    this.cd.markForCheck();
  }

  updateSelectedContext() {
    const columns = this.settings.dataSource ? this.settings.dataSource.columns : [];

    if (this.selectedItem) {
      this.contextElement.setOutputValue(
        SELECTED_ITEM_OUTPUT,
        getModelAttributesByColumns(this.selectedItem.model, columns)
      );
      this.contextElement.setOutputValue(HAS_SELECTED_ITEM_OUTPUT, true);
      this.contextElement.setOutputValue(NO_SELECTED_ITEM_OUTPUT, false);
    } else {
      this.contextElement.setOutputValue(SELECTED_ITEM_OUTPUT, undefined);
      this.contextElement.setOutputValue(HAS_SELECTED_ITEM_OUTPUT, false);
      this.contextElement.setOutputValue(NO_SELECTED_ITEM_OUTPUT, true);
    }

    if (this.settings.multipleSelection) {
      const models: Model[] = values(this.checkedItems);
      this.contextElement.setOutputValue(CHECKED_ITEMS_OUTPUT, getModelBulkAttributesByColumns(models, columns));
    } else {
      this.contextElement.setOutputValue(CHECKED_ITEMS_OUTPUT, getModelBulkAttributesByColumns([], columns));
    }
  }

  setSelectedItem(item: ListItem, updateContext = true) {
    this.selectedItem = item;
    this.cd.markForCheck();

    if (updateContext) {
      this.updateSelectedContext();
    }
  }

  setChecked(value: Model[], updateContext = true) {
    this.checkedItems = value.reduce((acc, item) => {
      const pk = item.primaryKey;
      acc[pk] = item.model;
      return acc;
    }, {});
    this.cd.markForCheck();

    if (updateContext) {
      this.updateSelectedContext();
    }
  }

  onModelUpdated(model: Model) {
    if (this.selectedItem && this.selectedItem.model.isSame(model)) {
      this.updateSelectedContext();
    }

    const checkedModels: Model[] = values(this.checkedItems);

    if (checkedModels.some(item => item.isSame(model))) {
      this.updateSelectedContext();
    }
  }

  trackStageFn(i, item: KanbanBoardStage) {
    return item.uid;
  }

  public getAnyModel(): Model {
    for (const component of this.columnComponents.toArray()) {
      const items = component.getItems();
      if (items && items.length) {
        return items[0].model;
      }
    }
  }
}
