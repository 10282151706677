import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { NotificationService } from '@common/notifications';
import { ServerRequestError } from '@modules/api';
import { TintStyle } from '@modules/customize';
import { defaultSignUpLayout, Domain } from '@modules/domain';
import { MetaService } from '@modules/meta';
import { RoutingService } from '@modules/routing';
import { ThemeContext } from '@modules/theme-components';
import { CurrentUserStore, UserService } from '@modules/users';
import { isSet } from '@shared';

@Component({
  selector: 'app-change-email-complete',
  templateUrl: './change-email-complete.component.html',
  providers: [ThemeContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeEmailCompleteComponent implements OnInit, OnDestroy {
  loading = false;
  error: string;
  domain: Domain;
  domainLoading = false;
  defaultLayout = defaultSignUpLayout;
  externalFonts: string[] = [];
  tintStyles = TintStyle;

  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private currentUserStore: CurrentUserStore,
    private notificationService: NotificationService,
    private routing: RoutingService,
    private metaService: MetaService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.metaService.set({ title: 'Email change' });

    this.domainLoading = true;
    this.cd.markForCheck();

    this.activatedRoute.data.pipe(untilDestroyed(this)).subscribe(
      data => {
        this.domain = data.domain;
        this.externalFonts = this.getExternalFonts(this.domain);
        this.domainLoading = false;
        this.cd.markForCheck();
      },
      () => {
        this.domainLoading = false;
        this.cd.markForCheck();
      }
    );

    this.confirmEmailChange();
  }

  confirmEmailChange() {
    const code = this.activatedRoute.snapshot.params['code'];

    this.loading = true;
    this.cd.markForCheck();

    this.userService
      .changeEmailConfirm(code)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.currentUserStore.getFirst(true);

          this.notificationService.success(
            'Email changed',
            `Email was successfully changed from ${result.oldEmail} to ${result.newEmail}`
          );

          this.routing.navigate(this.homeLink);
        },
        error => {
          if (error instanceof ServerRequestError && error.errors.length) {
            this.error = error.errors[0];
          } else {
            this.error = String(error);
          }

          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  ngOnDestroy(): void {}

  getExternalFonts(domain: Domain): string[] {
    if (!domain) {
      return [];
    }

    return [domain.signUpFontRegular, domain.signUpFontHeading].filter(item => isSet(item));
  }

  get layout() {
    return this.domain && isSet(this.domain.signUpLayout) ? this.domain.signUpLayout : this.defaultLayout;
  }

  get isWhiteLabel() {
    return this.domain && this.domain.whiteLabel;
  }

  get homeLink() {
    return ['/'];
  }
}
