import { Injectable, OnDestroy } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import range from 'lodash/range';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { ActionStore } from '@modules/action-queries';
import {
  ElementItem,
  processElementItemPageSegues,
  processElementItemResources,
  traverseElementItemQueries,
  ViewSettingsStore
} from '@modules/customize';
import { Option } from '@modules/field-components';
import { ButtonMenuItem, MenuItem, MenuSettingsStore, SectionMenuItem, SimpleMenuItem } from '@modules/menu';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { ModelDescription, PER_PAGE_PARAM } from '@modules/models';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  isResourceTypeItemReplicable,
  Resource,
  ResourceName,
  ResourceTypeItem
} from '@modules/projects';
import {
  ActionQuery,
  ChartWidgetQuery,
  ModelDescriptionQuery,
  Query,
  QueryType,
  ValueWidgetQuery
} from '@modules/queries';
import { ResourceGeneratorResolver } from '@modules/resource-generators';
import {
  Template,
  TemplateImage,
  TemplateService,
  TemplateTag,
  TemplateType,
  TemplateUsedResource
} from '@modules/template';

function validateType(): ValidatorFn {
  return (control: FormGroup) => {
    if (control.value['type'] == TemplateType.Widget && control.value['update_element'] && !control.value['element']) {
      return { local: ['No element selected'] };
    }

    if (control.value['type'] == TemplateType.Page && (!control.value['pages'] || !control.value['pages'].length)) {
      return { local: ['No pages selected'] };
    }
  };
}

interface PageUsedResource extends TemplateUsedResource {
  uniqueName?: string;
  resource: Resource;
}

@Injectable()
export class TemplatesItemForm extends FormGroup implements OnDestroy {
  controls: {
    type: FormControl;
    name: FormControl;
    subtitle: FormControl;
    description: FormControl;
    logo: FormControl;
    logo_fill: FormControl;
    icon: FormControl;
    color: FormControl;
    featured: FormControl;
    images: FormArray;
    tags: FormArray;
    // resource_type_items: FormControl;
    pages: FormControl;
    update_element: FormControl;
    element: FormControl;
    active: FormControl;
    ordering: FormControl;
  };

  template: Template;
  typeOptions: Option[] = [
    {
      value: TemplateType.Widget,
      name: 'Component'
    },
    {
      value: TemplateType.Page,
      name: 'Pages'
    },
    {
      value: TemplateType.ResourceModelDescriptionsTemplate,
      name: 'Data'
    }
  ];
  uploaderResourceName = 'jet_templates_uploader';
  uploaderResourceStorageName = 'jetadmin';

  constructor(
    private templateService: TemplateService,
    private modelDescriptionStore: ModelDescriptionStore,
    private modelService: ModelService,
    private actionStore: ActionStore,
    private viewSettingsStore: ViewSettingsStore,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private menuSettingsStore: MenuSettingsStore,
    private resourceGeneratorResolver: ResourceGeneratorResolver,
    private formUtils: FormUtils
  ) {
    super(
      {
        type: new FormControl('', Validators.required),
        name: new FormControl('', Validators.required),
        subtitle: new FormControl(''),
        description: new FormControl(''),
        logo: new FormControl(''),
        logo_fill: new FormControl(false),
        icon: new FormControl(''),
        color: new FormControl(''),
        featured: new FormControl(false),
        images: new FormArray([]),
        tags: new FormArray([]),
        // resource_type_items: new FormControl([]),
        pages: new FormControl(''),
        update_element: new FormControl(true),
        element: new FormControl(''),
        active: new FormControl(false),
        ordering: new FormControl(0)
      },
      validateType()
    );
  }

  initElement(element: ElementItem) {
    this.controls.element.patchValue(element);
    console.log('element', element);
  }

  ngOnDestroy(): void {}

  init(value: Template) {
    this.template = value;

    if (!value) {
      return;
    }

    this.patchTemplate(value);
  }

  patchTemplate(value: Template) {
    this.controls.type.patchValue(value.type);
    this.controls.name.patchValue(value.name);
    this.controls.subtitle.patchValue(value.subtitle);
    this.controls.description.patchValue(value.description);
    this.controls.logo.patchValue(value.logo);
    this.controls.logo_fill.patchValue(value.logoFill);
    this.controls.icon.patchValue(value.icon);
    this.controls.color.patchValue(value.color);
    this.controls.featured.patchValue(value.featured);
    // this.controls.resource_type_items.patchValue(value.resourceTypeItems.map(item => item.name));
    this.controls.pages.patchValue(value.pages.map(item => item.uniqueName));
    this.controls.active.patchValue(value.active);
    this.controls.ordering.patchValue(value.ordering);

    this.arraySet(
      'tags',
      value.tags.map(item =>
        this.createTagItem({
          name: item.name,
          color: item.color
        })
      )
    );

    this.arraySet(
      'images',
      value.images.map(item => {
        let prefix = '';
        const uploaderResource = this.getUploaderResource();

        if (uploaderResource) {
          prefix = uploaderResource.mediaUrlTemplate.replace('{}', '');
        }

        return this.createImageItem({
          image: item.image ? item.image.replace(prefix, '') : item.image
        });
      })
    );
  }

  getUploaderResource() {
    return this.currentEnvironmentStore.resources.find(item => item.name == this.uploaderResourceName);
  }

  getUploaderResourceStorage() {
    const result = this.getUploaderResource();
    if (!result) {
      return;
    }
    return result.storages.find(item => item.uniqueName == this.uploaderResourceStorageName);
  }

  arrayControls(name) {
    return (this.controls[name] as FormArray).controls;
  }

  arraySet(name, groups) {
    const array = this.controls[name] as FormArray;

    range(array.controls.length).forEach(() => array.removeAt(0));
    groups.forEach(item => array.push(item));
    array.updateValueAndValidity();
  }

  arrayAppend(name, group) {
    const array = this.controls[name] as FormArray;
    array.push(group);
    array.updateValueAndValidity();
  }

  arrayRemove(name, group) {
    const array = this.controls[name] as FormArray;
    const index = array.controls.findIndex(item => item === group);

    if (index == -1) {
      return;
    }

    array.removeAt(index);
    array.updateValueAndValidity();
  }

  createTagItem(value?: Object): FormGroup {
    const form = new FormGroup({
      name: new FormControl('', Validators.required),
      color: new FormControl('')
    });

    if (value) {
      form.patchValue(value);
    }

    return form;
  }

  createImageItem(value?: Object): FormGroup {
    const form = new FormGroup({
      image: new FormControl('', Validators.required)
    });

    if (value) {
      form.patchValue(value);
    }

    return form;
  }

  prepareUsedResourceModel(
    modelDescription: ModelDescription
  ): Observable<{ modelDescription: ModelDescription; instances: Object[] }> {
    const project = this.currentProjectStore.instance;
    const environment = this.currentEnvironmentStore.instance;
    const params = { [PER_PAGE_PARAM]: 100 };

    return this.modelService.getAll(project, environment, modelDescription.modelId, params).pipe(
      map(result => {
        return {
          modelDescription: modelDescription,
          instances: result.map(item => item.serialize())
        };
      })
    );
  }

  prepareUsedResource(item: PageUsedResource): Observable<TemplateUsedResource> {
    const project = this.currentProjectStore.instance;
    const environment = this.currentEnvironmentStore.instance;
    const generator = this.resourceGeneratorResolver.get(item.typeItem.name);

    const options$ =
      generator && item.typeItem.name != ResourceName.JetDatabase
        ? generator.getParamsOptions(project, environment, item.resource).pipe(
            map(options => {
              const result = { ...options };

              if (generator.tokenRequired && options.hasOwnProperty('token')) {
                result['token'] = undefined;
              }

              return result;
            })
          )
        : of({});
    const models$ = item.models
      ? combineLatest(item.models.map(model => this.prepareUsedResourceModel(model.modelDescription)))
      : of(undefined);

    return combineLatest(options$, models$).pipe(
      map(([options, models]) => {
        return {
          type: item.type,
          typeItem: item.typeItem,
          token: item.token,
          options: options,
          models: models
            ? models.map(model => {
                const templateModelDescription = cloneDeep(model.modelDescription) as ModelDescription;
                templateModelDescription.resource = item.token;

                return {
                  ...model,
                  modelDescription: templateModelDescription
                };
              })
            : undefined
        };
      })
    );
  }

  setUsedResources(instance: Template): Observable<Template> {
    const modelDescriptions = this.modelDescriptionStore.instance;
    const actionsDescriptions = this.actionStore.instance;
    const usedResources: PageUsedResource[] = [];
    const forResources: ResourceTypeItem[] = [];

    const processResource = (resourceName: string): string => {
      if (usedResources.find(item => item.token == resourceName)) {
        return resourceName;
      }

      const resource = this.currentEnvironmentStore.resources.find(item => item.uniqueName == resourceName);

      if (!resource) {
        return resourceName;
      }

      if (!forResources.find(item => item.name == resource.typeItem.name)) {
        forResources.push(resource.typeItem);
      }

      if (resource.demo && !resource.isSimpleIntegration()) {
        return resourceName;
      }

      let usedResource = usedResources.find(item => item.uniqueName == resource.uniqueName);

      if (!usedResource) {
        const number = usedResources.length + 1;
        const token = `{{TEMPLATE_RESOURCE_${number}}}`;

        usedResource = {
          type: resource.type,
          typeItem: resource.typeItem,
          token: token,
          models: isResourceTypeItemReplicable(resource.typeItem) ? [] : undefined,
          uniqueName: resource.uniqueName,
          resource: resource
        };
        usedResources.push(usedResource);
      }

      return usedResource.token;
    };

    const processQuery = (resourceName: string, query: Query) => {
      const usedResource = usedResources.find(item => item.token == resourceName);

      if (!usedResource || !isResourceTypeItemReplicable(usedResource.typeItem)) {
        return;
      }

      if (
        (query instanceof ModelDescriptionQuery ||
          query instanceof ValueWidgetQuery ||
          query instanceof ChartWidgetQuery) &&
        query.queryType == QueryType.Simple &&
        query.simpleQuery
      ) {
        const modelDescription = modelDescriptions.find(
          item => item.resource == usedResource.uniqueName && item.model == query.simpleQuery.model
        );

        if (modelDescription && !usedResource.models.find(item => item.modelDescription.isSame(modelDescription))) {
          usedResource.models.push({
            modelDescription: modelDescription,
            instances: []
          });
        }
      } else if (query instanceof ActionQuery && query.queryType == QueryType.Simple && query.simpleQuery) {
        const actionsDescription = actionsDescriptions.find(
          item => item.resource == usedResource.uniqueName && item.name == query.simpleQuery.name
        );
        const modelDescription = actionsDescription
          ? modelDescriptions.find(
              item => item.resource == usedResource.uniqueName && item.model == actionsDescription.model
            )
          : undefined;

        if (modelDescription && !usedResource.models.find(item => item.modelDescription.isSame(modelDescription))) {
          usedResource.models.push({
            modelDescription: modelDescription,
            instances: []
          });
        }
      }
    };

    if (instance.type == TemplateType.Page) {
      processElementItemResources(instance.pages, processResource);
      traverseElementItemQueries(instance.pages, processQuery);
    } else if (instance.type == TemplateType.Widget) {
      processElementItemResources(instance.element, processResource);
      traverseElementItemQueries(instance.element, processQuery);
    }

    instance.forResources = forResources
      .filter(item => !item.installConfigurationNeeded)
      .map(item => {
        return {
          type: item.resourceType,
          typeItem: item
        };
      });

    if (!usedResources.length) {
      instance.usedResources = [];
      return of(instance);
    }

    return combineLatest(usedResources.map(item => this.prepareUsedResource(item))).pipe(
      map(result => {
        instance.usedResources = result as any;
        return instance;
      })
    );
  }

  setUsedPages(instance: Template): Observable<Template> {
    return this.viewSettingsStore.getFirst().pipe(
      map(viewSettings => {
        const usedPages: {
          token: string;
          uniqueName: string;
        }[] = [];

        const processPageSegue = (pageName: string): string => {
          if (usedPages.find(item => item.uniqueName == pageName)) {
            return;
          }

          const page = viewSettings.find(item => item.uniqueName && item.uniqueName == pageName);

          if (!page) {
            return;
          }

          let usedPage = usedPages.find(item => item.uniqueName == page.uniqueName);

          if (!usedPage) {
            const number = usedPages.length + 1;
            const token = `{{TEMPLATE_PAGE_SEGUE_${number}}}`;

            usedPage = {
              token: token,
              uniqueName: page.uniqueName
            };
            usedPages.push(usedPage);
          }

          return usedPage.token;
        };

        if (instance.type == TemplateType.Page) {
          processElementItemPageSegues(instance.pages, processPageSegue);
        }

        instance.usedPages = usedPages.map(item => {
          return {
            token: item.token,
            uniqueName: item.uniqueName
          };
        });

        return instance;
      })
    );
  }

  setUsedMenuItems(instance: Template): Observable<Template> {
    return this.menuSettingsStore.getFirst().pipe(
      map(menuSettings => {
        const processMenuItems = (menuItems: MenuItem[]): MenuItem[] => {
          return menuItems.reduce((prev, current) => {
            if (current instanceof SimpleMenuItem || current instanceof ButtonMenuItem) {
              const page = instance.pages.find(p => current.isForPage(p));
              if (page) {
                prev.push(current);
              }
            } else if (current instanceof SectionMenuItem) {
              const children = processMenuItems(current.children);

              if (children.length) {
                const item = cloneDeep(current);
                item.children = children;
                prev.push(item);
              }
            }

            return prev;
          }, []);
        };

        instance.menuItems = processMenuItems(menuSettings.getAllItems());

        return instance;
      })
    );
  }

  getInstance(): Template {
    const instance: Template = this.template ? cloneDeep(this.template) : new Template();

    if (this.controls.type.value) {
      instance.type = this.controls.type.value;
    }

    instance.name = this.controls.name.value;
    instance.subtitle = this.controls.subtitle.value;
    instance.description = this.controls.description.value;
    instance.logo = this.controls.logo.value;
    instance.logoFill = this.controls.logo_fill.value;
    instance.icon = this.controls.icon.value;
    instance.color = this.controls.color.value;
    instance.featured = this.controls.featured.value;
    // instance.resourceTypeItems = this.controls.resource_type_items.value
    //   .map(item => resourceTypeItems.find(i => i.name == item));

    instance.tags = this.controls.tags.value.map(item => {
      const tag = new TemplateTag();
      tag.name = item['name'];
      tag.color = item['color'];
      return tag;
    });

    const uploaderResource = this.getUploaderResource();

    instance.images = this.controls.images.value.map(item => {
      const image = new TemplateImage();
      image.image = uploaderResource ? uploaderResource.mediaUrl(item['image']) : item['image'];
      return image;
    });

    if (this.controls.type.value == TemplateType.Widget && this.controls.update_element.value) {
      instance.element = cloneDeep(this.controls.element.value);
      instance.element.uid = undefined;
    }

    instance.active = this.controls.active.value;
    instance.ordering = this.controls.ordering.value;

    return instance;
  }

  submit(): Observable<Template> {
    const instance = this.getInstance();

    return this.viewSettingsStore.getFirst().pipe(
      switchMap(viewSettings => {
        if (this.controls.type.value == TemplateType.Page) {
          instance.pages = cloneDeep(viewSettings.filter(item => this.controls.pages.value.includes(item.uniqueName)));
          return this.setUsedResources(instance);
        } else if (this.controls.type.value == TemplateType.Widget) {
          return this.setUsedResources(instance);
        } else {
          return of(instance);
        }
      }),
      switchMap(() => {
        if (this.controls.type.value == TemplateType.Page) {
          return this.setUsedPages(instance).pipe(switchMap(() => this.setUsedMenuItems(instance)));
        } else {
          return of(instance);
        }
      }),
      switchMap(() => {
        if (this.template) {
          return this.templateService.update(instance);
        } else {
          return this.templateService.create(instance);
        }
      }),
      catchError(error => {
        this.formUtils.showFormErrors(this, error);
        return throwError(error);
      })
    );
  }
}
