var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, InjectionToken, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges } from '@angular/core';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import fromPairs from 'lodash/fromPairs';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, merge, of, ReplaySubject } from 'rxjs';
import { catchError, delayWhen, filter, map, switchMap, tap } from 'rxjs/operators';
import { DialogService } from '@common/dialogs';
import { AppDropListGroup } from '@common/drag-drop2';
import { NotificationService } from '@common/notifications';
import { DocumentService } from '@core';
import { ActionDescriptionService, ActionStore } from '@modules/action-queries';
import { ActionDescription, ActionItem, ActionType, LinkAction, SegueType } from '@modules/actions';
import { UserActivityService, UserActivityType } from '@modules/activities';
import { AnalyticsEvent, AnalyticsEventAction, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { ChangeForm, ChangeStateService } from '@modules/change';
import { BackElementItem, CardLayoutElementItem, ChangeViewSettings, CustomizeService, ElementType, FieldElementItem, filterElementItems, validateElementNames, ViewContext, ViewContextElement, ViewContextElementType, ViewSettingsService, ViewSettingsStore, ViewSettingsType } from '@modules/customize';
import { CustomizeBarContext, CustomizeBarEditEventType, CustomizeBarService, ProjectPropertyEditController } from '@modules/customize-bar';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { AutoElementComponent } from '@modules/customize-elements';
import { FieldType, Input as FieldInput, InputValueType } from '@modules/fields';
import { MenuSettingsStore } from '@modules/menu';
import { MetaService } from '@modules/meta';
import { ModelDescriptionStore, ModelService, ModelUtilsService } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectGroupService, ProjectPropertyStore, ProjectPropertyType, ProjectUserService } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { CurrentUserStore } from '@modules/users';
import { capitalize, getLocationAppPath, getLocationQueryParams, scrollTo } from '@shared';
export var teamPropertiesToken = new InjectionToken('teamPropertiesToken');
export var userPropertiesToken = new InjectionToken('userPropertiesToken');
export var appPropertiesToken = new InjectionToken('appPropertiesToken');
export var devicePropertiesToken = new InjectionToken('appDeviceToken');
var ChangeComponent = /** @class */ (function () {
    function ChangeComponent(modelDescriptionStore, modelService, context, currentProjectStore, currentEnvironmentStore, cd, router, routing, activatedRoute, viewSettingsService, viewSettingsStore, customizeService, dialogService, notificationService, userActivityService, analyticsService, changeStateService, metaService, actionStore, modelUtilsService, form, actionDescriptionService, customizeBarService, customizeBarContext, menuSettingsStore, viewContextElementTeamProperty, viewContextElementUserProperty, viewContextElementAppProperty, viewContextElementDeviceProperty, projectUserService, elementConfigurationService, documentService, currentUserStore, projectGroupService, projectPropertyStore, projectPropertyEditController) {
        this.modelDescriptionStore = modelDescriptionStore;
        this.modelService = modelService;
        this.context = context;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.cd = cd;
        this.router = router;
        this.routing = routing;
        this.activatedRoute = activatedRoute;
        this.viewSettingsService = viewSettingsService;
        this.viewSettingsStore = viewSettingsStore;
        this.customizeService = customizeService;
        this.dialogService = dialogService;
        this.notificationService = notificationService;
        this.userActivityService = userActivityService;
        this.analyticsService = analyticsService;
        this.changeStateService = changeStateService;
        this.metaService = metaService;
        this.actionStore = actionStore;
        this.modelUtilsService = modelUtilsService;
        this.form = form;
        this.actionDescriptionService = actionDescriptionService;
        this.customizeBarService = customizeBarService;
        this.customizeBarContext = customizeBarContext;
        this.menuSettingsStore = menuSettingsStore;
        this.viewContextElementTeamProperty = viewContextElementTeamProperty;
        this.viewContextElementUserProperty = viewContextElementUserProperty;
        this.viewContextElementAppProperty = viewContextElementAppProperty;
        this.viewContextElementDeviceProperty = viewContextElementDeviceProperty;
        this.projectUserService = projectUserService;
        this.elementConfigurationService = elementConfigurationService;
        this.documentService = documentService;
        this.currentUserStore = currentUserStore;
        this.projectGroupService = projectGroupService;
        this.projectPropertyStore = projectPropertyStore;
        this.projectPropertyEditController = projectPropertyEditController;
        this.children = new QueryList();
        this.dropListGroups = new QueryList();
        this.loading = true;
        this.submitLoading = false;
        this.items = [];
        this.saveLoading = false;
        this.viewContextInitialized = new ReplaySubject(1);
    }
    ChangeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.customizeService.setHandler(this);
        this.updateCustomizeHandleInfo();
        this.initViewContextElements();
        combineLatest(this.projectUserService
            .get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName)
            .pipe(catchError(function () { return of([]); })), this.projectGroupService
            .get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName)
            .pipe(catchError(function () { return of([]); })), this.currentUserStore.instance$, this.currentProjectStore.instance$, this.currentEnvironmentStore.instance$, this.projectPropertyStore.get())
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var usersProject = _a[0], groupsProject = _a[1], currentUser = _a[2], currentProject = _a[3], currentEnvironment = _a[4], projectProperties = _a[5];
            var userProperties = projectProperties.filter(function (item) { return item.type == ProjectPropertyType.User; });
            var groupProperties = projectProperties.filter(function (item) { return item.type == ProjectPropertyType.Group; });
            _this.updateContextUserProperties(userProperties);
            _this.updateContextTeamProperties(groupProperties);
            var currentProjectUser = usersProject.find(function (item) { return item.user && item.user.uid === currentUser.uid; });
            if (currentProjectUser) {
                var value = fromPairs(userProperties.map(function (property) { return [property.uid, currentProjectUser.getPropertyValue(property)]; }));
                _this.viewContextElementUserProperty.setOutputValues(value);
            }
            var currentProjectGroup = currentEnvironment.group
                ? groupsProject.find(function (item) { return item.uid == currentEnvironment.group.uid; })
                : undefined;
            if (currentProjectGroup) {
                var value = fromPairs(groupProperties.map(function (property) { return [property.uid, currentProjectGroup.getPropertyValue(property)]; }));
                _this.viewContextElementTeamProperty.setOutputValues(value);
            }
            _this.viewContextElementAppProperty.patchOutputValues({
                name: currentProject.uniqueName,
                env_name: currentEnvironment.uniqueName
            });
            _this.viewContextInitialized.next();
        });
        merge(of({}), this.router.events.pipe(filter(function (item) { return item instanceof ActivationEnd; })))
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.viewContextElementAppProperty.patchOutputValues({
                app_path: getLocationAppPath(),
                query_params: getLocationQueryParams()
            });
        });
        this.documentService.viewportSize$.pipe(untilDestroyed(this)).subscribe(function (value) {
            var type = _this.documentService.getViewportType(value.width);
            _this.viewContextElementDeviceProperty.patchOutputValues({
                is_desktop: _this.documentService.isDesktopViewportType(type),
                is_mobile: _this.documentService.isMobileViewportType(type),
                is_phone: _this.documentService.isPhoneViewportType(type),
                is_tablet: _this.documentService.isTabletViewportType(type),
                screen_width: value.width,
                screen_height: value.height
            });
        });
        merge(of(this.dropListGroups.toArray()), this.dropListGroups.changes.pipe(map(function () { return _this.dropListGroups.toArray(); })))
            .pipe(untilDestroyed(this))
            .subscribe(function (groups) {
            _this.context.dropListGroups = groups;
        });
    };
    ChangeComponent.prototype.ngOnDestroy = function () {
        if (this.form.form) {
            this.changeStateService.unregisterForm(this.form.form);
        }
        this.customizeService.layoutCustomization = undefined;
        this.customizeService.unsetHandler(this);
    };
    ChangeComponent.prototype.onBeforeDestroy = function () {
        this.context.clear();
    };
    ChangeComponent.prototype.ngOnChanges = function (changes) {
        if (changes['modelId'] || changes['id'] || changes['uniqueName'] || changes['queryParams']) {
            if (this.modelId || this.uniqueName) {
                this.getData();
            }
        }
    };
    ChangeComponent.prototype.getData = function () {
        var _this = this;
        var obs = [];
        var modelChanged = !((this.modelDescription ? this.modelDescription.modelId : undefined) == this.modelId);
        var idChanged = !((this.model ? this.model.primaryKey : undefined) == this.id);
        if (this.viewSettings && !modelChanged) {
            obs.push(of(this.modelDescription));
            obs.push(this.actionStore.getFirst());
        }
        else {
            obs.push(this.modelDescriptionStore.getDetailFirst(this.modelId));
            obs.push(this.actionStore.getFirst());
        }
        this.loading = true;
        this.error = undefined;
        this.viewSettings = undefined;
        this.context.clear(ViewContextElementType.Element);
        this.cd.detectChanges();
        var result = combineLatest.apply(void 0, obs).pipe(switchMap(function (_a) {
            var modelDescription = _a[0], actions = _a[1];
            obs = [];
            if (_this.uniqueName) {
                obs.push(_this.viewSettingsStore.getDetailFirst(_this.uniqueName, ViewSettingsType.Change));
            }
            else {
                obs.push(_this.viewSettingsStore.getChangeViewSettingsFirst(_this.modelId));
            }
            if (_this.id && (modelChanged || idChanged)) {
                obs.push(_this.modelService.getDetail(_this.currentProjectStore.instance, _this.currentEnvironmentStore.instance, _this.modelId, modelDescription.primaryKeyField, _this.id, _this.params));
                scrollTo(document.getElementsByClassName('admin__content')[0], 0); // TODO: refactor scrollTo
            }
            else if (_this.id && !modelChanged && !idChanged) {
                obs.push(of(_this.model));
            }
            else if (!_this.id) {
                obs.push(of(undefined));
                scrollTo(document.getElementsByClassName('admin__content')[0], 0);
            }
            else {
                obs.push(of(undefined));
            }
            if (_this.params['_duplicate']) {
                obs.push(_this.modelService.getDetail(_this.currentProjectStore.instance, _this.currentEnvironmentStore.instance, _this.modelId, modelDescription.primaryKeyField, _this.params['_duplicate']));
            }
            else {
                obs.push(of(undefined));
            }
            return combineLatest.apply(void 0, obs).pipe(map(function (_a) {
                var viewSettings = _a[0], model = _a[1], duplicateModel = _a[2];
                return [
                    modelDescription,
                    viewSettings,
                    actions,
                    model,
                    duplicateModel
                ];
            }));
        }), delayWhen(function () { return _this.viewContextInitialized; }), untilDestroyed(this), tap(function (_a) {
            var modelDescription = _a[0], viewSettings = _a[1], actions = _a[2], model = _a[3], duplicateModel = _a[4];
            _this.modelDescription = modelDescription;
            _this.viewSettings = viewSettings;
            if (_this.uniqueName) {
                _this.context.objectType = 'custom_page';
                _this.context.objectId = _this.uniqueName;
            }
            if (!_this.modelDescription) {
                return;
            }
            _this.setInitialSettings();
            _this.updateCustomizeHandleInfo();
            _this.initialViewSettings = cloneDeep(_this.viewSettings);
            _this.changeViewSettings = cloneDeep(_this.viewSettings);
            if (_this.viewSettings && !_this.uniqueName) {
                _this.context.objectType = ['model', _this.viewSettings.model].join('.');
                _this.context.objectId = _this.id;
            }
            // if (this.viewSettings) {
            //   this.viewSettings.actions = this.viewSettings.actions
            //     .map(item => {
            //       const action = actions.find(i => i.isSame(item.actionDescription));
            //
            //       if (!action) {
            //         return;
            //       }
            //
            //       item.verboseName = action.verboseName;
            //       item.icon = action.icon;
            //       return item;
            //     })
            //     .filter(item => item != undefined)
            //     .sort((lhs, rhs) => lhs.order - rhs.order);
            // }
            var initial;
            if (duplicateModel) {
                initial = duplicateModel.serialize(modelDescription.dbFields.map(function (item) { return item.name; }));
                initial[modelDescription.primaryKeyField] = undefined;
            }
            if (_this.activatedRoute.snapshot.queryParams['_initials']) {
                initial = JSON.parse(_this.activatedRoute.snapshot.queryParams['_initials']);
            }
            if (_this.form.form) {
                _this.changeStateService.unregisterForm(_this.form.form);
            }
            _this.model = model;
            _this.duplicateModel = duplicateModel;
            (_this.model ? _this.modelUtilsService.str(_this.model) : of('Create'))
                .pipe(untilDestroyed(_this))
                .subscribe(function (str) {
                _this.metaService.set({ title: [str, _this.modelDescription.verboseNamePlural] });
            });
            _this.form.initialize(_this.modelDescription, _this.model, initial);
            // this.context.init(this.form.form, this.modelDescription, this.model, []);
            _this.context.modelDescription = _this.modelDescription;
            _this.context.model = _this.model;
            _this.context.form = _this.form.form;
            _this.customizeService.layoutCustomization = {
                subtitle: '',
                title: 'Edit Record \n' + capitalize(_this.modelDescription.verboseName),
                image: 'change',
                clickEvent: [
                    AnalyticsEvent.Deprecated.CustomizeInterfaceChooseRecordView,
                    {
                        CollectionID: _this.modelDescription.model,
                        RecordID: _this.model ? _this.model.primaryKey : undefined
                    }
                ],
                description: [
                    { icon: 'blocks', label: 'Build your Record View using layouts and components' },
                    {
                        icon: 'fileds',
                        label: 'Edit and organize your data in the Record View: add Fields and Related Collections'
                    },
                    { icon: 'diagram', label: 'Create charts and track KPIs on a specific Record' },
                    { icon: 'power', label: 'Add custom action buttons' }
                ],
                modelDescription: _this.modelDescription
            };
            _this.cd.markForCheck();
            _this.changeStateService.registerForm(_this.form.form);
            if (_this.model) {
                _this.userActivityService
                    .currentProjectCreateModelInstance(_this.currentProjectStore.instance, _this.currentEnvironmentStore.instance, UserActivityType.ModelDetail, _this.modelDescription.modelId, _this.model.primaryKey, undefined, {
                    resource: _this.modelDescription.resource,
                    model: _this.modelDescription.model,
                    id: _this.model.primaryKey
                })
                    .subscribe(function () { });
            }
        }));
        result
            .pipe(untilDestroyed(this), catchError(function (error) {
            _this.loading = false;
            _this.error = error;
            _this.cd.markForCheck();
            console.log(error);
            return of(undefined);
        }))
            .subscribe(function () {
            _this.loading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
        return result;
    };
    ChangeComponent.prototype.setInitialSettings = function () {
        if (!this.viewSettings) {
            this.viewSettings = new ChangeViewSettings();
            this.viewSettings.project = this.currentProjectStore.instance.uniqueName;
            if (this.uniqueName) {
                this.viewSettings.uniqueName = this.uniqueName;
            }
            else if (this.modelDescription) {
                this.viewSettings.resource = this.modelDescription.resource;
                this.viewSettings.model = this.modelDescription.model;
            }
        }
        if (!this.viewSettings.elements || !this.viewSettings.elements.length) {
            if (this.modelDescription) {
                var displayField = this.modelDescription.displayField || this.modelDescription.primaryKeyField;
                var backElement = new BackElementItem();
                backElement.type = ElementType.Back;
                backElement.titleInput = new FieldInput();
                backElement.titleInput.name = 'value';
                backElement.titleInput.valueType = InputValueType.Formula;
                backElement.titleInput.formulaValue = "IF(record, GET(record, \"" + displayField + "\"), \"Create\")";
                backElement.previousPageAction = new ActionItem();
                backElement.previousPageAction.actionDescription = new ActionDescription();
                backElement.previousPageAction.actionDescription.type = ActionType.Link;
                backElement.previousPageAction.actionDescription.linkAction = new LinkAction();
                backElement.previousPageAction.actionDescription.linkAction.type = SegueType.PreviousPage;
                this.viewSettings.elements = [
                    backElement,
                    new CardLayoutElementItem().deserialize({
                        type: ElementType.Card,
                        params: {
                            children: this.modelDescription.displayFields.map(function (field) {
                                return new FieldElementItem().deserialize({
                                    type: ElementType.Field,
                                    params: {
                                        field: field.formField.serialize()
                                    }
                                });
                            })
                        }
                    })
                ];
                validateElementNames(this.viewSettings.elements);
            }
        }
        this.customizeService.startTrackChanges();
    };
    ChangeComponent.prototype.hasChanges = function () {
        return [
            this.form.hasChanges()
            // ...this.context.relatedModelsList.map(item => item.hasChanges()),
            // ...this.context.relations.map(item => item.hasChanges())
        ].some(function (item) { return item == true; });
    };
    ChangeComponent.prototype.submit = function () {
        var _this = this;
        if (this.form.form.invalid || this.form.form.disabled) {
            return;
        }
        var forms = [
            this.form
            // ...this.context.relatedModelsList.map(item => item.form)
        ]
            .map(function (form) {
            return {
                modelDescription: form.modelDescription,
                model: form.model,
                changes: form.getChanges(),
                form: form
            };
        })
            .filter(function (form) { return form.changes.length > 0; });
        var fields = filterElementItems(this.changeViewSettings.elements, function (item) { return item instanceof FieldElementItem; })
            .map(function (item) { return item.settings.name; })
            .filter(function (item) { return item != undefined; });
        var obs = forms.map(function (item) { return item.form.submit(fields); }).slice().filter(function (item) { return item != undefined; });
        if (!obs.length) {
            this.notificationService.info('Nothing changed', "You haven't made any changes");
            return;
        }
        this.submitLoading = true;
        this.cd.markForCheck();
        combineLatest(obs)
            .pipe(untilDestroyed(this))
            .subscribe(function (results) {
            _this.submitLoading = false;
            _this.cd.markForCheck();
            if (!_this.model) {
                var model = results[0];
                _this.analyticsService.sendEvent(AnalyticsEvent.GA.Model, AnalyticsEventAction.Created);
                _this.routing.navigateApp(model.getLink(), { queryParams: _this.listParams });
                _this.notificationService.success('Created', "<strong>" + _this.modelDescription.verboseName + "</strong> was successfully created");
            }
            else {
                _this.analyticsService.sendEvent(AnalyticsEvent.GA.Model, AnalyticsEventAction.Updated);
                _this.form.form.markAsPristine();
                _this.cd.markForCheck();
                _this.notificationService.success('Saved', "<strong>" + _this.modelDescription.verboseName + "</strong> was successfully updated");
            }
        }, function (error) {
            _this.submitLoading = false;
            _this.cd.markForCheck();
            if (error instanceof ServerRequestError && error.nonFieldErrors.length) {
                _this.notificationService.error('Error', "Saving <strong>" + _this.modelDescription.verboseName + "</strong> failed: " + error.nonFieldErrors[0]);
            }
            else {
                _this.notificationService.error('Error', "Saving <strong>" + _this.modelDescription.verboseName + "</strong> failed");
            }
        });
    };
    ChangeComponent.prototype.deleteModel = function () {
        var _this = this;
        this.dialogService
            .warning({
            title: 'Deleting',
            description: "Are you sure want to delete this <strong>" + this.modelDescription.verboseName + "</strong>?"
        })
            .pipe(filter(function (result) { return result == true; }), switchMap(function () {
            return _this.modelService.delete(_this.currentProjectStore.instance, _this.currentEnvironmentStore.instance, _this.modelDescription.modelId, _this.model);
        }), untilDestroyed(this))
            .subscribe(function () {
            _this.analyticsService.sendEvent(AnalyticsEvent.GA.Model, AnalyticsEventAction.Deleted);
            _this.routing.navigateApp(_this.modelDescription.link, { queryParams: _this.listParams });
        });
    };
    Object.defineProperty(ChangeComponent.prototype, "listParams", {
        get: function () {
            var params = clone(this.activatedRoute.snapshot.queryParams);
            if (params['_duplicate'] != undefined) {
                delete params['_duplicate'];
            }
            return params;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChangeComponent.prototype, "changed", {
        get: function () {
            var pristine = this.form.form.pristine;
            // && this.context.relations.every(relation => relation.items.every(item => item.form.form.pristine));
            return !pristine;
        },
        enumerable: true,
        configurable: true
    });
    ChangeComponent.prototype.updateCustomizeHandleInfo = function () {
        this.customizeService.setHandlerInfo(this, {
            breadcrumbs: ['Customizing Record'],
            title: this.modelDescription ? this.modelDescription.verboseName : undefined,
            page: this.viewSettings
        });
    };
    ChangeComponent.prototype.getChangesState = function () {
        var _this = this;
        var instance = this.viewSettings;
        var actions = this.viewSettings.actions
            .filter(function (item) { return item.sharedActionDescription; })
            .filter(function (item) {
            var initial = _this.initialViewSettings.actions.find(function (i) { return i.actionDescription == item.actionDescription; });
            var mapAction = function (action) {
                return {
                    verboseName: action.verboseNameInput ? action.verboseNameInput.staticValue : undefined,
                    icon: action.icon
                };
            };
            return !initial || !isEqual(mapAction(item), mapAction(initial));
        })
            .map(function (item) {
            var action = _this.actionStore.instance.find(function (i) { return i.isSame(item.actionDescription); });
            action.verboseName = item.verboseNameInput ? item.verboseNameInput.staticValue : undefined;
            action.icon = item.icon;
            return action;
        });
        return {
            viewSettings: cloneDeep(instance),
            actions: cloneDeep(actions)
        };
    };
    ChangeComponent.prototype.setChangesState = function (state) {
        this.viewSettings = cloneDeep(state.viewSettings);
        this.cd.markForCheck();
    };
    ChangeComponent.prototype.isChangesStateEqual = function (lhs, rhs) {
        var compare = ['params'];
        return (isEqual(lhs.viewSettings.serialize(compare), rhs.viewSettings.serialize(compare)) &&
            isEqual(lhs.actions.map(function (item) { return item.serialize(); }), rhs.actions.map(function (item) { return item.serialize(); })));
    };
    ChangeComponent.prototype.saveChangesState = function (state) {
        var _this = this;
        return combineLatest.apply(void 0, [this.viewSettingsService.create(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, state.viewSettings)].concat(state.actions.map(function (action) {
            return _this.actionDescriptionService.create(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, action);
        }))).pipe(switchMap(function () { return _this.viewSettingsStore.getFirst(true); }), tap(function () {
            if (state.viewSettings) {
                _this.initialViewSettings = cloneDeep(state.viewSettings);
            }
            _this.analyticsService.sendEvent(AnalyticsEvent.GA.ModelDetailLayout, AnalyticsEventAction.Updated);
        }), switchMap(function () { return _this.actionStore.getFirst(true); }), map(function () {
            return state;
        }));
    };
    ChangeComponent.prototype.reload = function () {
        this.viewSettings = undefined;
        return this.getData();
    };
    ChangeComponent.prototype.onViewSettingsUpdated = function (viewSettings) {
        this.viewSettings = viewSettings;
        this.customizeService.markChanged();
        this.cd.markForCheck();
    };
    ChangeComponent.prototype.customizeViewSettings = function () {
        var _this = this;
        this.customizeBarService
            .customizePage({ context: this.customizeBarContext, viewSettings: this.viewSettings, viewContext: this.context })
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
                var instance = e.args['result'];
                _this.onViewSettingsUpdated(instance);
                _this.cd.markForCheck();
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Page.SuccessfullySetUp, {
                    PageID: _this.viewSettings.uniqueName
                });
            }
            else if (e.type == CustomizeBarEditEventType.Deleted) {
                _this.viewSettingsService
                    .delete(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, _this.viewSettings)
                    .pipe(delayWhen(function () { return _this.viewSettingsStore.getFirst(true); }), delayWhen(function () { return _this.menuSettingsStore.getFirst(true); }), untilDestroyed(_this))
                    .subscribe(function () {
                    _this.routing.navigateApp(_this.currentProjectStore.instance.homeLink);
                    _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Page.Deleted, {
                        PageID: _this.viewSettings.uniqueName
                    });
                });
            }
        });
    };
    ChangeComponent.prototype.openHandlerSettings = function () {
        this.customizeViewSettings();
    };
    ChangeComponent.prototype.initViewContextElements = function () {
        var _this = this;
        this.viewContextElementTeamProperty.unregister();
        this.viewContextElementTeamProperty.initGlobal({
            uniqueName: 'team_properties',
            name: 'Team Properties',
            action: {
                label: 'Add Property',
                icon: 'plus',
                handler: function () {
                    return _this.projectPropertyEditController
                        .create({
                        type: ProjectPropertyType.Group,
                        context: _this.context,
                        analyticsSource: 'component_input'
                    })
                        .pipe(map(function (item) {
                        return {
                            insertToken: ['team_properties', item.property.uid]
                        };
                    }));
                }
            },
            documentation: 'conditional-visibility/properties'
        });
        this.viewContextElementUserProperty.unregister();
        this.viewContextElementUserProperty.initGlobal({
            uniqueName: 'user_properties',
            name: 'User Properties',
            action: {
                label: 'Add Property',
                icon: 'plus',
                handler: function () {
                    return _this.projectPropertyEditController
                        .create({
                        type: ProjectPropertyType.User,
                        context: _this.context,
                        analyticsSource: 'component_input'
                    })
                        .pipe(map(function (item) {
                        return {
                            insertToken: ['user_properties', item.property.uid]
                        };
                    }));
                }
            },
            documentation: 'conditional-visibility/properties'
        });
        this.viewContextElementAppProperty.unregister();
        this.viewContextElementAppProperty.initGlobal({ uniqueName: 'app', name: 'Application' });
        this.viewContextElementAppProperty.setOutputs([
            {
                uniqueName: 'name',
                name: 'App name',
                icon: 'home'
            },
            {
                uniqueName: 'env_name',
                name: 'Environment name',
                icon: 'tag'
            },
            {
                uniqueName: 'app_path',
                name: 'App path',
                icon: 'console'
            },
            {
                uniqueName: 'query_params',
                name: 'URL query parameters',
                icon: 'components',
                fieldType: FieldType.JSON
            }
        ]);
        this.viewContextElementDeviceProperty.unregister();
        this.viewContextElementDeviceProperty.initGlobal({ uniqueName: 'device', name: 'Device' });
        this.viewContextElementDeviceProperty.setOutputs([
            {
                uniqueName: 'is_desktop',
                name: 'Is Desktop',
                icon: 'pages'
            },
            {
                uniqueName: 'is_mobile',
                name: 'Is Mobile',
                icon: 'pages'
            },
            {
                uniqueName: 'is_phone',
                name: 'Is Phone',
                icon: 'pages'
            },
            {
                uniqueName: 'is_tablet',
                name: 'Is Tablet',
                icon: 'pages'
            },
            {
                uniqueName: 'screen_width',
                name: 'Screen width',
                icon: 'align_horizontal_fill'
            },
            {
                uniqueName: 'screen_height',
                name: 'Screen height',
                icon: 'align_vertical_fill'
            }
        ]);
    };
    ChangeComponent.prototype.updateContextUserProperties = function (properties) {
        var outputs = properties.map(function (item) {
            return __assign({ uniqueName: item.uid, name: item.name, icon: item.fieldDescription.icon }, (item.field
                ? {
                    fieldType: item.field.field,
                    fieldParams: item.field.params
                }
                : {}));
        });
        this.viewContextElementUserProperty.setOutputs(outputs);
    };
    ChangeComponent.prototype.updateContextTeamProperties = function (properties) {
        var outputs = properties.map(function (item) {
            return __assign({ uniqueName: item.uid, name: item.name, icon: item.fieldDescription.icon }, (item.field
                ? {
                    fieldType: item.field.field,
                    fieldParams: item.field.params
                }
                : {}));
        });
        this.viewContextElementTeamProperty.setOutputs(outputs);
    };
    return ChangeComponent;
}());
export { ChangeComponent };
