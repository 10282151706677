var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, Injector, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { ActionControllerService } from '@modules/action-queries';
import { SegueType } from '@modules/actions';
import { BackElementItem, CustomizeService, ElementType, registerElementComponent } from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { applyParamInput } from '@modules/fields';
import { EMPTY } from '@shared';
var BackElementComponent = /** @class */ (function (_super) {
    __extends(BackElementComponent, _super);
    function BackElementComponent(customizeService, actionControllerService, notificationService, injector, cd) {
        var _this = _super.call(this) || this;
        _this.customizeService = customizeService;
        _this.actionControllerService = actionControllerService;
        _this.notificationService = notificationService;
        _this.injector = injector;
        _this.cd = cd;
        _this.backEnabled = true;
        return _this;
    }
    BackElementComponent.prototype.ngOnInit = function () {
        this.init();
    };
    BackElementComponent.prototype.ngOnDestroy = function () { };
    BackElementComponent.prototype.ngOnChanges = function (changes) {
        if (changes['element'] || changes['context']) {
            this.init();
        }
    };
    BackElementComponent.prototype.init = function () {
        this.initContextObserver();
        if (this.element.previousPageAction) {
            if (this.element.previousPageAction.actionDescription &&
                this.element.previousPageAction.actionDescription.linkAction &&
                this.element.previousPageAction.actionDescription.linkAction.type == SegueType.PreviousPage) {
                this.backEnabled = history.length > 2;
            }
            else {
                this.backEnabled = true;
            }
        }
        else {
            this.backEnabled = false;
        }
        this.cd.markForCheck();
    };
    BackElementComponent.prototype.initContextObserver = function () {
        var _this = this;
        if (this.contextSubscription) {
            this.contextSubscription.unsubscribe();
        }
        this.updateInputs();
        this.contextSubscription = this.context.outputValues$
            .pipe(debounceTime(10), distinctUntilChanged(), untilDestroyed(this))
            .subscribe(function () { return _this.updateInputs(); });
    };
    BackElementComponent.prototype.updateInputs = function () {
        var title;
        var previousPageTitle;
        if (this.element.titleInput) {
            try {
                var value = applyParamInput(this.element.titleInput, { context: this.context });
                if (value !== EMPTY) {
                    title = value;
                }
            }
            catch (e) { }
        }
        if (this.element.previousPageTitleInput) {
            try {
                var value = applyParamInput(this.element.previousPageTitleInput, { context: this.context });
                if (value !== EMPTY) {
                    previousPageTitle = value;
                }
            }
            catch (e) { }
        }
        this.title = title || 'No title';
        this.previousPageTitle = previousPageTitle || 'Back';
        this.cd.markForCheck();
    };
    BackElementComponent.prototype.back = function () {
        this.actionControllerService
            .execute(this.element.previousPageAction, { context: this.context, injector: this.injector })
            .subscribe();
    };
    return BackElementComponent;
}(BaseElementComponent));
export { BackElementComponent };
registerElementComponent({
    type: ElementType.Back,
    component: BackElementComponent,
    label: 'Back',
    actions: []
});
