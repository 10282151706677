var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import pickBy from 'lodash/pickBy';
import { ViewSettingsAction } from '../../view-settings-action';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
export var ActionGroupOrientation;
(function (ActionGroupOrientation) {
    ActionGroupOrientation["Horizontal"] = "horizontal";
    ActionGroupOrientation["Vertical"] = "vertical";
})(ActionGroupOrientation || (ActionGroupOrientation = {}));
var ActionGroupElementItem = /** @class */ (function (_super) {
    __extends(ActionGroupElementItem, _super);
    function ActionGroupElementItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = ElementType.ActionGroup;
        _this.actions = [];
        _this.orientation = ActionGroupOrientation.Horizontal;
        return _this;
    }
    ActionGroupElementItem.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        if (this.params['actions']) {
            this.actions = this.params['actions'].map(function (item) { return new ViewSettingsAction().deserialize(item); });
        }
        if (this.params['orientation']) {
            this.orientation = this.params['orientation'];
        }
        return this;
    };
    ActionGroupElementItem.prototype.serialize = function (fields) {
        this.params = {};
        this.params['actions'] = this.actions.map(function (item) { return item.serialize(); });
        this.params['orientation'] = this.orientation;
        var data = _super.prototype.serialize.call(this);
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Object.defineProperty(ActionGroupElementItem.prototype, "typeStr", {
        get: function () {
            return 'trigger operations or transitions';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ActionGroupElementItem.prototype, "analyticsName", {
        get: function () {
            return 'action_group';
        },
        enumerable: true,
        configurable: true
    });
    ActionGroupElementItem.prototype.defaultName = function () {
        return 'Button group';
    };
    return ActionGroupElementItem;
}(ElementItem));
export { ActionGroupElementItem };
registerElementForType(ElementType.ActionGroup, ActionGroupElementItem);
