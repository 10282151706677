<div class="popover2 overlay-content overlay-content_animated">
  <div class="popover2__inner popover2__inner_sidebar">
    <div class="popover2__viewport">
      <div class="popover2__content popover2__content_padding_none">
        <div class="menu-item-edit-popover">
          <div class="sidebar__element">
            <app-sidebar-field
              [label]="'Conditional Visibility'"
              [documentation]="'conditional-visibility'"
              [documentationLabel]="'See how it works'"
              [documentationSource]="analyticsSource + '_visible'"
            >
              <app-input-edit
                [itemForm]="control.controls.visible_input"
                [context]="context"
                [staticValueDisabled]="true"
                [placeholder]="'Value'"
                [fill]="true"
                [analyticsSource]="analyticsSource + '_visible'"
              ></app-input-edit>

              <ng-container description>
                Example: user.region == 'Europe'
              </ng-container>
            </app-sidebar-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
