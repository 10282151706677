import { DragDropModule } from '@angular/cdk/drag-drop';
import { ObserversModule } from '@angular/cdk/observers';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { NgGxScrollableModule } from 'ng-gxscrollable';
import { NgGxSelectModule } from 'ng-gxselect';

import { BannersModule } from '@common/banners';
import { DragDrop2Module } from '@common/drag-drop2';
import { DynamicComponentModule } from '@common/dynamic-component';
import { EditableContentModule } from '@common/editable-content';
import { FormUtilsModule } from '@common/form-utils';
import { LocalizeModule } from '@common/localize';
import { SelectModule } from '@common/select';
import { TextEditorModule } from '@common/text-editor';
import { TipsModule } from '@common/tips';
import { UniqueIdModule } from '@common/unique-id';
import { ColorsComponentsModule } from '@modules/colors-components';
import { CustomViewsRoutesModule } from '@modules/custom-views-routes';
import { CustomizeUiModule } from '@modules/customize-ui';
import { FieldComponentsModule } from '@modules/field-components';
import { FieldsModule } from '@modules/fields';
import { FiltersComponentsModule } from '@modules/filters-components';
import { ModelComponentsModule } from '@modules/model-components';
import { ParametersComponentsModule } from '@modules/parameters-components';
import { ProjectsComponentsModule } from '@modules/projects-components';
import { QueriesComponentsModule } from '@modules/queries-components';
import { SidebarModule } from '@modules/sidebar';
import { TokensComponentsModule } from '@modules/tokens-components';
import { TutorialModule } from '@modules/tutorial';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { ActionFormSingleEditComponent } from './components/action-form-single-edit/action-form-single-edit.component';
import { ActionSingleEditComponent } from './components/action-single-edit/action-single-edit.component';
import { ActionsEditItemComponent } from './components/actions-edit/actions-edit-item/actions-edit-item.component';
import { ActionsEditComponent } from './components/actions-edit/actions-edit.component';
import { BindComponentComponent } from './components/bind-component/bind-component.component';
import { ChartWidgetDataSourceEditComponent } from './components/chart-widget-data-source-edit/chart-widget-data-source-edit.component';
import { ConfirmationEditOverlayComponent } from './components/confirmation-edit-overlay/confirmation-edit-overlay.component';
import { ConfirmationEditComponent } from './components/confirmation-edit/confirmation-edit.component';
import { CustomViewItemEditComponent } from './components/custom-view-item-edit/custom-view-item-edit.component';
import { CustomizeBarActionDropdownEditComponent } from './components/customize-bar-action-dropdown-edit/customize-bar-action-dropdown-edit.component';
import { CustomizeBarActionEditCompletionComponent } from './components/customize-bar-action-edit/customize-bar-action-edit-completion/customize-bar-action-edit-completion.component';
import { CustomizeBarActionEditDisplayComponent } from './components/customize-bar-action-edit/customize-bar-action-edit-display/customize-bar-action-edit-display.component';
import { CustomizeBarActionEditElementActionComponent } from './components/customize-bar-action-edit/customize-bar-action-edit-element-action/customize-bar-action-edit-element-action.component';
import { CustomizeBarActionEditOperationComponent } from './components/customize-bar-action-edit/customize-bar-action-edit-operation/customize-bar-action-edit-operation.component';
import { CustomizeBarActionEditParametersElementActionComponent } from './components/customize-bar-action-edit/customize-bar-action-edit-parameters/customize-bar-action-edit-parameters-element-action/customize-bar-action-edit-parameters-element-action.component';
import { CustomizeBarActionEditParametersExportComponent } from './components/customize-bar-action-edit/customize-bar-action-edit-parameters/customize-bar-action-edit-parameters-export/customize-bar-action-edit-parameters-export.component';
import { CustomizeBarActionEditParametersLinkComponent } from './components/customize-bar-action-edit/customize-bar-action-edit-parameters/customize-bar-action-edit-parameters-link/customize-bar-action-edit-parameters-link.component';
import { CustomizeBarActionEditParametersOpenPopupComponent } from './components/customize-bar-action-edit/customize-bar-action-edit-parameters/customize-bar-action-edit-parameters-open-popup/customize-bar-action-edit-parameters-open-popup.component';
import { CustomizeBarActionEditParametersQueryComponent } from './components/customize-bar-action-edit/customize-bar-action-edit-parameters/customize-bar-action-edit-parameters-query/customize-bar-action-edit-parameters-query.component';
import { CustomizeBarActionEditParametersWorkflowComponent } from './components/customize-bar-action-edit/customize-bar-action-edit-parameters/customize-bar-action-edit-parameters-workflow/customize-bar-action-edit-parameters-workflow.component';
import { CustomizeBarActionEditParametersComponent } from './components/customize-bar-action-edit/customize-bar-action-edit-parameters/customize-bar-action-edit-parameters.component';
import { CustomizeBarActionEditTypeClosePopupComponent } from './components/customize-bar-action-edit/customize-bar-action-edit-type/customize-bar-action-edit-type-close-popup/customize-bar-action-edit-type-close-popup.component';
import { CustomizeBarActionEditTypeCopyToClipboardComponent } from './components/customize-bar-action-edit/customize-bar-action-edit-type/customize-bar-action-edit-type-copy-to-clipboard/customize-bar-action-edit-type-copy-to-clipboard.component';
import { CustomizeBarActionEditTypeDownloadComponent } from './components/customize-bar-action-edit/customize-bar-action-edit-type/customize-bar-action-edit-type-download/customize-bar-action-edit-type-download.component';
import { CustomizeBarActionEditTypeElementActionComponent } from './components/customize-bar-action-edit/customize-bar-action-edit-type/customize-bar-action-edit-type-element-action/customize-bar-action-edit-type-element-action.component';
import { CustomizeBarActionEditTypeExportComponent } from './components/customize-bar-action-edit/customize-bar-action-edit-type/customize-bar-action-edit-type-export/customize-bar-action-edit-type-export.component';
import { CustomizeBarActionEditTypeExternalLinkComponent } from './components/customize-bar-action-edit/customize-bar-action-edit-type/customize-bar-action-edit-type-external-link/customize-bar-action-edit-type-external-link.component';
import { CustomizeBarActionEditTypeImportComponent } from './components/customize-bar-action-edit/customize-bar-action-edit-type/customize-bar-action-edit-type-import/customize-bar-action-edit-type-import.component';
import { CustomizeBarActionEditTypeLinkComponent } from './components/customize-bar-action-edit/customize-bar-action-edit-type/customize-bar-action-edit-type-link/customize-bar-action-edit-type-link.component';
import { CustomizeBarActionEditTypeOpenPopupComponent } from './components/customize-bar-action-edit/customize-bar-action-edit-type/customize-bar-action-edit-type-open-popup/customize-bar-action-edit-type-open-popup.component';
import { CustomizeBarActionEditTypeQueryComponent } from './components/customize-bar-action-edit/customize-bar-action-edit-type/customize-bar-action-edit-type-query/customize-bar-action-edit-type-query.component';
import { CustomizeBarActionEditTypeRunJavaScriptComponent } from './components/customize-bar-action-edit/customize-bar-action-edit-type/customize-bar-action-edit-type-run-javascript/customize-bar-action-edit-type-run-javascript.component';
import { CustomizeBarActionEditTypeSetPropertyComponent } from './components/customize-bar-action-edit/customize-bar-action-edit-type/customize-bar-action-edit-type-set-property/customize-bar-action-edit-type-set-property.component';
import { CustomizeBarActionEditTypeShowNotificationComponent } from './components/customize-bar-action-edit/customize-bar-action-edit-type/customize-bar-action-edit-type-show-notification/customize-bar-action-edit-type-show-notification.component';
import { CustomizeBarActionEditTypeWorkflowComponent } from './components/customize-bar-action-edit/customize-bar-action-edit-type/customize-bar-action-edit-type-workflow/customize-bar-action-edit-type-workflow.component';
import { CustomizeBarActionEditTypeComponent } from './components/customize-bar-action-edit/customize-bar-action-edit-type/customize-bar-action-edit-type.component';
import { CustomizeBarActionEditWrapperComponent } from './components/customize-bar-action-edit/customize-bar-action-edit-wrapper/customize-bar-action-edit-wrapper.component';
import { CustomizeBarActionEditComponent } from './components/customize-bar-action-edit/customize-bar-action-edit.component';
import { CustomizeBarActionGroupEditComponent } from './components/customize-bar-action-group-edit/customize-bar-action-group-edit.component';
import { CustomizeBarAlertEditComponent } from './components/customize-bar-alert-edit/customize-bar-alert-edit.component';
import { CustomizeBarApproveEditComponent } from './components/customize-bar-approve-edit/customize-bar-approve-edit.component';
import { CustomizeBarBackEditComponent } from './components/customize-bar-back-edit/customize-bar-back-edit.component';
import { CustomizeBarBarCodeEditComponent } from './components/customize-bar-bar-code-edit/customize-bar-bar-code-edit.component';
import { CustomizeBarCardEditComponent } from './components/customize-bar-card-edit/customize-bar-card-edit.component';
import { CustomizeBarColumnEditComponent } from './components/customize-bar-column-edit/customize-bar-column-edit.component';
import { CustomizeBarColumnsEditComponent } from './components/customize-bar-columns-edit/customize-bar-columns-edit.component';
import { CustomizeBarCustomEditComponent } from './components/customize-bar-custom-edit/customize-bar-custom-edit.component';
import { CustomizeBarDataSourceButtonComponent } from './components/customize-bar-data-source-button/customize-bar-data-source-button.component';
import { CustomizeBarFieldsEditComponent } from './components/customize-bar-fields-edit/customize-bar-fields-edit.component';
import { CustomizeBarFileViewerEditComponent } from './components/customize-bar-file-viewer-edit/customize-bar-file-viewer-edit.component';
import { CustomizeBarFilterEditItemsComponent } from './components/customize-bar-filter-edit-items/customize-bar-filter-edit-items.component';
import { CustomizeBarFilterEditComponent } from './components/customize-bar-filter-edit/customize-bar-filter-edit.component';
import { CustomizeBarFormEditGetQueryComponent } from './components/customize-bar-form-edit/customize-bar-form-edit-get-query/customize-bar-form-edit-get-query.component';
import { CustomizeBarFormEditComponent } from './components/customize-bar-form-edit/customize-bar-form-edit.component';
import { CustomizeBarFormSubmitEditComponent } from './components/customize-bar-form-submit-edit/customize-bar-form-submit-edit.component';
import { CustomizeBarHeaderComponent } from './components/customize-bar-header/customize-bar-header.component';
import { CustomizeBarIFrameEditComponent } from './components/customize-bar-iframe-edit/customize-bar-iframe-edit.component';
import { CustomizeBarImageEditComponent } from './components/customize-bar-image-edit/customize-bar-image-edit.component';
import { CustomizeBarInputEditComponent } from './components/customize-bar-input-edit/customize-bar-input-edit.component';
import { CustomizeBarListEditComponent } from './components/customize-bar-list-edit/customize-bar-list-edit.component';
import { CustomizeBarModelEditDataComponent } from './components/customize-bar-model-edit/customize-bar-model-edit-data/customize-bar-model-edit-data.component';
import { CustomizeBarModelEditComponent } from './components/customize-bar-model-edit/customize-bar-model-edit.component';
import { CustomizeBarModelFieldEditComponent } from './components/customize-bar-model-field-edit/customize-bar-model-field-edit.component';
import { CustomizeBarPageEditQueryComponent } from './components/customize-bar-page-edit/customize-bar-page-edit-query/customize-bar-page-edit-query.component';
import { CustomizeBarPageEditComponent } from './components/customize-bar-page-edit/customize-bar-page-edit.component';
import { CustomizeBarPopupEditComponent } from './components/customize-bar-popup-edit/customize-bar-popup-edit.component';
import { CustomizeBarQrCodeEditComponent } from './components/customize-bar-qr-code-edit/customize-bar-qr-code-edit.component';
import { CustomizeBarRangeSliderEditComponent } from './components/customize-bar-range-slider-edit/customize-bar-range-slider-edit.component';
import { CustomizeBarResourceFieldEditComponent } from './components/customize-bar-resource-field-edit/customize-bar-resource-field-edit.component';
import { CustomizeBarScannerEditComponent } from './components/customize-bar-scanner-edit/customize-bar-scanner-edit.component';
import { CustomizeBarSeparatorEditComponent } from './components/customize-bar-separator-edit/customize-bar-separator-edit.component';
import { CustomizeBarSpacingEditComponent } from './components/customize-bar-spacing-edit/customize-bar-spacing-edit.component';
import { CustomizeBarStackEditComponent } from './components/customize-bar-stack-edit/customize-bar-stack-edit.component';
import { CustomizeBarStepsEditComponent } from './components/customize-bar-steps-edit/customize-bar-steps-edit.component';
import { CustomizeBarTabsEditItemComponent } from './components/customize-bar-tabs-edit/customize-bar-tabs-edit-item/customize-bar-tabs-edit-item.component';
import { CustomizeBarTabsEditComponent } from './components/customize-bar-tabs-edit/customize-bar-tabs-edit.component';
import { CustomizeBarTextEditComponent } from './components/customize-bar-text-edit/customize-bar-text-edit.component';
import { CustomizeHomeComponent } from './components/customize-home/customize-home.component';
import { DataSourceBindComponentComponent } from './components/data-source-bind-component/data-source-bind-component.component';
import { DataSourceInputsEditComponent } from './components/data-source-inputs-edit/data-source-inputs-edit.component';
import { DisplayFieldsEditItemComponent } from './components/display-fields-edit-item/display-fields-edit-item.component';
import { DisplayFieldsEditComponent } from './components/display-fields-edit/display-fields-edit.component';
import { GapControlComponent } from './components/gap-control/gap-control.component';
import { CustomizeBarCalendarSettingsDataSourceComponent } from './components/list-layouts/customize-bar-calendar-settings/customize-bar-calendar-settings-data-source/customize-bar-calendar-settings-data-source.component';
import { CustomizeBarCalendarSettingsComponent } from './components/list-layouts/customize-bar-calendar-settings/customize-bar-calendar-settings.component';
import { CustomizeBarCarouselSettingsDataSourceComponent } from './components/list-layouts/customize-bar-carousel-settings/customize-bar-carousel-settings-data-source/customize-bar-carousel-settings-data-source.component';
import { CustomizeBarCarouselSettingsComponent } from './components/list-layouts/customize-bar-carousel-settings/customize-bar-carousel-settings.component';
import { CustomizeBarGridSettingsDataSourceComponent } from './components/list-layouts/customize-bar-grid-settings/customize-bar-grid-settings-data-source/customize-bar-grid-settings-data-source.component';
import { CustomizeBarGridSettingsComponent } from './components/list-layouts/customize-bar-grid-settings/customize-bar-grid-settings.component';
import { CustomizeBarKanbanBoardSettingsDataSourceComponent } from './components/list-layouts/customize-bar-kanban-board-settings/customize-bar-kanban-board-settings-data-source/customize-bar-kanban-board-settings-data-source.component';
import { CustomizeBarKanbanBoardSettingsComponent } from './components/list-layouts/customize-bar-kanban-board-settings/customize-bar-kanban-board-settings.component';
import { CustomizeBarMapSettingsDataSourceComponent } from './components/list-layouts/customize-bar-map-settings/customize-bar-map-settings-data-source/customize-bar-map-settings-data-source.component';
import { CustomizeBarMapSettingsComponent } from './components/list-layouts/customize-bar-map-settings/customize-bar-map-settings.component';
import { CustomizeBarTableSettingsDataSourceComponent } from './components/list-layouts/customize-bar-table-settings/customize-bar-table-settings-data-source/customize-bar-table-settings-data-source.component';
import { CustomizeBarTableSettingsComponent } from './components/list-layouts/customize-bar-table-settings/customize-bar-table-settings.component';
import { CustomizeBarTimelineSettingsDataSourceComponent } from './components/list-layouts/customize-bar-timeline-settings/customize-bar-timeline-settings-data-source/customize-bar-timeline-settings-data-source.component';
import { CustomizeBarTimelineSettingsComponent } from './components/list-layouts/customize-bar-timeline-settings/customize-bar-timeline-settings.component';
import { MarginControlComponent } from './components/margin-control/margin-control.component';
import { ModelDescriptionDataSourceButtonComponent } from './components/model-description-data-source-button/model-description-data-source-button.component';
import { ModelDescriptionDataSourceEditComponent } from './components/model-description-data-source-edit/model-description-data-source-edit.component';
import { OptionEnabledEditOverlayComponent } from './components/option-enabled-edit-overlay/option-enabled-edit-overlay.component';
import { OptionEnabledEditComponent } from './components/option-enabled-edit/option-enabled-edit.component';
import { ProjectPropertyEditPopupComponent } from './components/project-property-edit-popup/project-property-edit-popup.component';
import { RelatedModelComponentsComponent } from './components/related-model-components/related-model-components.component';
import { TableGroupsEditOverlayComponent } from './components/table-groups-edit-overlay/table-groups-edit-overlay.component';
import { TableGroupsEditComponent } from './components/table-groups-edit/table-groups-edit.component';
import { ValueWidgetDataSourceEditComponent } from './components/value-widget-data-source-edit/value-widget-data-source-edit.component';
import { ChartWidgetEditDatasetComponent } from './components/widgets/customize-bar-chart-widget-edit/chart-widget-edit-dataset/chart-widget-edit-dataset.component';
import { CustomizeBarChartWidgetEditDatasetComponent } from './components/widgets/customize-bar-chart-widget-edit/customize-bar-chart-widget-edit-dataset/customize-bar-chart-widget-edit-dataset.component';
import { CustomizeBarChartWidgetEditComponent } from './components/widgets/customize-bar-chart-widget-edit/customize-bar-chart-widget-edit.component';
import { CustomizeBarCompareValueWidgetEditDataComponent } from './components/widgets/customize-bar-value-widget-edit/customize-bar-compare-value-widget-edit-data/customize-bar-compare-value-widget-edit-data.component';
import { CustomizeBarValueWidgetEditDataComponent } from './components/widgets/customize-bar-value-widget-edit/customize-bar-value-widget-edit-data/customize-bar-value-widget-edit-data.component';
import { CustomizeBarValueWidgetEditComponent } from './components/widgets/customize-bar-value-widget-edit/customize-bar-value-widget-edit.component';
import { AddStepDropdownComponent } from './components/workflow/add-step-dropdown/add-step-dropdown.component';
import { AddStepMenuComponent } from './components/workflow/add-step-menu/add-step-menu.component';
import { AutoWorkflowStepComponent } from './components/workflow/auto-workflow-step/auto-workflow-step.component';
import { ActionCustomizeWorkflowStepComponent } from './components/workflow/customize-steps/action-customize-workflow-step/action-customize-workflow-step.component';
import { ConditionCustomizeWorkflowStepSwitchItemComponent } from './components/workflow/customize-steps/condition-customize-workflow-step-switch-item/condition-customize-workflow-step-switch-item.component';
import { ConditionCustomizeWorkflowStepComponent } from './components/workflow/customize-steps/condition-customize-workflow-step/condition-customize-workflow-step.component';
import { DelayCustomizeWorkflowStepComponent } from './components/workflow/customize-steps/delay-customize-workflow-step/delay-customize-workflow-step.component';
import { ExitCustomizeWorkflowStepComponent } from './components/workflow/customize-steps/exit-customize-workflow-step/exit-customize-workflow-step.component';
import { ForkCustomizeWorkflowStepComponent } from './components/workflow/customize-steps/fork-customize-workflow-step/fork-customize-workflow-step.component';
import { IteratorCustomizeWorkflowStepComponent } from './components/workflow/customize-steps/iterator-customize-workflow-step/iterator-customize-workflow-step.component';
import { TransformCustomizeWorkflowStepComponent } from './components/workflow/customize-steps/transform-customize-workflow-step/transform-customize-workflow-step.component';
import { CustomizeWorkflowResultComponent } from './components/workflow/customize-workflow-result/customize-workflow-result.component';
import { CustomizeWorkflowStepHeaderComponent } from './components/workflow/customize-workflow-step-header/customize-workflow-step-header.component';
import { CustomizeWorkflowTriggerOutputComponent } from './components/workflow/customize-workflow-trigger-output/customize-workflow-trigger-output.component';
import { CustomizeWorkflowTriggerComponent } from './components/workflow/customize-workflow-trigger/customize-workflow-trigger.component';
import { ActionWorkflowStepBranchComponent } from './components/workflow/steps/action-workflow-step-branch/action-workflow-step-branch.component';
import { ActionWorkflowStepComponent } from './components/workflow/steps/action-workflow-step/action-workflow-step.component';
import { ConditionWorkflowStepItemComponent } from './components/workflow/steps/condition-workflow-step-item/condition-workflow-step-item.component';
import { ConditionWorkflowStepComponent } from './components/workflow/steps/condition-workflow-step/condition-workflow-step.component';
import { DelayWorkflowStepComponent } from './components/workflow/steps/delay-workflow-step/delay-workflow-step.component';
import { ExitWorkflowStepComponent } from './components/workflow/steps/exit-workflow-step/exit-workflow-step.component';
import { ForkWorkflowStepItemComponent } from './components/workflow/steps/fork-workflow-step-item/fork-workflow-step-item.component';
import { ForkWorkflowStepComponent } from './components/workflow/steps/fork-workflow-step/fork-workflow-step.component';
import { IteratorWorkflowStepComponent } from './components/workflow/steps/iterator-workflow-step/iterator-workflow-step.component';
import { TransformWorkflowStepComponent } from './components/workflow/steps/transform-workflow-step/transform-workflow-step.component';
import { WorkflowResultComponent } from './components/workflow/workflow-result/workflow-result.component';
import { WorkflowRunsItemComponent } from './components/workflow/workflow-runs-item/workflow-runs-item.component';
import { WorkflowRunsComponent } from './components/workflow/workflow-runs/workflow-runs.component';
import { WorkflowSidebarComponent } from './components/workflow/workflow-sidebar/workflow-sidebar.component';
import { WorkflowStepCardComponent } from './components/workflow/workflow-step-card/workflow-step-card.component';
import { WorkflowTriggerComponent } from './components/workflow/workflow-trigger/workflow-trigger.component';
import { WorkflowComponent } from './components/workflow/workflow/workflow.component';
import { ZigZagConnectionComponent } from './components/workflow/zig-zag-connection/zig-zag-connection.component';
import { MarginControlBorderWidthPipe } from './pipes/margin-control-border-width/margin-control-border-width.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgGxSelectModule,
    SharedModule,
    MatMenuModule,
    OverlayModule,
    ObserversModule,
    TokensComponentsModule,
    DragDropModule,
    TipsModule,
    DynamicComponentModule,
    QueriesComponentsModule,
    FieldsModule,
    ProjectsComponentsModule,
    SidebarModule,
    FieldComponentsModule,
    ParametersComponentsModule,
    LocalizeModule,
    CustomViewsRoutesModule,
    SelectModule,
    ModelComponentsModule,
    DragDrop2Module,
    UiModule,
    TutorialModule,
    BannersModule,
    TextEditorModule,
    NgGxScrollableModule,
    EditableContentModule,
    FormUtilsModule,
    FiltersComponentsModule,
    UniqueIdModule,
    CustomizeUiModule,
    ColorsComponentsModule
  ],
  declarations: [
    CustomizeBarActionEditComponent,
    CustomizeBarActionGroupEditComponent,
    CustomizeBarColumnEditComponent,
    CustomizeBarResourceFieldEditComponent,
    CustomizeBarTableSettingsComponent,
    CustomizeBarTableSettingsDataSourceComponent,
    CustomizeBarMapSettingsComponent,
    CustomizeBarMapSettingsDataSourceComponent,
    CustomizeBarKanbanBoardSettingsComponent,
    CustomizeBarKanbanBoardSettingsDataSourceComponent,
    CustomizeBarCalendarSettingsComponent,
    CustomizeBarCalendarSettingsDataSourceComponent,
    CustomizeBarGridSettingsComponent,
    CustomizeBarGridSettingsDataSourceComponent,
    CustomizeBarCarouselSettingsComponent,
    CustomizeBarCarouselSettingsDataSourceComponent,
    CustomizeBarTimelineSettingsComponent,
    CustomizeBarTimelineSettingsDataSourceComponent,
    CustomizeBarListEditComponent,
    CustomizeBarValueWidgetEditComponent,
    CustomizeBarChartWidgetEditComponent,
    CustomizeBarChartWidgetEditDatasetComponent,
    CustomizeBarFieldsEditComponent,
    CustomizeBarHeaderComponent,
    CustomizeBarDataSourceButtonComponent,
    CustomizeBarPageEditComponent,
    CustomizeBarModelEditComponent,
    CustomizeBarFormEditComponent,
    CustomizeBarFormSubmitEditComponent,
    ActionsEditComponent,
    ActionsEditItemComponent,
    ActionSingleEditComponent,
    ActionFormSingleEditComponent,
    CustomizeBarImageEditComponent,
    CustomizeBarApproveEditComponent,
    CustomizeBarCustomEditComponent,
    CustomizeBarTextEditComponent,
    CustomizeBarColumnsEditComponent,
    CustomizeBarCardEditComponent,
    CustomizeBarStackEditComponent,
    CustomizeBarTabsEditComponent,
    CustomizeBarBackEditComponent,
    CustomizeBarImageEditComponent,
    CustomizeBarActionDropdownEditComponent,
    CustomizeBarFormEditGetQueryComponent,
    CustomizeBarModelEditDataComponent,
    CustomizeBarValueWidgetEditDataComponent,
    CustomizeBarCompareValueWidgetEditDataComponent,
    CustomizeBarActionEditOperationComponent,
    CustomizeBarActionEditTypeComponent,
    CustomizeBarActionEditParametersComponent,
    CustomizeBarActionEditElementActionComponent,
    CustomizeBarActionEditDisplayComponent,
    CustomizeBarActionEditCompletionComponent,
    CustomizeBarActionEditWrapperComponent,
    CustomizeBarPageEditQueryComponent,
    CustomizeBarIFrameEditComponent,
    CustomizeBarQrCodeEditComponent,
    CustomizeBarBarCodeEditComponent,
    CustomizeBarScannerEditComponent,
    CustomizeBarAlertEditComponent,
    CustomizeBarFileViewerEditComponent,
    CustomizeBarRangeSliderEditComponent,
    CustomizeBarPopupEditComponent,
    RelatedModelComponentsComponent,
    MarginControlComponent,
    CustomizeBarSeparatorEditComponent,
    CustomizeBarSpacingEditComponent,
    MarginControlBorderWidthPipe,
    ModelDescriptionDataSourceEditComponent,
    DataSourceInputsEditComponent,
    ModelDescriptionDataSourceButtonComponent,
    ValueWidgetDataSourceEditComponent,
    ChartWidgetDataSourceEditComponent,
    CustomizeBarInputEditComponent,
    CustomizeBarModelFieldEditComponent,
    CustomizeBarActionEditTypeQueryComponent,
    CustomizeBarActionEditTypeDownloadComponent,
    CustomizeBarActionEditTypeLinkComponent,
    CustomizeBarActionEditTypeExternalLinkComponent,
    CustomizeBarActionEditTypeShowNotificationComponent,
    CustomizeBarActionEditTypeSetPropertyComponent,
    CustomizeBarActionEditTypeRunJavaScriptComponent,
    CustomizeBarActionEditTypeCopyToClipboardComponent,
    CustomizeBarActionEditTypeExportComponent,
    CustomizeBarActionEditTypeImportComponent,
    CustomizeBarActionEditTypeOpenPopupComponent,
    CustomizeBarActionEditTypeClosePopupComponent,
    CustomizeBarActionEditTypeElementActionComponent,
    CustomizeBarActionEditParametersQueryComponent,
    CustomizeBarActionEditParametersLinkComponent,
    CustomizeBarActionEditParametersExportComponent,
    CustomizeBarActionEditParametersOpenPopupComponent,
    CustomizeBarActionEditParametersElementActionComponent,
    CustomizeBarActionEditTypeWorkflowComponent,
    ProjectPropertyEditPopupComponent,
    WorkflowComponent,
    WorkflowTriggerComponent,
    WorkflowResultComponent,
    AutoWorkflowStepComponent,
    ActionWorkflowStepComponent,
    ActionWorkflowStepBranchComponent,
    ConditionWorkflowStepComponent,
    ConditionWorkflowStepItemComponent,
    ForkWorkflowStepComponent,
    ForkWorkflowStepItemComponent,
    DelayWorkflowStepComponent,
    IteratorWorkflowStepComponent,
    ExitWorkflowStepComponent,
    ZigZagConnectionComponent,
    WorkflowSidebarComponent,
    ConditionCustomizeWorkflowStepComponent,
    IteratorCustomizeWorkflowStepComponent,
    ExitCustomizeWorkflowStepComponent,
    ForkCustomizeWorkflowStepComponent,
    ActionCustomizeWorkflowStepComponent,
    DelayCustomizeWorkflowStepComponent,
    AddStepDropdownComponent,
    CustomizeWorkflowStepHeaderComponent,
    WorkflowStepCardComponent,
    CustomizeWorkflowTriggerComponent,
    CustomizeWorkflowResultComponent,
    CustomizeBarActionEditParametersWorkflowComponent,
    BindComponentComponent,
    DataSourceBindComponentComponent,
    CustomizeBarStepsEditComponent,
    CustomizeBarFilterEditComponent,
    CustomizeBarFilterEditItemsComponent,
    WorkflowRunsComponent,
    WorkflowRunsItemComponent,
    ConditionCustomizeWorkflowStepSwitchItemComponent,
    CustomizeWorkflowTriggerOutputComponent,
    AddStepMenuComponent,
    TransformWorkflowStepComponent,
    TransformCustomizeWorkflowStepComponent,
    DisplayFieldsEditComponent,
    DisplayFieldsEditItemComponent,
    CustomizeBarTabsEditItemComponent,
    ChartWidgetEditDatasetComponent,
    CustomizeHomeComponent,
    OptionEnabledEditComponent,
    OptionEnabledEditOverlayComponent,
    TableGroupsEditComponent,
    TableGroupsEditOverlayComponent,
    CustomViewItemEditComponent,
    ConfirmationEditOverlayComponent,
    ConfirmationEditComponent,
    GapControlComponent
  ],
  exports: [
    CustomizeBarHeaderComponent,
    CustomizeBarFieldsEditComponent,
    CustomizeBarActionEditTypeComponent,
    CustomizeBarActionEditParametersComponent,
    ModelDescriptionDataSourceEditComponent,
    DataSourceInputsEditComponent,
    CustomizeHomeComponent,
    CustomizeBarInputEditComponent,
    CustomizeBarActionEditComponent,
    ActionsEditItemComponent
  ],
  entryComponents: [
    CustomizeBarActionEditComponent,
    CustomizeBarActionGroupEditComponent,
    CustomizeBarColumnEditComponent,
    CustomizeBarResourceFieldEditComponent,
    CustomizeBarTableSettingsComponent,
    CustomizeBarTableSettingsDataSourceComponent,
    CustomizeBarMapSettingsComponent,
    CustomizeBarMapSettingsDataSourceComponent,
    CustomizeBarKanbanBoardSettingsComponent,
    CustomizeBarKanbanBoardSettingsDataSourceComponent,
    CustomizeBarCalendarSettingsComponent,
    CustomizeBarCalendarSettingsDataSourceComponent,
    CustomizeBarGridSettingsComponent,
    CustomizeBarGridSettingsDataSourceComponent,
    CustomizeBarCarouselSettingsComponent,
    CustomizeBarCarouselSettingsDataSourceComponent,
    CustomizeBarTimelineSettingsComponent,
    CustomizeBarTimelineSettingsDataSourceComponent,
    CustomizeBarListEditComponent,
    CustomizeBarValueWidgetEditComponent,
    CustomizeBarChartWidgetEditComponent,
    CustomizeBarChartWidgetEditDatasetComponent,
    CustomizeBarPageEditComponent,
    CustomizeBarModelEditComponent,
    CustomizeBarFormEditComponent,
    CustomizeBarFormSubmitEditComponent,
    CustomizeBarFieldsEditComponent,
    CustomizeBarImageEditComponent,
    CustomizeBarCustomEditComponent,
    CustomizeBarTextEditComponent,
    CustomizeBarColumnsEditComponent,
    CustomizeBarCardEditComponent,
    CustomizeBarStackEditComponent,
    CustomizeBarTabsEditComponent,
    CustomizeBarBackEditComponent,
    CustomizeBarActionDropdownEditComponent,
    CustomizeBarFormEditGetQueryComponent,
    CustomizeBarModelEditDataComponent,
    CustomizeBarValueWidgetEditDataComponent,
    CustomizeBarCompareValueWidgetEditDataComponent,
    CustomizeBarActionEditOperationComponent,
    CustomizeBarPageEditQueryComponent,
    CustomizeBarIFrameEditComponent,
    CustomizeBarQrCodeEditComponent,
    CustomizeBarBarCodeEditComponent,
    CustomizeBarScannerEditComponent,
    CustomizeBarAlertEditComponent,
    CustomizeBarFileViewerEditComponent,
    CustomizeBarRangeSliderEditComponent,
    CustomizeBarPopupEditComponent,
    CustomizeBarSeparatorEditComponent,
    CustomizeBarSpacingEditComponent,
    CustomizeBarModelFieldEditComponent,
    CustomizeBarActionEditTypeWorkflowComponent,
    ProjectPropertyEditPopupComponent,
    WorkflowComponent,
    ActionWorkflowStepComponent,
    ConditionWorkflowStepComponent,
    ForkWorkflowStepComponent,
    DelayWorkflowStepComponent,
    IteratorWorkflowStepComponent,
    ExitWorkflowStepComponent,
    ConditionCustomizeWorkflowStepComponent,
    IteratorCustomizeWorkflowStepComponent,
    ExitCustomizeWorkflowStepComponent,
    ForkCustomizeWorkflowStepComponent,
    ActionCustomizeWorkflowStepComponent,
    DelayCustomizeWorkflowStepComponent,
    CustomizeWorkflowTriggerComponent,
    CustomizeWorkflowResultComponent,
    CustomizeBarStepsEditComponent,
    CustomizeBarFilterEditComponent,
    WorkflowRunsComponent,
    TransformWorkflowStepComponent,
    TransformCustomizeWorkflowStepComponent
  ]
})
export class CustomizeBarModule {}
