import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LocalStorage } from '@core';
import { addClass, removeClass } from '@shared';

import { projectColors } from '../../data/colors';

@Injectable()
export class ThemeService implements OnDestroy {
  _theme = new BehaviorSubject<string>('default');
  _color = new BehaviorSubject<string>('blue');
  _staffEnabled = new BehaviorSubject<boolean>(false);

  constructor(private storageService: LocalStorage) {
    if (this.storageService.get('theme')) {
      this._theme.next(this.storageService.get('theme'));
    }

    if (this.storageService.get('staff_enabled')) {
      this._staffEnabled.next(this.storageService.get('staff_enabled') == '1');
    }

    this.theme$.subscribe(() => this.onUpdated());
    this.color$.subscribe(() => this.onUpdated());
    this.staffEnabled$.subscribe(value => {
      if (value) {
        addClass(document.body, 'staff-enabled');
      } else {
        removeClass(document.body, 'staff-enabled');
      }
    });
  }

  ngOnDestroy(): void {
    this.clearClasses();
  }

  get theme(): string {
    return this._theme.value;
  }

  get theme$(): Observable<string> {
    return this._theme.asObservable();
  }

  set theme(value: string) {
    this._theme.next(value);
    this.storageService.set('theme', value);
  }

  get staffEnabled(): boolean {
    return this._staffEnabled.value;
  }

  get staffEnabled$(): Observable<boolean> {
    return this._staffEnabled.asObservable();
  }

  set staffEnabled(value: boolean) {
    this._staffEnabled.next(value);
    this.storageService.set('staff_enabled', value ? '1' : '0');
  }

  setDefaultTheme(value: string) {
    if (!this.storageService.get('theme')) {
      this.theme = value;
    }
  }

  get isDefaultTheme(): boolean {
    return this._theme.value == 'default';
  }

  get isDefaultTheme$(): Observable<boolean> {
    return this._theme.pipe(map(theme => theme == 'default'));
  }

  get color(): string {
    return this._color.value;
  }

  get color$(): Observable<string> {
    return this._color.asObservable();
  }

  set color(value: string) {
    this._color.next(value);
  }

  toggleTheme() {
    this.theme = this.theme != 'default' ? 'default' : 'dark';
  }

  toggleStaffEnabled() {
    this.staffEnabled = !this.staffEnabled;
  }

  clearClasses() {
    removeClass(document.body, `theme_default`);
    removeClass(document.body, `theme_dark`);
  }

  addClasses() {
    addClass(document.body, `theme_${this.theme}`);

    projectColors.forEach(item => removeClass(document.body, `body_color_${item}`));
    addClass(document.body, `body_color_${this.color}`);
  }

  onUpdated() {
    this.clearClasses();
    this.addClasses();
  }
}
