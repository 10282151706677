import { ChangeDetectionStrategy, Component } from '@angular/core';

import { TintStyle } from '@modules/customize';

import { ProjectUserForm } from '../../forms/project-user.form';
import { ProjectGroupChangeComponent } from '../project-group-change/project-group-change.component';
import { ProjectGroupChangeForm } from '../project-group-change/project-group-change.form';
import { ProjectModelPermissionsArray } from '../project-group-change/project-model-permissions.array';
import { ProjectPagePermissionsArray } from '../project-group-change/project-page-permissions.array';

@Component({
  selector: 'app-project-group-change-popup',
  templateUrl: './project-group-change-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProjectGroupChangeForm, ProjectModelPermissionsArray, ProjectPagePermissionsArray, ProjectUserForm]
})
export class ProjectGroupChangePopupComponent extends ProjectGroupChangeComponent {
  tintStyles = TintStyle;
}
