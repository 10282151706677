/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/components/loader-small/loader-small.component.ngfactory";
import * as i2 from "../../../../shared/components/loader-small/loader-small.component";
import * as i3 from "@angular/common";
import * as i4 from "../../../../common/tips/directives/tip/tip.directive";
import * as i5 from "../../../theme-components/pipes/theme-color-hex-option/theme-color-hex-option.pipe";
import * as i6 from "../../../theme-components/data/theme-context";
import * as i7 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i8 from "../../../../shared/pipes/capitalize/capitalize.pipe";
import * as i9 from "../../../colors-shared/directives/tint-color/tint-color.directive";
import * as i10 from "@angular/platform-browser";
import * as i11 from "./table-item-column-action.component";
import * as i12 from "../../../customize/services/customize/customize.service";
import * as i13 from "../../../action-queries/services/action/action.service";
import * as i14 from "../../../action-queries/services/action-controller/action-controller.service";
import * as i15 from "../../../../common/notifications/services/notification/notification.service";
var styles_TableItemColumnActionComponent = [];
var RenderType_TableItemColumnActionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TableItemColumnActionComponent, data: {} });
export { RenderType_TableItemColumnActionComponent as RenderType_TableItemColumnActionComponent };
function View_TableItemColumnActionComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-loader-small", [["class", "related-link-action__icon"]], [[2, "related-link-action__icon_left", null]], null, null, i1.View_LoaderSmallComponent_0, i1.RenderType_LoaderSmallComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoaderSmallComponent, [], null, null), i0.ɵppd(2, 1)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 0, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 1), _co.verboseName)); _ck(_v, 0, 0, currVal_0); }); }
function View_TableItemColumnActionComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "related-link-action__icon"]], [[2, "related-link-action__icon_left", null]], null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i3.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵppd(2, 1)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "related-link-action__icon"; var currVal_2 = ("icon-" + _co.item.icon); _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 0, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 1), _co.verboseName)); _ck(_v, 0, 0, currVal_0); }); }
function View_TableItemColumnActionComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 2), _co.verboseName)); _ck(_v, 1, 0, currVal_0); }); }
function View_TableItemColumnActionComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "element__indicator element__indicator_outside icon-eye"]], null, null, null, null, null)), i0.ɵdid(1, 737280, null, 0, i4.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"] }, null)], function (_ck, _v) { var currVal_0 = "Action is hidden"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TableItemColumnActionComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i5.ThemeColorHexOptionPipe, [[2, i6.ThemeContext]]), i0.ɵpid(0, i7.IsSetPipe, []), i0.ɵpid(0, i8.CapitalizePipe, []), (_l()(), i0.ɵeld(3, 0, null, null, 10, "a", [["class", "related-link-action"], ["href", "javascript:void(0)"]], [[2, "related-link-action_disabled", null], [8, "style", 2]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.executeAction(_co.item) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 540672, null, 0, i9.TintColorDirective, [i10.DomSanitizer], { appTintColor: [0, "appTintColor"], appTintColorStyle: [1, "appTintColorStyle"] }, null), i0.ɵppd(5, 1), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TableItemColumnActionComponent_1)), i0.ɵdid(8, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TableItemColumnActionComponent_2)), i0.ɵdid(10, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_TableItemColumnActionComponent_3)), i0.ɵdid(12, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(13, 1), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_TableItemColumnActionComponent_4)), i0.ɵdid(15, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 6).transform(i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v, 0), "accentColor")))); var currVal_3 = _co.tintStyles.Default; _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_4 = _co.submitLoading; _ck(_v, 8, 0, currVal_4); var currVal_5 = (_co.item.icon && !_co.submitLoading); _ck(_v, 10, 0, currVal_5); var currVal_6 = i0.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i0.ɵnov(_v, 1), _co.verboseName)); _ck(_v, 12, 0, currVal_6); var currVal_7 = (!_co.isVisible && i0.ɵunv(_v, 15, 0, i0.ɵnov(_v, 16).transform(_co.customizeService.enabled$))); _ck(_v, 15, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.disabled || _co.submitLoading); var currVal_1 = i0.ɵnov(_v, 4).style; _ck(_v, 3, 0, currVal_0, currVal_1); }); }
export function View_TableItemColumnActionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-table-item-column-action", [], [[2, "element__indicator-wrapper", null], [2, "hidden", null]], null, null, View_TableItemColumnActionComponent_0, RenderType_TableItemColumnActionComponent)), i0.ɵdid(1, 770048, null, 0, i11.TableItemColumnActionComponent, [i12.CustomizeService, i13.ActionService, i14.ActionControllerService, i15.NotificationService, i0.Injector, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).indicatorWrapper; var currVal_1 = i0.ɵnov(_v, 1).hidden; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var TableItemColumnActionComponentNgFactory = i0.ɵccf("app-table-item-column-action", i11.TableItemColumnActionComponent, View_TableItemColumnActionComponent_Host_0, { row: "row", item: "item", context: "context", contextElement: "contextElement" }, {}, []);
export { TableItemColumnActionComponentNgFactory as TableItemColumnActionComponentNgFactory };
