import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Power2, TimelineMax } from 'gsap';
import defaults from 'lodash/defaults';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { BasePopupComponent } from '@common/popups';
import { TintStyle } from '@modules/customize';
import { KeyboardEventKeyCode } from '@shared';

import { DialogResult } from '../../data/dialog-result';
import { DialogButton, DialogButtonHotkey, DialogButtonType, DialogOptions } from '../../data/options';
import { DialogForm } from './dialog.form';

export const DefaultDialogOptions: DialogOptions = {
  title: '',
  description: '',
  subtitle: undefined,
  buttons: [
    {
      name: 'cancel',
      label: 'Cancel',
      type: DialogButtonType.Default,
      hotkey: DialogButtonHotkey.Cancel
    },
    {
      name: 'ok',
      label: 'OK',
      type: DialogButtonType.Primary,
      hotkey: DialogButtonHotkey.Submit
    }
  ],
  style: 'default'
};

@Component({
  templateUrl: 'dialog.component.html',
  providers: [DialogForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() options: DialogOptions;
  @Output() closed = new EventEmitter<DialogResult>();

  @ViewChild('root') root: ElementRef;
  @ViewChild('background') background: ElementRef;

  tl = new TimelineMax();
  animating = false;
  dialogButtonTypes = DialogButtonType;
  tintStyles = TintStyle;
  buttonLoading: string;

  constructor(public form: DialogForm, private popupComponent: BasePopupComponent, private cd: ChangeDetectorRef) {}

  get currentOptions(): DialogOptions {
    return defaults(this.options, DefaultDialogOptions);
  }

  ngOnInit(): void {
    this.form.init(this.currentOptions);
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    fromEvent<KeyboardEvent>(document, 'keydown')
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (e.keyCode == KeyboardEventKeyCode.Enter) {
          e.preventDefault();
          this.clickSubmitButton();
        } else if (e.keyCode == KeyboardEventKeyCode.Escape) {
          e.preventDefault();
          this.clickCancelButton();
        }
      });

    this.show();
  }

  show() {
    if (this.animating) {
      return;
    }

    this.animating = true;
    this.tl
      .clear()
      .fromTo(
        this.background.nativeElement,
        0,
        {
          opacity: 0
        },
        {
          opacity: 1,
          ease: Power2.easeOut
        }
      )
      .fromTo(
        this.root.nativeElement,
        0,
        {
          scale: 0.9,
          opacity: 0
        },
        {
          scale: 1,
          opacity: 1,
          ease: Power2.easeOut
        },
        0
      )
      .add(() => {
        this.animating = false;
      });
  }

  clickSubmitButton() {
    const button = this.currentOptions.buttons.find(item => item.hotkey == DialogButtonHotkey.Submit);
    if (button) {
      this.submit(button.name);
    }
  }

  clickCancelButton() {
    const button = this.currentOptions.buttons.find(item => item.hotkey == DialogButtonHotkey.Cancel);
    if (button) {
      this.submit(button.name);
    }
  }

  submit(buttonName: string) {
    if (this.animating) {
      return;
    }

    const button = this.currentOptions.buttons.find(item => item.name == buttonName);

    if (button && button.type == DialogButtonType.Submit && this.form.form.invalid) {
      return;
    }

    let obs: Observable<any>;
    const result: DialogResult = {
      button: buttonName,
      form: this.form.form.value
    };

    if (button.executor) {
      const executorObs = button.executor(result);

      if (executorObs instanceof Observable) {
        obs = executorObs;
      } else {
        obs = of(executorObs);
      }
    }

    if (!obs) {
      obs = of(undefined);
    }

    this.buttonLoading = buttonName;
    this.cd.markForCheck();

    obs
      .pipe(
        map(executorResult => {
          return {
            executorResult: executorResult
          };
        }),
        catchError<any, { executorResult?: any; executorError?: any }>(executorError => {
          return of({
            executorResult: executorError,
            executorError: executorError
          });
        }),
        untilDestroyed(this)
      )
      .subscribe(value => {
        this.hide({
          button: buttonName,
          form: this.form.form.value,
          executorResult: value.executorResult,
          executorError: value.executorError
        });
      });
  }

  hide(result: DialogResult) {
    if (this.animating) {
      return;
    }

    this.animating = true;
    this.tl
      .clear()
      .to(
        this.root.nativeElement,
        0.2,
        {
          scale: 0.9,
          opacity: 0,
          ease: Power2.easeIn
        },
        0
      )
      .to(this.background.nativeElement, 0.2, {
        opacity: 0,
        ease: Power2.easeIn
      })
      .add(() => {
        this.animating = false;
        this.popupComponent.close();
        this.closed.next(result);
      });
  }

  miss() {
    if (this.animating) {
      return;
    }

    this.animating = true;
    this.tl
      .clear()
      .fromTo(
        this.root.nativeElement,
        0.1,
        {
          scale: 1
        },
        {
          scale: 1.025,
          ease: Power2.easeOut
        }
      )
      .fromTo(
        this.root.nativeElement,
        0.1,
        {
          scale: 1.025,
          immediateRender: false
        },
        {
          scale: 1,
          ease: Power2.easeIn
        }
      )
      .add(() => {
        this.animating = false;
      });
  }
}
