/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./json-field-node/json-field-node.component.ngfactory";
import * as i2 from "./json-field-node/json-field-node.component";
import * as i3 from "../field-wrapper/field-wrapper.component.ngfactory";
import * as i4 from "../field-wrapper/field-wrapper.component";
import * as i5 from "@angular/common";
import * as i6 from "./json-field.component";
import * as i7 from "../../../../core/services/scripts/scripts.service";
var styles_JsonFieldComponent = [];
var RenderType_JsonFieldComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_JsonFieldComponent, data: {} });
export { RenderType_JsonFieldComponent as RenderType_JsonFieldComponent };
function View_JsonFieldComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[1, 0], ["editor_element", 1]], null, 0, "div", [["class", "field__editor field__editor_json"]], [[2, "field__editor_disabled", null]], [[null, "wheel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("wheel" === en)) {
        var pd_0 = (_co.onWheel($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.control == null) ? null : _co.control.disabled); _ck(_v, 0, 0, currVal_0); }); }
function View_JsonFieldComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-json-field-node", [], null, null, null, i1.View_JsonFieldNodeComponent_0, i1.RenderType_JsonFieldNodeComponent)), i0.ɵdid(1, 770048, null, 0, i2.JsonFieldNodeComponent, [i0.ChangeDetectorRef], { node: [0, "node"], form: [1, "form"], editable: [2, "editable"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rootNode; var currVal_1 = _co.structureForm; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_JsonFieldComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "app-field-wrapper", [], null, null, null, i3.View_FieldWrapperComponent_0, i3.RenderType_FieldWrapperComponent)), i0.ɵdid(1, 245760, null, 0, i4.FieldWrapperComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], readonly: [2, "readonly"], requiredAsterisk: [3, "requiredAsterisk"], label: [4, "label"], errors: [5, "errors"], truncate: [6, "truncate"], manualMargin: [7, "manualMargin"], labelButtons: [8, "labelButtons"], tooltip: [9, "tooltip"], idToken: [10, "idToken"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_JsonFieldComponent_2)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_JsonFieldComponent_3)), i0.ɵdid(5, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; var currVal_1 = _co.field; var currVal_2 = _co.readonly; var currVal_3 = _co.requiredAsterisk; var currVal_4 = _co.label; var currVal_5 = _co.errors; var currVal_6 = _co.truncate; var currVal_7 = _co.manualMargin; var currVal_8 = _co.labelButtons; var currVal_9 = _co.tooltip; var currVal_10 = _co.idToken; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10]); var currVal_11 = (!_co.field.params["display_fields"] || !_co.rootNode); _ck(_v, 3, 0, currVal_11); var currVal_12 = (_co.field.params["display_fields"] && _co.rootNode); _ck(_v, 5, 0, currVal_12); }, null); }
function View_JsonFieldComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-json-field-node", [], null, null, null, i1.View_JsonFieldNodeComponent_0, i1.RenderType_JsonFieldNodeComponent)), i0.ɵdid(1, 770048, null, 0, i2.JsonFieldNodeComponent, [i0.ChangeDetectorRef], { node: [0, "node"], form: [1, "form"], editable: [2, "editable"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rootNode; var currVal_1 = _co.structureForm; var currVal_2 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_JsonFieldComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "text-truncate"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formatEmpty(_co.serializeValue(_co.currentValue)); _ck(_v, 1, 0, currVal_0); }); }
function View_JsonFieldComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "app-field-wrapper", [], null, null, null, i3.View_FieldWrapperComponent_0, i3.RenderType_FieldWrapperComponent)), i0.ɵdid(1, 245760, null, 0, i4.FieldWrapperComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], readonly: [2, "readonly"], requiredAsterisk: [3, "requiredAsterisk"], label: [4, "label"], errors: [5, "errors"], truncate: [6, "truncate"], manualMargin: [7, "manualMargin"], labelButtons: [8, "labelButtons"], tooltip: [9, "tooltip"], idToken: [10, "idToken"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_JsonFieldComponent_5)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_JsonFieldComponent_6)), i0.ɵdid(5, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; var currVal_1 = _co.field; var currVal_2 = _co.readonly; var currVal_3 = _co.requiredAsterisk; var currVal_4 = _co.label; var currVal_5 = _co.errors; var currVal_6 = _co.truncate; var currVal_7 = _co.manualMargin; var currVal_8 = _co.labelButtons; var currVal_9 = _co.tooltip; var currVal_10 = _co.idToken; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10]); var currVal_11 = (_co.field.params["display_fields"] && _co.rootNode); _ck(_v, 3, 0, currVal_11); var currVal_12 = !_co.field.params["display_fields"]; _ck(_v, 5, 0, currVal_12); }, null); }
export function View_JsonFieldComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { editorElement: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_JsonFieldComponent_1)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_JsonFieldComponent_4)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.readonly; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.readonly; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_JsonFieldComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-json-field", [], null, null, null, View_JsonFieldComponent_0, RenderType_JsonFieldComponent)), i0.ɵdid(1, 4964352, null, 0, i6.JsonFieldComponent, [i7.ScriptsService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var JsonFieldComponentNgFactory = i0.ɵccf("app-json-field", i6.JsonFieldComponent, View_JsonFieldComponent_Host_0, { form: "form", field: "field", readonly: "readonly", requiredAsterisk: "requiredAsterisk", value: "value", label: "label", errors: "errors", autofocus: "autofocus", context: "context", contextElement: "contextElement", truncate: "truncate", compact: "compact", manualMargin: "manualMargin", labelButtons: "labelButtons", tooltip: "tooltip", accentColor: "accentColor" }, {}, []);
export { JsonFieldComponentNgFactory as JsonFieldComponentNgFactory };
