import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { debounceTime, delay, filter, map, switchMap, take } from 'rxjs/operators';

import { copyTextToClipboard } from '@common/code';
import { NotificationService } from '@common/notifications';
import { SelectComponent } from '@common/select';
import { ActionService } from '@modules/action-queries';
import { ActionDescription, ActionItem, ActionType, QueryAction } from '@modules/actions';
import { ApiService } from '@modules/api';
import { ViewContextElement } from '@modules/customize';
import { CustomSelectItem } from '@modules/field-components';
import { createFormFieldFactory, FieldOutput, OptionsType, ParameterField } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ActionQuery, QueryType } from '@modules/queries';
import { SidebarCollapseContext } from '@modules/sidebar';
import {
  Automation,
  AutomationService,
  AutomationTrigger,
  AutomationTriggerType,
  AutomationTriggerWebhook,
  ModelAutomationTrigger,
  TimetableType,
  WebhookAutomationTrigger
} from '@modules/workflow';
import { controlValue, isSet } from '@shared';

import { CustomizeBarContext } from '../../../services/customize-bar-context/customize-bar.context';
import { CustomizeWorkflowTriggerForm, WorkflowTriggerSaveEvent } from './customize-workflow-trigger.form';

@Component({
  selector: 'app-customize-workflow-trigger',
  templateUrl: './customize-workflow-trigger.component.html',
  providers: [CustomizeWorkflowTriggerForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeWorkflowTriggerComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() automation: Automation;
  @Input() triggerEditable = false;
  @Input() trigger: AutomationTrigger;
  @Input() triggerOutputs: FieldOutput[];
  @Input() triggerData: any;
  @Input() testParameters: Object;
  @Input() workflowEditable = false;
  @Input() parametersEnabled = false;
  @Input() parameters: ParameterField[];
  @Input() webhookUpdated$: Observable<AutomationTriggerWebhook>;
  @Input() contextElement: ViewContextElement;
  @Input() analyticsSource: string;
  @Output() workflowChange = new EventEmitter<WorkflowTriggerSaveEvent>();
  @Output() closeCustomize = new EventEmitter<void>();

  @ViewChild('type_select', { read: SelectComponent }) typeSelect: SelectComponent;

  createField = createFormFieldFactory();
  triggerTypes = AutomationTriggerType;
  timetableTypes = TimetableType;
  optionsTypes = OptionsType;
  actionItems: CustomSelectItem<string>[] = [];
  webhook: AutomationTriggerWebhook;
  webhookLoading = true;
  webhookUrl: string;
  collapseContext = new SidebarCollapseContext();
  parametersValue = {};
  outputs: FieldOutput[] = [];
  arrayOutput = false;

  trackOutput = (() => {
    return (i, item: FieldOutput) => {
      return isSet(item.name) ? item.name : `index_${i}`;
    };
  })();

  exactValueEquals = (lhs, rhs) => lhs === rhs;

  constructor(
    public form: CustomizeWorkflowTriggerForm,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private automationService: AutomationService,
    private actionService: ActionService,
    private customizeBarContext: CustomizeBarContext,
    private notificationService: NotificationService,
    private apiService: ApiService,
    private zone: NgZone,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.form.init({
      triggerEditable: this.triggerEditable,
      trigger: this.trigger,
      testParameters: this.testParameters,
      parameters: this.parameters
    });

    this.form.valueChanges
      .pipe(
        debounceTime(200),
        map(() => this.form.submit()),
        untilDestroyed(this)
      )
      .subscribe(result => {
        this.trigger = result.trigger;
        this.updateContextOutputs();
        this.submit(result);
      });

    this.updateContextOutputs();

    this.contextElement.outputsValue$.pipe(untilDestroyed(this)).subscribe(value => {
      this.parametersValue = value;
      this.cd.markForCheck();
    });

    this.form
      .triggerModelActionItems$()
      .pipe(untilDestroyed(this))
      .subscribe(items => {
        this.actionItems = items;
        this.cd.markForCheck();
      });

    controlValue<AutomationTriggerType>(this.form.controls.trigger_type)
      .pipe(
        filter(item => item == AutomationTriggerType.Webhook),
        switchMap(() =>
          this.automationService.getAutomationTriggerWebhook(
            this.currentProjectStore.instance,
            this.currentEnvironmentStore.instance,
            this.automation
          )
        ),
        untilDestroyed(this)
      )
      .subscribe(webhook => {
        this.setWebhook(webhook);

        if (this.form.controls.trigger_webhook_token.value != webhook.token) {
          this.form.controls.trigger_webhook_token.patchValue(webhook.token);
        }
      });

    if (this.webhookUpdated$) {
      this.webhookUpdated$.pipe(untilDestroyed(this)).subscribe(result => {
        this.setWebhook(result);
        this.contextElement.setOutputValues(result.testData || {});
        this.form.controls.test_parameters.patchValue(result.testData || {});
      });
    }
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.zone.onStable.pipe(take(1), delay(100), untilDestroyed(this)).subscribe(() => {
      if (this.typeSelect && !this.form.controls.trigger_type.value) {
        this.typeSelect.open();
      }
    });
  }

  copyWebhookUrl() {
    copyTextToClipboard(this.webhookUrl)
      .pipe(untilDestroyed(this))
      .subscribe(success => {
        if (!success) {
          return;
        }

        this.notificationService.info('Copied', 'Webhook URL was copied to clipboard');
      });
  }

  reinitializeStructure() {
    this.webhookLoading = true;
    this.cd.markForCheck();

    this.automationService
      .automationTriggerWebhookReinitializeStructure(
        this.currentProjectStore.instance,
        this.currentEnvironmentStore.instance,
        this.webhook
      )
      .pipe(untilDestroyed(this))
      .subscribe(webhook => {
        this.setWebhook(webhook);

        this.notificationService.info(
          'Listening for the data',
          'We are listening for the data to determine data structure from request.'
        );
      });
  }

  setWebhook(webhook: AutomationTriggerWebhook) {
    const hookPath = webhook ? webhook.getRunPath() : undefined;

    this.webhook = webhook;
    this.webhookUrl = webhook ? this.apiService.workflowsMethodURL(hookPath) : undefined;
    this.webhookLoading = false;
    this.cd.markForCheck();
    this.updateContextOutputs();
  }

  updateContextOutputs() {
    if (this.triggerOutputs) {
      this.outputs = [...this.triggerOutputs];
      this.arrayOutput = false;
      this.cd.markForCheck();
    } else if (this.triggerEditable) {
      if (this.trigger instanceof ModelAutomationTrigger) {
        const actionDescription = new ActionDescription();

        actionDescription.resource = this.trigger.resource;
        actionDescription.type = ActionType.Query;
        actionDescription.model = this.trigger.model;
        actionDescription.modelAction = this.trigger.action;
        actionDescription.queryAction = new QueryAction();
        actionDescription.queryAction.query = new ActionQuery();
        actionDescription.queryAction.query.queryType = QueryType.Simple;
        actionDescription.queryAction.query.simpleQuery = new actionDescription.queryAction.query.simpleQueryClass();
        actionDescription.queryAction.query.simpleQuery.name = `__${this.trigger.model}__${this.trigger.action}`;

        const action = new ActionItem();

        action.actionDescription = actionDescription;

        this.actionService
          .getActionOutputs(action)
          .pipe(untilDestroyed(this))
          .subscribe(result => {
            this.outputs = result.outputs || [];
            this.arrayOutput = isSet(result.arrayOutput) ? result.arrayOutput : false;
            this.cd.markForCheck();
          });
      } else if (this.trigger instanceof WebhookAutomationTrigger) {
        this.outputs = this.webhook && isSet(this.webhook.dataStructure) ? this.webhook.dataStructure : [];
        this.arrayOutput =
          this.webhook && isSet(this.webhook.dataStructureArray) ? this.webhook.dataStructureArray : false;
        this.cd.markForCheck();
      }
    } else {
      this.outputs = [];
      this.arrayOutput = false;
      this.cd.markForCheck();
    }
  }

  updateParameterValue(name: string, value: any) {
    this.contextElement.setOutputValue(name, value);
    this.form.controls.test_parameters.patchValue(this.contextElement.outputsValue);
  }

  submit(value?: WorkflowTriggerSaveEvent) {
    if (!value) {
      value = this.form.submit();
    }

    this.workflowChange.emit(value);
  }

  close() {
    this.customizeBarContext.popSettingsComponent();
  }
}
