var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import toPairs from 'lodash/toPairs';
import { CurrentEnvironmentStore, ProjectPermission, ProjectPermissionType } from '@modules/projects';
import { ActionQuery, ChartWidgetQuery, ModelDescriptionQuery, QueryType, ValueWidgetQuery } from '@modules/queries';
import { traverseElementItemQueries } from '../../utils/traverse';
var PagePermissionsService = /** @class */ (function () {
    function PagePermissionsService(currentEnvironmentStore) {
        this.currentEnvironmentStore = currentEnvironmentStore;
    }
    PagePermissionsService.prototype.getModelPermissionsFromPagePermissions = function (pagePermissions, existingModelPermissions, viewSettings, actions) {
        var _this = this;
        var modelPermissions = {};
        var addModelPermission = function (modelId, add) {
            modelPermissions[modelId] = __assign({}, modelPermissions[modelId], add);
        };
        pagePermissions.forEach(function (permission) {
            var page = viewSettings.find(function (item) { return item.uid == permission.permissionObject; });
            if (!page) {
                return;
            }
            traverseElementItemQueries(page, function (resourceName, query) {
                var resource = _this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == resourceName; });
                if (!resource || resource.demo) {
                    return;
                }
                if ((query instanceof ModelDescriptionQuery ||
                    query instanceof ValueWidgetQuery ||
                    query instanceof ChartWidgetQuery) &&
                    query.queryType == QueryType.Simple &&
                    query.simpleQuery) {
                    var modelId = [resourceName, query.simpleQuery.model].join('.');
                    if (permission.permissionActions.includes('r')) {
                        addModelPermission(modelId, { read: true });
                    }
                }
                else if (query instanceof ActionQuery && query.queryType == QueryType.Simple && query.simpleQuery) {
                    var action = actions.find(function (item) { return item.resource == resourceName && item.name == query.simpleQuery.name; });
                    if (action && action.model) {
                        var modelId = [resourceName, action.model].join('.');
                        if (permission.permissionActions.includes('r') &&
                            (action.modelAction == 'get' || action.modelAction == 'get_detail')) {
                            addModelPermission(modelId, { read: true });
                        }
                        else if (permission.permissionActions.includes('w') &&
                            (action.modelAction == 'create' || action.modelAction == 'update')) {
                            addModelPermission(modelId, { write: true });
                        }
                        else if (permission.permissionActions.includes('d') && action.modelAction == 'delete') {
                            addModelPermission(modelId, { delete: true });
                        }
                    }
                }
            });
        });
        return toPairs(modelPermissions).map(function (_a) {
            var modelId = _a[0], permissionActions = _a[1];
            var modelPermission = new ProjectPermission();
            var existingPermission = existingModelPermissions.find(function (item) { return item.permissionObject == modelId; });
            if (existingPermission && existingPermission.id) {
                modelPermission.id = existingPermission.id;
            }
            modelPermission.permissionType = ProjectPermissionType.Model;
            modelPermission.permissionObject = modelId;
            modelPermission.permissionActions = [
                permissionActions.read ? 'r' : '',
                permissionActions.write ? 'w' : '',
                permissionActions.delete ? 'd' : ''
            ].join('');
            return modelPermission;
        });
    };
    return PagePermissionsService;
}());
export { PagePermissionsService };
