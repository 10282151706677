<ng-container *ngIf="!loading && control.type == displayFieldTypes.CustomView">
  <mat-menu #dropdown="matMenu">
    <ng-template matMenuContent>
      <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" (click)="openViewEditor.emit(view)">
        <span class="choose-items-item__dropdown-icon icon-canvas"></span>
        <ng-container *ngIf="view">Customize with Designer</ng-container>
        <ng-container *ngIf="!view">Create with Designer</ng-container>
      </button>

      <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" (click)="openCustomViewTemplates.emit()">
        <span class="choose-items-item__dropdown-icon icon-blocks"></span>
        Use Marketplace component
      </button>

      <button
        *ngIf="view"
        mat-menu-item
        class="mat-menu-item-odd"
        [disableRipple]="true"
        (click)="changeMapping.emit(view)"
      >
        <span class="choose-items-item__dropdown-icon icon-plug"></span>
        Change data binding
      </button>

      <button
        *ngIf="(currentUserStore.instance$ | async)?.isStaff"
        mat-menu-item
        class="mat-menu-item-odd staff-block staff-block_background"
        [disabled]="!control.value"
        [disableRipple]="true"
        (click)="updateCustomViewTemplate.emit(view)"
      >
        <span class="choose-items-item__dropdown-icon icon-redo"></span>
        Save to marketplace
      </button>

      <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" (click)="startEditing()">
        <span class="choose-items-item__dropdown-icon icon-edit"></span>
        Rename field
      </button>
    </ng-template>
  </mat-menu>

  <div
    class="sidebar-list-item sidebar-list-item_clickable"
    [class.sidebar-list-item_draggable]="true"
    [class.sidebar-list-item_disabled]="dropdown_trigger.menuOpen"
    [matMenuTriggerFor]="dropdown"
    #dropdown_trigger="matMenuTrigger"
  >
    <div class="sidebar-list-item__drag"></div>
    <div class="sidebar-list-item__left">
      <span
        class="button button_icon button_purple-gradient button_extra-small"
        [class.button_disabled]="!view"
        style="margin: 0;"
      >
        <span class="button__icon icon-canvas" [style.font-size.px]="16"></span>
      </span>
    </div>

    <div class="sidebar-list-item__main">
      <div class="sidebar-list-item__title">
        <input
          *ngIf="nameEditing"
          class="sidebar-list-item__input"
          [(ngModel)]="nameValue"
          (blur)="finishEditing()"
          (keydown)="onKeyDown($event)"
          (keyup)="onKeyUp($event)"
          (click)="$event.stopPropagation()"
          #input
        />

        <ng-container *ngIf="!nameEditing">
          {{ (control.controls.verboseName.value | appIsSet) ? control.controls.verboseName.value : 'No name' }}
        </ng-container>
      </div>

      <div *ngIf="!view" class="sidebar-list-item__description">Not specified</div>
    </div>

    <div class="sidebar-list-item__right">
      <a
        *ngIf="!(search | appIsSet) && form.getMoveToTopPosition(control) !== index"
        href="javascript:void(0)"
        class="sidebar-list-item__action sidebar-list-item__action_hover-only icon-align_vertical_top"
        [appTip]="'Move to top'"
        [appTipOptions]="{ side: 'top' }"
        (click)="$event.stopPropagation(); moveToTop.emit()"
      >
      </a>
      <a
        *ngIf="!queryField"
        href="javascript:void(0)"
        class="sidebar-list-item__action icon-bin"
        (click)="$event.stopPropagation(); removeControl.emit()"
      >
      </a>
    </div>
  </div>
</ng-container>

<div
  *ngIf="!loading && control.type != displayFieldTypes.CustomView"
  class="sidebar-list-item sidebar-list-item_clickable"
  [class.sidebar-list-item_draggable]="true"
  [class.sidebar-list-item_disabled]="visibleEditable && !control.controls.visible.value"
  (click)="customize.emit()"
>
  <div class="sidebar-list-item__drag"></div>
  <div class="sidebar-list-item__left">
    <span class="button button_icon button_orange button_extra-small" style="margin: 0;">
      <span class="icon button__icon" [ngClass]="['icon-' + (control.getIcon$() | async)]"></span>
    </span>
  </div>

  <div class="sidebar-list-item__main">
    <div class="sidebar-list-item__title">
      {{
        (control.controls.verboseName.value | appIsSet)
          ? control.controls.verboseName.value
          : control.controls.name.value
      }}
    </div>
  </div>

  <div class="sidebar-list-item__right">
    <a
      *ngIf="!(search | appIsSet) && form.getMoveToTopPosition(control) !== index"
      href="javascript:void(0)"
      class="sidebar-list-item__action sidebar-list-item__action_hover-only icon-align_vertical_top"
      [appTip]="'Move to top'"
      [appTipOptions]="{ side: 'top' }"
      (click)="$event.stopPropagation(); moveToTop.emit()"
    >
    </a>
    <app-toggle
      *ngIf="visibleEditable && queryField"
      [orange]="true"
      [selected]="control.controls.visible.value"
      (click)="$event.stopPropagation(); control.toggleVisible()"
    >
    </app-toggle>
    <a
      *ngIf="!queryField"
      href="javascript:void(0)"
      class="sidebar-list-item__action icon-bin"
      (click)="$event.stopPropagation(); removeControl.emit()"
    >
    </a>
  </div>
</div>

<div *ngIf="loading" class="sidebar-list-item" [class.sidebar-list-item_disabled]="true">
  <div class="sidebar-list-item__left">
    <span
      *ngIf="control.type == displayFieldTypes.CustomView"
      class="button button_icon button_purple-gradient button_extra-small"
      [class.button_disabled]="true"
      style="margin: 0;"
    >
      <span class="button__icon icon-canvas" [style.font-size.px]="16"></span>
    </span>

    <div class="sidebar-list-item__left">
      <span
        *ngIf="control.type != displayFieldTypes.CustomView"
        class="button button_icon button_orange button_extra-small"
        style="margin: 0;"
        [class.button_disabled]="true"
      >
        <span class="icon button__icon" [ngClass]="['icon-' + (control.getIcon$() | async)]"></span>
      </span>
    </div>
  </div>

  <div class="sidebar-list-item__main">
    <div class="sidebar-list-item__title">
      <span [class.loading-animation]="loading"><span class="stub-text">name</span></span>
    </div>
  </div>
</div>
