<app-field-wrapper
  [form]="form"
  [field]="createField({ name: 'range', label: element.verboseName, required: element.required })"
  [label]="element.verboseName | appIsSet"
  [manualMargin]="true"
  [tooltip]="element.tooltip"
  [idToken]="idToken"
>
  <app-slider
    [range]="true"
    [baseControl]="fromControl"
    [extentControl]="toControl"
    [min]="minValue"
    [minLabel]="(minValueLabel | appIsSet) ? minValueLabel : (minValue | appFormatNumber: '0')"
    [max]="maxValue"
    [maxLabel]="(maxValueLabel | appIsSet) ? maxValueLabel : (maxValue | appFormatNumber: '0')"
    [normal]="normalValue"
    [normalLabel]="normalValueLabel"
    [step]="stepSize"
    [stickToSteps]="false"
    [debounce]="0"
    [fillBackground]="element.tint || accentColor"
    [thumbBorderColor]="element.tint || accentColor"
    [orange]="false"
    [thumbInside]="false"
  ></app-slider>
</app-field-wrapper>
