import { AbstractControlOptions, AsyncValidatorFn, FormControl, FormGroup, ValidatorFn } from '@angular/forms';

import { Input, isInputSet } from '@modules/fields';

import { singleTokenFormulaToContextValue } from './formula';

export function FieldInputRequiredValidator(control: FieldInputControl) {
  if (!isInputSet(control.value)) {
    return { required: true };
  }
}

export class FieldInputControl extends FormGroup {
  controls: {
    name: FormControl;
    field: FormControl;
    value_type: FormControl;
    static_value: FormControl;
    context_value: FormControl;
    filter_field: FormControl;
    filter_lookup: FormControl;
    formula_value: FormControl;
    js_value: FormControl;
    required: FormControl;
  };

  constructor(
    formState: Object = {},
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    const controls = {
      name: new FormControl(''),
      field: new FormControl(null),
      value_type: new FormControl(null),
      static_value: new FormControl(''),
      context_value: new FormControl(undefined),
      filter_field: new FormControl(''),
      filter_lookup: new FormControl(''),
      formula_value: new FormControl(''),
      js_value: new FormControl(''),
      required: new FormControl(false)
    };
    super(controls, validatorOrOpts, asyncValidator);
    formState = this.cleanValue(formState);
    this.patchValue(formState, { emitEvent: false });
  }

  serialize(): Input {
    const result = new Input();

    result.name = this.controls.name.value;
    result.valueType = this.controls.value_type.value;
    result.staticValue = this.controls.static_value.value;
    result.contextValue = this.controls.context_value.value;
    result.filterField = this.controls.filter_field.value;
    result.filterLookup = this.controls.filter_lookup.value;
    result.formulaValue = this.controls.formula_value.value;
    result.jsValue = this.controls.js_value.value;
    result.required = this.controls.required.value;

    return result;
  }

  patchValue(value: { [p: string]: any }, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    value = this.cleanValue(value);
    super.patchValue(value, options);
  }

  setValue(value: { [p: string]: any }, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    value = this.cleanValue(value);
    super.setValue(value, options);
  }

  cleanValue(value: { [p: string]: any }) {
    if (!value) {
      return value;
    }

    // Backward compatibility
    if (typeof value['context_value'] === 'string') {
      value = {
        ...value,
        context_value: singleTokenFormulaToContextValue(value['context_value'])
      };
    }

    return value;
  }

  clearValue(value: Object = {}) {
    this.patchValue({
      name: '',
      field: null,
      value_type: null,
      static_value: '',
      context_value: [],
      filter_field: '',
      filter_lookup: '',
      formula_value: '',
      js_value: '',
      required: false,
      ...value
    });
  }
}
