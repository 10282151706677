<form [formGroup]="form" (ngSubmit)="submit()">
  <app-customize-bar-header
    [title]="form.controls.name.value || (titleEditable ? 'detail' : 'Detail')"
    [titleEnabled]="titleEnabled"
    [titleEditable]="titleEditable"
    [titleCleanValue]="titleCleanValue"
    [subtitle]="'Customization'"
    [backLabel]="'All components'"
    [deleteEnabled]="deleteEnabled"
    [parentLabel]="parentElement?.displayName || parentPopup?.displayName"
    (titleChanged)="onTitleChanged($event)"
    (parentClick)="parentElement?.customize({ event: $event }) || parentPopup?.customize({ event: $event })"
    (deleteClick)="delete()"
  >
  </app-customize-bar-header>

  <div class="sidebar__content">
    <app-sidebar-tabs>
      <app-sidebar-tab [label]="'General'">
        <app-sidebar-section>
          <div class="sidebar__element">
            <app-model-description-data-source-button
              [control]="form.controls.data_source"
              [context]="context"
              [contextElement]="contextElement"
              [error]="(dataConfigured$ | async) !== false ? undefined : 'Not configured'"
              [classes]="'component-data-button'"
              (editClick)="openGetQuery()"
              (addInputClick)="openGetQuery({ addInput: true })"
            >
            </app-model-description-data-source-button>
          </div>
        </app-sidebar-section>

        <app-sidebar-section *ngIf="queryConfigured$ | async" [title]="'Fields'">
          <ng-container header-left>
            <div
              class="sidebar-section__title-icon sidebar-section__title-icon_clickable sidebar-section__title-action icon-search"
              [class.sidebar-section__title-icon_active]="columnsSearch"
              (mousedown)="toggleSearch()"
              [appTip]="'Search'"
              [appTipOptions]="{ side: 'top' }"
            ></div>
          </ng-container>

          <ng-container header-right>
            <div class="sidebar-section__header-links">
              <a class="sidebar__link" href="javascript:void(0)" (click)="resetColumns()">
                Reset
              </a>

              <ng-container *ngIf="fieldsComponent.hasMultipleItems()">
                <span class="sidebar-section__header-separator"></span>
                <a class="sidebar__link" href="javascript:void(0)" (click)="fieldsComponent.toggleAll()">
                  <span *ngIf="fieldsComponent.isToggledAll()" class="button__label">Deselect All</span>
                  <span *ngIf="!fieldsComponent.isToggledAll()" class="button__label">Select All</span>
                </a>
              </ng-container>
            </div>
          </ng-container>

          <div class="sidebar__list">
            <app-display-fields-edit
              [form]="form.controls.data_source.controls.columns"
              [fieldActionsControl]="form.controls.columns_actions"
              [resource]="resource"
              [modelDescription]="modelDescription"
              [context]="context"
              [contextElement]="contextElement"
              [contextElementPaths]="columnsContextElementPaths"
              [searchEnabled]="columnsSearch"
              [searchFocus]="columnsSearch"
              [firstInit]="firstInit"
              [analyticsSource]="element.analyticsGenericName"
              (searchCleared)="setColumnsSearch(false)"
              #fieldsComponent
            >
            </app-display-fields-edit>
          </div>
        </app-sidebar-section>

        <app-related-model-components
          *ngIf="modelDescription"
          [modelDescription]="modelDescription"
          [context]="context"
          [contextElement]="contextElement"
          [contextElementModelOutput]="itemContextElementPath"
          [collapseContext]="collapseContext"
        ></app-related-model-components>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Display'">
        <div class="sidebar__element">
          <app-customize-bar-input-edit
            [label]="'detail title'"
            [required]="true"
            [itemForm]="form.controls.title"
            [context]="context"
            [contextElement]="contextElement"
            [contextElementPath]="contextElementPath"
            [contextElementPaths]="contextElementPaths"
            [fill]="true"
            [analyticsSource]="element.analyticsGenericName + '_title'"
          ></app-customize-bar-input-edit>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field
            [label]="'Conditional Visibility'"
            [documentation]="'conditional-visibility'"
            [documentationLabel]="'See how it works'"
            [documentationSource]="element.analyticsGenericName + '_visible'"
          >
            <app-input-edit
              [itemForm]="form.controls.visible_input"
              [context]="context"
              [contextElement]="contextElement"
              [contextElementPaths]="visibleContextElementPaths"
              [staticValueDisabled]="true"
              [placeholder]="'Value'"
              [fill]="true"
              [analyticsSource]="element.analyticsGenericName + '_visible'"
            ></app-input-edit>

            <ng-container description>
              Example: elements.field.value >= 10
            </ng-container>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'tooltip'">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'tooltip',
                  field: 'CharField',
                  placeholder: 'Show information for users',
                  reset_enabled: true,
                  params: { classes: ['input_fill'] }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'Spacing'">
            <app-margin-control [control]="form.controls.margin"></app-margin-control>
          </app-sidebar-field>
        </div>
      </app-sidebar-tab>
    </app-sidebar-tabs>
  </div>
</form>
