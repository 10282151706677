var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { throwError } from 'rxjs';
import { catchError, delayWhen } from 'rxjs/operators';
import { FormUtils } from '@common/form-utils';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectService } from '@modules/projects';
import { AppError, isSet } from '@shared';
var ProjectUniqueNameForm = /** @class */ (function (_super) {
    __extends(ProjectUniqueNameForm, _super);
    function ProjectUniqueNameForm(currentProjectStore, currentEnvironmentStore, formUtils, projectService) {
        var _this = _super.call(this, {
            unique_name: new FormControl('', [Validators.required, Validators.pattern(/^[a-z0-9_]{2,}$/)]),
            subdomain: new FormControl('', [Validators.pattern(/^[a-z0-9_]{2,}$/)])
        }) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.formUtils = formUtils;
        _this.projectService = projectService;
        _this.controls.unique_name.valueChanges.subscribe(function (rawValue) {
            var cleanValue = _this.cleanValue(rawValue);
            if (cleanValue !== rawValue) {
                _this.controls.unique_name.setValue(cleanValue);
            }
        });
        _this.controls.subdomain.valueChanges.subscribe(function (rawValue) {
            var cleanValue = _this.cleanValue(rawValue);
            if (cleanValue !== rawValue) {
                _this.controls.subdomain.setValue(cleanValue);
            }
        });
        return _this;
    }
    ProjectUniqueNameForm.prototype.cleanValue = function (value) {
        if (!isSet(value)) {
            value = '';
        }
        return value
            .toLowerCase()
            .replace(/[-.\s]/g, '_')
            .replace(/[^a-z0-9_]/g, '');
    };
    ProjectUniqueNameForm.prototype.init = function (project) {
        this.instance = project;
        this.controls.unique_name.patchValue(project.uniqueName);
        if (project.domain && !project.domain.custom) {
            this.controls.subdomain.patchValue(project.domain.domain);
        }
        this.markAsPristine();
    };
    ProjectUniqueNameForm.prototype.hasChanges = function () {
        if (!this.instance) {
            return false;
        }
        var uniqueNameChanged = this.instance.uniqueName != this.controls.unique_name.value;
        var subdomainChanged = this.instance.domain && !this.instance.domain.custom
            ? this.instance.domain.domain != this.controls.subdomain.value
            : false;
        return uniqueNameChanged || subdomainChanged;
    };
    ProjectUniqueNameForm.prototype.submit = function () {
        var _this = this;
        for (var _i = 0, _a = this.instance.environments; _i < _a.length; _i++) {
            var environment = _a[_i];
            if (!this.instance.hasEnvironmentCustomizationPermission(environment)) {
                var error = new AppError("You need to have customization permissions to all environments (missing " + environment.name + ")");
                return throwError(error);
            }
        }
        var instance = cloneDeep(this.instance);
        var fields = [];
        if (this.controls.unique_name.value != instance.uniqueName) {
            instance.uniqueName = this.controls.unique_name.value;
            fields.push('unique_name');
        }
        if (instance.domain && !instance.domain.custom) {
            instance.subdomain = this.controls.subdomain.value;
            fields.push('subdomain');
        }
        return this.projectService.update(this.instance.uniqueName, instance, fields).pipe(delayWhen(function (project) {
            _this.currentProjectStore.uniqueName = project.uniqueName;
            return _this.currentProjectStore.getFirst(true);
        }), catchError(function (error) {
            _this.formUtils.showFormErrors(_this, error);
            return throwError(error);
        }));
    };
    return ProjectUniqueNameForm;
}(FormGroup));
export { ProjectUniqueNameForm };
