import uniq from 'lodash/uniq';
import * as moment from 'moment';

import { localize } from '@common/localize';
import { User } from '@modules/users';

import { ProjectProperty } from './project-property';

import { ProjectPermission, ProjectPermissionType } from './project-permission';

export class ProjectUser {
  public uid: string;
  public user: User;
  public userEmail: string;
  public group: ProjectGroup;
  public properties = {};
  public dateAdd: moment.Moment;

  deserialize(data: Object): ProjectUser {
    this.uid = data['uid'];
    this.userEmail = data['user_email'];
    this.properties = data['properties'];
    this.dateAdd = moment(data['date_add']);

    if (data['user']) {
      this.user = new User().deserialize(data['user']);
    }

    if (data['group']) {
      this.group = new ProjectGroup().deserialize(data['group']);
    } else {
      this.group = new ProjectGroup();
    }

    return this;
  }

  serialize() {
    return {
      user: this.user ? this.user.id : undefined,
      user_email: this.userEmail,
      group: this.group ? this.group.uid : undefined,
      properties: this.properties
    };
  }

  get link() {
    return ['project', 'users', this.uid];
  }

  getEmail() {
    return this.user ? this.user.email : this.userEmail;
  }

  getFirstName() {
    return this.user ? this.user.firstName : undefined;
  }

  getLastName() {
    return this.user ? this.user.lastName : undefined;
  }

  getFullName() {
    return this.user ? this.user.strFull : this.userEmail;
  }

  getPropertyValue(property: ProjectProperty): any {
    let value = this.properties[property.uid];

    if (property.fieldDescription && property.fieldDescription.deserializeValue) {
      value = property.fieldDescription.deserializeValue(value, property.field);
    }

    return value;
  }

  setPropertyValue(property: ProjectProperty, value: any) {
    if (property.fieldDescription && property.fieldDescription.serializeValue) {
      value = property.fieldDescription.serializeValue(value, property.field);
    }

    this.properties[property.uid] = value;
  }
}

export enum PermissionsGroup {
  FullAccess = 'full_access',
  Editor = 'editor',
  ReadOnly = 'read_only'
}

export class ProjectGroup {
  public uid: string;
  public name: string;
  public description: string;
  // public superGroup: boolean;
  public permissionsGroup: PermissionsGroup;
  public permissions: ProjectPermission[] = [];
  public permissionsVersion = 1;
  public properties = {};
  public protected = false;
  public projectUsers: ProjectUser[] = [];
  public params = {};

  deserialize(data: Object): ProjectGroup {
    this.uid = data['uid'];
    this.name = data['name'];
    this.description = data['description'];
    // this.superGroup = data['super_group'];
    this.properties = data['properties'];
    this.protected = data['protected'];

    if (data['permissions_group']) {
      this.permissionsGroup = data['permissions_group'];
    }

    if (data['project_permissions']) {
      this.permissions = data['project_permissions'].map(item => new ProjectPermission().deserialize(item));
    }

    if (data['project_users']) {
      this.projectUsers = data['project_users'].map(item => new ProjectUser().deserialize(item));
    }

    if (data['params']) {
      this.params = data['params'];
    }

    if (this.params['permissions_version']) {
      this.permissionsVersion = this.params['permissions_version'];
    }

    return this;
  }

  serialize(): Object {
    this.params['permissions_version'] = this.permissionsVersion;

    return {
      uid: this.uid,
      name: this.name,
      // super_group: this.superGroup,
      permissions_group: this.permissionsGroup,
      project_permissions: this.permissions.map(item => item.serialize()),
      properties: this.properties,
      params: this.params
    };
  }

  getPermissions(type: ProjectPermissionType) {
    return this.permissions.filter(item => item.permissionType == type);
  }

  getPagesCount() {
    return uniq(this.getPermissions(ProjectPermissionType.Page).map(item => item.permissionObject)).length;
  }

  getIcon(): string {
    if (this.permissionsGroup == PermissionsGroup.FullAccess) {
      return 'configure';
    } else if (this.permissionsGroup == PermissionsGroup.Editor) {
      return 'pen';
    } else if (this.permissionsGroup == PermissionsGroup.ReadOnly) {
      return 'eye';
    }
  }

  getDescription(): string {
    if (this.permissionsGroup == PermissionsGroup.FullAccess) {
      return localize('Can build application and modify data');
    } else if (this.permissionsGroup == PermissionsGroup.Editor) {
      return localize('Can modify data in application');
    } else if (this.permissionsGroup == PermissionsGroup.ReadOnly) {
      return localize('Can only view data without modification');
    }
  }

  getPropertyValue(property: ProjectProperty): any {
    let value = this.properties[property.uid];

    if (property.fieldDescription && property.fieldDescription.deserializeValue) {
      value = property.fieldDescription.deserializeValue(value, property.field);
    }

    return value;
  }

  setPropertyValue(property: ProjectProperty, value: any) {
    if (property.fieldDescription && property.fieldDescription.serializeValue) {
      value = property.fieldDescription.serializeValue(value, property.field);
    }

    this.properties[property.uid] = value;
  }
}
