import pickBy from 'lodash/pickBy';

import { ActionItem } from '@modules/actions';
import { Input } from '@modules/fields';
import { isSet } from '@shared';

import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
import { ResizableElementItem } from './widget';

export class RangeSliderElementItem extends ElementItem implements ResizableElementItem {
  public type = ElementType.RangeSlider;
  public width: number;
  public verboseName: string;
  public from: Input;
  public to: Input;
  public minValueInput: Input;
  public maxValueInput: Input;
  public stepSizeInput: Input;
  public required = false;
  public tint: string;
  public disableInput: Input;
  public tooltip: string;
  public onChangeActions: ActionItem[] = [];

  deserialize(data: Object): RangeSliderElementItem {
    super.deserialize(data);
    this.width = this.params['width'];
    this.verboseName = this.params['verbose_name'];
    this.required = this.params['required'];
    this.tint = this.params['tint'];
    this.tooltip = this.params['tooltip'];

    if (this.params['from']) {
      this.from = new Input().deserialize(this.params['from']);
    }

    if (this.params['to']) {
      this.to = new Input().deserialize(this.params['to']);
    }

    if (this.params['min_value_input']) {
      this.minValueInput = new Input().deserialize(this.params['min_value_input']);
    } else if (isSet(this.params['min_value'])) {
      // Backward compatibility
      this.minValueInput = new Input().deserializeFromStatic('value', this.params['min_value']);
    }

    if (this.params['max_value_input']) {
      this.maxValueInput = new Input().deserialize(this.params['max_value_input']);
    } else if (isSet(this.params['max_value'])) {
      // Backward compatibility
      this.maxValueInput = new Input().deserializeFromStatic('value', this.params['max_value']);
    }

    if (this.params['step_size_input']) {
      this.stepSizeInput = new Input().deserialize(this.params['step_size_input']);
    } else if (isSet(this.params['step_size'])) {
      // Backward compatibility
      this.stepSizeInput = new Input().deserializeFromStatic('value', this.params['step_size']);
    }

    if (this.params['disable_input']) {
      this.disableInput = new Input().deserialize(this.params['disable_input']);
    }

    if (this.params['on_change_actions']) {
      this.onChangeActions = this.params['on_change_actions'].map(item => new ActionItem().deserialize(item));
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = {};
    this.params['width'] = this.width;
    this.params['verbose_name'] = this.verboseName;
    this.params['from'] = this.from ? this.from.serialize() : null;
    this.params['to'] = this.to ? this.to.serialize() : null;
    this.params['min_value_input'] = this.minValueInput ? this.minValueInput.serialize() : null;
    this.params['max_value_input'] = this.maxValueInput ? this.maxValueInput.serialize() : null;
    this.params['step_size_input'] = this.stepSizeInput ? this.stepSizeInput.serialize() : null;
    this.params['required'] = this.required;
    this.params['disable_input'] = this.disableInput ? this.disableInput.serialize() : null;
    this.params['tint'] = this.tint;
    this.params['tooltip'] = this.tooltip;
    this.params['on_change_actions'] = this.onChangeActions.map(item => item.serialize());

    let data = super.serialize();
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  get typeStr(): string {
    return 'range';
  }

  get analyticsName(): string {
    return 'range';
  }

  defaultName() {
    return 'Range';
  }
}

registerElementForType(ElementType.RangeSlider, RangeSliderElementItem);
