var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { FieldType, registerFieldComponent } from '@modules/fields';
import { FieldComponent } from '../field/field.component';
var IconFieldComponent = /** @class */ (function (_super) {
    __extends(IconFieldComponent, _super);
    function IconFieldComponent(cd) {
        var _this = _super.call(this) || this;
        _this.cd = cd;
        _this.editPopoverOpened = false;
        return _this;
    }
    IconFieldComponent.prototype.openEditPopover = function (value) {
        this.editPopoverOpened = value;
        this.cd.markForCheck();
    };
    IconFieldComponent.prototype.getLabel = function (value) {
        if (typeof value == 'string') {
            value = value.replace(/_/g, ' ');
        }
        return value;
    };
    IconFieldComponent.prototype.setIcon = function (icon) {
        var patch = {};
        patch[this.field.name] = icon;
        this.form.patchValue(patch);
    };
    return IconFieldComponent;
}(FieldComponent));
export { IconFieldComponent };
registerFieldComponent(FieldType.Icon, IconFieldComponent);
