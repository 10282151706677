import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { WorkflowExecuteEventType } from '@modules/action-queries';
import { ActionItem } from '@modules/actions';
import { SidebarTabsComponent } from '@modules/sidebar';
import { ActionWorkflowStep, WorkflowStepRun, WorkflowStepType } from '@modules/workflow';
import { isSet } from '@shared';

import { registerCustomizeWorkflowStepComponent } from '../../../../data/customize-workflow-step-components';
import { WorkflowEditContext } from '../../../../services/workflow-edit-context/workflow-edit.context';
import { CustomizeBarActionEditTypeComponent } from '../../../customize-bar-action-edit/customize-bar-action-edit-type/customize-bar-action-edit-type.component';
import { CustomizeBarActionEditFormGetQueries } from '../../../customize-bar-action-edit/customize-bar-action-edit.form';
import { CUSTOMIZE_BAR_ACTION_EDIT_FORM_PROVIDER } from '../../../customize-bar-action-edit/customize-bar-action-edit.provider';
import { WorkflowStepStatus } from '../../steps/base-workflow-step/base-workflow-step.component';
import { CustomizeWorkflowStepComponent } from '../base-customize-workflow-step/base-customize-workflow-step.component';
import { ActionCustomizeWorkflowStepForm } from './action-customize-workflow-step.form';

@Component({
  selector: 'app-action-customize-workflow-step',
  templateUrl: './action-customize-workflow-step.component.html',
  providers: [
    ...CUSTOMIZE_BAR_ACTION_EDIT_FORM_PROVIDER,
    { provide: CustomizeBarActionEditFormGetQueries, useValue: true },
    ActionCustomizeWorkflowStepForm
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionCustomizeWorkflowStepComponent extends CustomizeWorkflowStepComponent<ActionWorkflowStep>
  implements OnInit, OnDestroy {
  @ViewChild(CustomizeBarActionEditTypeComponent) editTypeComponent: CustomizeBarActionEditTypeComponent;
  @ViewChild(SidebarTabsComponent) tabsComponent: SidebarTabsComponent;

  submitActionClass = ActionItem;
  stepRun: WorkflowStepRun;
  status = WorkflowStepStatus.NotExecuted;
  statuses = WorkflowStepStatus;
  operationTypeValid$: Observable<boolean>;
  getOperation$: Observable<boolean>;

  constructor(
    public form: ActionCustomizeWorkflowStepForm,
    private workflowEditContext: WorkflowEditContext,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.form.init(this.step, { backgroundWorkflow: this.backgroundWorkflow, context: this.context, firstInit: false });

    this.operationTypeValid$ = this.form.controls.action.operationTypeValid$();
    this.getOperation$ = this.form.controls.action.getActionDescription$().pipe(
      map(actionDescription => {
        return actionDescription && actionDescription.modelAction == 'get';
      })
    );

    this.trackRun();
    this.trackExecuteStatus();

    if (this.workflowEditable) {
      this.form.valueChanges
        .pipe(
          debounceTime(200),
          map(() => this.form.submit()),
          untilDestroyed(this)
        )
        .subscribe(result => {
          this.submit(result);
        });
    }
  }

  ngOnDestroy(): void {}

  trackRun() {
    this.workflowEditContext.run$
      .pipe(
        map(run => {
          if (!run) {
            return;
          }

          return run.stepRuns.find(item => item.uid == this.step.uid);
        }),
        untilDestroyed(this)
      )
      .subscribe(value => {
        this.stepRun = value;

        if (this.stepRun) {
          if (isSet(value.error)) {
            this.status = WorkflowStepStatus.Failed;
          } else {
            this.status = WorkflowStepStatus.Success;
          }
        }

        this.cd.markForCheck();
      });
  }

  trackExecuteStatus() {
    this.workflowEditContext.testExecuteEvents$.pipe(untilDestroyed(this)).subscribe(event => {
      if (event.type == WorkflowExecuteEventType.WorkflowStarted) {
        this.status = WorkflowStepStatus.NotExecuted;
      } else if (event.type == WorkflowExecuteEventType.StepStarted && event.step.uid == this.step.uid) {
        this.status = WorkflowStepStatus.Executing;
      } else if (event.type == WorkflowExecuteEventType.StepFinished && event.step.uid == this.step.uid) {
        if (event.success) {
          this.status = WorkflowStepStatus.Success;
        } else {
          this.status = WorkflowStepStatus.Failed;
        }
      }

      this.cd.markForCheck();
    });
  }

  addQueryInput() {
    if (this.editTypeComponent) {
      this.editTypeComponent.addQueryInput();
    }
  }

  submit(value?: ActionWorkflowStep) {
    if (!value) {
      value = this.form.submit();
    }

    this.stepChange.emit(value);
  }

  execute() {
    this.stepExecute.emit();
    this.tabsComponent.setVisibleTab(this.tabsComponent.tabs[1]);
  }
}

registerCustomizeWorkflowStepComponent(WorkflowStepType.Action, ActionCustomizeWorkflowStepComponent);
