import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of, Subscription } from 'rxjs';

import { ActionService } from '@modules/action-queries';
import { ActionDescription, ActionItem } from '@modules/actions';
import { ViewContext, ViewContextElement, ViewSettingsAction } from '@modules/customize';
import { applyParamInput$ } from '@modules/fields';
import { CurrentEnvironmentStore, Resource } from '@modules/projects';
import { isSet } from '@shared';

@Component({
  selector: 'app-actions-edit-item',
  templateUrl: './actions-edit-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionsEditItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() actionItemClass = ActionItem;
  @Input() title: string;
  @Input() action: ActionItem;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() defaultTitle: string;
  @Input() defaultSubtitle = 'Not specified';
  @Input() disabled = false;
  @Input() draggable = false;
  @Input() remove = false;
  @Input() pin = false;
  @Input() pinned = false;
  @Input() execute = false;
  @Input() icon: string;
  @Input() error: string;
  @Input() classes: string | string[];
  @Output() open = new EventEmitter<ActionItem>();
  @Output() removeClick = new EventEmitter<void>();
  @Output() pinClick = new EventEmitter<void>();
  @Output() executeClick = new EventEmitter<void>();

  loading = false;
  hasIcon = false;
  displayIcon: string;
  titles: string[] = [];
  titlesSubscription: Subscription;
  actionDescription: ActionDescription;
  actionDescriptionResource: Resource;

  constructor(
    private actionService: ActionService,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updateAction();
  }

  updateAction() {
    this.loading = true;
    this.cd.markForCheck();

    this.actionService
      .getActionDescription(this.action)
      .pipe(untilDestroyed(this))
      .subscribe(
        actionDescription => {
          const resource = actionDescription
            ? this.currentEnvironmentStore.resources.find(i => i.uniqueName == actionDescription.resource)
            : undefined;

          this.actionDescription = actionDescription;
          this.actionDescriptionResource = resource;
          this.hasIcon = this.actionItemClass == ViewSettingsAction || this.icon != undefined;
          this.updateDisplayIcon(actionDescription);
          this.updateTitles(actionDescription);
          this.cd.markForCheck();
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  updateDisplayIcon(actionDescription: ActionDescription) {
    if (this.icon) {
      this.displayIcon = this.icon;
    } else if (this.action instanceof ViewSettingsAction && this.action.icon) {
      this.displayIcon = this.action.icon;
    } else if (actionDescription && actionDescription.icon) {
      this.displayIcon = actionDescription.icon;
    } else {
      this.displayIcon = 'power';
    }

    this.cd.markForCheck();
  }

  updateTitles(actionDescription: ActionDescription) {
    if (this.titlesSubscription) {
      this.titlesSubscription.unsubscribe();
      this.titlesSubscription = undefined;
    }

    this.titlesSubscription = combineLatest(
      this.getDisplayName$(actionDescription),
      this.actionService.getActionDescriptionLabel(
        actionDescription,
        this.action ? this.action.inputs : [],
        this.context,
        this.contextElement
      )
    )
      .pipe(untilDestroyed(this))
      .subscribe(
        ([displayName, actionDescriptionLabel]) => {
          this.titles = [
            ...(isSet(displayName) ? [displayName] : []),
            ...(isSet(actionDescriptionLabel) ? actionDescriptionLabel : []),
            this.defaultSubtitle
          ];
          this.loading = false;
          this.cd.markForCheck();
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  getDisplayName$(actionDescription: ActionDescription): Observable<string> {
    if (this.title) {
      return of(this.title);
    } else if (this.action && this.action.verboseNameInput) {
      return applyParamInput$(this.action.verboseNameInput, {
        context: this.context,
        contextElement: this.contextElement,
        defaultValue: ''
      });
    } else if (actionDescription && actionDescription.verboseName) {
      return of(actionDescription.verboseName);
    } else {
      return of(this.defaultTitle);
    }
  }
}
