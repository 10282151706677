import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { ActionItem } from '@modules/actions';
import { ViewContext } from '@modules/customize';
import { CustomizeBarContext, CustomizeBarEditEventType, CustomizeBarService } from '@modules/customize-bar';

import { LayerInteractionArray } from '../../controls/layer-interaction.array';
import { LayerInteractionControl } from '../../controls/layer-interaction.control';
import { ViewEditorSidebarComponent } from '../../view-editor-sidebar/view-editor-sidebar.component';

@Component({
  selector: 'app-interactions-section-click',
  templateUrl: './interactions-section-click.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InteractionsSectionClickComponent implements OnInit, OnDestroy {
  @Input() control: LayerInteractionControl;
  @Input() arrayControl: LayerInteractionArray;
  @Input() viewContext: ViewContext;

  constructor(
    @Optional() private sidebarComponent: ViewEditorSidebarComponent,
    private customizeBarService: CustomizeBarService,
    private customizeBarContext: CustomizeBarContext
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  customizeAction() {
    this.customizeBarService
      .customizeActionItem({
        options: {
          actionItem: this.control.controls.action.value
        },
        context: this.customizeBarContext,
        viewContext: this.viewContext,
        append: true
      })
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
          const instance = e.args['result'] as ActionItem;
          this.control.controls.action.patchValue(instance);
          this.control.controls.action.markAsDirty();
        } else if (e.type == CustomizeBarEditEventType.Deleted) {
          this.control.controls.action.patchValue(undefined);
          this.control.controls.action.markAsDirty();
        }
      });

    if (this.sidebarComponent) {
      this.sidebarComponent.scrollToTop();
    }
  }
}
