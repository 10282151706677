import pickBy from 'lodash/pickBy';
import { DataSourceType } from '@modules/data-sources';
import { Input, InputValueType } from '@modules/fields';
import { PER_PAGE_PARAM } from '@modules/models';
import { generateAlphanumeric } from '@shared';
import { ActionDescription } from './action-description';
import { ActionType } from './action-type';
import { Confirmation } from './confirmation';
var ActionItem = /** @class */ (function () {
    function ActionItem() {
        this.inputs = [];
        this.protected = false;
        this.onSuccessActions = [];
        this.onErrorActions = [];
    }
    ActionItem.prototype.deserialize = function (data) {
        this.uid = data['uid'];
        this.name = data['name'];
        this.sharedActionDescription = data['shared_action_description'];
        if (data['verbose_name_input']) {
            this.verboseNameInput = new Input().deserialize(data['verbose_name_input']);
        }
        else if (data['verbose_name']) {
            // Backward compatibility
            this.verboseNameInput = new Input().deserializeFromStatic('value', data['verbose_name']);
        }
        if (data['action_description']) {
            this.actionDescription = new ActionDescription().deserialize(data['action_description']);
        }
        if (data['inputs']) {
            this.inputs = data['inputs'].map(function (item) { return new Input().deserialize(item); });
        }
        if (this.actionDescription &&
            this.actionDescription.type === ActionType.ExternalLink &&
            !this.inputs.find(function (item) { return item.name === 'new_tab'; })) {
            var input = new Input();
            input.name = 'new_tab';
            input.valueType = InputValueType.StaticValue;
            input.staticValue = '1';
            this.inputs.push(input);
        }
        // Backward compatibility
        if (data['action_description'] &&
            data['action_description']['params'] &&
            data['action_description']['params']['export_action'] &&
            data['action_description']['params']['export_action']['model'] &&
            this.actionDescription &&
            this.actionDescription.type === ActionType.Export &&
            this.actionDescription.exportAction &&
            this.actionDescription.exportAction.dataSource &&
            this.actionDescription.exportAction.dataSource.type == DataSourceType.Query) {
            this.actionDescription.exportAction.dataSource.queryInputs = this.inputs.filter(function (item) { return ![PER_PAGE_PARAM].includes(item.name); });
            this.inputs = this.inputs.filter(function (item) { return [PER_PAGE_PARAM].includes(item.name); });
        }
        if (data['protected'] != undefined) {
            this.protected = data['protected'];
        }
        if (data['approve'] != undefined) {
            this.approve = new Approve().deserialize(data['approve']);
        }
        if (data['confirmation'] != undefined) {
            this.confirmation = new Confirmation().deserialize(data['confirmation']);
        }
        if (data['on_success_actions']) {
            this.onSuccessActions = data['on_success_actions'].map(function (item) { return new ActionItem().deserialize(item); });
        }
        if (data['on_error_actions']) {
            this.onErrorActions = data['on_error_actions'].map(function (item) { return new ActionItem().deserialize(item); });
        }
        return this;
    };
    ActionItem.prototype.serialize = function (fields) {
        var data = {
            uid: this.uid,
            name: this.name,
            verbose_name_input: this.verboseNameInput ? this.verboseNameInput.serialize() : null,
            shared_action_description: this.sharedActionDescription,
            action_description: this.actionDescription ? this.actionDescription.serialize() : undefined,
            inputs: this.inputs.map(function (item) { return item.serialize(); }),
            protected: this.protected,
            approve: this.approve ? this.approve.serialize() : undefined,
            confirmation: this.confirmation ? this.confirmation.serialize() : undefined,
            on_success_actions: this.onSuccessActions.map(function (item) { return item.serialize(); }),
            on_error_actions: this.onErrorActions.map(function (item) { return item.serialize(); })
        };
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Object.defineProperty(ActionItem.prototype, "queryLink", {
        get: function () {
            return ['execute_action'];
        },
        enumerable: true,
        configurable: true
    });
    ActionItem.prototype.generateUid = function () {
        this.uid = generateAlphanumeric(8, { letterFirst: true });
    };
    return ActionItem;
}());
export { ActionItem };
var Approve = /** @class */ (function () {
    function Approve() {
        this.taskInputs = [];
        this.onTaskCreateActions = [];
        this.onRejectActions = [];
    }
    Approve.prototype.deserialize = function (data) {
        this.taskQueue = data['task_queue'];
        this.taskName = new Input().deserialize(data['task_name']);
        this.taskAssignee = data['task_assignee'];
        this.taskCreateStatus = data['task_create_status'];
        this.taskApproveStatus = data['task_approve_status'];
        this.taskRejectStatus = data['task_reject_status'];
        if (data['task_inputs']) {
            this.taskInputs = data['task_inputs'].map(function (item) { return new Input().deserialize(item); });
        }
        if (data['on_task_create_actions']) {
            this.onTaskCreateActions = data['on_task_create_actions'].map(function (item) { return new ActionItem().deserialize(item); });
        }
        if (data['on_reject_actions']) {
            this.onRejectActions = data['on_reject_actions'].map(function (item) { return new ActionItem().deserialize(item); });
        }
        return this;
    };
    Approve.prototype.serialize = function (fields) {
        var data = {
            task_queue: this.taskQueue,
            task_name: this.taskName ? this.taskName.serialize() : undefined,
            task_inputs: this.taskInputs.map(function (item) { return item.serialize(); }),
            task_assignee: this.taskAssignee,
            task_create_status: this.taskCreateStatus,
            task_approve_status: this.taskApproveStatus,
            task_reject_status: this.taskRejectStatus,
            on_task_create_actions: this.onTaskCreateActions.map(function (item) { return item.serialize(); }),
            on_reject_actions: this.onRejectActions.map(function (item) { return item.serialize(); })
        };
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    return Approve;
}());
export { Approve };
