import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import range from 'lodash/range';

import { TintStyle } from '@modules/customize';
import { TypedChanges } from '@shared';

@Component({
  selector: 'app-table-stub-content-body, [app-table-stub-content-body]',
  templateUrl: './table-stub-content-body.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableStubContentBodyComponent implements OnInit, OnChanges {
  @Input() columns = 5;
  @Input() rows = 4;
  @Input() leftPaddingColumn = true;
  @Input() newColumn = false;
  @Input() action = false;
  @Input() selection = false;
  @Input() animating = false;
  @Input() scrollable = false;

  columnsRange: number[];
  rowsRange: number[];
  tintStyles = TintStyle;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: TypedChanges<TableStubContentBodyComponent>): void {
    if (changes.columns) {
      this.columnsRange = range(1, this.columns + 1);
    }

    if (changes.rows) {
      this.rowsRange = range(1, this.rows + 1);
    }
  }
}
