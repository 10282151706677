import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

import {
  CustomView,
  CustomViewLoaderService,
  CustomViewSource,
  CustomViewsStore,
  defaultCustomViewHtml
} from '@modules/custom-views';
import {
  CustomElementItem,
  CustomizeService,
  ElementType,
  registerElementComponent,
  ViewContextElement
} from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { applyParamInputs, LOADING_VALUE, NOT_SET_VALUE } from '@modules/fields';
import { CurrentProjectStore } from '@modules/projects';
import { QueryService } from '@modules/queries';
import { View } from '@modules/views';
import { TypedChanges } from '@shared';

import { CustomPagePopupComponent } from '../custom-page-popup/custom-page-popup.component';

@Component({
  selector: 'app-custom-element',
  templateUrl: './custom-element.component.html',
  providers: [ViewContextElement],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomElementComponent extends BaseElementComponent implements OnInit, OnDestroy, OnChanges {
  @Input() element: CustomElementItem;

  loading = true;
  customElementLoading = false;
  customElementUploadPending = false;
  customView: CustomView;
  customViewSubscription: Subscription;
  html: string;
  params = {};
  dataInputsChange = new Subject<void>();
  firstVisible = false;
  view: View;

  constructor(
    private viewContextElement: ViewContextElement,
    private customViewsStore: CustomViewsStore,
    private customViewLoaderService: CustomViewLoaderService,
    private queryService: QueryService,
    public customizeService: CustomizeService,
    public currentProjectStore: CurrentProjectStore,
    private cd: ChangeDetectorRef,
    @Optional() private popup: CustomPagePopupComponent
  ) {
    super();
  }

  ngOnInit() {
    this.initContext(this.element);
    this.updateContextOutputs(this.element);
    this.updateCustomView();

    this.dataInputsChange
      .pipe(
        filter(() => this.firstVisible),
        debounceTime(10),
        untilDestroyed(this)
      )
      .subscribe(() => this.onChange());

    this.context.outputValues$.pipe(untilDestroyed(this)).subscribe(() => this.dataInputsChange.next());
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<CustomElementComponent>): void {
    if (changes.element) {
      this.updateContextInfo(this.element);
      this.updateContextOutputs(this.element);
      this.updateCustomView();
    }

    this.dataInputsChange.next();
  }

  onFirstVisible() {
    this.firstVisible = true;
    this.dataInputsChange.next();
  }

  onChange() {
    const prevData = {
      params: this.params
    };
    const currentParams = applyParamInputs({}, this.element.inputs, {
      context: this.context,
      parameters: this.element.parameters,
      handleLoading: true
    });
    const currentData = this.element
      ? {
          params: pickBy(currentParams, (v, k) => v !== LOADING_VALUE && v !== NOT_SET_VALUE)
        }
      : {};

    if (!isEqual(prevData, currentData)) {
      this.params = currentData.params;
      this.cd.markForCheck();

      const source =
        this.element.source || (this.customView ? this.customView.source : undefined) || CustomViewSource.View;

      if (source == CustomViewSource.HTML) {
        this.updateHtml();
      }
    }
  }

  initContext(element: CustomElementItem) {
    this.viewContextElement.initElement({
      uniqueName: element.uid,
      name: element.name,
      element: element,
      popup: this.popup ? this.popup.popup : undefined
    });
  }

  updateContextInfo(element: CustomElementItem) {
    this.viewContextElement.initInfo({ name: element.name, element: this.element });
  }

  updateContextOutputs(element: CustomElementItem) {
    this.viewContextElement.setOutputs(
      element.outputs.map(item => ({
        uniqueName: item.name,
        name: item.verboseName || item.name,
        icon: item.fieldDescription.icon,
        fieldType: item.field,
        fieldParams: item.params,
        external: true
      }))
    );
  }

  updateCustomView() {
    if (this.customViewSubscription) {
      this.customViewSubscription.unsubscribe();
      this.customViewSubscription = undefined;
    }

    let customView$: Observable<CustomView>;

    if (this.element.customViewTemporary) {
      customView$ = of(this.element.customViewTemporary);
    } else if (this.element.customView) {
      customView$ = this.customViewsStore.getDetail(this.element.customView);
    } else {
      customView$ = of(undefined);
    }

    this.customViewSubscription = customView$.pipe(untilDestroyed(this)).subscribe(
      customView => {
        this.customView = customView;
        this.loading = false;
        this.cd.detectChanges();

        const source =
          this.element.source || (this.customView ? this.customView.source : undefined) || CustomViewSource.View;

        if (source == CustomViewSource.CustomElement) {
          this.init();
          this.html = undefined;
          this.view = undefined;
          this.cd.markForCheck();
        } else if (source == CustomViewSource.HTML) {
          this.updateHtml();
          this.view = undefined;
          this.cd.markForCheck();
        } else if (source == CustomViewSource.View) {
          this.view = this.customView ? this.customView.view : undefined;
          this.html = undefined;
          this.cd.markForCheck();
        }
      },
      () => {
        this.customView = undefined;
        this.html = undefined;
        this.loading = false;
        this.cd.markForCheck();
      }
    );
  }

  init() {
    if (!this.customView) {
      this.customElementUploadPending = false;
      this.cd.markForCheck();
      return;
    }

    if (this.customView.dist instanceof File) {
      this.customElementUploadPending = true;
      this.cd.markForCheck();
      return;
    } else {
      this.customElementUploadPending = false;
      this.cd.markForCheck();
    }

    if (this.customElementLoading) {
      return;
    }

    this.customElementLoading = true;
    this.cd.detectChanges();

    this.customViewLoaderService
      .load(this.customView)
      .pipe(untilDestroyed(this))
      .subscribe(() => (this.customElementLoading = false));
  }

  updateHtml() {
    if (!this.customView || this.customView.html === undefined) {
      this.html = defaultCustomViewHtml;
      this.cd.markForCheck();
      return;
    }

    this.html = this.queryService.applyTokens(this.customView.html, {
      params: this.params
    });
    this.cd.markForCheck();
  }

  onOutputEmitted(name: string, event: any) {
    this.viewContextElement.setOutputValue(name, event);
  }
}

registerElementComponent({
  type: ElementType.Custom,
  component: CustomElementComponent,
  label: 'Custom Component',
  actions: []
});
