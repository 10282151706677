import * as moment from 'moment';

import { generateUUID, isSet } from '@shared';

import { AnyField, EditableField } from '../data/base-field';
import { DefaultType } from '../data/default';
import { FormField } from '../data/form-field';
import { Input } from '../data/input';
import { InputValueType } from '../data/input-value-type';
import { ParameterField } from '../data/parameter-field';

// TODO: make common for actions and dbFields
export function getDefaultValue(field: ParameterField): any {
  let result = field.fieldDescription.defaultValue;

  if (field.defaultType == DefaultType.Value) {
    result = field.defaultValue;
  } else if (field.defaultType == DefaultType.DatetimeNow) {
    result = moment().toISOString();
  } else if (field.defaultType == DefaultType.UUID) {
    result = generateUUID();
  }

  return result;
}

export function anyFieldToFormField(field: AnyField): FormField {
  const result = new FormField();

  result.name = field.name;
  result.label = field.verboseName;
  result.description = field.description;
  // result.placeholder = field.name;
  result.field = field.field;
  result.editable = field.editable;
  result.required = field.required;
  result.params = field.params;

  return result;
}

export function isInputSet(value: Object) {
  if (!isSet(value)) {
    return false;
  } else if (value['value_type'] == InputValueType.Prompt) {
    return true;
  } else if (value['value_type'] == InputValueType.EmptyString) {
    return true;
  } else if (value['value_type'] == InputValueType.Null) {
    return true;
  } else if (value['value_type'] == InputValueType.StaticValue) {
    return isSet(value['static_value']);
  } else if (value['value_type'] == InputValueType.Filter) {
    return isSet(value['filter_field']) && isSet(value['filter_lookup']);
  } else if (value['value_type'] == InputValueType.Context) {
    return isSet(value['context_value']);
  } else if (value['value_type'] == InputValueType.Formula) {
    return isSet(value['formula_value']);
  } else if (value['value_type'] == InputValueType.Js) {
    return isSet(value['js_value']);
  } else {
    return false;
  }
}

export function isRequiredInputsSet(fields: EditableField[], inputs: Input[]) {
  return fields
    .filter(item => item.required)
    .every(item => {
      const input = isSet(item.name) ? inputs.find(i => i.name == item.name) : undefined;
      return input && input.isSet();
    });
}
