import { AfterViewInit, ChangeDetectorRef, ComponentFactoryResolver, ComponentRef, ElementRef, EventEmitter, Injector, NgZone, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChange, SimpleChanges } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import defaults from 'lodash/defaults';
import isPlainObject from 'lodash/isPlainObject';
import toPairs from 'lodash/toPairs';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, take } from 'rxjs/operators';
import { DialogService } from '@common/dialogs';
import { AppDropList, DropListService } from '@common/drag-drop2';
import { DynamicComponent } from '@common/dynamic-component';
import { NotificationService } from '@common/notifications';
import { PopoverService } from '@common/popover';
import { PopupService } from '@common/popups';
import { ResizeType } from '@common/resizable';
import { SessionStorage } from '@core';
import { AdminMode } from '@modules/admin-mode';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { AlignHorizontal, AlignVertical, CustomizeService, ElementItem, ElementPaddingType, ElementType, elementTypePadding, elementTypeResize, elementTypeResizeMax, elementTypeResizeMin, elementTypeResizeStep, getElementComponentByType, ignoreElementCustomize, isElementCustomizeIgnored, isElementTypeAddable, isElementTypeAlignHorizontal, isElementTypeAlignVertical, isElementTypeContainer, isElementTypeCustomizable, isElementTypeCustomizableAlways, isElementTypeCustomizeOnAdd, isElementTypeDeletable, isElementTypeGroupable, isElementTypeWrappable, markElementClickEvent, ViewContext } from '@modules/customize';
import { CustomizeBarContext, CustomizeBarEditEventType, CustomizeBarService } from '@modules/customize-bar';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { AutoElementToolbarComponent } from '@modules/customize-ui';
import { applyBooleanInput } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { RoutingService } from '@modules/routing';
import { defaultComponentTemplateName, Template, TemplateService, TemplateType } from '@modules/template';
import { addClass, forceObservable, isControlElement, isSet, KeyboardEventKeyCode, removeClass } from '@shared';
// TODO: Refactor import
import { CustomPagePopupComponent } from '../../../customize-components/components/custom-page-popup/custom-page-popup.component';
import { ElementComponentsService } from '../../services/element-components/element-components.service';
import { ElementContainerService } from '../../services/element-container/element-container.service';
import { BUILDER_ELEMENT_BUFFER } from '../../services/element-container/element-container.service';
import { BUILDER_ADD_ELEMENT } from '../../services/element-container/element-container.service';
export var DRAG_DROP_SIBLING_ACTIVE_CLASS = 'app-drag-drop-sibling-active';
var AutoElementComponent = /** @class */ (function () {
    function AutoElementComponent(injector, mode, customizeService, customizeBarContext, customizeBarService, popupService, popoverService, resolver, cd, sessionStorage, routing, templateService, modelDescriptionStore, notificationService, dropListService, dialogService, el, renderer, elementComponentsService, elementContainerService, elementConfigurationService, zone, analyticsService, parentElement, parentPopup, dropList) {
        this.injector = injector;
        this.mode = mode;
        this.customizeService = customizeService;
        this.customizeBarContext = customizeBarContext;
        this.customizeBarService = customizeBarService;
        this.popupService = popupService;
        this.popoverService = popoverService;
        this.resolver = resolver;
        this.cd = cd;
        this.sessionStorage = sessionStorage;
        this.routing = routing;
        this.templateService = templateService;
        this.modelDescriptionStore = modelDescriptionStore;
        this.notificationService = notificationService;
        this.dropListService = dropListService;
        this.dialogService = dialogService;
        this.el = el;
        this.renderer = renderer;
        this.elementComponentsService = elementComponentsService;
        this.elementContainerService = elementContainerService;
        this.elementConfigurationService = elementConfigurationService;
        this.zone = zone;
        this.analyticsService = analyticsService;
        this.parentElement = parentElement;
        this.parentPopup = parentPopup;
        this.dropList = dropList;
        this.customizing = false;
        this.wrapperEnabled = true;
        this.paddingEnabled = true;
        this.updated = new EventEmitter();
        this.duplicatedRequested = new EventEmitter();
        this.replaceRequested = new EventEmitter();
        this.deleteRequested = new EventEmitter();
        this.moveToRequested = new EventEmitter();
        this.visibleUpdated = new EventEmitter();
        this.customizeComponentData = new BehaviorSubject(undefined);
        this.hover = false;
        this.hoverAnother = false;
        this.draggingOver = false;
        this.actionClicked = new Subject();
        this.isContainer = false;
        this.containerHorizontal = false;
        this.customizingElement = false;
        this.customizingElementFirstInit = false;
        this.resizingElement = false;
        this.customizeElementEnabled = false;
        this.addElementEnabled = false;
        this.deleteElementEnabled = false;
        this.customizeElementAlways = false;
        this.interactionsDisabled = false;
        this.resizeSupported = { width: false, height: false };
        this.resizeEnabled = { width: false, height: false };
        this.resizeStep = {};
        this.resizeMinSize = {};
        this.resizeMaxSize = {};
        this.alignHorizontalSupported = false;
        this.alignVerticalSupported = false;
        this.capabilitiesSubscriptions = [];
        this.elementSelfCustomized = new Subject();
        this.configured = true;
        this.visible = false;
        this.wrapper = false;
        this.padding = {};
        this.paddingTypes = ElementPaddingType;
        this.toolbarBottom = false;
        this.siblingLeftEntered$ = new BehaviorSubject(false);
        this.siblingRightEntered$ = new BehaviorSubject(false);
        this.adminModes = AdminMode;
        this.alignsHorizontal = AlignHorizontal;
        this.elementCanEnterSibling = (function () {
            return function (drag, drop) {
                return drag.data instanceof ElementItem || (isPlainObject(drag.data) && !drag.data.popup);
            };
        })();
        this.containerCanMove = (function () {
            return function (drag, drop) {
                return true;
            };
        })();
    }
    Object.defineProperty(AutoElementComponent.prototype, "inlineClass", {
        get: function () {
            return this.alignHorizontalSupported ? this.element.isInline() : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutoElementComponent.prototype, "elementId", {
        get: function () {
            return this.element.uid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutoElementComponent.prototype, "elementName", {
        get: function () {
            return this.element.name;
        },
        enumerable: true,
        configurable: true
    });
    AutoElementComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.elementComponentsService.register(this.element, this);
        this.initElement();
        this.initElementSize();
        this.updateCapabilities();
        if (this.mode == AdminMode.Builder) {
            combineLatest(this.customizeService.lastHovered$, this.dropListService.dragging$)
                .pipe(untilDestroyed(this))
                .subscribe(function (_a) {
                var lastHovered = _a[0], dragging = _a[1];
                _this.hover = lastHovered === _this && !dragging;
                _this.hoverAnother = !!lastHovered && lastHovered !== _this;
                _this.draggingOver = lastHovered === _this && dragging;
                _this.cd.markForCheck();
                if (!dragging) {
                    _this.siblingLeftEntered$.next(false);
                    _this.siblingRightEntered$.next(false);
                }
            });
            if (this.customizeBarContext) {
                combineLatest(this.customizeComponentData, this.customizeBarContext.settingsComponents$)
                    .pipe(untilDestroyed(this))
                    .subscribe(function (_a) {
                    var customizeComponentData = _a[0], components = _a[1];
                    var customizingElement = components[0] && components[0] === customizeComponentData;
                    if (_this.customizingElement == customizingElement) {
                        return;
                    }
                    _this.customizingElement = customizingElement;
                    if (!_this.customizingElement) {
                        _this.customizingElementFirstInit = false;
                    }
                    _this.cd.markForCheck();
                    _this.updateElement();
                });
            }
            combineLatest(this.siblingLeftEntered$, this.siblingRightEntered$)
                .pipe(untilDestroyed(this))
                .subscribe(function (_a) {
                var siblingLeftEntered = _a[0], siblingRightEntered = _a[1];
                var dropList = _this.dropListService.draggingDropList$.value;
                if (dropList) {
                    dropList.mergeDraggingStateData({
                        siblingLeftEntered: siblingLeftEntered,
                        siblingRightEntered: siblingRightEntered,
                        siblingSelf: false,
                        siblingAnchor: _this.element,
                        siblingAnchorContainer: _this.dropList
                    });
                }
                if (siblingLeftEntered || siblingRightEntered) {
                    addClass(document.body, DRAG_DROP_SIBLING_ACTIVE_CLASS);
                }
                else {
                    removeClass(document.body, DRAG_DROP_SIBLING_ACTIVE_CLASS);
                }
            });
            fromEvent(document, 'keydown')
                .pipe(filter(function () {
                return (_this.customizing &&
                    _this.customizingElement &&
                    !_this.popupService.items.length &&
                    !isControlElement(document.activeElement));
            }), untilDestroyed(this))
                .subscribe(function (e) {
                if (e.keyCode == KeyboardEventKeyCode.Escape) {
                    _this.closeCustomize();
                }
                else if (e.keyCode == KeyboardEventKeyCode.Backspace) {
                    _this.deleteElement();
                }
                else if ((e.metaKey || e.ctrlKey) && e.keyCode == KeyboardEventKeyCode.X) {
                    _this.copyElement(true);
                }
                else if ((e.metaKey || e.ctrlKey) && e.keyCode == KeyboardEventKeyCode.C) {
                    _this.copyElement();
                }
            });
        }
    };
    AutoElementComponent.prototype.ngOnDestroy = function () {
        this.elementComponentsService.unregister(this.element);
        this.customizeService.removeHover(this);
        this.closeCustomize();
        if (this.siblingLeftEntered$.value || this.siblingRightEntered$.value) {
            removeClass(document.body, DRAG_DROP_SIBLING_ACTIVE_CLASS);
        }
    };
    AutoElementComponent.prototype.ngOnChanges = function (changes) {
        if (changes.element && !changes.element.firstChange) {
            this.elementComponentsService.unregister(changes.element.previousValue);
            this.elementComponentsService.register(this.element, this);
        }
        if (changes.element) {
            var prevType = changes.element.previousValue ? changes.element.previousValue.type : undefined;
            var currentType = changes.element.currentValue ? changes.element.currentValue.type : undefined;
            this.initElementSize();
            this.updateConfigured();
            this.updateVisible();
            this.updateGroupable();
            this.updateHidden();
            if (prevType != currentType) {
                this.initElement();
            }
            else {
                this.updateElement({ updateCustomizeComponent: true });
            }
        }
        else if (changes.accentColor) {
            this.updateElement();
        }
        if (changes.element || changes.customizing) {
            this.updateCapabilities();
        }
    };
    AutoElementComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.mode == AdminMode.Builder) {
            fromEvent(this.elementWrapperElement.nativeElement, 'mouseenter')
                .pipe(filter(function () { return _this.customizing; }), untilDestroyed(this))
                .subscribe(function () { return _this.onHover(true); });
            fromEvent(this.elementWrapperElement.nativeElement, 'mouseleave')
                .pipe(untilDestroyed(this))
                .subscribe(function () { return _this.onHover(false); });
        }
    };
    Object.defineProperty(AutoElementComponent.prototype, "displayName", {
        get: function () {
            if (!this.element) {
                return;
            }
            return this.element.name || this.elementComponent.label;
        },
        enumerable: true,
        configurable: true
    });
    AutoElementComponent.prototype.updateToolbarBottom = function () {
        var toolbarBottom = this.el.nativeElement.getBoundingClientRect().top < 110;
        if (this.toolbarBottom == toolbarBottom) {
            return;
        }
        this.toolbarBottom = toolbarBottom;
        this.cd.markForCheck();
    };
    AutoElementComponent.prototype.initElement = function () {
        var _this = this;
        var elementComponent = this.element ? getElementComponentByType(this.element.type) : undefined;
        if (!elementComponent) {
            this.componentData = undefined;
            this.cd.markForCheck();
            console.error("No such component type registered: " + this.element.type);
            return;
        }
        this.componentData = {
            component: elementComponent.component,
            inputs: {
                element: this.element,
                elementActive: this.customizingElement,
                context: this.context,
                visible: this.visible,
                accentColor: this.accentColor,
                // TODO: Refactor
                actionClicked: this.actionClicked.asObservable()
            },
            outputs: {
                updated: [
                    function (element) {
                        _this.onCustomized(element);
                        _this.elementSelfCustomized.next(element);
                    }
                ],
                replaceRequested: [function (elements) { return _this.replaceRequested.next(elements); }],
                deleteRequested: [function () { return _this.deleteRequested.next(); }]
            },
            resolver: this.resolver
        };
        this.elementComponent = elementComponent;
        this.wrapper = this.wrapperEnabled && isElementTypeWrappable(this.element.type);
        this.padding = elementTypePadding(this.element.type);
        this.cd.markForCheck();
        this.zone.onStable
            .pipe(take(1))
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            var createdElement = _this.customizeService.applyCreatedElementComponent(_this.element);
            if (createdElement) {
                _this.onAdd(createdElement.barItem);
            }
        });
    };
    AutoElementComponent.prototype.updateElement = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        if (!this.dynamicComponent ||
            !this.dynamicComponent.currentComponent ||
            !this.dynamicComponent.currentComponent.instance) {
            return;
        }
        var ref = this.dynamicComponent.currentComponent;
        var changes = {};
        var inputs = {
            element: this.element,
            elementActive: this.customizingElement,
            context: this.context,
            visible: this.visible,
            accentColor: this.accentColor
        };
        var addChange = function (name, value) {
            changes[name] = new SimpleChange(ref.instance[name], value, false);
            ref.instance[name] = value;
        };
        toPairs(inputs).forEach(function (_a) {
            var name = _a[0], value = _a[1];
            if (name == 'element' && options.forceUpdate) {
                addChange(name, value);
            }
            else if (name == 'element' && !options.forceUpdate) {
                var currentValue = value;
                var currentValueSerialized = currentValue ? currentValue.serialize() : undefined;
                var previousValue = ref.instance.element;
                var previousValueSerialized = previousValue ? previousValue.serialize() : undefined;
                if (JSON.stringify(previousValueSerialized) != JSON.stringify(currentValueSerialized)) {
                    addChange(name, value);
                }
            }
            else {
                if (ref.instance[name] !== value) {
                    addChange(name, value);
                }
            }
            _this.componentData.inputs[name] = value;
        });
        if (values(changes).length) {
            ref.changeDetectorRef.markForCheck();
            if (ref.instance['ngOnChanges']) {
                ref.instance['ngOnChanges'](changes);
            }
            if (options.updateCustomizeComponent && this.customizingElement) {
                this.closeCustomize();
            }
        }
    };
    AutoElementComponent.prototype.updateConfigured = function () {
        var _this = this;
        this.elementConfigurationService
            .isConfigured(this.element)
            .pipe(untilDestroyed(this))
            .subscribe(function (configured) {
            _this.configured = configured;
            _this.cd.markForCheck();
        });
    };
    AutoElementComponent.prototype.updateVisible = function () {
        var _this = this;
        if (this.visibleSubscription) {
            this.visibleSubscription.unsubscribe();
        }
        if (!this.element.visibleInput) {
            this.setVisible(true);
            return;
        }
        var visible = applyBooleanInput(this.element.visibleInput, this.context);
        this.setVisible(visible);
        this.visibleSubscription = this.context.outputValues$
            .pipe(debounceTime(10), distinctUntilChanged(), untilDestroyed(this))
            .subscribe(function () {
            var value = applyBooleanInput(_this.element.visibleInput, _this.context);
            _this.setVisible(value, true);
        });
    };
    AutoElementComponent.prototype.updateGroupable = function () {
        if (isElementTypeGroupable(this.element.type)) {
            this.renderer.setAttribute(this.el.nativeElement, 'element-groupable', 'true');
        }
        else {
            this.renderer.removeAttribute(this.el.nativeElement, 'element-groupable');
        }
    };
    AutoElementComponent.prototype.updateHidden = function () {
        if (!this.visibleDisplay) {
            this.renderer.setAttribute(this.el.nativeElement, 'element-hidden', 'true');
        }
        else {
            this.renderer.removeAttribute(this.el.nativeElement, 'element-hidden');
        }
    };
    AutoElementComponent.prototype.setVisible = function (visible, updateElement) {
        if (updateElement === void 0) { updateElement = false; }
        if (this.visible == visible) {
            return;
        }
        this.visible = visible;
        this.cd.markForCheck();
        this.updateHidden();
        if (updateElement) {
            this.updateElement();
        }
        this.visibleUpdated.emit();
    };
    AutoElementComponent.prototype.updateCapabilities = function () {
        var _this = this;
        this.capabilitiesSubscriptions.forEach(function (item) { return item.unsubscribe(); });
        this.capabilitiesSubscriptions = [];
        this.isContainer = isElementTypeContainer(this.element.type);
        this.containerHorizontal = this.parentElement && this.parentElement.element.type == ElementType.Stack;
        this.customizeElementEnabled = this.customizing && isElementTypeCustomizable(this.element.type);
        this.addElementEnabled = isElementTypeAddable(this.element.type);
        this.deleteElementEnabled = this.customizing && isElementTypeDeletable(this.element.type);
        this.customizeElementAlways = this.customizing && isElementTypeCustomizableAlways(this.element.type);
        this.resizeStep = elementTypeResizeStep(this.element.type);
        this.alignHorizontalSupported = !this.containerHorizontal && isElementTypeAlignHorizontal(this.element.type);
        this.alignVerticalSupported = this.containerHorizontal && isElementTypeAlignVertical(this.element.type);
        this.cd.markForCheck();
        this.capabilitiesSubscriptions.push(combineLatest(forceObservable(elementTypeResize(this.element.type, { injector: this.injector, element: this.element })), forceObservable(elementTypeResizeMin(this.element.type, { injector: this.injector, element: this.element })), forceObservable(elementTypeResizeMax(this.element.type, { injector: this.injector, element: this.element })))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var resizeSupported = _a[0], resizeMinSize = _a[1], resizeMaxSize = _a[2];
            var margin = _this.getMarginSize();
            _this.resizeSupported = resizeSupported;
            _this.resizeEnabled = _this.customizing
                ? _this.resizeSupported
                : {
                    width: false,
                    height: false
                };
            _this.resizeMinSize = {
                width: isSet(resizeMinSize.width) ? resizeMinSize.width + margin.horizontal : undefined,
                height: isSet(resizeMinSize.height) ? resizeMinSize.height + margin.vertical : undefined
            };
            _this.resizeMaxSize = {
                width: isSet(resizeMaxSize.width) ? resizeMaxSize.width + margin.horizontal : undefined,
                height: isSet(resizeMaxSize.height) ? resizeMaxSize.height + margin.vertical : undefined
            };
            _this.initElementSize();
            _this.cd.markForCheck();
        }));
    };
    AutoElementComponent.prototype.getMarginSize = function () {
        var horizontal = 0;
        var vertical = 0;
        if (isSet(this.element.margin.left)) {
            horizontal += this.element.margin.left;
        }
        else if (this.padding.horizontal == ElementPaddingType.Normal ||
            this.padding.horizontal == ElementPaddingType.DoubleAfter) {
            horizontal += 20;
        }
        else if (this.padding.horizontal == ElementPaddingType.DoubleBefore) {
            horizontal += 40;
        }
        if (isSet(this.element.margin.right)) {
            horizontal += this.element.margin.right;
        }
        else if (this.padding.horizontal == ElementPaddingType.Normal ||
            this.padding.horizontal == ElementPaddingType.DoubleBefore) {
            horizontal += 20;
        }
        else if (this.padding.horizontal == ElementPaddingType.DoubleAfter) {
            horizontal += 40;
        }
        if (isSet(this.element.margin.top)) {
            vertical += this.element.margin.top;
        }
        else if (this.padding.vertical == ElementPaddingType.Normal ||
            this.padding.vertical == ElementPaddingType.DoubleAfter) {
            vertical += 15;
        }
        else if (this.padding.vertical == ElementPaddingType.DoubleBefore) {
            vertical += 30;
        }
        if (isSet(this.element.margin.bottom)) {
            vertical += this.element.margin.bottom;
        }
        else if (this.padding.vertical == ElementPaddingType.Normal ||
            this.padding.vertical == ElementPaddingType.DoubleBefore) {
            vertical += 15;
        }
        else if (this.padding.vertical == ElementPaddingType.DoubleAfter) {
            vertical += 30;
        }
        return {
            horizontal: horizontal,
            vertical: vertical
        };
    };
    Object.defineProperty(AutoElementComponent.prototype, "visibleDisplay", {
        get: function () {
            return this.visible || !!this.customizeService.enabled;
        },
        enumerable: true,
        configurable: true
    });
    AutoElementComponent.prototype.onHover = function (hover) {
        if (hover) {
            this.customizeService.addHover(this);
            this.updateToolbarBottom();
        }
        else {
            this.customizeService.removeHover(this);
        }
    };
    AutoElementComponent.prototype.getForm = function () {
        if (!this.parentElement) {
            return;
        }
        else if (this.parentElement.element.type == ElementType.Form) {
            return this.parentElement;
        }
        else {
            return this.parentElement.getForm();
        }
    };
    AutoElementComponent.prototype.customize = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        options = defaults(options, { firstInit: false });
        if (!this.customizing) {
            return;
        }
        if (!this.customizeBarContext) {
            return;
        }
        if (options.event) {
            if (isElementCustomizeIgnored(options.event)) {
                return;
            }
            else {
                ignoreElementCustomize(options.event);
            }
        }
        if (this.customizingElement) {
            if (options.highlight) {
                this.customizeBarContext.highlight();
            }
            return;
        }
        if (!this.customizeElementEnabled) {
            return;
        }
        if (options.firstInit && !isElementTypeCustomizeOnAdd(this.element.type)) {
            return;
        }
        var component = this.dynamicComponent && this.dynamicComponent.currentComponent && this.dynamicComponent.currentComponent.instance
            ? this.dynamicComponent.currentComponent.instance
            : undefined;
        this.customizingElementFirstInit = options.firstInit;
        this.cd.markForCheck();
        this.customizeBarService
            .customize(this.customizeBarContext, this.element, this.context, component, this.elementSelfCustomized, {
            append: options.append,
            backLabel: options.backLabel,
            firstInit: options.firstInit,
            setupOnCreate: options.setupOnCreate,
            parentElement: this.parentElement,
            parentPopup: this.parentPopup,
            parentForm: this.getForm()
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (result.event && result.event === CustomizeBarEditEventType.Deleted) {
                _this.deleteElement();
            }
            else {
                _this.setElementSize(result.el);
                _this.onCustomized(result.el);
            }
        });
        if (options.forceHover) {
            this.customizeService.forceHover(this);
        }
        this.customizeComponentData.next(this.customizeBarContext.settingsComponent);
        this.updateToolbarBottom();
    };
    AutoElementComponent.prototype.closeCustomize = function () {
        if (this.customizeComponentData.value) {
            this.customizeBarContext.closeSettingsComponent(this.customizeComponentData.value);
            this.customizeComponentData.next(undefined);
        }
    };
    AutoElementComponent.prototype.duplicateElement = function () {
        this.duplicatedRequested.emit();
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.Duplicated, {
            ComponentTypeID: this.element.analyticsName
        });
    };
    AutoElementComponent.prototype.moveElementToPage = function (page) {
        if (!page || !page.link) {
            return;
        }
        this.sessionStorage.set(BUILDER_ADD_ELEMENT, JSON.stringify({
            element: this.element.serialize()
        }));
        this.moveToRequested.emit(page.link);
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.MovedToPage, {
            ComponentTypeID: this.element.analyticsName
        });
    };
    AutoElementComponent.prototype.copyElement = function (cut) {
        if (cut === void 0) { cut = false; }
        this.sessionStorage.set(BUILDER_ELEMENT_BUFFER, JSON.stringify({
            element: this.element.serialize(),
            persistent: !cut
        }));
        if (cut) {
            this.deleteElementProcess();
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.Cut, {
                ComponentTypeID: this.element.analyticsName
            });
        }
        else {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.Copy, {
                ComponentTypeID: this.element.analyticsName
            });
        }
    };
    AutoElementComponent.prototype.deleteElement = function () {
        var _this = this;
        var components = this.element.childrenCount();
        if (!components) {
            this.deleteElementProcess();
            return;
        }
        this.dialogService
            .warning({
            title: "Delete container components (" + components + ")",
            description: "\n          This container contains other components.\n          Are you sure want to delete this container with the contained components?\n        ",
            style: 'orange'
        })
            .pipe(filter(function (result) { return result; }), untilDestroyed(this))
            .subscribe(function () { return _this.deleteElementProcess(); });
    };
    AutoElementComponent.prototype.deleteElementProcess = function () {
        this.closeCustomize();
        this.deleteRequested.emit();
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.Deleted, {
            ComponentTypeID: this.element.analyticsName
        });
    };
    AutoElementComponent.prototype.onAdd = function (item) {
        var _this = this;
        this.elementContainerService
            .setUpElementComponent(this.element, item)
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (result.customized) {
                _this.onCustomized(result.element);
            }
            _this.onCreated(item);
        }, function () {
            _this.onSetupCancel();
        });
        if (item) {
            this.elementContainerService.sendAddElementItemAnalytics(this.element, item);
        }
    };
    AutoElementComponent.prototype.onCreated = function (item) {
        this.customize({ firstInit: true, setupOnCreate: item ? item.setupOnCreate : false });
        this.customizeService.markChanged();
    };
    AutoElementComponent.prototype.onSetupCancel = function () {
        this.deleteElement();
    };
    AutoElementComponent.prototype.onCustomized = function (element) {
        this.element.copy(element);
        this.updated.next(element);
        this.customizeService.markChanged();
        this.updateConfigured();
        this.updateVisible();
        this.updateCapabilities();
        this.updateGroupable();
        this.updateHidden();
        this.updateElement({ forceUpdate: true });
    };
    AutoElementComponent.prototype.createTemplate = function () {
        this.sessionStorage.set('template_widget', JSON.stringify(this.element.serialize()));
        this.routing.navigateApp(['create_template']);
    };
    AutoElementComponent.prototype.setDefaultElement = function () {
        var _this = this;
        this.dialogService
            .confirm({
            title: 'Default Component',
            description: 'Do you want to set this component as default for this component type?'
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (!result) {
                return;
            }
            var instance = new Template();
            instance.type = TemplateType.DefaultComponent;
            instance.uniqueName = defaultComponentTemplateName(_this.element);
            instance.name = _this.element.type;
            instance.element = cloneDeep(_this.element);
            instance.element.uid = undefined;
            instance.active = true;
            _this.templateService
                .create(instance)
                .pipe(untilDestroyed(_this))
                .subscribe(function () {
                _this.notificationService.success('Updated', 'Default component settings updated');
            });
        });
    };
    AutoElementComponent.prototype.initElementSize = function () {
        if (this.resizeSupported.width) {
            this.width = this.element.width;
        }
        if (this.resizeSupported.height) {
            this.height = this.element.height;
        }
    };
    AutoElementComponent.prototype.setElementSize = function (element) {
        if (this.resizeEnabled.width) {
            element.width = this.width;
            if (isSet(element.width) &&
                this.alignHorizontalSupported &&
                element.alignHorizontalOrDefault == AlignHorizontal.Justify) {
                element.alignHorizontal = AlignHorizontal.Left;
            }
        }
        if (this.resizeEnabled.height) {
            element.height = this.height;
            if (isSet(element.height) &&
                this.alignVerticalSupported &&
                (!element.alignVertical || element.alignVertical == AlignVertical.Justify)) {
                element.alignVertical = AlignVertical.Top;
            }
        }
    };
    AutoElementComponent.prototype.markElementEvent = function (e) {
        markElementClickEvent(e);
    };
    AutoElementComponent.prototype.onResizeStarted = function () {
        this.interactionsDisabled = true;
        this.resizingElement = true;
        this.cd.markForCheck();
    };
    AutoElementComponent.prototype.onResize = function (event) {
        if (event.types.includes(ResizeType.Horizontal) && event.widthChanged) {
            this.width = event.width;
        }
        if (event.types.includes(ResizeType.Vertical) && event.heightChanged) {
            this.height = event.height;
        }
        this.setElementSize(this.element);
        this.interactionsDisabled = false;
        this.resizingElement = false;
        this.cd.markForCheck();
        this.customizeService.markChanged();
    };
    AutoElementComponent.prototype.alignHorizontal = function (alignment) {
        this.element.alignHorizontal = alignment;
        if ((!alignment || alignment == AlignHorizontal.Justify) && this.resizeEnabled.width) {
            this.width = undefined;
            this.element.width = undefined;
        }
        this.cd.markForCheck();
        this.customizeService.markChanged();
    };
    AutoElementComponent.prototype.alignVertical = function (alignment) {
        this.element.alignVertical = alignment;
        if ((!alignment || alignment == AlignVertical.Justify) && this.resizeEnabled.height) {
            this.height = undefined;
            this.element.height = undefined;
        }
        this.cd.markForCheck();
        this.customizeService.markChanged();
    };
    AutoElementComponent.prototype.isCreateColumnsAllowed = function () {
        var isRestricted = [
            ElementType.Columns,
            ElementType.Stack,
            ElementType.Tabs,
            ElementType.Form,
            ElementType.Card
        ].includes(this.element.type);
        var isStackParent = this.parentElement && this.parentElement.element.type == ElementType.Stack;
        var widthBigEnough = this.el.nativeElement.offsetWidth >= 200;
        return !isRestricted && !isStackParent && widthBigEnough;
    };
    return AutoElementComponent;
}());
export { AutoElementComponent };
