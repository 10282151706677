<div
  class="alert"
  [class.alert_tint_info]="info"
  [class.alert_tint_secondary]="secondary"
  [class.alert_tint_danger]="danger"
  [class.alert_tint_warning]="warning"
  [class.alert_tint_success]="success"
  [class.alert_tint_purple]="purple"
  [class.alert_tint-color]="color"
  [class.alert_fill]="!border"
  [class.alert_border]="border"
  [class.alert_compact]="compact"
  [class.alert_animate-show]="animateShow"
  [appTintColor]="color"
  [appTintColorStyle]="tintStyles.Primary"
>
  <div *ngIf="icon || loader" class="alert__left">
    <span *ngIf="icon" class="alert__icon" [ngClass]="'icon-' + icon"></span>
    <app-loader-small *ngIf="loader" class="alert__title-icon"></app-loader-small>
  </div>

  <div class="alert__main" [class.alert__main_center]="center">
    <div *ngIf="title | appIsSet" class="alert__title">
      {{ title }}
    </div>

    <div class="alert__description">
      <ng-content></ng-content>
    </div>

    <div class="alert__actions">
      <ng-content select="ng-container[actions]"></ng-content>
    </div>
  </div>
</div>
