<div class="model-card__item">
  <label *ngIf="label" class="model-card__item-label">
    {{ item.verboseName }}

    <app-model-card-item-action
      *ngFor="let action of actions"
      [model]="model"
      [action]="action"
      [context]="context"
      [contextElement]="contextElement"
      (click)="$event.stopPropagation()"
    >
    </app-model-card-item-action>
  </label>

  <div class="model-card__item-value">
    <ng-container *ngIf="!view">
      <app-auto-field
        *ngIf="!(valueStr | appIsSet)"
        [field]="createField(item)"
        [readonly]="true"
        [value]="model.getAttribute(item.name)"
        [label]="false"
        [context]="context"
      >
      </app-auto-field>

      <ng-container *ngIf="valueStr | appIsSet">
        {{ valueStr | appSafeAsync }}
      </ng-container>
    </ng-container>

    <div
      *ngIf="view"
      [style.height.px]="view.frame.height"
      [style.min-width.px]="view.widthResize.enabled && (view.widthResize.min | appIsSet) ? view.widthResize.min : null"
      [style.max-width.px]="view.widthResize.enabled && (view.widthResize.max | appIsSet) ? view.widthResize.max : null"
      [style.min-height.px]="
        view.heightResize.enabled && (view.heightResize.min | appIsSet) ? view.heightResize.min : null
      "
      [style.max-height.px]="
        view.heightResize.enabled && (view.heightResize.max | appIsSet) ? view.heightResize.max : null
      "
    >
      <app-custom-element-view
        [view]="view"
        [parameters]="viewParameters"
        [params]="viewParams$ | async"
      ></app-custom-element-view>
    </div>
  </div>
</div>
