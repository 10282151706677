import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of } from 'rxjs';
import { debounceTime, first, map, switchMap } from 'rxjs/operators';

import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import {
  cleanElementName,
  FormSubmitElementItem,
  ViewContext,
  ViewContextElement,
  ViewSettingsAction
} from '@modules/customize';
import { createFormFieldFactory, Input as FieldInput } from '@modules/fields';
import { SidebarCollapseContext } from '@modules/sidebar';

import { CustomizeBarEditComponent } from '../../data/customize-bar-edit-component';
import { CustomizeBarEditEvent } from '../../data/customize-bar-edit-event';
import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { ElementConfiguration, trackConfigured } from '../../utils/analytics';
import { CustomizeBarFormSubmitEditForm } from './customize-bar-form-submit-edit.form';

@Component({
  selector: 'app-customize-bar-form-submit-edit',
  templateUrl: './customize-bar-form-submit-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CustomizeBarFormSubmitEditForm]
})
export class CustomizeBarFormSubmitEditComponent implements OnInit, OnDestroy, CustomizeBarEditComponent {
  @Input() element: FormSubmitElementItem;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() visibleInput: FieldInput;
  @Input() visibleEditable: boolean;
  @Input() titleEnabled = true;
  @Input() titleEditable: boolean;
  @Input() deleteEnabled = false;
  @Input() title: string;
  @Input() parentElement: any;
  @Input() parentPopup: any;
  @Input() parentForm: any;
  @Input() firstInit = false;
  @Output() event = new EventEmitter<CustomizeBarEditEvent>();

  viewSettingsActionClass = ViewSettingsAction;
  createField = createFormFieldFactory();
  result: FormSubmitElementItem;
  collapseContext = new SidebarCollapseContext();
  configurationStarted = false;

  titleCleanValue = (() => {
    return (value: string): string => {
      return cleanElementName(value, this.element, this.context.getElementItems());
    };
  })();

  constructor(
    private cd: ChangeDetectorRef,
    private customizeBarContext: CustomizeBarContext,
    public form: CustomizeBarFormSubmitEditForm,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnInit(): void {
    this.form.init(this.element, this.firstInit);

    const resultObs = this.form.form.valueChanges.pipe(
      debounceTime(200),
      map(() => this.form.submit())
    );

    resultObs.pipe(untilDestroyed(this)).subscribe(result => {
      this.result = result;
      this.emitUpdate();
    });

    resultObs
      .pipe(
        switchMap(result => this.form.isConfigured(result)),
        trackConfigured(),
        first(configuration => configuration == ElementConfiguration.Started),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.configurationStarted = true;
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.StartedConfiguration, {
          ComponentTypeID: this.element.analyticsName
        });
      });
  }

  ngOnDestroy(): void {}

  delete() {
    this.event.emit({ type: CustomizeBarEditEventType.Deleted });
    this.close();
  }

  close() {
    (this.configurationStarted ? this.form.isConfigured(this.result) : of(false))
      .pipe(untilDestroyed(this))
      .subscribe(configured => {
        if (configured) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.SuccessfullyConfigured, {
            ComponentTypeID: this.element.analyticsName
          });
        }

        this.customizeBarContext.popSettingsComponent();
      });
  }

  submit() {
    this.result = this.form.submit();
    this.emitUpdate(true);
    this.close();
  }

  onTitleChanged(title: string) {
    this.title = title;
    this.result = this.form.submit();
    this.emitUpdate();
  }

  emitUpdate(submit = false) {
    const args = { result: this.result, title: this.title, submit: submit };

    this.event.emit({
      type: CustomizeBarEditEventType.Updated,
      args: args
    });
  }

  cancel() {
    this.event.emit({ type: CustomizeBarEditEventType.Canceled });
    this.close();
  }

  editForm(event: MouseEvent) {
    this.parentForm.customize({ event: event });
  }
}
