import uniq from 'lodash/uniq';
import * as moment from 'moment';
import { localize } from '@common/localize';
import { User } from '@modules/users';
import { ProjectPermission, ProjectPermissionType } from './project-permission';
var ProjectUser = /** @class */ (function () {
    function ProjectUser() {
        this.properties = {};
    }
    ProjectUser.prototype.deserialize = function (data) {
        this.uid = data['uid'];
        this.userEmail = data['user_email'];
        this.properties = data['properties'];
        this.dateAdd = moment(data['date_add']);
        if (data['user']) {
            this.user = new User().deserialize(data['user']);
        }
        if (data['group']) {
            this.group = new ProjectGroup().deserialize(data['group']);
        }
        else {
            this.group = new ProjectGroup();
        }
        return this;
    };
    ProjectUser.prototype.serialize = function () {
        return {
            user: this.user ? this.user.id : undefined,
            user_email: this.userEmail,
            group: this.group ? this.group.uid : undefined,
            properties: this.properties
        };
    };
    Object.defineProperty(ProjectUser.prototype, "link", {
        get: function () {
            return ['project', 'users', this.uid];
        },
        enumerable: true,
        configurable: true
    });
    ProjectUser.prototype.getEmail = function () {
        return this.user ? this.user.email : this.userEmail;
    };
    ProjectUser.prototype.getFirstName = function () {
        return this.user ? this.user.firstName : undefined;
    };
    ProjectUser.prototype.getLastName = function () {
        return this.user ? this.user.lastName : undefined;
    };
    ProjectUser.prototype.getFullName = function () {
        return this.user ? this.user.strFull : this.userEmail;
    };
    ProjectUser.prototype.getPropertyValue = function (property) {
        var value = this.properties[property.uid];
        if (property.fieldDescription && property.fieldDescription.deserializeValue) {
            value = property.fieldDescription.deserializeValue(value, property.field);
        }
        return value;
    };
    ProjectUser.prototype.setPropertyValue = function (property, value) {
        if (property.fieldDescription && property.fieldDescription.serializeValue) {
            value = property.fieldDescription.serializeValue(value, property.field);
        }
        this.properties[property.uid] = value;
    };
    return ProjectUser;
}());
export { ProjectUser };
export var PermissionsGroup;
(function (PermissionsGroup) {
    PermissionsGroup["FullAccess"] = "full_access";
    PermissionsGroup["Editor"] = "editor";
    PermissionsGroup["ReadOnly"] = "read_only";
})(PermissionsGroup || (PermissionsGroup = {}));
var ProjectGroup = /** @class */ (function () {
    function ProjectGroup() {
        this.permissions = [];
        this.permissionsVersion = 1;
        this.properties = {};
        this.protected = false;
        this.projectUsers = [];
        this.params = {};
    }
    ProjectGroup.prototype.deserialize = function (data) {
        this.uid = data['uid'];
        this.name = data['name'];
        this.description = data['description'];
        // this.superGroup = data['super_group'];
        this.properties = data['properties'];
        this.protected = data['protected'];
        if (data['permissions_group']) {
            this.permissionsGroup = data['permissions_group'];
        }
        if (data['project_permissions']) {
            this.permissions = data['project_permissions'].map(function (item) { return new ProjectPermission().deserialize(item); });
        }
        if (data['project_users']) {
            this.projectUsers = data['project_users'].map(function (item) { return new ProjectUser().deserialize(item); });
        }
        if (data['params']) {
            this.params = data['params'];
        }
        if (this.params['permissions_version']) {
            this.permissionsVersion = this.params['permissions_version'];
        }
        return this;
    };
    ProjectGroup.prototype.serialize = function () {
        this.params['permissions_version'] = this.permissionsVersion;
        return {
            uid: this.uid,
            name: this.name,
            // super_group: this.superGroup,
            permissions_group: this.permissionsGroup,
            project_permissions: this.permissions.map(function (item) { return item.serialize(); }),
            properties: this.properties,
            params: this.params
        };
    };
    ProjectGroup.prototype.getPermissions = function (type) {
        return this.permissions.filter(function (item) { return item.permissionType == type; });
    };
    ProjectGroup.prototype.getPagesCount = function () {
        return uniq(this.getPermissions(ProjectPermissionType.Page).map(function (item) { return item.permissionObject; })).length;
    };
    ProjectGroup.prototype.getIcon = function () {
        if (this.permissionsGroup == PermissionsGroup.FullAccess) {
            return 'configure';
        }
        else if (this.permissionsGroup == PermissionsGroup.Editor) {
            return 'pen';
        }
        else if (this.permissionsGroup == PermissionsGroup.ReadOnly) {
            return 'eye';
        }
    };
    ProjectGroup.prototype.getDescription = function () {
        if (this.permissionsGroup == PermissionsGroup.FullAccess) {
            return localize('Can build application and modify data');
        }
        else if (this.permissionsGroup == PermissionsGroup.Editor) {
            return localize('Can modify data in application');
        }
        else if (this.permissionsGroup == PermissionsGroup.ReadOnly) {
            return localize('Can only view data without modification');
        }
    };
    ProjectGroup.prototype.getPropertyValue = function (property) {
        var value = this.properties[property.uid];
        if (property.fieldDescription && property.fieldDescription.deserializeValue) {
            value = property.fieldDescription.deserializeValue(value, property.field);
        }
        return value;
    };
    ProjectGroup.prototype.setPropertyValue = function (property, value) {
        if (property.fieldDescription && property.fieldDescription.serializeValue) {
            value = property.fieldDescription.serializeValue(value, property.field);
        }
        this.properties[property.uid] = value;
    };
    return ProjectGroup;
}());
export { ProjectGroup };
