export function getExtension(path: string) {
  if (!path) {
    return;
  }

  const lastPart = path.split('/').pop();
  const namePart = lastPart.split('?')[0];

  if (!namePart.includes('.')) {
    return;
  }

  return path.split('.').pop();
}

export function getFilenameWithExtension(path: string) {
  if (!path) {
    return;
  }

  const lastPart = path.split('/').pop();
  const namePart = lastPart.split('?')[0];
  return decodeURIComponent(namePart);
}

export function getFilename(path: string): string {
  const fileName = getFilenameWithExtension(path);

  if (!fileName) {
    return;
  }

  return fileName.split('.')[0];
}
