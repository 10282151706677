import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

// TODO: Refactor import
import { TintStyle } from '../../../modules/customize/data/view-settings-action';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertComponent implements OnInit {
  @Input() title: string;
  @Input() icon: string;
  @Input() loader = false;
  @Input() secondary = false;
  @Input() info = false;
  @Input() danger = false;
  @Input() warning = false;
  @Input() success = false;
  @Input() purple = false;
  @Input() color: string;
  @Input() border = false;
  @Input() compact = false;
  @Input() center = false;
  @Input() animateShow = false;

  tintStyles = TintStyle;

  constructor() {}

  ngOnInit() {}
}
