/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../list-components/components/model-card-action/model-card-action.component.ngfactory";
import * as i2 from "../../../list-components/components/model-card-action/model-card-action.component";
import * as i3 from "../../../customize/services/customize/customize.service";
import * as i4 from "../../../action-queries/services/action/action.service";
import * as i5 from "../../../action-queries/services/action-controller/action-controller.service";
import * as i6 from "../../../../common/notifications/services/notification/notification.service";
import * as i7 from "@angular/common";
import * as i8 from "../../../list-components/components/model-card/model-card.component.ngfactory";
import * as i9 from "../../../list-components/components/model-card/model-card.component";
import * as i10 from "../../../views-components/components/custom-element-view/custom-element-view.component.ngfactory";
import * as i11 from "../../../customize/data/view-context";
import * as i12 from "../../../projects/stores/current-environment.store";
import * as i13 from "../../../views-components/components/custom-element-view/custom-element-view.component";
import * as i14 from "../../../customize/data/view-context-element";
import * as i15 from "../../../theme/services/theme/theme.service";
import * as i16 from "@angular/platform-browser";
import * as i17 from "./grid-item.component";
var styles_GridItemComponent = [];
var RenderType_GridItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GridItemComponent, data: {} });
export { RenderType_GridItemComponent as RenderType_GridItemComponent };
function View_GridItemComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-model-card-action", [["class", "grid__item-buttons-item"]], [[2, "element__indicator-wrapper", null], [2, "hidden", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ModelCardActionComponent_0, i1.RenderType_ModelCardActionComponent)), i0.ɵdid(2, 770048, null, 0, i2.ModelCardActionComponent, [i3.CustomizeService, i4.ActionService, i5.ActionControllerService, i6.NotificationService, i0.Injector, i0.ChangeDetectorRef], { model: [0, "model"], action: [1, "action"], context: [2, "context"], contextElement: [3, "contextElement"], accentColor: [4, "accentColor"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.item.model; var currVal_3 = _v.context.$implicit; var currVal_4 = _co.context; var currVal_5 = _co.contextElement; var currVal_6 = _co.accentColor; _ck(_v, 2, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).indicatorWrapper; var currVal_1 = i0.ɵnov(_v, 2).hidden; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_GridItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "grid__item-buttons"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GridItemComponent_3)), i0.ɵdid(2, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings.modelActions; _ck(_v, 2, 0, currVal_0); }, null); }
function View_GridItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "grid__item-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-model-card", [], null, [[null, "modelUpdated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("modelUpdated" === en)) {
        var pd_0 = (_co.modelUpdated.next($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ModelCardComponent_0, i8.RenderType_ModelCardComponent)), i0.ɵdid(2, 245760, null, 0, i9.ModelCardComponent, [i4.ActionService, i0.ChangeDetectorRef], { item: [0, "item"], modelDescription: [1, "modelDescription"], visibleColumns: [2, "visibleColumns"], columns: [3, "columns"], columnActions: [4, "columnActions"], context: [5, "context"], contextElement: [6, "contextElement"] }, { modelUpdated: "modelUpdated" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GridItemComponent_2)), i0.ɵdid(4, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item; var currVal_1 = _co.modelDescription; var currVal_2 = _co.visibleColumns; var currVal_3 = (((_co.settings.dataSource == null) ? null : _co.settings.dataSource.columns) || i0.ɵEMPTY_ARRAY); var currVal_4 = _co.settings.columnActions; var currVal_5 = _co.context; var currVal_6 = _co.contextElement; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.settings.modelActions.length; _ck(_v, 4, 0, currVal_7); }, null); }
function View_GridItemComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "grid__item-view"]], [[4, "height", "px"]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "app-custom-element-view", [], null, null, null, i10.View_CustomElementViewComponent_0, i10.RenderType_CustomElementViewComponent)), i0.ɵprd(131584, null, i11.ViewContext, i11.ViewContext, [i12.CurrentEnvironmentStore]), i0.ɵprd(512, null, i13.parametersToken, i14.ViewContextElement, [i11.ViewContext]), i0.ɵprd(512, null, i13.actionsToken, i14.ViewContextElement, [i11.ViewContext]), i0.ɵprd(512, null, i13.stateToken, i14.ViewContextElement, [i11.ViewContext]), i0.ɵprd(512, null, i13.layerToken, i14.ViewContextElement, [i11.ViewContext]), i0.ɵdid(7, 770048, null, 0, i13.CustomElementViewComponent, [i11.ViewContext, i13.parametersToken, i13.actionsToken, i13.stateToken, i13.layerToken, i5.ActionControllerService, i15.ThemeService, i6.NotificationService, i16.DomSanitizer, i0.Injector, i0.ChangeDetectorRef], { view: [0, "view"], parameters: [1, "parameters"], actions: [2, "actions"], params: [3, "params"], localContext: [4, "localContext"], stateSelectedEnabled: [5, "stateSelectedEnabled"], stateSelected: [6, "stateSelected"] }, null), i0.ɵpid(131072, i7.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.view; var currVal_2 = _co.viewParameters; var currVal_3 = _co.settings.customActions; var currVal_4 = i0.ɵunv(_v, 7, 3, i0.ɵnov(_v, 8).transform(_co.viewParams$)); var currVal_5 = _co.localContext; var currVal_6 = true; var currVal_7 = _co.selected; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.view.frame.height; _ck(_v, 0, 0, currVal_0); }); }
export function View_GridItemComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_GridItemComponent_1)), i0.ɵdid(1, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GridItemComponent_4)), i0.ɵdid(3, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.view; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.view; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_GridItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-grid-item", [], [[2, "grid__item_clickable", null], [2, "grid__item_selected", null], [2, "grid__item", null], [2, "grid__item_snap", null], [2, "grid__item_snap-margin", null], [1, "data-card-width", 0], [4, "min-width", "px"], [4, "max-width", "px"], [4, "min-height", "px"], [4, "max-height", "px"], [4, "padding-left", "px"], [4, "padding-right", "px"], [4, "padding-top", "px"], [4, "padding-bottom", "px"]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_GridItemComponent_0, RenderType_GridItemComponent)), i0.ɵdid(1, 770048, null, 0, i17.GridItemComponent, [i0.ElementRef, i4.ActionService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).clickable; var currVal_1 = i0.ɵnov(_v, 1).selected; var currVal_2 = i0.ɵnov(_v, 1).class; var currVal_3 = i0.ɵnov(_v, 1).snapCls; var currVal_4 = i0.ɵnov(_v, 1).snapMarginCls; var currVal_5 = i0.ɵnov(_v, 1).dataGridCards; var currVal_6 = i0.ɵnov(_v, 1).minWidthPx; var currVal_7 = i0.ɵnov(_v, 1).maxWidthPx; var currVal_8 = i0.ɵnov(_v, 1).minHeightPx; var currVal_9 = i0.ɵnov(_v, 1).maxHeightPx; var currVal_10 = i0.ɵnov(_v, 1).paddingLeftPx; var currVal_11 = i0.ɵnov(_v, 1).paddingRightPx; var currVal_12 = i0.ɵnov(_v, 1).paddingTopPx; var currVal_13 = i0.ɵnov(_v, 1).paddingBottomPx; _ck(_v, 0, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13]); }); }
var GridItemComponentNgFactory = i0.ɵccf("app-grid-item", i17.GridItemComponent, View_GridItemComponent_Host_0, { item: "item", modelDescription: "modelDescription", settings: "settings", visibleColumns: "visibleColumns", rowCards: "rowCards", view: "view", context: "context", contextElement: "contextElement", containerWidth: "containerWidth", accentColor: "accentColor", snap: "snap", snapMargin: "snapMargin", clickable: "clickable", selected: "selected" }, { select: "select", modelUpdated: "modelUpdated" }, []);
export { GridItemComponentNgFactory as GridItemComponentNgFactory };
