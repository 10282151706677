import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of } from 'rxjs';
import { debounceTime, delay, first, map, shareReplay, startWith, switchMap } from 'rxjs/operators';

import { DynamicComponentArguments } from '@common/dynamic-component';
import { NotificationService } from '@common/notifications';
import { PopoverComponent } from '@common/popover';
import { ActionItem } from '@modules/actions';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { AggregateFunc } from '@modules/charts';
import {
  cleanElementName,
  FieldElementItem,
  Margin,
  VALUE_OUTPUT,
  ViewContext,
  ViewContextElement,
  ViewSettingsAction
} from '@modules/customize';
import { BooleanFieldStyle, Option } from '@modules/field-components';
import { FieldsEditConfigurable } from '@modules/field-components';
import {
  BaseField,
  createFormFieldFactory,
  DefaultType,
  FieldType,
  getFieldComponentsByName,
  getFieldDescriptionByType,
  Input as FieldInput,
  InputValueType,
  ValidatorType
} from '@modules/fields';
import { ModelOptionSelectedEvent } from '@modules/filters-components';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore } from '@modules/projects';
import { Singleton } from '@shared';

import { CustomizeBarEditComponent } from '../../data/customize-bar-edit-component';
import { CustomizeBarEditEvent } from '../../data/customize-bar-edit-event';
import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { ElementConfiguration, trackConfigured } from '../../utils/analytics';
import { CustomizeBarColumnEditForm, CustomizeColumnResult } from './customize-bar-column-edit.form';

export interface CustomizeBarColumnEditController<T = any> {
  submit(result: CustomizeColumnResult): Observable<T>;
}

@Component({
  selector: 'app-customize-bar-column-edit',
  templateUrl: './customize-bar-column-edit.component.html',
  providers: [CustomizeBarColumnEditForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarColumnEditComponent implements OnInit, OnDestroy, AfterViewInit, CustomizeBarEditComponent {
  @Input() title: string;
  @Input() backEnabled = true;
  @Input() titleEnabled = true;
  @Input() titleEditable = false;
  @Input() titleAutoUpdate = false;
  @Input() field: BaseField;
  @Input() actions: ViewSettingsAction[];
  @Input() onChangeActions: ActionItem[] = [];
  @Input() element: FieldElementItem;
  @Input() modelDescription: ModelDescription;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() configurable: FieldsEditConfigurable = {};
  @Input() visibleInput: FieldInput;
  @Input() visibleEditable: boolean;
  @Input() disableInput: FieldInput;
  @Input() disableEditable: boolean;
  @Input() tooltip: string;
  @Input() tooltipEditable = false;
  @Input() margin: Margin;
  @Input() marginEditable = false;
  @Input() submitEnabled = false;
  @Input() submitLabel = 'Save';
  @Input() backLabel = 'Back';
  @Input() deleteEnabled = false;
  @Input() actionsLabels: {
    title?: string;
    emptyAction?: string;
    actionLabel?: string;
  };
  @Input() object: string;
  @Input() trackConfigured = false;
  @Input() parentElement: any;
  @Input() parentPopup: any;
  @Input() parentForm: any;
  @Input() firstInit = false;
  @Input() controller: CustomizeBarColumnEditController;
  @Output() event = new EventEmitter<CustomizeBarEditEvent>();

  createField = createFormFieldFactory();
  booleanFieldStyle = BooleanFieldStyle;
  viewParamsComponentData: DynamicComponentArguments;
  dataParamsComponentData: DynamicComponentArguments;
  contextNew = new Singleton(() => new ViewContext(this.currentEnvironmentStore));
  defaultTypes = DefaultType;
  result: CustomizeColumnResult;
  rootComponent = false;
  field$: Observable<BaseField>;
  hasBack = false;
  validatorTypes = ValidatorType;
  configurationStarted = false;
  submitLoading = false;
  lookupPath: string[];
  aggregatePath: string[];
  aggregateFuncLabel: string;
  aggregateColumnOptions: Option[] = [];
  actionsValid$: Observable<boolean>;
  viewSettingsActionClass = ViewSettingsAction;
  itemContextElementPaths = [[VALUE_OUTPUT]];

  constructor(
    private cd: ChangeDetectorRef,
    private customizeBarContext: CustomizeBarContext,
    private notificationService: NotificationService,
    @Optional() private popoverComponent: PopoverComponent,
    public form: CustomizeBarColumnEditForm,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private modelDescriptionStore: ModelDescriptionStore,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnInit(): void {
    this.form.init({
      field: this.field,
      modelDescription: this.modelDescription,
      actions: this.actions,
      onChangeActions: this.onChangeActions,
      configurable: this.configurable,
      visibleInput: this.visibleInput,
      disableInput: this.disableInput,
      title: this.title,
      titleEditable: this.titleEditable,
      titleAutoUpdate: this.titleAutoUpdate,
      titleCleanValue: this.getTitleCleanFn(),
      tooltipEditable: this.tooltipEditable,
      tooltip: this.tooltip,
      margin: this.margin,
      firstInit: this.firstInit
    });

    const resultObs = this.form.valueChanges.pipe(
      debounceTime(200),
      map(() => this.form.submit())
    );

    resultObs.pipe(untilDestroyed(this)).subscribe(result => {
      this.result = result;
      this.emitUpdate();
    });

    this.customizeBarContext.settingsComponents$.pipe(untilDestroyed(this)).subscribe(result => {
      this.rootComponent = result.length == 1;
    });

    this.field$ = resultObs.pipe(
      map(result => result.field),
      startWith(this.form.submit().field),
      shareReplay(1)
    );

    this.customizeBarContext.settingsComponents$.pipe(untilDestroyed(this)).subscribe(components => {
      this.hasBack = components && components.length > 1;
      this.cd.markForCheck();
    });

    if (this.configurable.lookup) {
      this.form
        .getLookupPath$()
        .pipe(untilDestroyed(this))
        .subscribe(value => {
          this.lookupPath = value;
          this.cd.markForCheck();
        });
    }

    if (this.configurable.aggregate) {
      combineLatest(
        this.form.getAggregatePath$(),
        this.form.getAggregateFuncLabel$(),
        this.form.getAggregateColumnOptions$()
      )
        .pipe(untilDestroyed(this))
        .subscribe(result => {
          [this.aggregatePath, this.aggregateFuncLabel, this.aggregateColumnOptions] = result;
          this.cd.markForCheck();
        });
    }

    this.actionsValid$ = this.form.actionsValid$();

    if (this.trackConfigured) {
      resultObs
        .pipe(
          switchMap(result => this.form.isConfigured(result.field)),
          trackConfigured(),
          first(configuration => configuration == ElementConfiguration.Started),
          untilDestroyed(this)
        )
        .subscribe(() => {
          this.configurationStarted = true;
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.StartedConfiguration, {
            ComponentTypeID: this.object
          });
        });
    }

    this.form.controls.value_input.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      if (
        (value['value_type'] == InputValueType.StaticValue && value['static_value']) ||
        (value['value_type'] == InputValueType.Filter && value['filter_field'] && value['filter_lookup']) ||
        (value['value_type'] == InputValueType.Context && value['context_value'])
      ) {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.SetParameter.SuccessfullySetUp, {
          Object: 'field',
          Type: value['value_type']
        });
      }
    });
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.form.controls.field.valueChanges
      .pipe(delay(0), untilDestroyed(this))
      .subscribe(() => this.updateParamsComponentData());

    this.updateParamsComponentData();
  }

  delete() {
    this.event.emit({ type: CustomizeBarEditEventType.Deleted });
    this.close();
  }

  getContextProviders() {
    return [
      {
        provide: ViewContext,
        useFactory: () => {
          if (this.context) {
            return this.context;
          } else {
            return this.contextNew.get();
          }
        },
        deps: []
      }
    ];
  }

  updateParamsComponentData() {
    const item = getFieldDescriptionByType(this.form.value['field']);
    const components = getFieldComponentsByName(item.name);

    if (!components || !components.viewParamsComponent) {
      this.viewParamsComponentData = undefined;
    } else {
      this.viewParamsComponentData = {
        component: components.viewParamsComponent,
        inputs: {
          field: this.field,
          field$: this.field$,
          configurable: this.configurable,
          control: this.form.controls.params,
          element: this.element,
          context: this.context || this.contextNew.get(),
          contextElement: this.contextElement,
          contextElementPath: this.contextElementPath,
          contextElementPaths: this.contextElementPaths,
          analyticsSource: this.object
        },
        providers: this.getContextProviders()
      };
    }

    if (!components || !components.dataParamsComponent) {
      this.dataParamsComponentData = undefined;
    } else {
      this.dataParamsComponentData = {
        component: components.dataParamsComponent,
        inputs: {
          field: this.field,
          field$: this.field$,
          configurable: this.configurable,
          control: this.form.controls.params,
          element: this.element,
          context: this.context || this.contextNew.get(),
          contextElement: this.contextElement,
          contextElementPath: this.contextElementPath,
          contextElementPaths: this.contextElementPaths,
          analyticsSource: this.object
        },
        providers: this.getContextProviders()
      };
    }

    this.cd.detectChanges();
  }

  close() {
    (this.configurationStarted ? this.form.isConfigured(this.result.field) : of(false))
      .pipe(untilDestroyed(this))
      .subscribe(configured => {
        if (configured) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.SuccessfullyConfigured, {
            ComponentTypeID: this.object
          });
        }

        this.customizeBarContext.popSettingsComponent();

        if (this.popoverComponent) {
          this.popoverComponent.close();
        }
      });
  }

  submit() {
    if (this.submitLoading) {
      return;
    }

    this.result = this.form.submit();

    this.submitLoading = true;
    this.cd.markForCheck();

    const controllerResult$ =
      this.controller && this.controller.submit ? this.controller.submit(this.result) : of(undefined);

    controllerResult$.pipe(untilDestroyed(this)).subscribe(
      controllerResult => {
        this.emitUpdate(true, controllerResult);
        this.close();

        this.submitLoading = false;
        this.cd.markForCheck();
      },
      () => {
        this.submitLoading = false;
        this.cd.markForCheck();
      }
    );
  }

  getTitleCleanFn() {
    if (!this.element || !this.context) {
      return;
    }

    return (value: string): string => {
      return cleanElementName(value, this.element, this.context.getElementItems());
    };
  }

  onTitleChanged(title: string) {
    this.form.controls.title.patchValue(title);
    this.result = this.form.submit();
    this.emitUpdate();
  }

  emitUpdate(submit = false, controllerResult?: any) {
    // TODO: Add args interface
    const args = { result: this.result.field, title: this.result.title, submit: submit };
    if (this.configurable.action) {
      args['actions'] = this.result.actions;
    }
    if (this.configurable.onChangeActions) {
      args['on_change_actions'] = this.result.onChangeActions;
    }
    if (this.visibleEditable) {
      args['visible_input'] = this.result.visibleInput;
    }
    if (this.disableEditable) {
      args['disable_input'] = this.result.disableInput;
    }
    if (this.tooltipEditable) {
      args['tooltip'] = this.result.tooltip;
    }
    if (this.marginEditable) {
      args['margin'] = this.result.margin;
    }
    if (controllerResult) {
      args['controller_result'] = controllerResult;
    }
    this.event.emit({
      type: CustomizeBarEditEventType.Updated,
      args: args
    });
  }

  cancel() {
    this.event.emit({ type: CustomizeBarEditEventType.Canceled });
    this.close();
  }

  editForm(event: MouseEvent) {
    this.parentForm.customize({ event: event });
  }

  setLookupPath(e: ModelOptionSelectedEvent) {
    if (!e.field) {
      return;
    }

    const defaultVerboseName = e.path.map(item => item.verboseName).join(' ');
    const path = e.path.map(item => item.name);

    this.form.controls.verbose_name.patchValue(defaultVerboseName);
    this.form.controls.field.patchValue(e.field.field);
    this.form.controls.params.patchValue(e.field.params);
    this.form.controls.lookup_path.patchValue(path);
  }

  setAggregationPath(e: ModelOptionSelectedEvent) {
    if (!e.field) {
      return;
    }

    const defaultVerboseName = `${e.path.map(item => item.verboseName).join(' ')} ${
      e.aggregation ? e.aggregation.func.toLowerCase() : 'Count'
    }`;
    const path = e.path.map(item => item.name);

    this.form.controls.verbose_name.patchValue(defaultVerboseName);
    this.form.controls.field.patchValue(FieldType.Number);
    this.form.controls.aggregate_path.patchValue(path);
    this.form.controls.aggregate_func.patchValue(AggregateFunc.Count);
    this.form.controls.aggregate_column.patchValue(undefined);
  }
}
