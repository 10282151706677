import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { DynamicComponent, DynamicComponentArguments } from '@common/dynamic-component';
import { TypedChanges } from '@shared';

// TODO: Refactor import
import { FieldComponent } from '../../../field-components/components/field/field.component';

import { getFieldComponentsByName } from '../../data/components';
import { FieldLabelButton } from '../../data/field-label-button';
import { getFieldDescriptionByType } from '../../data/fields';
import { FormField } from '../../data/form-field';

@Component({
  selector: 'app-auto-field',
  templateUrl: 'auto-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoFieldComponent implements OnInit, OnDestroy, OnChanges {
  @Input() form: FormGroup;
  @Input() field: FormField;
  @Input() readonly = false;
  @Input() requiredAsterisk = false;
  @Input() value: any;
  @Input() label = true;
  @Input() errors = true;
  @Input() autofocus = false;
  // TODO: Fix types
  @Input() context: any;
  @Input() contextElement: any;
  @Input() truncate = false;
  @Input() compact = false;
  @Input() manualMargin = false;
  @Input() labelButtons: FieldLabelButton[] = [];
  @Input() tooltip: string;
  @Input() accentColor: string;
  @ViewChild(DynamicComponent) public dynamicComponent: DynamicComponent<FieldComponent>;

  constructor(private cd: ChangeDetectorRef) {}

  componentData: DynamicComponentArguments;

  ngOnInit(): void {
    this.initField();

    if (this.form) {
      this.form.statusChanges.pipe(untilDestroyed(this)).subscribe(() => this.cd.detectChanges());
    }
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<AutoFieldComponent>): void {
    if (
      changes.field &&
      (!changes.field.previousValue ||
        !changes.field.currentValue ||
        changes.field.previousValue.field != changes.field.currentValue.field ||
        changes.field.previousValue.label != changes.field.currentValue.label ||
        changes.field.previousValue.required != changes.field.currentValue.required ||
        changes.field.previousValue.placeholder != changes.field.currentValue.placeholder ||
        changes.field.previousValue.resetEnabled != changes.field.currentValue.resetEnabled ||
        !isEqual(changes.field.previousValue.params, changes.field.currentValue.params))
    ) {
      this.initField();
    } else if (changes.value) {
      this.initField();
    } else if (changes.readonly) {
      this.initField();
    } else if (changes.requiredAsterisk) {
      this.initField();
    } else if (changes.form) {
      this.initField();
    } else if (changes.labelButtons) {
      this.initField();
    } else if (changes.tooltip) {
      this.initField();
    } else if (changes.accentColor) {
      this.initField();
    }
  }

  initField() {
    if (!this.field) {
      this.componentData = undefined;
      return;
    }

    const item = getFieldDescriptionByType(this.field.field);
    const components = getFieldComponentsByName(item.name);

    if (!components) {
      this.componentData = undefined;
      return;
    }

    // TODO: readonly (value from form)
    // if (!readOnly && this.context['modelDescription']) {
    //   readOnly = this.(currentProjectStore.instance$ | async)?.hasModelPermission(
    //     this.context['modelDescription'].appLabel,
    //     this.context['modelDescription'].model,
    //     'w'
    //   ) == false;
    // }

    this.componentData = {
      component: components.component,
      inputs: {
        form: this.form,
        field: this.field,
        readonly: this.readonly,
        requiredAsterisk: this.requiredAsterisk,
        value: this.value,
        label: this.label,
        errors: this.errors,
        autofocus: this.autofocus,
        context: this.context,
        contextElement: this.contextElement,
        truncate: this.truncate,
        compact: this.compact,
        manualMargin: this.manualMargin,
        labelButtons: this.labelButtons,
        tooltip: this.tooltip,
        accentColor: this.accentColor
      }
    };
  }

  focus() {
    if (!this.dynamicComponent || !this.dynamicComponent.currentComponent) {
      return;
    }

    this.dynamicComponent.currentComponent.instance.focus();
  }
}
