import { FormControl, FormGroup } from '@angular/forms';
import { of } from 'rxjs';
import { UniqueIdToken } from '@common/unique-id';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { FormField } from '@modules/fields';
import { controlValue, isEmptyArray, isSet } from '@shared';
var FieldComponent = /** @class */ (function () {
    function FieldComponent() {
        this.readonly = false;
        this.requiredAsterisk = false;
        this.label = true;
        this.errors = true;
        this.autofocus = false;
        this.truncate = false;
        this.compact = false;
        this.manualMargin = false;
        this.labelButtons = [];
        this.idToken = new UniqueIdToken();
    }
    Object.defineProperty(FieldComponent.prototype, "control", {
        get: function () {
            return this.form && this.field ? this.form.controls[this.field.name] : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FieldComponent.prototype, "currentValue", {
        get: function () {
            var control = this.control;
            if (control) {
                return control.value;
            }
            else {
                return this.value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FieldComponent.prototype, "currentValue$", {
        get: function () {
            var control = this.control;
            if (control) {
                return controlValue(control);
            }
            else {
                return of(this.value);
            }
        },
        enumerable: true,
        configurable: true
    });
    FieldComponent.prototype.setCurrentValue = function (value) {
        var control = this.control;
        if (!control || control.disabled) {
            return;
        }
        control.patchValue(value);
        control.markAsDirty();
    };
    FieldComponent.prototype.isEmpty = function (value) {
        return !isSet(value) || isEmptyArray(value);
    };
    FieldComponent.prototype.formatEmpty = function (value) {
        if (this.isEmpty(value)) {
            return '---';
        }
        else {
            return value;
        }
    };
    FieldComponent.prototype.resetCurrentValue = function (value) {
        if (value === void 0) { value = ''; }
        this.setCurrentValue(value);
    };
    FieldComponent.prototype.focus = function () { };
    return FieldComponent;
}());
export { FieldComponent };
