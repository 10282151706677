var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import cloneDeep from 'lodash/cloneDeep';
import defaults from 'lodash/defaults';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import isPlainObject from 'lodash/isPlainObject';
import keys from 'lodash/keys';
import toPairs from 'lodash/toPairs';
import values from 'lodash/values';
import { asyncScheduler, concat, of } from 'rxjs';
import { map, throttleTime } from 'rxjs/operators';
import { environment } from '@env/environment';
import { AppError, cacheCall, cleanWrapSpaces, EMPTY, isSet, objectGet } from '@shared';
import { InputValueType } from '../data/input-value-type';
import { mathjs } from './math';
import { HASH, NOW, RANDOM, UUID } from './math-custom-functions';
export function mathJsIsSingleToken(node) {
    if (node && node['isAccessorNode']) {
        return mathJsIsSingleToken(node.object);
    }
    else if (node && node['isSymbolNode']) {
        return true;
    }
    else {
        return false;
    }
}
var FormulaError = /** @class */ (function (_super) {
    __extends(FormulaError, _super);
    function FormulaError(message) {
        var _this = _super.call(this, message) || this;
        _this.name = 'FormulaError';
        Object.setPrototypeOf(_this, FormulaError.prototype);
        return _this;
    }
    return FormulaError;
}(AppError));
export function elementOutputMeta(name) {
    return name + "__jetmeta";
}
export var contextInputValueTypes = [InputValueType.Context, InputValueType.Formula, InputValueType.Js];
export var LOADING_VALUE_CLS = function () { };
LOADING_VALUE_CLS.prototype.toString = function () { return 'LOADING_VALUE'; };
export var LOADING_VALUE = new LOADING_VALUE_CLS();
export var NOT_SET_VALUE_CLS = function () { };
NOT_SET_VALUE_CLS.prototype.toString = function () { return 'NOT_SET_VALUE'; };
export var NOT_SET_VALUE = new NOT_SET_VALUE_CLS();
export function isEmpty(value, emptyValues) {
    if (emptyValues) {
        return emptyValues.some(function (emptyValue) { return isEqual(value, emptyValue); });
    }
    else {
        return !isSet(value);
    }
}
export function addValidChecks(obj, options) {
    if (options === void 0) { options = {}; }
    if (!options.raiseErrors && !options.handleLoading) {
        return obj;
    }
    if (isPlainObject(obj)) {
        var result_1 = {};
        toPairs(obj).forEach(function (_a) {
            var k = _a[0], v = _a[1];
            Object.defineProperty(result_1, k, {
                get: function () {
                    var meta = obj[elementOutputMeta(k)];
                    if (options.raiseErrors && meta && meta.error === true) {
                        throw new AppError('Is not valid');
                    }
                    if (options.handleLoading && meta && meta.loading === true) {
                        throw LOADING_VALUE;
                    }
                    return addValidChecks(v, options);
                },
                enumerable: true
            });
        });
        return result_1;
    }
    else {
        return obj;
    }
}
export function getJetCtx(options) {
    if (options === void 0) { options = {}; }
    var env = options.context ? options.context.currentEnvironmentStore.instance : undefined;
    var page = options.context ? options.context.viewSettings : undefined;
    return {
        env: env,
        page: page
    };
}
export function executeJavaScript(js, options) {
    if (options === void 0) { options = {}; }
    var jetCtx = getJetCtx(options);
    var outputs = options.context ? options.context.outputValues : {};
    try {
        var elementCtx = void 0;
        var elementPath = options.context ? options.context.getElementPath(options.contextElement) : undefined;
        if (elementPath) {
            var obj = objectGet(outputs, elementPath);
            if (obj !== EMPTY) {
                elementCtx = obj;
            }
        }
        var scope = addValidChecks(__assign({}, outputs, elementCtx, options.dynamicFunctionsContext, options.localContext, { jet: jetCtx }));
        var result = void 0;
        try {
            var x = new (Function.bind.apply(Function, [void 0].concat(keys(scope), [js])))();
            result = x.apply(void 0, values(scope));
        }
        catch (e) {
            if (e === LOADING_VALUE) {
                result = LOADING_VALUE;
            }
            else {
                throw e;
            }
        }
        if (options.ignoreEmpty && isEmpty(result, options.emptyValues)) {
            return undefined;
        }
        return result;
    }
    catch (e) {
        if (!environment.production) {
            console.error("Failed executing JS: " + js, e, outputs);
        }
        throw new FormulaError('Failed executing JavaScript');
    }
}
export function applyParamInput(input, options) {
    if (options === void 0) { options = {}; }
    if (!input) {
        return options.defaultValue;
    }
    options = defaults(options, { defaultValue: EMPTY });
    if (input.valueType == InputValueType.StaticValue) {
        if (isSet(input.staticValue)) {
            if (input.array && !isArray(input.staticValue)) {
                return [input.staticValue];
            }
            else {
                return input.staticValue;
            }
        }
        else {
            return;
        }
    }
    else if (input.valueType == InputValueType.EmptyString) {
        return '';
    }
    else if (input.valueType == InputValueType.Null) {
        return null;
    }
    else if (input.valueType == InputValueType.Context) {
        var jetCtx = getJetCtx(options);
        var outputs = options.context ? options.context.outputValues : {};
        var elementCtx = void 0;
        var elementPath = options.context ? options.context.getElementPath(options.contextElement) : undefined;
        if (elementPath) {
            var obj = objectGet(outputs, elementPath);
            if (obj !== EMPTY) {
                elementCtx = obj;
            }
        }
        var ctx = addValidChecks(__assign({}, outputs, elementCtx, options.dynamicFunctionsContext, options.localContext, { jet: jetCtx }), options);
        var result = void 0;
        try {
            result = objectGet(ctx, input.contextValue, EMPTY);
        }
        catch (e) {
            if (e === LOADING_VALUE) {
                result = LOADING_VALUE;
            }
            else {
                throw e;
            }
        }
        if (options.ignoreEmpty && isEmpty(result, options.emptyValues)) {
            return undefined;
        }
        if (result !== EMPTY) {
            return result;
        }
    }
    else if (input.valueType == InputValueType.Formula) {
        if (isSet(input.formulaValue)) {
            var jetCtx = getJetCtx(options);
            var outputs = options.context ? options.context.outputValues : {};
            try {
                var rootNode = mathjs.parse(cleanWrapSpaces(input.formulaValue)).transform(function (node) {
                    if (node['isIndexNode']) {
                        var dimension = node.dimensions[0];
                        if (!node.isObjectProperty() && dimension && typeof dimension.value == 'number') {
                            dimension.value = dimension.value + 1;
                        }
                    }
                    return node;
                });
                var code = rootNode.compile();
                var elementCtx = void 0;
                var elementPath = options.context ? options.context.getElementPath(options.contextElement) : undefined;
                if (elementPath) {
                    var obj = objectGet(outputs, elementPath);
                    if (obj !== EMPTY) {
                        elementCtx = obj;
                    }
                }
                var scope = addValidChecks(__assign({}, outputs, elementCtx, options.dynamicFunctionsContext, options.localContext, { jet: jetCtx }), options);
                var result = void 0;
                try {
                    result = code.evaluate(scope);
                    if (result && result['isResultSet']) {
                        result = result.entries[result.entries.length - 1];
                    }
                }
                catch (e) {
                    if (e === LOADING_VALUE) {
                        result = LOADING_VALUE;
                    }
                    else {
                        throw e;
                    }
                }
                if (options.ignoreEmpty && isEmpty(result, options.emptyValues)) {
                    return undefined;
                }
                return result;
            }
            catch (e) {
                if (!environment.production) {
                    console.error("Failed executing formula: " + input.formulaValue, e, outputs);
                }
                throw new FormulaError('Failed executing formula');
            }
        }
    }
    else if (input.valueType == InputValueType.Js) {
        if (isSet(input.jsValue)) {
            return executeJavaScript(input.jsValue, options);
        }
    }
    return options.defaultValue;
}
export function applyParamInput$(input, options) {
    if (options === void 0) { options = {}; }
    var dynamicFunctionsContext = {
        NOW: cacheCall(NOW),
        RANDOM: cacheCall(RANDOM),
        HASH: cacheCall(HASH),
        UUID: cacheCall(UUID)
    };
    var debounce = options.debounce !== undefined ? options.debounce : 10;
    var obs = options.context && contextInputValueTypes.includes(input.valueType)
        ? concat(of(options.context.outputValues), options.context.outputValues$).pipe(throttleTime(debounce, asyncScheduler, { leading: true, trailing: true }))
        : of({});
    return obs.pipe(map(function () {
        try {
            return applyParamInput(input, __assign({ dynamicFunctionsContext: dynamicFunctionsContext }, options));
        }
        catch (e) {
            return options.defaultValue;
        }
    }));
}
export function applyParamInputs(params, inputs, options) {
    if (!inputs.length) {
        return params;
    }
    var resultParams = cloneDeep(params);
    // if (context && context.model) {
    //   ctx['record'] = context.model.getAttributes();
    // }
    //
    // if (context && context.contextParameters) {
    //   ctx['context'] = context.contextParameterValues;
    // }
    inputs
        .filter(function (item) { return item.valueType != InputValueType.Prompt; })
        .forEach(function (item) {
        var parameter = options.parameters ? options.parameters.find(function (i) { return i.name == item.name; }) : undefined;
        try {
            var result = applyParamInput(item, {
                context: options.context,
                contextElement: options.contextElement,
                localContext: options.localContext,
                dynamicFunctionsContext: options.dynamicFunctionsContext,
                handleLoading: options.handleLoading,
                ignoreEmpty: options.ignoreEmpty,
                emptyValues: options.emptyValues,
                raiseErrors: options.raiseErrors,
                field: parameter
            });
            if (item.required && (result === EMPTY || isEmpty(result, options.emptyValues))) {
                resultParams[item.name] = NOT_SET_VALUE;
            }
            else if (result !== EMPTY) {
                resultParams[item.name] = result;
            }
        }
        catch (e) {
            if (options.raiseErrors) {
                throw e;
            }
        }
    });
    return resultParams;
}
export function applyParamInputs$(params, inputs, options) {
    if (!inputs.length) {
        return of(params);
    }
    var dynamicFunctionsContext = {
        NOW: cacheCall(NOW),
        RANDOM: cacheCall(RANDOM),
        HASH: cacheCall(HASH),
        UUID: cacheCall(UUID)
    };
    var debounce = options.debounce !== undefined ? options.debounce : 10;
    var obs = options.context && inputs.some(function (item) { return contextInputValueTypes.includes(item.valueType); })
        ? concat(of(options.context.outputValues), options.context.outputValues$).pipe(throttleTime(debounce, asyncScheduler, { leading: true, trailing: true }))
        : of({});
    return obs.pipe(map(function () {
        try {
            return applyParamInputs(params, inputs, {
                context: options.context,
                contextElement: options.contextElement,
                dynamicFunctionsContext: dynamicFunctionsContext,
                localContext: options.localContext,
                parameters: options.parameters,
                handleLoading: options.handleLoading,
                ignoreEmpty: options.ignoreEmpty,
                emptyValues: options.emptyValues,
                raiseErrors: options.raiseErrors
            });
        }
        catch (e) {
            if (isSet(options.errorValue)) {
                return options.errorValue;
            }
            throw e;
        }
    }));
}
export function applyBooleanInput(input, context) {
    if (!input || !input.isSet()) {
        return true;
    }
    try {
        var value = applyParamInput(input, { context: context });
        return cleanBooleanValue(value);
    }
    catch (e) {
        return false;
    }
}
export function applyBooleanInput$(input, options) {
    if (options === void 0) { options = {}; }
    if (!input || !input.isSet()) {
        return of(true);
    }
    return applyParamInput$(input, {
        context: options.context,
        contextElement: options.contextElement,
        localContext: options.localContext
    }).pipe(map(function (value) { return cleanBooleanValue(value); }));
}
export var ERROR_VALUE_CLS = function () { };
ERROR_VALUE_CLS.prototype.toString = function () { return 'ERROR_VALUE'; };
export var ERROR_VALUE = new ERROR_VALUE_CLS();
export function getInputsValid$(inputs, options) {
    if (options === void 0) { options = {}; }
    return applyParamInputs$({}, inputs, __assign({}, options, { raiseErrors: true, errorValue: ERROR_VALUE })).pipe(map(function (value) {
        if (value === ERROR_VALUE) {
            return false;
        }
        return true;
    }));
}
export function cleanBooleanValue(value) {
    // if (value === EMPTY || !isSet(value)) {
    if (value === EMPTY) {
        return false;
    }
    if (value === '0' || value === 'false') {
        return false;
    }
    return !!value;
}
