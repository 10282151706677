/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/select/components/select/select.component.ngfactory";
import * as i2 from "../../../../common/select/components/select/select.component";
import * as i3 from "@angular/common";
import * as i4 from "../field-wrapper/field-wrapper.component.ngfactory";
import * as i5 from "../field-wrapper/field-wrapper.component";
import * as i6 from "../../../../shared/pipes/capitalize/capitalize.pipe";
import * as i7 from "../../../../common/localize/pipes/localize/localize.pipe";
import * as i8 from "../../../../common/unique-id/pipes/unique-id/unique-id.pipe";
import * as i9 from "../../../../common/unique-id/services/unique-id/unique-id.service";
import * as i10 from "../../../../shared/pipes/is-color-hex/is-color-hex.pipe";
import * as i11 from "../../../../shared/directives/element/element.directive";
import * as i12 from "../../../../core/services/document/document.service";
import * as i13 from "./select-field.component";
import * as i14 from "../../../model-queries/stores/model-description.store";
var styles_SelectFieldComponent = [];
var RenderType_SelectFieldComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SelectFieldComponent, data: {} });
export { RenderType_SelectFieldComponent as RenderType_SelectFieldComponent };
function View_SelectFieldComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "app-select", [], null, null, null, i1.View_SelectComponent_0, i1.RenderType_SelectComponent)), i0.ɵdid(1, 4964352, [[1, 4]], 0, i2.SelectComponent, [i0.ChangeDetectorRef], { control: [0, "control"], placeholder: [1, "placeholder"], fill: [2, "fill"], small: [3, "small"], segment: [4, "segment"], compareWith: [5, "compareWith"], source: [6, "source"], options: [7, "options"], resetEnabled: [8, "resetEnabled"], searchEnabled: [9, "searchEnabled"], searchDebounce: [10, "searchDebounce"], searchMinimumLength: [11, "searchMinimumLength"], id: [12, "id"], classes: [13, "classes"] }, null), i0.ɵppd(2, 1), i0.ɵppd(3, 1), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵppd(5, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.control; var currVal_1 = (_co.field.placeholder || i0.ɵunv(_v, 1, 1, _ck(_v, 3, 0, i0.ɵnov(_v.parent.parent, 0), i0.ɵunv(_v, 1, 1, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent, 1), "choose"))))); var currVal_2 = _co.selectFill; var currVal_3 = _co.selectSmall; var currVal_4 = _co.selectSegment; var currVal_5 = _co.field.params["valueEquals"]; var currVal_6 = (((_co.source == null) ? null : _co.source.initialized) ? _co.source : undefined); var currVal_7 = i0.ɵunv(_v, 1, 7, i0.ɵnov(_v, 4).transform(_co.options$)); var currVal_8 = (!_co.field["required"] && _co.field["resetEnabled"]); var currVal_9 = true; var currVal_10 = (_co.source ? 200 : 0); var currVal_11 = 1; var currVal_12 = i0.ɵunv(_v, 1, 12, _ck(_v, 5, 0, i0.ɵnov(_v.parent.parent, 2), _co.field.name, _co.idToken)); var currVal_13 = _co.field.params["classes"]; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13]); }, null); }
function View_SelectFieldComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "app-select", [], null, null, null, i1.View_SelectComponent_0, i1.RenderType_SelectComponent)), i0.ɵdid(1, 4964352, [[1, 4]], 0, i2.SelectComponent, [i0.ChangeDetectorRef], { placeholder: [0, "placeholder"], fill: [1, "fill"], small: [2, "small"], segment: [3, "segment"], compareWith: [4, "compareWith"], source: [5, "source"], options: [6, "options"], resetEnabled: [7, "resetEnabled"], searchEnabled: [8, "searchEnabled"], searchDebounce: [9, "searchDebounce"], searchMinimumLength: [10, "searchMinimumLength"], id: [11, "id"], classes: [12, "classes"] }, null), i0.ɵppd(2, 1), i0.ɵppd(3, 1), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵppd(5, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.field.placeholder || i0.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent.parent, 0), i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent, 1), "choose"))))); var currVal_1 = _co.selectFill; var currVal_2 = _co.selectSmall; var currVal_3 = _co.selectSegment; var currVal_4 = _co.field.params["valueEquals"]; var currVal_5 = (((_co.source == null) ? null : _co.source.initialized) ? _co.source : undefined); var currVal_6 = i0.ɵunv(_v, 1, 6, i0.ɵnov(_v, 4).transform(_co.options$)); var currVal_7 = (!_co.field["required"] && _co.field["resetEnabled"]); var currVal_8 = true; var currVal_9 = (_co.source ? 200 : 0); var currVal_10 = 1; var currVal_11 = i0.ɵunv(_v, 1, 11, _ck(_v, 5, 0, i0.ɵnov(_v.parent.parent, 2), _co.field.name, _co.idToken)); var currVal_12 = _co.field.params["classes"]; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12]); }, null); }
function View_SelectFieldComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_SelectFieldComponent_2)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SelectFieldComponent_3)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.form && _co.control); _ck(_v, 1, 0, currVal_0); var currVal_1 = !_v.context.form; _ck(_v, 3, 0, currVal_1); }, null); }
function View_SelectFieldComponent_5(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0), (_l()(), i0.ɵand(0, null, null, 0))], null, null); }
function View_SelectFieldComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "app-field-wrapper", [], null, null, null, i4.View_FieldWrapperComponent_0, i4.RenderType_FieldWrapperComponent)), i0.ɵdid(2, 245760, null, 0, i5.FieldWrapperComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], readonly: [2, "readonly"], requiredAsterisk: [3, "requiredAsterisk"], label: [4, "label"], errors: [5, "errors"], truncate: [6, "truncate"], manualMargin: [7, "manualMargin"], labelButtons: [8, "labelButtons"], tooltip: [9, "tooltip"], idToken: [10, "idToken"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 2, null, View_SelectFieldComponent_5)), i0.ɵdid(4, 540672, null, 0, i3.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(5, { form: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; var currVal_1 = _co.field; var currVal_2 = _co.readonly; var currVal_3 = _co.requiredAsterisk; var currVal_4 = _co.label; var currVal_5 = _co.errors; var currVal_6 = _co.truncate; var currVal_7 = _co.manualMargin; var currVal_8 = _co.labelButtons; var currVal_9 = _co.tooltip; var currVal_10 = _co.idToken; _ck(_v, 2, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10]); var currVal_11 = _ck(_v, 5, 0, _co.form); var currVal_12 = i0.ɵnov(_v.parent, 7); _ck(_v, 4, 0, currVal_11, currVal_12); }, null); }
function View_SelectFieldComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "span", [["class", "status"]], [[4, "color", null], [4, "background-color", null]], null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i3.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵppd(3, 1), i0.ɵpad(4, 2), i0.ɵpad(5, 1), i0.ɵppd(6, 1), i0.ɵppd(7, 1), (_l()(), i0.ɵted(8, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "status"; var currVal_3 = (!i0.ɵunv(_v, 2, 1, _ck(_v, 3, 0, i0.ɵnov(_v.parent.parent, 3), _co.valueOption.color)) ? _ck(_v, 4, 0, (("color_" + _co.valueOption.color) + "_1"), (("background-color_" + _co.valueOption.color) + "_2")) : _ck(_v, 5, 0, "")); _ck(_v, 2, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵunv(_v, 1, 0, _ck(_v, 6, 0, i0.ɵnov(_v.parent.parent, 3), _co.valueOption.color)) ? _co.valueOption.color : null); var currVal_1 = (i0.ɵunv(_v, 1, 1, _ck(_v, 7, 0, i0.ɵnov(_v.parent.parent, 3), _co.valueOption.color)) ? _co.backgroundCustomColor(_co.valueOption.color) : null); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = (_co.valueOption.name || _co.valueOption.value); _ck(_v, 8, 0, currVal_4); }); }
function View_SelectFieldComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formatEmpty(_co.value); _ck(_v, 1, 0, currVal_0); }); }
function View_SelectFieldComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "app-field-wrapper", [], null, null, null, i4.View_FieldWrapperComponent_0, i4.RenderType_FieldWrapperComponent)), i0.ɵdid(1, 245760, null, 0, i5.FieldWrapperComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], readonly: [2, "readonly"], requiredAsterisk: [3, "requiredAsterisk"], label: [4, "label"], errors: [5, "errors"], truncate: [6, "truncate"], manualMargin: [7, "manualMargin"], labelButtons: [8, "labelButtons"], tooltip: [9, "tooltip"], idToken: [10, "idToken"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_SelectFieldComponent_7)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_SelectFieldComponent_8)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; var currVal_1 = _co.field; var currVal_2 = _co.readonly; var currVal_3 = _co.requiredAsterisk; var currVal_4 = _co.label; var currVal_5 = _co.errors; var currVal_6 = _co.truncate; var currVal_7 = _co.manualMargin; var currVal_8 = _co.labelButtons; var currVal_9 = _co.tooltip; var currVal_10 = _co.idToken; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10]); var currVal_11 = _co.valueOption; _ck(_v, 3, 0, currVal_11); var currVal_12 = !_co.valueOption; _ck(_v, 5, 0, currVal_12); }, null); }
export function View_SelectFieldComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i6.CapitalizePipe, []), i0.ɵpid(0, i7.LocalizePipe, []), i0.ɵpid(0, i8.UniqueIdPipe, [i9.UniqueIdService]), i0.ɵpid(0, i10.IsColorHexPipe, []), i0.ɵqud(671088640, 1, { selectComponent: 0 }), (_l()(), i0.ɵeld(5, 0, null, null, 6, "div", [["appElement", ""], ["style", "min-height: 2px;"]], null, [[null, "appElementFirstVisible"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("appElementFirstVisible" === en)) {
        var pd_0 = (_co.onFirstVisible() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(6, 8601600, null, 0, i11.ElementDirective, [i0.ElementRef, i12.DocumentService], { appElementDetectVisible: [0, "appElementDetectVisible"] }, { appElementFirstVisible: "appElementFirstVisible" }), (_l()(), i0.ɵand(0, [["input", 2]], null, 0, null, View_SelectFieldComponent_1)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SelectFieldComponent_4)), i0.ɵdid(9, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SelectFieldComponent_6)), i0.ɵdid(11, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 6, 0, currVal_0); var currVal_1 = !_co.readonly; _ck(_v, 9, 0, currVal_1); var currVal_2 = _co.readonly; _ck(_v, 11, 0, currVal_2); }, null); }
export function View_SelectFieldComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-select-field", [], null, null, null, View_SelectFieldComponent_0, RenderType_SelectFieldComponent)), i0.ɵdid(1, 4964352, null, 0, i13.SelectFieldComponent, [[2, i14.ModelDescriptionStore], i0.Injector, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SelectFieldComponentNgFactory = i0.ɵccf("app-select-field", i13.SelectFieldComponent, View_SelectFieldComponent_Host_0, { form: "form", field: "field", readonly: "readonly", requiredAsterisk: "requiredAsterisk", value: "value", label: "label", errors: "errors", autofocus: "autofocus", context: "context", contextElement: "contextElement", truncate: "truncate", compact: "compact", manualMargin: "manualMargin", labelButtons: "labelButtons", tooltip: "tooltip", accentColor: "accentColor" }, {}, ["*"]);
export { SelectFieldComponentNgFactory as SelectFieldComponentNgFactory };
