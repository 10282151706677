import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild
} from '@angular/core';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  CustomizeService,
  ElementType,
  registerElementComponent,
  ViewContextElement,
  WidgetElementItem
} from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { AutoWidgetComponent } from '@modules/dashboard-components';
import { TypedChanges } from '@shared';

import { CustomPagePopupComponent } from '../custom-page-popup/custom-page-popup.component';

interface ElementState {
  name?: string;
  reloadDataSupported?: boolean;
}

function getElementStateName(state: ElementState): Object {
  return {
    name: state.name
  };
}

function getElementStateActions(state: ElementState): Object {
  return {
    reloadDataSupported: state.reloadDataSupported
  };
}

@Component({
  selector: 'app-auto-widget-element',
  templateUrl: './auto-widget-element.component.html',
  providers: [ViewContextElement],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoWidgetElementComponent extends BaseElementComponent<WidgetElementItem>
  implements OnInit, OnDestroy, OnChanges {
  @Input() element: WidgetElementItem;

  @ViewChild(AutoWidgetComponent) autoWidgetComponent: AutoWidgetComponent;

  state: ElementState = {};
  customizeEnabled$: Observable<boolean>;

  constructor(
    private customizeService: CustomizeService,
    public viewContextElement: ViewContextElement,
    @Optional() private popup: CustomPagePopupComponent,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(item => !!item));

    this.initContext();

    this.elementOnChange(this.element);
    this.trackChanges();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<AutoWidgetElementComponent>): void {
    if (changes.element && !changes.element.firstChange) {
      this.viewContextElement.initInfo({ element: this.element });
    }

    if (changes.element) {
      this.elementOnChange(this.element);
    }
  }

  trackChanges() {
    this.element$
      .pipe(
        map(element => this.getElementState(element)),
        untilDestroyed(this)
      )
      .subscribe(state => {
        this.onStateUpdated(state);
        this.state = state;
      });
  }

  getElementState(element: WidgetElementItem): ElementState {
    return {
      name: element.name,
      reloadDataSupported: element.widget.isReloadDataSupported()
    };
  }

  onStateUpdated(state: ElementState) {
    if (!isEqual(getElementStateName(state), getElementStateName(this.state))) {
      this.updateContextInfo(state);
    }

    if (!isEqual(getElementStateActions(state), getElementStateActions(this.state))) {
      this.initContextActions(state);
    }
  }

  initContext() {
    this.viewContextElement.initElement({
      uniqueName: this.element.uid,
      name: this.element.name,
      icon: 'chart',
      element: this.element,
      popup: this.popup ? this.popup.popup : undefined
    });
  }

  initContextActions(state: ElementState) {
    const actions = state
      ? [
          {
            uniqueName: 'update_data',
            name: 'Update Data',
            icon: 'repeat',
            parameters: [],
            handler: () => this.reloadData()
          }
        ]
      : [];

    this.viewContextElement.setActions(actions);
  }

  updateContextInfo(state: ElementState) {
    this.viewContextElement.initInfo({
      name: state.name
    });
  }

  getData$<T = any>(): Observable<T> {
    if (this.autoWidgetComponent) {
      return this.autoWidgetComponent.getData$<T>();
    }
  }

  reloadData() {
    if (this.autoWidgetComponent) {
      this.autoWidgetComponent.reloadData();
    }
  }
}

registerElementComponent({
  type: ElementType.Widget,
  component: AutoWidgetElementComponent,
  label: 'Component',
  actions: []
});
