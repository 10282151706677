<div appElement [appElementDetectVisible]="true" (appElementFirstVisible)="onFirstVisible()" style="min-height: 2px;">
  <ng-container *ngIf="!readonly">
    <app-field-wrapper
      [form]="form"
      [field]="field"
      [readonly]="readonly"
      [requiredAsterisk]="requiredAsterisk"
      [label]="label"
      [errors]="errors"
      [truncate]="truncate"
      [manualMargin]="manualMargin"
      [labelButtons]="labelButtons"
      [tooltip]="tooltip"
      [idToken]="idToken"
    >
      <app-select
        *ngIf="control"
        [multiple]="true"
        [placeholder]="'choose' | localize | appCapitalize"
        [control]="control"
        [fill]="selectFill"
        [segment]="selectSegment"
        [compareWith]="field.params['valueEquals']"
        [source]="source?.initialized ? source : undefined"
        [options]="staticOptions$ | async"
        [resetEnabled]="!field['required'] && field['resetEnabled']"
        [searchEnabled]="true"
        [searchMinimumLength]="1"
        [searchDebounce]="source ? 200 : 0"
        [id]="field.name | appUniqueId: idToken"
        [classes]="field.params['classes']"
      >
      </app-select>

      <app-select
        *ngIf="!form"
        [multiple]="true"
        [placeholder]="'choose' | localize | appCapitalize"
        [fill]="selectFill"
        [segment]="selectSegment"
        [compareWith]="field.params['valueEquals']"
        [source]="source?.initialized ? source : undefined"
        [options]="selectedOptions"
        [resetEnabled]="!field['required'] && field['resetEnabled']"
        [searchEnabled]="true"
        [searchMinimumLength]="1"
        [searchDebounce]="source ? 200 : 0"
        [id]="field.name | appUniqueId: idToken"
        [classes]="field.params['classes']"
      >
      </app-select>
    </app-field-wrapper>
  </ng-container>

  <app-field-wrapper
    *ngIf="readonly"
    [form]="form"
    [field]="field"
    [readonly]="readonly"
    [requiredAsterisk]="requiredAsterisk"
    [label]="label"
    [errors]="errors"
    [truncate]="truncate"
    [manualMargin]="manualMargin"
    [labelButtons]="labelButtons"
    [tooltip]="tooltip"
    [idToken]="idToken"
  >
    <div *ngIf="!selectedOptionsLoading" class="status-list" #list_element>
      <ng-container *ngFor="let item of selectedOptions; let i = index">
        <span
          *ngIf="clipListItems === undefined || i < clipListItems"
          class="status"
          [ngClass]="['color_' + item.color + '_1', 'background-color_' + item.color + '_2']"
          #list_item_element
        >
          {{ item.name || item.value }}
        </span>
      </ng-container>

      <span *ngIf="clipListItems < selectedOptions.length" class="status">
        +{{ selectedOptions.length - clipListItems }}
      </span>
    </div>
    <div *ngIf="selectedOptionsLoading" class="status-list">
      <span class="status">
        <span [class.loading-animation]="true">
          <span class="stub-text">option</span>
        </span>
      </span>
    </div>
  </app-field-wrapper>
</div>
