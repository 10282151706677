import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { ActionService } from '@modules/action-queries';
import { ActionItem } from '@modules/actions';
import { ViewContext, ViewContextElement, ViewSettingsAction } from '@modules/customize';
import { applyParamInput$ } from '@modules/fields';
import { CurrentEnvironmentStore } from '@modules/projects';
import { isSet } from '@shared';
var ActionsEditItemComponent = /** @class */ (function () {
    function ActionsEditItemComponent(actionService, currentEnvironmentStore, cd) {
        this.actionService = actionService;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.cd = cd;
        this.actionItemClass = ActionItem;
        this.defaultSubtitle = 'Not specified';
        this.disabled = false;
        this.draggable = false;
        this.remove = false;
        this.pin = false;
        this.pinned = false;
        this.execute = false;
        this.open = new EventEmitter();
        this.removeClick = new EventEmitter();
        this.pinClick = new EventEmitter();
        this.executeClick = new EventEmitter();
        this.loading = false;
        this.hasIcon = false;
        this.titles = [];
    }
    ActionsEditItemComponent.prototype.ngOnInit = function () { };
    ActionsEditItemComponent.prototype.ngOnDestroy = function () { };
    ActionsEditItemComponent.prototype.ngOnChanges = function (changes) {
        this.updateAction();
    };
    ActionsEditItemComponent.prototype.updateAction = function () {
        var _this = this;
        this.loading = true;
        this.cd.markForCheck();
        this.actionService
            .getActionDescription(this.action)
            .pipe(untilDestroyed(this))
            .subscribe(function (actionDescription) {
            var resource = actionDescription
                ? _this.currentEnvironmentStore.resources.find(function (i) { return i.uniqueName == actionDescription.resource; })
                : undefined;
            _this.actionDescription = actionDescription;
            _this.actionDescriptionResource = resource;
            _this.hasIcon = _this.actionItemClass == ViewSettingsAction || _this.icon != undefined;
            _this.updateDisplayIcon(actionDescription);
            _this.updateTitles(actionDescription);
            _this.cd.markForCheck();
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    ActionsEditItemComponent.prototype.updateDisplayIcon = function (actionDescription) {
        if (this.icon) {
            this.displayIcon = this.icon;
        }
        else if (this.action instanceof ViewSettingsAction && this.action.icon) {
            this.displayIcon = this.action.icon;
        }
        else if (actionDescription && actionDescription.icon) {
            this.displayIcon = actionDescription.icon;
        }
        else {
            this.displayIcon = 'power';
        }
        this.cd.markForCheck();
    };
    ActionsEditItemComponent.prototype.updateTitles = function (actionDescription) {
        var _this = this;
        if (this.titlesSubscription) {
            this.titlesSubscription.unsubscribe();
            this.titlesSubscription = undefined;
        }
        this.titlesSubscription = combineLatest(this.getDisplayName$(actionDescription), this.actionService.getActionDescriptionLabel(actionDescription, this.action ? this.action.inputs : [], this.context, this.contextElement))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var displayName = _a[0], actionDescriptionLabel = _a[1];
            _this.titles = (isSet(displayName) ? [displayName] : []).concat((isSet(actionDescriptionLabel) ? actionDescriptionLabel : []), [
                _this.defaultSubtitle
            ]);
            _this.loading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    ActionsEditItemComponent.prototype.getDisplayName$ = function (actionDescription) {
        if (this.title) {
            return of(this.title);
        }
        else if (this.action && this.action.verboseNameInput) {
            return applyParamInput$(this.action.verboseNameInput, {
                context: this.context,
                contextElement: this.contextElement,
                defaultValue: ''
            });
        }
        else if (actionDescription && actionDescription.verboseName) {
            return of(actionDescription.verboseName);
        }
        else {
            return of(this.defaultTitle);
        }
    };
    return ActionsEditItemComponent;
}());
export { ActionsEditItemComponent };
