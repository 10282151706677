var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import pickBy from 'lodash/pickBy';
import { Input } from '@modules/fields';
import { AlignHorizontal } from '../../align-horizontal';
import { TintStyle, ViewSettingsAction } from '../../view-settings-action';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
var ActionDropdownElementItem = /** @class */ (function (_super) {
    __extends(ActionDropdownElementItem, _super);
    function ActionDropdownElementItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = ElementType.ActionDropdown;
        _this.alignHorizontalDefault = AlignHorizontal.Left;
        _this.actionItems = [];
        _this.style = TintStyle.Primary;
        return _this;
    }
    ActionDropdownElementItem.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.tooltip = this.params['tooltip'];
        if (this.params['verbose_name_input']) {
            this.verboseNameInput = new Input().deserialize(this.params['verbose_name_input']);
        }
        else if (data['verbose_name']) {
            // Backward compatibility
            this.verboseNameInput = new Input().deserializeFromStatic('value', data['verbose_name']);
        }
        else if (this.params['verbose_name']) {
            // Backward compatibility
            this.verboseNameInput = new Input().deserializeFromStatic('value', this.params['verbose_name']);
        }
        // Backward compatibility
        if (data['icon']) {
            this.icon = data['icon'];
        }
        else {
            this.icon = this.params['icon'];
        }
        if (this.params['action_items']) {
            this.actionItems = this.params['action_items'].map(function (item) { return new ViewSettingsAction().deserialize(item); });
        }
        if (this.params['style'] != undefined) {
            this.style = this.params['style'];
        }
        if (this.style == TintStyle.Default && this.params['color_text']) {
            // Backward compatibility
            this.tint = this.params['color_text'];
        }
        else if (this.style == TintStyle.Primary && this.params['color']) {
            // Backward compatibility
            this.tint = this.params['color'];
        }
        else if (this.style == TintStyle.Transparent && this.params['color_text']) {
            // Backward compatibility
            this.tint = this.params['color_text'];
        }
        else {
            this.tint = this.params['tint'];
        }
        if (this.params['disabled_input']) {
            this.disabledInput = new Input().deserialize(this.params['disabled_input']);
        }
        return this;
    };
    ActionDropdownElementItem.prototype.serialize = function (fields) {
        this.params = {};
        this.params['verbose_name_input'] = this.verboseNameInput ? this.verboseNameInput.serialize() : null;
        this.params['icon'] = this.icon;
        this.params['action_items'] = this.actionItems.map(function (item) { return item.serialize(); });
        this.params['style'] = this.style;
        this.params['tint'] = this.tint;
        this.params['disabled_input'] = this.disabledInput ? this.disabledInput.serialize() : null;
        this.params['tooltip'] = this.tooltip;
        var data = _super.prototype.serialize.call(this);
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Object.defineProperty(ActionDropdownElementItem.prototype, "typeStr", {
        get: function () {
            return 'trigger operations or transitions';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ActionDropdownElementItem.prototype, "analyticsName", {
        get: function () {
            return 'action_dropdown';
        },
        enumerable: true,
        configurable: true
    });
    ActionDropdownElementItem.prototype.defaultName = function () {
        return 'Actions';
    };
    return ActionDropdownElementItem;
}(ElementItem));
export { ActionDropdownElementItem };
registerElementForType(ElementType.ActionDropdown, ActionDropdownElementItem);
