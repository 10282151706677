var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ParameterField } from '@modules/fields';
import { ListLayoutType } from '@modules/layouts';
import { CalendarSettings } from '../calendar-settings';
import { CarouselSettings } from '../carousel-settings';
import { GridSettings } from '../grid-settings';
import { KanbanBoardSettings } from '../kanban-board-settings';
import { MapSettings } from '../map-settings';
import { TableSettings } from '../table-settings';
import { TimelineSettings } from '../timeline-settings';
import { ViewSettingsAction } from '../view-settings-action';
import { ViewSettings, ViewSettingsType } from './base';
export function getListLayoutSettingsClass(type) {
    if (type == ListLayoutType.Table) {
        return TableSettings;
    }
    else if (type == ListLayoutType.Map) {
        return MapSettings;
    }
    else if (type == ListLayoutType.KanbanBoard) {
        return KanbanBoardSettings;
    }
    else if (type == ListLayoutType.Calendar) {
        return CalendarSettings;
    }
    else if (type == ListLayoutType.Grid) {
        return GridSettings;
    }
    else if (type == ListLayoutType.Carousel) {
        return CarouselSettings;
    }
    else if (type == ListLayoutType.Timeline) {
        return TimelineSettings;
    }
}
var ListViewSettings = /** @class */ (function (_super) {
    __extends(ListViewSettings, _super);
    function ListViewSettings() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.view = ViewSettingsType.List;
        _this.layouts = [];
        return _this;
    }
    ListViewSettings.prototype.deserialize = function (data) {
        var _this = this;
        _super.prototype.deserialize.call(this, data);
        if (this.params['parameters']) {
            this.parameters = this.params['parameters'].map(function (item) { return new ParameterField().deserialize(item); });
        }
        if (this.params['layouts'] || this.params['layout']) {
            var layoutsData = this.params['layouts'] || [this.params['layout']];
            this.layouts = layoutsData
                .map(function (item) {
                if (typeof item == 'string') {
                    item = { type: item };
                }
                var cls = getListLayoutSettingsClass(item['type']);
                if (!cls) {
                    return;
                }
                if (item['title'] === undefined && _this.name) {
                    item['title'] = _this.name;
                }
                return new cls().deserialize(item);
            })
                .filter(function (item) { return item != undefined; });
        }
        else {
            this.layouts = [new TableSettings()];
        }
        if (this.params['default_layout']) {
            this.layouts = this.layouts.sort(function (lhs, rhs) {
                var defaultType = _this.params['default_layout'];
                if (lhs.type == defaultType && rhs.type != defaultType) {
                    return -1;
                }
                else if (lhs.type != defaultType && rhs.type == defaultType) {
                    return 1;
                }
                else {
                    return 0;
                }
            });
        }
        if (this.params['table_settings']) {
            var settings = this.layouts.find(function (item) { return item.type == ListLayoutType.Table; });
            if (settings) {
                settings.deserialize(this.params['table_settings']);
            }
        }
        else if (this.params['columns']) {
            var settings = this.layouts.find(function (item) { return item.type == ListLayoutType.Table; });
            if (settings) {
                settings.deserialize({
                    columns: this.params['columns'],
                    date_field: this.params['calendar_date_field'],
                    display1_field: this.params['calendar_display1_field'],
                    display2_field: this.params['calendar_display2_field'],
                    display3_field: this.params['calendar_display3_field'],
                    model_actions: this.params['table_model_actions']
                });
            }
        }
        if (this.params['map_settings']) {
            var settings = this.layouts.find(function (item) { return item.type == ListLayoutType.Map; });
            if (settings) {
                settings.deserialize(this.params['map_settings']);
            }
        }
        else if (this.params['map_columns']) {
            var settings = this.layouts.find(function (item) { return item.type == ListLayoutType.Map; });
            if (settings) {
                settings.deserialize({
                    columns: this.params['map_columns'],
                    location_storage: this.params['map_location_storage'],
                    location_field: this.params['map_location_field'],
                    location_latitude_field: this.params['map_location_latitude_field'],
                    location_longitude_field: this.params['map_location_longitude_field'],
                    model_actions: this.params['map_model_actions']
                });
            }
        }
        if (this.params['kanban_board_settings']) {
            var settings = this.layouts.find(function (item) { return item.type == ListLayoutType.KanbanBoard; });
            if (settings) {
                settings.deserialize(this.params['kanban_board_settings']);
            }
        }
        else if (this.params['kanban_board_columns']) {
            var settings = this.layouts.find(function (item) { return item.type == ListLayoutType.KanbanBoard; });
            if (settings) {
                settings.deserialize({
                    columns: this.params['kanban_board_columns'],
                    stage_field: this.params['kanban_board_stage_field'],
                    stages: this.params['kanban_board_stages']
                });
            }
        }
        if (this.params['calendar_settings']) {
            var settings = this.layouts.find(function (item) { return item.type == ListLayoutType.Calendar; });
            if (settings) {
                settings.deserialize(this.params['calendar_settings']);
            }
        }
        else if (this.params['calendar_date_field']) {
            var settings = this.layouts.find(function (item) { return item.type == ListLayoutType.Calendar; });
            if (settings) {
                settings.deserialize({
                    date_field: this.params['calendar_date_field'],
                    display1_field: this.params['calendar_display1_field'],
                    display2_field: this.params['calendar_display2_field'],
                    display3_field: this.params['calendar_display3_field']
                });
            }
        }
        if (this.params['grid_settings']) {
            var settings = this.layouts.find(function (item) { return item.type == ListLayoutType.Grid; });
            if (settings) {
                settings.deserialize(this.params['grid_settings']);
            }
        }
        else if (this.params['grid_columns']) {
            var settings = this.layouts.find(function (item) { return item.type == ListLayoutType.Grid; });
            if (settings) {
                settings.deserialize({
                    columns: this.params['grid_columns']
                });
            }
        }
        if (this.params['actions']) {
            this.layouts.forEach(function (layout) {
                layout.actions = _this.params['actions'].map(function (item) { return new ViewSettingsAction().deserialize(item); });
            });
        }
        return this;
    };
    ListViewSettings.prototype.serialize = function (fields) {
        this.params = {};
        this.params['parameters'] = this.parameters.map(function (item) { return item.serialize(); });
        this.params['layouts'] = this.layouts.map(function (item) { return item.serialize(); });
        return _super.prototype.serialize.call(this, fields);
    };
    ListViewSettings.prototype.getOrCreateLayout = function (layout) {
        var settings = this.layouts.find(function (item) { return item.type == layout; });
        if (settings) {
            return settings;
        }
        else {
            var cls = getListLayoutSettingsClass(layout);
            var instance = new cls();
            this.layouts = this.layouts.concat([instance]);
            return new cls();
        }
    };
    return ListViewSettings;
}(ViewSettings));
export { ListViewSettings };
