/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./text-editor.component";
var styles_TextEditorComponent = [];
var RenderType_TextEditorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TextEditorComponent, data: {} });
export { RenderType_TextEditorComponent as RenderType_TextEditorComponent };
export function View_TextEditorComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { toolbar: 0 }), i0.ɵqud(402653184, 2, { elEditor: 0 }), (_l()(), i0.ɵeld(2, 0, [[2, 0], ["editorjs", 1]], null, 0, "div", [], [[4, "min-width", "px"], [4, "min-height", "em"]], [[null, "mousedown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.onMouseDown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.empty ? 120 : null); var currVal_1 = (_co.empty ? 1 : null); _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_TextEditorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "app-text-editor", [], null, null, null, View_TextEditorComponent_0, RenderType_TextEditorComponent)), i0.ɵdid(1, 4964352, null, 0, i1.TextEditorComponent, [i0.Injector, i0.ComponentFactoryResolver, i0.ApplicationRef, i0.ViewContainerRef, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TextEditorComponentNgFactory = i0.ɵccf("app-text-editor", i1.TextEditorComponent, View_TextEditorComponent_Host_0, { value: "value", editable: "editable", toolbarElement: "toolbarElement", toolbarAlignEnabled: "toolbarAlignEnabled", toolbarHeadersEnabled: "toolbarHeadersEnabled", context: "context", analyticsSource: "analyticsSource" }, { changed: "changed", escapeClick: "escapeClick" }, []);
export { TextEditorComponentNgFactory as TextEditorComponentNgFactory };
