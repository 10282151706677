<div
  class="list"
  [class.list_scrollable]="scrollable"
  appElement
  [appElementDetectVisible]="{ intervalCheck: 600 }"
  (appElementFirstVisible)="firstVisible$.next(true)"
>
  <div class="list__header" [class.list__header_active]="settings.header">
    <app-list-header
      *ngIf="settings.header"
      [noBottomMargin]="true"
      [params]="params"
      [filtersEnabled]="settings.displayFilters"
      [filters]="listState.filters"
      [filterFields]="settings.filterFields"
      [hideParams]="hideParams"
      [pageSettings]="pageSettings"
      [settings]="settings"
      [dataSource]="listState.dataSource"
      [inputs]="inputs"
      [parameters]="parameters"
      [moreLink]="moreLink"
      [focus]="focus"
      [searchEnabled]="settings.searchEnabled || settings.searchQuery != undefined"
      [search]="listState.search"
      [searchLive]="settings.searchLive"
      [createSegmentAllowed]="createSegmentAllowed"
      [viewId]="viewId"
      [context]="context"
      [contextElement]="contextElement"
      [accentColor]="accentColor"
      (paramsChanged)="paramsChanged.emit($event)"
      (filtersUpdated)="onFiltersUpdated($event)"
      (searchUpdated)="onSearchUpdated($event)"
      (layoutAdd)="layoutAdd.emit($event)"
      (layoutCustomize)="layoutCustomize.emit($event)"
      (segmentCreated)="segmentCreated.emit($event)"
      (updateData)="reloadData()"
      (customizeModelDescription)="customizeModelDescription()"
      (customizeViewSettings)="customizeViewSettings()"
    >
    </app-list-header>
  </div>

  <div class="list__content">
    <div class="list__viewport" #scrollable_element>
      <app-error *ngIf="!configured" [title]="'Not configured' | localize" [fill]="scrollable">
        {{ 'Unfortunately, this component is not configured yet' | localize }}
      </app-error>

      <div *ngIf="configured && error" class="timeline-message">
        <app-error [title]="'Loading failed' | localize">
          {{ error }}
        </app-error>
      </div>

      <app-timeline-stub-content
        *ngIf="configured && !error && items == undefined"
        [animating]="loading"
        [columns]="visibleColumns.length ? visibleColumns.length : scrollable ? 8 : 2"
        [rows]="scrollable ? 8 : loadingItems"
        [scrollable]="scrollable"
        [height]="heightBeforeLoading"
      >
      </app-timeline-stub-content>

      <ng-container *ngIf="configured && !error && items != undefined">
        <div class="timeline" #timeline_element>
          <div class="timeline__line-container">
            <div class="timeline__line"></div>
            <app-timeline-item
              *ngFor="let item of itemsMain; let i = index; let first = first; trackBy: trackByFn"
              [first]="first"
              [item]="item"
              [modelDescription]="listState.modelDescription"
              [settings]="settings"
              [dataSource]="listState.dataSource"
              [visibleColumns]="visibleColumns"
              [context]="context"
              [contextElement]="contextElement"
              [selected]="isItemSelected(item, i)"
              [accentColor]="accentColor"
              (select)="toggleSelectedItem(item, i, true)"
              (modelUpdated)="onModelUpdated($event)"
            >
            </app-timeline-item>
          </div>
          <app-timeline-item
            *ngIf="itemsLast"
            [item]="itemsLast"
            [modelDescription]="listState.modelDescription"
            [settings]="settings"
            [dataSource]="listState.dataSource"
            [visibleColumns]="visibleColumns"
            [context]="context"
            [contextElement]="contextElement"
            [selected]="isItemSelected(itemsLast, itemsMain.length)"
            [accentColor]="accentColor"
            (select)="toggleSelectedItem(itemsLast, itemsMain.length, true)"
            (modelUpdated)="onModelUpdated($event)"
          >
          </app-timeline-item>

          <div *ngIf="loading" class="timeline__loading">
            <app-loader [title]="'Loading'" [fill]="scrollable">
              {{
                'We are fetching {0}, please, wait...'
                  | localize: [title ? (title | appCapitalize) : ('records' | localize)]
              }}
            </app-loader>
          </div>
        </div>

        <div *ngIf="items.length == 0" class="timeline-message">
          <app-error [title]="'Nothing found' | localize" [fill]="scrollable">
            {{
              'Unfortunately, no {0} matching your query found'
                | localize: [title ? (title | appCapitalize) : ('records' | localize)]
            }}
          </app-error>
        </div>
      </ng-container>
    </div>

    <app-list-stub-footer
      *ngIf="configured && !error && items == undefined && settings.displayFooter"
      [count]="true"
      [animating]="loading"
      class="list__footer"
    >
    </app-list-stub-footer>

    <app-list-store-footer
      *ngIf="configured && !error && items != undefined && settings.displayFooter"
      [title]="title"
      [listStore]="listStore"
      [viewId]="viewId"
      (pageSelected)="onPageSelected($event)"
      (reload)="reloadData()"
      class="list__footer"
    >
    </app-list-store-footer>
  </div>
</div>
