<a
  href="javascript:void(0)"
  class="table-action"
  [class.table-action_icon]="!verboseName"
  [class.table-action_disabled]="disabled || submitLoading"
  [appTintColor]="accentColor"
  [appTintColorStyle]="tintStyles.Primary"
  (click)="execute()"
>
  <app-loader-small *ngIf="submitLoading" class="table-action__icon"></app-loader-small>
  <span *ngIf="action.icon && !submitLoading" class="table-action__icon" [ngClass]="'icon-' + action.icon"></span>

  <span *ngIf="verboseName" class="table-action__label">{{ verboseName | appCapitalize }}</span>
</a>

<span
  *ngIf="!isVisible && (customizeService.enabled$ | async)"
  class="element__indicator element__indicator_outside icon-eye"
  [appTip]="'Action is hidden'"
></span>
