var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ActionDescription, ActionType, LinkAction, SegueType } from '@modules/actions';
import { FieldElementItem, FormElementItem, FormSubmitElementItem, generateElementName, SUBMIT_RESULT_OUTPUT, VALUE_OUTPUT, ViewSettingsAction } from '@modules/customize';
import { fieldsEditItemFromParameterField } from '@modules/field-components';
import { Input, InputValueType } from '@modules/fields';
import { isSet } from '@shared';
import { GeneratorUtils } from '../generator-utils/generator-utils.service';
var CreateFormGenerator = /** @class */ (function () {
    function CreateFormGenerator(generatorUtils) {
        this.generatorUtils = generatorUtils;
    }
    CreateFormGenerator.prototype.getCreateParameters = function (modelDescription) {
        return modelDescription.createParametersOrDefaults;
    };
    CreateFormGenerator.prototype.getElement = function (resource, modelDescription, options) {
        var _this = this;
        var fieldElements = this.getCreateParameters(modelDescription).map(function (item) {
            var element = new FieldElementItem();
            element.settings = { field: item.field };
            _this.generatorUtils.applyElementTemplate(element, options.templates);
            if (options.idGenerator) {
                element.uid = options.idGenerator
                    ? options.idGenerator.elementId(options.idUniqueName + "_" + item.name)
                    : undefined;
            }
            else {
                element.generateUid();
            }
            element.settings = __assign({}, fieldsEditItemFromParameterField(item), { verboseName: item.verboseName || item.name, editable: true });
            element.updateFormField();
            element.name = generateElementName(element, {});
            return element;
        });
        if (options.fields) {
            fieldElements = fieldElements
                .filter(function (item) { return options.fields.includes(item.settings.name); })
                .sort(function (lhs, rhs) { return _this.generatorUtils.fieldsSort(options.fields, lhs.settings.name, rhs.settings.name); });
        }
        var formElement = new FormElementItem();
        if (options.idGenerator && isSet(options.idUniqueName)) {
            formElement.uid = options.idGenerator ? options.idGenerator.elementId(options.idUniqueName) : undefined;
        }
        else {
            formElement.generateUid();
        }
        formElement.generated = true;
        formElement.children = fieldElements.slice();
        var autoAction = modelDescription.autoActions().find(function (item) { return item.name == 'create'; });
        if (autoAction) {
            var submitElement = new FormSubmitElementItem();
            if (options.idGenerator) {
                submitElement.uid = options.idGenerator
                    ? options.idGenerator.elementId(options.idUniqueName + "___submit__")
                    : undefined;
            }
            else {
                submitElement.generateUid();
            }
            submitElement.verboseNameInput = new Input().deserializeFromStatic('value', autoAction.label);
            formElement.children.push(submitElement);
            var action = new ViewSettingsAction();
            action.verboseNameInput = new Input().deserializeFromStatic('value', autoAction.label);
            action.sharedActionDescription = [resource.uniqueName, autoAction.uniqueName].join('.');
            action.inputs = modelDescription.createParametersOrDefaults
                .map(function (item) {
                var input = new Input();
                input.name = item.name;
                input.valueType = InputValueType.Context;
                var fieldElement = fieldElements.find(function (element) { return element.settings.name == item.name; });
                if (fieldElement) {
                    input.contextValue = ['elements', fieldElement.uid, VALUE_OUTPUT];
                }
                return input;
            })
                .filter(function (item) { return !options.fields || options.fields.includes(item.name); });
            if (options.detailUniqueName) {
                var successLinkAction = new LinkAction();
                successLinkAction.type = SegueType.Page;
                successLinkAction.page = options.detailUniqueName;
                var successActionDescription = new ActionDescription();
                successActionDescription.type = ActionType.Link;
                successActionDescription.linkAction = successLinkAction;
                var input = new Input();
                input.name = 'ID';
                input.valueType = InputValueType.Context;
                input.contextValue = [SUBMIT_RESULT_OUTPUT, modelDescription.primaryKeyField];
                var successAction = new ViewSettingsAction();
                successAction.verboseNameInput = new Input().deserializeFromStatic('value', 'Open Record');
                successAction.actionDescription = successActionDescription;
                successAction.inputs = [input];
                action.onSuccessActions = [successAction];
            }
            formElement.submitAction = action;
        }
        return formElement;
    };
    return CreateFormGenerator;
}());
export { CreateFormGenerator };
