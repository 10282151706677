import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { ActionControllerService, ActionService, ActionStore } from '@modules/action-queries';
import { ActionDescription } from '@modules/actions';
import {
  ActionElementItem,
  AlignHorizontal,
  CustomizeService,
  ElementType,
  registerElementComponent,
  SUBMIT_RESULT_OUTPUT,
  TintStyle,
  ViewContextElement
} from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { applyBooleanInput$, applyParamInput$, FieldType, getInputsValid$ } from '@modules/fields';
import { TypedChanges } from '@shared';

import { CustomPagePopupComponent } from '../custom-page-popup/custom-page-popup.component';

@Component({
  selector: 'app-action-element',
  templateUrl: './action-element.component.html',
  providers: [ViewContextElement],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionElementComponent extends BaseElementComponent implements OnInit, OnDestroy, OnChanges {
  @Input() element: ActionElementItem;

  action: ActionDescription;
  loading = false;
  submitLoading = false;
  hidden = false;
  verboseName: string;
  verboseNameSubscription: Subscription;
  disabled = false;
  disabledSubscription: Subscription;
  style: TintStyle = TintStyle.Primary;
  tintStyles = TintStyle;
  alignHorizontals = AlignHorizontal;

  subscription: Subscription;
  customizeEnabled$: Observable<boolean>;

  constructor(
    private customizeService: CustomizeService,
    public viewContextElement: ViewContextElement,
    private actionService: ActionService,
    private actionControllerService: ActionControllerService,
    private actionStore: ActionStore,
    private notificationService: NotificationService,
    private injector: Injector,
    private cd: ChangeDetectorRef,
    @Optional() private popup: CustomPagePopupComponent
  ) {
    super();
  }

  ngOnInit() {
    this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(item => !!item));

    this.viewContextElement.initElement({
      uniqueName: this.element.uid,
      name: this.element.name,
      icon: 'cursor',
      element: this.element,
      popup: this.popup ? this.popup.popup : undefined
    });

    if (!this.element.actionItem) {
      return;
    }

    this.initVerboseName();
    this.initStyle();
    this.initDisabled();
    this.updateContextOutputs();

    this.actionService
      .getActionDescription(this.element.actionItem)
      .pipe(untilDestroyed(this))
      .subscribe(action => {
        this.action = action;
        this.cd.markForCheck();

        // if (this.action && this.action.dynamicStatus && this.context.model) {
        //   const context = {};
        //
        //   if (this.context.modelDescription) {
        //     context['model'] = this.context.modelDescription.model;
        //   }
        //
        //   if (this.context.model) {
        //     context['model_primary_key'] = this.context.model.primaryKey;
        //   }
        //
        //   this.loading = true;
        //   this.cd.markForCheck();
        //
        //   this.actionService
        //     .getElementStatus('action_element', this.element.actionItem, context)
        //     .pipe(untilDestroyed(this))
        //     .subscribe(
        //       status => {
        //         this.loading = false;
        //
        //         if (status.hidden) {
        //           this.hidden = true;
        //         }
        //
        //         if (status.disabled) {
        //           this.disabled = true;
        //         }
        //
        //         this.cd.markForCheck();
        //       },
        //       () => {
        //         this.loading = false;
        //         this.hidden = false;
        //         this.disabled = false;
        //         this.cd.markForCheck();
        //       }
        //     );
        // }
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<ActionElementComponent>): void {
    if (changes.element) {
      this.viewContextElement.initInfo({ name: this.element.name, element: this.element });
      this.initVerboseName();
      this.initStyle();
      this.initDisabled();
      this.updateContextOutputs();
    }
  }

  initVerboseName() {
    if (this.verboseNameSubscription) {
      this.verboseNameSubscription.unsubscribe();
      this.verboseNameSubscription = undefined;
    }

    if (!this.element.actionItem || !this.element.actionItem.verboseNameInput) {
      this.verboseName = undefined;
      this.cd.markForCheck();
      return;
    }

    this.verboseNameSubscription = applyParamInput$<string>(this.element.actionItem.verboseNameInput, {
      context: this.context,
      contextElement: this.viewContextElement,
      defaultValue: ''
    })
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.verboseName = value;
        this.cd.markForCheck();
      });
  }

  initStyle() {
    this.style =
      this.element.actionItem && this.element.actionItem.style ? this.element.actionItem.style : TintStyle.Primary;
    this.cd.markForCheck();
  }

  getInputsValid$(): Observable<boolean> {
    return this.actionService.getActionDescription(this.element.actionItem).pipe(
      switchMap(actionDescription => {
        if (!actionDescription) {
          return of(true);
        }

        return getInputsValid$(this.element.actionItem.inputs, {
          context: this.context,
          contextElement: this.viewContextElement,
          parameters: actionDescription.actionParams
        });
      })
    );
  }

  getElementDisabled$() {
    return this.element.actionItem.disabledInput && this.element.actionItem.disabledInput.isSet()
      ? applyBooleanInput$(this.element.actionItem.disabledInput, {
          context: this.context,
          contextElement: this.viewContextElement
        })
      : of(false);
  }

  initDisabled() {
    if (this.disabledSubscription) {
      this.disabledSubscription.unsubscribe();
      this.disabledSubscription = undefined;
    }

    if (!this.element.actionItem) {
      this.disabled = false;
      this.cd.markForCheck();
      return;
    }

    combineLatest(this.getInputsValid$(), this.getElementDisabled$())
      .pipe(untilDestroyed(this))
      .subscribe(([inputsValid, inputDisabled]) => {
        this.disabled = inputDisabled || !inputsValid;
        this.cd.markForCheck();
      });
  }

  updateContextOutputs() {
    this.actionService
      .getActionOutputs(this.element.actionItem)
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result.outputs.length && !result.arrayOutput) {
          this.viewContextElement.setOutputs([
            {
              uniqueName: SUBMIT_RESULT_OUTPUT,
              name: 'Operation Result',
              icon: 'components',
              fieldType: FieldType.JSON,
              external: true,
              children: result.outputs.map(item => {
                return {
                  uniqueName: item.name,
                  name: item.verboseName,
                  icon: item.fieldDescription.icon,
                  fieldType: item.field,
                  fieldParams: item.params
                };
              })
            }
          ]);
        } else {
          this.viewContextElement.setOutputs([
            {
              uniqueName: SUBMIT_RESULT_OUTPUT,
              name: 'Operation Result',
              icon: 'components',
              fieldType: FieldType.JSON,
              external: true
            }
          ]);
        }
      });
  }

  execute() {
    this.submitLoading = true;
    this.cd.markForCheck();

    this.actionControllerService
      .execute(this.element.actionItem, {
        context: this.context,
        contextElement: this.viewContextElement,
        saveResultTo: SUBMIT_RESULT_OUTPUT,
        injector: this.injector,
        analyticsSource: this.element.analyticsGenericName
      })
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.submitLoading = false;
          this.cd.markForCheck();
        },
        () => {
          this.submitLoading = false;
          this.cd.markForCheck();
        }
      );
  }
}

registerElementComponent({
  type: ElementType.Action,
  component: ActionElementComponent,
  label: 'Action',
  actions: []
});
