<a
  href="javascript:void(0)"
  class="button button_tiny"
  [class.button_icon]="true"
  [class.button_disabled]="disabled || submitLoading"
  [appButtonTintColor]="'accentColor' | appThemeColorHexOption | async"
  [appButtonTintColorStyle]="tintStyles.Default"
  tabindex="-1"
  [appTip]="verboseName"
  (click)="execute($event)"
>
  <app-loader-small
    *ngIf="submitLoading"
    class="button__icon"
    [class.button__icon_left]="verboseName | appIsSet"
  ></app-loader-small>
  <span
    *ngIf="!submitLoading"
    class="icon button__icon"
    [ngClass]="action.icon ? 'icon-' + action.icon : 'icon-power'"
  ></span>
</a>

<span
  *ngIf="!isVisible && (customizeService.enabled$ | async)"
  class="element__indicator element__indicator_tiny element__indicator_outside icon-eye"
  [appTip]="'Action is hidden'"
></span>
