import { Injectable } from '@angular/core';
import isArray from 'lodash/isArray';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ActionService } from '@modules/action-queries';
import { ActionItem, ActionType, DownloadActionType, SegueType } from '@modules/actions';
import { CustomViewSource, CustomViewsStore } from '@modules/custom-views';
import {
  ActionDropdownElementItem,
  ActionElementItem,
  ActionGroupElementItem,
  AlertElementItem,
  BackElementItem,
  BarCodeElementItem,
  CalendarSettings,
  CarouselSettings,
  CustomElementItem,
  ElementItem,
  ElementType,
  FieldElementItem,
  FileViewerElementItem,
  FilterElementItem,
  FormElementItem,
  GridSettings,
  IFrameElementItem,
  ImageElementItem,
  KanbanBoardSettings,
  ListElementItem,
  MapSettings,
  ModelElementItem,
  QrCodeElementItem,
  RangeSliderElementItem,
  ScannerElementItem,
  StepsElementItem,
  TableSettings,
  TimelineSettings,
  WidgetElementItem
} from '@modules/customize';
import { ChartWidget, ValueWidget, WidgetType } from '@modules/dashboard';
import { DataSourceType } from '@modules/data-sources';
import {
  BaseField,
  EditableField,
  FieldType,
  Input,
  isRequiredInputsSet,
  OptionsType,
  ParameterField,
  validateValidatorParams,
  ValidatorType
} from '@modules/fields';
import { ListLayoutType } from '@modules/layouts';
import { ModelDescriptionStore } from '@modules/model-queries';
import { CurrentEnvironmentStore } from '@modules/projects';
import { ListModelDescriptionQuery } from '@modules/queries';
import { instanceOf, isSet } from '@shared';

export interface ConfiguredOptions {
  restrictDemo?: boolean;
}

@Injectable()
export class ElementConfigurationService {
  constructor(
    private actionService: ActionService,
    private customViewsStore: CustomViewsStore,
    private modelDescriptionStore: ModelDescriptionStore,
    private currentEnvironmentStore: CurrentEnvironmentStore
  ) {}

  isConfigured(element: ElementItem, options: ConfiguredOptions = {}): Observable<boolean> {
    if (element.type == ElementType.Action) {
      return this.isActionElementConfigured(element as ActionElementItem, options);
    } else if (element.type == ElementType.ActionGroup) {
      return this.isActionGroupConfigured(element as ActionGroupElementItem, options);
    } else if (element.type == ElementType.ActionDropdown) {
      return this.isActionDropdownConfigured(element as ActionDropdownElementItem, options);
    } else if (element.type == ElementType.Back) {
      return this.isBackConfigured(element as BackElementItem, options);
    } else if (element.type == ElementType.Steps) {
      return of(this.isStepsConfigured(element as StepsElementItem, options));
    } else if (element.type == ElementType.Model) {
      return of(this.isModelConfigured(element as ModelElementItem, options));
    } else if (element.type == ElementType.List) {
      return this.isListConfigured(element as ListElementItem, options);
    } else if (element.type == ElementType.Widget) {
      return this.isWidgetConfigured(element as WidgetElementItem, options);
    } else if (element.type == ElementType.Image) {
      return this.isImageConfigured(element as ImageElementItem, options);
    } else if (element.type == ElementType.IFrame) {
      return of(this.isIFrameConfigured(element as IFrameElementItem, options));
    } else if (element.type == ElementType.QrCode) {
      return of(this.isQrCodeConfigured(element as QrCodeElementItem, options));
    } else if (element.type == ElementType.BarCode) {
      return of(this.isBarCodeConfigured(element as BarCodeElementItem, options));
    } else if (element.type == ElementType.Scanner) {
      return of(this.isScannerConfigured(element as ScannerElementItem, options));
    } else if (element.type == ElementType.Alert) {
      return of(this.isAlertConfigured(element as AlertElementItem, options));
    } else if (element.type == ElementType.FileViewer) {
      return of(this.isFileViewerConfigured(element as FileViewerElementItem, options));
    } else if (element.type == ElementType.RangeSlider) {
      return of(this.isRangeConfigured(element as RangeSliderElementItem, options));
    } else if (element.type == ElementType.Field) {
      return this.isFieldConfigured(element as FieldElementItem, options);
    } else if (element.type == ElementType.Custom) {
      return this.isCustomConfigured(element as CustomElementItem, options);
    } else if (element.type == ElementType.Form) {
      return this.isFormConfigured(element as FormElementItem, options);
    } else {
      return of(true);
    }
  }

  isActionElementConfigured(element: ActionElementItem, options: ConfiguredOptions = {}): Observable<boolean> {
    return this.isActionConfigured(element.actionItem, options);
  }

  isActionGroupConfigured(element: ActionGroupElementItem, options: ConfiguredOptions = {}): Observable<boolean> {
    if (!element.actions.length) {
      return of(false);
    }

    return combineLatest(element.actions.map(item => this.isActionConfigured(item, options))).pipe(
      map(result => result.every(item => item))
    );
  }

  isActionDropdownConfigured(element: ActionDropdownElementItem, options: ConfiguredOptions = {}): Observable<boolean> {
    if (!element.actionItems.length) {
      return of(false);
    }

    return combineLatest(element.actionItems.map(item => this.isActionConfigured(item, options))).pipe(
      map(result => result.every(item => item))
    );
  }

  isBackConfigured(element: BackElementItem, options: ConfiguredOptions = {}): Observable<boolean> {
    if (!isSet(element.titleInput) || !element.titleInput.isSet()) {
      return of(false);
    }

    return this.isActionConfigured(element.previousPageAction, options);
  }

  isStepsConfigured(element: StepsElementItem, options: ConfiguredOptions = {}): boolean {
    if (!isSet(element.currentItem) || !element.currentItem.isSet()) {
      return false;
    }

    return element.items.every(item => isSet(item.name));
  }

  isFilterConfigured(element: FilterElementItem, options: ConfiguredOptions = {}): boolean {
    return element.elements.length && element.elements.some(item => isSet(item));
  }

  isModelConfigured(element: ModelElementItem, options: ConfiguredOptions = {}): boolean {
    if (!isSet(element.dataSource)) {
      return false;
    }

    const result = [];

    result.push(isSet(element.dataSource) && element.dataSource.isConfigured());

    if (element.dataSource && element.dataSource.type == DataSourceType.Query && options.restrictDemo) {
      const resource = this.currentEnvironmentStore.resources.find(
        item => item.uniqueName == element.dataSource.queryResource
      );
      result.push(isSet(resource) && !resource.demo);
    }

    return result.every(item => item);
  }

  isListCalendarConfigured(instance: CalendarSettings, options: ConfiguredOptions = {}): Observable<boolean> {
    const obs = [];

    obs.push(of(isSet(instance.dataSource) && instance.dataSource.isConfigured()));

    if (instance.dataSource && instance.dataSource.type == DataSourceType.Query && options.restrictDemo) {
      const resource = this.currentEnvironmentStore.resources.find(
        item => item.uniqueName == instance.dataSource.queryResource
      );
      obs.push(of(isSet(resource) && !resource.demo));
    }

    if (instance.cardClickAction) {
      obs.push(this.isActionConfigured(instance.cardClickAction, options));
    }

    instance.actions.forEach(item => {
      obs.push(this.isActionConfigured(item, options));
    });

    instance.modelActions.forEach(item => {
      obs.push(this.isActionConfigured(item, options));
    });

    if (!obs.length) {
      return of(true);
    }

    return combineLatest(obs).pipe(map(result => result.every(item => item)));
  }

  isListGridConfigured(instance: GridSettings, options: ConfiguredOptions = {}): Observable<boolean> {
    const obs = [];

    obs.push(of(isSet(instance.dataSource) && instance.dataSource.isConfigured()));

    if (instance.dataSource && instance.dataSource.type == DataSourceType.Query && options.restrictDemo) {
      const resource = this.currentEnvironmentStore.resources.find(
        item => item.uniqueName == instance.dataSource.queryResource
      );
      obs.push(of(isSet(resource) && !resource.demo));
    }

    if (instance.cardClickAction) {
      obs.push(this.isActionConfigured(instance.cardClickAction, options));
    }

    instance.actions.forEach(item => {
      obs.push(this.isActionConfigured(item, options));
    });

    instance.modelActions.forEach(item => {
      obs.push(this.isActionConfigured(item, options));
    });

    if (!obs.length) {
      return of(true);
    }

    return combineLatest(obs).pipe(map(result => result.every(item => item)));
  }

  isListCarouselConfigured(instance: CarouselSettings, options: ConfiguredOptions = {}): Observable<boolean> {
    const obs = [];

    obs.push(of(isSet(instance.dataSource) && instance.dataSource.isConfigured()));

    if (instance.dataSource && instance.dataSource.type == DataSourceType.Query && options.restrictDemo) {
      const resource = this.currentEnvironmentStore.resources.find(
        item => item.uniqueName == instance.dataSource.queryResource
      );
      obs.push(of(isSet(resource) && !resource.demo));
    }

    if (instance.cardClickAction) {
      obs.push(this.isActionConfigured(instance.cardClickAction, options));
    }

    instance.actions.forEach(item => {
      obs.push(this.isActionConfigured(item, options));
    });

    instance.modelActions.forEach(item => {
      obs.push(this.isActionConfigured(item, options));
    });

    if (!obs.length) {
      return of(true);
    }

    return combineLatest(obs).pipe(map(result => result.every(item => item)));
  }

  isListKanbanConfigured(instance: KanbanBoardSettings, options: ConfiguredOptions = {}): Observable<boolean> {
    const obs = [];

    obs.push(of(isSet(instance.dataSource) && instance.dataSource.isConfigured()));

    if (instance.dataSource && instance.dataSource.type == DataSourceType.Query && options.restrictDemo) {
      const resource = this.currentEnvironmentStore.resources.find(
        item => item.uniqueName == instance.dataSource.queryResource
      );
      obs.push(of(isSet(resource) && !resource.demo));
    }

    if (instance.cardClickAction) {
      obs.push(this.isActionConfigured(instance.cardClickAction, options));
    }

    if (instance.cardColumnChangeAction) {
      obs.push(this.isActionConfigured(instance.cardColumnChangeAction, options));
    }

    if (instance.cardOrderChangeAction) {
      obs.push(this.isActionConfigured(instance.cardOrderChangeAction, options));
    }

    instance.actions.forEach(item => {
      obs.push(this.isActionConfigured(item, options));
    });

    instance.modelActions.forEach(item => {
      obs.push(this.isActionConfigured(item, options));
    });

    if (!obs.length) {
      return of(true);
    }

    return combineLatest(obs).pipe(map(result => result.every(item => item)));
  }

  isListMapConfigured(instance: MapSettings, options: ConfiguredOptions = {}): Observable<boolean> {
    const obs = [];

    obs.push(of(isSet(instance.dataSource) && instance.dataSource.isConfigured()));

    if (instance.dataSource && instance.dataSource.type == DataSourceType.Query && options.restrictDemo) {
      const resource = this.currentEnvironmentStore.resources.find(
        item => item.uniqueName == instance.dataSource.queryResource
      );
      obs.push(of(isSet(resource) && !resource.demo));
    }

    if (instance.cardClickAction) {
      obs.push(this.isActionConfigured(instance.cardClickAction, options));
    }

    instance.actions.forEach(item => {
      obs.push(this.isActionConfigured(item, options));
    });

    instance.modelActions.forEach(item => {
      obs.push(this.isActionConfigured(item, options));
    });

    if (!obs.length) {
      return of(true);
    }

    return combineLatest(obs).pipe(map(result => result.every(item => item)));
  }

  isListTableConfigured(instance: TableSettings, options: ConfiguredOptions = {}): Observable<boolean> {
    const obs = [];

    obs.push(of(isSet(instance.dataSource) && instance.dataSource.isConfigured()));

    if (instance.dataSource && instance.dataSource.type == DataSourceType.Query && options.restrictDemo) {
      const resource = this.currentEnvironmentStore.resources.find(
        item => item.uniqueName == instance.dataSource.queryResource
      );
      obs.push(of(isSet(resource) && !resource.demo));
    }

    if (instance.rowClickAction) {
      obs.push(this.isActionConfigured(instance.rowClickAction, options));
    }

    instance.actions.forEach(item => {
      obs.push(this.isActionConfigured(item, options));
    });

    instance.rowActions.forEach(item => {
      obs.push(this.isActionConfigured(item, options));
    });

    instance.modelActions.forEach(item => {
      obs.push(this.isActionConfigured(item, options));
    });

    if (!obs.length) {
      return of(true);
    }

    return combineLatest(obs).pipe(map(result => result.every(item => item)));
  }

  isListTimelineConfigured(instance: TimelineSettings, options: ConfiguredOptions = {}): Observable<boolean> {
    const obs = [];

    obs.push(of(isSet(instance.dataSource) && instance.dataSource.isConfigured()));

    if (instance.dataSource && instance.dataSource.type == DataSourceType.Query && options.restrictDemo) {
      const resource = this.currentEnvironmentStore.resources.find(
        item => item.uniqueName == instance.dataSource.queryResource
      );
      obs.push(of(isSet(resource) && !resource.demo));
    }

    if (instance.cardClickAction) {
      obs.push(this.isActionConfigured(instance.cardClickAction, options));
    }

    instance.actions.forEach(item => {
      obs.push(this.isActionConfigured(item, options));
    });

    instance.modelActions.forEach(item => {
      obs.push(this.isActionConfigured(item, options));
    });

    if (!obs.length) {
      return of(true);
    }

    return combineLatest(obs).pipe(map(result => result.every(item => item)));
  }

  isListConfigured(element: ListElementItem, options: ConfiguredOptions = {}): Observable<boolean> {
    const layout = element.layouts[0];

    if (!layout) {
      return of(false);
    }

    if (layout.type == ListLayoutType.Table) {
      return this.isListTableConfigured(layout as TableSettings, options);
    } else if (layout.type == ListLayoutType.Map) {
      return this.isListMapConfigured(layout as MapSettings, options);
    } else if (layout.type == ListLayoutType.KanbanBoard) {
      return this.isListKanbanConfigured(layout as KanbanBoardSettings, options);
    } else if (layout.type == ListLayoutType.Calendar) {
      return this.isListCalendarConfigured(layout as CalendarSettings, options);
    } else if (layout.type == ListLayoutType.Grid) {
      return this.isListGridConfigured(layout as GridSettings, options);
    } else if (layout.type == ListLayoutType.Carousel) {
      return this.isListCarouselConfigured(layout as CarouselSettings, options);
    } else if (layout.type == ListLayoutType.Timeline) {
      return this.isListTimelineConfigured(layout as TimelineSettings, options);
    } else {
      return of(false);
    }
  }

  isWidgetChartConfigured(instance: ChartWidget, options: ConfiguredOptions = {}): Observable<boolean> {
    const obs = [];
    let datasets = instance.datasets;

    if (instance.datasetColumn) {
      datasets = datasets.slice(0, 1);
    }

    datasets.forEach(dataset => {
      obs.push(of(isSet(dataset.dataSource) && dataset.dataSource.isConfigured()));

      if (dataset.dataSource && dataset.dataSource.type == DataSourceType.Query && options.restrictDemo) {
        const resource = this.currentEnvironmentStore.resources.find(
          item => item.uniqueName == dataset.dataSource.queryResource
        );
        obs.push(of(isSet(resource) && !resource.demo));
      }
    });

    if (!obs.length) {
      return of(false);
    }

    return combineLatest(obs).pipe(map(result => result.every(item => item)));
  }

  isWidgetValueConfigured(instance: ValueWidget, options: ConfiguredOptions = {}): Observable<boolean> {
    const obs = [];

    obs.push(of(isSet(instance.dataSource) && instance.dataSource.isConfigured()));

    if (instance.dataSource && instance.dataSource.type == DataSourceType.Query && options.restrictDemo) {
      const resource = this.currentEnvironmentStore.resources.find(
        item => item.uniqueName == instance.dataSource.queryResource
      );
      obs.push(of(isSet(resource) && !resource.demo));
    }

    if (instance.clickAction) {
      obs.push(this.isActionConfigured(instance.clickAction, options));
    }

    return combineLatest(obs).pipe(map(result => result.every(item => item)));

    if (!obs.length) {
      return of(true);
    }

    return combineLatest(obs).pipe(map(result => result.every(item => item)));
  }

  isWidgetConfigured(element: WidgetElementItem, options: ConfiguredOptions = {}): Observable<boolean> {
    if (!element.widget) {
      return of(false);
    }

    if (element.widget.type == WidgetType.Chart) {
      return this.isWidgetChartConfigured(element.widget as ChartWidget, options);
    } else if (element.widget.type == WidgetType.Value) {
      return this.isWidgetValueConfigured(element.widget as ValueWidget, options);
    } else {
      return of(false);
    }
  }

  isImageConfigured(element: ImageElementItem, options: ConfiguredOptions = {}): Observable<boolean> {
    const obs = [
      of(
        isSet(element.storageResource) &&
          isSet(element.storageName) &&
          isSet(element.outputFormat) &&
          isSet(element.valueInput) &&
          element.valueInput.isSet()
      )
    ];

    if (element.clickAction) {
      obs.push(this.isActionConfigured(element.clickAction, options));
    }

    return combineLatest(obs).pipe(map(result => result.every(item => item)));
  }

  isIFrameConfigured(element: IFrameElementItem, options: ConfiguredOptions = {}): boolean {
    return isSet(element.url) && element.url.isSet();
  }

  isQrCodeConfigured(element: QrCodeElementItem, options: ConfiguredOptions = {}): boolean {
    return isSet(element.value) && element.value.isSet();
  }

  isBarCodeConfigured(element: BarCodeElementItem, options: ConfiguredOptions = {}): boolean {
    return isSet(element.value) && element.value.isSet();
  }

  isScannerConfigured(element: ScannerElementItem, options: ConfiguredOptions = {}): boolean {
    return true;
  }

  isAlertConfigured(element: AlertElementItem, options: ConfiguredOptions = {}): boolean {
    return (isSet(element.title) && element.title.isSet()) || (isSet(element.message) && element.message.isSet());
  }

  isFileViewerConfigured(element: FileViewerElementItem, options: ConfiguredOptions = {}): boolean {
    return isSet(element.url) && element.url.isSet();
  }

  isRangeConfigured(element: RangeSliderElementItem, options: ConfiguredOptions = {}): boolean {
    return true;
  }

  isColumnConfigured(
    instance: BaseField,
    options: { editable?: boolean } & ConfiguredOptions = {}
  ): Observable<boolean> {
    const obs = [of(isSet(instance.field))];

    if (options.editable) {
      const editableField = instance as EditableField;
      obs.push(of(validateValidatorParams(editableField.validatorType, editableField.validatorParams)));
    }

    if (instance.field == FieldType.RelatedModel) {
      const modelId =
        instance.params && instance.params['related_model'] ? instance.params['related_model']['model'] : undefined;

      obs.push(
        this.modelDescriptionStore.getDetail(modelId).pipe(
          map(modelDescription => {
            if (!modelDescription) {
              return false;
            }

            const resource = this.currentEnvironmentStore.resources.find(
              item => item.uniqueName == modelDescription.resource
            );

            return (
              isSet(resource) &&
              (!options.restrictDemo || !resource.demo) &&
              (isSet(modelDescription.primaryKeyField) || isSet(instance.params['custom_primary_key'])) &&
              (isSet(modelDescription.displayField) ||
                isSet(instance.params['custom_display_field']) ||
                isSet(instance.params['custom_display_field_input']))
            );
          })
        )
      );
    } else if ([FieldType.Select, FieldType.RadioButton, FieldType.MultipleSelect].includes(instance.field)) {
      if (instance.params && instance.params['options_type'] == OptionsType.Static) {
        obs.push(
          of(
            isSet(instance.params['options']) &&
              instance.params['options'].length > 0 &&
              instance.params['options'].every(item => isSet(item['name']))
          )
        );
      } else if (instance.params && instance.params['options_type'] == OptionsType.Query) {
        const resource = this.currentEnvironmentStore.resources.find(
          item => item.uniqueName == instance.params['resource']
        );
        const query = instance.params['query']
          ? new ListModelDescriptionQuery().deserialize(instance.params['query'])
          : undefined;
        const parameters = instance.params['parameters']
          ? instance.params['parameters'].map(item => new ParameterField().deserialize(item))
          : [];
        const inputs = instance.params['inputs']
          ? instance.params['inputs'].map(item => new Input().deserialize(item))
          : [];

        obs.push(
          of(
            isSet(resource) &&
              (!options.restrictDemo || !resource.demo) &&
              query &&
              query.isConfigured() &&
              isSet(instance.params['value_field']) &&
              (isSet(instance.params['label_field']) || isSet(instance.params['label_field_input'])) &&
              isRequiredInputsSet(parameters, inputs)
          )
        );
      } else {
        obs.push(of(false));
      }
    }

    if (!obs.length) {
      return of(true);
    }

    return combineLatest(obs).pipe(map(result => result.every(item => item)));
  }

  isFieldConfigured(element: FieldElementItem, options: ConfiguredOptions = {}): Observable<boolean> {
    const obs = [];

    obs.push(this.isColumnConfigured(element.settings, { editable: true, ...options }));

    element.onChangeActions.forEach(item => {
      obs.push(this.isActionConfigured(item, options));
    });

    if (!obs.length) {
      return of(true);
    }

    return combineLatest(obs).pipe(map(result => result.every(item => item)));
  }

  isCustomConfigured(element: CustomElementItem, options: ConfiguredOptions = {}): Observable<boolean> {
    return (element.customViewTemporary
      ? of(element.customViewTemporary)
      : this.customViewsStore.getDetailFirst(element.customView)
    ).pipe(
      map(customView => {
        const source = element.source || (customView ? customView.source : undefined) || CustomViewSource.View;
        const isInputsSet = () => isRequiredInputsSet(element.parameters, element.inputs);

        if (source == CustomViewSource.View) {
          return customView && customView.view && isInputsSet();
        } else if (source == CustomViewSource.HTML) {
          return isInputsSet();
        } else if (source == CustomViewSource.CustomElement) {
          return customView && customView.dist && customView.tagName && customView.filesJs.length > 0 && isInputsSet();
        } else {
          return false;
        }
      })
    );
  }

  isFormConfigured(element: FormElementItem, options: ConfiguredOptions = {}): Observable<boolean> {
    const obs = [];

    obs.push(of(element.generated));

    if (element.getDataSource) {
      obs.push(of(element.getDataSource.isConfigured()));
    }

    if (element.getDataSource && element.getDataSource.type == DataSourceType.Query && options.restrictDemo) {
      const resource = this.currentEnvironmentStore.resources.find(
        item => item.uniqueName == element.getDataSource.queryResource
      );
      obs.push(of(isSet(resource) && !resource.demo));
    }

    if (!obs.length) {
      return of(true);
    }

    obs.push(this.isActionConfigured(element.submitAction, options));

    return combineLatest(obs).pipe(map(result => result.every(item => item)));
  }

  isActionConfigured(
    instance: ActionItem,
    options: ConfiguredOptions & { ignoreInputs?: boolean } = {}
  ): Observable<boolean> {
    return this.actionService.getActionDescription(instance).pipe(
      map(actionDescription => {
        if (!actionDescription) {
          return false;
        }

        const isInputsSet = () => {
          return options.ignoreInputs || isRequiredInputsSet(actionDescription.actionParams, instance.inputs);
        };

        if (
          actionDescription.type == ActionType.Query ||
          (actionDescription.type == ActionType.Download &&
            actionDescription.downloadAction &&
            actionDescription.downloadAction.type == DownloadActionType.Query)
        ) {
          const action =
            actionDescription.type == ActionType.Download
              ? actionDescription.downloadAction
              : actionDescription.queryAction;
          const resource = this.currentEnvironmentStore.resources.find(
            item => item.uniqueName == actionDescription.resource
          );

          return (
            isSet(resource) &&
            (!options.restrictDemo || !resource.demo) &&
            action &&
            isSet(action.query) &&
            action.query.isConfigured() &&
            isInputsSet()
          );
        } else if (
          actionDescription.type == ActionType.Download &&
          actionDescription.downloadAction &&
          actionDescription.downloadAction.type == DownloadActionType.Input
        ) {
          return actionDescription.downloadAction.input && actionDescription.downloadAction.input.isSet();
        } else if (actionDescription.type == ActionType.Link) {
          if (!actionDescription.linkAction) {
            return false;
          }

          if (actionDescription.linkAction.type == SegueType.PreviousPage) {
            return true;
          } else if (actionDescription.linkAction.type == SegueType.Page) {
            return isSet(actionDescription.linkAction.page) && isInputsSet();
          } else if (actionDescription.linkAction.type == SegueType.ModelChange) {
            return isSet(actionDescription.linkAction.model) && isInputsSet();
          } else {
            return false;
          }
        } else if (actionDescription.type == ActionType.ExternalLink) {
          return isInputsSet();
        } else if (actionDescription.type == ActionType.ElementAction) {
          return (
            isSet(actionDescription.elementAction) &&
            isArray(actionDescription.elementAction) &&
            !!actionDescription.elementAction.length
          );
        } else if (actionDescription.type == ActionType.ShowNotification) {
          return (
            actionDescription.notificationAction &&
            isSet(actionDescription.notificationAction.title) &&
            actionDescription.notificationAction.title.isSet()
          );
        } else if (actionDescription.type == ActionType.SetProperty) {
          return (
            actionDescription.setPropertyAction &&
            isSet(actionDescription.setPropertyAction.property) &&
            isSet(actionDescription.setPropertyAction.value)
          );
        } else if (actionDescription.type == ActionType.RunJavaScript) {
          return actionDescription.runJavaScriptAction && isSet(actionDescription.runJavaScriptAction.js);
        } else if (actionDescription.type == ActionType.CopyToClipboard) {
          return (
            actionDescription.copyToClipboardAction &&
            isSet(actionDescription.copyToClipboardAction.value) &&
            actionDescription.copyToClipboardAction.value.isSet()
          );
        } else if (actionDescription.type == ActionType.Export) {
          return (
            actionDescription.exportAction &&
            isSet(actionDescription.exportAction.dataSource) &&
            actionDescription.exportAction.dataSource.isConfigured({ columnsOptional: true })
          );
        } else if (actionDescription.type == ActionType.Import) {
          return (
            actionDescription.importAction &&
            isSet(actionDescription.importAction.resource) &&
            isSet(actionDescription.importAction.model)
          );
        } else if (actionDescription.type == ActionType.OpenPopup) {
          return actionDescription.openPopupAction && isSet(actionDescription.openPopupAction.popup);
        } else if (actionDescription.type == ActionType.ClosePopup) {
          return !!actionDescription.closePopupAction;
        } else if (actionDescription.type == ActionType.ScanCode) {
          return true;
        } else if (actionDescription.type == ActionType.Workflow) {
          return (
            !!actionDescription.workflowAction &&
            !!actionDescription.workflowAction.workflow &&
            actionDescription.workflowAction.workflow.steps.length > 0
          );
        } else {
          return false;
        }
      })
    );
  }

  getElementItemsMeta(
    elementItems: ElementItem[]
  ): Observable<{ configured: number; configuredModel: number; configuredAction: number }> {
    const dataClasses = [ListElementItem, ModelElementItem, WidgetElementItem];
    const actionClasses = [ActionElementItem, ActionDropdownElementItem, FormElementItem];

    return (elementItems.length
      ? combineLatest(
          elementItems.map(item =>
            this.isConfigured(item, { restrictDemo: true }).pipe(
              map(configured => {
                return {
                  element: item,
                  configured: configured
                };
              })
            )
          )
        )
      : of([])
    ).pipe(
      map(items => {
        const configured = items.filter(item => item.configured);

        return {
          configured: configured.length,
          configuredModel: configured.filter(item => instanceOf(item.element, dataClasses)).length,
          configuredAction: configured.filter(item => instanceOf(item.element, actionClasses)).length
        };
      })
    );
  }
}
