import { select } from 'd3-selection';
export function getYAxisWidth(yAxis) {
    var width = 0;
    yAxis.selectAll('text').each(function () {
        if (this.getBBox().width > width) {
            width = this.getBBox().width;
        }
    });
    return width;
}
export function fitXAxisLabelWithWidth(axis, domain, maxWidth) {
    axis
        .selectAll('.chart-axis-label')
        .each(function (datum, index, selection) {
        var el = selection[index];
        addTextOverflowEllipsis(select(el), maxWidth, 1, 'chart-axis-label_hidden');
    })
        .append('title')
        .text(function (datum, index) {
        return domain[index];
    });
}
export function fitXAxisLabelWithVisibility(axis, canvas, padding) {
    if (padding === void 0) { padding = 8; }
    var canvasBounds = canvas.getBoundingClientRect();
    var nodes = axis.selectAll('.chart-axis-label').nodes();
    if (!nodes.length) {
        return;
    }
    var nodeFirst = nodes[0];
    var nodeLast = nodes.length > 1 ? nodes[nodes.length - 1] : undefined;
    var rectFirst = nodeFirst.getBoundingClientRect();
    var rectLast = nodeLast ? nodeLast.getBoundingClientRect() : undefined;
    if (rectFirst.left < canvasBounds.left) {
        select(nodeFirst).attr('x', canvasBounds.left - rectFirst.left);
    }
    if (nodeLast && rectLast.right > canvasBounds.right) {
        select(nodeLast).attr('x', 0 - (rectLast.right - canvasBounds.right));
    }
    var labels = nodes.length;
    var _loop_1 = function (skip) {
        var lastEnd = 0;
        var fit = nodes
            .filter(function (el, i) { return i % (skip + 1) == 0; })
            .every(function (el, i) {
            var rect = el.getBoundingClientRect();
            var rectCollapses = rect.left <= lastEnd + padding;
            lastEnd = rect.right;
            return !rectCollapses;
        });
        if (fit) {
            nodes.forEach(function (el, i) {
                if (i % (skip + 1) == 0) {
                    el.classList.remove('chart-axis-label_hidden');
                }
                else {
                    el.classList.add('chart-axis-label_hidden');
                }
            });
            return "break";
        }
    };
    for (var skip = 0; skip < labels; ++skip) {
        var state_1 = _loop_1(skip);
        if (state_1 === "break")
            break;
    }
}
export function addTextOverflowEllipsis(textSel, width, padding, hiddenClass) {
    if (padding === void 0) { padding = 0; }
    if (!textSel.text().length) {
        if (hiddenClass) {
            textSel.node().classList.remove('chart-axis-label_hidden');
        }
        return;
    }
    var initialText = textSel.text();
    var text = initialText;
    while (true) {
        var textLength = textSel.node().getComputedTextLength();
        if (textLength <= width - 2 * padding) {
            if (hiddenClass) {
                textSel.node().classList.remove('chart-axis-label_hidden');
            }
            break;
        }
        text = text.slice(0, -1);
        textSel.text(text + '...');
        if (text.length == 0) {
            if (hiddenClass) {
                textSel.text(initialText);
                textSel.node().classList.add('chart-axis-label_hidden');
            }
            break;
        }
    }
}
