/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./draft-changes-item.component";
var styles_DraftChangesItemComponent = [];
var RenderType_DraftChangesItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DraftChangesItemComponent, data: {} });
export { RenderType_DraftChangesItemComponent as RenderType_DraftChangesItemComponent };
function View_DraftChangesItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" (", ")"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.count; _ck(_v, 1, 0, currVal_0); }); }
function View_DraftChangesItemComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [", "]))], null, null); }
function View_DraftChangesItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DraftChangesItemComponent_3)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_1 = !_v.context.last; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_0); }); }
function View_DraftChangesItemComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "icon-button2 icon-button2_size_s icon-button2_circle"]], [[2, "icon-button2_hover", null], [2, "icon-button2_active", null], [2, "icon-arrow_forward_2", null], [2, "icon-arrow_down_2", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hover; var currVal_1 = _co.expanded; var currVal_2 = !_co.expanded; var currVal_3 = _co.expanded; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_DraftChangesItemComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "status color_white_1 background-color_green_1"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Updated "]))], null, null); }
function View_DraftChangesItemComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "status color_white_1 background-color_red_1"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Deleted "]))], null, null); }
function View_DraftChangesItemComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 17, "div", [["class", "card-table__expand-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "card-table__expand-item-left"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DraftChangesItemComponent_8)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DraftChangesItemComponent_9)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "card-table__expand-item-main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [["class", "card-table__expand-item-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), (_l()(), i0.ɵeld(9, 0, null, null, 8, "div", [["class", "card-table__expand-item-right card-table__expand-item-right_hover"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 3, "button", [["class", "button button_extra-small button_orange-border card-table__expand-item-action"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.publishItem(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 0, "span", [["class", "button__icon button__icon_left icon-cloud_upload"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Publish"])), (_l()(), i0.ɵeld(14, 0, null, null, 3, "button", [["class", "button button_extra-small button_orange-border card-table__expand-item-action"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.discardItem(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 0, "span", [["class", "button__icon button__icon_left icon-bin"]], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 1, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Discard"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.context.$implicit.type == _co.changeTypes.Created) || (_v.context.$implicit.type == _co.changeTypes.Updated)); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_v.context.$implicit.type == _co.changeTypes.Deleted); _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _v.context.$implicit.name; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.disabled; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.disabled; _ck(_v, 14, 0, currVal_4); }); }
function View_DraftChangesItemComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "td", [["colspan", "4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "card-table__expand"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DraftChangesItemComponent_7)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.items; _ck(_v, 4, 0, currVal_0); }, null); }
function View_DraftChangesItemComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_DraftChangesItemComponent_6)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.expanded; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DraftChangesItemComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { template: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 5, "td", [["class", "card-table__column"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "card-table__column-inner card-table__column-inner_truncate"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DraftChangesItemComponent_1)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 3, "td", [["class", "card-table__column"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 2, "div", [["class", "card-table__column-inner card-table__column-inner_truncate"], ["style", "max-width: 340px;"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DraftChangesItemComponent_2)), i0.ɵdid(10, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(11, 0, null, null, 9, "td", [["class", "card-table__column"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 8, "div", [["class", "card-table__column-inner card-table__column-inner_right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 3, "button", [["class", "button button_small button_orange-border"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.publishAll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 0, "span", [["class", "button__icon button__icon_left icon-cloud_upload"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 1, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Publish"])), (_l()(), i0.ɵeld(17, 0, null, null, 3, "button", [["class", "button button_small button_orange-border"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.discardAll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 0, "span", [["class", "button__icon button__icon_left icon-bin"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 1, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Discard"])), (_l()(), i0.ɵeld(21, 0, null, null, 3, "td", [["class", "card-table__column"], ["style", "width: 0;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(22, 0, null, null, 2, "div", [["class", "card-table__column-inner card-table__column-inner_right"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DraftChangesItemComponent_4)), i0.ɵdid(24, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, [[1, 2], ["secondRow", 2]], null, 0, null, View_DraftChangesItemComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.item.singleItem; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.item.items; _ck(_v, 10, 0, currVal_2); var currVal_5 = !_co.item.singleItem; _ck(_v, 24, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.label; _ck(_v, 4, 0, currVal_0); var currVal_3 = _co.disabled; _ck(_v, 13, 0, currVal_3); var currVal_4 = _co.disabled; _ck(_v, 17, 0, currVal_4); }); }
export function View_DraftChangesItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "app-draft-changes-item", [], [[2, "card-table__row_expanded", null]], [[null, "click"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onEnter() !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1).onLeave() !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_DraftChangesItemComponent_0, RenderType_DraftChangesItemComponent)), i0.ɵdid(1, 245760, null, 0, i2.DraftChangesItemComponent, [i0.ViewContainerRef, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).expandedClass; _ck(_v, 0, 0, currVal_0); }); }
var DraftChangesItemComponentNgFactory = i0.ɵccf("app-draft-changes-item, [app-draft-changes-item]", i2.DraftChangesItemComponent, View_DraftChangesItemComponent_Host_0, { item: "item", disabled: "disabled" }, { publish: "publish", discard: "discard" }, []);
export { DraftChangesItemComponentNgFactory as DraftChangesItemComponentNgFactory };
