import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { AfterViewInit, ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, merge, timer } from 'rxjs';
import { debounce, debounceTime, filter } from 'rxjs/operators';
import { MenuBlock, MenuGeneratorService } from '@modules/menu';
import { CurrentProjectStore } from '@modules/projects';
export var dropdownPositionsHorizontal = [
    {
        panelClass: ['overlay_position_right-top'],
        originX: 'end',
        overlayX: 'start',
        originY: 'top',
        overlayY: 'top',
        offsetY: -8
    },
    {
        panelClass: ['overlay_position_right-bottom'],
        originX: 'end',
        overlayX: 'start',
        originY: 'bottom',
        overlayY: 'bottom',
        offsetY: 8
    },
    {
        panelClass: ['overlay_position_right-center'],
        originX: 'end',
        overlayX: 'start',
        originY: 'center',
        overlayY: 'center'
    },
    {
        panelClass: ['overlay_position_left-top'],
        originX: 'start',
        overlayX: 'end',
        originY: 'top',
        overlayY: 'top',
        offsetY: -8
    },
    {
        panelClass: ['overlay_position_left-bottom'],
        originX: 'start',
        overlayX: 'end',
        originY: 'bottom',
        overlayY: 'bottom',
        offsetY: 8
    },
    {
        panelClass: ['overlay_position_left-center'],
        originX: 'start',
        overlayX: 'end',
        originY: 'center',
        overlayY: 'center'
    },
    {
        panelClass: ['overlay_position_bottom-left'],
        originX: 'start',
        overlayX: 'start',
        originY: 'bottom',
        overlayY: 'top',
        offsetX: -8
    },
    {
        panelClass: ['overlay_position_bottom-right'],
        originX: 'end',
        overlayX: 'end',
        originY: 'bottom',
        overlayY: 'top',
        offsetX: 8
    },
    {
        panelClass: ['overlay_position_top-left'],
        originX: 'start',
        overlayX: 'start',
        originY: 'top',
        overlayY: 'bottom',
        offsetX: -8
    },
    {
        panelClass: ['overlay_position_top-right'],
        originX: 'end',
        overlayX: 'end',
        originY: 'top',
        overlayY: 'bottom',
        offsetX: 8
    }
];
export var dropdownPositionsVertical = [
    {
        panelClass: ['overlay_position_bottom-left'],
        originX: 'start',
        overlayX: 'start',
        originY: 'bottom',
        overlayY: 'top',
        offsetX: -8
    },
    {
        panelClass: ['overlay_position_bottom-right'],
        originX: 'end',
        overlayX: 'end',
        originY: 'bottom',
        overlayY: 'top',
        offsetX: 8
    },
    {
        panelClass: ['overlay_position_top-left'],
        originX: 'start',
        overlayX: 'start',
        originY: 'top',
        overlayY: 'bottom',
        offsetX: -8
    },
    {
        panelClass: ['overlay_position_top-right'],
        originX: 'end',
        overlayX: 'end',
        originY: 'top',
        overlayY: 'bottom',
        offsetX: 8
    },
    {
        panelClass: ['overlay_position_left-center'],
        originX: 'start',
        overlayX: 'end',
        originY: 'center',
        overlayY: 'center'
    },
    {
        panelClass: ['overlay_position_right-center'],
        originX: 'end',
        overlayX: 'start',
        originY: 'center',
        overlayY: 'center'
    }
];
var MenuItemComponent = /** @class */ (function () {
    function MenuItemComponent(currentProjectStore, menuGeneratorService, router, cd) {
        this.currentProjectStore = currentProjectStore;
        this.menuGeneratorService = menuGeneratorService;
        this.router = router;
        this.cd = cd;
        this.exactActive = false;
        this.children = [];
        this.childrenVertical = false;
        this.primary = false;
        this.dropdown = false;
        this.forceActive = false;
        this.forceOpened = true;
        this.dropdownOpen = new EventEmitter();
        this.dropdownClose = new EventEmitter();
        this.displayChildren = [];
        this.menuItemHovered$ = new BehaviorSubject(undefined);
        this.menuDropdownHovered$ = new BehaviorSubject(false);
        this.menuChildDropdownOpened$ = new BehaviorSubject(false);
        this.dropdownOpened = false;
        this.popoverPositions = [];
        this.menuDropdownSubscription = [];
    }
    MenuItemComponent.prototype.trackMenuItemFn = function (i, item) {
        return item.id;
    };
    MenuItemComponent.prototype.ngOnInit = function () {
        this.popoverPositions = this.childrenVertical ? dropdownPositionsVertical : dropdownPositionsHorizontal;
        this.cd.markForCheck();
        if (this.forceOpened) {
            this.opened = true;
            this.cd.markForCheck();
        }
    };
    MenuItemComponent.prototype.ngOnDestroy = function () { };
    MenuItemComponent.prototype.ngOnChanges = function (changes) {
        if (changes.children) {
            this.updateChildren();
            this.initMenuDropdown();
        }
        if (this.opened == undefined) {
            this.opened = this.alwaysOpened = localStorage[this.alwaysOpenedUniqueKey] == '1';
        }
    };
    MenuItemComponent.prototype.ngAfterViewInit = function () {
        this.setPositionObserver();
    };
    MenuItemComponent.prototype.updateChildren = function () {
        this.displayChildren = this.menuGeneratorService.cleanMenuItemsAppMode(this.children);
        this.cd.markForCheck();
    };
    MenuItemComponent.prototype.toggleOpened = function () {
        this.opened = this.alwaysOpened = !this.opened;
        this.cd.markForCheck();
        localStorage[this.alwaysOpenedUniqueKey] = this.alwaysOpened ? '1' : '0';
    };
    Object.defineProperty(MenuItemComponent.prototype, "alwaysOpenedUniqueKey", {
        get: function () {
            return JSON.stringify({
                project: this.currentProjectStore.instance.uniqueName,
                link: this.link,
                externalLink: this.href
            });
        },
        enumerable: true,
        configurable: true
    });
    MenuItemComponent.prototype.onActivate = function () {
        this.opened = true;
        this.cd.markForCheck();
    };
    MenuItemComponent.prototype.onDeactivate = function () {
        this.opened = this.alwaysOpened;
        this.cd.markForCheck();
    };
    MenuItemComponent.prototype.initMenuDropdown = function () {
        var _this = this;
        this.menuDropdownSubscription.forEach(function (item) { return item.unsubscribe(); });
        this.menuDropdownSubscription = [];
        if (!this.children.length) {
            return;
        }
        this.menuDropdownSubscription.push(combineLatest(this.menuItemHovered$.pipe(debounce(function (origin) { return timer(origin ? 0 : 100); })), this.menuDropdownHovered$, this.menuChildDropdownOpened$)
            .pipe(debounceTime(10), untilDestroyed(this))
            .subscribe(function (_a) {
            var itemOrigin = _a[0], dropdownHovered = _a[1], childDropdownOpened = _a[2];
            if (itemOrigin && !_this.dropdownOpened) {
                _this.openDropdown(itemOrigin);
            }
            else if (!itemOrigin && !dropdownHovered && !childDropdownOpened && _this.dropdownOpened) {
                _this.closeDropdown();
            }
        }));
        this.menuDropdownSubscription.push(merge(this.router.events.pipe(filter(function (item) { return item instanceof ActivationEnd; })))
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.closeDropdown(); }));
    };
    MenuItemComponent.prototype.openDropdown = function (origin) {
        this.origin = origin;
        this.dropdownOpened = true;
        this.cd.markForCheck();
        this.dropdownOpen.emit();
    };
    MenuItemComponent.prototype.closeDropdown = function () {
        this.dropdownOpened = false;
        this.cd.markForCheck();
        this.dropdownClose.emit();
    };
    MenuItemComponent.prototype.setPositionObserver = function () {
        var _this = this;
        if (this.popoverPositionsSubscription) {
            this.popoverPositionsSubscription.unsubscribe();
        }
        if (!this.cdkConnectedOverlay) {
            return;
        }
        this.popoverPositionsSubscription = this.cdkConnectedOverlay.positionChange
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            var propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
            var position = _this.popoverPositions.find(function (item) {
                return propsEqual.every(function (prop) { return (item[prop] || undefined) == e.connectionPair[prop]; });
            });
            var otherPosition = _this.popoverPositions.filter(function (item) { return item !== position; });
            if (position) {
                _this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
            }
            otherPosition.forEach(function (item) { return _this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass); });
        });
    };
    MenuItemComponent.prototype.onClick = function () {
        if (this.handler) {
            this.handler();
        }
    };
    return MenuItemComponent;
}());
export { MenuItemComponent };
