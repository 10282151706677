var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, ElementRef, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { untilDestroyed } from 'ngx-take-until-destroy';
// import Editor from '@toast-ui/editor';
import { EmbedScripts, ScriptsService } from '@core';
import { ApiService } from '@modules/api';
import { FieldType, registerFieldComponent, RichTextOutput } from '@modules/fields';
import { ProjectApiService } from '@modules/project-api';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ProjectStorageService } from '@modules/resources';
import { controlValue, isSet } from '@shared';
import { FieldComponent } from '../field/field.component';
var RichTextFieldComponent = /** @class */ (function (_super) {
    __extends(RichTextFieldComponent, _super);
    function RichTextFieldComponent(domSanitizer, apiService, projectApiService, currentProjectStore, currentEnvironmentStore, projectStorageService, scriptsService) {
        var _this = _super.call(this) || this;
        _this.domSanitizer = domSanitizer;
        _this.apiService = apiService;
        _this.projectApiService = projectApiService;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.projectStorageService = projectStorageService;
        _this.scriptsService = scriptsService;
        return _this;
    }
    RichTextFieldComponent.prototype.ngOnInit = function () { };
    RichTextFieldComponent.prototype.ngOnDestroy = function () {
        if (this.editor) {
            this.editor.destroy();
            this.editor = undefined;
        }
    };
    RichTextFieldComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (!this.readonly) {
            setTimeout(function () { return _this.initWysiwyg(); });
        }
    };
    RichTextFieldComponent.prototype.ngOnChanges = function (changes) {
        if (changes['field'] && !changes['field'].firstChange) {
            this.initWysiwyg();
        }
    };
    Object.defineProperty(RichTextFieldComponent.prototype, "sanitizeValue", {
        get: function () {
            if (!this.currentValue || typeof this.currentValue !== 'string') {
                return this.currentValue;
            }
            if (this.field.params['format_readonly']) {
                return this.domSanitizer.bypassSecurityTrustHtml(this.currentValue);
            }
            else {
                return this.currentValue.replace(/<\/?[^>]+(>|$)/g, '');
            }
        },
        enumerable: true,
        configurable: true
    });
    RichTextFieldComponent.prototype.getPath = function () {
        if (this.context && this.context.modelDescription) {
            return [this.context.modelDescription.model, this.field.name].join('/');
            // } else if (this.context['action']) {
            //   return ['actions', this.context['action'], this.field.name].join('/');
        }
    };
    RichTextFieldComponent.prototype.isMarkdown = function () {
        return this.field.params && this.field.params['output'] == RichTextOutput.Markdown;
    };
    RichTextFieldComponent.prototype.initWysiwyg = function () {
        if (this.actualValueSubscription) {
            this.actualValueSubscription.unsubscribe();
            this.actualValueSubscription = undefined;
        }
        if (this.isMarkdown()) {
            // if (this.markdownEditor) {
            //   return;
            // }
            if (this.editor) {
                this.editor.destroy();
                this.editor = undefined;
            }
            this.initMarkdown();
        }
        else {
            if (this.editor) {
                return;
            }
            // if (this.markdownEditor) {
            //   this.markdownEditor.remove();
            //   this.markdownEditor = undefined;
            // }
            this.initHTMLWysiwyg();
        }
    };
    RichTextFieldComponent.prototype.initMarkdown = function () {
        // if (!this.elEditor) {
        //   return;
        // }
        //
        // const editor = new Editor({
        //   el: this.elEditor.nativeElement,
        //   height: '500px',
        //   initialEditType: 'wysiwyg',
        //   previewStyle: 'tab',
        //   initialValue: this.currentValue,
        //   usageStatistics: false,
        //   events: {
        //     change: () => {
        //       this.setCurrentValue(editor.getMarkdown());
        //     }
        //   }
        // });
        //
        // this.markdownEditor = editor;
    };
    RichTextFieldComponent.prototype.initHTMLWysiwyg = function () {
        var _this = this;
        if (!this.input) {
            return;
        }
        this.scriptsService
            .loadSingleton(EmbedScripts.CKEditor)
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.initCKEditor(); });
    };
    RichTextFieldComponent.prototype.initCKEditor = function () {
        var _this = this;
        var uploadUrl = this.projectStorageService.getUploadUrl(this.currentProjectStore.uniqueName);
        var CKEDITOR = window['CKEDITOR'];
        var editor = CKEDITOR.replace(this.input.nativeElement, {
            toolbar: [
                {
                    name: 'clipboard',
                    items: [
                        'Format',
                        'Indent',
                        'Outdent',
                        '-',
                        'Cut',
                        'Copy',
                        'Paste',
                        'PasteText',
                        'PasteFromWord',
                        '-',
                        'Undo',
                        'Redo'
                    ]
                },
                {
                    name: 'document',
                    items: ['Source', 'Preview']
                },
                '/',
                {
                    name: 'basicstyles',
                    items: [
                        'Bold',
                        'Italic',
                        'Underline',
                        'Strike',
                        'Subscript',
                        'Superscript',
                        '-',
                        'TextColor',
                        'BGColor',
                        '-',
                        'RemoveFormat'
                    ]
                },
                {
                    name: 'align',
                    items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']
                },
                {
                    name: 'specials',
                    items: ['NumberedList', 'BulletedList', '-', 'Link', 'Unlink', 'Anchor', 'Image', 'Table', 'SpecialChar']
                }
            ],
            height: isSet(this.field.params['rows']) ? this.field.params['rows'] + "em" : 'auto',
            removePlugins: 'elementspath,resize',
            // fullPage: true,
            allowedContent: true,
            filebrowserUploadUrl: uploadUrl
        });
        this.actualValueSubscription = controlValue(this.control)
            .pipe(untilDestroyed(this))
            .subscribe(function (actualValue) {
            var editorValue = editor.getData();
            if (editorValue != actualValue) {
                editor.setData(actualValue);
            }
        });
        editor.on('change', function (e) {
            _this.input.nativeElement.value = editor.getData();
            var event = document.createEvent('Event');
            event.initEvent('input', true, true);
            _this.input.nativeElement.dispatchEvent(event);
        });
        editor.on('fileUploadRequest', function (evt) {
            var xhr = evt.data.fileLoader.xhr;
            var file = evt.data.fileLoader.file;
            var fileName = evt.data.fileLoader.fileName;
            var path = _this.getPath();
            var filePath = ['rich_text'].concat((isSet(path) ? [path] : [])).join('/');
            var form = _this.projectStorageService.getUploadData(file, filePath, fileName);
            _this.projectApiService
                .refreshToken()
                .pipe(untilDestroyed(_this))
                .subscribe(function () {
                var headers = _this.projectStorageService.getUploadHeaders();
                headers.keys().forEach(function (key) {
                    xhr.setRequestHeader(key, headers.get(key));
                });
                xhr.send(form);
            });
            // Prevented the default behavior.
            evt.stop();
        });
        editor.on('fileUploadResponse', function (evt) {
            // Prevent the default response handler.
            evt.stop();
            // Get XHR and response.
            var data = evt.data, xhr = data.fileLoader.xhr, response = xhr.responseText;
            var result = JSON.parse(response);
            data.url = result['url'];
            // if (response[1]) {
            //   // An error occurred during upload.
            //   data.message = response[1];
            //   evt.cancel();
            // } else {
            //   data.url = response[0];
            // }
        });
        this.editor = editor;
    };
    return RichTextFieldComponent;
}(FieldComponent));
export { RichTextFieldComponent };
registerFieldComponent(FieldType.RichText, RichTextFieldComponent);
