import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { Observable, throwError } from 'rxjs';
import { catchError, delayWhen } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { CurrentEnvironmentStore, CurrentProjectStore, Project, ProjectService } from '@modules/projects';
import { AppError, isSet } from '@shared';

@Injectable()
export class ProjectUniqueNameForm extends FormGroup {
  instance: Project;

  controls: {
    unique_name: FormControl;
    subdomain: FormControl;
  };

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private formUtils: FormUtils,
    private projectService: ProjectService
  ) {
    super({
      unique_name: new FormControl('', [Validators.required, Validators.pattern(/^[a-z0-9_]{2,}$/)]),
      subdomain: new FormControl('', [Validators.pattern(/^[a-z0-9_]{2,}$/)])
    });

    this.controls.unique_name.valueChanges.subscribe(rawValue => {
      const cleanValue = this.cleanValue(rawValue);

      if (cleanValue !== rawValue) {
        this.controls.unique_name.setValue(cleanValue);
      }
    });

    this.controls.subdomain.valueChanges.subscribe(rawValue => {
      const cleanValue = this.cleanValue(rawValue);

      if (cleanValue !== rawValue) {
        this.controls.subdomain.setValue(cleanValue);
      }
    });
  }

  cleanValue(value: any) {
    if (!isSet(value)) {
      value = '';
    }

    return value
      .toLowerCase()
      .replace(/[-.\s]/g, '_')
      .replace(/[^a-z0-9_]/g, '');
  }

  init(project: Project) {
    this.instance = project;

    this.controls.unique_name.patchValue(project.uniqueName);

    if (project.domain && !project.domain.custom) {
      this.controls.subdomain.patchValue(project.domain.domain);
    }

    this.markAsPristine();
  }

  hasChanges(): boolean {
    if (!this.instance) {
      return false;
    }

    const uniqueNameChanged = this.instance.uniqueName != this.controls.unique_name.value;
    const subdomainChanged =
      this.instance.domain && !this.instance.domain.custom
        ? this.instance.domain.domain != this.controls.subdomain.value
        : false;

    return uniqueNameChanged || subdomainChanged;
  }

  submit(): Observable<Project> {
    for (const environment of this.instance.environments) {
      if (!this.instance.hasEnvironmentCustomizationPermission(environment)) {
        const error = new AppError(
          `You need to have customization permissions to all environments (missing ${environment.name})`
        );
        return throwError(error);
      }
    }

    const instance = cloneDeep(this.instance) as Project;
    const fields = [];

    if (this.controls.unique_name.value != instance.uniqueName) {
      instance.uniqueName = this.controls.unique_name.value;
      fields.push('unique_name');
    }

    if (instance.domain && !instance.domain.custom) {
      instance.subdomain = this.controls.subdomain.value;
      fields.push('subdomain');
    }

    return this.projectService.update(this.instance.uniqueName, instance, fields).pipe(
      delayWhen(project => {
        this.currentProjectStore.uniqueName = project.uniqueName;
        return this.currentProjectStore.getFirst(true);
      }),
      catchError(error => {
        this.formUtils.showFormErrors(this, error);
        return throwError(error);
      })
    );
  }
}
