import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import * as Color from 'color';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { controlValue } from '@shared';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorPickerComponent implements OnInit, OnDestroy {
  @Input() hexControl: AbstractControl;

  hexControlInternalUpdate = false;
  hueControl = new FormControl(0);
  saturationControl = new FormControl(0);
  luminosityControl = new FormControl(0);
  // hexControl = new FormControl('#2B50ED');
  hueBackground =
    'linear-gradient(to right, #FF0000 0%, #FF7D00 12.38%, #FFFF00 19.52%, #00FF00 35.71%, #00FFFF 51.43%, #0000FF 65.72%, #FF00FF 83.33%, #FF0000 100%)';
  hueThumbBorder: string;
  saturationBackground: string;
  luminosityBackground: string;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.initHex();
  }

  ngOnDestroy(): void {}

  initHex() {
    this.hexControl.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      if (value.length > 0) {
        let cleanValue = value.toUpperCase().replace(/[^0-9A-F]/g, '');

        if (cleanValue[0] != '#') {
          cleanValue = '#' + cleanValue;
        }

        cleanValue = cleanValue.substring(0, 7);

        if (cleanValue != value) {
          this.hexControl.patchValue(cleanValue);
        }
      }
    });

    controlValue<string>(this.hexControl)
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        let internalUpdate = false;

        if (this.hexControlInternalUpdate) {
          this.hexControlInternalUpdate = false;
          internalUpdate = true;
        }

        if (value.length == 0) {
          return;
        }

        try {
          const clr = Color(value);
          const hsl = clr.hsl();
          const [hue, saturation, luminosity] = hsl.color;

          if (!internalUpdate) {
            this.hueControl.patchValue(hue);
            this.saturationControl.patchValue(saturation);
            this.luminosityControl.patchValue(luminosity);
          }

          this.hueThumbBorder = Color({ h: hue, s: 100, l: 30 }).hex();
          this.saturationBackground = `linear-gradient(to right, hsl(${hue}, 0%, ${luminosity}%) 0%, hsl(${hue}, 100%, ${luminosity}%) 100%)`;
          this.luminosityBackground = `linear-gradient(to right, hsl(${hue}, ${saturation}%, 0%) 0%, hsl(${hue}, ${saturation}%, 50%) 50%, hsl(${hue}, ${saturation}%, 100%) 100%)`;
          this.cd.markForCheck();
        } catch (e) {}
      });
  }

  getCurrentColor(): Color {
    try {
      return Color(this.hexControl.value);
    } catch (e) {
      return Color({ h: this.hueControl.value, s: this.saturationControl.value, l: this.luminosityControl.value });
    }
  }

  setHue(value: number) {
    const clr = this.getCurrentColor().hue(value);
    this.hexControl.patchValue(clr.hex().toUpperCase());
    this.hexControlInternalUpdate = true;
  }

  setSaturation(value: number) {
    const clr = this.getCurrentColor().saturationl(value);
    this.hexControl.patchValue(clr.hex().toUpperCase());
    this.hexControlInternalUpdate = true;
  }

  setLuminosity(value: number) {
    const clr = this.getCurrentColor().lightness(value);
    this.hexControl.patchValue(clr.hex().toUpperCase());
    this.hexControlInternalUpdate = true;
  }
}
