var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import pickBy from 'lodash/pickBy';
import { ActionItem } from '@modules/actions';
import { ModelDescriptionDataSource } from '@modules/data-sources';
import { Input, InputValueType } from '@modules/fields';
import { ModelDescriptionQuery } from '@modules/queries';
import { migrateModelDescriptionDataSource } from '../../../utils/migration';
import { ITEM_OUTPUT, VALUE_OUTPUT } from '../../form-outputs';
import { TintStyle } from '../../view-settings-action';
import { getElementByType, registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
import { FieldElementItem } from './field';
import { FormSubmitElementItem } from './form-submit-element';
import { editorJsOutputDataToQuillDelta, TextElementItem } from './text';
export var FormStyle;
(function (FormStyle) {
    FormStyle["Wrap"] = "wrap";
    FormStyle["Background"] = "background";
})(FormStyle || (FormStyle = {}));
var FormElementItem = /** @class */ (function (_super) {
    __extends(FormElementItem, _super);
    function FormElementItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = ElementType.Form;
        _this.columns = [];
        _this.children = [];
        _this.style = FormStyle.Wrap;
        _this.generated = false;
        _this.loadInvisible = false;
        return _this;
    }
    FormElementItem.prototype.deserialize = function (data) {
        var _this = this;
        _super.prototype.deserialize.call(this, data);
        if (this.params['generated'] !== undefined) {
            this.generated = this.params['generated'];
        }
        if (this.params['get_data_source']) {
            this.getDataSource = new ModelDescriptionDataSource().deserialize(this.params['get_data_source']);
        }
        else if (this.params['resource']) {
            // Backward compatibility
            this.getDataSource = migrateModelDescriptionDataSource(ModelDescriptionDataSource, ModelDescriptionQuery, {
                resource: this.params['resource'],
                query: this.params['get_query'],
                parameters: this.params['parameters'],
                inputs: this.params['inputs'],
                columns: this.params['get_columns']
            });
        }
        else {
            this.getDataSource = undefined;
        }
        if (this.params['submit_action']) {
            this.submitAction = new ActionItem().deserialize(this.params['submit_action']);
        }
        if (this.params['style']) {
            this.style = this.params['style'];
        }
        if (this.params['children']) {
            this.children = this.params['children']
                .map(function (item) {
                var element = getElementByType(item['type']);
                if (!element) {
                    console.error("Unsupported element type: " + item['type']);
                    return;
                }
                return new element().deserialize(item);
            })
                .filter(function (item) { return item != undefined; });
        }
        else {
            // Backward compatibility
            var children_1 = [];
            if (this.params['title']) {
                var element = new TextElementItem();
                element.generateUid();
                element.name = element.defaultName();
                element.quillDelta = editorJsOutputDataToQuillDelta({
                    blocks: [
                        {
                            type: 'header',
                            data: {
                                text: this.params['title'],
                                level: 1
                            }
                        }
                    ]
                });
                children_1.push(element);
            }
            if (this.params['columns']) {
                children_1.push.apply(children_1, this.params['columns'].map(function (item) {
                    var element = new FieldElementItem();
                    element.generateUid();
                    element.name = item.verboseName || item.name;
                    element.settings = item;
                    element.version = 1;
                    element.width = 220;
                    if (_this.getDataSource) {
                        var input = new Input();
                        input.name = 'value';
                        input.valueType = InputValueType.Context;
                        input.contextValue = ['elements', _this.uid, ITEM_OUTPUT, item.name];
                        element.settings.valueInput = input;
                    }
                    return element;
                }));
                if (this.submitAction) {
                    this.submitAction.inputs = this.submitAction.inputs.map(function (input) {
                        if (input.valueType == InputValueType.Context &&
                            input.contextValue &&
                            input.contextValue[0] == VALUE_OUTPUT) {
                            var element = children_1.find(function (item) { return item instanceof FieldElementItem && item.settings.name == input.name; });
                            if (element) {
                                input.contextValue = ['elements', element.uid, VALUE_OUTPUT];
                            }
                        }
                        return input;
                    });
                }
            }
            var submitElement = new FormSubmitElementItem();
            submitElement.generateUid();
            if (this.params['submit_action']) {
                submitElement.verboseNameInput = new Input().deserializeFromStatic('value', this.params['submit_action']['verbose_name']);
                submitElement.icon = this.params['submit_action']['icon'];
                submitElement.style = this.params['submit_action']['style'];
                if (this.params['submit_action']['tint'] != undefined) {
                    submitElement.tint = this.params['submit_action']['tint'];
                }
                else if (submitElement.style == TintStyle.Default && this.params['submit_action']['color_text']) {
                    submitElement.tint = this.params['submit_action']['color_text'];
                }
                else if (submitElement.style == TintStyle.Primary && this.params['submit_action']['color']) {
                    submitElement.tint = this.params['submit_action']['color'];
                }
                else if (submitElement.style == TintStyle.Transparent && this.params['submit_action']['color_text']) {
                    submitElement.tint = this.params['submit_action']['color_text'];
                }
            }
            children_1.push(submitElement);
            this.children = children_1;
            this.generated = this.params['columns'] && this.params['columns'].length;
        }
        if (this.params['load_invisible'] != undefined) {
            this.loadInvisible = this.params['load_invisible'];
        }
        return this;
    };
    FormElementItem.prototype.serialize = function (fields) {
        this.params = {};
        this.params['generated'] = this.generated;
        this.params['get_data_source'] = this.getDataSource ? this.getDataSource.serialize() : undefined;
        this.params['columns'] = this.columns;
        this.params['submit_action'] = this.submitAction ? this.submitAction.serialize() : undefined;
        this.params['children'] = this.children.map(function (item) { return item.serialize(); });
        this.params['style'] = this.style;
        this.params['load_invisible'] = this.loadInvisible;
        var data = _super.prototype.serialize.call(this);
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Object.defineProperty(FormElementItem.prototype, "typeStr", {
        get: function () {
            return 'send form data';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormElementItem.prototype, "analyticsName", {
        get: function () {
            return 'form';
        },
        enumerable: true,
        configurable: true
    });
    FormElementItem.prototype.defaultName = function () {
        return 'Form';
    };
    return FormElementItem;
}(ElementItem));
export { FormElementItem };
registerElementForType(ElementType.Form, FormElementItem);
