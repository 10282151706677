import pickBy from 'lodash/pickBy';

import { ActionDescription } from '@modules/actions';

import { AlignHorizontal } from '../../align-horizontal';
import { ViewSettingsAction } from '../../view-settings-action';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';

export class ActionElementItem extends ElementItem {
  public type = ElementType.Action;
  public alignHorizontalDefault = AlignHorizontal.Left;

  public actionItem: ViewSettingsAction;
  public tooltip: string;

  deserialize(data: Object): ActionElementItem {
    super.deserialize(data);
    this.tooltip = this.params['tooltip'];

    if (this.params['action_item']) {
      this.actionItem = new ViewSettingsAction().deserialize(this.params['action_item']);
    } else if (this.params['action']) {
      this.actionItem = new ViewSettingsAction();
      this.actionItem.sharedActionDescription = new ActionDescription().deserialize(this.params['action']).id;
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = {};
    this.params['action_item'] = this.actionItem ? this.actionItem.serialize() : undefined;
    this.params['tooltip'] = this.tooltip;

    let data = super.serialize();
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  get typeStr(): string {
    return 'trigger operations or transitions';
  }

  get analyticsName(): string {
    return 'action';
  }

  defaultName() {
    return 'Button';
  }
}

registerElementForType(ElementType.Action, ActionElementItem);
