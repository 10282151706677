import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import range from 'lodash/range';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { TintStyle } from '@modules/customize';
import {
  createFormFieldFactory,
  FormFieldSerialized,
  JsonStructureArrayParams,
  JsonStructureNode,
  JsonStructureNodeType,
  JsonStructureObjectParams
} from '@modules/fields';
import { controlValue } from '@shared';

import { createJsonStructureNodeControl } from '../../../utils/json-structure';

@Component({
  selector: 'app-json-field-node',
  templateUrl: './json-field-node.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JsonFieldNodeComponent implements OnInit, OnDestroy, OnChanges {
  @Input() node: JsonStructureNode;
  @Input() form: FormGroup | FormArray;
  @Input() editable = true;

  objectParams: JsonStructureObjectParams;
  arrayParams: JsonStructureArrayParams;
  arrayNodes: JsonStructureNode[];
  fieldParams: FormFieldSerialized;
  createField = createFormFieldFactory();
  updateArrayNodesSubscription: Subscription;
  tintStyles = TintStyle;

  trackItemFn(i, item: JsonStructureNode) {
    return item.name;
  }

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.initNode();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes['node'] && !changes['node'].isFirstChange()) ||
      (changes['form'] && !changes['form'].isFirstChange())
    ) {
      this.initNode();
    }
  }

  initNode() {
    if (this.updateArrayNodesSubscription) {
      this.updateArrayNodesSubscription.unsubscribe();
      this.updateArrayNodesSubscription = undefined;
    }

    if (!this.node) {
      return;
    }

    if (this.node.type == JsonStructureNodeType.Object) {
      this.objectParams = this.node.params as JsonStructureObjectParams;
    } else if (this.node.type == JsonStructureNodeType.Array) {
      this.arrayParams = this.node.params as JsonStructureArrayParams;
      this.updateArrayNodes();
    } else if (this.node.type == JsonStructureNodeType.Field) {
      this.fieldParams = {
        ...this.node.params,
        name: this.node.name
      };
    }
  }

  updateArrayNodes() {
    if (this.updateArrayNodesSubscription) {
      this.updateArrayNodesSubscription.unsubscribe();
      this.updateArrayNodesSubscription = undefined;
    }

    const control = this.form.controls[this.node.name] as FormArray;

    if (!control) {
      this.arrayNodes = [];
      this.cd.markForCheck();
      return;
    }

    this.updateArrayNodesSubscription = controlValue(control)
      .pipe(
        map(value => value.length),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe(length => {
        this.arrayNodes = range(0, length).map(i => {
          const item = cloneDeep(this.arrayParams.item) as JsonStructureNode;
          item.name = i;
          return item;
        });
        this.cd.markForCheck();
      });
  }

  add() {
    const form = this.form.controls[this.node.name] as FormArray;
    const control = createJsonStructureNodeControl(this.arrayParams.item);

    if (control) {
      form.push(control);
    }

    this.updateArrayNodes();
    this.cd.detectChanges();
  }

  remove(index: number) {
    const form = this.form.controls[this.node.name] as FormArray;
    form.removeAt(index);
    this.updateArrayNodes();
    this.cd.detectChanges();
  }
}
