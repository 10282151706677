/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../shared/components/loader-small/loader-small.component.ngfactory";
import * as i2 from "../../../../../shared/components/loader-small/loader-small.component";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../common/tips/directives/tip/tip.directive";
import * as i5 from "../../../../../shared/pipes/capitalize/capitalize.pipe";
import * as i6 from "../../../../colors-shared/directives/tint-color/tint-color.directive";
import * as i7 from "@angular/platform-browser";
import * as i8 from "./table-actions-item.component";
import * as i9 from "../../../../customize/services/customize/customize.service";
import * as i10 from "../../../../action-queries/services/action/action.service";
import * as i11 from "../../../../action-queries/services/action-controller/action-controller.service";
import * as i12 from "../../../../../common/notifications/services/notification/notification.service";
var styles_TableActionsItemComponent = [];
var RenderType_TableActionsItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TableActionsItemComponent, data: {} });
export { RenderType_TableActionsItemComponent as RenderType_TableActionsItemComponent };
function View_TableActionsItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loader-small", [["class", "table-action__icon"]], null, null, null, i1.View_LoaderSmallComponent_0, i1.RenderType_LoaderSmallComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoaderSmallComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_TableActionsItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "table-action__icon"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i3.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "table-action__icon"; var currVal_1 = ("icon-" + _co.action.icon); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_TableActionsItemComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "table-action__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), _co.verboseName)); _ck(_v, 1, 0, currVal_0); }); }
function View_TableActionsItemComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "element__indicator element__indicator_outside icon-eye"]], null, null, null, null, null)), i0.ɵdid(1, 737280, null, 0, i4.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"] }, null)], function (_ck, _v) { var currVal_0 = "Action is hidden"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TableActionsItemComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i5.CapitalizePipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 7, "a", [["class", "table-action"], ["href", "javascript:void(0)"]], [[2, "table-action_icon", null], [2, "table-action_disabled", null], [8, "style", 2]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.execute() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 540672, null, 0, i6.TintColorDirective, [i7.DomSanitizer], { appTintColor: [0, "appTintColor"], appTintColorStyle: [1, "appTintColorStyle"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TableActionsItemComponent_1)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TableActionsItemComponent_2)), i0.ɵdid(6, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TableActionsItemComponent_3)), i0.ɵdid(8, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_TableActionsItemComponent_4)), i0.ɵdid(10, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.accentColor; var currVal_4 = _co.tintStyles.Primary; _ck(_v, 2, 0, currVal_3, currVal_4); var currVal_5 = _co.submitLoading; _ck(_v, 4, 0, currVal_5); var currVal_6 = (_co.action.icon && !_co.submitLoading); _ck(_v, 6, 0, currVal_6); var currVal_7 = _co.verboseName; _ck(_v, 8, 0, currVal_7); var currVal_8 = (!_co.isVisible && i0.ɵunv(_v, 10, 0, i0.ɵnov(_v, 11).transform(_co.customizeService.enabled$))); _ck(_v, 10, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.verboseName; var currVal_1 = (_co.disabled || _co.submitLoading); var currVal_2 = i0.ɵnov(_v, 2).style; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_TableActionsItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-table-actions-item", [], [[2, "element__indicator-wrapper", null], [2, "hidden", null]], null, null, View_TableActionsItemComponent_0, RenderType_TableActionsItemComponent)), i0.ɵdid(1, 770048, null, 0, i8.TableActionsItemComponent, [i9.CustomizeService, i10.ActionService, i11.ActionControllerService, i12.NotificationService, i0.Injector, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).indicatorWrapper; var currVal_1 = i0.ɵnov(_v, 1).hidden; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var TableActionsItemComponentNgFactory = i0.ɵccf("app-table-actions-item", i8.TableActionsItemComponent, View_TableActionsItemComponent_Host_0, { action: "action", selectedItems: "selectedItems", context: "context", contextElement: "contextElement", accentColor: "accentColor" }, {}, []);
export { TableActionsItemComponentNgFactory as TableActionsItemComponentNgFactory };
