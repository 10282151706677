<div *ngIf="!view" class="map-popup" [class.map-popup_clickable]="settings.cardClickAction">
  <!--<a href="javascript:void(0)"-->
  <!--class="map-popup__close icon icon-close"-->
  <!--(click)="close.emit()">-->
  <!--</a>-->
  <app-model-card
    [item]="item"
    [modelDescription]="modelDescription"
    [visibleColumns]="visibleColumns"
    [columns]="settings.dataSource?.columns || []"
    [columnActions]="settings.columnActions"
    [context]="context"
    [contextElement]="contextElement"
    (modelUpdated)="modelUpdated.next($event)"
  ></app-model-card>

  <div *ngIf="settings.cardClickAction || settings.modelActions.length" class="map-popup__buttons">
    <span *ngIf="settings.cardClickAction" class="map-popup__buttons-item">
      <a href="javascript:void(0)" class="button button_small" (click)="open($event)">
        <span class="icon icon-eye button__icon button__icon_left"></span>
        <span class="button__label">Open</span>
      </a>
    </span>

    <ng-container *ngFor="let action of settings.modelActions">
      <app-model-card-action
        class="map-popup__buttons-item"
        [model]="item.model"
        [action]="action"
        [context]="context"
        [contextElement]="contextElement"
        [accentColor]="accentColor"
      ></app-model-card-action>
    </ng-container>
  </div>
</div>

<div
  *ngIf="view"
  class="map-popup"
  [class.map-popup_clickable]="settings.cardClickAction"
  [style.width.px]="view.frame.width"
  [style.height.px]="view.frame.height"
  [style.min-width.px]="view.widthResize.enabled && (view.widthResize.min | appIsSet) ? view.widthResize.min : null"
  [style.max-width.px]="view.widthResize.enabled && (view.widthResize.max | appIsSet) ? view.widthResize.max : null"
  [style.min-height.px]="view.heightResize.enabled && (view.heightResize.min | appIsSet) ? view.heightResize.min : null"
  [style.max-height.px]="view.heightResize.enabled && (view.heightResize.max | appIsSet) ? view.heightResize.max : null"
>
  <app-custom-element-view
    [view]="view"
    [parameters]="viewParameters"
    [actions]="settings.customActions"
    [params]="viewParams$ | async"
    [localContext]="localContext"
  ></app-custom-element-view>
</div>
