import { EventEmitter, OnChanges, OnInit } from '@angular/core';
import { AppConfigService } from '@core';
import { AnalyticsEvent } from '@modules/analytics';
import { TintStyle } from '@modules/customize';
import { defaultSignUpLayout, Domain } from '@modules/domain';
import { createFormFieldFactory } from '@modules/fields';
import { ProjectInvite, ProjectPublicInvite } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { SSOType } from '@modules/sso';
import { isSet } from '@shared';
import { RegisterForm } from '../register/register.form';
var RegisterTemplateComponent = /** @class */ (function () {
    function RegisterTemplateComponent(appConfigService, routing) {
        this.appConfigService = appConfigService;
        this.routing = routing;
        this.domainLoading = false;
        this.submitLoading = false;
        this.screenLoader = false;
        this.customFields = [];
        this.inviteLoading = false;
        this.publicInviteLoading = false;
        this.fillScreen = false;
        this.preview = false;
        this.socialLogin = new EventEmitter();
        this.ssoLogin = new EventEmitter();
        this.cleanErrorForm = new EventEmitter();
        this.submit = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.defaultLayout = defaultSignUpLayout;
        this.externalFonts = [];
        this.tintStyles = TintStyle;
        this.analyticsEvents = AnalyticsEvent;
    }
    RegisterTemplateComponent.prototype.ngOnInit = function () {
        var disabledEmailSignUp = this.domain ? this.domain.disableEmailSignUp : false;
        var disabledEmailSignIn = this.domain ? this.domain.disableEmailSignIn : false;
        if (disabledEmailSignUp && !disabledEmailSignIn && !this.invite && this.signInLink) {
            this.routing.navigate(this.signInLink, { queryParamsHandling: 'preserve' });
        }
    };
    RegisterTemplateComponent.prototype.ngOnChanges = function (changes) {
        if (changes.domain) {
            this.externalFonts = this.getExternalFonts(this.domain);
        }
    };
    RegisterTemplateComponent.prototype.getExternalFonts = function (domain) {
        if (!domain) {
            return [];
        }
        return [domain.signUpFontRegular, domain.signUpFontHeading].filter(function (item) { return isSet(item); });
    };
    Object.defineProperty(RegisterTemplateComponent.prototype, "layout", {
        get: function () {
            return this.domain && isSet(this.domain.signUpLayout) ? this.domain.signUpLayout : this.defaultLayout;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegisterTemplateComponent.prototype, "headerHasContent", {
        get: function () {
            var hasLogo = !this.isWhiteLabel || (this.domain && (this.domain.logo || this.domain.initials));
            var hasTitle = !this.domain || this.domain.signUpTitle;
            var hasDescription = !this.domain || this.domain.signUpDescription;
            return hasLogo || hasTitle || hasDescription;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegisterTemplateComponent.prototype, "isWhiteLabel", {
        get: function () {
            return this.domain && this.domain.whiteLabel;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegisterTemplateComponent.prototype, "isGoogleSignEnabled", {
        get: function () {
            var disabledGoogleSignIn = this.domain ? this.domain.disableGoogleSignIn : false;
            return !this.isWhiteLabel && this.appConfigService.socialAuthGoogleEnabled && !disabledGoogleSignIn;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegisterTemplateComponent.prototype, "isFacebookSignEnabled", {
        get: function () {
            var disabledFacebookSignIn = this.domain ? this.domain.disableFacebookSignIn : false;
            return !this.isWhiteLabel && this.appConfigService.socialAuthFacebookEnabled && !disabledFacebookSignIn;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegisterTemplateComponent.prototype, "isSignInWithEnabled", {
        get: function () {
            var ssoSettings = this.domain ? this.domain.ssoSettings : [];
            return this.isGoogleSignEnabled || this.isFacebookSignEnabled || ssoSettings.length;
        },
        enumerable: true,
        configurable: true
    });
    RegisterTemplateComponent.prototype.startSSOLogin = function (sso) {
        if (sso.type == SSOType.Custom) {
            this.routing.navigate(this.signInLink, { queryParams: { sso: sso.uid }, queryParamsHandling: 'merge' });
        }
        else {
            this.ssoLogin.emit(sso);
        }
    };
    return RegisterTemplateComponent;
}());
export { RegisterTemplateComponent };
