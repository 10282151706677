<div
  *ngIf="!view"
  class="calendar-card calendar-card--accent"
  [class.calendar-card_clickable]="true"
  [class.calendar-card_selected]="selected"
  (click)="onClick($event)"
>
  <!--<div class="calendar-card__top">-->
  <!--<div class="calendar-card__icons">-->
  <!--<figure class="calendar-card__icon"></figure>-->
  <!--</div>-->
  <!--<p class="calendar-card__device">{{model | appModelStr | appStub | async}}</p>-->
  <!--</div>-->

  <!--              <p class="calendar-card__status" [innerHTML]="model | appModelStr | appStub | async"></p>-->
  <app-model-card
    [item]="item"
    [modelDescription]="modelDescription"
    [visibleColumns]="visibleColumns"
    [columns]="settings.dataSource?.columns || []"
    [labels]="false"
    [compact]="true"
    [context]="context"
    [contextElement]="contextElement"
    (modelUpdated)="modelUpdated.emit($event)"
  >
  </app-model-card>

  <div *ngIf="settings.modelActions.length" class="calendar-card__buttons">
    <ng-container *ngFor="let action of settings.modelActions">
      <app-model-card-action
        class="calendar-card__buttons-item"
        [model]="item.model"
        [action]="action"
        [context]="context"
        [contextElement]="contextElement"
        (click)="$event.stopPropagation()"
      >
      </app-model-card-action>
    </ng-container>
  </div>

  <!--<p class="calendar-card__status" *ngIf="event.finished">Finished</p>-->
  <!--<p class="calendar-card__status" *ngIf="!event.finished">Not finished</p>-->
</div>

<div
  *ngIf="view"
  class="calendar-card-view"
  [class.calendar-card-view_clickable]="true"
  [style.height.px]="view.frame.height"
  [style.min-width.px]="view.widthResize.enabled && (view.widthResize.min | appIsSet) ? view.widthResize.min : null"
  [style.max-width.px]="view.widthResize.enabled && (view.widthResize.max | appIsSet) ? view.widthResize.max : null"
  [style.min-height.px]="view.heightResize.enabled && (view.heightResize.min | appIsSet) ? view.heightResize.min : null"
  [style.max-height.px]="view.heightResize.enabled && (view.heightResize.max | appIsSet) ? view.heightResize.max : null"
  (click)="onClick($event)"
>
  <app-custom-element-view
    [view]="view"
    [parameters]="viewParameters"
    [actions]="settings.customActions"
    [params]="viewParams$ | async"
    [localContext]="localContext"
    [stateSelectedEnabled]="true"
    [stateSelected]="selected"
  ></app-custom-element-view>
</div>
