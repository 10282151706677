var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, Injector, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import isEqual from 'lodash/isEqual';
import isPlainObject from 'lodash/isPlainObject';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { ActionControllerService } from '@modules/action-queries';
import { ServerRequestError } from '@modules/api';
import { VALUE_OUTPUT } from '@modules/customize';
import { DataSourceGeneratorService } from '@modules/customize-generators';
import { ValueWidget } from '@modules/dashboard';
import { WidgetDataSourceService } from '@modules/dashboard-queries';
import { ChartWidgetDataSource, DataSourceType, ValueWidgetDataSource } from '@modules/data-sources';
import { applyParamInput$, applyParamInputs$, LOADING_VALUE, NOT_SET_VALUE } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ascComparator } from '@shared';
import { WidgetComponent } from '../widget/widget.component';
export function serializeDataSourceColumns(columns) {
    return columns
        .filter(function (item) { return !item.flex; })
        .map(function (item) {
        return {
            name: item.name
        };
    })
        .sort(function (lhs, rhs) { return ascComparator(lhs.name, rhs.name); });
}
export function serializeData(data) {
    return {
        dataSource: data.dataSource
            ? __assign({}, data.dataSource.serialize(), { columns: serializeDataSourceColumns(data.dataSource.columns) }) : undefined,
        staticData: data.staticData,
        params: data.params
    };
}
function getElementStateFetch(state) {
    return {
        data: state.data ? serializeData(state.data) : undefined,
        compare: state.data ? serializeData(state.compare) : undefined,
        chart: state.chart
            ? {
                dataSource: state.chart.dataSource
                    ? __assign({}, state.chart.dataSource.serialize(), { columns: serializeDataSourceColumns(state.chart.dataSource.columns) }) : undefined,
                staticData: state.chart.staticData,
                params: state.chart.params
            }
            : undefined,
        inputsLoading: state.inputsLoading,
        inputsNotSet: state.inputsNotSet
    };
}
function getElementStateChartDisplay(state) {
    return {
        chart: state.chart
            ? {
                name: state.chart.name,
                color: state.chart.color,
                format: state.chart.format
            }
            : undefined
    };
}
var ValueWidgetComponent = /** @class */ (function (_super) {
    __extends(ValueWidgetComponent, _super);
    function ValueWidgetComponent(currentProjectStore, currentEnvironmentStore, widgetDataSourceService, dataSourceGeneratorService, actionControllerService, notificationService, injector, cd) {
        var _this = _super.call(this) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.widgetDataSourceService = widgetDataSourceService;
        _this.dataSourceGeneratorService = dataSourceGeneratorService;
        _this.actionControllerService = actionControllerService;
        _this.notificationService = notificationService;
        _this.injector = injector;
        _this.cd = cd;
        _this.widget$ = new BehaviorSubject(undefined);
        _this.firstVisible$ = new BehaviorSubject(false);
        _this.elementState = {};
        _this.loading = true;
        _this.configured = true;
        _this.hoverItem$ = new BehaviorSubject(undefined);
        return _this;
    }
    ValueWidgetComponent.prototype.ngOnInit = function () {
        this.updateContextOutputs();
        this.widgetOnChange(this.widget);
        this.trackChanges();
    };
    ValueWidgetComponent.prototype.ngOnDestroy = function () { };
    ValueWidgetComponent.prototype.ngOnChanges = function (changes) {
        if (changes['widget']) {
            this.widgetOnChange(this.widget);
        }
    };
    ValueWidgetComponent.prototype.widgetOnChange = function (value) {
        this.widget$.next(value);
    };
    ValueWidgetComponent.prototype.trackChanges = function () {
        var _this = this;
        this.firstVisible$
            .pipe(first(function (value) { return value; }), switchMap(function () { return _this.widget$; }), switchMap(function (element) {
            return combineLatest(_this.dataSourceGeneratorService.applyDataSourceDefaults(ValueWidgetDataSource, element.dataSource), _this.dataSourceGeneratorService.applyDataSourceDefaults(ValueWidgetDataSource, element.compareDataSource), _this.dataSourceGeneratorService.applyDataSourceDefaults(ChartWidgetDataSource, element.chartDataset ? element.chartDataset.dataSource : undefined)).pipe(map(function (_a) {
                var dataSource = _a[0], compareDataSource = _a[1], chartDataset = _a[2];
                element.dataSource = dataSource;
                element.compareDataSource = compareDataSource;
                if (chartDataset) {
                    element.chartDataset.dataSource = chartDataset;
                }
                return element;
            }));
        }), switchMap(function (element) { return _this.getElementState(element); }), untilDestroyed(this))
            .subscribe(function (state) {
            _this.onStateUpdated(state);
            _this.elementState = state;
        });
    };
    ValueWidgetComponent.prototype.getElementStateDataParams = function (dataSource) {
        var staticData$ = dataSource && dataSource.type == DataSourceType.Input && dataSource.input
            ? applyParamInput$(dataSource.input, {
                context: this.context,
                defaultValue: {},
                handleLoading: true,
                ignoreEmpty: true
            }).pipe(distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }))
            : of({});
        var params$ = dataSource
            ? applyParamInputs$({}, dataSource.queryInputs, {
                context: this.context,
                parameters: dataSource.queryParameters,
                handleLoading: true,
                ignoreEmpty: true
            }).pipe(distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }))
            : of({});
        return combineLatest(staticData$, params$).pipe(map(function (_a) {
            var staticData = _a[0], params = _a[1];
            return {
                dataSource: dataSource,
                staticData: staticData,
                params: params
            };
        }));
    };
    ValueWidgetComponent.prototype.getElementState = function (widget) {
        return combineLatest(this.getElementStateDataParams(widget.dataSource), this.getElementStateDataParams(widget.compareDataSource), this.getElementStateDataParams(widget.chartDataset ? widget.chartDataset.dataSource : undefined)).pipe(map(function (_a) {
            var data = _a[0], compare = _a[1], chart = _a[2];
            return {
                widget: widget,
                data: data,
                compare: compare,
                chart: widget.chartDataset
                    ? __assign({ name: widget.chartDataset.name, color: widget.chartDataset.color, format: widget.chartDataset.format }, chart) : undefined,
                inputsLoading: [data, compare, chart].some(function (dataParams) {
                    return [dataParams.params, dataParams.staticData].some(function (obj) {
                        return obj == LOADING_VALUE || values(obj).some(function (item) { return item === LOADING_VALUE; });
                    });
                }),
                inputsNotSet: [data, compare, chart].some(function (dataParams) {
                    return [dataParams.params, dataParams.staticData].some(function (obj) {
                        return obj == NOT_SET_VALUE || values(obj).some(function (item) { return item === NOT_SET_VALUE; });
                    });
                })
            };
        }));
    };
    ValueWidgetComponent.prototype.onStateUpdated = function (state) {
        if (!isEqual(getElementStateFetch(state), getElementStateFetch(this.elementState))) {
            var chartDisplayState$ = new BehaviorSubject(state);
            this.fetch(state, chartDisplayState$);
            this.chartDisplayState$ = chartDisplayState$;
        }
        else if (this.chartDisplayState$ &&
            !isEqual(getElementStateChartDisplay(state), getElementStateChartDisplay(this.elementState))) {
            this.chartDisplayState$.next(state);
        }
    };
    ValueWidgetComponent.prototype.fetch = function (state, chartDisplayState$) {
        var _this = this;
        if (this.loadingSubscription) {
            this.loadingSubscription.unsubscribe();
            this.loadingSubscription = undefined;
        }
        this.loading = false;
        this.configured = state.data && state.data.dataSource && state.data.dataSource.isConfigured();
        this.error = undefined;
        this.cd.markForCheck();
        this.contextElement.patchOutputValueMeta(VALUE_OUTPUT, { loading: true });
        if (!this.configured) {
            this.value = undefined;
            this.compareValue = undefined;
            this.chartValue = undefined;
            this.cd.detectChanges();
            return;
        }
        if (state.inputsNotSet) {
            this.value = undefined;
            this.compareValue = undefined;
            this.chartValue = undefined;
            this.loading = false;
            this.cd.markForCheck();
            return;
        }
        this.loading = true;
        this.cd.markForCheck();
        if (state.inputsLoading) {
            return;
        }
        var dataAggregate$ = this.widgetDataSourceService.aggregate({
            project: this.currentProjectStore.instance,
            environment: this.currentEnvironmentStore.instance,
            dataSource: state.data.dataSource,
            params: state.data.params,
            staticData: state.data.staticData,
            context: this.context
        });
        var compareAggregate$ = state.compare && state.compare.dataSource && state.compare.dataSource.isConfigured()
            ? this.widgetDataSourceService.aggregate({
                project: this.currentProjectStore.instance,
                environment: this.currentEnvironmentStore.instance,
                dataSource: state.compare.dataSource,
                params: state.compare.params,
                staticData: state.compare.staticData,
                context: this.context
            })
            : of(undefined);
        var chartGroup$ = state.chart && state.chart.dataSource && state.chart.dataSource.isConfigured()
            ? this.widgetDataSourceService.group({
                project: this.currentProjectStore.instance,
                environment: this.currentEnvironmentStore.instance,
                dataSource: state.chart.dataSource,
                params: state.chart.params,
                staticData: state.chart.staticData,
                context: this.context
            })
            : of(undefined);
        this.loadingSubscription = combineLatest(dataAggregate$, compareAggregate$, chartGroup$, chartDisplayState$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            // const obj = { value: value };
            //
            // TweenMax.fromTo(obj, 0.6, { value: 0 }, { value: value, ease: Power4.easeIn,
            //   onUpdate: () => {
            //     if (this['_isComponentDestroyed']) {
            //       return;
            //     }
            //     this.value = this.widget.format ? obj.value : Math.round(obj.value);
            //     this.cd.markForCheck();
            //   },
            //   onComplete: () => {
            //     if (this['_isComponentDestroyed']) {
            //       return;
            //     }
            //     this.cd.markForCheck();
            //   }
            // });
            var value = _a[0], compareValue = _a[1], chartValue = _a[2], chartDisplayState = _a[3];
            _this.value = value;
            _this.compareValue = compareValue;
            _this.chartValue = chartValue
                ? {
                    name: chartDisplayState.chart.name,
                    color: chartDisplayState.chart.color,
                    format: chartDisplayState.chart.format,
                    groupLookup: state.chart.dataSource.xLookup,
                    dataset: chartValue.slice(-30)
                }
                : undefined;
            _this.loading = false;
            _this.cd.markForCheck();
            _this.contextElement.setOutputValue(VALUE_OUTPUT, _this.value, { loading: false });
        }, function (error) {
            console.error(error);
            _this.value = undefined;
            _this.compareValue = undefined;
            _this.chartValue = undefined;
            _this.loading = false;
            if (error instanceof ServerRequestError && error.errors.length) {
                _this.error = error.errors[0];
            }
            else if (isPlainObject(error)) {
                _this.error = JSON.stringify(error);
            }
            else if (error.hasOwnProperty('message')) {
                _this.error = error.message;
            }
            else {
                _this.error = error;
            }
            _this.cd.markForCheck();
            _this.contextElement.setOutputValue(VALUE_OUTPUT, undefined, { loading: false, error: true });
        });
    };
    ValueWidgetComponent.prototype.updateContextOutputs = function () {
        this.contextElement.setOutputs([
            {
                uniqueName: VALUE_OUTPUT,
                name: 'Value',
                icon: 'spiral',
                external: true
            }
        ]);
    };
    ValueWidgetComponent.prototype.reloadData = function () {
        this.fetch(this.elementState, this.chartDisplayState$);
    };
    ValueWidgetComponent.prototype.any = function (value) {
        return value;
    };
    ValueWidgetComponent.prototype.onClick = function () {
        if (this.widget.clickAction) {
            this.actionControllerService
                .execute(this.widget.clickAction, {
                context: this.context,
                injector: this.injector
            })
                .subscribe();
        }
    };
    return ValueWidgetComponent;
}(WidgetComponent));
export { ValueWidgetComponent };
