<div *ngIf="previewFilename" class="file-icon" [class.file-icon_compact]="compact">
  <div class="file-icon__image">
    <div class="file-icon__image-text">{{ previewExtension }}</div>
  </div>
  <div class="file-icon__title">{{ previewFilename }}</div>
  <div *ngIf="additional && previewSize" class="file-icon__subtitle">
    {{ additional }} ({{ previewSize | appFormatNumber: '0.00b' }})
  </div>
  <div *ngIf="!additional && previewSize" class="file-icon__subtitle">
    {{ previewSize | appFormatNumber: '0.00b' }}
  </div>
</div>
