<div appElement [appElementDetectVisible]="true" (appElementFirstVisible)="onFirstVisible()" style="min-height: 2px;">
  <ng-template #input let-form="form">
    <app-select
      *ngIf="form && control"
      [placeholder]="field.placeholder || ('choose' | localize | appCapitalize)"
      [control]="control"
      [fill]="selectFill"
      [small]="selectSmall"
      [segment]="selectSegment"
      [compareWith]="field.params['valueEquals']"
      [source]="source?.initialized ? source : undefined"
      [options]="options$ | async"
      [resetEnabled]="!field['required'] && field['resetEnabled']"
      [searchEnabled]="true"
      [searchMinimumLength]="1"
      [searchDebounce]="source ? 200 : 0"
      [id]="field.name | appUniqueId: idToken"
      [classes]="field.params['classes']"
    >
    </app-select>

    <app-select
      *ngIf="!form"
      [placeholder]="field.placeholder || ('choose' | localize | appCapitalize)"
      [fill]="selectFill"
      [small]="selectSmall"
      [segment]="selectSegment"
      [compareWith]="field.params['valueEquals']"
      [source]="source?.initialized ? source : undefined"
      [options]="options$ | async"
      [resetEnabled]="!field['required'] && field['resetEnabled']"
      [searchEnabled]="true"
      [searchMinimumLength]="1"
      [searchDebounce]="source ? 200 : 0"
      [id]="field.name | appUniqueId: idToken"
      [classes]="field.params['classes']"
    >
    </app-select>
  </ng-template>

  <ng-container *ngIf="!readonly">
    <app-field-wrapper
      [form]="form"
      [field]="field"
      [readonly]="readonly"
      [requiredAsterisk]="requiredAsterisk"
      [label]="label"
      [errors]="errors"
      [truncate]="truncate"
      [manualMargin]="manualMargin"
      [labelButtons]="labelButtons"
      [tooltip]="tooltip"
      [idToken]="idToken"
    >
      <ng-content *ngTemplateOutlet="input; context: { form: form }"></ng-content>
    </app-field-wrapper>
  </ng-container>

  <app-field-wrapper
    *ngIf="readonly"
    [form]="form"
    [field]="field"
    [readonly]="readonly"
    [requiredAsterisk]="requiredAsterisk"
    [label]="label"
    [errors]="errors"
    [truncate]="truncate"
    [manualMargin]="manualMargin"
    [labelButtons]="labelButtons"
    [tooltip]="tooltip"
    [idToken]="idToken"
  >
    <ng-container *ngIf="valueOption">
      <span
        class="status"
        [ngClass]="
          !(valueOption.color | appIsColorHex)
            ? ['color_' + valueOption.color + '_1', 'background-color_' + valueOption.color + '_2']
            : ['']
        "
        [style.color]="(valueOption.color | appIsColorHex) ? valueOption.color : null"
        [style.background-color]="(valueOption.color | appIsColorHex) ? backgroundCustomColor(valueOption.color) : null"
      >
        {{ valueOption.name || valueOption.value }}
      </span>
    </ng-container>
    <ng-container *ngIf="!valueOption">
      {{ formatEmpty(value) }}
    </ng-container>
  </app-field-wrapper>
</div>
