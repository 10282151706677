<div class="popover2 overlay-content overlay-content_animated">
  <div class="popover2__inner popover2__inner_sidebar">
    <div class="popover2__viewport">
      <div class="popover2__content popover2__content_padding_none">
        <div class="menu-item-edit-popover">
          <div class="sidebar__element">
            <app-logo-uploader
              [logoUrlControl]="control.controls.image_custom"
              [logoIconControl]="control.controls.image_icon"
              [logoOptionControl]="control.controls.image_option"
              [logoFillControl]="control.controls.image_fill"
              [logoOptions]="imageOptions"
              [colorControl]="control.controls.image_color"
              [colorCustomEnabledControl]="control.controls.image_color_custom_enabled"
              [colorCustomControl]="control.controls.image_color_custom"
              [borderRadiusControl]="control.controls.image_border_radius"
              [colors]="colors"
            ></app-logo-uploader>
          </div>

          <div class="sidebar__element">
            <app-auto-field
              [form]="control"
              [label]="false"
              [field]="
                createField({
                  name: 'image_size',
                  field: 'SliderField',
                  params: { min_value: 16, max_value: imageMaxSize, normal_value: 32, normal_label: 'Size' }
                })
              "
            ></app-auto-field>
          </div>

          <div class="sidebar__element">
            <app-auto-field
              [form]="control"
              [label]="false"
              [field]="
                createField({
                  name: 'image_border_radius',
                  field: 'SliderField',
                  params: {
                    min_value: 0,
                    min_value_label: '0%',
                    max_value: 100,
                    max_value_label: '100%',
                    normal_value: 50,
                    normal_label: 'Border radius'
                  }
                })
              "
            ></app-auto-field>
          </div>

          <div class="sidebar__element">
            <app-customize-bar-input-edit
              [label]="'Title'"
              [itemForm]="control.controls.title"
              [context]="context"
              [focusedInitial]="options.focusTitle"
              [fill]="true"
              [analyticsSource]="analyticsSource + '_title'"
            ></app-customize-bar-input-edit>
          </div>

          <div class="sidebar__element">
            <app-customize-bar-input-edit
              [label]="'Subtitle'"
              [itemForm]="control.controls.subtitle"
              [context]="context"
              [focusedInitial]="options.focusSubtitle"
              [fill]="true"
              [analyticsSource]="analyticsSource + '_subtitle'"
            ></app-customize-bar-input-edit>
          </div>

          <app-edit-menu-item-action
            [label]="'When item clicked'"
            [control]="control.controls.action"
            [context]="context"
            [analyticsSource]="analyticsSource"
          ></app-edit-menu-item-action>

          <div class="sidebar__element">
            <app-sidebar-field
              [label]="'Conditional Visibility'"
              [documentation]="'conditional-visibility'"
              [documentationLabel]="'See how it works'"
              [documentationSource]="analyticsSource + '_visible'"
            >
              <app-input-edit
                [itemForm]="control.controls.visible_input"
                [context]="context"
                [staticValueDisabled]="true"
                [placeholder]="'Value'"
                [fill]="true"
                [analyticsSource]="analyticsSource + '_visible'"
              ></app-input-edit>

              <ng-container description>
                Example: user.region == 'Europe'
              </ng-container>
            </app-sidebar-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
