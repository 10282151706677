<div
  class="layer-container"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
  (mouseup)="onMouseUp()"
  (click)="onMouseClick($event)"
>
  <div
    class="ellipse-layer"
    [style.opacity]="layer.opacity"
    [style.box-shadow]="!fills.length ? boxShadow : null"
    [style.cursor]="layer.getCursor() || null"
    #layer_element
  >
    <app-layer-interaction
      *ngFor="let item of layer.interactions"
      [interaction]="item"
      [element]="layer_element"
      [context]="viewContext"
      [contextElement]="contextElement"
      [analyticsSource]="analyticsSource"
    ></app-layer-interaction>

    <div
      *ngFor="let fill of fills; let last = last; trackBy: trackFillFn"
      class="ellipse-layer__fill"
      [class.ellipse-layer__fill_inner]="fill.transform"
      [style.background]="fill.transform ? null : fill.background"
      [style.opacity]="(fill.opacity | appIsSet) ? fill.opacity : null"
      [style.box-shadow]="last ? boxShadow : null"
    >
      <span
        *ngIf="fill.transform"
        class="ellipse-layer__fill-inner"
        [style.background]="fill.background"
        [style.width]="fill.width"
        [style.height]="fill.height"
        [style.transform]="fill.transform"
      ></span>

      <span
        *ngIf="fill.icon"
        class="ellipse-layer__fill-icon"
        [style.color]="fill.icon.color"
        [style.font-size.px]="fill.icon.size"
        [ngClass]="'icon-' + fill.icon.icon"
      ></span>
    </div>

    <div
      *ngFor="let border of borders"
      class="ellipse-layer__fill ellipse-layer__fill_no-interaction"
      [style.border]="border.border"
      [style.top.px]="border.position"
      [style.right.px]="border.position"
      [style.bottom.px]="border.position"
      [style.left.px]="border.position"
    ></div>
  </div>

  <ng-container *ngIf="editorContext">
    <ng-container *appViewEditorViewportOverlay="{ frame: layer.frame, translate: translate }">
      <app-view-editor-bounds
        *ngIf="editorContext"
        class="ellipse-layer__bounds"
        [class.ellipse-layer__bounds_hover]="hover$ | async"
        [class.ellipse-layer__bounds_active]="
          (customizing$ | async) &&
          !(editorContext.movingLayer$ | async) &&
          !(editorContext.customizingGradient$ | async)
        "
        [frame]="layer.frame"
        [translate]="translate"
        [snapFrame]="true"
        [snapFrameContainer]="container"
        [snapFrameExceptLayers]="[layer]"
        [constrainProportions]="!!layer.constrainProportion"
        [active]="customizing$ | async"
        [resizeHorizontal]="(customizing$ | async) && !(customizingMultiple$ | async)"
        [resizeVertical]="(customizing$ | async) && !(customizingMultiple$ | async)"
        (resizeStarted)="editorContext.resizingLayer$.next(layer)"
        (resizeFinished)="editorContext.resizingLayer$.next(undefined)"
        (updateFrame)="onFrameUpdate($event)"
      ></app-view-editor-bounds>
    </ng-container>
  </ng-container>
</div>
