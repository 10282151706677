import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { UniqueIdToken } from '@common/unique-id';
import {
  ActionDropdownElementItem,
  ActionElementItem,
  AlignHorizontal,
  ElementItem,
  ElementType,
  elementTypeResize,
  FieldElementItem,
  generateElementNameInContext,
  getElementByType,
  ResizableElementItem,
  ViewContext,
  ViewSettingsAction
} from '@modules/customize';
import { FieldType, getFieldDescriptionByType, Input as FieldInput } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ProjectStorageService } from '@modules/resources';
import { defaultComponentTemplateName, Template } from '@modules/template';
import {
  forceObservable,
  getFilename,
  getImageSize,
  isSet,
  keyboardChars,
  KeyboardEventKeyCode,
  TypedChanges
} from '@shared';

import { ViewEditorContext, ViewEditorTool } from '../../services/view-editor-context/view-editor.context';
import { RootToolItem, ToolItem } from '../view-editor-tools/view-editor-tools.component';

interface ElementDescription {
  type: ElementType;
  title: string;
  subtitle?: string;
  image: string;
  init?: (element: ElementItem) => void;
}

@Component({
  selector: 'app-view-editor-tools-item',
  templateUrl: './view-editor-tools-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewEditorToolsItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() tool: RootToolItem;
  @Input() opened = false;
  @Input() first = false;
  @Input() last = false;
  @Input() elementTemplates: Template[] = [];
  @Input() elementTemplatesLoading = false;
  @Input() viewContext: ViewContext;
  @Output() openTool = new EventEmitter<void>();
  @Output() closeTool = new EventEmitter<void>();

  toolActive = false;
  childToolActive = false;
  defaultChildTool: ToolItem;
  toolForFile: ToolItem;
  toolFileUploading: ToolItem;
  toolElements: ElementDescription[] = [
    {
      type: ElementType.Action,
      title: 'Button',
      subtitle: 'Basic',
      image: 'button',
      init: (element: ActionElementItem) => {
        const name = isSet(element.name) ? element.name : 'Button';

        if (!element.actionItem) {
          element.actionItem = new ViewSettingsAction();
        }

        element.actionItem.verboseNameInput = new FieldInput().deserializeFromStatic('value', name);
      }
    },
    {
      type: ElementType.ActionGroup,
      title: 'Button group',
      subtitle: 'Basic',
      image: 'button_group',
      init: (element: ActionDropdownElementItem) => {
        const name = isSet(element.name) ? element.name : 'Button group';

        element.verboseNameInput = new FieldInput().deserializeFromStatic('value', name);
      }
    },
    {
      type: ElementType.ActionDropdown,
      title: 'Dropdown',
      subtitle: 'Basic',
      image: 'button_dropdown',
      init: (element: ActionDropdownElementItem) => {
        const name = isSet(element.name) ? element.name : 'Button';

        element.verboseNameInput = new FieldInput().deserializeFromStatic('value', name);
      }
    },
    ...[
      {
        field: FieldType.Text,
        label: 'Text Field'
      },
      // {
      //   field: FieldType.RichText,
      //   label: 'Rich Text Field'
      // },
      {
        field: FieldType.Boolean
      },
      {
        field: FieldType.RelatedModel,
        fieldParams: {
          resetEnabled: true
        }
      },
      {
        field: FieldType.Select,
        fieldParams: {
          resetEnabled: true
        }
      },
      {
        field: FieldType.MultipleSelect,
        fieldParams: {
          resetEnabled: true
        }
      },
      {
        field: FieldType.RadioButton
      },
      {
        field: FieldType.DateTime
      },
      // {
      //   field: FieldType.Date
      // },
      {
        field: FieldType.Time
      },
      {
        field: FieldType.File,
        label: 'File Picker'
      },
      {
        field: FieldType.Image,
        label: 'Image Picker'
      },
      {
        field: FieldType.Password
      },
      {
        field: FieldType.Number
      },
      {
        field: FieldType.Rating
      },
      {
        field: FieldType.Slider
      },
      {
        field: FieldType.URL
      },
      // {
      //   field: FieldType.Location
      // },
      {
        field: FieldType.Video,
        label: 'Video Picker'
      },
      {
        field: FieldType.Audio,
        label: 'Audio Picker'
      },
      {
        field: FieldType.Color
      }
      // {
      //   field: FieldType.JSON
      // }
    ].map(field => {
      const fieldDescription = getFieldDescriptionByType(field.field);

      return {
        type: ElementType.Field,
        title: field.label || fieldDescription.label,
        subtitle: 'Fields',
        image: fieldDescription.image,
        init: (item: FieldElementItem) => {
          item.alignHorizontal = AlignHorizontal.Justify;
          item.settings = {
            field: field.field,
            editable: true,
            params: field.fieldParams || {}
          };
        }
      };
    })
  ];
  dropdownPositions: ConnectedPosition[] = [
    { originX: 'start', overlayX: 'start', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 4 },
    { originX: 'center', overlayX: 'center', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 4 },
    { originX: 'end', overlayX: 'end', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 4 },
    { originX: 'start', overlayX: 'start', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: -4 },
    { originX: 'center', overlayX: 'center', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: -4 },
    { originX: 'end', overlayX: 'end', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: -4 },
    { originX: 'end', overlayX: 'start', originY: 'center', overlayY: 'center', offsetX: 4, offsetY: 0 },
    { originX: 'start', overlayX: 'end', originY: 'center', overlayY: 'center', offsetX: -4, offsetY: 0 }
  ];
  viewEditorTools = ViewEditorTool;
  idToken = new UniqueIdToken();

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    public editorContext: ViewEditorContext,
    private projectStorageService: ProjectStorageService,
    private injector: Injector,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.editorContext.tool$.pipe(untilDestroyed(this)).subscribe(tool => {
      const activeChildTool =
        this.tool.children && tool ? this.tool.children.find(item => item.tool == tool) : undefined;

      this.toolActive = this.tool.tool && this.tool.tool == tool;
      this.childToolActive = !!this.tool.children && tool && !!this.tool.children.find(item => item.tool == tool);

      if (activeChildTool) {
        this.defaultChildTool = activeChildTool;
      }

      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<ViewEditorToolsItemComponent>): void {
    if (changes.tool) {
      this.defaultChildTool = this.tool.children ? this.tool.children[0] : undefined;
    }
  }

  onToolClick(tool: ToolItem, options: { element?: ElementDescription } = {}) {
    if (tool.tool && !tool.toolFile && !tool.toolElement) {
      this.editorContext.tool$.next(tool.tool);
    }

    if (tool.tool && tool.toolElement && options.element) {
      const elementCls = getElementByType(options.element.type);

      if (elementCls) {
        const elementBase = new elementCls().deserialize({
          type: options.element.type
        });

        if (options.element.init) {
          options.element.init(elementBase);
        }

        const resize$ = forceObservable(
          elementTypeResize(elementBase.type, { injector: this.injector, element: elementBase })
        );

        combineLatest(resize$)
          .pipe(untilDestroyed(this))
          .subscribe(([resize]) => {
            let width: number;
            let height: number;
            const template = this.elementTemplates.find(i => i.uniqueName == defaultComponentTemplateName(elementBase));
            const params = template ? template.element.serialize()['params'] : {};
            const element = new elementCls().deserialize({
              type: options.element.type,
              params: params
            });

            if (resize.width) {
              width = (element as ResizableElementItem).width;
              (element as ResizableElementItem).width = undefined;
            }

            if (resize.height) {
              height = (element as ResizableElementItem).height;
              (element as ResizableElementItem).height = undefined;
            }

            if (options.element.init) {
              options.element.init(element);
            }

            generateElementNameInContext(element, this.viewContext, { name: element.defaultName() });

            const widthFluid = !resize.width;
            const heightFluid = !resize.height;

            this.editorContext.tool$.next(tool.tool);
            this.editorContext.toolElement$.next({
              element: element,
              width: width,
              widthFluid: widthFluid,
              height: height,
              heightFluid: heightFluid
            });
          });
      }
    }

    this.toolForFile = tool.toolFile ? tool : undefined;
  }

  onRootToolClick(tool: RootToolItem) {
    if (tool.toolElement && this.elementTemplatesLoading) {
      return;
    }

    this.onToolClick(tool);

    if (!tool.tool && this.defaultChildTool) {
      this.editorContext.tool$.next(this.defaultChildTool.tool);
    }

    if (tool.children || tool.toolElement) {
      this.openTool.emit();
    }
  }

  onToolFileChange(el: HTMLInputElement) {
    if (!el.files || !el.files.length) {
      return;
    }

    const file = el.files[0];

    el.value = null;

    const toolForFile = this.toolForFile;
    if (!toolForFile) {
      return;
    }

    const filePath = ['custom_views', this.editorContext.view$.value.id].join('/');
    const fileName = file.name;
    const fileUrl = URL.createObjectURL(file);

    this.toolFileUploading = toolForFile;
    this.toolForFile = undefined;
    this.cd.markForCheck();

    combineLatest(
      this.projectStorageService.uploadFile(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        file,
        filePath,
        fileName
      ),
      getImageSize(fileUrl).pipe(catchError(() => of(undefined)))
    )
      .pipe(untilDestroyed(this))
      .subscribe(
        ([response, imageSize]) => {
          if (response.result) {
            this.editorContext.tool$.next(toolForFile.tool);
            this.editorContext.toolFile$.next({
              fileName: getFilename(fileName),
              url: response.result.uploadedUrl,
              size: imageSize
            });

            this.toolForFile = undefined;
            this.toolFileUploading = undefined;
            this.cd.markForCheck();
          }
        },
        () => {
          this.toolFileUploading = undefined;
          this.cd.markForCheck();
        }
      );
  }

  getKeyChar(code: KeyboardEventKeyCode): string {
    const result = keyboardChars.find(item => item.code == code);
    return result ? result.char : undefined;
  }
}
