<td class="card-table__column">
  <div class="card-table__column-inner">
    <div class="square-icons">
      <div
        *ngFor="let item of stepsInfo"
        class="square-icon square-icons__item"
        [appTip]="item.labels?.join(' - ')"
        [appTipOptions]="{ side: 'top' }"
      >
        <div
          class="square-icon__inner"
          [class.square-icon__inner_background]="item.icon"
          [ngClass]="item.icon ? 'icon-' + item.icon : null"
          [style.background-image]="item.image ? 'url(' + item.image + ')' : null"
        ></div>
      </div>

      <div *ngIf="stepsMore > 0" class="square-icon square-icons__item">
        <div class="square-icon__inner square-icon__inner_text">+{{ stepsMore }}</div>
      </div>
    </div>
  </div>
</td>

<td class="card-table__column">
  <div class="card-table__column-inner">
    {{ item.name || 'Automation' }}
  </div>
</td>

<td class="card-table__column">
  <div class="card-table__column-inner">
    <div style="margin: -4px 0;">
      <span
        class="status"
        [ngClass]="{
          'color_blue_1 background-color_blue_2': item.lastRun?.status == statuses.Pending,
          'color_yellow_1 background-color_yellow_2': item.lastRun?.status == statuses.Running,
          'color_green_1 background-color_green_2': item.lastRun?.status == statuses.Succeeded,
          'color_red_1 background-color_red_2': item.lastRun?.status == statuses.Failed
        }"
      >
        {{ item.getStatusLabel() }}
      </span>

      <span *ngIf="item.lastRun?.getLastDate()" class="card-table__grey" style="margin-left: 8px;">
        {{ item.lastRun.getLastDate() | appFormatDate: 'lll' }}
      </span>
    </div>
  </div>
</td>

<td class="card-table__column">
  <div class="card-table__column-inner card-table__column-inner_right">
    <app-toggle
      *ngIf="item.trigger?.type !== triggerTypes.Manual"
      [label]="item.active ? 'ON' : 'OFF'"
      [selected]="item.active"
      [disabled]="loadingActive"
      [loading]="loadingActive"
      (click)="$event.stopPropagation(); toggleItemActive()"
      style="margin-right: 40px;"
    ></app-toggle>

    <span *ngIf="item.trigger" class="card-table__strong" style="margin-right: 40px;">
      <span [ngClass]="'icon-' + item.trigger.getTypeIcon()" style="margin-right: 4px;"></span>
      {{ item.trigger.getTypeStr() | appCapitalize }}
    </span>

    <mat-menu #dropdown="matMenu">
      <ng-template matMenuContent>
        <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" (click)="edit.emit()">
          <span class="icon-edit choose-items-item__dropdown-icon"></span> Edit automation
        </button>

        <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" (click)="duplicate.emit()">
          <span class="icon-documents choose-items-item__dropdown-icon"></span> Duplicate automation
        </button>

        <button mat-menu-item class="mat-menu-item-odd" [disabled]="loadingRun" [disableRipple]="true" (click)="run()">
          <span class="icon-play choose-items-item__dropdown-icon"></span>
          Run now
        </button>

        <button mat-menu-item class="mat-menu-item-odd mat-menu-item-red" [disableRipple]="true" (click)="delete()">
          <span class="icon-bin choose-items-item__dropdown-icon choose-items-item__dropdown-icon_red"></span> Delete
          automation
        </button>
      </ng-template>
    </mat-menu>

    <div class="icon-button2 icon-more" [matMenuTriggerFor]="dropdown" (click)="$event.stopPropagation()"></div>
  </div>
</td>
