var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import pickBy from 'lodash/pickBy';
import { Input } from '@modules/fields';
import { ViewSettingsAction } from '../../view-settings-action';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
export var AlertTint;
(function (AlertTint) {
    AlertTint["Default"] = "default";
    AlertTint["Info"] = "info";
    AlertTint["Danger"] = "danger";
    AlertTint["Warning"] = "warning";
    AlertTint["Success"] = "success";
    AlertTint["Special"] = "special";
})(AlertTint || (AlertTint = {}));
export var AlertStyle;
(function (AlertStyle) {
    AlertStyle["Fill"] = "fill";
    AlertStyle["Border"] = "border";
})(AlertStyle || (AlertStyle = {}));
var AlertElementItem = /** @class */ (function (_super) {
    __extends(AlertElementItem, _super);
    function AlertElementItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = ElementType.Alert;
        _this.tint = AlertTint.Default;
        _this.style = AlertStyle.Fill;
        _this.actions = [];
        return _this;
    }
    AlertElementItem.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.color = this.params['color'];
        this.icon = this.params['icon'];
        this.width = this.params['width'];
        this.height = this.params['height'];
        if (this.params['tint']) {
            this.tint = this.params['tint'];
        }
        if (this.params['style']) {
            this.style = this.params['style'];
        }
        if (this.params['title']) {
            this.title = new Input().deserialize(this.params['title']);
        }
        if (this.params['message']) {
            this.message = new Input().deserialize(this.params['message']);
        }
        if (this.params['actions']) {
            this.actions = this.params['actions'].map(function (item) { return new ViewSettingsAction().deserialize(item); });
        }
        return this;
    };
    AlertElementItem.prototype.serialize = function (fields) {
        this.params = {};
        this.params['tint'] = this.tint;
        this.params['color'] = this.color;
        this.params['style'] = this.style;
        this.params['title'] = this.title ? this.title.serialize() : null;
        this.params['message'] = this.message ? this.message.serialize() : null;
        this.params['icon'] = this.icon;
        this.params['width'] = this.width;
        this.params['height'] = this.height;
        this.params['actions'] = this.actions.map(function (item) { return item.serialize(); });
        var data = _super.prototype.serialize.call(this);
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Object.defineProperty(AlertElementItem.prototype, "typeStr", {
        get: function () {
            return 'alert';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AlertElementItem.prototype, "analyticsName", {
        get: function () {
            return 'alert';
        },
        enumerable: true,
        configurable: true
    });
    AlertElementItem.prototype.defaultName = function () {
        return 'Alert';
    };
    return AlertElementItem;
}(ElementItem));
export { AlertElementItem };
registerElementForType(ElementType.Alert, AlertElementItem);
