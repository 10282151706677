import pickBy from 'lodash/pickBy';

import {
  ChangeViewSettings,
  CustomViewSettings,
  ElementItem,
  FieldElementItem,
  getElementByType,
  ListElementItem,
  ListViewSettings,
  ViewSettings,
  ViewSettingsType,
  WidgetElementItem
} from '@modules/customize';
import { ChartWidget } from '@modules/dashboard';
import { deserializeMenuItem, getMenuItemByType, MenuItem } from '@modules/menu';
import { ModelDescription } from '@modules/models';
import { ResourceType, ResourceTypeItem, resourceTypeItems } from '@modules/projects';

export enum TemplateType {
  Page = 'page',
  Widget = 'widget',
  DefaultComponent = 'default_component',
  ResourceInitialModelDescriptions = 'resource_initial_model_descriptions',
  ResourceModelDescriptionsTemplate = 'resource_model_descriptions_template',
  ResourceDefaultModelDescription = 'resource_default_model_description'
}

export function defaultComponentTemplateName(element: ElementItem): string {
  if (element instanceof ListElementItem) {
    return [element.type, element.layouts.length ? element.layouts[0].type : ''].join('_');
  } else if (element instanceof WidgetElementItem) {
    if (element.widget instanceof ChartWidget) {
      return [
        element.type,
        element.widget ? element.widget.type : '',
        element.widget ? element.widget.chartType : ''
      ].join('_');
    } else {
      return [element.type, element.widget ? element.widget.type : ''].join('_');
    }
  } else if (element instanceof FieldElementItem) {
    return [element.type, element.settings.field].join('_');
  } else {
    return element.type;
  }
}

export class TemplateTag {
  public name: string;
  public color: string;

  deserialize(data: Object): TemplateTag {
    this.name = data['name'];
    this.color = data['color'];

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      name: this.name,
      color: this.color
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }
}

export class TemplateImage {
  public image: string;

  deserialize(data: Object): TemplateImage {
    this.image = data['image'];

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      image: this.image
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }
}

export interface TemplateUsedResource {
  token: string;
  type: ResourceType;
  typeItem: ResourceTypeItem;
  options?: any;
  models?: {
    modelDescription: ModelDescription;
    instances: Object[];
  }[];
}

export class Template {
  public id: number;
  public type: TemplateType;
  public uniqueName: string;
  public name: string;
  public subtitle: string;
  public description: string;
  public params = {};
  public logo: string;
  public logoFill = false;
  public icon: string;
  public color: string;
  public featured = false;
  public tags: TemplateTag[] = [];
  public images: TemplateImage[] = [];
  public forResources: { type: ResourceType; typeItem: ResourceTypeItem }[] = [];
  public usedResources: TemplateUsedResource[] = [];
  public usedPages: { token: string; uniqueName: string }[] = [];
  public element: ElementItem;
  public pages: ViewSettings[] = [];
  public menuItems: MenuItem[] = [];
  public modelDescriptions: ModelDescription[] = [];
  public modelDescription: ModelDescription;
  public models: Object[] = [];
  public forEmptyResources = false;
  public active = false;
  public ordering: number;

  deserialize(data: Object): Template {
    this.id = data['id'];
    this.type = data['type'];
    this.uniqueName = data['unique_name'];
    this.name = data['name'];
    this.description = data['description'];
    this.color = data['color'];
    this.icon = data['icon'];
    this.params = data['params'] || {};
    this.subtitle = this.params['subtitle'];
    this.logo = this.params['logo'];
    this.icon = this.params['icon'];
    this.color = this.params['color'];
    this.ordering = data['ordering'];

    if (data['active'] != undefined) {
      this.active = data['active'];
    }

    if (this.params['logo_fill'] != undefined) {
      this.logoFill = this.params['logo_fill'];
    }

    if (this.params['featured'] != undefined) {
      this.featured = this.params['featured'];
    }

    if (this.params['tags']) {
      this.tags = this.params['tags'].map(item => new TemplateTag().deserialize(item));
    }

    if (this.params['images']) {
      this.images = this.params['images'].map(item => new TemplateImage().deserialize(item));
    }

    if (this.params['for_resources']) {
      this.forResources = this.params['for_resources']
        .map(item => {
          return {
            type: item['type'],
            typeItem: resourceTypeItems.find(i => i.name == item['type_item'])
          };
        })
        .filter(item => item.typeItem != undefined);
    }

    if (this.params['used_resources']) {
      this.usedResources = this.params['used_resources']
        .map(item => {
          return {
            token: item['token'],
            type: item['type'],
            typeItem: resourceTypeItems.find(i => i.name == item['type_item']),
            options: item['options'],
            models: item['models']
              ? item['models'].map(model => {
                  return {
                    modelDescription: new ModelDescription().deserialize(model['model_description']),
                    instances: model['instances']
                  };
                })
              : undefined
          };
        })
        .filter(item => item.typeItem != undefined);
    }

    if (this.params['used_pages']) {
      this.usedPages = this.params['used_pages'].map(item => {
        return {
          token: item['token'],
          uniqueName: item['unique_name']
        };
      });
    }

    if (this.params['menu_items']) {
      this.menuItems = this.params['menu_items']
        .map(item => deserializeMenuItem(item))
        .filter(item => item != undefined);
    }

    // if (data['resource_type_items']) {
    //   this.resourceTypeItems = data['resource_type_items']
    //     .map(item => resourceTypeItems.find(i => i.name == item))
    //     .filter(item => item != undefined);
    // }

    if (this.params['pages']) {
      this.pages = this.params['pages'].map(item => {
        if (item['view'] == ViewSettingsType.Custom) {
          return new CustomViewSettings().deserialize(item);
        } else if (item['view'] == ViewSettingsType.List) {
          return new ListViewSettings().deserialize(item);
        } else if (item['view'] == ViewSettingsType.Change) {
          return new ChangeViewSettings().deserialize(item);
        } else {
          return new ViewSettings().deserialize(item);
        }
      });
    }

    if (this.params['element']) {
      const Element = getElementByType(this.params['element']['type']);
      this.element = new Element().deserialize(this.params['element']);
    }

    if (this.params['model_descriptions']) {
      this.modelDescriptions = this.params['model_descriptions'].map(item => new ModelDescription().deserialize(item));
    }

    if (this.params['model_description']) {
      this.modelDescription = new ModelDescription().deserialize(this.params['model_description']);
    }

    if (this.params['models']) {
      this.models = this.params['models'];
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = {};

    this.params['subtitle'] = this.subtitle;
    this.params['logo'] = this.logo;
    this.params['logo_fill'] = this.logoFill;
    this.params['icon'] = this.icon;
    this.params['color'] = this.color;
    this.params['featured'] = this.featured;
    this.params['tags'] = this.tags.map(item => item.serialize());
    this.params['images'] = this.images.map(item => item.serialize());

    this.params['for_resources'] = this.forResources.map(item => {
      return {
        type: item.type,
        type_item: item.typeItem.name
      };
    });

    this.params['used_resources'] = this.usedResources.map(item => {
      return {
        type: item.type,
        type_item: item.typeItem.name,
        token: item.token,
        options: item.options,
        models: item.models
          ? item.models.map(model => {
              return {
                model_description: model.modelDescription.serialize(),
                instances: model.instances
              };
            })
          : undefined
      };
    });

    this.params['used_pages'] = this.usedPages.map(item => {
      return {
        token: item.token,
        unique_name: item.uniqueName
      };
    });

    if (this.menuItems.length) {
      this.params['menu_items'] = this.menuItems.map(item => item.serialize());
    }

    if (this.pages.length) {
      this.params['pages'] = this.pages.map(item => item.serialize());
    }

    if (this.element) {
      this.params['element'] = this.element.serialize();
    }

    if (this.modelDescriptions) {
      this.params['model_descriptions'] = this.modelDescriptions.map(item => item.serialize());
    }

    if (this.modelDescription) {
      this.params['model_description'] = this.modelDescription.serialize(['fields']);
    }

    this.params['models'] = this.models;

    let data: Object = {
      id: this.id,
      type: this.type,
      unique_name: this.uniqueName,
      name: this.name,
      description: this.description,
      resource_type_items: [],
      // resource_type_items: this.resourceTypeItems.map(item => item.name),
      // resource_type_items: uniq(this.usedResources.map(item => item.typeItem.name)),
      params: this.params,
      active: this.active,
      ordering: this.ordering
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  get link() {
    return ['templates', this.id];
  }
}
