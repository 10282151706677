<div *ngIf="!view" class="grid__item-inner">
  <app-model-card
    [item]="item"
    [modelDescription]="modelDescription"
    [visibleColumns]="visibleColumns"
    [columns]="settings.dataSource?.columns || []"
    [columnActions]="settings.columnActions"
    [context]="context"
    [contextElement]="contextElement"
    (modelUpdated)="modelUpdated.next($event)"
  >
  </app-model-card>

  <div *ngIf="settings.modelActions.length" class="grid__item-buttons">
    <ng-container *ngFor="let action of settings.modelActions">
      <app-model-card-action
        class="grid__item-buttons-item"
        [model]="item.model"
        [action]="action"
        [context]="context"
        [contextElement]="contextElement"
        [accentColor]="accentColor"
        (click)="$event.stopPropagation()"
      >
      </app-model-card-action>
    </ng-container>
  </div>
</div>

<div *ngIf="view" class="grid__item-view" [style.height.px]="view.frame.height">
  <app-custom-element-view
    [view]="view"
    [parameters]="viewParameters"
    [actions]="settings.customActions"
    [params]="viewParams$ | async"
    [localContext]="localContext"
    [stateSelectedEnabled]="true"
    [stateSelected]="selected"
  ></app-custom-element-view>
</div>
