<form [formGroup]="form" (ngSubmit)="submit()">
  <app-customize-bar-header
    [title]="'Alert'"
    [titleEnabled]="titleEnabled"
    [backLabel]="'All components'"
    [deleteEnabled]="deleteEnabled"
    [parentLabel]="parentElement?.displayName || parentPopup?.displayName"
    (parentClick)="parentElement?.customize({ event: $event }) || parentPopup?.customize({ event: $event })"
    (deleteClick)="delete()"
  >
  </app-customize-bar-header>

  <div class="sidebar__content">
    <app-sidebar-tabs>
      <app-sidebar-tab [label]="'General'">
        <div class="sidebar__element">
          <app-sidebar-field [label]="'Color'">
            <app-sidebar-card>
              <div class="sidebar__element sidebar__element_padding_s sidebar__element_margin_xs">
                <app-color-options
                  [colors]="form.tintOptions"
                  [colorControl]="form.controls.tint"
                  [customColorEnabledControl]="form.controls.color_enabled"
                  [customColorControl]="form.controls.color"
                ></app-color-options>
              </div>
            </app-sidebar-card>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'style'">
            <div class="sidebar-image-toggle sidebar-image-toggle_cols_3">
              <div *ngFor="let item of form.styleOptions" class="sidebar-image-toggle__col">
                <a
                  href="javascript:void(0)"
                  class="sidebar-image-toggle__item"
                  [class.sidebar-image-toggle__item_active]="item.value === form.controls.style.value"
                  (click)="form.controls.style.patchValue(item.value)"
                >
                  <div
                    class="sidebar-image-toggle__item-inner"
                    [style.padding-top.%]="(42 / 90) * 100"
                    [style.background-image]="'/assets/images/alert/' + item.image + '.svg' | appDeployCssUrl"
                  ></div>
                </a>
              </div>
            </div>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-customize-bar-input-edit
            [label]="'Title'"
            [itemForm]="form.controls.title"
            [context]="context"
            [placeholder]="'Title'"
            [fill]="true"
            [analyticsSource]="element.analyticsGenericName + '_title'"
          ></app-customize-bar-input-edit>
        </div>

        <div class="sidebar__element">
          <app-customize-bar-input-edit
            [label]="'Message'"
            [itemForm]="form.controls.message"
            [context]="context"
            [staticValueParams]="{ multiline: true, rows: 3 }"
            [placeholder]="'Message'"
            [fill]="true"
            [analyticsSource]="element.analyticsGenericName + '_message'"
          ></app-customize-bar-input-edit>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'Icon'">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'icon',
                  label: 'Icon',
                  field: 'IconField',
                  params: { classes: ['select_fill'], allow_empty: true }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </div>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Display'">
        <div class="sidebar__element">
          <app-sidebar-field
            [label]="'Conditional Visibility'"
            [documentation]="'conditional-visibility'"
            [documentationLabel]="'See how it works'"
            [documentationSource]="element.analyticsGenericName + '_visible'"
          >
            <app-input-edit
              [itemForm]="form.controls.visible_input"
              [context]="context"
              [staticValueDisabled]="true"
              [placeholder]="'Value'"
              [fill]="true"
              [analyticsSource]="element.analyticsGenericName + '_visible'"
            ></app-input-edit>

            <ng-container description>
              Example: elements.field.value >= 10
            </ng-container>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'Spacing'">
            <app-margin-control [control]="form.controls.margin"></app-margin-control>
          </app-sidebar-field>
        </div>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Actions'" [error]="(actionsValid$ | async) !== false ? undefined : 'Not configured'">
        <app-sidebar-section>
          <div class="sidebar__list">
            <app-actions-edit
              [options]="{
                actionItemClass: viewSettingsActionClass,
                nameEditable: true,
                iconEditable: true,
                colorsEditable: true,
                approveEnabled: true,
                confirmationEnabled: true,
                completionEditable: true,
                disabledEditable: true,
                visibleEditable: true
              }"
              [control]="form.controls.actions"
              [context]="context"
              [emptyAction]="'Alert action'"
              [emptyActionValue]="'Not specified'"
              [emptyIcon]="'cursor'"
              [actionLabel]="'Alert action click'"
              [object]="element.analyticsGenericName + '_actions'"
              [firstInit]="firstInit"
            >
            </app-actions-edit>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>
    </app-sidebar-tabs>
  </div>
</form>
