export enum ActionType {
  Query = 'query',
  Download = 'download',
  Link = 'link',
  ExternalLink = 'external_link',
  ElementAction = 'element_action',
  ShowNotification = 'show_notification',
  SetProperty = 'set_property',
  RunJavaScript = 'run_javascript',
  CopyToClipboard = 'copy_to_clipboard',
  Export = 'export',
  Import = 'import',
  OpenPopup = 'open_popup',
  ClosePopup = 'close_popup',
  ScanCode = 'scan_code',
  Workflow = 'workflow'
}
