import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import { Power2, TweenMax } from 'gsap';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { DialogService } from '@common/dialogs';
import { ScrollService } from '@common/scroll';
import { SessionStorage } from '@core';
import { ActionControllerService } from '@modules/action-queries';
import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import {
  CustomizeCreatePopupOptions,
  CustomizeOpenPopupOptions,
  CustomizeService,
  CustomViewSettings,
  generateUidAndNamesRecursive,
  PopupSettings,
  ViewContext,
  ViewContextElement,
  ViewSettings
} from '@modules/customize';
import { CustomizeBarContext } from '@modules/customize-bar';
import { BUILDER_ADD_ELEMENT } from '@modules/customize-elements';
import { RoutingService } from '@modules/routing';
import { isSet, scrollTo } from '@shared';

import {
  CustomPagePopupComponent,
  isCustomPagePopupClickEvent
} from '../custom-page-popup/custom-page-popup.component';

export interface CustomizeDuplicatePopupOptions {
  open?: boolean;
  customize?: boolean;
  analyticsSource?: string;
}

@Component({
  selector: 'app-custom-page-popups',
  templateUrl: './custom-page-popups.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'custom_page_popups'
})
export class CustomPagePopupsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() viewSettings: CustomViewSettings;
  @Input() context: ViewContext;
  @Input() popupsContextElement: ViewContextElement;
  @Input() openedToolbar = false;
  @Output() closedToolbar = new EventEmitter<void>();

  @ViewChildren('popup_container_element', { read: ElementRef }) popupContainerElements = new QueryList<ElementRef>();
  @ViewChildren('popup_element', { read: ElementRef }) popupElements = new QueryList<ElementRef>();
  @ViewChildren('popup_overlay_element', { read: ElementRef }) popupOverlayElements = new QueryList<ElementRef>();
  @ViewChildren('popup_element') popupComponents = new QueryList<CustomPagePopupComponent>();

  openedPopupUid: string;
  mouseDownContainer = false;
  adminModes = AdminMode;
  analyticsSource = 'popups_toolbar';

  trackColumn = (() => {
    return (i, item: PopupSettings) => (isSet(item.uid) ? item.uid : item);
  })();

  constructor(
    @Inject(ROUTE_ADMIN_MODE) public mode: AdminMode,
    public customizeService: CustomizeService,
    private customizeBarContext: CustomizeBarContext,
    private actionControllerService: ActionControllerService,
    private sessionStorage: SessionStorage,
    private routing: RoutingService,
    private scrollService: ScrollService,
    private dialogService: DialogService,
    private injector: Injector,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {
    this.openedPopupUid = undefined;
    this.updateWindowScroll();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['viewSettings'] && this.openedPopupUid && (!this.viewSettings || !this.getPopup(this.openedPopupUid))) {
      this.openedPopupUid = undefined;
      this.updateWindowScroll();
    }
  }

  public hasOpenedPopup(): boolean {
    return isSet(this.openedPopupUid);
  }

  getPopup(uid: string): PopupSettings {
    return this.viewSettings.popups.find(item => item.uid == uid);
  }

  get openedPopup(): PopupSettings {
    if (!this.openedPopupUid) {
      return;
    }
    return this.getPopup(this.openedPopupUid);
  }

  openPopup(uid: string, options: CustomizeOpenPopupOptions = {}) {
    // Allow re-opening already opened popup (ex. for changing parameters)
    // if (this.openedPopupUid === uid) {
    //   return;
    // }

    const popup = this.getPopup(uid);

    if (!popup) {
      return;
    }

    this.openedPopupUid = uid;
    this.cd.detectChanges();

    const index = this.viewSettings.popups.indexOf(popup);

    if (index === -1) {
      return;
    }

    const element = this.popupElements.toArray()[index];
    const overlay = this.popupOverlayElements.toArray()[index];

    if (!element || !overlay) {
      return;
    }

    const popupComponent = this.popupComponents.find(item => item.popup === popup);

    if (popupComponent && options.params) {
      popupComponent.setContextOutputValues(options.params);
    }

    this.updateWindowScroll();
    this.scrollPopupToTop(index);

    popup.openActions.forEach(action => {
      this.actionControllerService
        .execute(action, {
          context: this.context,
          injector: this.injector
        })
        .subscribe();
    });

    if (options.openComponents && this.customizeService.enabled) {
      this.customizeBarContext.resetSettingsComponent();
    } else if (options.customize && this.customizeService.enabled) {
      if (popupComponent) {
        popupComponent.customize();
      }
    }

    TweenMax.fromTo(
      overlay.nativeElement,
      0.15,
      {
        opacity: 0
      },
      {
        opacity: 1,
        ease: Power2.easeOut
      }
    );

    if (popup.isSide()) {
      TweenMax.fromTo(
        element.nativeElement,
        0.6,
        {
          xPercent: 100
        },
        {
          xPercent: 0,
          ease: Power2.easeOut
        }
      );
    } else {
      TweenMax.fromTo(
        element.nativeElement,
        0.15,
        {
          y: -10,
          opacity: 0
        },
        {
          y: 0,
          opacity: 1
        }
      );
    }
  }

  scrollPopupToTop(index: number) {
    const popup = this.viewSettings.popups[index];
    if (!popup) {
      return;
    }

    const scrollableElement = popup.isSide()
      ? this.popupElements.toArray()[index]
      : this.popupContainerElements.toArray()[index];

    if (!scrollableElement) {
      return;
    }

    scrollTo(scrollableElement.nativeElement, 0);
  }

  createPopup(open = false, options: CustomizeCreatePopupOptions = {}): PopupSettings {
    const defaultName = 'Modal';
    let i = 1;
    let newName: string;

    do {
      newName = i > 1 ? [defaultName, i].join(' ') : defaultName;
      ++i;
    } while (this.viewSettings.popups.find(item => item.name.toLowerCase() == newName.toLowerCase()));

    const popup = new PopupSettings();

    popup.generateUid();
    popup.name = newName;

    if (options.width) {
      popup.width = options.width;
    }

    if (options.style) {
      popup.style = options.style;
    }

    if (options.position) {
      popup.position = options.position;
    }

    this.viewSettings.popups = [...this.viewSettings.popups, popup];
    this.customizeService.markChanged();
    this.cd.markForCheck();

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Modal.Created, {
      Source: options.analyticsSource
    });

    if (open) {
      this.openPopup(popup.uid, { openComponents: true });
    }

    return popup;
  }

  createPopupFromToolbar(open = false, options: CustomizeCreatePopupOptions = {}): PopupSettings {
    return this.createPopup(open, { analyticsSource: this.analyticsSource, ...options });
  }

  duplicatePopup(uid: string, options: CustomizeDuplicatePopupOptions): PopupSettings {
    const existingPopup = this.getPopup(uid);

    if (!existingPopup) {
      return;
    }

    const defaultName = `${existingPopup.name} Copy`;
    let i = 1;
    let newName: string;

    do {
      newName = i > 1 ? [defaultName, i].join(' ') : defaultName;
      ++i;
    } while (this.viewSettings.popups.find(item => item.name.toLowerCase() == newName.toLowerCase()));

    const popup = new PopupSettings();

    popup.generateUid();
    popup.patch(existingPopup);
    popup.name = newName;
    popup.elements = cloneDeep(existingPopup.elements);

    generateUidAndNamesRecursive(popup.elements, this.context, { forceNewUid: true });

    this.viewSettings.popups = [...this.viewSettings.popups, popup];
    this.customizeService.markChanged();
    this.cd.markForCheck();

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Modal.Duplicated, {
      Source: options.analyticsSource
    });

    if (options.open) {
      this.openPopup(popup.uid, options.customize ? { customize: true } : { openComponents: true });
    }

    return popup;
  }

  duplicatePopupFromToolbar(uid: string, options: CustomizeDuplicatePopupOptions): PopupSettings {
    return this.duplicatePopup(uid, { analyticsSource: this.analyticsSource, ...options });
  }

  moveToPage(uid: string, page: ViewSettings, options: CustomizeDuplicatePopupOptions): PopupSettings {
    const existingPopup = this.getPopup(uid);

    if (!existingPopup) {
      return;
    }

    this.sessionStorage.set(
      BUILDER_ADD_ELEMENT,
      JSON.stringify({
        popup: existingPopup.serialize()
      })
    );

    this.deletePopupProcess(uid);

    this.customizeService.stopTrackChanges();
    this.customizeService
      .saveActualChanges()
      .pipe(untilDestroyed(this))
      .subscribe(() => this.routing.navigateApp(page.link));

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Modal.MovedToPage, {
      Source: options.analyticsSource
    });
  }

  moveToPageFromToolbar(uid: string, page: ViewSettings, options: CustomizeDuplicatePopupOptions): PopupSettings {
    return this.moveToPage(uid, page, { analyticsSource: this.analyticsSource, ...options });
  }

  deletePopup(uid?: string, force = false) {
    if (force) {
      this.deletePopupProcess(uid);
      return;
    }

    this.dialogService
      .warning({
        title: 'Deleting',
        description: `Are you sure want to delete Modal?`,
        style: 'orange'
      })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (!result) {
          return;
        }

        this.deletePopupProcess(uid);
      });
  }

  deletePopupProcess(uid: string) {
    this.closePopup(uid, false);

    const popup = this.getPopup(uid);

    if (!popup) {
      return;
    }

    this.viewSettings.popups = this.viewSettings.popups.filter(item => item !== popup);
    this.customizeService.markChanged();
    this.cd.markForCheck();
  }

  closePopup(uid?: string, animate = true) {
    const popup = this.openedPopup;
    if (!popup) {
      return;
    }

    if (uid && popup.uid != uid) {
      return;
    }

    const index = this.viewSettings.popups.indexOf(popup);

    if (index === -1) {
      this.onPopupClosed(popup);
      return;
    }

    const element = this.popupElements.toArray()[index];
    const overlay = this.popupOverlayElements.toArray()[index];

    if (!element || !overlay) {
      return;
    }

    popup.closeActions.forEach(action => {
      this.actionControllerService
        .execute(action, {
          context: this.context,
          injector: this.injector
        })
        .subscribe();
    });

    if (animate) {
      TweenMax.to(overlay.nativeElement, 0.2, {
        opacity: 0
      });

      if (this.customizeService.enabled) {
        TweenMax.to(element.nativeElement, 0.4, {
          x: -1 * 0.5 * window.innerWidth + 400,
          y: -1 * 0.5 * window.innerHeight - 50,
          scale: 0,
          opacity: 0,
          clearProps: 'transform,opacity',
          ease: Power2.easeIn,
          onComplete: () => this.onPopupClosed(popup)
        });
      } else {
        if (popup.isSide()) {
          TweenMax.to(element.nativeElement, 0.4, {
            xPercent: 100,
            ease: Power2.easeOut,
            onComplete: () => this.onPopupClosed(popup)
          });
        } else {
          TweenMax.to(element.nativeElement, 0.15, {
            opacity: 0,
            y: 10,
            onComplete: () => this.onPopupClosed(popup)
          });
        }
      }
    } else {
      this.onPopupClosed(popup);
    }
  }

  updatePopup(uid: string, newPopup: PopupSettings) {
    const popup = this.getPopup(uid);
    const popupComponent = popup ? this.popupComponents.find(item => item.popup === popup) : undefined;

    if (!popupComponent) {
      return;
    }

    popupComponent.onCustomized(newPopup);
  }

  updateWindowScroll() {
    if (this.openedPopup && this.openedPopup.overlay) {
      this.scrollService.disableWindowScroll();
    } else {
      this.scrollService.enableWindowScroll();
    }
  }

  onPopupClosed(popup: PopupSettings) {
    if (this.openedPopupUid != popup.uid) {
      return;
    }

    this.openedPopupUid = undefined;
    this.cd.markForCheck();

    this.updateWindowScroll();
  }

  onPopupContainerClicked(item: PopupSettings) {
    const closeEnabled = this.customizeService.enabled ? true : item.closeOnBlur;

    if (this.mouseDownContainer && closeEnabled) {
      this.closePopup();
    }
  }

  onPopupContainerMouseDown(event: MouseEvent) {
    this.mouseDownContainer = !isCustomPagePopupClickEvent(event);
  }
}
