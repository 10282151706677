var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AlignHorizontal, ElementType, FIELD_ELEMENT_VERSION } from '@modules/customize';
import { FieldType, getFieldDescriptionByType } from '@modules/fields';
import { isSet } from '@shared';
function mapField(item) {
    var fieldDescription = getFieldDescriptionByType(item.field);
    return {
        title: item.label || fieldDescription.label,
        icon: fieldDescription.icon,
        image: fieldDescription.image,
        action: "Add " + fieldDescription.label,
        type: ElementType.Field,
        defaultParams: __assign({ settings: __assign({ field: item.field, verboseName: fieldDescription.label, editable: true }, (item.fieldParams || {})), v: FIELD_ELEMENT_VERSION }, (item.params || {})),
        postCreate: function (element) {
            if (isSet(element.name)) {
                element.settings.verboseName = element.name;
            }
            return element;
        }
    };
}
export var changeCustomizeBarComponentsForms = [
    {
        title: 'Form',
        image: 'form',
        action: 'Add Form',
        type: ElementType.Form,
        defaultParams: {
            title: 'Form'
        }
    },
    {
        title: 'Detail',
        image: 'detail',
        action: 'Add Detail',
        type: ElementType.Model,
        defaultParams: {
            title: 'Detail'
        }
    }
].concat([
    {
        field: FieldType.Text,
        label: 'Text Field',
        params: {},
        fieldParams: {}
    },
    {
        field: FieldType.RichText,
        label: 'Rich Text Field',
        params: {},
        fieldParams: {}
    },
    {
        field: FieldType.Boolean,
        params: {},
        fieldParams: {}
    },
    {
        field: FieldType.RelatedModel,
        params: {},
        fieldParams: {
            resetEnabled: true
        }
    },
    {
        field: FieldType.Select,
        params: {},
        fieldParams: {
            resetEnabled: true
        }
    },
    {
        field: FieldType.MultipleSelect,
        params: {},
        fieldParams: {
            resetEnabled: true
        }
    },
    {
        field: FieldType.RadioButton,
        params: {},
        fieldParams: {}
    },
    {
        field: FieldType.DateTime,
        params: {},
        fieldParams: {}
    },
    // {
    //   field: FieldType.Date,
    //   params: {},
    //   fieldParams: {}
    // },
    {
        field: FieldType.Time,
        params: {},
        fieldParams: {}
    },
    {
        field: FieldType.File,
        label: 'File Picker',
        params: {},
        fieldParams: {}
    },
    {
        field: FieldType.Image,
        label: 'Image Picker',
        params: {},
        fieldParams: {}
    },
    {
        field: FieldType.Password,
        params: {},
        fieldParams: {}
    },
    {
        field: FieldType.Number,
        params: {},
        fieldParams: {}
    },
    {
        field: FieldType.Rating,
        params: {},
        fieldParams: {}
    },
    {
        field: FieldType.Slider,
        params: {},
        fieldParams: {}
    }
].map(function (item) { return mapField(item); }), [
    {
        title: 'Range Slider',
        image: 'range_slider_field',
        action: 'Add Range Slider',
        type: ElementType.RangeSlider,
        defaultParams: {
            verbose_name: 'Range Slider'
        },
        postCreate: function (element) {
            if (isSet(element.name)) {
                element.verboseName = element.name;
            }
            return element;
        }
    }
], [
    {
        field: FieldType.URL,
        params: {},
        fieldParams: {}
    },
    {
        field: FieldType.Location,
        params: {},
        fieldParams: {}
    },
    {
        field: FieldType.Video,
        label: 'Video Picker',
        params: {},
        fieldParams: {}
    },
    {
        field: FieldType.Audio,
        label: 'Audio Picker',
        params: {},
        fieldParams: {}
    },
    {
        field: FieldType.Signature,
        params: {
            width: 400,
            height: 200,
            align_horizontal: AlignHorizontal.Left
        },
        fieldParams: {}
    },
    {
        field: FieldType.Color,
        params: {},
        fieldParams: {}
    },
    {
        field: FieldType.JSON,
        params: {},
        fieldParams: {}
    }
].map(function (item) { return mapField(item); }));
