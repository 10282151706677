import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationExtras, Router, RouterState, UrlTree } from '@angular/router';
import isArray from 'lodash/isArray';
import toPairs from 'lodash/toPairs';

import { AdminMode } from '@modules/admin-mode';
import { versionLessThan } from '@modules/api';
import { containsTree, isSet, Link, openUrl } from '@shared';

const ROUTER_ATTRS_KEY = '_attrs';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {
  constructor(private router: Router, private location: Location) {}

  ensureRouterAttrs() {
    this.router[ROUTER_ATTRS_KEY] = this.router[ROUTER_ATTRS_KEY] || {};
  }

  setRouterAttr(name: string, value: any) {
    this.ensureRouterAttrs();
    this.router[ROUTER_ATTRS_KEY][name] = value;
  }

  popRouterAttr(name: string): any {
    this.ensureRouterAttrs();

    if (this.router[ROUTER_ATTRS_KEY].hasOwnProperty(name)) {
      const value = this.router[ROUTER_ATTRS_KEY][name];
      delete this.router[ROUTER_ATTRS_KEY][name];
      return value;
    }
  }

  get routerState(): RouterState {
    return this.router.routerState;
  }

  appLink(commands: any[], options: { projectName?: string; environmentName?: string; modeName?: AdminMode } = {}) {
    if (!commands) {
      return;
    }

    const version = window['version'];
    let mode = options.modeName || window['mode'];

    if (version && versionLessThan(version, '2.1.0')) {
      mode = AdminMode.App;
    }

    const project = options.projectName || window['project'];
    const environment = options.environmentName || window['project_environment'];
    const prefix = version ? [`/v${version}`, mode] : [`/${mode}`];
    return [...prefix, project, environment, ...commands];
  }

  buildURL(href: string, options: { queryParams?: Object; fragment?: string } = {}): string {
    const result = [href];

    const queryParamPairs = options.queryParams ? toPairs(options.queryParams) : undefined;
    if (queryParamPairs && queryParamPairs.length) {
      const queryParamsStr = queryParamPairs.map(([k, v]) => [k, encodeURIComponent(v as string)].join('=')).join('&');
      result.push(`?${queryParamsStr}`);
    }

    if (isSet(options.fragment)) {
      result.push(`#${options.fragment}`);
    }

    return result.join('');
  }

  getRouteWithNested(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot[] {
    return [
      route,
      ...route.children.reduce((acc, item) => {
        acc.push(...this.getRouteWithNested(item));
        return acc;
      }, [])
    ];
  }

  getRouteUrl(route: ActivatedRouteSnapshot): any[] {
    return this.getRouteWithNested(route).reduce((acc, item) => {
      acc.push(...item.url.map(segment => segment.path));
      return acc;
    }, []);
  }

  navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
    return this.router.navigate(commands, extras);
  }

  navigateApp(
    commands: any[],
    extras?: NavigationExtras,
    options: { projectName?: string; environmentName?: string; modeName?: AdminMode } = {}
  ): Promise<boolean> {
    return this.router.navigate(this.appLink(commands, options), extras);
  }

  navigateCurrent(extras?: NavigationExtras): Promise<boolean> {
    return this.router.navigate([], extras);
  }

  navigateRoute(route: ActivatedRouteSnapshot, extras?: NavigationExtras): Promise<boolean> {
    const commands = this.getRouteUrl(route);
    return this.navigate(commands, extras);
  }

  navigateLink(link: Link, extras?: NavigationExtras) {
    if (link.link) {
      this.navigate(link.link, extras);
    } else {
      const url = this.buildURL(link.href, {
        ...(extras && {
          queryParams: extras.queryParams,
          fragment: extras.fragment
        })
      });

      openUrl(url);
    }
  }

  navigateLinkApp(
    link: Link,
    extras?: NavigationExtras,
    options: { projectName?: string; environmentName?: string; modeName?: AdminMode } = {}
  ) {
    if (link.link) {
      this.navigateApp(link.link, extras, options);
    } else {
      const url = this.buildURL(link.href, {
        ...(extras && {
          queryParams: extras.queryParams,
          fragment: extras.fragment
        })
      });

      openUrl(url);
    }
  }

  createUrlTree(commands: any[], navigationExtras?: NavigationExtras): UrlTree {
    return this.router.createUrlTree(commands, navigationExtras);
  }

  createUrlTreeApp(
    commands: any[],
    navigationExtras?: NavigationExtras,
    options: { projectName?: string; environmentName?: string; modeName?: AdminMode } = {}
  ): UrlTree {
    return this.router.createUrlTree(this.appLink(commands, options), navigationExtras);
  }

  serializeUrl(url: UrlTree) {
    return this.router.serializeUrl(url);
  }

  navigateByUrl(url: string | UrlTree, extras?: NavigationExtras): Promise<boolean> {
    return this.router.navigateByUrl(url, extras);
  }

  replaceUrl(commands: any[], navigationExtras?: NavigationExtras) {
    const url = this.serializeUrl(this.createUrlTree(commands, navigationExtras));
    this.location.replaceState(url);
  }

  replaceUrlApp(commands: any[], navigationExtras?: NavigationExtras) {
    const url = this.serializeUrl(this.createUrlTreeApp(commands, navigationExtras));
    this.location.replaceState(url);
  }

  isRouterLinkActive(route: any[], exact = false) {
    const currentUrlTree = this.router.parseUrl(this.router.url);
    const routeUrlTree = this.router.createUrlTree(isArray ? route : [route]);
    return containsTree(currentUrlTree, routeUrlTree, exact);
  }

  isAppLinkActive(route: any[], exact = false) {
    const currentUrlTree = this.router.parseUrl(this.router.url);
    const routeLink = this.appLink(isArray ? route : [route]);
    const routeUrlTree = this.router.createUrlTree(routeLink);
    return containsTree(currentUrlTree, routeUrlTree, exact);
  }

  navigateUnauthenticated(route: ActivatedRouteSnapshot) {
    const queryParams = {};
    const url = this.getRouteUrl(route);
    const redirect = encodeURIComponent(
      JSON.stringify({
        url: url,
        params: route.queryParams
      })
    );
    const referrer = route.queryParams['referrer'];
    const registered = route.queryParams['registered'];

    if (redirect) {
      queryParams['redirect'] = redirect;
    }

    if (referrer) {
      queryParams['referrer'] = referrer;
    }

    if (route.data['registerWithoutAuthenticated'] && !registered) {
      this.navigate(['/register'], { queryParams: queryParams });
    } else {
      this.navigate(['/login'], { queryParams: queryParams });
    }
  }
}
