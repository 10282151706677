import { Injectable, Injector } from '@angular/core';
import isArray from 'lodash/isArray';
import keys from 'lodash/keys';
import { Option } from 'ng-gxselect';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { SelectSource } from '@common/select';
import { ITEM_OUTPUT, ViewContext, ViewContextElement } from '@modules/customize';
import { DataSourceType, ListModelDescriptionDataSource } from '@modules/data-sources';
import { ModelDescriptionDataSourceService } from '@modules/data-sources-queries';
import {
  applyParamInput,
  applyParamInput$,
  ComputedDisplayField,
  DisplayField,
  DisplayFieldType,
  Input,
  ParameterField
} from '@modules/fields';
import { ModelDescriptionStore, ModelService, ModelUtilsService } from '@modules/model-queries';
import { getDefaultValue, Model, ORDER_BY_PARAM, PER_PAGE_PARAM, SEARCH_PARAM } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ListModelDescriptionQuery, ModelDescriptionQuery } from '@modules/queries';
import { paramsToGetQueryOptions, ResourceControllerService } from '@modules/resources';
import { EMPTY, isSet } from '@shared';

import { ModelListStore } from './model-list.store';

@Injectable()
export class ModelSelectSource extends SelectSource {
  initialized = false;

  private listStore: ModelListStore;
  private resource: string;
  private query: ListModelDescriptionQuery;
  private queryParameters: ParameterField[] = [];
  private detailQuery: ModelDescriptionQuery;
  private detailQueryParameters: ParameterField[] = [];
  private columns: DisplayField[];
  private params = {};
  private sortingField: string;
  private sortingAsc: boolean;
  private valueField: string;
  private nameField: string;
  private nameInput: Input;
  private subtitleField: string;
  private subtitleInput: Input;
  private iconField: string;
  private iconInput: Input;
  private multiple = false;
  private context: ViewContext;
  private contextElement: ViewContextElement;

  constructor(
    private modelService: ModelService,
    private modelDescriptionStore: ModelDescriptionStore,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private injector: Injector,
    private modelUtilsService: ModelUtilsService
  ) {
    super();
    this.listStore = this.createModelListStore();
  }

  createModelListStore(): ModelListStore {
    return Injector.create({
      providers: [
        {
          provide: ModelListStore,
          deps: [
            ModelService,
            CurrentProjectStore,
            CurrentEnvironmentStore,
            ModelDescriptionStore,
            ModelDescriptionDataSourceService,
            ResourceControllerService
          ]
        }
      ],
      parent: this.injector
    }).get<ModelListStore>(ModelListStore);
  }

  init(params: {
    resource?: string;
    query?: ListModelDescriptionQuery;
    queryParameters?: ParameterField[];
    detailQuery?: ModelDescriptionQuery;
    detailQueryParameters?: ParameterField[];
    columns?: DisplayField[];
    params?: Object;
    sortingField?: string;
    sortingAsc?: boolean;
    valueField?: string;
    nameField?: string;
    nameInput?: Input;
    subtitleField?: string;
    subtitleInput?: Input;
    iconField?: string;
    iconInput?: Input;
    multiple?: boolean;
    context?: ViewContext;
    contextElement?: ViewContextElement;
  }) {
    this.listStore.dataSource = new ListModelDescriptionDataSource();
    this.listStore.dataSource.type = DataSourceType.Query;
    this.listStore.queryOptions = {};

    if (params.resource) {
      this.resource = this.listStore.dataSource.queryResource = params.resource;
    }

    if (params.query) {
      this.query = this.listStore.dataSource.query = params.query;
    }

    if (params.queryParameters) {
      this.queryParameters = this.listStore.dataSource.queryParameters = params.queryParameters;
    }

    if (params.detailQuery) {
      this.detailQuery = params.detailQuery;
    }

    if (params.detailQueryParameters) {
      this.detailQueryParameters = params.detailQueryParameters;
    }

    if (params.columns) {
      this.columns = this.listStore.dataSource.columns = params.columns;
    }

    const listStoreParams = {
      ...params.params,
      ...(isSet(params.sortingField) && {
        [ORDER_BY_PARAM]:
          isSet(params.sortingAsc) && !params.sortingAsc ? `-${params.sortingField}` : params.sortingField
      })
    };

    if (keys(listStoreParams)) {
      this.params = this.listStore.params = listStoreParams;
      this.listStore.queryOptions = paramsToGetQueryOptions(listStoreParams);

      if (listStoreParams[PER_PAGE_PARAM]) {
        this.listStore.perPage = listStoreParams[PER_PAGE_PARAM];
      }
    }

    if (isSet(params.valueField)) {
      this.valueField = params.valueField;
    } else {
      this.valueField = 'id';
    }

    if (isSet(params.nameField)) {
      this.nameField = params.nameField;
    }

    if (isSet(params.nameInput)) {
      this.nameInput = params.nameInput;
    }

    if (isSet(params.subtitleField)) {
      this.subtitleField = params.subtitleField;
    }

    if (isSet(params.subtitleInput)) {
      this.subtitleInput = params.subtitleInput;
    }

    if (isSet(params.iconField)) {
      this.iconField = params.iconField;
    }

    if (isSet(params.iconInput)) {
      this.iconInput = params.iconInput;
    }

    if (params.multiple) {
      this.multiple = params.multiple;
    }

    if (params.context) {
      this.context = params.context;
    }

    if (params.contextElement) {
      this.contextElement = params.contextElement;
    }

    this.initialized = true;
  }

  hasName() {
    return isSet(this.nameField) || isSet(this.nameInput);
  }

  deserializeModelAttributes(items: Model[]) {
    if (!items) {
      return;
    }

    return items.map(item => {
      // item.deserializeAttributes(
      //   this.dataSource.columns.filter(column => column.type != DisplayFieldType.Computed)
      // );

      if (this.columns) {
        this.columns
          .filter(column => column instanceof ComputedDisplayField)
          .forEach((column: ComputedDisplayField) => {
            const value = this.resolveFlexItemValue(column, item);
            item.setAttribute(column.name, value);
          });
      }

      return item;
    });
  }

  resolveFlexItemValue(column: ComputedDisplayField, model: Model) {
    if (column.valueInput) {
      try {
        const value = applyParamInput(column.valueInput, {
          context: this.context,
          contextElement: this.contextElement,
          localContext: {
            [ITEM_OUTPUT]: model.getAttributes()
          }
          // field: { field: column.field, params: column.params }
        });

        if (value !== EMPTY) {
          return value;
        }
      } catch (e) {}
    }

    return getDefaultValue(column);
  }

  fetch(searchQuery: string): Observable<Option[]> {
    const params = {
      ...this.params
    };

    if (isSet(searchQuery)) {
      params[SEARCH_PARAM] = searchQuery;
    }

    this.listStore.params = params;

    if (this.listStore.queryOptions) {
      this.listStore.queryOptions.search = searchQuery;
    }

    return this.listStore.getNext().pipe(
      map(result => {
        if (!result) {
          return;
        }

        return this.deserializeModelAttributes(result);
      }),
      switchMap(result => {
        if (!result || !result.length) {
          return of([]);
        }

        return combineLatest(
          ...result.map(item => {
            let value: any;
            let name$: Observable<string>;
            let subtitle$: Observable<string>;
            let icon$: Observable<string>;

            if (isSet(this.valueField)) {
              value = item.hasAttribute(this.valueField)
                ? item.getAttribute(this.valueField)
                : item.getRawAttribute(this.valueField);
            } else if (item.modelDescription) {
              value = item.primaryKey;
            }

            if (this.nameInput) {
              name$ = applyParamInput$(this.nameInput, {
                localContext: {
                  item: item.getAttributes()
                }
              });
            } else if (isSet(this.nameField)) {
              name$ = of(
                item.hasAttribute(this.nameField)
                  ? item.getAttribute(this.nameField)
                  : item.getRawAttribute(this.nameField)
              );
            } else if (item.modelDescription) {
              name$ = this.modelUtilsService.str(item);
            } else {
              name$ = of(undefined);
            }

            if (this.subtitleInput) {
              subtitle$ = applyParamInput$(this.subtitleInput, {
                localContext: {
                  item: item.getAttributes()
                }
              });
            } else if (isSet(this.subtitleField)) {
              subtitle$ = of(
                item.hasAttribute(this.subtitleField)
                  ? item.getAttribute(this.subtitleField)
                  : item.getRawAttribute(this.subtitleField)
              );
            } else {
              subtitle$ = of(undefined);
            }

            if (this.iconInput) {
              icon$ = applyParamInput$(this.iconInput, {
                localContext: {
                  item: item.getAttributes()
                }
              });
            } else if (isSet(this.iconField)) {
              icon$ = of(this.iconField);
            } else {
              icon$ = of(undefined);
            }

            return combineLatest(name$, subtitle$, icon$).pipe(
              map(([name, subtitle, icon]) => {
                if (this.multiple && isArray(value)) {
                  return value.map(valueItem => {
                    if (isSet(this.valueField) && !this.nameInput && this.valueField == this.nameField) {
                      return this.getOption({
                        value: valueItem,
                        name: valueItem,
                        icon: icon,
                        subtitle: subtitle
                      });
                    } else {
                      return this.getOption({
                        value: valueItem,
                        name: name,
                        icon: icon,
                        subtitle: subtitle
                      });
                    }
                  });
                } else {
                  return [
                    this.getOption({
                      value: value,
                      name: name,
                      icon: icon,
                      subtitle: subtitle
                    })
                  ];
                }
              })
            );
          })
        ).pipe(
          map<Option[][], Option[]>(options => {
            return options.reduce((acc, item) => {
              acc.push(...item);
              return acc;
            }, []);
          })
        );
      })
    );
  }

  getOption(options: { value: any; name: string; icon?: string; subtitle?: string }): Option {
    return {
      value: options.value,
      name: options.name,
      image: options.icon,
      data: {
        subtitle: options.subtitle
      }
    };
  }

  fetchByValue(value: any | any[]): Observable<Option | Option[]> {
    if (!value) {
      return of(this.multiple ? [] : undefined);
    } else if (isArray(value) && !value.length) {
      return of(this.multiple ? [] : undefined);
    }

    const arrayValues: any[] = this.multiple && isArray(value) ? value : [value];
    const resource = this.currentEnvironmentStore.resources.find(item => item.uniqueName == this.resource);
    let obs: Observable<Model[]>;

    if (this.detailQuery) {
      obs = combineLatest(
        arrayValues.map(item => {
          const params = { ...this.params, [this.valueField]: item };
          return this.modelService.getDetailQuery(
            this.currentProjectStore.instance,
            this.currentEnvironmentStore.instance,
            resource,
            this.detailQuery,
            this.detailQueryParameters,
            params,
            this.columns
          );
        })
      );
    } else {
      const params = {
        ...this.params,
        ...(this.multiple ? { [`${this.valueField}__in`]: arrayValues } : { [this.valueField]: value })
      };
      obs = this.modelService
        .getQuery(
          this.currentProjectStore.instance,
          this.currentEnvironmentStore.instance,
          resource,
          this.query,
          this.queryParameters,
          params,
          (this.columns || []).filter(item => item.type != DisplayFieldType.Computed)
        )
        .pipe(
          map(result => {
            if (!result) {
              return [];
            }

            return result.results.filter(item => {
              const itemField = item.modelDescription && !this.valueField ? item.primaryKey : this.valueField;
              const itemFieldValue = isSet(itemField) ? item.getAttribute(itemField) : undefined;

              return !!arrayValues.find(i => {
                if (this.multiple && isArray(itemFieldValue)) {
                  return itemFieldValue.includes(i);
                } else {
                  return i == itemFieldValue;
                }
              });
            });
          })
        );
    }

    return obs.pipe(
      map(result => {
        if (!result) {
          return;
        }

        return this.deserializeModelAttributes(result);
      }),
      switchMap<Model[], Option[]>(items => {
        if (!items) {
          return of(
            arrayValues.map(v => ({
              value: v,
              name: v
            }))
          );
        }

        if (!items.length) {
          return of([]);
        }

        return combineLatest(
          items.map(item => {
            let itemValue: any;
            let name$: Observable<string>;
            let subtitle$: Observable<string>;
            let icon$: Observable<string>;

            if (isSet(this.valueField)) {
              itemValue = item.hasAttribute(this.valueField)
                ? item.getAttribute(this.valueField)
                : item.getRawAttribute(this.valueField);
            } else if (item.modelDescription) {
              itemValue = item.primaryKey;
            }

            if (this.nameInput) {
              name$ = applyParamInput$(this.nameInput, {
                localContext: {
                  item: item.getAttributes()
                }
              });
            } else if (isSet(this.nameField)) {
              name$ = of(
                item.hasAttribute(this.nameField)
                  ? item.getAttribute(this.nameField)
                  : item.getRawAttribute(this.nameField)
              );
            } else if (item.modelDescription) {
              name$ = this.modelUtilsService.str(item);
            } else {
              name$ = of(undefined);
            }

            if (this.subtitleInput) {
              subtitle$ = applyParamInput$(this.subtitleInput, {
                localContext: {
                  item: item.getAttributes()
                }
              });
            } else if (isSet(this.subtitleField)) {
              subtitle$ = of(
                item.hasAttribute(this.subtitleField)
                  ? item.getAttribute(this.subtitleField)
                  : item.getRawAttribute(this.subtitleField)
              );
            } else {
              subtitle$ = of(undefined);
            }

            if (this.iconInput) {
              icon$ = applyParamInput$(this.iconInput, {
                localContext: {
                  item: item.getAttributes()
                }
              });
            } else if (isSet(this.iconField)) {
              icon$ = of(this.iconField);
            } else {
              icon$ = of(undefined);
            }

            return combineLatest(name$, subtitle$, icon$).pipe(
              map(([name, subtitle, icon]) => {
                if (this.multiple && isArray(itemValue)) {
                  return itemValue.map(valueItem => {
                    if (isSet(this.valueField) && !this.nameInput && this.valueField == this.nameField) {
                      return this.getOption({
                        value: valueItem,
                        name: valueItem,
                        icon: icon,
                        subtitle: subtitle
                      });
                    } else {
                      return this.getOption({
                        value: valueItem,
                        name: name,
                        icon: icon,
                        subtitle: subtitle
                      });
                    }
                  });
                } else {
                  return [
                    this.getOption({
                      value: itemValue,
                      name: name,
                      icon: icon,
                      subtitle: subtitle
                    })
                  ];
                }
              })
            );
          })
        ).pipe(
          map<Option[][], Option[]>(options => {
            return options.reduce((acc, item) => {
              acc.push(...item);
              return acc;
            }, []);
          }),
          map(options => {
            return arrayValues
              .map(arrayValue => options.find(item => item.value == arrayValue))
              .filter(option => option);
          })
        );
      }),
      map(options => {
        if (this.multiple) {
          return options;
        } else {
          return options[0];
        }
      })
    );
  }

  isFetchAvailable(): boolean {
    return this.listStore.hasMore;
  }

  resetPagination() {
    this.listStore.reset();
  }

  setStaticOptions(options: Option[]) {}
}
