var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, OnChanges, OnInit } from '@angular/core';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { map } from 'rxjs/operators';
import { ActionService } from '@modules/action-queries';
import { ActionType } from '@modules/actions';
import { HTTP_BODY_OUTPUT, HTTP_CODE_OUTPUT, HTTP_STATUS_OUTPUT, SUBMIT_ERROR_OUTPUT, SUBMIT_RESULT_OUTPUT, ViewContextElement } from '@modules/customize';
import { FieldType } from '@modules/fields';
import { CurrentEnvironmentStore } from '@modules/projects';
import { QueryService } from '@modules/queries';
import { WorkflowStepType } from '@modules/workflow';
import { isSet } from '@shared';
import { registerWorkflowStepComponent } from '../../../../data/workflow-step-components';
import { WorkflowEditContext } from '../../../../services/workflow-edit-context/workflow-edit.context';
import { WorkflowStepComponent } from '../base-workflow-step/base-workflow-step.component';
function getStepStateContextAction(state) {
    return {
        type: state.type,
        icon: state.icon,
        action: state.actionDescription ? state.actionDescription.serialize() : undefined,
        inputs: state.action && state.action.inputs ? state.action.inputs.map(function (item) { return item.serialize(); }) : undefined
    };
}
function getStepStateContextInfo(state) {
    return {
        name: state.name,
        type: state.type,
        icon: state.actionDescription ? state.actionDescription.typeIcon : undefined
    };
}
function getStepStateContextOutputs(state) {
    return {
        name: state.name,
        actionDescription: state.actionDescription ? state.actionDescription.serialize() : undefined
    };
}
var ActionWorkflowStepComponent = /** @class */ (function (_super) {
    __extends(ActionWorkflowStepComponent, _super);
    function ActionWorkflowStepComponent(actionService, queryService, currentEnvironmentStore, workflowStepsContextElement, contextElement, workflowEditContext, cd) {
        var _this = _super.call(this, contextElement, workflowEditContext, cd) || this;
        _this.actionService = actionService;
        _this.queryService = queryService;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.workflowStepsContextElement = workflowStepsContextElement;
        _this.contextSubmitResultInit = false;
        _this.loading = true;
        _this.defaultTitle = 'Not specified';
        _this.titles = [];
        return _this;
    }
    ActionWorkflowStepComponent.prototype.ngOnInit = function () {
        this.initContext();
        this.trackRun();
        this.trackExecuteStatus();
        this.stepOnChange(this.step);
        this.trackChanges();
        this.workflowEditContext.updateCreatedElement(this.step, { contextElement: this.contextElement });
    };
    ActionWorkflowStepComponent.prototype.ngOnChanges = function (changes) {
        if (changes.step) {
            if (!changes.step.firstChange) {
                this.stepOnChange(this.step);
            }
        }
    };
    ActionWorkflowStepComponent.prototype.getStepState = function (step) {
        var _this = this;
        return this.actionService.getActionDescription(step.action).pipe(map(function (actionDescription) {
            var resource = actionDescription
                ? _this.currentEnvironmentStore.resources.find(function (i) { return i.uniqueName == actionDescription.resource; })
                : undefined;
            return {
                step: step,
                name: step ? step.name : undefined,
                icon: step ? step.getIcon() : undefined,
                type: step ? step.type : undefined,
                action: step ? step.action : undefined,
                actionDescription: actionDescription,
                resource: resource
            };
        }));
    };
    ActionWorkflowStepComponent.prototype.onStateUpdated = function (state) {
        if (!isEqual(getStepStateContextAction(state), getStepStateContextAction(this.state))) {
            this.updateAction(state);
        }
        if (!isEqual(getStepStateContextInfo(state), getStepStateContextInfo(this.state))) {
            this.updateContextInfo(state);
        }
        if (!isEqual(getStepStateContextOutputs(state), getStepStateContextOutputs(this.state))) {
            this.updateContextOutputs(state);
        }
    };
    ActionWorkflowStepComponent.prototype.initContext = function () {
        var _this = this;
        this.actionService
            .getActionDescription(this.step.action)
            .pipe(untilDestroyed(this))
            .subscribe(function (actionDescription) {
            _this.contextElement.initGlobal({
                uniqueName: _this.step.uid,
                name: _this.step.name || _this.step.type,
                icon: actionDescription ? actionDescription.typeIcon : 'power',
                // allowSkip: true,
                getFieldValue: function (field, outputs) {
                    return outputs[field];
                }
            }, _this.workflowStepsContextElement);
            if (actionDescription &&
                actionDescription.type == ActionType.Query &&
                actionDescription.queryAction &&
                actionDescription.queryAction.query) {
                var response = _this.queryService.getResponseProcessed(actionDescription.queryAction.query);
                if (isSet(response)) {
                    _this.contextElement.setOutputValue(SUBMIT_RESULT_OUTPUT, response);
                }
            }
        });
    };
    ActionWorkflowStepComponent.prototype.updateAction = function (state) {
        this.updateDisplayIcon(state);
        this.updateTitles(state);
        this.loading = false;
        this.cd.markForCheck();
    };
    ActionWorkflowStepComponent.prototype.updateDisplayIcon = function (state) {
        this.displayIcon = undefined;
        this.displayImage = undefined;
        if (state.resource) {
            this.displayImage = state.resource.icon;
        }
        else if (isSet(state.icon)) {
            this.displayIcon = state.icon;
        }
        this.cd.markForCheck();
    };
    ActionWorkflowStepComponent.prototype.updateTitles = function (state) {
        var _this = this;
        if (this.titlesSubscription) {
            this.titlesSubscription.unsubscribe();
            this.titlesSubscription = undefined;
        }
        this.titlesSubscription = this.actionService
            .getActionDescriptionLabel(state.actionDescription, state.action ? state.action.inputs : [], this.context, this.contextElement)
            .pipe(untilDestroyed(this))
            .subscribe(function (actionDescriptionLabel) {
            _this.titles = (isSet(actionDescriptionLabel) && state.actionDescription
                ? actionDescriptionLabel.filter(function (item) { return isSet(item) && item != state.actionDescription.typeStr; })
                : []).concat([
                _this.defaultTitle
            ]);
            _this.loading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    ActionWorkflowStepComponent.prototype.updateContextInfo = function (state) {
        this.contextElement.initInfo({
            name: state.name || state.type,
            icon: state.actionDescription ? state.actionDescription.typeIcon : 'power'
        });
    };
    ActionWorkflowStepComponent.prototype.updateContextOutputs = function (state) {
        var _this = this;
        this.actionService
            .getActionDescriptionOutputs(state.actionDescription)
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (isEqual(result, _this.outputsState)) {
                return;
            }
            _this.outputsState = result;
            _this.contextElement.setOutputs([
                __assign({ uniqueName: SUBMIT_RESULT_OUTPUT, name: 'Operation Result', icon: 'components', fieldType: FieldType.JSON, external: true }, (result.outputs.length &&
                    !result.arrayOutput && {
                    children: result.outputs.map(function (item) {
                        return {
                            uniqueName: item.name,
                            name: item.verboseName,
                            icon: item.fieldDescription.icon,
                            fieldType: item.field,
                            fieldParams: item.params
                        };
                    })
                })),
                {
                    uniqueName: SUBMIT_ERROR_OUTPUT,
                    name: 'Operation Error',
                    icon: 'alert',
                    fieldType: FieldType.Text
                },
                {
                    uniqueName: HTTP_BODY_OUTPUT,
                    name: 'HTTP data',
                    icon: 'components',
                    fieldType: FieldType.JSON
                },
                {
                    uniqueName: HTTP_CODE_OUTPUT,
                    name: 'HTTP code',
                    icon: 'number',
                    fieldType: FieldType.Number
                },
                {
                    uniqueName: HTTP_STATUS_OUTPUT,
                    name: 'HTTP message',
                    icon: 'comments',
                    fieldType: FieldType.Text
                }
            ]);
        });
    };
    ActionWorkflowStepComponent.prototype.onRunUpdated = function (event) {
        if (this.contextElement) {
            var result = event.success ? event.result : event.error;
            this.contextElement.setOutputValue(SUBMIT_RESULT_OUTPUT, result);
        }
    };
    return ActionWorkflowStepComponent;
}(WorkflowStepComponent));
export { ActionWorkflowStepComponent };
registerWorkflowStepComponent(WorkflowStepType.Action, ActionWorkflowStepComponent);
