import pickBy from 'lodash/pickBy';

import { Input } from '@modules/fields';

import { AlignHorizontal } from '../../align-horizontal';
import { TintStyle, ViewSettingsAction } from '../../view-settings-action';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';

export class ActionDropdownElementItem extends ElementItem {
  public type = ElementType.ActionDropdown;
  public alignHorizontalDefault = AlignHorizontal.Left;

  public actionItems: ViewSettingsAction[] = [];
  public verboseNameInput: Input;
  public icon: string;
  public style: TintStyle = TintStyle.Primary;
  public tint: string;
  public disabledInput: Input;
  public tooltip: string;

  deserialize(data: Object): ActionDropdownElementItem {
    super.deserialize(data);
    this.tooltip = this.params['tooltip'];

    if (this.params['verbose_name_input']) {
      this.verboseNameInput = new Input().deserialize(this.params['verbose_name_input']);
    } else if (data['verbose_name']) {
      // Backward compatibility
      this.verboseNameInput = new Input().deserializeFromStatic('value', data['verbose_name']);
    } else if (this.params['verbose_name']) {
      // Backward compatibility
      this.verboseNameInput = new Input().deserializeFromStatic('value', this.params['verbose_name']);
    }

    // Backward compatibility
    if (data['icon']) {
      this.icon = data['icon'];
    } else {
      this.icon = this.params['icon'];
    }

    if (this.params['action_items']) {
      this.actionItems = this.params['action_items'].map(item => new ViewSettingsAction().deserialize(item));
    }

    if (this.params['style'] != undefined) {
      this.style = this.params['style'];
    }

    if (this.style == TintStyle.Default && this.params['color_text']) {
      // Backward compatibility
      this.tint = this.params['color_text'];
    } else if (this.style == TintStyle.Primary && this.params['color']) {
      // Backward compatibility
      this.tint = this.params['color'];
    } else if (this.style == TintStyle.Transparent && this.params['color_text']) {
      // Backward compatibility
      this.tint = this.params['color_text'];
    } else {
      this.tint = this.params['tint'];
    }

    if (this.params['disabled_input']) {
      this.disabledInput = new Input().deserialize(this.params['disabled_input']);
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = {};
    this.params['verbose_name_input'] = this.verboseNameInput ? this.verboseNameInput.serialize() : null;
    this.params['icon'] = this.icon;
    this.params['action_items'] = this.actionItems.map(item => item.serialize());
    this.params['style'] = this.style;
    this.params['tint'] = this.tint;
    this.params['disabled_input'] = this.disabledInput ? this.disabledInput.serialize() : null;
    this.params['tooltip'] = this.tooltip;

    let data = super.serialize();
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  get typeStr(): string {
    return 'trigger operations or transitions';
  }

  get analyticsName(): string {
    return 'action_dropdown';
  }

  defaultName() {
    return 'Actions';
  }
}

registerElementForType(ElementType.ActionDropdown, ActionDropdownElementItem);
