<button
  *ngIf="visible || (customizeEnabled$ | async)"
  mat-menu-item
  [disabled]="disabled || submitLoading"
  [disableRipple]="true"
  style="position: relative;"
  (click)="executeAction(item)"
>
  <app-loader-small *ngIf="submitLoading" class="mat-menu-item-icon"></app-loader-small>
  <ng-container *ngIf="!submitLoading">
    <span *ngIf="item.icon" class="mat-menu-item-icon" [ngClass]="'icon-' + item.icon"></span>
    <span *ngIf="!item.icon" class="mat-menu-item-icon icon-power"></span>
  </ng-container>

  {{ verboseName | appCapitalize }}

  <span
    *ngIf="!visible && (customizeEnabled$ | async)"
    class="element__indicator icon-eye"
    [appTip]="'Component is hidden'"
  ></span>
</button>
