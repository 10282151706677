import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import clone from 'lodash/clone';
import { Observable, of } from 'rxjs';

import { FormSubmitElementItem, TintStyle } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Input, Input as FieldInput, InputValueType } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';

import { MarginControl } from '../margin-control/margin-control.component';

const defaultVerboseName = {
  name: 'value',
  value_type: InputValueType.StaticValue,
  static_value: 'Button'
};

@Injectable()
export class CustomizeBarFormSubmitEditForm {
  element: FormSubmitElementItem;
  form = new FormGroup({
    icon: new FormControl(null),
    style: new FormControl(TintStyle.Primary),
    tint: new FormControl(null),
    verbose_name: new FieldInputControl(defaultVerboseName),
    disabled_input: new FieldInputControl({ name: 'value' }),
    visible_input: new FieldInputControl({ name: 'value' }),
    margin: new MarginControl()
  });

  styleOptions = [
    {
      value: TintStyle.Primary,
      image: 'button-primary'
    },
    {
      value: TintStyle.Default,
      image: 'button-default'
    },
    {
      value: TintStyle.Transparent,
      image: 'button-transparent'
    }
  ];

  constructor(private elementConfigurationService: ElementConfigurationService) {}

  init(element: FormSubmitElementItem, firstInit = false) {
    this.element = element;

    const value = {
      icon: element.icon,
      style: element.style,
      tint: element.tint,
      verbose_name: element.verboseNameInput ? element.verboseNameInput.serialize() : defaultVerboseName,
      disabled_input: element.disabledInput ? element.disabledInput.serialize() : {},
      visible_input: element.visibleInput ? element.visibleInput.serialize() : {},
      margin: element.margin
    };

    this.form.patchValue(value, { emitEvent: false });

    if (!firstInit) {
      this.form.markAsDirty();
    }
  }

  isConfigured(instance: FormSubmitElementItem): Observable<boolean> {
    //   return this.elementConfigurationService.isActionDropdownConfigured(instance, { restrictDemo: true });
    return of(true);
  }

  submit(): FormSubmitElementItem {
    const value = this.form.value;
    const instance = clone(this.element);

    instance.icon = value['icon'];
    instance.style = value['style'];
    instance.tint = value['tint'];
    instance.verboseNameInput = value['verbose_name'] ? new FieldInput().deserialize(value['verbose_name']) : undefined;

    if (value['disabled_input']) {
      instance.disabledInput = new Input().deserialize(value['disabled_input']);
    } else {
      instance.disabledInput = undefined;
    }

    if (value['visible_input']) {
      instance.visibleInput = new Input().deserialize(value['visible_input']);
    } else {
      instance.visibleInput = undefined;
    }

    instance.margin = value['margin'];

    return instance;
  }
}
