<ng-template let-menuBlocks="menuBlocks" let-horizontal="horizontal" #menu>
  <div
    *ngIf="asMenuBlockItems(menuBlocks) as menuBlocks"
    class="menu2 theme-enabled"
    [class.menu2_horizontal]="horizontal"
    appThemeOptions
    [accentColor]="
      settingsForm.controls.accent_color_custom_enabled.value
        ? settingsForm.controls.accent_color_custom.value
        : settingsForm.controls.accent_color.value
    "
    [fontRegular]="settingsForm.controls.font_regular.value"
    [fontHeading]="settingsForm.controls.font_heading.value"
  >
    <ng-container *ngFor="let block of menuBlocks; trackBy: trackMenuBlockItemFn">
      <div
        *ngIf="block.primary"
        class="menu2__side"
        [class.menu2__side_clickable]="blockControlActive !== block.control"
        [style]="block.style"
        (mouseenter)="blockControlPreviewHover$.next(block.control)"
        (mouseleave)="blockControlPreviewHover$.next(undefined)"
        (click)="blockControlPreviewClick.next({ control: block.control, event: $event })"
      >
        <div
          class="menu2-side menu2-block"
          [class.menu2-side_light]="block.isLight === true"
          [class.menu2-side_dark]="block.isLight === false"
          [class.menu2-side_unset]="!(block.block.accentColor | appIsSet)"
          [class.menu2-block_light]="block.isLight === true"
          [class.menu2-block_dark]="block.isLight === false"
          [class.menu2-block_unset]="!(block.block.accentColor | appIsSet)"
          [class.menu2-side_disabled]="blockControlFocus && blockControlFocus !== block.control"
          [class.menu2-side_non-interactive]="blockControlActive !== block.control"
          [style.background-color]="(block.block.accentColor | appIsColorHex) ? block.block.accentColor : null"
          [ngClass]="
            !(block.block.accentColor | appIsColorHex) ? 'background-color_' + block.block.accentColor + '_1' : null
          "
        >
          <div class="menu2-side__start">
            <app-auto-menu-item
              *ngFor="let item of block.block.startItems; trackBy: trackMenuItemFn"
              [menuItem]="item"
              [menuBlock]="block.block"
              [primary]="true"
              [horizontal]="horizontal"
              [forceActive]="item === block.firstMenuItem"
            >
            </app-auto-menu-item>
          </div>

          <div class="menu2-side__main">
            <app-auto-menu-item
              *ngFor="let item of block.block.centerItems; trackBy: trackMenuItemFn"
              [menuItem]="item"
              [menuBlock]="block.block"
              [primary]="true"
              [horizontal]="horizontal"
              [forceActive]="item === block.firstMenuItem"
            >
            </app-auto-menu-item>
          </div>

          <div class="menu2-side__end">
            <app-auto-menu-item
              *ngFor="let item of block.block.endItems; trackBy: trackMenuItemFn"
              [menuItem]="item"
              [menuBlock]="block.block"
              [primary]="true"
              [horizontal]="horizontal"
              [forceActive]="item === block.firstMenuItem"
            >
            </app-auto-menu-item>
          </div>
        </div>
      </div>

      <div
        *ngIf="!block.primary"
        class="menu2__main"
        [class.menu2__main_clickable]="blockControlActive !== block.control"
        [style]="block.style"
        (mouseenter)="blockControlPreviewHover$.next(block.control)"
        (mouseleave)="blockControlPreviewHover$.next(undefined)"
        (click)="blockControlPreviewClick.next({ control: block.control, event: $event })"
      >
        <div
          class="menu2-main menu2-block"
          [class.menu2-main_light]="block.isLight === true"
          [class.menu2-main_dark]="block.isLight === false"
          [class.menu2-main_unset]="!(block.block.accentColor | appIsSet)"
          [class.menu2-block_light]="block.isLight === true"
          [class.menu2-block_dark]="block.isLight === false"
          [class.menu2-block_unset]="!(block.block.accentColor | appIsSet)"
          [class.menu2-side_disabled]="blockControlFocus && blockControlFocus !== block.control"
          [class.menu2-main_non-interactive]="blockControlActive !== block.control"
          [style.background-color]="(block.block.accentColor | appIsColorHex) ? block.block.accentColor : null"
          [ngClass]="
            !(block.block.accentColor | appIsColorHex) ? 'background-color_' + block.block.accentColor + '_1' : null
          "
        >
          <div
            *ngIf="!horizontal"
            class="menu2-main__viewport"
            xsScrollable
            [xsScrollableOptions]="{ stopPropagationAlways: true }"
          >
            <div class="menu2-main__content menu2-main__containers">
              <div class="menu2-main__start">
                <app-auto-menu-item
                  *ngFor="let item of block.block.startItems; trackBy: trackMenuItemFn"
                  [menuItem]="item"
                  [menuBlock]="block.block"
                  [primary]="false"
                  [horizontal]="horizontal"
                  [forceActive]="item === block.firstMenuItem"
                >
                </app-auto-menu-item>
              </div>

              <div class="menu2-main__main">
                <app-auto-menu-item
                  *ngFor="let item of block.block.centerItems; trackBy: trackMenuItemFn"
                  [menuItem]="item"
                  [menuBlock]="block.block"
                  [primary]="false"
                  [horizontal]="horizontal"
                  [forceActive]="item === block.firstMenuItem"
                >
                </app-auto-menu-item>
              </div>

              <div class="menu2-main__end">
                <app-auto-menu-item
                  *ngFor="let item of block.block.endItems; trackBy: trackMenuItemFn"
                  [menuItem]="item"
                  [menuBlock]="block.block"
                  [primary]="false"
                  [horizontal]="horizontal"
                  [forceActive]="item === block.firstMenuItem"
                >
                </app-auto-menu-item>
              </div>
            </div>
          </div>

          <div *ngIf="horizontal" class="menu2-main__content menu2-main__containers">
            <div class="menu2-main__start">
              <app-auto-menu-item
                *ngFor="let item of block.block.startItems; trackBy: trackMenuItemFn"
                [menuItem]="item"
                [menuBlock]="block.block"
                [primary]="false"
                [horizontal]="horizontal"
                [forceActive]="item === block.firstMenuItem"
              >
              </app-auto-menu-item>
            </div>

            <div class="menu2-main__main">
              <app-auto-menu-item
                *ngFor="let item of block.block.centerItems; trackBy: trackMenuItemFn"
                [menuItem]="item"
                [menuBlock]="block.block"
                [primary]="false"
                [horizontal]="horizontal"
                [forceActive]="item === block.firstMenuItem"
              >
              </app-auto-menu-item>
            </div>

            <div class="menu2-main__end">
              <app-auto-menu-item
                *ngFor="let item of block.block.endItems; trackBy: trackMenuItemFn"
                [menuItem]="item"
                [menuBlock]="block.block"
                [primary]="false"
                [horizontal]="horizontal"
                [forceActive]="item === block.firstMenuItem"
              >
              </app-auto-menu-item>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<div class="admin-template">
  <div class="admin-template__vertical">
    <div *ngIf="topMenuBlocks.length" class="admin-template__vertical-top">
      <ng-container *ngTemplateOutlet="menu; context: { menuBlocks: topMenuBlocks, horizontal: true }"></ng-container>
    </div>

    <div class="admin-template__vertical-main">
      <div class="admin-template__horizontal">
        <div *ngIf="leftMenuBlocks.length" class="admin-template__horizontal-left">
          <ng-container
            *ngTemplateOutlet="menu; context: { menuBlocks: leftMenuBlocks, horizontal: false }"
          ></ng-container>
        </div>

        <div class="admin-template__horizontal-main">
          <div class="admin-template__vertical">
            <div *ngIf="topContentMenuBlocks.length" class="admin-template__vertical-top">
              <ng-container
                *ngTemplateOutlet="menu; context: { menuBlocks: topContentMenuBlocks, horizontal: true }"
              ></ng-container>
            </div>

            <div
              class="admin-template__vertical-main admin-template__main"
              [class.admin-template__main_disabled]="contentDisabled"
            >
              <!--              <div *ngIf="!topMenuBlocks.length" class="admin-header">-->
              <!--                <div class="admin-header__inner">-->
              <!--                  <div class="admin-header__main">-->
              <!--                    <span class="admin-header__title admin-header__item">Page Title</span>-->
              <!--                  </div>-->
              <!--  -->
              <!--                  <div class="admin-header__right">-->
              <!--                    <ng-container *ngIf="settingsForm.controls.collaboration_enabled.value">-->
              <!--                      <div class="user-photos user-photos_small user-photos_clickable user-photos_background_body admin-header__user-photos">-->
              <!--                        <a class="user-photos__item"></a>-->
              <!--                        <a class="user-photos__item"></a>-->
              <!--                        <a class="user-photos__item"></a>-->
              <!--                        <a class="user-photos__item"></a>-->
              <!--                        <a class="user-photos__item"></a>-->
              <!--                        <a class="user-photos__add icon-plus"></a>-->
              <!--                      </div>-->
              <!--  -->
              <!--                      <a href="javascript:void(0)" class="admin-header-link admin-header__item">-->
              <!--                        <span class="admin-header-link__icon icon-chat"></span> Collaborations-->
              <!--                      </a>-->
              <!--                    </ng-container>-->
              <!--  -->
              <!--                    <a-->
              <!--                      *ngIf="settingsForm.controls.activity_log_enabled.value"-->
              <!--                      href="javascript:void(0)"-->
              <!--                      class="admin-header-link admin-header__item"-->
              <!--                    >-->
              <!--                      <span class="admin-header-link__icon icon-book"></span>Activity Log-->
              <!--                    </a>-->
              <!--  -->
              <!--                    &lt;!&ndash;                <a&ndash;&gt;-->
              <!--                    &lt;!&ndash;                  *ngIf="!projectLayouts.hasMenuPrimary(settingsForm.controls.layout.value)"&ndash;&gt;-->
              <!--                    &lt;!&ndash;                  href="javascript:void(0)"&ndash;&gt;-->
              <!--                    &lt;!&ndash;                  class="admin-header__item"&ndash;&gt;-->
              <!--                    &lt;!&ndash;                >&ndash;&gt;-->
              <!--                    &lt;!&ndash;                  <div class="admin-header__button admin-header__button_orange icon-brush"></div>&ndash;&gt;-->
              <!--                    &lt;!&ndash;                </a>&ndash;&gt;-->
              <!--  -->
              <!--                    &lt;!&ndash;                <a&ndash;&gt;-->
              <!--                    &lt;!&ndash;                  *ngIf="!projectLayouts.hasMenuPrimary(settingsForm.controls.layout.value)"&ndash;&gt;-->
              <!--                    &lt;!&ndash;                  href="javascript:void(0)"&ndash;&gt;-->
              <!--                    &lt;!&ndash;                  class="admin-header__item admin-header__profile"&ndash;&gt;-->
              <!--                    &lt;!&ndash;                  [style.background-image]="&ndash;&gt;-->
              <!--                    &lt;!&ndash;              (currentUserStore.instance$ | async)?.photo&ndash;&gt;-->
              <!--                    &lt;!&ndash;                ? 'url(' + (currentUserStore.instance$ | async)?.photo + ')'&ndash;&gt;-->
              <!--                    &lt;!&ndash;                : ''&ndash;&gt;-->
              <!--                    &lt;!&ndash;            "&ndash;&gt;-->
              <!--                    &lt;!&ndash;                ></a>&ndash;&gt;-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->

              <app-external-fonts [fonts]="externalFonts"></app-external-fonts>

              <div
                class="theme-enabled"
                appThemeOptions
                [accentColor]="
                  settingsForm.controls.accent_color_custom_enabled.value
                    ? settingsForm.controls.accent_color_custom.value
                    : settingsForm.controls.accent_color.value
                "
                [fontRegular]="settingsForm.controls.font_regular.value"
                [fontHeading]="settingsForm.controls.font_heading.value"
              >
                <app-element-wrapper>
                  <div class="element element_padding-h_normal element_padding-v_normal">
                    <div class="element__content">
                      <div class="text-block text-block_no-margin-collapse">
                        <div class="ql-container ql-snow ql-editor">
                          <h1>Page title</h1>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Dolor sed viverra ipsum nunc aliquet bibendum enim. In massa
                            tempor nec feugiat. Nunc aliquet bibendum enim facilisis gravida. Nisl nunc mi ipsum
                            faucibus vitae aliquet nec ullamcorper. Amet luctus venenatis lectus magna fringilla.
                            Volutpat maecenas volutpat blandit aliquam etiam erat velit scelerisque in.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </app-element-wrapper>

                <app-element-wrapper>
                  <div class="element element_padding-h_normal element_padding-v_normal">
                    <span class="element__content">
                      <div class="steps">
                        <div class="steps__progress steps-progress steps-progress_disable-animations">
                          <div class="steps-progress__track">
                            <div class="steps-progress__track-item"></div>
                            <div class="steps-progress__track-item"></div>
                            <div class="steps-progress__track-item"></div>
                            <div class="steps-progress__track-item"></div>
                          </div>
                          <div
                            *ngIf="
                              ('accentColor' | appThemeColorHexOption | async) || defaultAccentColor as accentColor
                            "
                            class="steps-progress__fill-container"
                          >
                            <div class="steps-progress__fill-start" [style.background-color]="accentColor"></div>
                            <div
                              class="steps-progress__fill"
                              [style.width.%]="stepsProgress * 100"
                              [style.background-color]="accentColor"
                            >
                              <div class="steps-progress__fill-thumb" [style.border-color]="accentColor"></div>
                            </div>
                          </div>
                        </div>
                        <div class="steps__items">
                          <div class="steps__item">
                            <div class="steps__item-info">
                              <div class="steps__item-title">Ordered</div>
                            </div>
                          </div>
                          <div class="steps__item steps__item_current">
                            <div class="steps__item-info">
                              <div class="steps__item-title">Packed</div>
                            </div>
                          </div>
                          <div class="steps__item steps__item_current">
                            <div class="steps__item-info">
                              <div class="steps__item-title">Shipped</div>
                            </div>
                          </div>
                          <div class="steps__item steps__item_active">
                            <div class="steps__item-info">
                              <div class="steps__item-title">Delivered</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                </app-element-wrapper>

                <app-element-wrapper>
                  <div class="tabs2">
                    <div class="element-wrapper">
                      <div class="element-wrapper__inner">
                        <div class="tabs2-navigation tabs2-navigation_border">
                          <div class="tabs2-navigation__item tabs2-navigation__item_active">
                            General
                          </div>
                          <div class="tabs2-navigation__item">
                            Metrics
                          </div>
                          <div class="tabs2-navigation__item">
                            Activity
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="tabs2__item tabs2__item_active">
                      <app-element-wrapper>
                        <div class="element element_padding-h_normal element_padding-v_normal">
                          <div class="element__content">
                            <app-table-stub
                              [title]="'Example data'"
                              [action]="true"
                              [selection]="true"
                              [animating]="false"
                              [scrollable]="false"
                            ></app-table-stub>
                          </div>
                        </div>
                      </app-element-wrapper>
                    </div>
                  </div>
                </app-element-wrapper>

                <app-element-wrapper>
                  <div class="element element_padding-h_normal element_padding-v_normal">
                    <div class="element__content">
                      <app-table-stub [animating]="false" [scrollable]="false"></app-table-stub>
                    </div>
                  </div>
                </app-element-wrapper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
