var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, Injector, OnInit, Provider } from '@angular/core';
import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { ACTION_CONTROLLER_PROMPT_COMPONENT, ActionControllerService, ActionService } from '@modules/action-queries';
import { TintStyle } from '@modules/customize';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { BaseActionExecuteComponent } from '../base-action-execute/base-action-execute.component';
import { BaseActionExecuteForm } from '../base-action-execute/base-action-execute.form';
export var ACTION_CONTROLLER_PROMPT_COMPONENT_PROVIDER = {
    provide: ACTION_CONTROLLER_PROMPT_COMPONENT,
    useFactory: actionControllerPromptComponentFactory
};
export function actionControllerPromptComponentFactory() {
    return ActionExecutePopupComponent;
}
var ActionExecutePopupComponent = /** @class */ (function (_super) {
    __extends(ActionExecutePopupComponent, _super);
    function ActionExecutePopupComponent(currentProjectStore, currentEnvironmentStore, popupComponent, actionService, actionControllerService, form, notificationService, injector, cd) {
        var _this = _super.call(this, currentProjectStore, currentEnvironmentStore, actionService, actionControllerService, form, notificationService, injector, cd) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.popupComponent = popupComponent;
        _this.actionService = actionService;
        _this.actionControllerService = actionControllerService;
        _this.form = form;
        _this.notificationService = notificationService;
        _this.injector = injector;
        _this.cd = cd;
        _this.tintStyles = TintStyle;
        return _this;
    }
    ActionExecutePopupComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        if (!this.actionParams.length) {
            this.execute();
        }
    };
    ActionExecutePopupComponent.prototype.close = function () {
        if (this.popupComponent) {
            this.popupComponent.close();
        }
    };
    ActionExecutePopupComponent.prototype.cancel = function () {
        this.cancelled.emit();
        this.close();
    };
    ActionExecutePopupComponent.prototype.onSubmitCompleted = function (successResult, result) {
        _super.prototype.onSubmitCompleted.call(this, successResult, result);
        if (successResult === true) {
            this.close();
        }
    };
    return ActionExecutePopupComponent;
}(BaseActionExecuteComponent));
export { ActionExecutePopupComponent };
