/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/components/loader-small/loader-small.component.ngfactory";
import * as i2 from "../../../../shared/components/loader-small/loader-small.component";
import * as i3 from "@angular/common";
import * as i4 from "../../../../common/tips/directives/tip/tip.directive";
import * as i5 from "../../../theme-components/pipes/theme-color-hex-option/theme-color-hex-option.pipe";
import * as i6 from "../../../theme-components/data/theme-context";
import * as i7 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i8 from "../../../colors-shared/directives/button-tint-color/button-tint-color.directive";
import * as i9 from "@angular/platform-browser";
import * as i10 from "./model-card-item-action.component";
import * as i11 from "../../../customize/services/customize/customize.service";
import * as i12 from "../../../action-queries/services/action/action.service";
import * as i13 from "../../../action-queries/services/action-controller/action-controller.service";
import * as i14 from "../../../../common/notifications/services/notification/notification.service";
var styles_ModelCardItemActionComponent = [];
var RenderType_ModelCardItemActionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModelCardItemActionComponent, data: {} });
export { RenderType_ModelCardItemActionComponent as RenderType_ModelCardItemActionComponent };
function View_ModelCardItemActionComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-loader-small", [["class", "button__icon"]], [[2, "button__icon_left", null]], null, null, i1.View_LoaderSmallComponent_0, i1.RenderType_LoaderSmallComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoaderSmallComponent, [], null, null), i0.ɵppd(2, 1)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 0, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 1), _co.verboseName)); _ck(_v, 0, 0, currVal_0); }); }
function View_ModelCardItemActionComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "icon button__icon"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i3.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "icon button__icon"; var currVal_1 = (_co.action.icon ? ("icon-" + _co.action.icon) : "icon-power"); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ModelCardItemActionComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "element__indicator element__indicator_tiny element__indicator_outside icon-eye"]], null, null, null, null, null)), i0.ɵdid(1, 737280, null, 0, i4.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"] }, null)], function (_ck, _v) { var currVal_0 = "Action is hidden"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ModelCardItemActionComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i5.ThemeColorHexOptionPipe, [[2, i6.ThemeContext]]), i0.ɵpid(0, i7.IsSetPipe, []), (_l()(), i0.ɵeld(2, 0, null, null, 8, "a", [["class", "button button_tiny"], ["href", "javascript:void(0)"], ["tabindex", "-1"]], [[2, "button_icon", null], [2, "button_disabled", null], [8, "style", 2], [2, "button_tint-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.execute($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 737280, null, 0, i4.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"] }, null), i0.ɵdid(4, 540672, null, 0, i8.ButtonTintColorDirective, [i9.DomSanitizer], { appTintColor: [0, "appTintColor"], appTintColorStyle: [1, "appTintColorStyle"] }, null), i0.ɵppd(5, 1), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ModelCardItemActionComponent_1)), i0.ɵdid(8, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ModelCardItemActionComponent_2)), i0.ɵdid(10, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ModelCardItemActionComponent_3)), i0.ɵdid(12, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.verboseName; _ck(_v, 3, 0, currVal_4); var currVal_5 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 6).transform(i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v, 0), "accentColor")))); var currVal_6 = _co.tintStyles.Default; _ck(_v, 4, 0, currVal_5, currVal_6); var currVal_7 = _co.submitLoading; _ck(_v, 8, 0, currVal_7); var currVal_8 = !_co.submitLoading; _ck(_v, 10, 0, currVal_8); var currVal_9 = (!_co.isVisible && i0.ɵunv(_v, 12, 0, i0.ɵnov(_v, 13).transform(_co.customizeService.enabled$))); _ck(_v, 12, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = (_co.disabled || _co.submitLoading); var currVal_2 = i0.ɵnov(_v, 4).style; var currVal_3 = i0.ɵnov(_v, 4).cls; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_ModelCardItemActionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-model-card-item-action", [], [[2, "element__indicator-wrapper", null], [2, "hidden", null]], null, null, View_ModelCardItemActionComponent_0, RenderType_ModelCardItemActionComponent)), i0.ɵdid(1, 770048, null, 0, i10.ModelCardItemActionComponent, [i11.CustomizeService, i12.ActionService, i13.ActionControllerService, i14.NotificationService, i0.Injector, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).indicatorWrapper; var currVal_1 = i0.ɵnov(_v, 1).hidden; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var ModelCardItemActionComponentNgFactory = i0.ɵccf("app-model-card-item-action", i10.ModelCardItemActionComponent, View_ModelCardItemActionComponent_Host_0, { model: "model", action: "action", context: "context", contextElement: "contextElement" }, {}, []);
export { ModelCardItemActionComponentNgFactory as ModelCardItemActionComponentNgFactory };
