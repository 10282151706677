import { ObserversModule } from '@angular/cdk/observers';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LocalizeModule } from '@common/localize';
import { TipsModule } from '@common/tips';
import { AnalyticsModule } from '@modules/analytics';
import { FeaturesModule } from '@modules/features';
import { FieldComponentsModule } from '@modules/field-components';
import { FieldsModule } from '@modules/fields';
import { ProjectSettingsComponentsModule } from '@modules/project-settings-components';
import { RoutingModule } from '@modules/routing';
import { TutorialModule } from '@modules/tutorial';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { SharePublicComponent } from './components/share-public/share-public.component';
import { ShareInviteItemComponent } from './components/share/share-invite-item/share-invite-item.component';
import { SharePublicInviteItemComponent } from './components/share/share-public-invite-item/share-public-invite-item.component';
import { ShareUserItemComponent } from './components/share/share-user-item/share-user-item.component';
import { ShareComponent } from './components/share/share.component';

@NgModule({
  imports: [
    CommonModule,
    // NgGxSelectModule,
    // ModelQueriesModule,
    FieldComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    FieldsModule,
    // SharedModule,
    // ActivitiesRoutesModule,
    RoutingModule,
    TutorialModule,
    // DemoModule,
    AnalyticsModule,
    LocalizeModule,
    // MenuProfileModule,
    // MenuComponentsModule,
    TipsModule,
    OverlayModule,
    ObserversModule,
    ProjectSettingsComponentsModule,
    UiModule,
    FeaturesModule,
    SharedModule
  ],
  declarations: [
    ShareComponent,
    ShareUserItemComponent,
    ShareInviteItemComponent,
    SharePublicInviteItemComponent,
    SharePublicComponent
  ],
  exports: [ShareComponent],
  entryComponents: [ShareComponent]
})
export class ShareModule {}
