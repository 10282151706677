import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { ActionControllerService, ActionService } from '@modules/action-queries';
import { CustomizeService, TintStyle, ViewContext, ViewContextElement, ViewSettingsAction } from '@modules/customize';
import { applyBooleanInput$, applyParamInput$, getInputsValid$ } from '@modules/fields';
import { ITEM_OUTPUT } from '@modules/list';
import { Model } from '@modules/models';

@Component({
  selector: 'app-model-card-action',
  templateUrl: './model-card-action.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModelCardActionComponent implements OnInit, OnDestroy, OnChanges {
  @Input() model: Model;
  @Input() action: ViewSettingsAction;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() accentColor: string;

  @HostBinding('class.element__indicator-wrapper') indicatorWrapper = true;
  @HostBinding('class.hidden') get hidden() {
    return !this.isVisible && !this.customizeService.enabled;
  }

  submitLoading = false;
  verboseName: string;
  verboseNameSubscription: Subscription;
  isVisible = true;
  visibleSubscription: Subscription;
  disabled = false;
  disabledSubscription: Subscription;
  style: TintStyle = TintStyle.Primary;
  tintStyles = TintStyle;

  constructor(
    public customizeService: CustomizeService,
    private actionService: ActionService,
    private actionControllerService: ActionControllerService,
    private notificationService: NotificationService,
    private injector: Injector,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.initVerboseName();
    this.initStyle();
    this.initVisible();
    this.initDisabled();
  }

  initVerboseName() {
    if (this.verboseNameSubscription) {
      this.verboseNameSubscription.unsubscribe();
      this.verboseNameSubscription = undefined;
    }

    if (!this.action || !this.action.verboseNameInput) {
      this.verboseName = undefined;
      this.cd.markForCheck();
      return;
    }

    this.verboseNameSubscription = applyParamInput$<string>(this.action.verboseNameInput, {
      context: this.context,
      contextElement: this.contextElement,
      localContext: {
        [ITEM_OUTPUT]: this.model.getAttributes()
      },
      defaultValue: ''
    })
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.verboseName = value;
        this.cd.markForCheck();
      });
  }

  initStyle() {
    this.style = this.action.style ? this.action.style : TintStyle.Primary;
    this.cd.markForCheck();
  }

  initVisible() {
    if (this.visibleSubscription) {
      this.visibleSubscription.unsubscribe();
      this.visibleSubscription = undefined;
    }

    if (!this.action || !this.action.visibleInput) {
      this.isVisible = true;
      this.cd.markForCheck();
      return;
    }

    this.visibleSubscription = applyBooleanInput$(this.action.visibleInput, {
      context: this.context,
      contextElement: this.contextElement,
      localContext: {
        [ITEM_OUTPUT]: this.model.getAttributes()
      }
    })
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.isVisible = value;
        this.cd.markForCheck();
      });
  }

  getInputsValid$(): Observable<boolean> {
    return this.actionService.getActionDescription(this.action).pipe(
      switchMap(actionDescription => {
        if (!actionDescription) {
          return of(true);
        }

        return getInputsValid$(this.action.inputs, {
          context: this.context,
          contextElement: this.contextElement,
          localContext: {
            [ITEM_OUTPUT]: this.model.getAttributes()
          },
          parameters: actionDescription.actionParams
        });
      })
    );
  }

  getElementDisabled$() {
    return this.action.disabledInput && this.action.disabledInput.isSet()
      ? applyBooleanInput$(this.action.disabledInput, {
          context: this.context,
          contextElement: this.contextElement,
          localContext: {
            [ITEM_OUTPUT]: this.model.getAttributes()
          }
        })
      : of(false);
  }

  initDisabled() {
    if (this.disabledSubscription) {
      this.disabledSubscription.unsubscribe();
      this.disabledSubscription = undefined;
    }

    if (!this.action) {
      this.disabled = false;
      this.cd.markForCheck();
      return;
    }

    combineLatest(this.getInputsValid$(), this.getElementDisabled$())
      .pipe(untilDestroyed(this))
      .subscribe(([inputsValid, inputDisabled]) => {
        this.disabled = inputDisabled || !inputsValid;
        this.cd.markForCheck();
      });
  }

  execute(e: MouseEvent) {
    this.submitLoading = true;
    this.cd.markForCheck();

    this.context.clickEvent = e;
    this.actionControllerService
      .execute(this.action, {
        context: this.context,
        contextElement: this.contextElement,
        localContext: {
          [ITEM_OUTPUT]: this.model.getAttributes()
        },
        injector: this.injector
      })
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.submitLoading = false;
          this.cd.markForCheck();
        },
        () => {
          this.submitLoading = false;
          this.cd.markForCheck();
        }
      );
  }
}
