<ng-container *ngIf="visible || (customizeEnabled$ | async)">
  <app-auto-field
    *ngIf="!loadingValue && state.field"
    [form]="form"
    [field]="state.field"
    [readonly]="state.readonly"
    [manualMargin]="true"
    [context]="context"
    [contextElement]="viewContextElement"
    [tooltip]="state.tooltip"
    [accentColor]="accentColor"
  >
  </app-auto-field>

  <app-auto-field-stub
    *ngIf="loadingValue"
    [field]="state.field"
    [readonly]="state.readonly"
    [manualMargin]="true"
  ></app-auto-field-stub>
</ng-container>
