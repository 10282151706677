var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpErrorResponse } from '@angular/common/http';
import { Injector } from '@angular/core';
import saveAs from 'file-saver';
import cloneDeep from 'lodash/cloneDeep';
import fromPairs from 'lodash/fromPairs';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import last from 'lodash/last';
import round from 'lodash/round';
import toPairs from 'lodash/toPairs';
import truncate from 'lodash/truncate';
import * as moment from 'moment';
import { combineLatest, merge, Observable, of, ReplaySubject, Subject, throwError, timer } from 'rxjs';
import { catchError, debounceTime, delayWhen, filter, first, map, share, switchMap, tap } from 'rxjs/operators';
import { copyTextToClipboard } from '@common/code';
import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { DialogButtonHotkey, DialogButtonType, DialogService } from '@common/dialogs';
import { NotificationService } from '@common/notifications';
import { PopupService } from '@common/popups';
import { SessionStorage } from '@core';
import { ActionDescription, ActionItem, ActionType, DownloadActionType, SegueType, WorkflowAction } from '@modules/actions';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { Task, TaskQueueStore, TaskService } from '@modules/collaboration';
import { CustomizeService, CustomViewSettings, HTTP_BODY_OUTPUT, HTTP_CODE_OUTPUT, HTTP_STATUS_OUTPUT, ITEM_OUTPUT, modelFieldToRawListViewSettingsColumn, rawListViewSettingsColumnsToDisplayField, SUBMIT_ERROR_OUTPUT, SUBMIT_RESULT_OUTPUT } from '@modules/customize';
import { DataSourceType } from '@modules/data-sources';
import { applyParamInput, applyParamInputs, DisplayFieldType, executeJavaScript, FieldOutput, FieldType, getFieldDescriptionByType, JsonOutputFormat, ParameterField } from '@modules/fields';
import { EMPTY_FILTER_VALUES } from '@modules/filters';
import { ScannerPopupController } from '@modules/image-codes';
import { MessageName, MessageService } from '@modules/messages';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { Model, ModelAction, ModelDescription, ModelFieldType, NO_KEY_ATTRIBUTE } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore, isResourceTypeItem3rdParty, ProjectPropertyStore, ProjectPropertyType, ResourceType } from '@modules/projects';
import { HttpQuery, HttpQueryService, HttpResponseType, QueryService, QueryType } from '@modules/queries';
import { applyFrontendFiltering, applyFrontendPagination, applyQueryOptionsFilterInputs, getQueryOptionsToParams, ModelResponse, paramsToGetQueryOptions, ResourceControllerService } from '@modules/resources';
import { RoutingService } from '@modules/routing';
import { StorageService } from '@modules/storages-queries';
import { CurrentUserStore, User } from '@modules/users';
import { ActionWorkflowStep, ConditionWorkflowStep, ConditionWorkflowStepType, DelayWorkflowStep, ExitWorkflowStep, ForkWorkflowStep, IteratorWorkflowStep, TransformWorkflowStep, WorkflowBackendRunService, WorkflowRun, WorkflowStepRun, WorkflowStepType } from '@modules/workflow';
import { AppError, coerceArray, forceObservable, getExtension, getFilenameWithExtension, getMimeExtension, isAbsoluteUrl, isSet, limitObjectLength, mapWithError, openUrl, splitmax, strip } from '@shared';
import { ViewSettingsStore } from '../../../customize/stores/view-settings.store';
import { CancelledError } from '../../data/cancelled.error';
import { WorkflowExecuteEventType, WorkflowExecuteStepError } from '../../data/workflow-execute';
import { ActionStore } from '../../stores/action.store';
import { ActionDescriptionService } from '../action-description/action-description.service';
import { ExportController } from '../export-controller/export.controller';
import { storageGetObjectsStructure } from './storage-get-objects-structure';
export var ActionItemExecuteSessionKey = 'action_item_execute';
export var ActionDescriptionExecuteSessionKey = 'action_description_execute';
export var ActionDescriptionParamsExecuteSessionKey = 'action_description_params_execute';
export function isModelUpdateEventMatch(e, modelDescription, model) {
    return e.modelDescription.isSame(modelDescription) && e.model.isSame(model);
}
export function patchModel(instance, newModel) {
    var newInstance = instance.clone();
    toPairs(newModel.getAttributes()).forEach(function (_a) {
        var k = _a[0], v = _a[1];
        if (v !== undefined) {
            newInstance.setAttribute(k, v);
        }
    });
    toPairs(newModel.getRawAttributes()).forEach(function (_a) {
        var k = _a[0], v = _a[1];
        if (v !== undefined) {
            newInstance.setRawAttribute(k, v);
        }
    });
    return newInstance;
}
var ActionService = /** @class */ (function () {
    function ActionService(exportComponent, importComponent, messageService, injector, currentProjectStore, currentEnvironmentStore, currentUserStore, actionDescriptionService, modelDescriptionStore, modelService, storageService, queryService, workflowBackendRunService, actionStore, exportController, resourceControllerService, httpQueryService, routing, popupService, sessionStorage, taskService, taskQueueStore, dialogService, viewSettingsStore, projectPropertyStore, scannerPopupController, notificationService, customizeService, analyticsService) {
        this.exportComponent = exportComponent;
        this.importComponent = importComponent;
        this.messageService = messageService;
        this.injector = injector;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.currentUserStore = currentUserStore;
        this.actionDescriptionService = actionDescriptionService;
        this.modelDescriptionStore = modelDescriptionStore;
        this.modelService = modelService;
        this.storageService = storageService;
        this.queryService = queryService;
        this.workflowBackendRunService = workflowBackendRunService;
        this.actionStore = actionStore;
        this.exportController = exportController;
        this.resourceControllerService = resourceControllerService;
        this.httpQueryService = httpQueryService;
        this.routing = routing;
        this.popupService = popupService;
        this.sessionStorage = sessionStorage;
        this.taskService = taskService;
        this.taskQueueStore = taskQueueStore;
        this.dialogService = dialogService;
        this.viewSettingsStore = viewSettingsStore;
        this.projectPropertyStore = projectPropertyStore;
        this.scannerPopupController = scannerPopupController;
        this.notificationService = notificationService;
        this.customizeService = customizeService;
        this.analyticsService = analyticsService;
        this._modelUpdated$ = new Subject();
    }
    ActionService.prototype.createGetResponse = function () {
        return Injector.create({
            providers: [{ provide: ModelResponse.GetResponse, deps: [Injector] }],
            parent: this.injector
        }).get(ModelResponse.GetResponse);
    };
    ActionService.prototype.getActionDescriptionParams = function (actionDescription, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        if (!actionDescription) {
            return of([]);
        }
        var itemsToFields = function (value) {
            var getFields = function (items) {
                return items.reduce(function (prev, item) {
                    if (item.field) {
                        prev.push(item.field);
                    }
                    if (item.children) {
                        prev.push.apply(prev, getFields(item.children));
                    }
                    return prev;
                }, []);
            };
            return getFields(value);
        };
        var fieldToParameter = function (item) {
            var result = new ParameterField();
            result.name = item.name;
            result.verboseName = item.verboseName;
            result.description = item.description;
            result.field = item.field;
            result.required = item['required'];
            result.defaultType = item['defaultType'];
            result.defaultValue = item['defaultValue'];
            result.params = item.params;
            result.updateFieldDescription();
            return result;
        };
        if (actionDescription.type == ActionType.Link) {
            var page = actionDescription.linkAction ? actionDescription.linkAction.page : undefined;
            if (!page) {
                return of([]);
            }
            return this.viewSettingsStore.getDetailFirst(page).pipe(map(function (viewSettings) {
                var parameters = viewSettings ? viewSettings.parameters : [];
                var items = _this.actionDescriptionService.getLinkActionParameters(actionDescription.linkAction, parameters);
                return itemsToFields(items).map(function (item) { return fieldToParameter(item); });
            }));
        }
        else if (actionDescription.type == ActionType.ExternalLink) {
            return of(this.actionDescriptionService
                .getExternalLinkActionParameters()
                .filter(function (item) { return item.name !== 'new_tab'; })
                .map(function (item) { return fieldToParameter(item); }));
        }
        else if (actionDescription.type == ActionType.Export) {
            if (!actionDescription.exportAction) {
                return of([]);
            }
            var modelId = actionDescription.exportAction.getModelId();
            return this.modelDescriptionStore.getDetailFirst(modelId).pipe(map(function (modelDescription) {
                if (!modelDescription) {
                    return [];
                }
                var resource = _this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == modelDescription.resource; });
                var items = _this.actionDescriptionService.getExportActionParameters(resource, modelDescription, []);
                return itemsToFields(items).map(function (item) { return fieldToParameter(item); });
            }));
        }
        else if (actionDescription.type == ActionType.OpenPopup) {
            var uid_1 = actionDescription.openPopupAction ? actionDescription.openPopupAction.popup : undefined;
            if (!isSet(uid_1) ||
                !options.context ||
                !options.context.viewSettings ||
                !(options.context.viewSettings instanceof CustomViewSettings)) {
                return of([]);
            }
            var page = options.context.viewSettings;
            return this.viewSettingsStore.getDetailFirst(page.uniqueName).pipe(map(function (viewSettings) {
                var popup = viewSettings ? viewSettings.popups.find(function (item) { return item.uid == uid_1; }) : undefined;
                var parameters = popup ? popup.parameters : [];
                var items = _this.actionDescriptionService.getOpenPopupActionParameters(parameters);
                return itemsToFields(items).map(function (item) { return fieldToParameter(item); });
            }));
        }
        else if (actionDescription.type == ActionType.ElementAction) {
            var items = this.actionDescriptionService.getElementActionParameters(actionDescription.elementAction, options.context);
            return of(itemsToFields(items).map(function (item) { return fieldToParameter(item); }));
        }
        else {
            return of(actionDescription.actionParams);
        }
    };
    ActionService.prototype.getActionDescription = function (action, options) {
        if (options === void 0) { options = {}; }
        if (!action) {
            return of(undefined);
        }
        if (action.sharedActionDescription) {
            return this.actionStore.getDetailFirst(action.sharedActionDescription);
        }
        else if (action.actionDescription) {
            return this.getActionDescriptionParams(action.actionDescription, { context: options.context }).pipe(map(function (actionParams) {
                var actionDescription = cloneDeep(action.actionDescription);
                actionDescription.actionParams = actionParams;
                return actionDescription;
            }));
        }
        else {
            return of(undefined);
        }
    };
    ActionService.prototype.getActionDescriptionModel$ = function (actionDescription) {
        if (!actionDescription || !actionDescription.model || !actionDescription.modelAction) {
            return of(undefined);
        }
        if (![ActionType.Query, ActionType.Download].includes(actionDescription.type)) {
            return of(undefined);
        }
        var modelId = [actionDescription.resource, actionDescription.model].join('.');
        return this.modelDescriptionStore.getDetailFirst(modelId);
    };
    ActionService.prototype.getActionModel$ = function (action) {
        var _this = this;
        return this.getActionDescription(action).pipe(switchMap(function (actionDescription) { return _this.getActionDescriptionModel$(actionDescription); }));
    };
    ActionService.prototype.getActionDescriptionOutputs = function (actionDescription) {
        var _this = this;
        var defaultOutputs = { outputs: [] };
        return this.getActionDescriptionModel$(actionDescription).pipe(switchMap(function (modelDescription) {
            var type = actionDescription ? actionDescription.type : undefined;
            var specifiedOutputs = actionDescription
                ? { outputs: actionDescription.outputs, arrayOutput: actionDescription.arrayOutput }
                : defaultOutputs;
            if (type == ActionType.Query || type == ActionType.Download) {
                if (specifiedOutputs.outputs.length) {
                    return of(specifiedOutputs);
                }
                if (modelDescription) {
                    if (['get', 'get_detail', 'create', 'update'].includes(actionDescription.modelAction)) {
                        var outputs = modelDescription.dbFields.map(function (item) {
                            var output = new FieldOutput();
                            output.name = item.name;
                            output.verboseName = item.verboseName;
                            output.field = item.field;
                            output.params = item.params;
                            output.updateFieldDescription();
                            return output;
                        });
                        return of({
                            outputs: outputs,
                            arrayOutput: actionDescription.modelAction == 'get'
                        });
                    }
                    else if (actionDescription.modelAction == 'delete') {
                        var output = new FieldOutput();
                        output.name = NO_KEY_ATTRIBUTE;
                        output.verboseName = 'result';
                        output.field = FieldType.Boolean;
                        output.updateFieldDescription();
                        return of({
                            outputs: [output]
                        });
                    }
                }
                else if (actionDescription.storageAction == 'get_object_url') {
                    var output = new FieldOutput();
                    output.name = 'url';
                    output.verboseName = 'URL';
                    output.field = FieldType.URL;
                    output.updateFieldDescription();
                    return of({
                        outputs: [output]
                    });
                }
                else if (actionDescription.storageAction == 'upload') {
                    var urlOutput = new FieldOutput();
                    urlOutput.name = 'url';
                    urlOutput.verboseName = 'URL';
                    urlOutput.field = FieldType.URL;
                    urlOutput.updateFieldDescription();
                    var pathOutput = new FieldOutput();
                    pathOutput.name = 'path';
                    pathOutput.verboseName = 'path';
                    pathOutput.field = FieldType.Text;
                    pathOutput.updateFieldDescription();
                    return of({
                        outputs: [urlOutput, pathOutput]
                    });
                }
                else if (actionDescription.storageAction == 'get') {
                    var objectsOutput = new FieldOutput();
                    objectsOutput.name = 'objects';
                    objectsOutput.verboseName = 'objects';
                    objectsOutput.field = FieldType.JSON;
                    objectsOutput.params = {
                        structure: storageGetObjectsStructure
                    };
                    objectsOutput.updateFieldDescription();
                    return of({
                        outputs: [objectsOutput]
                    });
                }
                else if (['create_directory', 'remove'].includes(actionDescription.storageAction)) {
                    var output = new FieldOutput();
                    output.name = NO_KEY_ATTRIBUTE;
                    output.verboseName = 'result';
                    output.field = FieldType.Boolean;
                    output.updateFieldDescription();
                    return of({
                        outputs: [output]
                    });
                }
                return of(defaultOutputs);
            }
            else if (type == ActionType.Workflow) {
                if (!actionDescription.workflowAction || !actionDescription.workflowAction.workflow) {
                    return of(defaultOutputs);
                }
                if (actionDescription.workflowAction.workflow.result) {
                    var resultOutputs = actionDescription.workflowAction.workflow.result.parameters.map(function (item) {
                        var output = new FieldOutput();
                        output.name = item.name;
                        output.verboseName = item.verboseName || item.name;
                        output.field = item.field;
                        output.params = item.params;
                        output.updateFieldDescription();
                        return output;
                    });
                    return of({
                        outputs: resultOutputs,
                        array: actionDescription.workflowAction.workflow.result.array
                    });
                }
                var lastStep = last(actionDescription.workflowAction.workflow.steps);
                if (!lastStep) {
                    return of(defaultOutputs);
                }
                if (lastStep.type == WorkflowStepType.Action) {
                    var actionStep = lastStep;
                    if (!actionStep || !actionStep.action) {
                        return of(defaultOutputs);
                    }
                    return _this.getActionOutputs(actionStep.action);
                }
                return of(defaultOutputs);
            }
            else if ([
                ActionType.Link,
                ActionType.ExternalLink,
                ActionType.ShowNotification,
                ActionType.CopyToClipboard,
                ActionType.Export,
                ActionType.OpenPopup,
                ActionType.ClosePopup
            ].includes(type)) {
                var output = new FieldOutput();
                output.name = NO_KEY_ATTRIBUTE;
                output.verboseName = 'result';
                output.field = FieldType.Boolean;
                output.updateFieldDescription();
                return of({
                    outputs: [output]
                });
            }
            else if (type == ActionType.Import) {
                var outputs = [
                    { name: 'processedCount', field: FieldType.Number },
                    { name: 'successCount', field: FieldType.Number },
                    { name: 'failedCount', field: FieldType.Number },
                    { name: 'totalCount', field: FieldType.Number },
                    { name: 'objectResults', field: FieldType.JSON }
                ].map(function (item) {
                    var output = new FieldOutput();
                    output.name = item.name;
                    output.verboseName = item.name;
                    output.field = item.field;
                    output.updateFieldDescription();
                    return output;
                });
                return of({
                    outputs: outputs
                });
            }
            else if ([ActionType.RunJavaScript].includes(type)) {
                var output = new FieldOutput();
                output.name = NO_KEY_ATTRIBUTE;
                output.verboseName = 'result';
                output.field = FieldType.JSON;
                output.updateFieldDescription();
                return of({
                    outputs: [output]
                });
            }
            else if ([ActionType.ScanCode].includes(type)) {
                var output = new FieldOutput();
                output.name = 'value';
                output.verboseName = 'value';
                output.field = FieldType.Text;
                output.updateFieldDescription();
                return of({
                    outputs: [output]
                });
            }
            else {
                return of(defaultOutputs);
            }
        }));
    };
    ActionService.prototype.getActionOutputs = function (action) {
        var _this = this;
        var defaultOutputs = { outputs: [] };
        if (!action) {
            return of(defaultOutputs);
        }
        return this.getActionDescription(action).pipe(switchMap(function (actionDescription) { return _this.getActionDescriptionOutputs(actionDescription); }));
    };
    ActionService.prototype.getWorkflowOutputs = function (workflow) {
        var action = new ActionItem();
        action.actionDescription = new ActionDescription();
        action.actionDescription.type = ActionType.Workflow;
        action.actionDescription.workflowAction = new WorkflowAction();
        action.actionDescription.workflowAction.workflow = workflow;
        return this.getActionOutputs(action);
    };
    ActionService.prototype.getElementStatus = function (element, action, context) {
        if (context === void 0) { context = {}; }
        var name = action.actionDescription ? action.actionDescription.name : action.sharedActionDescription;
        var params = {
            element: element,
            name: name,
            context: context
        };
        return this.messageService.send(undefined, MessageName.GetElementStatus, params).pipe(map(function (result) {
            if (!result.json) {
                throw new ServerRequestError('No status specified');
            }
            return result.json;
        }));
    };
    ActionService.prototype.serializeParamsToQueryParams = function (parameters, params) {
        return parameters.reduce(function (acc, parameter) {
            var value = params[parameter.name];
            if (value !== undefined) {
                var fieldDescription = getFieldDescriptionByType(parameter.field);
                if (fieldDescription.serializeValue) {
                    if (parameter.field == FieldType.JSON) {
                        parameter = cloneDeep(parameter);
                        parameter.params['output_format'] = JsonOutputFormat.String;
                    }
                    value = fieldDescription.serializeValue(value, parameter);
                }
                acc[parameter.name] = value;
            }
            return acc;
        }, {});
    };
    ActionService.prototype.getActionResponseBlob = function (response, options) {
        if (options === void 0) { options = {}; }
        if (response.blob) {
            return this.coerceFile(response.blob, __assign({}, options, { response: response.response }));
        }
        else if (isSet(response.json)) {
            return this.coerceFile(response.json, __assign({}, options, { response: response.response }));
        }
        else if (isSet(response.text)) {
            return this.coerceFile(response.text, __assign({}, options, { response: response.response }));
        }
    };
    ActionService.prototype.coerceFile = function (data, options) {
        if (options === void 0) { options = {}; }
        if (typeof data == 'string' && isAbsoluteUrl(data)) {
            var query = new HttpQuery();
            query.url = data;
            query.responseType = HttpResponseType.Blob;
            return this.httpQueryService.request(query).pipe(map(function (response) {
                var fileName = getFilenameWithExtension(data);
                var file = new File([response.body], isSet(fileName) ? fileName : '', { type: response.body.type });
                return {
                    file: file,
                    response: response
                };
            }));
        }
        else if (data instanceof File) {
            return of({
                file: data,
                response: options.response
            });
        }
        else if (data instanceof Blob) {
            var file = new File([data], isSet(options.fileName) ? options.fileName : '', { type: options.type });
            return of({
                file: file,
                response: options.response
            });
        }
        else {
            var dataStr = void 0;
            var type = void 0;
            if (isSet(data) && typeof data === 'object') {
                try {
                    dataStr = JSON.stringify(data);
                    type = 'application/json';
                }
                catch (e) { }
            }
            if (!isSet(type)) {
                dataStr = String(data);
                type = 'text/plain';
            }
            var file = new File([dataStr], '', { type: type });
            return of({
                file: file,
                response: options.response
            });
        }
    };
    ActionService.prototype.executeActionDescription = function (actionDescription, params, options) {
        var _this = this;
        if (params === void 0) { params = {}; }
        if (options === void 0) { options = {}; }
        var _a;
        var openLink = function (url, newTab) {
            if (newTab === void 0) { newTab = false; }
            if (newTab ||
                (options.context &&
                    options.context['clickEvent'] &&
                    (options.context['clickEvent'].shiftKey ||
                        options.context['clickEvent'].ctrlKey ||
                        options.context['clickEvent'].metaKey))) {
                openUrl(_this.routing.serializeUrl(url), true);
            }
            else {
                _this.routing.navigateByUrl(url);
            }
        };
        // const params = {};
        // const bulkParams = [];
        // console.log('output', options.context.outputs, action.inputs);
        // action.inputs
        //   .filter(item => item.valueType != InputValueType.Prompt)
        //   .forEach(item => {
        //     if (item.valueType == InputValueType.StaticValue) {
        //       params[item.name] = item.staticValue;
        //     } else if (item.valueType == InputValueType.Context) {
        //       const getValue = (ctx, query) => {
        //         const actionCtx = options.context ? options.context.outputs : {};
        //
        //         if (ctx && ctx.models) {
        //           actionCtx['record'] = ctx.models.reduce((prev, current) => {
        //             const attrs = current.getAttributes();
        //             toPairs(attrs).forEach(([k, v]) => {
        //               if (!prev.hasOwnProperty(k)) {
        //                 prev[k] = [];
        //               }
        //               prev[k].push(v);
        //             });
        //             return prev;
        //           }, {});
        //         }
        //
        //         const result = objectGet(actionCtx, query);
        //
        //         if (result !== EMPTY) {
        //           return result;
        //         }
        //       };
        //
        //       const value = getValue(options.context, item.contextValue);
        //
        //       if (value !== undefined) {
        //         if (isArray(value)) {
        //           params[item.name] = JSON.stringify(value);
        //           bulkParams.push(item.name);
        //         } else {
        //           params[item.name] = value;
        //         }
        //       }
        //     }
        //   });
        //
        // if (bulkParams.length) {
        //   params[ACTION_BULK_INPUTS_PARAM] = bulkParams.join(',');
        // }
        if (actionDescription.type == ActionType.Query) {
            if (!actionDescription.queryAction) {
                return throwError(new AppError('No query specified'));
            }
            var resource = this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == actionDescription.resource; });
            return this.executeQuery(resource, actionDescription.queryAction.query, actionDescription.actionParams, params, {
                context: options.context
            }).pipe(map(function (response) { return (response ? response.blob || response.json || response.text : undefined); }));
        }
        else if (actionDescription.type == ActionType.Download) {
            if (!actionDescription.downloadAction) {
                return throwError(new AppError('No query specified'));
            }
            var resource = this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == actionDescription.resource; });
            var file$ = actionDescription.downloadAction && actionDescription.downloadAction.type == DownloadActionType.Input
                ? of(applyParamInput(actionDescription.downloadAction.input, {
                    context: options.context,
                    contextElement: options.contextElement,
                    localContext: options.localContext,
                    defaultValue: ''
                })).pipe(switchMap(function (url) { return _this.coerceFile(url); }))
                : this.executeQuery(resource, actionDescription.downloadAction.query, actionDescription.actionParams, params, { context: options.context }).pipe(switchMap(function (result) {
                    if (isSet(actionDescription.downloadAction.fileColumn)) {
                        if (result && isArray(result.json)) {
                            var url = result.json[0]
                                ? result.json[0][actionDescription.downloadAction.fileColumn]
                                : undefined;
                            return _this.coerceFile(url);
                        }
                        else if (result && isPlainObject(result.json)) {
                            var url = result.json[actionDescription.downloadAction.fileColumn];
                            return _this.coerceFile(url);
                        }
                    }
                    return _this.getActionResponseBlob(result);
                }));
            return file$.pipe(map(function (result) {
                if (!result) {
                    throw new AppError('No data to download');
                }
                return {
                    response: result.response,
                    blob: result.file
                };
            }), tap(function (response) {
                var contentDisposition = response.response
                    ? response.response.headers.get('content-disposition')
                    : undefined;
                var meta = contentDisposition
                    ? fromPairs(contentDisposition.split(';').map(function (item) {
                        var parts = splitmax(item.trim(), '=', 2);
                        return parts.length == 2 ? [parts[0], strip(parts[1], '"')] : [parts[0], true];
                    }))
                    : {};
                var filename;
                if (meta['filename']) {
                    filename = meta['filename'];
                }
                else {
                    var name_1 = response.blob instanceof File && isSet(response.blob.name)
                        ? response.blob.name
                        : ['file', moment().format('YYYY-MM-DD'), moment().format('HH-mm-ss')].join('_');
                    var nameExtension = getExtension(name_1);
                    var fileTypeExtension = getMimeExtension(response.blob.type);
                    filename = !isSet(nameExtension) && isSet(fileTypeExtension) ? [name_1, fileTypeExtension].join('.') : name_1;
                }
                saveAs(response.blob, filename);
            }), map(function (response) { return (response ? response.blob : undefined); }));
        }
        else if (actionDescription.type == ActionType.Link) {
            if (!actionDescription.linkAction) {
                return throwError(new AppError('No link specified'));
            }
            var newTab_1 = isSet(params['new_tab']) && params['new_tab'] && params['new_tab'] !== '0' && params['new_tab'] !== 'false';
            if (actionDescription.linkAction.type == SegueType.Page) {
                if (!actionDescription.linkAction.page) {
                    return throwError(new AppError('No page specified'));
                }
                return this.viewSettingsStore.getDetailFirst(actionDescription.linkAction.page).pipe(switchMap(function (viewSettings) {
                    if (!viewSettings) {
                        throw new AppError('Page not found');
                    }
                    if (options.disableRouting) {
                        return of(undefined);
                    }
                    var queryParams = _this.serializeParamsToQueryParams(viewSettings.parameters, params);
                    var url = _this.routing.createUrlTreeApp(viewSettings.link, { queryParams: queryParams });
                    return _this.runOnRedirectConfirm(String(url), function () {
                        openLink(url, newTab_1);
                    }, options.confirmRouting);
                }));
            }
            else if (actionDescription.linkAction.type == SegueType.PreviousPage) {
                if (options.disableRouting) {
                    return of(undefined);
                }
                return this.runOnRedirectConfirm('Previous URL', function () {
                    window.history.back();
                }, options.confirmRouting);
            }
            else if (actionDescription.linkAction.type == SegueType.ModelCreate) {
                return this.modelDescriptionStore.getDetailFirst(actionDescription.linkAction.model).pipe(switchMap(function (modelDescription) {
                    if (options.disableRouting) {
                        return of(undefined);
                    }
                    var url = _this.routing.createUrlTreeApp(modelDescription.createLink, { queryParams: params });
                    return _this.runOnRedirectConfirm(String(url), function () {
                        openLink(url, newTab_1);
                    }, options.confirmRouting);
                }));
            }
            else if (actionDescription.linkAction.type == SegueType.ModelChange) {
                return this.modelDescriptionStore.getDetailFirst(actionDescription.linkAction.model).pipe(switchMap(function (modelDescription) {
                    var pageParams = cloneDeep(params);
                    if (pageParams.hasOwnProperty('id')) {
                        delete pageParams['id'];
                    }
                    var link = modelDescription.changeLink(params['id']);
                    if (!link) {
                        throw new AppError('No page found');
                    }
                    if (options.disableRouting) {
                        return of(undefined);
                    }
                    var url = _this.routing.createUrlTreeApp(link, { queryParams: params });
                    return _this.runOnRedirectConfirm(String(url), function () {
                        openLink(url, newTab_1);
                    }, options.confirmRouting);
                }));
            }
            else if (actionDescription.linkAction.type == SegueType.ModelMassEdit) {
                return this.modelDescriptionStore.getDetailFirst(actionDescription.linkAction.model).pipe(switchMap(function (modelDescription) {
                    if (isArray(params['ids'])) {
                        params['ids'] = JSON.stringify(params['ids']);
                    }
                    if (options.disableRouting) {
                        return of(undefined);
                    }
                    var url = _this.routing.createUrlTreeApp(modelDescription.massEditLink, { queryParams: params });
                    return _this.runOnRedirectConfirm(String(url), function () {
                        openLink(url, newTab_1);
                    }, options.confirmRouting);
                }));
            }
            else if (actionDescription.linkAction.type == SegueType.ModelDelete) {
                return this.modelDescriptionStore.getDetailFirst(actionDescription.linkAction.model).pipe(switchMap(function (modelDescription) {
                    _this.sessionStorage.set(ActionDescriptionParamsExecuteSessionKey, JSON.stringify(params));
                    if (options.disableRouting) {
                        return of(undefined);
                    }
                    var url = _this.routing.createUrlTreeApp(modelDescription.deleteLink, { queryParamsHandling: 'merge' });
                    return _this.runOnRedirectConfirm(String(url), function () {
                        openLink(url, newTab_1);
                    }, options.confirmRouting);
                }));
            }
            else if (actionDescription.linkAction.type == SegueType.ModelActivityLog) {
                return this.modelDescriptionStore.getDetailFirst(actionDescription.linkAction.model).pipe(switchMap(function (modelDescription) {
                    if (options.disableRouting) {
                        return of(undefined);
                    }
                    var url = _this.routing.createUrlTreeApp(modelDescription.userActivityLink, { queryParams: params });
                    return _this.runOnRedirectConfirm(String(url), function () {
                        openLink(url, newTab_1);
                    }, options.confirmRouting);
                }));
            }
        }
        else if (actionDescription.type == ActionType.ExternalLink) {
            if (!params['href']) {
                return throwError(new AppError('No URL specified'));
            }
            if (options.disableRouting) {
                return of(undefined);
            }
            var url_1 = params['href'];
            var newTab_2 = isSet(params['new_tab']) && params['new_tab'] && params['new_tab'] !== '0' && params['new_tab'] !== 'false';
            return this.runOnRedirectConfirm(String(url_1), function () {
                openUrl(url_1, newTab_2);
            }, options.confirmRouting);
        }
        else if (actionDescription.type == ActionType.ElementAction) {
            var path = actionDescription.elementAction;
            return this.executeElement(path, options.context, params);
        }
        else if (actionDescription.type == ActionType.ShowNotification) {
            if (!actionDescription.notificationAction) {
                return throwError(new AppError('No notification specified'));
            }
            var title = applyParamInput(actionDescription.notificationAction.title, {
                context: options.context,
                contextElement: options.contextElement,
                localContext: options.localContext,
                defaultValue: ''
            });
            var description = applyParamInput(actionDescription.notificationAction.description, {
                context: options.context,
                contextElement: options.contextElement,
                localContext: options.localContext,
                defaultValue: ''
            });
            var newlineToBr = function (value) {
                if (typeof value == 'string') {
                    value = value.replace(/(\r\n|\n|\r)/gm, '<br>');
                }
                return value;
            };
            this.notificationService.show({
                title: title,
                description: isSet(description) ? newlineToBr(description) : undefined,
                icon: actionDescription.notificationAction.icon,
                type: actionDescription.notificationAction.type,
                color: actionDescription.notificationAction.color,
                closeTimeout: actionDescription.notificationAction.closeTimeoutEnabled
                    ? actionDescription.notificationAction.closeTimeout
                    : null
            });
            return of(undefined);
        }
        else if (actionDescription.type == ActionType.SetProperty) {
            if (!actionDescription.setPropertyAction ||
                !isSet(actionDescription.setPropertyAction.property) ||
                !actionDescription.setPropertyAction.value) {
                return throwError(new AppError('No property value specified'));
            }
            return this.projectPropertyStore.getDetail(actionDescription.setPropertyAction.property).pipe(map(function (property) {
                if (!property) {
                    return;
                }
                var value = applyParamInput(actionDescription.setPropertyAction.value, {
                    context: options.context,
                    contextElement: options.contextElement,
                    localContext: options.localContext,
                    defaultValue: ''
                });
                if (property.type == ProjectPropertyType.Global && _this.currentEnvironmentStore.globalStorage) {
                    _this.currentEnvironmentStore.globalStorage.setValue(property, value);
                }
                else if (property.type == ProjectPropertyType.Page && options.context && options.context.pageStorage) {
                    options.context.pageStorage.setValue(property, value);
                }
                return value;
            }));
        }
        else if (actionDescription.type == ActionType.RunJavaScript) {
            if (!actionDescription.runJavaScriptAction || !isSet(actionDescription.runJavaScriptAction.js)) {
                return throwError(new AppError('No code specified'));
            }
            var result = executeJavaScript(actionDescription.runJavaScriptAction.js, {
                context: options.context,
                contextElement: options.contextElement,
                localContext: options.localContext
            });
            return of((_a = {},
                _a[NO_KEY_ATTRIBUTE] = result,
                _a));
        }
        else if (actionDescription.type == ActionType.CopyToClipboard) {
            if (!actionDescription.copyToClipboardAction || !actionDescription.copyToClipboardAction.value) {
                return throwError(new AppError('No value specified'));
            }
            var value = applyParamInput(actionDescription.copyToClipboardAction.value, {
                context: options.context,
                contextElement: options.contextElement,
                localContext: options.localContext,
                defaultValue: ''
            });
            return copyTextToClipboard(value);
        }
        else if (actionDescription.type == ActionType.Export) {
            if (!actionDescription.exportAction || !actionDescription.exportAction.dataSource) {
                return throwError(new AppError('No collection specified'));
            }
            var modelId = actionDescription.exportAction.getModelId();
            return this.modelDescriptionStore.getDetailFirst(modelId).pipe(map(function (modelDescription) {
                _this.exportController.openPopup({
                    dataSource: actionDescription.exportAction.dataSource,
                    queryOptions: actionDescription.exportAction.queryOptions,
                    modelDescription: modelDescription,
                    context: options.context,
                    contextElement: options.contextElement,
                    localContext: options.localContext,
                    params: params,
                    ids: isSet(params['ids']) ? [params['ids']] : undefined
                });
                return undefined;
            }));
        }
        else if (actionDescription.type == ActionType.Import) {
            if (!actionDescription.importAction || !actionDescription.importAction.getModelId()) {
                return throwError(new AppError('No collection specified'));
            }
            var modelId = actionDescription.importAction.getModelId();
            return this.modelDescriptionStore.getDetailFirst(modelId).pipe(switchMap(function (modelDescription) {
                var result = new ReplaySubject();
                var resource = _this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == actionDescription.importAction.resource; });
                _this.popupService.push({
                    component: _this.importComponent,
                    popupComponent: ThinDialogPopupComponent,
                    inputs: {
                        resource: resource,
                        modelDescription: modelDescription,
                        analyticsSource: options.analyticsSource
                    },
                    outputs: {
                        imported: [function (event) { return result.next(event); }],
                        cancelled: [function (event) { return result.next(event); }]
                    },
                    injector: _this.injector
                });
                return result.asObservable();
            }));
        }
        else if (actionDescription.type == ActionType.OpenPopup) {
            if (!actionDescription.openPopupAction || !actionDescription.openPopupAction.popup) {
                return throwError(new AppError('No modal specified'));
            }
            if (!this.customizeService.handler || !this.customizeService.handler.openPopup) {
                return throwError(new AppError('Incorrect handler'));
            }
            if (!options.disablePopups) {
                this.customizeService.handler.openPopup(actionDescription.openPopupAction.popup, { params: params });
            }
            return of(undefined);
        }
        else if (actionDescription.type == ActionType.ClosePopup) {
            if (!actionDescription.closePopupAction) {
                return throwError(new AppError('No modal specified'));
            }
            if (!this.customizeService.handler || !this.customizeService.handler.closePopup) {
                return throwError(new AppError('Incorrect handler'));
            }
            if (!options.disablePopups) {
                this.customizeService.handler.closePopup(actionDescription.closePopupAction.popup);
            }
            return of(undefined);
        }
        else if (actionDescription.type == ActionType.ScanCode) {
            return this.scannerPopupController.scan().pipe(map(function (result) {
                if (result.cancelled) {
                    throw new CancelledError();
                }
                return {
                    value: result.result ? result.result.text : undefined
                };
            }));
        }
        else if (actionDescription.type == ActionType.Workflow) {
            if (!actionDescription.workflowAction || !actionDescription.workflowAction.workflow) {
                return throwError(new AppError('No workflow specified'));
            }
            var dateRun_1 = moment();
            return this.executeWorkflow(actionDescription.workflowAction.workflow, params, {
                context: options.context,
                contextElement: options.contextElement,
                localContext: options.localContext,
                disablePopups: options.disablePopups,
                disableRouting: options.disableRouting,
                confirmRouting: options.confirmRouting,
                analyticsSource: options.analyticsSource
            }).pipe(filter(function (item) { return item.type == WorkflowExecuteEventType.WorkflowFinished; }), map(function (item) {
                var project = window['project'];
                var environment = window['project_environment'];
                var dateFinished = moment();
                var pageUid = options.context && options.context.viewSettings ? options.context.viewSettings.uid : undefined;
                var elementUid = options.contextElement && options.contextElement.element ? options.contextElement.element.uid : undefined;
                var elementType = options.contextElement && options.contextElement.element ? options.contextElement.element.type : undefined;
                var elementLabel = options.contextElement && options.contextElement.element ? options.contextElement.element.name : undefined;
                var userUid = _this.currentUserStore.instance.uid;
                _this.workflowBackendRunService
                    .createForWorkflow(project, environment, actionDescription.workflowAction.workflow, item.success, item.run, dateRun_1, dateFinished, {
                    pageUid: pageUid,
                    elementUid: elementUid,
                    elementType: elementType,
                    elementLabel: elementLabel,
                    userUid: userUid
                })
                    .subscribe();
                if (!item.success) {
                    throw item.error;
                }
                return item.result;
            }));
        }
        return throwError(new AppError('No action specified'));
    };
    ActionService.prototype.requestApproval = function (action, actionParams, options) {
        var _this = this;
        if (actionParams === void 0) { actionParams = {}; }
        if (options === void 0) { options = {}; }
        return this.taskQueueStore.getDetailFirst(action.approve.taskQueue).pipe(tap(function (queue) {
            if (!_this.currentProjectStore.instance.features.isTasksEnabled()) {
                throw new AppError('Tasks feature is not enabled in your Plan');
            }
            if (!queue) {
                throw new AppError('Task queue does not exist');
            }
        }), switchMap(function (queue) {
            return _this.dialogService
                .confirm({
                title: 'Approval required',
                description: "This action requires approval. It will be executed only after your teammate approval. The appropriate task will be created in task queue <strong>" + queue.name + "</strong>."
            })
                .pipe(switchMap(function (confirm) {
                if (!confirm) {
                    return of(undefined);
                }
                var task = new Task();
                var parameter = new ParameterField();
                parameter.name = 'name';
                parameter.field = FieldType.Text;
                var nameParams = applyParamInputs({}, [action.approve.taskName], {
                    context: options.context,
                    contextElement: options.contextElement,
                    localContext: options.localContext,
                    parameters: [parameter]
                });
                var taskParams = applyParamInputs({}, action.approve.taskInputs, {
                    context: options.context,
                    contextElement: options.contextElement,
                    localContext: options.localContext,
                    parameters: queue.parameters
                });
                var assigned;
                if (action.approve.taskAssignee) {
                    assigned = new User();
                    assigned.uid = action.approve.taskAssignee;
                }
                var status = queue.statuses.find(function (item) { return item.uid == action.approve.taskCreateStatus; });
                if (!isSet(action.approve.taskQueue)) {
                    throw new AppError('Task queue is not specified');
                }
                if (!isSet(nameParams['value'])) {
                    throw new AppError('Task name is not specified');
                }
                if (!status) {
                    throw new AppError('Task status is not specified');
                }
                task.queue = action.approve.taskQueue;
                task.name = nameParams['value'];
                task.assigned = assigned;
                task.approveAction = action;
                task.approveActionParams = actionParams;
                task.status = status;
                task.objectType = options.context ? options.context.objectType : undefined;
                task.objectId = options.context ? options.context.objectId : undefined;
                task.parameterValues = taskParams;
                return _this.taskService
                    .create(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, task)
                    .pipe(tap(function (result) {
                    _this.notificationService.success('Approval task created', "Task " + result.name + " was successfully created");
                    _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Approval.TaskCreated, {
                        TaskQueueId: result.queue
                    });
                }));
            }), catchError(function (error) {
                if (error instanceof ServerRequestError && error.nonFieldErrors.length) {
                    _this.notificationService.error('Approval failed', error.nonFieldErrors[0]);
                }
                else {
                    _this.notificationService.error('Approval Failed', error);
                }
                return throwError(error);
            }));
        }));
    };
    ActionService.prototype.executeQuery = function (resource, query, parameters, params, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var data = {
            actionParams: params
        };
        if (!resource || !query) {
            return throwError(new AppError('No query specified'));
        }
        params = params || {};
        if (isResourceTypeItem3rdParty(resource.typeItem) &&
            !this.currentProjectStore.instance.features.isThirdPartyResourcesEnabled() &&
            !resource.demo) {
            return throwError(new AppError('Business Apps feature is not enabled in your Plan'));
        }
        var throwOnPermissionError = function (action) {
            if (options.context &&
                options.context.viewSettings &&
                options.context.viewSettings instanceof CustomViewSettings) {
                var project = _this.currentProjectStore.instance;
                var environment = _this.currentEnvironmentStore.instance;
                if (!project.hasEnvironmentPagePermission(environment, options.context.viewSettings.uid, action)) {
                    throw new AppError('You dont have permission for run this operation');
                }
            }
        };
        var getPermissionError = function (action) {
            try {
                throwOnPermissionError(action);
            }
            catch (e) {
                return throwError(e);
            }
        };
        if (query.queryType == QueryType.Http && query.httpQuery) {
            if (!this.currentProjectStore.instance.features.isCustomResourcesEnabled() && !resource.demo) {
                return throwError(new AppError('Custom Queries feature is not enabled in your Plan'));
            }
            var error = getPermissionError('w');
            if (error) {
                return error;
            }
            var controller = this.resourceControllerService.get(ResourceType.RestAPI);
            return controller.actionExecute(resource, query, parameters, data, options.rawErrors);
        }
        else if (query.queryType == QueryType.SQL && query.sqlQuery) {
            if (!this.currentProjectStore.instance.features.isCustomResourcesEnabled() && !resource.demo) {
                return throwError(new AppError('Custom Queries feature is not enabled in your Plan'));
            }
            var error = getPermissionError('w');
            if (error) {
                return error;
            }
            var controller = this.resourceControllerService.getForResource(resource, true);
            return controller.actionExecuteSql(resource, query, parameters, { actionParams: params });
        }
        else if (query.queryType == QueryType.Object && query.objectQuery) {
            if (!this.currentProjectStore.instance.features.isCustomResourcesEnabled() && !resource.demo) {
                return throwError(new AppError('Custom Queries feature is not enabled in your Plan'));
            }
            var error = getPermissionError('w');
            if (error) {
                return error;
            }
            var controller = this.resourceControllerService.get(resource.type);
            return controller.objectQuery(resource, query.objectQuery, params);
        }
        else if (query.queryType == QueryType.Simple) {
            return combineLatest(this.modelDescriptionStore.getFirst(), this.actionStore.getFirst()).pipe(switchMap(function (_a) {
                var modelDescriptions = _a[0], actionDescriptions = _a[1];
                var controller = _this.resourceControllerService.get(resource.type);
                var project = _this.currentProjectStore.instance;
                var environment = _this.currentEnvironmentStore.instance;
                var actionDescription = actionDescriptions.find(function (item) {
                    return item.resource == resource.uniqueName && item.name == query.simpleQuery.name;
                });
                if (actionDescription && !isSet(actionDescription.modelAction) && !isSet(actionDescription.storageAction)) {
                    throwOnPermissionError('w');
                    // if (!project.hasEnvironmentModelPermission(environment, model.modelId, 'w')) {
                    //   throw new AppError('You dont have permission for run this operation');
                    // }
                    return controller.actionExecute(resource, query, parameters, data);
                }
                var modelDescription = actionDescription.modelAction
                    ? modelDescriptions.find(function (item) { return item.resource == actionDescription.resource && item.model == actionDescription.model; })
                    : undefined;
                var modelAction = modelDescription
                    ? modelDescription.autoActions().find(function (item) { return item.name == actionDescription.modelAction; })
                    : undefined;
                var storage = actionDescription.storageAction
                    ? resource.storages.find(function (item) { return item.isSame(actionDescription.storage); })
                    : undefined;
                var storageAction = storage
                    ? storage.autoActions().find(function (item) { return item.name == actionDescription.storageAction; })
                    : undefined;
                if (modelAction) {
                    var model_1 = new Model(_this.injector).deserialize(modelDescription.model, params);
                    var viewSettingsUid_1 = options.context &&
                        options.context.viewSettings &&
                        options.context.viewSettings instanceof CustomViewSettings
                        ? options.context.viewSettings.uid
                        : undefined;
                    model_1.setAttributes(params);
                    model_1.setUp(modelDescription);
                    if (modelAction.name == 'get') {
                        throwOnPermissionError('r');
                        if (!project.hasEnvironmentModelPermission(environment, model_1.modelId, 'r')) {
                            throw new AppError("You dont have permission for run this operation: " + actionDescription.anyName + " (" + resource.name + ")");
                        }
                        var columns = modelDescription.fields
                            .filter(function (item) { return item.type == ModelFieldType.Db; })
                            .map(function (item) { return modelFieldToRawListViewSettingsColumn(item); });
                        return _this.modelService
                            .getQuery(project, environment, resource, modelDescription.getQuery, modelDescription.getParameters, params, columns || [])
                            .pipe(map(function (result) {
                            return {
                                json: result.results.map(function (item) { return item.serialize(); })
                            };
                        }));
                    }
                    else if (modelAction.name == 'get_detail') {
                        throwOnPermissionError('r');
                        if (!project.hasEnvironmentModelPermission(environment, model_1.modelId, 'r')) {
                            throw new AppError("You dont have permission for run this operation: " + actionDescription.anyName + " (" + resource.name + ")");
                        }
                        var columns = modelDescription.fields
                            .filter(function (item) { return item.type == ModelFieldType.Db; })
                            .map(function (item) { return modelFieldToRawListViewSettingsColumn(item); });
                        return _this.modelService
                            .getDetailQuery(project, environment, resource, modelDescription.getDetailQuery, modelDescription.getDetailParametersOrDefaults, params, columns || [])
                            .pipe(map(function (result) {
                            return {
                                json: result.serialize()
                            };
                        }));
                    }
                    else if (modelAction.name == 'create') {
                        throwOnPermissionError('w');
                        if (!project.hasEnvironmentModelPermission(environment, model_1.modelId, 'w')) {
                            throw new AppError("You dont have permission for run this operation: " + actionDescription.anyName + " (" + resource.name + ")");
                        }
                        return controller.modelCreate(resource, modelDescription, model_1).pipe(delayWhen(function (result) {
                            return _this.modelService.onModelAction(project, environment, resource, controller, modelDescription, result, ModelAction.Create, {
                                viewSettings: viewSettingsUid_1
                            });
                        }), map(function (result) {
                            return {
                                json: result.serialize()
                            };
                        }));
                    }
                    else if (modelAction.name == 'update') {
                        throwOnPermissionError('w');
                        if (!project.hasEnvironmentModelPermission(environment, model_1.modelId, 'w')) {
                            throw new AppError("You dont have permission for run this operation: " + actionDescription.anyName + " (" + resource.name + ")");
                        }
                        var fields = modelDescription.dbFields
                            .map(function (item) { return item.name; })
                            .filter(function (name) { return params[name] !== undefined; });
                        return controller.modelUpdate(resource, modelDescription, model_1, fields).pipe(delayWhen(function () {
                            return _this.modelService.onModelAction(project, environment, resource, controller, modelDescription, model_1, ModelAction.Update, {
                                viewSettings: viewSettingsUid_1
                            });
                        }), map(function (result) {
                            if (result) {
                                _this._modelUpdated$.next({ modelDescription: modelDescription, model: result });
                            }
                            return {
                                json: result.serialize()
                            };
                        }));
                    }
                    else if (modelAction.name == 'delete') {
                        throwOnPermissionError('d');
                        if (!project.hasEnvironmentModelPermission(environment, model_1.modelId, 'd')) {
                            throw new AppError("You dont have permission for run this operation: " + actionDescription.anyName + " (" + resource.name + ")");
                        }
                        return controller.modelDelete(resource, modelDescription, model_1).pipe(delayWhen(function () {
                            return _this.modelService.onModelAction(project, environment, resource, controller, modelDescription, model_1, ModelAction.Delete, {
                                viewSettings: viewSettingsUid_1
                            });
                        }), map(function (result) {
                            return {
                                json: result
                            };
                        }));
                    }
                }
                else if (storageAction) {
                    if (storageAction.name == 'get_object_url') {
                        // checkPagePermission('r');
                        // if (!project.hasEnvironmentModelPermission(environment, model.modelId, 'r')) {
                        //   throw new AppError(
                        //     `You dont have permission for run this operation: ${actionDescription.anyName} (${resource.name})`
                        //   );
                        // }
                        var path = params ? params['path'] : undefined;
                        var expiresInSec = params ? params['expires'] : undefined;
                        return _this.storageService
                            .getObjectUrl(resource, storage, storage.getObjectUrlQuery, path, expiresInSec)
                            .pipe(map(function (result) {
                            return {
                                json: result
                            };
                        }));
                    }
                    else if (storageAction.name == 'upload') {
                        // checkPagePermission('r');
                        // if (!project.hasEnvironmentModelPermission(environment, model.modelId, 'r')) {
                        //   throw new AppError(
                        //     `You dont have permission for run this operation: ${actionDescription.anyName} (${resource.name})`
                        //   );
                        // }
                        var fileData = params ? params['file'] : undefined;
                        var path_1 = params ? params['path'] : undefined;
                        return _this.coerceFile(fileData).pipe(switchMap(function (file) {
                            return _this.storageService.upload(resource, storage, storage.getObjectUrlQuery, file.file, path_1).pipe(filter(function (event) { return !!event.result; }), map(function (result) {
                                return {
                                    json: result && result.result
                                        ? {
                                            path: result.result.uploadedPath,
                                            url: result.result.uploadedUrl
                                        }
                                        : undefined
                                };
                            }));
                        }));
                    }
                    else if (storageAction.name == 'get') {
                        // checkPagePermission('r');
                        // if (!project.hasEnvironmentModelPermission(environment, model.modelId, 'r')) {
                        //   throw new AppError(
                        //     `You dont have permission for run this operation: ${actionDescription.anyName} (${resource.name})`
                        //   );
                        // }
                        var path = params ? params['path'] : undefined;
                        return _this.storageService.getStorageObjects(resource, storage, storage.getObjectUrlQuery, path).pipe(map(function (result) {
                            return {
                                json: result
                            };
                        }));
                    }
                    else if (storageAction.name == 'create_directory') {
                        // checkPagePermission('r');
                        // if (!project.hasEnvironmentModelPermission(environment, model.modelId, 'r')) {
                        //   throw new AppError(
                        //     `You dont have permission for run this operation: ${actionDescription.anyName} (${resource.name})`
                        //   );
                        // }
                        var path = params ? params['path'] : undefined;
                        return _this.storageService.createStorageFolder(resource, storage, storage.getObjectUrlQuery, path).pipe(map(function (result) {
                            return {
                                json: result
                            };
                        }));
                    }
                    else if (storageAction.name == 'remove') {
                        // checkPagePermission('r');
                        // if (!project.hasEnvironmentModelPermission(environment, model.modelId, 'r')) {
                        //   throw new AppError(
                        //     `You dont have permission for run this operation: ${actionDescription.anyName} (${resource.name})`
                        //   );
                        // }
                        var path = params ? params['path'] : undefined;
                        return _this.storageService.deleteStorageObject(resource, storage, storage.getObjectUrlQuery, path).pipe(map(function (result) {
                            return {
                                json: result
                            };
                        }));
                    }
                }
                throw new AppError('No action found');
            }));
        }
        else {
            return throwError(new AppError('No query found'));
        }
    };
    ActionService.prototype.executeElement = function (path, context, params) {
        if (!context) {
            return throwError(new AppError('No context'));
        }
        var action = context.getElementAction(path);
        if (!action) {
            return throwError(new AppError('Action not found'));
        }
        var result = action.handler(params);
        return forceObservable(result);
    };
    ActionService.prototype.getActionDescriptionLabel = function (actionDescription, inputs, context, element) {
        if (!actionDescription) {
            return of(undefined);
        }
        if (actionDescription.type == ActionType.Query || actionDescription.type == ActionType.Download) {
            var resource_1 = this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == actionDescription.resource; });
            var query = void 0;
            if (actionDescription.type == ActionType.Query && actionDescription.queryAction) {
                query = actionDescription.queryAction.query;
            }
            else if (actionDescription.type == ActionType.Download && actionDescription.downloadAction) {
                query = actionDescription.downloadAction.query;
            }
            if (!resource_1 || !query || !query.queryType) {
                return of([actionDescription.typeStr]);
            }
            var prefix_1 = actionDescription.type == ActionType.Download ? [actionDescription.typeStr] : [];
            if (query.queryType == QueryType.Http) {
                return of(prefix_1.concat(['HTTP query', resource_1.name]));
            }
            else if (query.queryType == QueryType.SQL) {
                return of(prefix_1.concat(['SQL query', resource_1.name]));
            }
            else if (query.queryType == QueryType.Object) {
                return of(prefix_1.concat(['Database query', resource_1.name]));
            }
            else if (query.queryType == QueryType.Simple) {
                return this.modelDescriptionStore.getFirst().pipe(map(function (modelDescriptions) {
                    var modelDescription = actionDescription.modelAction
                        ? modelDescriptions.find(function (item) { return item.resource == actionDescription.resource && item.model == actionDescription.model; })
                        : undefined;
                    var modelAction = modelDescription
                        ? modelDescription.autoActions().find(function (item) { return item.name == actionDescription.modelAction; })
                        : undefined;
                    var storage = actionDescription.storageAction
                        ? resource_1.storages.find(function (item) { return item.isSame(actionDescription.storage); })
                        : undefined;
                    var storageAction = storage
                        ? storage.autoActions().find(function (item) { return item.name == actionDescription.storageAction; })
                        : undefined;
                    if (modelAction) {
                        return prefix_1.concat([
                            (modelDescription.verboseNamePlural || modelDescription.model) + " - " + modelAction.label,
                            resource_1.name
                        ]);
                    }
                    else if (storageAction) {
                        return prefix_1.concat([(storage.name || storage.uniqueName) + " - " + storageAction.label, resource_1.name]);
                    }
                    else {
                        return prefix_1.concat([resource_1.name + " query"]);
                    }
                }));
            }
        }
        else if (actionDescription.type == ActionType.Link) {
            if (!actionDescription.linkAction) {
                return of([actionDescription.typeStr]);
            }
            if (actionDescription.linkAction.type == SegueType.Page) {
                if (!actionDescription.linkAction.page) {
                    return of([actionDescription.typeStr]);
                }
                return this.viewSettingsStore.getDetailFirst(actionDescription.linkAction.page).pipe(map(function (viewSettings) {
                    if (!viewSettings) {
                        return [actionDescription.typeStr];
                    }
                    return [viewSettings.name, actionDescription.typeStr];
                }));
            }
            else if (actionDescription.linkAction.type == SegueType.PreviousPage) {
                return of(['Previous Page', actionDescription.typeStr]);
            }
            else if (actionDescription.linkAction.type == SegueType.ModelCreate) {
                return this.modelDescriptionStore.getDetailFirst(actionDescription.linkAction.model).pipe(map(function (modelDescription) {
                    if (!modelDescription) {
                        return [actionDescription.typeStr];
                    }
                    return [
                        (modelDescription.verboseNamePlural || modelDescription.model) + " - Create",
                        actionDescription.typeStr
                    ];
                }));
            }
            else if (actionDescription.linkAction.type == SegueType.ModelChange) {
                return this.modelDescriptionStore.getDetailFirst(actionDescription.linkAction.model).pipe(map(function (modelDescription) {
                    if (!modelDescription) {
                        return [actionDescription.typeStr];
                    }
                    return [
                        (modelDescription.verboseNamePlural || modelDescription.model) + " - Change",
                        actionDescription.typeStr
                    ];
                }));
            }
            else if (actionDescription.linkAction.type == SegueType.ModelMassEdit) {
                return this.modelDescriptionStore.getDetailFirst(actionDescription.linkAction.model).pipe(map(function (modelDescription) {
                    if (!modelDescription) {
                        return [actionDescription.typeStr];
                    }
                    return [
                        (modelDescription.verboseNamePlural || modelDescription.model) + " - Mass Edit",
                        actionDescription.typeStr
                    ];
                }));
            }
            else if (actionDescription.linkAction.type == SegueType.ModelDelete) {
                return this.modelDescriptionStore.getDetailFirst(actionDescription.linkAction.model).pipe(map(function (modelDescription) {
                    if (!modelDescription) {
                        return [actionDescription.typeStr];
                    }
                    return [
                        (modelDescription.verboseNamePlural || modelDescription.model) + " - Delete",
                        actionDescription.typeStr
                    ];
                }));
            }
            else if (actionDescription.linkAction.type == SegueType.ModelActivityLog) {
                return this.modelDescriptionStore.getDetailFirst(actionDescription.linkAction.model).pipe(map(function (modelDescription) {
                    if (!modelDescription) {
                        return [actionDescription.typeStr];
                    }
                    return [
                        (modelDescription.verboseNamePlural || modelDescription.model) + " - Activity Log",
                        actionDescription.typeStr
                    ];
                }));
            }
        }
        else if (actionDescription.type == ActionType.ExternalLink) {
            return merge((context.outputValues ? [of(context.outputValues)] : []).concat([
                context ? context.outputValues$ : of({})
            ])).pipe(debounceTime(600), map(function () {
                var params = applyParamInputs({}, inputs, {
                    context: context,
                    contextElement: element,
                    parameters: actionDescription.actionParams
                });
                if (!params['href']) {
                    return [actionDescription.typeStr];
                }
                return [params['href'], actionDescription.typeStr];
            }));
        }
        else if (actionDescription.type == ActionType.ElementAction) {
            if (!actionDescription.elementAction || !context) {
                return of([actionDescription.typeStr]);
            }
            return context.elementActionPath$(actionDescription.elementAction).pipe(map(function (result) {
                return [
                    result.slice().reverse()
                        .map(function (item) { return item.name; })
                        .join(' · '),
                    actionDescription.typeStr
                ];
            }));
        }
        else if (actionDescription.type == ActionType.ShowNotification) {
            if (!actionDescription.notificationAction) {
                return of([actionDescription.typeStr]);
            }
            if (actionDescription.notificationAction && actionDescription.notificationAction.title) {
                var title = void 0;
                try {
                    title = applyParamInput(actionDescription.notificationAction.title, {
                        context: context,
                        contextElement: element,
                        defaultValue: ''
                    });
                }
                catch (e) { }
                return of([title, actionDescription.typeStr]);
            }
            else {
                return of([actionDescription.typeStr]);
            }
        }
        else if (actionDescription.type == ActionType.SetProperty) {
            if (!actionDescription.setPropertyAction || !isSet(!actionDescription.setPropertyAction.property)) {
                return of([actionDescription.typeStr]);
            }
            return this.projectPropertyStore.getDetail(actionDescription.setPropertyAction.property).pipe(map(function (property) {
                if (!property) {
                    return [actionDescription.typeStr];
                }
                return [actionDescription.typeStr + " \"" + property.name + "\""];
            }));
        }
        else if (actionDescription.type == ActionType.RunJavaScript) {
            if (actionDescription.runJavaScriptAction && actionDescription.runJavaScriptAction.js) {
                var code = truncate(actionDescription.runJavaScriptAction.js, { length: 64 });
                return of([code, actionDescription.typeStr]);
            }
            else {
                return of([actionDescription.typeStr]);
            }
        }
        else if (actionDescription.type == ActionType.CopyToClipboard) {
            if (!actionDescription.copyToClipboardAction) {
                return of([actionDescription.typeStr]);
            }
            if (actionDescription.copyToClipboardAction && actionDescription.copyToClipboardAction.value) {
                var value = void 0;
                try {
                    value = applyParamInput(actionDescription.copyToClipboardAction.value, {
                        context: context,
                        contextElement: element,
                        defaultValue: ''
                    });
                }
                catch (e) { }
                return of([value, actionDescription.typeStr]);
            }
            else {
                return of([actionDescription.typeStr]);
            }
        }
        else if (actionDescription.type == ActionType.Export) {
            if (!actionDescription.exportAction) {
                return of([actionDescription.typeStr]);
            }
            var modelId = actionDescription.exportAction.getModelId();
            return this.modelDescriptionStore.getDetailFirst(modelId).pipe(map(function (modelDescription) {
                if (!modelDescription) {
                    return [actionDescription.typeStr];
                }
                return ["" + (modelDescription.verboseNamePlural || modelDescription.model), actionDescription.typeStr];
            }));
        }
        else if (actionDescription.type == ActionType.Import) {
            if (!actionDescription.importAction) {
                return of([actionDescription.typeStr]);
            }
            var modelId = actionDescription.importAction.getModelId();
            return this.modelDescriptionStore.getDetailFirst(modelId).pipe(map(function (modelDescription) {
                if (!modelDescription) {
                    return [actionDescription.typeStr];
                }
                return ["" + (modelDescription.verboseNamePlural || modelDescription.model), actionDescription.typeStr];
            }));
        }
        else if (actionDescription.type == ActionType.OpenPopup) {
            return of([actionDescription.typeStr]);
        }
        else if (actionDescription.type == ActionType.ClosePopup) {
            return of([actionDescription.typeStr]);
        }
        else if (actionDescription.type == ActionType.ScanCode) {
            return of([actionDescription.typeStr]);
        }
        else if (actionDescription.type == ActionType.Workflow) {
            if (!actionDescription.workflowAction || !actionDescription.workflowAction.workflow) {
                return of([actionDescription.typeStr]);
            }
            var steps = actionDescription.workflowAction.workflow.getStepsCount();
            return of([actionDescription.typeStr, steps == 1 ? steps + " step" : steps + " steps"]);
        }
        return of(undefined);
    };
    ActionService.prototype.executeWorkflow = function (workflow, params, options) {
        var _this = this;
        if (params === void 0) { params = {}; }
        if (options === void 0) { options = {}; }
        return Observable.create(function (observer) {
            var workflowRun = new WorkflowRun();
            var localContext = __assign({}, options.localContext, { workflow: params, steps: {} });
            var event$ = new Subject();
            var eventsSubscription = event$.subscribe(function (result) {
                observer.next(result);
                if (result.type == WorkflowExecuteEventType.StepFinished) {
                    var stepRun = new WorkflowStepRun();
                    stepRun.uid = result.step.uid;
                    stepRun.params = limitObjectLength(result.params, 20);
                    stepRun.result = limitObjectLength(result.result, 20);
                    stepRun.error = result.error;
                    workflowRun.stepRuns.push(stepRun);
                }
                else if (result.type == WorkflowExecuteEventType.WorkflowFinished && workflow.result) {
                    var stepRun = new WorkflowStepRun();
                    stepRun.result = limitObjectLength(result.result, 20);
                    stepRun.error = result.error;
                    workflowRun.resultStepRun = stepRun;
                }
            }, function (error) {
                observer.error(error);
            }, function () { return observer.complete(); });
            event$.next({
                type: WorkflowExecuteEventType.WorkflowStarted
            });
            var executionSubscription = _this.executeWorkflowSteps(workflow.steps, event$, __assign({}, options, { localContext: localContext })).subscribe(function (result) {
                if (workflow.result && workflow.result.array) {
                    result = applyParamInput(workflow.result.arrayInput, {
                        context: options.context,
                        contextElement: options.contextElement,
                        localContext: localContext
                    });
                }
                else if (workflow.result && !workflow.result.array) {
                    result = applyParamInputs({}, workflow.result.inputs, {
                        context: options.context,
                        contextElement: options.contextElement,
                        localContext: localContext,
                        parameters: workflow.result.parameters
                    });
                }
                event$.next({
                    type: WorkflowExecuteEventType.WorkflowFinished,
                    success: true,
                    result: result,
                    run: workflowRun
                });
                event$.complete();
            }, function (error) {
                event$.next({
                    type: WorkflowExecuteEventType.WorkflowFinished,
                    success: false,
                    error: error,
                    run: workflowRun
                });
                event$.complete();
            });
            return function () {
                executionSubscription.unsubscribe();
                eventsSubscription.unsubscribe();
            };
        }).pipe(share());
    };
    ActionService.prototype.executeWorkflowSteps = function (steps, event$, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        if (!steps.length) {
            return of(undefined);
        }
        var iterate = function (index) {
            var step = steps[index];
            event$.next({
                type: WorkflowExecuteEventType.StepStarted,
                step: step
            });
            return timer(options.delayBefore || 0).pipe(switchMap(function () { return _this.executeWorkflowStep(step, event$, options); }), catchError(function (error) {
                return of({ error: error });
            }), switchMap(function (result) {
                var _a;
                if (result.error && result.error instanceof WorkflowExecuteStepError) {
                    return throwError(result.error);
                }
                else if (result.error) {
                    event$.next({
                        type: WorkflowExecuteEventType.StepFinished,
                        step: step,
                        success: false,
                        params: result.params,
                        error: result.error
                    });
                    return throwError(new WorkflowExecuteStepError(step, result.error));
                }
                if (options.localContext) {
                    options.localContext['steps'][step.uid] = __assign({}, options.localContext['steps'][step.uid], (_a = {}, _a[SUBMIT_RESULT_OUTPUT] = result.result, _a));
                }
                event$.next({
                    type: WorkflowExecuteEventType.StepFinished,
                    step: step,
                    success: true,
                    params: result.params,
                    result: result.result
                });
                var nextIndex = index + 1;
                var nextStep = steps[nextIndex];
                if (nextStep) {
                    return iterate(nextIndex);
                }
                else {
                    return of(result.result);
                }
            }));
        };
        return iterate(0);
    };
    ActionService.prototype.executeWorkflowStep = function (step, event$, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        if (step instanceof ActionWorkflowStep) {
            if (!step.action) {
                return throwError(new ServerRequestError('No step action specified'));
            }
            return this.getActionDescription(step.action).pipe(switchMap(function (actionDescription) {
                if (!actionDescription) {
                    throw new ServerRequestError('Action not found specified');
                }
                var inputs = step.action.inputs.slice();
                if (actionDescription.type == ActionType.Export &&
                    actionDescription.exportAction &&
                    actionDescription.exportAction.dataSource) {
                    inputs.push.apply(inputs, actionDescription.exportAction.dataSource.queryInputs);
                }
                var params = applyParamInputs({}, inputs, {
                    context: options.context,
                    contextElement: options.contextElement,
                    localContext: options.localContext,
                    parameters: actionDescription.actionParams
                });
                return _this.executeActionDescription(actionDescription, params, options).pipe(mapWithError(function (result, error) {
                    return {
                        params: params,
                        result: result,
                        error: error
                    };
                }), switchMap(function (parentResult) {
                    var _a;
                    if (step.resultSteps && parentResult.result && step.successSteps.length) {
                        options.localContext['steps'][step.uid] = __assign({}, options.localContext['steps'][step.uid], (_a = {}, _a[SUBMIT_RESULT_OUTPUT] = parentResult.result, _a));
                        return _this.executeWorkflowSteps(step.successSteps, event$, options).pipe(mapWithError(function (result, error) {
                            return {
                                params: params,
                                result: result,
                                error: error
                            };
                        }));
                    }
                    else if (step.resultSteps && parentResult.error) {
                        var outputs = {};
                        if (parentResult.error instanceof ServerRequestError && parentResult.error.errors.length) {
                            outputs[SUBMIT_ERROR_OUTPUT] = parentResult.error.errors[0];
                        }
                        else {
                            outputs[SUBMIT_ERROR_OUTPUT] = String(parentResult.error);
                        }
                        if (parentResult.error instanceof ServerRequestError &&
                            parentResult.error.response instanceof HttpErrorResponse) {
                            outputs[HTTP_BODY_OUTPUT] = parentResult.error.response.error;
                            outputs[HTTP_CODE_OUTPUT] = parentResult.error.response.status;
                            outputs[HTTP_STATUS_OUTPUT] = parentResult.error.response.statusText;
                        }
                        options.localContext['steps'][step.uid] = __assign({}, options.localContext['steps'][step.uid], outputs);
                        return _this.executeWorkflowSteps(step.errorSteps, event$, options).pipe(mapWithError(function (result, error) {
                            return {
                                params: params,
                                result: result,
                                error: error
                            };
                        }));
                    }
                    else {
                        return of(parentResult);
                    }
                }));
            }));
        }
        else if (step instanceof ConditionWorkflowStep) {
            if (step.conditionType == ConditionWorkflowStepType.Boolean) {
                if (!step.items.length) {
                    return throwError(new ServerRequestError('No conditions found'));
                }
                var conditionIsTrue = applyParamInput(step.items[0].condition, {
                    context: options.context,
                    contextElement: options.contextElement,
                    localContext: options.localContext,
                    defaultValue: false
                });
                var params_1 = {
                    conditions: [conditionIsTrue]
                };
                if (conditionIsTrue) {
                    var steps = step.items[0].steps;
                    return this.executeWorkflowSteps(steps, event$, options).pipe(mapWithError(function (result, error) {
                        return {
                            params: params_1,
                            result: result,
                            error: error
                        };
                    }));
                }
                else {
                    var steps = step.items[1] ? step.items[1].steps : [];
                    return this.executeWorkflowSteps(steps, event$, options).pipe(mapWithError(function (result, error) {
                        return {
                            params: params_1,
                            result: result,
                            error: error
                        };
                    }));
                }
            }
            else if (step.conditionType == ConditionWorkflowStepType.Switch) {
                var params_2 = {
                    conditions: step.items.map(function () { return false; })
                };
                for (var i = 0; i < step.items.length; ++i) {
                    var item = step.items[i];
                    var conditionIsTrue = applyParamInput(item.condition, {
                        context: options.context,
                        contextElement: options.contextElement,
                        localContext: options.localContext,
                        defaultValue: false
                    });
                    params_2.conditions[i] = conditionIsTrue;
                    if (conditionIsTrue) {
                        return this.executeWorkflowSteps(item.steps, event$, options).pipe(mapWithError(function (result, error) {
                            return {
                                params: params_2,
                                result: result,
                                error: error
                            };
                        }));
                    }
                }
                return this.executeWorkflowSteps(step.elseSteps, event$, options).pipe(mapWithError(function (result, error) {
                    return {
                        params: params_2,
                        result: result,
                        error: error
                    };
                }));
            }
            else {
                return throwError(new ServerRequestError('Unknown condition type'));
            }
        }
        else if (step instanceof IteratorWorkflowStep) {
            if (!step.dataSource) {
                return of({
                    result: []
                });
            }
            var dataSourceParams_1 = applyParamInputs({}, step.dataSource.queryInputs, {
                context: options.context,
                contextElement: options.contextElement,
                localContext: options.localContext,
                parameters: step.dataSource.queryParameters,
                handleLoading: true,
                ignoreEmpty: true,
                emptyValues: EMPTY_FILTER_VALUES
            });
            var staticData_1 = step.dataSource.type == DataSourceType.Input && step.dataSource.input
                ? applyParamInput(step.dataSource.input, {
                    context: options.context,
                    contextElement: options.contextElement,
                    localContext: options.localContext,
                    defaultValue: [],
                    handleLoading: true,
                    ignoreEmpty: true
                })
                : [];
            if (isSet(staticData_1)) {
                staticData_1 = coerceArray(staticData_1).map(function (item) {
                    var _a;
                    if (isArray(item) || isPlainObject(item)) {
                        return item;
                    }
                    else {
                        return _a = {},
                            _a[NO_KEY_ATTRIBUTE] = item,
                            _a;
                    }
                });
            }
            else {
                staticData_1 = [];
            }
            var stepResultParams_1 = {
                params: dataSourceParams_1,
                iterate: []
            };
            var itemsIteration_1 = function (items, index, iterationAcc) {
                if (iterationAcc === void 0) { iterationAcc = []; }
                var _a, _b;
                var item = items[index];
                return _this.executeWorkflowSteps(step.steps, event$, __assign({}, options, { localContext: __assign({}, options.localContext, { steps: __assign({}, (options.localContext && options.localContext['steps']), (_a = {}, _a[step.uid] = __assign({}, (options.localContext && options.localContext['steps'] && options.localContext['steps'][step.uid]), (_b = {}, _b[ITEM_OUTPUT] = item, _b)), _a)) }) })).pipe(switchMap(function (result) {
                    var nextIndex = index + 1;
                    var nextItem = items[nextIndex];
                    iterationAcc.push(result);
                    if (nextItem) {
                        return itemsIteration_1(items, nextIndex, iterationAcc);
                    }
                    else {
                        return of(iterationAcc);
                    }
                }));
            };
            var getIteration_1 = function (paging, resultAcc) {
                if (resultAcc === void 0) { resultAcc = []; }
                var queryOptions = paramsToGetQueryOptions(dataSourceParams_1);
                queryOptions.paging = paging;
                queryOptions.columns = step.dataSource.columns;
                if (isSet(step.sortingField)) {
                    queryOptions.sort = [{ field: step.sortingField, desc: !step.sortingAsc }];
                }
                queryOptions = applyQueryOptionsFilterInputs(step.dataSource, queryOptions);
                return _this.getDataSourceDataAdv({
                    project: _this.currentProjectStore.instance,
                    environment: _this.currentEnvironmentStore.instance,
                    dataSource: step.dataSource,
                    queryOptions: queryOptions,
                    staticData: staticData_1,
                    context: options.context,
                    contextElement: options.contextElement
                }).pipe(switchMap(function (response) {
                    var _a;
                    var iterate = response.results.map(function (item) { return item.serialize(); });
                    (_a = stepResultParams_1.iterate).push.apply(_a, iterate);
                    return itemsIteration_1(iterate, 0).pipe(switchMap(function (iterationResult) {
                        resultAcc.push.apply(resultAcc, iterationResult);
                        if (response.hasMore) {
                            var newPaging = __assign({}, paging, (isSet(paging.page) && { page: paging.page + 1 }), { cursorNext: response.cursorNext, cursorPrev: response.cursorPrev });
                            return getIteration_1(newPaging, resultAcc);
                        }
                        else {
                            return of(resultAcc);
                        }
                    }));
                }));
            };
            return getIteration_1({ page: 1 }).pipe(mapWithError(function (result, error) {
                return {
                    params: stepResultParams_1,
                    result: result,
                    error: error
                };
            }));
        }
        else if (step instanceof ForkWorkflowStep) {
            if (!step.items.length) {
                return of({
                    result: []
                });
            }
            return combineLatest(step.items.map(function (item) {
                return _this.executeWorkflowSteps(item.steps, event$, options);
            })).pipe(first(), mapWithError(function (result, error) {
                return {
                    result: result,
                    error: error
                };
            }));
        }
        else if (step instanceof DelayWorkflowStep) {
            if (!step.delay || step.delay < 1 || step.delay > 300) {
                return throwError(new ServerRequestError('Delay should be between 1 and 300 seconds'));
            }
            return timer(step.delay * 1000).pipe(mapWithError(function (result, error) {
                return {
                    result: result,
                    error: error
                };
            }));
        }
        else if (step instanceof TransformWorkflowStep) {
            if (!step.value || !step.value.isSet()) {
                return throwError(new ServerRequestError('Transformation is not set'));
            }
            var value = applyParamInput(step.value, {
                context: options.context,
                contextElement: options.contextElement,
                localContext: options.localContext,
                defaultValue: {}
            });
            return of(value).pipe(mapWithError(function (result, error) {
                return {
                    result: result,
                    error: error
                };
            }));
        }
        else if (step instanceof ExitWorkflowStep) {
            var errorText = step.errorText
                ? applyParamInput(step.errorText, {
                    context: options.context,
                    contextElement: options.contextElement,
                    localContext: options.localContext,
                    defaultValue: ''
                })
                : '';
            var error = new WorkflowExecuteStepError(step, errorText, !isSet(errorText));
            event$.next({
                type: WorkflowExecuteEventType.StepFinished,
                step: step,
                success: false,
                error: error
            });
            return throwError(error);
        }
        else {
            return throwError(new ServerRequestError('Unknown step type'));
        }
    };
    ActionService.prototype.getDataSourceDataAdv = function (options) {
        var params = getQueryOptionsToParams(options.queryOptions);
        if (options.dataSource.type == DataSourceType.Query) {
            var resource = options.project
                .getEnvironmentResources(options.environment.uniqueName)
                .find(function (item) { return item.uniqueName == options.dataSource.queryResource; });
            return this.modelService.getQueryAdv(options.project, options.environment, resource, options.dataSource.query, options.dataSource.queryParameters, options.queryOptions, (options.dataSource.columns || []).filter(function (item) { return item.type != DisplayFieldType.Computed; }));
        }
        else if (options.dataSource.type == DataSourceType.Input) {
            var result = isArray(options.staticData) ? options.staticData : [options.staticData];
            result = applyFrontendFiltering(result, params, options.dataSource.columns);
            var data = {
                results: result,
                count: result.length
            };
            var response = this.createGetResponse().deserialize(data, undefined, undefined);
            // Backward compatibility
            if (!options.dataSource.columns.length) {
                var columns = this.queryService.autoDetectGetFields(result);
                if (columns) {
                    options.dataSource.columns = columns.map(function (item) { return rawListViewSettingsColumnsToDisplayField(item); });
                }
            }
            response.results.forEach(function (item) {
                item.deserializeAttributes(options.dataSource.columns);
            });
            applyFrontendPagination(response, params, true);
            return of(response);
        }
        else {
            return of(undefined);
        }
    };
    ActionService.prototype.getStepsInfo = function (steps, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        if (!steps.length) {
            return of([]);
        }
        return combineLatest.apply(void 0, steps.map(function (item) { return _this.getStepInfo(item); }));
    };
    ActionService.prototype.getStepInfo = function (step, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        if (step instanceof ActionWorkflowStep) {
            return this.getActionDescription(step.action).pipe(switchMap(function (actionDescription) {
                return _this.getActionDescriptionLabel(actionDescription, step.action ? step.action.inputs : [], options.context, options.contextElement).pipe(map(function (labels) { return [actionDescription, labels]; }));
            }), map(function (_a) {
                var actionDescription = _a[0], labels = _a[1];
                var resource = actionDescription
                    ? _this.currentEnvironmentStore.resources.find(function (i) { return i.uniqueName == actionDescription.resource; })
                    : undefined;
                var image;
                var icon;
                if (resource) {
                    image = resource.icon;
                }
                else {
                    icon = step.getIcon();
                }
                return {
                    icon: icon,
                    image: image,
                    labels: labels
                };
            }));
        }
        else if (step instanceof ConditionWorkflowStep) {
            var labels = [];
            if (step.conditionType == ConditionWorkflowStepType.Boolean) {
                labels.push('Yes/No');
            }
            else if (step.conditionType == ConditionWorkflowStepType.Switch) {
                labels.push('Switch');
            }
            return of({
                icon: step.getIcon(),
                labels: labels
            });
        }
        else if (step instanceof IteratorWorkflowStep) {
            return of({
                icon: step.getIcon(),
                labels: ['Iterator']
            });
        }
        else if (step instanceof ForkWorkflowStep) {
            var labels = [];
            if (step.name) {
                labels.push(step.name);
            }
            return of({
                icon: step.getIcon(),
                labels: labels
            });
        }
        else if (step instanceof DelayWorkflowStep) {
            var labels = [];
            var seconds = step.delay;
            var minutes = seconds / 60;
            var hours = minutes / 60;
            if (hours >= 1) {
                labels.push(round(hours, 1) + " " + (hours == 1 ? 'hour' : 'hours'));
            }
            else if (minutes >= 1) {
                labels.push(round(minutes, 1) + " " + (minutes == 1 ? 'minute' : 'minutes'));
            }
            else {
                labels.push(round(seconds, 1) + " " + (seconds == 1 ? 'second' : 'seconds'));
            }
            if (step.name) {
                labels.push(step.name);
            }
            return of({
                icon: step.getIcon(),
                labels: labels
            });
        }
        else if (step instanceof TransformWorkflowStep) {
            return of({
                icon: step.getIcon(),
                labels: ['Transform']
            });
        }
        else {
            return of({});
        }
    };
    ActionService.prototype.processResponse = function (response) {
        if (!response) {
            return;
        }
        if (response.json) {
            var body = response.json;
            if (body['message']) {
                this.notificationService.success('Action Executed', body['message']);
            }
        }
        else if (response.blob) {
            var contentDisposition = response.response.headers.get('Content-Disposition') || '';
            var matches = /filename=(?:(?:"([^;]+)")|([^;]+))/gi.exec(contentDisposition);
            var defaultFilename = 'action_result';
            var fileName = ((matches ? matches[1] || matches[2] : undefined) || defaultFilename).trim();
            saveAs(response.blob, fileName);
        }
    };
    ActionService.prototype.runOnConfirm = function (handler, confirm) {
        if (confirm) {
            return this.dialogService.confirm(confirm).pipe(map(function (confirmed) {
                if (confirmed) {
                    return handler();
                }
                else {
                    return;
                }
            }));
        }
        else {
            return of(handler());
        }
    };
    ActionService.prototype.runOnRedirectConfirm = function (url, handler, confirm) {
        var dialogOptions = confirm
            ? {
                title: 'Redirect on "page opens" action',
                description: "\n            You should be redirected to the following URL:<br>\n            <strong>" + url + "</strong><br>\n            <small>(This message is displayed in Builder mode only)</small>\n          ",
                style: 'orange',
                buttons: [
                    {
                        name: 'cancel',
                        label: 'Cancel',
                        type: DialogButtonType.Default,
                        hotkey: DialogButtonHotkey.Cancel
                    },
                    {
                        name: 'ok',
                        label: 'Redirect',
                        type: DialogButtonType.Submit,
                        hotkey: DialogButtonHotkey.Submit
                    }
                ]
            }
            : undefined;
        return this.runOnConfirm(handler, dialogOptions);
    };
    Object.defineProperty(ActionService.prototype, "modelUpdated$", {
        get: function () {
            return this._modelUpdated$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    return ActionService;
}());
export { ActionService };
