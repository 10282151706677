<form [formGroup]="form" (ngSubmit)="submit()">
  <app-customize-bar-header
    [title]="form.controls.name.value || (titleEditable ? 'filter' : 'Filter')"
    [titleEnabled]="titleEnabled"
    [titleEditable]="titleEditable"
    [titleCleanValue]="titleCleanValue"
    [subtitle]="'Customization'"
    [backLabel]="'All components'"
    [deleteEnabled]="deleteEnabled"
    [parentLabel]="parentElement?.displayName || parentPopup?.displayName"
    (parentClick)="parentElement?.customize({ event: $event }) || parentPopup?.customize({ event: $event })"
    (deleteClick)="delete()"
    (titleChanged)="onTitleChanged($event)"
  >
  </app-customize-bar-header>

  <div class="sidebar__content">
    <app-sidebar-tabs>
      <app-sidebar-tab [label]="'General'">
        <app-sidebar-section>
          <div class="sidebar__element">
            <app-bind-component
              [inputs]="
                form.controls.element.value
                  ? [
                      {
                        name: 'value',
                        value_type: 'context',
                        context_value: ['elements', form.controls.element.value, '0']
                      } | appFieldInput
                    ]
                  : []
              "
              [context]="context"
              [element]="element"
              [targetBindField]="false"
              [targetBindPrimaryKey]="true"
              [targetElementType]="elementTypes.List"
              [selfBindPrimaryKey]="false"
              [selfBindField]="'value'"
              [analyticsSource]="element.analyticsGenericName"
              (updateInputs)="form.controls.element.patchValue($event[0].contextValue[1])"
            ></app-bind-component>
          </div>

          <div *ngIf="form.controls.element.value" class="sidebar__list">
            <app-customize-bar-filter-edit-items
              [element]="element"
              [form]="form"
              [context]="context"
              [contextElement]="contextElement"
            ></app-customize-bar-filter-edit-items>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Display'">
        <app-sidebar-section>
          <div class="sidebar__element">
            <app-sidebar-field [label]="'style'">
              <div class="sidebar-image-toggle sidebar-image-toggle_cols_3">
                <div *ngFor="let item of form.styleOptions" class="sidebar-image-toggle__col">
                  <a
                    href="javascript:void(0)"
                    class="sidebar-image-toggle__item"
                    [class.sidebar-image-toggle__item_active]="item.value === form.controls.style.value"
                    (click)="form.controls.style.patchValue(item.value)"
                  >
                    <div
                      class="sidebar-image-toggle__item-inner"
                      [style.padding-top.%]="(29 / 63) * 100"
                      [style.background-image]="'/assets/images/filter/' + item.image + '.svg' | appDeployCssUrl"
                    ></div>
                  </a>
                </div>
              </div>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field
              [label]="'Conditional Visibility'"
              [documentation]="'conditional-visibility'"
              [documentationLabel]="'See how it works'"
              [documentationSource]="element.analyticsGenericName + '_visible'"
            >
              <app-input-edit
                [itemForm]="form.controls.visible_input"
                [context]="context"
                [staticValueDisabled]="true"
                [placeholder]="'Value'"
                [fill]="true"
                [analyticsSource]="element.analyticsGenericName + '_visible'"
              ></app-input-edit>

              <ng-container description>
                Example: elements.field.value >= 10
              </ng-container>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'Spacing'">
              <app-margin-control [control]="form.controls.margin"></app-margin-control>
            </app-sidebar-field>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>
    </app-sidebar-tabs>
  </div>

  <!--  <app-sidebar-submit-->
  <!--    [saveDisabled]="form.invalid || form.disabled"-->
  <!--    [errors]="form | appFormErrors"-->
  <!--    (cancelClick)="cancel()"-->
  <!--    (deleteClick)="delete()"-->
  <!--  >-->
  <!--  </app-sidebar-submit>-->
</form>
