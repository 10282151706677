import isArray from 'lodash/isArray';

import { ParameterField } from '@modules/fields';
import { ModelDescriptionQuery } from '@modules/queries';

import { getElementByType } from '../elements/element-items';
import { ElementItem } from '../elements/items/base';
import { ViewSettingsAction } from '../view-settings-action';
import { ViewSettings, ViewSettingsType } from './base';

export class ChangeViewSettings extends ViewSettings {
  public view = ViewSettingsType.Change;
  public getResource: string;
  public getQuery: ModelDescriptionQuery;
  public elements: ElementItem[] = [];
  public actions: ViewSettingsAction[] = [];

  deserialize(data: Object) {
    super.deserialize(data);
    this.getResource = this.params['get_resource'];

    if (this.params['get_query']) {
      this.getQuery = new ModelDescriptionQuery().deserialize(this.params['get_query']);
    }

    if (this.params['parameters']) {
      this.parameters = this.params['parameters'].map(item => new ParameterField().deserialize(item));
    }

    const mapElement = item => {
      const element = getElementByType(item['type']);
      if (!element) {
        console.error(`Unsupported element type: ${item['type']}`);
        return;
      }
      return new element().deserialize(item);
    };

    if (this.params && this.params['elements']) {
      this.elements = this.params['elements'].map(mapElement).filter(item => item != undefined);
    } else if (this.params && isArray(this.params)) {
      this.elements = this.params.map(mapElement).filter(item => item != undefined);
    }

    if (this.params['actions']) {
      this.actions = this.params['actions'].map(item => new ViewSettingsAction().deserialize(item));
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = {};
    this.params['parameters'] = this.parameters.map(item => item.serialize());
    this.params['elements'] = this.elements.map(item => item.serialize());
    this.params['actions'] = this.actions.map(item => item.serialize());
    this.params['get_resource'] = this.getResource;
    this.params['get_query'] = this.getQuery ? this.getQuery.serialize() : undefined;

    return super.serialize(fields);
  }
}
