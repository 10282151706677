import Delta from 'quill-delta';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { asyncScheduler, concat, Observable, of } from 'rxjs';
import { map, throttleTime } from 'rxjs/operators';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { applyParamInput, contextInputValueTypes, Input as FieldInput } from '@modules/fields';
import { isSet } from '@shared';

export function quillDeltaToHtml(
  delta: Delta,
  options: { context?: ViewContext; contextElement?: ViewContextElement } = {}
): Observable<string> {
  if (!delta) {
    return of(undefined);
  }

  const hasContextFormula =
    options.context &&
    delta.ops.some(item => {
      return (
        item['insert'] &&
        item['insert']['context-formula'] &&
        contextInputValueTypes.includes(item['insert']['context-formula']['value_type'])
      );
    });
  const converter = new QuillDeltaToHtmlConverter(delta.ops, {});

  converter.renderCustomWith((customOp, contextOp) => {
    if (customOp.insert.type === 'context-formula') {
      const input = new FieldInput();

      if (customOp.insert.value) {
        input.deserialize(customOp.insert.value);
      }

      let value: any;

      try {
        value = applyParamInput(input, { ...options, defaultValue: '' });
      } catch (e) {}

      const html = `<span class="inline-input">${isSet(value) ? value : ''}</span>`;
      return customOp.attributes ? surroundHtmlWithQuillAttributesApplied(html, customOp.attributes) : html;
    }
  });

  const obs = hasContextFormula
    ? concat(of(options.context.outputValues), options.context.outputValues$).pipe(
        throttleTime(10, asyncScheduler, { leading: true, trailing: true })
      )
    : of({});

  return obs.pipe(map(() => converter.convert()));
}

export function surroundHtmlWithQuillAttributesApplied(html: string, attributes: Object): string {
  const placeholder = '____jet-context-formula____';
  const ops = [{ insert: placeholder, attributes }];
  const converter = new QuillDeltaToHtmlConverter(ops, { paragraphTag: 'span' });
  const result = converter.convert();

  return result.replace(placeholder, html);
}
