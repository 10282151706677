var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActionControllerService } from '@modules/action-queries';
import { CustomizeService, ElementType, registerElementComponent, ScannerElementItem, ViewContextElement } from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { FieldType } from '@modules/fields';
import { isSet } from '@shared';
import { VALUE_OUTPUT } from '../auto-field-element/auto-field-element.component';
import { CustomPagePopupComponent } from '../custom-page-popup/custom-page-popup.component';
var ScannerElementComponent = /** @class */ (function (_super) {
    __extends(ScannerElementComponent, _super);
    function ScannerElementComponent(customizeService, viewContextElement, actionControllerService, popup, injector) {
        var _this = _super.call(this) || this;
        _this.customizeService = customizeService;
        _this.viewContextElement = viewContextElement;
        _this.actionControllerService = actionControllerService;
        _this.popup = popup;
        _this.injector = injector;
        _this.scanValue$ = new BehaviorSubject(undefined);
        return _this;
    }
    ScannerElementComponent.prototype.ngOnInit = function () {
        this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(function (item) { return !!item; }));
        this.initContext();
        this.updateContextOutputs();
        this.updateContextOutputValues();
    };
    ScannerElementComponent.prototype.ngOnDestroy = function () { };
    ScannerElementComponent.prototype.ngOnChanges = function (changes) {
        if (changes.element && !changes.element.firstChange) {
            this.updateContextInfo();
        }
    };
    ScannerElementComponent.prototype.initContext = function () {
        this.viewContextElement.initElement({
            uniqueName: this.element.uid,
            name: this.element.name,
            icon: 'qr_code',
            allowSkip: true,
            element: this.element,
            popup: this.popup ? this.popup.popup : undefined
        });
    };
    ScannerElementComponent.prototype.updateContextInfo = function () {
        this.viewContextElement.initInfo({
            name: this.element.name,
            element: this.element,
            getFieldValue: function (field, outputs) {
                return outputs[VALUE_OUTPUT];
            }
        });
    };
    ScannerElementComponent.prototype.updateContextOutputs = function () {
        this.viewContextElement.setOutputs([
            {
                uniqueName: VALUE_OUTPUT,
                name: 'Value',
                icon: 'qr_code',
                fieldType: FieldType.Text,
                external: true
            }
        ]);
    };
    ScannerElementComponent.prototype.updateContextOutputValues = function () {
        var _this = this;
        this.scanValue$.pipe(untilDestroyed(this)).subscribe(function (value) {
            _this.viewContextElement.setOutputValue(VALUE_OUTPUT, value);
        });
    };
    ScannerElementComponent.prototype.onScan = function (event) {
        var _this = this;
        if (!isSet(event.text)) {
            return;
        }
        var valueChange = this.scanValue$.value != event.text;
        if (valueChange) {
            this.scanValue$.next(event.text);
        }
        if (valueChange || !this.element.ignoreDuplicateScans) {
            this.element.onScanActions.forEach(function (action) {
                var _a;
                _this.actionControllerService
                    .execute(action, {
                    context: _this.context,
                    contextElement: _this.viewContextElement,
                    localContext: (_a = {},
                        _a[VALUE_OUTPUT] = event.text,
                        _a),
                    injector: _this.injector
                })
                    .subscribe();
            });
        }
    };
    return ScannerElementComponent;
}(BaseElementComponent));
export { ScannerElementComponent };
registerElementComponent({
    type: ElementType.Scanner,
    component: ScannerElementComponent,
    label: 'Scanner',
    actions: []
});
