import { Injectable } from '@angular/core';
import fromPairs from 'lodash/fromPairs';
import values from 'lodash/values';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ActionStore } from '@modules/action-queries';
import { ActionDescription, ActionType, QueryAction } from '@modules/actions';
import {
  ActionElementItem,
  elementItemDataCategories,
  elementItemImages,
  ElementType,
  FormElementItem,
  ListElementItem,
  ListViewSettings,
  ModelElementItem,
  TableSettings,
  ViewContext,
  ViewSettingsAction,
  ViewSettingsType,
  WidgetElementItem
} from '@modules/customize';
import { DataSourceGeneratorService } from '@modules/customize-generators';
import { ChartType, ChartWidget, ChartWidgetDataset, ValueWidget, Widget, WidgetType } from '@modules/dashboard';
import {
  ChartWidgetDataSource,
  DataSourceType,
  ListModelDescriptionDataSource,
  ModelDescriptionDataSource,
  ValueWidgetDataSource
} from '@modules/data-sources';
import { Input } from '@modules/fields';
import { listLayouts, ListLayoutType } from '@modules/layouts';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription, ModelFieldType } from '@modules/models';
import {
  isResourceTypeItemCompatible,
  isResourceTypeItemCustom,
  Resource,
  ResourceName,
  ResourceType,
  ResourceTypeItem,
  resourceTypeItems
} from '@modules/projects';
import {
  ActionQuery,
  ChartWidgetQuery,
  ListModelDescriptionQuery,
  ModelDescriptionQuery,
  QueryType,
  ValueWidgetQuery
} from '@modules/queries';
import { resourceTemplates } from '@modules/resource-generators';
import { isResourceCustom, ResourceControllerService } from '@modules/resources';
import { Template, TemplateService, TemplateType } from '@modules/template';
import { objectsSortPredicate } from '@shared';

import { changeCustomizeBarComponentsCharts } from '../../components/change-customize-bar/change-customize-bar-components/change-customize-bar-components-charts';
import {
  changeCustomizeBarComponentsLists,
  listComponentPostCreate
} from '../../components/change-customize-bar/change-customize-bar-components/change-customize-bar-components-lists';
import { CustomizeBarItem } from '../../data/customize-bar-item';
import { TemplatesGroup } from '../../data/templates-group';
import { TemplatesSection } from '../../data/templates-section';
import { TemplatesSectionGroup, TemplatesSectionGroupDisplay } from '../../data/templates-section-group';

@Injectable()
export class TemplateProvider {
  constructor(
    private dataSourceGeneratorService: DataSourceGeneratorService,
    private actionStore: ActionStore,
    private modelDescriptionStore: ModelDescriptionStore,
    private resourceControllerService: ResourceControllerService,
    private templateService: TemplateService
  ) {}

  getAllTemplates(): Observable<Template[]> {
    return combineLatest(
      this.templateService.get({ type: TemplateType.Widget }),
      // of(this.getGenericResourceAutoTemplates(ResourceName.PostgreSQL, QueryType.SQL)),
      // of(this.getGenericResourceAutoTemplates(ResourceName.GoogleSheets, QueryType.Simple)),
      // of(this.getGenericResourceAutoTemplates(ResourceName.Firebase, QueryType.Simple)),
      // of(this.getGenericResourceAutoTemplates(ResourceName.RestApi, QueryType.Http)),
      this.get3rdPartyAutoTemplates()
    ).pipe(
      map(result => {
        return result.reduce((acc, item) => {
          acc.push(...item);
          return acc;
        }, []);
      })
    );
  }

  getInstalledGroups(resources: Resource[]): Observable<TemplatesGroup[]> {
    return this.getAllTemplates().pipe(
      map(templates => {
        return [
          // TemplateGroup[] for installed resources
          ...resources
            .filter(resource => resource.typeItem)
            .map(resource => {
              return {
                title: resource.name,
                subtitle: resource.typeItem.featuresDescription || resource.typeItem.shortDescription,
                icon: resource.typeItem.icon,
                resource: resource,
                templates: templates.filter(item =>
                  item.forResources.find(i => isResourceTypeItemCompatible(i.typeItem, resource.typeItem))
                ),
                resourceType: resource.type,
                resourceTypeItem: resource.typeItem
              };
            })
        ];
      })
    );
  }

  getOtherGroups(): Observable<TemplatesGroup[]> {
    return this.getAllTemplates().pipe(
      map(templates => {
        return [
          // TemplateGroup[] for other resources
          ...templates
            .reduce((prev, current) => {
              return [
                ...prev,
                ...current.forResources
                  .map(item => item.typeItem)
                  .filter(item => !prev.find(i => isResourceTypeItemCompatible(i, item)))
              ];
            }, [])
            // .filter(item => !project.resources.find(i => i.typeItem === item))
            .map((typeItem: ResourceTypeItem) => {
              let title: string;
              let subtitle: string;
              let icon: string;
              let requireResource: boolean;
              let resourceType: ResourceType;
              let resourceTypeItem: ResourceTypeItem;

              if (typeItem.resourceType == ResourceType.JetBridge) {
                title = 'Databases';
                subtitle = 'Any SQL database or Framework';
                icon = 'database';
                requireResource = true;
                resourceType = typeItem.resourceType;
              } else {
                title = typeItem.label;
                subtitle = typeItem.featuresDescription || typeItem.shortDescription;
                icon = typeItem.icon;
                requireResource = [ResourceName.Firebase, ResourceName.GoogleSheets].includes(typeItem.name);
                resourceTypeItem = typeItem;
              }

              return {
                title: title,
                subtitle: subtitle,
                icon: icon,
                templates: templates.filter(item =>
                  item.forResources.find(i => isResourceTypeItemCompatible(i.typeItem, typeItem))
                ),
                requireResource: requireResource,
                resourceType: resourceType,
                resourceTypeItem: resourceTypeItem
              };
            })
        ];
      })
    );
  }

  getGenericResourceAutoTemplates(name: string, queryType: QueryType): Template[] {
    const result: Template[] = [];
    const typeItem = resourceTypeItems.find(item => item.name == name);
    const listElements = [
      {
        layout: ListLayoutType.Table
      },
      {
        layout: ListLayoutType.Map
      },
      {
        layout: ListLayoutType.KanbanBoard
      },
      {
        layout: ListLayoutType.Calendar
      },
      {
        layout: ListLayoutType.Grid
      },
      {
        layout: ListLayoutType.Carousel
      },
      {
        layout: ListLayoutType.Timeline
      }
    ];

    result.push(
      ...listElements.map(listElement => {
        const template = new Template();
        const element = new ListElementItem();
        const layout = listLayouts.find(item => item.type == listElement.layout);
        const layoutSettings = element.getOrCreateLayout(listElement.layout);

        element.type = ElementType.List;
        // element.title = layout.label;
        element.params = element.serialize(['params'])['params'];

        layoutSettings.titleInput = new Input().deserializeFromStatic('value', layout.label);
        layoutSettings.dataSource = new ListModelDescriptionDataSource();
        layoutSettings.dataSource.type = DataSourceType.Query;
        layoutSettings.dataSource.queryResource = '{{TEMPLATE_RESOURCE_1}}';
        layoutSettings.dataSource.query = new ListModelDescriptionQuery();
        layoutSettings.dataSource.query.queryType = queryType;

        template.type = TemplateType.Widget;
        template.name = layout.label;
        template.usedResources = [
          {
            type: typeItem.resourceType,
            typeItem: typeItem,
            token: '{{TEMPLATE_RESOURCE_1}}'
          }
        ];
        template.forResources = template.usedResources.map(item => {
          return {
            type: item.type,
            typeItem: item.typeItem
          };
        });
        template.element = element;
        template.forEmptyResources = true;

        return template;
      })
    );

    result.push(
      (() => {
        const template = new Template();
        const element = new ModelElementItem();
        const label = 'Detail';

        element.type = ElementType.Model;
        element.dataSource = new ModelDescriptionDataSource();
        element.dataSource.type = DataSourceType.Query;
        element.dataSource.queryResource = '{{TEMPLATE_RESOURCE_1}}';
        element.params = element.serialize()['params'];

        template.type = TemplateType.Widget;
        template.name = label;
        template.usedResources = [
          {
            type: typeItem.resourceType,
            typeItem: typeItem,
            token: '{{TEMPLATE_RESOURCE_1}}'
          }
        ];
        template.forResources = template.usedResources.map(item => {
          return {
            type: item.type,
            typeItem: item.typeItem
          };
        });
        template.element = element;

        return template;
      })()
    );

    const widgetElements = [
      {
        label: 'Single Value',
        type: WidgetType.Value,
        widgetClass: ValueWidget,
        dataSourceClass: ValueWidgetDataSource,
        queryClass: ValueWidgetQuery
      }
    ];

    result.push(
      ...widgetElements.map(widgetElement => {
        const template = new Template();
        const element = new WidgetElementItem();
        const widget = new widgetElement.widgetClass();

        widget.nameInput = new Input().deserializeFromStatic('value', widgetElement.label);
        widget.type = widgetElement.type;

        widget.dataSource = new widgetElement.dataSourceClass();
        widget.dataSource.type = DataSourceType.Query;
        widget.dataSource.queryResource = '{{TEMPLATE_RESOURCE_1}}';
        widget.dataSource.query = new widgetElement.queryClass();
        widget.dataSource.query.queryType = queryType;

        element.type = ElementType.Widget;
        element.widget = widget;
        element.params = element.serialize()['params'];

        template.type = TemplateType.Widget;
        template.name = widgetElement.label;
        template.usedResources = [
          {
            type: typeItem.resourceType,
            typeItem: typeItem,
            token: '{{TEMPLATE_RESOURCE_1}}'
          }
        ];
        template.forResources = template.usedResources.map(item => {
          return {
            type: item.type,
            typeItem: item.typeItem
          };
        });
        template.element = element;

        return template;
      })
    );

    result.push(
      (() => {
        const template = new Template();
        const element = new WidgetElementItem();
        const widget = new ChartWidget();
        const dataset = new ChartWidgetDataset();
        const label = 'Chart';

        dataset.dataSource = new ChartWidgetDataSource();
        dataset.dataSource.type = DataSourceType.Query;
        dataset.dataSource.queryResource = '{{TEMPLATE_RESOURCE_1}}';
        dataset.dataSource.query = new ChartWidgetQuery();
        dataset.dataSource.query.queryType = queryType;

        widget.nameInput = new Input().deserializeFromStatic('value', label);
        widget.type = WidgetType.Chart;
        widget.chartType = ChartType.Bar;
        widget.datasets = [dataset];

        element.type = ElementType.Widget;
        element.widget = widget;
        element.params = element.serialize()['params'];

        template.type = TemplateType.Widget;
        template.name = label;
        template.usedResources = [
          {
            type: typeItem.resourceType,
            typeItem: typeItem,
            token: '{{TEMPLATE_RESOURCE_1}}'
          }
        ];
        template.forResources = template.usedResources.map(item => {
          return {
            type: item.type,
            typeItem: item.typeItem
          };
        });
        template.element = element;

        return template;
      })()
    );

    result.push(
      (() => {
        const template = new Template();
        const element = new ActionElementItem();
        const actionItem = new ViewSettingsAction();
        const actionDescription = new ActionDescription();

        actionDescription.resource = '{{TEMPLATE_RESOURCE_1}}';
        actionDescription.type = ActionType.Query;
        actionDescription.queryAction = new QueryAction();
        actionDescription.queryAction.query = new ActionQuery();
        actionDescription.queryAction.query.queryType = queryType;

        actionItem.verboseNameInput = new Input().deserializeFromStatic('value', 'Button');
        actionItem.actionDescription = actionDescription;

        element.type = ElementType.Action;
        element.actionItem = actionItem;
        element.params = element.serialize(['params'])['params'];

        template.type = TemplateType.Widget;
        template.name = actionItem.verboseNameInput.staticValue;
        template.usedResources = [
          {
            type: typeItem.resourceType,
            typeItem: typeItem,
            token: '{{TEMPLATE_RESOURCE_1}}'
          }
        ];
        template.forResources = template.usedResources.map(item => {
          return {
            type: item.type,
            typeItem: item.typeItem
          };
        });
        template.element = element;

        return template;
      })()
    );

    return result;
  }

  getGenericComponentTemplates(group: TemplatesGroup, queryType?: QueryType): TemplatesSectionGroup[] {
    const result: TemplatesSectionGroup[] = [];
    const typeItem = group.resourceTypeItem;

    if (typeItem.modelsEnabled) {
      result.push({
        title: 'Lists',
        showAll: true,
        display: TemplatesSectionGroupDisplay.Tiles,
        items: changeCustomizeBarComponentsLists
          .filter(item => item.type == ElementType.List)
          .map(listElement => {
            const template = new Template();
            const element = new ListElementItem();
            const layoutType = listElement.defaultParams.layouts[0].type;
            const layout = listLayouts.find(item => item.type == layoutType);
            const layoutSettings = element.getOrCreateLayout(layoutType);

            element.type = ElementType.List;
            // element.title = layout.label;
            element.params = element.serialize(['params'])['params'];

            layoutSettings.titleInput = new Input().deserializeFromStatic('value', layout.label);

            layoutSettings.dataSource = new ListModelDescriptionDataSource();
            layoutSettings.dataSource.type = DataSourceType.Query;
            layoutSettings.dataSource.queryResource = '{{TEMPLATE_RESOURCE_1}}';
            layoutSettings.dataSource.query = new ListModelDescriptionQuery();
            layoutSettings.dataSource.query.frontendFiltering = true;

            if (queryType) {
              layoutSettings.dataSource.query.queryType = queryType;
            }

            template.type = TemplateType.Widget;
            template.name = listElement.title;
            template.usedResources = [
              {
                type: typeItem.resourceType,
                typeItem: typeItem,
                token: '{{TEMPLATE_RESOURCE_1}}'
              }
            ];
            template.forResources = template.usedResources.map(item => {
              return {
                type: item.type,
                typeItem: item.typeItem
              };
            });
            template.element = element;
            template.forEmptyResources = true;

            return {
              type: template.element.type,
              title: template.name,
              subtitle: template.description || template.element.typeStr,
              image: listElement.image,
              defaultParams: template.element.params,
              template: template,
              resource: group.resource,
              postCreate: listComponentPostCreate,
              setupOnCreate: !!queryType
            };
          })
      });
    }

    if (typeItem.actionsEnabled) {
      result.push({
        title: 'Actions',
        showAll: true,
        display: TemplatesSectionGroupDisplay.Tiles,
        items: [
          (() => {
            const template = new Template();
            const element = new FormElementItem();
            const actionItem = new ViewSettingsAction();
            const actionDescription = new ActionDescription();

            actionDescription.resource = '{{TEMPLATE_RESOURCE_1}}';
            actionDescription.type = ActionType.Query;
            actionDescription.queryAction = new QueryAction();
            actionDescription.queryAction.query = new ActionQuery();

            if (queryType) {
              actionDescription.queryAction.query.queryType = queryType;
            }

            actionItem.verboseNameInput = new Input().deserializeFromStatic('value', 'Submit');
            actionItem.actionDescription = actionDescription;

            element.type = ElementType.Form;
            element.submitAction = actionItem;
            element.params = element.serialize(['params'])['params'];

            template.type = TemplateType.Widget;
            template.name = actionItem.verboseNameInput.staticValue;
            template.usedResources = [
              {
                type: typeItem.resourceType,
                typeItem: typeItem,
                token: '{{TEMPLATE_RESOURCE_1}}'
              }
            ];
            template.forResources = template.usedResources.map(item => {
              return {
                type: item.type,
                typeItem: item.typeItem
              };
            });
            template.element = element;

            return {
              type: template.element.type,
              title: template.name,
              subtitle: template.description || template.element.typeStr,
              image: 'form',
              defaultParams: template.element.params,
              template: template,
              resource: group.resource,
              setupOnCreate: !!queryType
            };
          })(),
          (() => {
            const template = new Template();
            const element = new ActionElementItem();
            const actionItem = new ViewSettingsAction();
            const actionDescription = new ActionDescription();

            actionDescription.resource = '{{TEMPLATE_RESOURCE_1}}';
            actionDescription.type = ActionType.Query;
            actionDescription.queryAction = new QueryAction();
            actionDescription.queryAction.query = new ActionQuery();

            if (queryType) {
              actionDescription.queryAction.query.queryType = queryType;
            }

            actionItem.verboseNameInput = new Input().deserializeFromStatic('value', 'Button');
            actionItem.actionDescription = actionDescription;

            element.type = ElementType.Action;
            element.actionItem = actionItem;
            element.params = element.serialize(['params'])['params'];

            template.type = TemplateType.Widget;
            template.name = actionItem.verboseNameInput.staticValue;
            template.usedResources = [
              {
                type: typeItem.resourceType,
                typeItem: typeItem,
                token: '{{TEMPLATE_RESOURCE_1}}'
              }
            ];
            template.forResources = template.usedResources.map(item => {
              return {
                type: item.type,
                typeItem: item.typeItem
              };
            });
            template.element = element;

            return {
              type: template.element.type,
              title: template.name,
              subtitle: template.description || template.element.typeStr,
              image: 'button',
              defaultParams: template.element.params,
              template: template,
              resource: group.resource,
              setupOnCreate: !!queryType
            };
          })()
        ]
      });
    }

    if (typeItem.modelsEnabled) {
      result.push({
        title: 'Charts',
        showAll: true,
        display: TemplatesSectionGroupDisplay.Tiles,
        items: changeCustomizeBarComponentsCharts.map(chartElement => {
          const template = new Template();
          const element = new WidgetElementItem();
          let widget: Widget;

          if (chartElement.defaultParams.widget.widget_type == WidgetType.Chart) {
            const dataset = new ChartWidgetDataset();
            const chartWidget = new ChartWidget();

            dataset.dataSource = new ChartWidgetDataSource();
            dataset.dataSource.type = DataSourceType.Query;
            dataset.dataSource.queryResource = '{{TEMPLATE_RESOURCE_1}}';
            dataset.dataSource.query = new ChartWidgetQuery();

            if (queryType) {
              dataset.dataSource.query.queryType = queryType;
            }

            chartWidget.nameInput = new Input().deserializeFromStatic('value', chartElement.title);
            chartWidget.type = chartElement.defaultParams.widget.widget_type;
            chartWidget.chartType = JSON.parse(chartElement.defaultParams.widget.params)['chart_type'];
            chartWidget.datasets = [dataset];

            widget = chartWidget;
          } else if (chartElement.defaultParams.widget.widget_type == WidgetType.Value) {
            const valueWidget = new ValueWidget();

            valueWidget.nameInput = new Input().deserializeFromStatic('value', chartElement.title);
            valueWidget.type = chartElement.defaultParams.widget.widget_type;

            valueWidget.dataSource = new ValueWidgetDataSource();
            valueWidget.dataSource.type = DataSourceType.Query;
            valueWidget.dataSource.queryResource = '{{TEMPLATE_RESOURCE_1}}';
            valueWidget.dataSource.query = new ValueWidgetQuery();

            if (queryType) {
              valueWidget.dataSource.query.queryType = queryType;
            }

            widget = valueWidget;
          }

          element.type = ElementType.Widget;
          element.widget = widget;
          element.params = element.serialize()['params'];

          template.type = TemplateType.Widget;
          template.name = chartElement.title;
          template.usedResources = [
            {
              type: typeItem.resourceType,
              typeItem: typeItem,
              token: '{{TEMPLATE_RESOURCE_1}}'
            }
          ];
          template.forResources = template.usedResources.map(item => {
            return {
              type: item.type,
              typeItem: item.typeItem
            };
          });
          template.element = element;

          return {
            type: template.element.type,
            title: template.name,
            subtitle: template.description || template.element.typeStr,
            image: chartElement.image,
            defaultParams: template.element.params,
            template: template,
            resource: group.resource,
            setupOnCreate: !!queryType
          };
        })
      });
    }

    return result;
  }

  get3rdPartyAutoTemplates(): Observable<Template[]> {
    return this.modelDescriptionStore.getFirst().pipe(
      map(() => {
        return resourceTemplates.reduce((prev, autoTemplate) => {
          const typeItem = resourceTypeItems.find(i => i.name == autoTemplate.name);
          const allViewSettings = autoTemplate.viewSettings
            ? autoTemplate.viewSettings
                .filter(item => item['view'] == ViewSettingsType.List)
                .map(item => new ListViewSettings().deserialize(item))
            : [];

          if (autoTemplate.modelDescriptions) {
            prev.push(
              ...autoTemplate.modelDescriptions.map(data => {
                const modelDescription = new ModelDescription().deserialize(data);
                const viewSettings = allViewSettings.find(item => item.model == modelDescription.model);
                const template = new Template();
                const element = new ListElementItem();
                const tableSettings = element.getOrCreateLayout<TableSettings>(ListLayoutType.Table);
                const viewSettingsTableSettings = viewSettings
                  ? viewSettings.layouts.find(item => item.type == ListLayoutType.Table)
                  : undefined;

                element.type = ElementType.List;
                // element.title = modelDescription.verboseNamePlural;

                tableSettings.titleInput = new Input().deserializeFromStatic(
                  'value',
                  modelDescription.verboseNamePlural
                );
                tableSettings.dataSource = new ListModelDescriptionDataSource();
                tableSettings.dataSource.type = DataSourceType.Query;
                tableSettings.dataSource.queryResource = '{{TEMPLATE_RESOURCE_1}}';
                tableSettings.dataSource.query = new ListModelDescriptionQuery();
                tableSettings.dataSource.query.queryType = QueryType.Simple;
                tableSettings.dataSource.query.simpleQuery = new tableSettings.dataSource.query.simpleQueryClass();
                tableSettings.dataSource.query.simpleQuery.model = modelDescription.model;

                if (viewSettingsTableSettings && viewSettingsTableSettings.dataSource) {
                  tableSettings.dataSource.columns = viewSettingsTableSettings.dataSource.columns;
                  tableSettings.dataSource.columns = this.dataSourceGeneratorService.resetModelColumns(
                    modelDescription,
                    tableSettings.dataSource.columns
                  );
                } else {
                  tableSettings.dataSource.columns = this.dataSourceGeneratorService.getModelColumns(
                    modelDescription,
                    8
                  );
                  tableSettings.dataSource.columns = this.dataSourceGeneratorService.resetModelColumns(
                    modelDescription,
                    tableSettings.dataSource.columns
                  );
                }

                tableSettings.columnActions = this.dataSourceGeneratorService.getModelColumnActions(
                  modelDescription,
                  tableSettings.dataSource.columns
                );

                element.params = element.serialize(['params'])['params'];

                template.type = TemplateType.Widget;
                template.name = modelDescription.verboseNamePlural;
                template.description = modelDescription.description;
                template.usedResources = [
                  {
                    type: typeItem.resourceType,
                    typeItem: typeItem,
                    token: '{{TEMPLATE_RESOURCE_1}}'
                  }
                ];
                template.forResources = template.usedResources.map(item => {
                  return {
                    type: item.type,
                    typeItem: item.typeItem
                  };
                });
                template.element = element;

                return template;
              })
            );
          }

          if (autoTemplate.actionDescriptions) {
            prev.push(
              ...autoTemplate.actionDescriptions.map(data => {
                const actionDescription = new ActionDescription().deserialize(data);
                const template = new Template();
                const element = new ActionElementItem();
                const actionItem = new ViewSettingsAction();

                actionItem.verboseNameInput = new Input().deserializeFromStatic('value', actionDescription.verboseName);
                actionItem.sharedActionDescription = ['{{TEMPLATE_RESOURCE_1}}', actionDescription.name].join('.');

                element.type = ElementType.Action;
                element.actionItem = actionItem;
                element.params = element.serialize(['params'])['params'];

                template.type = TemplateType.Widget;
                template.name = actionItem.verboseNameInput.staticValue;
                template.description = actionDescription.description;
                template.usedResources = [
                  {
                    type: typeItem.resourceType,
                    typeItem: typeItem,
                    token: '{{TEMPLATE_RESOURCE_1}}'
                  }
                ];
                template.forResources = template.usedResources.map(item => {
                  return {
                    type: item.type,
                    typeItem: item.typeItem
                  };
                });
                template.element = element;

                return template;
              })
            );
          }

          return prev;
        }, []);
      })
    );
  }

  getResourceItems(resource: Resource, context?: ViewContext): Observable<CustomizeBarItem[]> {
    const modelDescription = context ? context.modelDescription : undefined;
    const obs: Observable<CustomizeBarItem[]>[] = [];

    if (resource) {
      if (modelDescription && modelDescription.resource == resource.uniqueName) {
        obs.push(of(this.getModelDescriptionFieldsItems(resource, modelDescription)));
        obs.push(this.getModelDescriptionRelationsItems(resource, modelDescription));
      }

      obs.push(this.getResourceModelDescriptionListItems(resource));
      // obs.push(this.getResourceModelDescriptionDetailItems(resource));
      obs.push(this.getResourceActionDescriptionsItems(resource));
    }

    if (!obs.length) {
      return of([]);
    }

    return combineLatest(...obs).pipe(
      map(result => {
        return result.reduce((acc, item) => {
          acc.push(...item);
          return acc;
        }, []);
      })
    );
  }

  getGroupSections(group: TemplatesGroup, context?: ViewContext): Observable<TemplatesSection[]> {
    return this.getResourceItems(group.resource, context).pipe(
      map(resourceItems => {
        const items = [...this.getTemplatesItems(group), ...resourceItems];
        const resourceCustom = group.resource
          ? isResourceCustom(group.resource)
          : isResourceTypeItemCustom(group.resourceTypeItem);
        let queryType: QueryType;

        if (resourceCustom) {
          const resourceType = group.resource ? group.resource.type : group.resourceType;
          const controller = this.resourceControllerService.get(resourceType);

          if (controller) {
            queryType = controller
              .supportedQueryTypes(ModelDescriptionQuery)
              .filter(item => item != QueryType.Simple)[0];
          }
        }

        const sections: TemplatesSection[] = [
          {
            title: 'Components',
            description: 'Use built-in templates',
            openedInitial: true,
            groups: [...this.groupItemsBySectionGroups(items), ...this.getGenericComponentTemplates(group, queryType)]
          }
        ];

        // Make openedInitial only the last one that has openedInitial == true
        const openedInitial = sections
          .slice()
          .reverse()
          .find(item => item.openedInitial);
        sections.forEach(item => (item.openedInitial = openedInitial === item));

        return sections;
      })
    );
  }

  groupItemsBySectionGroups(items: CustomizeBarItem[]): TemplatesSectionGroup[] {
    const keysOrder: { [key: string]: number } = fromPairs(values(elementItemDataCategories).map((key, i) => [key, i]));
    const groups: TemplatesSectionGroup[] = values(
      items.reduce<{ [k: string]: TemplatesSectionGroup }>((prev, item) => {
        const key = item.type;

        if (!prev[key]) {
          prev[key] = {
            title: elementItemDataCategories[key],
            items: [],
            display: TemplatesSectionGroupDisplay.List
          };
        }

        prev[key].items.push(item);

        return prev;
      }, {})
    );

    if (!groups.length) {
      return [];
    }

    return groups
      .map(group => {
        group.items = group.items.sort(objectsSortPredicate('title'));
        return group;
      })
      .sort((lhs, rhs) => {
        return (keysOrder[lhs.title] || 0) - (keysOrder[rhs.title] || 0);
      });
  }

  getTemplatesItems(group: TemplatesGroup): CustomizeBarItem[] {
    return (
      group.templates
        .filter(item => item.element)
        // .filter(item => !item.forEmptyResources || (item.forEmptyResources && !group.resource))
        .map(item => {
          return {
            type: item.element.type,
            title: item.name,
            subtitle: item.description || item.element.typeStr,
            image: elementItemImages[item.element.type],
            defaultParams: item.element.params,
            template: item,
            resource: group.resource
          };
        })
    );
  }

  getModelDescriptionFieldsItems(resource: Resource, modelDescription: ModelDescription): CustomizeBarItem[] {
    return modelDescription.fields.map(field => {
      const title = field.verboseName;
      const subtitle = `${field.type == ModelFieldType.Flex ? '(Flex) ' : ''} ${field.item.fieldDescription.label}`;
      const icon = field.item.fieldDescription.icon;

      // let children: Observable<ChangeCustomizeBarSectionItem[]>;
      //
      // if (field.item.field == 'ForeignKey' && field.item.params['related_model']) {
      //   const model = fromLegacyModel(field.item.params['related_model']['model']);
      //
      //   children = this.modelDescriptionStore.getDetailFirst(model).pipe(map(relatedModel => {
      //     const context = this.context.relatedModels[field.name].context;
      //
      //     return relatedModel.fields.map(relatedField => {
      //       const relatedTitle = relatedField.verboseName;
      //       const relatedSubtitle = `${relatedField.type == ModelFieldType.Flex ? '(Flex) ' : ''} ${relatedField.item.fieldDescription.label}`;
      //       const relatedIcon = relatedField.item.fieldDescription.icon;
      //
      //       return {
      //         item: {
      //           itemType: ChangeCustomizeBarItemType.Line,
      //           context: {
      //             title: relatedTitle,
      //             subtitle: relatedSubtitle,
      //             icon: relatedIcon,
      //           }
      //         },
      //         type: ElementType.Field,
      //         context: context,
      //         params: { field: relatedField.serialize(), related_model_from_field: field.name }
      //       };
      //     });
      //   }));
      // }

      return {
        type: ElementType.Field,
        title: title,
        subtitle: subtitle,
        icon: icon,
        defaultParams: { field: field.serialize() },
        resource: resource
      };
    });
  }

  getModelDescriptionRelationsItems(
    resource: Resource,
    modelDescription: ModelDescription
  ): Observable<CustomizeBarItem[]> {
    return this.modelDescriptionStore.getFirst().pipe(
      map(modelDescriptions => {
        return modelDescription.relations
          .map(relation => {
            const relatedModelDescription = modelDescriptions.find(item => item.isSame(relation.relatedModel));

            if (!relatedModelDescription) {
              return;
            }

            const relatedResource = relatedModelDescription.resource;
            const columns = this.dataSourceGeneratorService.getModelColumns(relatedModelDescription, 6);
            const getQuery = this.dataSourceGeneratorService.applyModelQueryDefaults(
              relatedModelDescription,
              ListModelDescriptionQuery
            );
            const updateQuery = this.dataSourceGeneratorService.applyModelQueryDefaults(
              relatedModelDescription,
              ModelDescriptionQuery
            );

            return {
              type: ElementType.List,
              title: relatedModelDescription.verboseNamePlural,
              // subtitle: relation.verboseName,
              subtitle: relation.name,
              defaultParams: {
                title: relatedModelDescription.verboseNamePlural,
                layouts: [
                  {
                    type: ListLayoutType.Table,
                    title: relatedModelDescription.verboseNamePlural,
                    columns: columns,
                    resource: relatedResource,
                    get_query: getQuery.serialize(),
                    update_resource: relatedResource,
                    update_query: updateQuery.serialize()
                  }
                ]
              },
              resource: resource
            };
          })
          .filter(item => item != undefined);
      })
    );
  }

  getResourceModelDescriptionListItems(resource: Resource): Observable<CustomizeBarItem[]> {
    const typeItem = resource.typeItem;

    if (resourceTemplates.find(item => item.name == typeItem.name && item.modelDescriptions != undefined)) {
      return of([]);
    }

    return this.modelDescriptionStore.getFirst().pipe(
      map(modelDescriptions => {
        return modelDescriptions

          .filter(item => item.resource == resource.uniqueName)
          .map(modelDescription => {
            const columns = this.dataSourceGeneratorService.getModelColumns(modelDescription, 6);
            const getQuery = this.dataSourceGeneratorService.applyModelQueryDefaults(
              modelDescription,
              ListModelDescriptionQuery
            );
            const updateQuery = this.dataSourceGeneratorService.applyModelQueryDefaults(
              modelDescription,
              ModelDescriptionQuery
            );
            const layout = listLayouts.find(item => item.type == ListLayoutType.Table);

            return {
              type: ElementType.List,
              title: modelDescription.verboseNamePlural,
              subtitle: modelDescription.description || layout.label || 'List',
              image: elementItemImages[ElementType.List],
              defaultParams: {
                title: modelDescription.verboseNamePlural,
                layouts: [
                  {
                    type: ListLayoutType.Table,
                    title: modelDescription.verboseNamePlural,
                    columns: columns,
                    resource: resource.uniqueName,
                    get_query: getQuery.serialize(),
                    update_resource: resource.uniqueName,
                    update_query: updateQuery.serialize()
                  }
                ]
              },
              resource: resource
            };
          });
      })
    );
  }

  getResourceModelDescriptionDetailItems(resource: Resource): Observable<CustomizeBarItem[]> {
    return this.modelDescriptionStore.getFirst().pipe(
      map(modelDescriptions => {
        return modelDescriptions
          .filter(item => item.resource == resource.uniqueName)
          .map(modelDescription => {
            const columns = this.dataSourceGeneratorService.getModelColumns(modelDescription, 6);
            const getQuery = this.dataSourceGeneratorService.applyModelQueryDefaults(
              modelDescription,
              ModelDescriptionQuery
            );

            return {
              type: ElementType.Model,
              title: modelDescription.verboseName,
              subtitle: modelDescription.description,
              image: elementItemImages[ElementType.Model],
              defaultParams: {
                resource: resource.uniqueName,
                get_query: getQuery.serialize(),
                columns: columns
              },
              resource: resource
            };
          });
      })
    );
  }

  getResourceActionDescriptionsItems(resource: Resource): Observable<CustomizeBarItem[]> {
    const typeItem = resource.typeItem;

    if (resourceTemplates.find(item => item.name == typeItem.name && item.actionDescriptions != undefined)) {
      return of([]);
    }

    return this.actionStore.getFirst().pipe(
      map(actionDescriptions => {
        return actionDescriptions
          .filter(item => item.resource == resource.uniqueName)
          .map(actionDescription => {
            return {
              type: ElementType.Action,
              title: actionDescription.verboseName,
              subtitle: actionDescription.description,
              image: elementItemImages[ElementType.Action],
              defaultParams: {
                action_item: {
                  verbose_name: actionDescription.verboseName,
                  shared_action_description: actionDescription.id
                }
              },
              resource: resource
            };
          });
      })
    );
  }
}
