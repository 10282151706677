var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ActionDescriptionService } from '@modules/action-queries';
import { SegueType } from '@modules/actions';
import { ViewSettingsStore } from '@modules/customize';
import { isRequiredInputsSet } from '@modules/fields';
import { MenuItemAction, MenuItemActionType } from '@modules/menu';
import { InputFieldProvider } from '@modules/parameters';
import { controlValue, isSet } from '@shared';
export var validateUrl = function (control) {
    if (!isSet(control.value)) {
        return;
    }
    if (['http://', 'https://', 'ftp://', '//', '/'].every(function (item) { return !String(control.value).toLowerCase().startsWith(item); })) {
        return { local: ['Should start with https://, http:// or /'] };
    }
};
export var validateInputs = function (control) {
    var parent = control.parent;
    if (!parent) {
        return;
    }
    var fields = parent.inputFieldProvider.fields;
    var inputs = control.value;
    if (!isRequiredInputsSet(fields, inputs)) {
        return { required: true };
    }
};
var MenuItemActionControl = /** @class */ (function (_super) {
    __extends(MenuItemActionControl, _super);
    function MenuItemActionControl(injector, actionDescriptionService, viewSettingsStore) {
        var _this = _super.call(this, {
            type: new FormControl(''),
            page_uid: new FormControl(''),
            page_unique_name: new FormControl(''),
            url: new FormControl('', validateUrl),
            system_type: new FormControl(''),
            inputs: new FormControl([], validateInputs)
        }) || this;
        _this.injector = injector;
        _this.actionDescriptionService = actionDescriptionService;
        _this.viewSettingsStore = viewSettingsStore;
        _this.inputFieldProvider = new InputFieldProvider();
        _this.updateInputFieldProvider().pipe(untilDestroyed(_this)).subscribe();
        _this.inputFieldProvider.fields$.subscribe(function () {
            _this.controls.inputs.updateValueAndValidity();
        });
        return _this;
    }
    MenuItemActionControl.inject = function (injector) {
        return Injector.create({
            providers: [
                {
                    provide: MenuItemActionControl,
                    useFactory: function (actionDescriptionService, viewSettingsStore) {
                        return new MenuItemActionControl(injector, actionDescriptionService, viewSettingsStore);
                    },
                    deps: [ActionDescriptionService, ViewSettingsStore]
                }
            ],
            parent: injector
        }).get(MenuItemActionControl);
    };
    MenuItemActionControl.prototype.ngOnDestroy = function () {
        this.inputFieldProvider.clearProvider();
    };
    MenuItemActionControl.prototype.deserialize = function (item) {
        this.instance = item;
        if (item) {
            this.controls.type.patchValue(item.type);
            this.controls.page_uid.patchValue(item.pageUid);
            this.controls.page_unique_name.patchValue(item.pageUniqueName);
            this.controls.url.patchValue(item.url);
            this.controls.system_type.patchValue(item.systemType);
            this.controls.inputs.patchValue(item.inputs);
        }
        this.markAsPristine();
    };
    MenuItemActionControl.prototype.updateInputFieldProvider = function () {
        var _this = this;
        return combineLatest(controlValue(this.controls.type), controlValue(this.controls.page_uid).pipe(switchMap(function (pageUid) {
            if (isSet(pageUid)) {
                return _this.viewSettingsStore.getDetailByUid(pageUid);
            }
            else {
                return of(undefined);
            }
        }))).pipe(map(function (_a) {
            var type = _a[0], page = _a[1];
            if (type == MenuItemActionType.Page) {
                if (!page) {
                    return of([]);
                }
                return _this.actionDescriptionService.getLinkActionParameters({ type: SegueType.Page }, page.parameters);
            }
            else {
                return of([]);
            }
        }), tap(function (items) {
            _this.inputFieldProvider.setProvider(items);
        }));
    };
    MenuItemActionControl.prototype.serialize = function () {
        var instance = new MenuItemAction();
        if (this.instance) {
            instance.deserialize(this.instance.serialize());
        }
        instance.type = this.controls.type.value;
        instance.pageUid = this.controls.page_uid.value;
        instance.pageUniqueName = this.controls.page_unique_name.value;
        instance.url = this.controls.url.value;
        instance.systemType = this.controls.system_type.value;
        instance.inputs = this.controls.inputs.value;
        return instance;
    };
    MenuItemActionControl.prototype.getPageUid$ = function () {
        return combineLatest(controlValue(this.controls.type), controlValue(this.controls.page_uid)).pipe(map(function (_a) {
            var type = _a[0], pageUid = _a[1];
            if (type == MenuItemActionType.Page) {
                return pageUid;
            }
        }));
    };
    MenuItemActionControl.prototype.setPageValue = function (page) {
        this.controls.type.patchValue(MenuItemActionType.Page);
        if (page) {
            this.controls.page_uid.patchValue(page.uid);
            this.controls.page_unique_name.patchValue(page.uniqueName);
        }
        else {
            this.controls.page_unique_name.patchValue(page.uniqueName);
        }
    };
    MenuItemActionControl.prototype.setUrlValue = function (url) {
        this.controls.type.patchValue(MenuItemActionType.URL);
        this.controls.url.patchValue(url);
    };
    MenuItemActionControl.prototype.setSystemValue = function (type) {
        this.controls.type.patchValue(MenuItemActionType.System);
        this.controls.system_type.patchValue(type);
    };
    return MenuItemActionControl;
}(FormGroup));
export { MenuItemActionControl };
