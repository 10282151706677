<div class="projects theme_dark">
  <div class="projects__background"></div>
  <div class="projects__inner">
    <div class="projects__header">
      <app-header [isWhiteLabel]="isWhiteLabel" [analyticsSource]="'user_edit_header'"></app-header>
    </div>

    <div class="projects__content">
      <app-loader *ngIf="loading" [title]="'Loading' | localize">
        {{ 'We are fetching user data, please, wait...' | localize }}
      </app-loader>

      <div class="settings__body">
        <div class="settings__aside">
          <a href="javascript:void(0)" class="button-back" (click)="back()">
            {{ 'Back' | localize }}
          </a>
        </div>
        <div
          *ngIf="!loading && user"
          class="workspace workspace_size_md settings__workspace settings__workspace_button"
        >
          <div class="workspace__inner">
            <div class="workspace__header">
              <h2 class="workspace__title">{{ 'Profile Settings' | localize }}</h2>
              <div class="workspace__sub-title">{{ 'Basic information about your account.' | localize }}</div>
            </div>
            <form [formGroup]="userEditForm" class="workspace__body" (ngSubmit)="submit()">
              <div class="workspace__section">
                <div class="photo-selector">
                  <div class="photo-selector__label">{{ 'Photo' | localize }}</div>
                  <div class="photo-selector__image">
                    <span
                      class="photo-selector__image-inner photo-selector__image-inner_contain"
                      [style.background-image]="'url(' + photo + ')'"
                    >
                      <label
                        class="photo-selector__image-overlay"
                        [class.photo-selector__image-overlay_visible]="!photo"
                        for="photo"
                      >
                        {{ 'your photo' | localize }}
                      </label>
                    </span>
                  </div>
                  <div class="photo-selector__controls">
                    <input
                      class="photo-selector__input"
                      type="file"
                      id="photo"
                      (change)="onFileChange($event.target)"
                    />
                    <label class="photo-selector__control button button_grey" for="photo">
                      <span class="icon button__icon_left icon-pen button__icon"></span>
                      <span class="button__label">
                        <ng-container *ngIf="photo">{{ 'Change' | localize }}</ng-container>
                        <ng-container *ngIf="!photo">{{ 'Upload' | localize }}</ng-container>
                      </span>
                    </label>
                    <a
                      *ngIf="photo"
                      href="javascript:void(0)"
                      class="photo-selector__control button button_grey"
                      (click)="deletePhoto()"
                    >
                      <span class="icon button__icon_left icon-bin button__icon"></span>
                      <span class="button__label">
                        {{ 'Delete' | localize }}
                      </span>
                    </a>
                  </div>
                </div>
                <div class="workspace__form-grid form-grid">
                  <div class="form-grid__row">
                    <div class="form-grid__item">
                      <app-char-field
                        [form]="userEditForm"
                        [field]="
                          createField({
                            name: 'first_name',
                            label: 'First Name' | localize,
                            required: true,
                            field: 'CharField'
                          })
                        "
                      >
                      </app-char-field>
                    </div>
                    <div class="form-grid__item">
                      <app-char-field
                        [form]="userEditForm"
                        [field]="createField({ name: 'last_name', label: 'Last Name' | localize, field: 'CharField' })"
                      >
                      </app-char-field>
                    </div>
                  </div>
                  <div class="form-grid__row">
                    <div class="form-grid__item">
                      <div class="field">
                        <label class="field__label">{{ 'Your Email' | localize }}</label>
                        <div class="field__content">
                          {{ user.email }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <form
                *ngIf="!ssoAuth"
                class="workspace__section part-workspace"
                [formGroup]="changeEmailForm"
                (ngSubmit)="changeEmail()"
              >
                <div class="part-workspace__header">
                  <div class="part-workspace__title">{{ 'Email Change' | localize }}</div>
                </div>
                <div class="part-workspace__body">
                  <div class="form-grid part-workspace__form-grid">
                    <div class="form-grid__row">
                      <div class="form-grid__item">
                        <app-char-field
                          [form]="changeEmailForm"
                          [field]="
                            createField({
                              name: 'email',
                              label: 'New Email' | localize,
                              required: true,
                              field: 'CharField'
                            })
                          "
                        >
                        </app-char-field>

                        <app-errors [errors]="changeEmailForm | appFormErrors"></app-errors>
                      </div>
                      <div *ngIf="user.hasPassword" class="form-grid__item">
                        <app-password-field
                          [form]="changeEmailForm"
                          [field]="
                            createField({
                              name: 'password',
                              label: 'Current Password' | localize,
                              required: true,
                              field: 'PasswordField'
                            })
                          "
                        >
                        </app-password-field>
                      </div>
                      <button
                        type="button"
                        class="button button_primary button_bigger form-grid__button form-grid__button_margin-top"
                        [disabled]="changeEmailForm.invalid || changeEmailForm.pristine || changingEmail"
                        (click)="changeEmail()"
                      >
                        <app-loader-small *ngIf="changingEmail" class="button__icon button__icon_left">
                        </app-loader-small>
                        <span class="button__label">{{ 'Change Email' | localize }}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>

              <form
                *ngIf="!ssoAuth"
                class="workspace__section part-workspace"
                [formGroup]="changePasswordForm"
                (ngSubmit)="changePassword()"
              >
                <div class="part-workspace__header">
                  <div class="part-workspace__title">{{ 'Password Change' | localize }}</div>
                  <div class="part-workspace__sub-title">
                    {{
                      'A password should be strong enough to protect your account, so make it at least 12 characters long.'
                        | localize
                    }}
                  </div>
                </div>
                <div class="part-workspace__body">
                  <div class="form-grid part-workspace__form-grid">
                    <div *ngIf="user.hasPassword" class="form-grid__row">
                      <div class="form-grid__item" [style.max-width.px]="264">
                        <app-password-field
                          [form]="changePasswordForm"
                          [field]="
                            createField({
                              name: 'current_password',
                              label: 'Current Password' | localize,
                              required: true,
                              field: 'PasswordField'
                            })
                          "
                        >
                        </app-password-field>
                      </div>
                      <div class="form-grid__item"></div>
                    </div>

                    <div class="form-grid__row">
                      <div class="form-grid__item">
                        <app-password-field
                          [form]="changePasswordForm"
                          [field]="
                            createField({
                              name: 'new_password',
                              label: 'New Password' | localize,
                              required: true,
                              field: 'PasswordField'
                            })
                          "
                        >
                        </app-password-field>
                        <app-errors [errors]="changePasswordForm | appFormErrors"></app-errors>
                      </div>
                      <div class="form-grid__item">
                        <app-password-field
                          [form]="changePasswordForm"
                          [field]="
                            createField({
                              name: 'repeat_password',
                              label: 'Confirm New Password' | localize,
                              required: true,
                              field: 'PasswordField'
                            })
                          "
                        >
                        </app-password-field>
                      </div>
                      <button
                        type="button"
                        class="button button_primary button_bigger form-grid__button form-grid__button_margin-top"
                        [disabled]="
                          changePasswordForm.invalid ||
                          changePasswordForm.pristine ||
                          !validPassword ||
                          changingPassword
                        "
                        (click)="changePassword()"
                      >
                        <app-loader-small *ngIf="changingPassword" class="button__icon button__icon_left">
                        </app-loader-small>
                        <span class="button__label">{{ 'Change Password' | localize }}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>

              <div class="workspace__section part-workspace">
                <div class="part-workspace__header">
                  <div class="part-workspace__title">{{ 'Personal Preferences' | localize }}</div>
                  <div class="part-workspace__sub-title">
                    {{ 'Customize additional preferences for your account.' | localize }}
                  </div>
                </div>
                <div class="part-workspace__body">
                  <div class="form-grid part-workspace__form-grid">
                    <div class="form-grid__row">
                      <div class="form-grid__item">
                        <div class="field">
                          <div class="field__label">
                            {{ 'Language' | localize }}
                          </div>
                          <div class="field__value">
                            <app-select
                              [control]="userEditForm.controls.language"
                              [placeholder]="userEditForm.languageDefaultOption?.name"
                              [options]="userEditForm.languageOptions"
                              [fill]="true"
                            ></app-select>
                          </div>
                          <div *ngIf="!isWhiteLabel" class="field__description">
                            You can submit new localizations or edits to the existing ones on our
                            <a href="https://github.com/jet-admin/jet-localization" target="_blank">GitHub</a>.
                          </div>
                        </div>
                      </div>

                      <div class="form-grid__item">
                        <div class="field">
                          <div class="field__label">
                            {{ 'Timezone' | localize }}
                          </div>
                          <div class="field__value">
                            <app-select
                              [control]="userEditForm.controls.timezone"
                              [placeholder]="userEditForm.timezoneDefaultOption?.name"
                              [options]="userEditForm.timezoneOptions"
                              [fill]="true"
                            ></app-select>
                          </div>
                          <div
                            *ngIf="currentTimezoneOption && currentTimezoneTime$ | async as now"
                            class="field__description"
                          >
                            Current time in {{ currentTimezoneOption.data['name'] }} is <br />
                            <strong>{{ now.format('ll hh:mm A') }}</strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="part-workspace__body">
                  <div class="feature-line">
                    <div class="feature-line__left">
                      <div class="feature-line__icon feature-line__icon_green">
                        <span class="icon icon-news"></span>
                      </div>
                    </div>
                    <div class="feature-line__main">
                      <div class="feature-line__title">{{ 'News & Proposals' | localize }}</div>
                      <div class="feature-line__description">
                        {{ 'Subscribe to our updates and proposals.' | localize }}
                      </div>
                    </div>
                    <app-toggle
                      class="feature-line__right"
                      [selected]="userEditForm.controls.email_subscription.value"
                      (click)="$event.stopPropagation(); userEditForm.toggleEmailSubscription()"
                    >
                    </app-toggle>
                  </div>
                </div>
              </div>
              <div class="workspace__footer-container" #container_footer>
                <div class="workspace__footer" #footer>
                  <a href="javascript:void(0)" class="button" (click)="back()">
                    <span class="button__label">{{ 'Cancel' | localize }}</span>
                  </a>
                  <button
                    type="submit"
                    class="button button_primary"
                    [disabled]="userEditForm.pristine || userEditForm.invalid || userEditForm.disabled || submitting"
                  >
                    <app-loader-small *ngIf="submitting" class="button__icon button__icon_left"> </app-loader-small>
                    <span class="button__label">{{ 'Save changes' | localize }}</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
