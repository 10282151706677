/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../colors-shared/directives/button-tint-color/button-tint-color.directive";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../field-wrapper/field-wrapper.component.ngfactory";
import * as i5 from "../field-wrapper/field-wrapper.component";
import * as i6 from "../../../../shared/directives/element/element.directive";
import * as i7 from "../../../../core/services/document/document.service";
import * as i8 from "../../../models-list/stores/model-select-source";
import * as i9 from "../../../model-queries/services/model/model.service";
import * as i10 from "../../../model-queries/stores/model-description.store";
import * as i11 from "../../../projects/stores/current-project.store";
import * as i12 from "../../../projects/stores/current-environment.store";
import * as i13 from "../../../model-queries/services/model-utils/model-utils.service";
import * as i14 from "./radio-button-field.component";
var styles_RadioButtonFieldComponent = [];
var RenderType_RadioButtonFieldComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RadioButtonFieldComponent, data: {} });
export { RenderType_RadioButtonFieldComponent as RenderType_RadioButtonFieldComponent };
function View_RadioButtonFieldComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "a", [["class", "button button_small"], ["href", "javascript:void(0)"]], [[2, "button_segment-left", null], [2, "button_segment-right", null], [2, "button_segment-middle", null], [2, "button_primary", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setCurrentValue(_v.context.$implicit.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.first && !_v.context.last); var currVal_1 = (!_v.context.first && _v.context.last); var currVal_2 = (!_v.context.first && !_v.context.last); var currVal_3 = (((_co.valueOption == null) ? null : _co.valueOption.value) === _v.context.$implicit.value); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_4); }); }
function View_RadioButtonFieldComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_RadioButtonFieldComponent_4)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.source.options$)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_RadioButtonFieldComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "button button_small button_disabled"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "span", [], [[2, "loading-animation", null]], null, null, null, null)), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Option"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.source.loading$)); _ck(_v, 3, 0, currVal_0); }); }
function View_RadioButtonFieldComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "radio-button-field radio-button-field_manual-margin"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 3, null, View_RadioButtonFieldComponent_3)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 3, null, View_RadioButtonFieldComponent_5)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var tmp_0_0 = null; var currVal_0 = (!i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.source.loading$)) && (((tmp_0_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 4).transform(_co.source.options$))) == null) ? null : tmp_0_0.length)); _ck(_v, 2, 0, currVal_0); var tmp_1_0 = null; var currVal_1 = (i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform(_co.source.loading$)) || !(((tmp_1_0 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 8).transform(_co.source.options$))) == null) ? null : tmp_1_0.length)); _ck(_v, 6, 0, currVal_1); }, null); }
function View_RadioButtonFieldComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "button__icon"]], [[2, "button__icon_left", null]], null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(2, 1)], function (_ck, _v) { var currVal_1 = "button__icon"; var currVal_2 = _ck(_v, 2, 0, ("icon-" + _v.parent.context.$implicit.icon)); _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.name; _ck(_v, 0, 0, currVal_0); }); }
function View_RadioButtonFieldComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.name; _ck(_v, 1, 0, currVal_0); }); }
function View_RadioButtonFieldComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "a", [["class", "button button_small"], ["href", "javascript:void(0)"]], [[2, "button_segment-left", null], [2, "button_segment-right", null], [2, "button_segment-middle", null], [2, "button_primary", null], [2, "button_icon", null], [2, "button_disabled", null], [8, "style", 2], [2, "button_tint-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setCurrentValue(_v.context.$implicit.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 540672, null, 0, i2.ButtonTintColorDirective, [i3.DomSanitizer], { appTintColor: [0, "appTintColor"], appTintColorStyle: [1, "appTintColorStyle"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RadioButtonFieldComponent_9)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RadioButtonFieldComponent_10)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.accentColor; var currVal_9 = ((((_co.valueOption == null) ? null : _co.valueOption.value) === _v.context.$implicit.value) ? _co.tintStyles.Primary : _co.tintStyles.Default); _ck(_v, 1, 0, currVal_8, currVal_9); var currVal_10 = _v.context.$implicit.icon; _ck(_v, 3, 0, currVal_10); var currVal_11 = _v.context.$implicit.name; _ck(_v, 5, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.first && !_v.context.last); var currVal_1 = (!_v.context.first && _v.context.last); var currVal_2 = (!_v.context.first && !_v.context.last); var currVal_3 = ((((_co.valueOption == null) ? null : _co.valueOption.value) === _v.context.$implicit.value) && !_co.accentColor); var currVal_4 = (_v.context.$implicit.icon && !_v.context.$implicit.name); var currVal_5 = ((_co.control == null) ? null : _co.control.disabled); var currVal_6 = i0.ɵnov(_v, 1).style; var currVal_7 = i0.ɵnov(_v, 1).cls; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_RadioButtonFieldComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_RadioButtonFieldComponent_8)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.options$)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_RadioButtonFieldComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "button button_small button_disabled"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Option"]))], null, null); }
function View_RadioButtonFieldComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "radio-button-field radio-button-field_manual-margin"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_RadioButtonFieldComponent_7)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_RadioButtonFieldComponent_11)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var tmp_0_0 = null; var currVal_0 = (((tmp_0_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.options$))) == null) ? null : tmp_0_0.length); _ck(_v, 2, 0, currVal_0); var tmp_1_0 = null; var currVal_1 = !(((tmp_1_0 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform(_co.options$))) == null) ? null : tmp_1_0.length); _ck(_v, 5, 0, currVal_1); }, null); }
function View_RadioButtonFieldComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "app-field-wrapper", [], null, null, null, i4.View_FieldWrapperComponent_0, i4.RenderType_FieldWrapperComponent)), i0.ɵdid(2, 245760, null, 0, i5.FieldWrapperComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], readonly: [2, "readonly"], requiredAsterisk: [3, "requiredAsterisk"], label: [4, "label"], errors: [5, "errors"], truncate: [6, "truncate"], manualMargin: [7, "manualMargin"], labelButtons: [8, "labelButtons"], tooltip: [9, "tooltip"], idToken: [10, "idToken"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_RadioButtonFieldComponent_2)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_RadioButtonFieldComponent_6)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; var currVal_1 = _co.field; var currVal_2 = _co.readonly; var currVal_3 = _co.requiredAsterisk; var currVal_4 = _co.label; var currVal_5 = _co.errors; var currVal_6 = _co.truncate; var currVal_7 = _co.manualMargin; var currVal_8 = _co.labelButtons; var currVal_9 = _co.tooltip; var currVal_10 = _co.idToken; _ck(_v, 2, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10]); var currVal_11 = _co.source; _ck(_v, 4, 0, currVal_11); var currVal_12 = (!_co.source && _co.options$); _ck(_v, 6, 0, currVal_12); }, null); }
function View_RadioButtonFieldComponent_13(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "span", [["class", "status"]], null, null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(3, 2), (_l()(), i0.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "status"; var currVal_1 = _ck(_v, 3, 0, (("color_" + _co.valueOption.color) + "_1"), (("background-color_" + _co.valueOption.color) + "_2")); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.valueOption.name || _co.valueOption.value); _ck(_v, 4, 0, currVal_2); }); }
function View_RadioButtonFieldComponent_14(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formatEmpty(_co.value); _ck(_v, 1, 0, currVal_0); }); }
function View_RadioButtonFieldComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "app-field-wrapper", [], null, null, null, i4.View_FieldWrapperComponent_0, i4.RenderType_FieldWrapperComponent)), i0.ɵdid(1, 245760, null, 0, i5.FieldWrapperComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], readonly: [2, "readonly"], requiredAsterisk: [3, "requiredAsterisk"], label: [4, "label"], errors: [5, "errors"], truncate: [6, "truncate"], manualMargin: [7, "manualMargin"], labelButtons: [8, "labelButtons"], tooltip: [9, "tooltip"], idToken: [10, "idToken"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_RadioButtonFieldComponent_13)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_RadioButtonFieldComponent_14)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; var currVal_1 = _co.field; var currVal_2 = _co.readonly; var currVal_3 = _co.requiredAsterisk; var currVal_4 = _co.label; var currVal_5 = _co.errors; var currVal_6 = _co.truncate; var currVal_7 = _co.manualMargin; var currVal_8 = _co.labelButtons; var currVal_9 = _co.tooltip; var currVal_10 = _co.idToken; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10]); var currVal_11 = _co.valueOption; _ck(_v, 3, 0, currVal_11); var currVal_12 = !_co.valueOption; _ck(_v, 5, 0, currVal_12); }, null); }
export function View_RadioButtonFieldComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["appElement", ""]], null, [[null, "appElementFirstVisible"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("appElementFirstVisible" === en)) {
        var pd_0 = (_co.onFirstVisible() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 8601600, null, 0, i6.ElementDirective, [i0.ElementRef, i7.DocumentService], { appElementDetectVisible: [0, "appElementDetectVisible"] }, { appElementFirstVisible: "appElementFirstVisible" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RadioButtonFieldComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RadioButtonFieldComponent_12)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.readonly; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.readonly; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_RadioButtonFieldComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-radio-button-field", [], null, null, null, View_RadioButtonFieldComponent_0, RenderType_RadioButtonFieldComponent)), i0.ɵprd(4608, null, i8.ModelSelectSource, i8.ModelSelectSource, [i9.ModelService, i10.ModelDescriptionStore, i11.CurrentProjectStore, i12.CurrentEnvironmentStore, i0.Injector, i13.ModelUtilsService]), i0.ɵdid(2, 770048, null, 0, i14.RadioButtonFieldComponent, [[2, i10.ModelDescriptionStore], i0.Injector, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var RadioButtonFieldComponentNgFactory = i0.ɵccf("app-radio-button-field", i14.RadioButtonFieldComponent, View_RadioButtonFieldComponent_Host_0, { form: "form", field: "field", readonly: "readonly", requiredAsterisk: "requiredAsterisk", value: "value", label: "label", errors: "errors", autofocus: "autofocus", context: "context", contextElement: "contextElement", truncate: "truncate", compact: "compact", manualMargin: "manualMargin", labelButtons: "labelButtons", tooltip: "tooltip", accentColor: "accentColor" }, {}, []);
export { RadioButtonFieldComponentNgFactory as RadioButtonFieldComponentNgFactory };
