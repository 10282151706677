var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import pickBy from 'lodash/pickBy';
import { Input } from '@modules/fields';
import { AlignHorizontal } from '../../align-horizontal';
import { TintStyle } from '../../view-settings-action';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
var FormSubmitElementItem = /** @class */ (function (_super) {
    __extends(FormSubmitElementItem, _super);
    function FormSubmitElementItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = ElementType.FormSubmit;
        _this.alignHorizontalDefault = AlignHorizontal.Left;
        _this.style = TintStyle.Primary;
        return _this;
    }
    FormSubmitElementItem.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.icon = this.params['icon'];
        this.style = this.params['style'];
        this.tint = this.params['tint'];
        if (this.params['verbose_name_input']) {
            this.verboseNameInput = new Input().deserialize(this.params['verbose_name_input']);
        }
        else if (this.params['verbose_name']) {
            // Backward compatibility
            this.verboseNameInput = new Input().deserializeFromStatic('value', this.params['verbose_name']);
        }
        if (this.params['disabled_input']) {
            this.disabledInput = new Input().deserialize(this.params['disabled_input']);
        }
        return this;
    };
    FormSubmitElementItem.prototype.serialize = function (fields) {
        this.params = {};
        this.params['verbose_name_input'] = this.verboseNameInput ? this.verboseNameInput.serialize() : null;
        this.params['icon'] = this.icon;
        this.params['style'] = this.style;
        this.params['tint'] = this.tint;
        this.params['disabled_input'] = this.disabledInput ? this.disabledInput.serialize() : null;
        var data = _super.prototype.serialize.call(this);
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Object.defineProperty(FormSubmitElementItem.prototype, "typeStr", {
        get: function () {
            return 'form submit button';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormSubmitElementItem.prototype, "analyticsName", {
        get: function () {
            return 'form_submit';
        },
        enumerable: true,
        configurable: true
    });
    FormSubmitElementItem.prototype.defaultName = function () {
        return 'Form Submit';
    };
    return FormSubmitElementItem;
}(ElementItem));
export { FormSubmitElementItem };
registerElementForType(ElementType.FormSubmit, FormSubmitElementItem);
