import { Injector } from '@angular/core';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { PopupService } from '@common/popups';
import { TimelinePopupComponent, UserActivitiesController } from '@modules/activities-components';
import { CustomizeService, ViewSettingsStore } from '@modules/customize';
import { applyParamInputs } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { ThemeService } from '@modules/theme';
import { controlValue, isAbsoluteUrl, isSet } from '@shared';
import { getMenuItemSystemActionTypeInfo, MenuItemActionType, MenuItemSystemActionType } from '../../data/menu-item-action';
var MenuItemActionService = /** @class */ (function () {
    function MenuItemActionService(currentProjectStore, currentEnvironmentStore, viewSettingsStore, themeService, routing, userActivitiesController, customizeService, popupService, injector) {
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.viewSettingsStore = viewSettingsStore;
        this.themeService = themeService;
        this.routing = routing;
        this.userActivitiesController = userActivitiesController;
        this.customizeService = customizeService;
        this.popupService = popupService;
        this.injector = injector;
    }
    MenuItemActionService.prototype.getActionExecution = function (action, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        if (!action) {
            return of({});
        }
        var project = this.currentProjectStore.instance;
        var environment = this.currentEnvironmentStore.instance;
        if (action.type == MenuItemActionType.Page) {
            if (!project.hasEnvironmentPagePermission(environment, action.pageUid, 'r')) {
                return of({});
            }
            return this.viewSettingsStore.getDetailByUid(action.pageUid).pipe(map(function (page) {
                var params = applyParamInputs({}, action.inputs, {
                    context: options.context,
                    parameters: page ? page.parameters : undefined
                });
                return {
                    link: page ? _this.routing.appLink(page.link) : undefined,
                    queryParams: params
                    // link: result ? result.link : undefined
                };
            }));
        }
        else if (action.type == MenuItemActionType.URL) {
            if (!isSet(action.url)) {
                return of({});
            }
            if (isAbsoluteUrl(action.url)) {
                return of({
                    href: action.url
                });
            }
            else {
                return of({
                    link: [action.url]
                });
            }
        }
        else if (action.type == MenuItemActionType.System) {
            if (action.systemType == MenuItemSystemActionType.Home) {
                return of({
                    link: this.routing.appLink(this.currentProjectStore.instance.homeLink)
                    // link: this.currentProjectStore.instance.homeLink
                });
            }
            else if (action.systemType == MenuItemSystemActionType.Profile) {
                return of({
                    link: ['/profile/update']
                });
            }
            else if (action.systemType == MenuItemSystemActionType.Users) {
                if (!project.hasEnvironmentAccessPermission(environment)) {
                    return of({});
                }
                return of({
                    link: this.routing.appLink(this.currentProjectStore.instance.settingsUsersLink)
                    // link: this.currentProjectStore.instance.settingsUsersLink
                });
            }
            else if (action.systemType == MenuItemSystemActionType.ActivityLog) {
                return of({
                    handler: function () {
                        _this.openActivityLogPopup();
                    }
                });
            }
            else if (action.systemType == MenuItemSystemActionType.ActivityLogPage) {
                return of({
                    link: this.routing.appLink(this.currentProjectStore.instance.settingsUserActivitiesLink)
                    // link: this.currentProjectStore.instance.settingsUserActivitiesLink
                });
            }
            else if (action.systemType == MenuItemSystemActionType.Collaboration) {
                return of({
                    handler: function () {
                        _this.openTimelinePopup();
                    }
                });
            }
            else if (action.systemType == MenuItemSystemActionType.CollaborationTasksPage) {
                return of({
                    link: this.routing.appLink(this.currentProjectStore.instance.settingsCollaborationLink('tasks'))
                    // link: this.currentProjectStore.instance.settingsCollaborationLink('tasks')
                });
            }
            else if (action.systemType == MenuItemSystemActionType.CollaborationMessagesPage) {
                return of({
                    link: this.routing.appLink(this.currentProjectStore.instance.settingsCollaborationLink('messages'))
                    // link: this.currentProjectStore.instance.settingsCollaborationLink('messages')
                });
            }
            else if (action.systemType == MenuItemSystemActionType.ToggleTheme) {
                return of({
                    handler: function () {
                        _this.themeService.toggleTheme();
                    }
                });
            }
            else if (action.systemType == MenuItemSystemActionType.Logout) {
                return of({
                    link: ['/logout']
                });
            }
        }
        return of({});
    };
    MenuItemActionService.prototype.getActionValueDisplay$ = function (control, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        return combineLatest(controlValue(control.controls.type), controlValue(control.controls.page_uid), controlValue(control.controls.url), controlValue(control.controls.system_type)).pipe(switchMap(function (_a) {
            var type = _a[0], pageUid = _a[1], url = _a[2], systemType = _a[3];
            if (type == MenuItemActionType.Page) {
                return _this.viewSettingsStore.getDetailByUid(pageUid).pipe(map(function (page) {
                    if (!page) {
                        return {
                            label: 'Open page (not selected)',
                            labelShort: 'Open page',
                            icon: 'document'
                        };
                    }
                    return {
                        label: "Open page - " + page.name,
                        labelShort: "/" + page.uniqueName,
                        icon: 'document'
                    };
                }));
            }
            else if (type == MenuItemActionType.URL) {
                return of({
                    label: options.excludeUrl || !isSet(url) ? 'Open URL' : "Open URL - " + url,
                    labelShort: options.excludeUrl ? 'Open URL' : url,
                    icon: 'external_link'
                });
            }
            else if (type == MenuItemActionType.System) {
                if (!isSet(systemType)) {
                    return of({
                        label: 'Built-in action',
                        icon: 'gear'
                    });
                }
                var info = getMenuItemSystemActionTypeInfo(systemType);
                return of({
                    label: info.actionLabel,
                    labelShort: info.label,
                    icon: info.icon
                });
            }
            return of(undefined);
        }));
    };
    MenuItemActionService.prototype.openTimelinePopup = function () {
        var _this = this;
        if (this.popupService.items.find(function (item) { return item === _this.timelinePopup; })) {
            return;
        }
        var handler = this.customizeService.handler;
        var params = handler && handler.getCollaborationParams ? handler.getCollaborationParams() : {};
        this.timelinePopup = this.popupService.push({
            component: TimelinePopupComponent,
            disablePointerEvents: true,
            enableWindowScroll: true,
            inputs: { baseParams: params },
            injector: this.injector
        });
    };
    MenuItemActionService.prototype.openActivityLogPopup = function () {
        var _this = this;
        if (this.popupService.items.find(function (item) { return item === _this.activityLogPopup; })) {
            return;
        }
        var handler = this.customizeService.handler;
        var params = handler && handler.getUserActivitiesParams ? handler.getUserActivitiesParams() : {};
        this.activityLogPopup = this.userActivitiesController.open(params);
    };
    return MenuItemActionService;
}());
export { MenuItemActionService };
