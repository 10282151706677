var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Injector } from '@angular/core';
import fromPairs from 'lodash/fromPairs';
import toPairs from 'lodash/toPairs';
import { parseFieldType } from '@modules/fields';
import { Model } from '@modules/models';
import { isSet } from '@shared';
export var ModelResponse;
(function (ModelResponse) {
    var GetResponse = /** @class */ (function () {
        function GetResponse(injector) {
            this.injector = injector;
        }
        GetResponse.prototype.createModel = function () {
            return Injector.create({
                providers: [{ provide: Model, deps: [Injector] }],
                parent: this.injector
            }).get(Model);
        };
        GetResponse.prototype.deserialize = function (data, model, modelDescription) {
            var _this = this;
            this.results = data['results'].map(function (item, i) {
                var relations = (data['relations'] || [])[i];
                var instance = _this.createModel().deserialize(model, item, relations);
                if (modelDescription) {
                    instance.setUp(modelDescription);
                }
                return instance;
            });
            this.next = data['next'];
            this.previous = data['previous'];
            this.count = data['count'];
            this.hasMore = !!data['has_more'];
            this.numPages = data['num_pages'];
            this.perPage = data['per_page'];
            this.cursorPrev = data['cursor_prev'];
            this.cursorNext = data['cursor_next'];
            return this;
        };
        return GetResponse;
    }());
    ModelResponse.GetResponse = GetResponse;
    var SqlResponse = /** @class */ (function () {
        function SqlResponse() {
            this.columnDescriptions = {};
        }
        SqlResponse.prototype.deserialize = function (data) {
            this.data = data['data'];
            this.columns = data['columns'];
            if (data['column_descriptions']) {
                this.columnDescriptions = fromPairs(toPairs(data['column_descriptions'])
                    .filter(function (_a) {
                    var k = _a[0];
                    return isSet(k);
                })
                    .map(function (_a) {
                    var k = _a[0], v = _a[1];
                    return [
                        k,
                        __assign({}, (isSet(v['field']) ? { field: parseFieldType(v['field']) } : {}))
                    ];
                }));
            }
            if (!this.columnDescriptions.length) {
                this.columnDescriptions = undefined;
            }
            this.count = data['count'];
            return this;
        };
        SqlResponse.prototype.serialize = function () {
            return {
                data: this.data,
                columns: this.columns,
                column_descriptions: this.columnDescriptions,
                count: this.count
            };
        };
        SqlResponse.prototype.toObject = function () {
            var _this = this;
            return this.data.map(function (item) {
                if (_this.columns.length == 1 && _this.columns[0] === null) {
                    return item[0];
                }
                else if (_this.columns) {
                    return fromPairs(_this.columns.map(function (column, i) { return [column, item[i]]; }));
                }
                else {
                    return _this.data.map(function (row) {
                        return fromPairs(row.map(function (value, i) { return [i, value]; }));
                    });
                }
            });
        };
        return SqlResponse;
    }());
    ModelResponse.SqlResponse = SqlResponse;
})(ModelResponse || (ModelResponse = {}));
