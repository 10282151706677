/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../ui/components/checkbox/checkbox.component.ngfactory";
import * as i2 from "../../../../ui/components/checkbox/checkbox.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../../../shared/components/toggle/toggle.component.ngfactory";
import * as i5 from "../../../../shared/components/toggle/toggle.component";
import * as i6 from "../../../../common/tips/directives/tip/tip.directive";
import * as i7 from "../field-errors/field-errors.component.ngfactory";
import * as i8 from "../field-errors/field-errors.component";
import * as i9 from "@angular/common";
import * as i10 from "@angular/forms";
import * as i11 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i12 from "../../../../common/unique-id/pipes/unique-id/unique-id.pipe";
import * as i13 from "../../../../common/unique-id/services/unique-id/unique-id.service";
import * as i14 from "./boolean-field.component";
var styles_BooleanFieldComponent = [];
var RenderType_BooleanFieldComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BooleanFieldComponent, data: {} });
export { RenderType_BooleanFieldComponent as RenderType_BooleanFieldComponent };
function View_BooleanFieldComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-checkbox", [["class", "checkbox-control__checkbox"]], null, null, null, i1.View_CheckboxComponent_0, i1.RenderType_CheckboxComponent)), i0.ɵdid(1, 770048, null, 0, i2.CheckboxComponent, [i3.DomSanitizer, i0.ChangeDetectorRef], { control: [0, "control"], accentColor: [1, "accentColor"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.control; var currVal_1 = _co.accentColor; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_BooleanFieldComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toggle", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ToggleComponent_0, i4.RenderType_ToggleComponent)), i0.ɵdid(1, 114688, null, 0, i5.ToggleComponent, [], { orange: [0, "orange"], selected: [1, "selected"], disabled: [2, "disabled"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.field.params["orange"]; var currVal_1 = _co.currentValue; var currVal_2 = ((_co.control == null) ? null : _co.control.disabled); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_BooleanFieldComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "checkbox-control__help help-icon icon-help"]], null, null, null, null, null)), i0.ɵdid(1, 737280, null, 0, i6.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(2, { side: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tooltip; var currVal_1 = _ck(_v, 2, 0, "top"); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_BooleanFieldComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-field-errors", [], null, null, null, i7.View_FieldErrorsComponent_0, i7.RenderType_FieldErrorsComponent)), i0.ɵdid(1, 114688, null, 0, i8.FieldErrorsComponent, [], { form: [0, "form"], fieldName: [1, "fieldName"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; var currVal_1 = _co.field.name; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_BooleanFieldComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "field"]], [[2, "field_manual-margin", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "checkbox-control"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BooleanFieldComponent_3)), i0.ɵdid(3, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BooleanFieldComponent_4)), i0.ɵdid(5, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "label", [["class", "checkbox-control__label checkbox-label handle"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_BooleanFieldComponent_5)), i0.ɵdid(9, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(10, 1), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BooleanFieldComponent_6)), i0.ɵdid(12, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.style == _co.styles.Checkbox); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.style == _co.styles.Toggle); _ck(_v, 5, 0, currVal_2); var currVal_4 = i0.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i0.ɵnov(_v.parent.parent, 0), _co.tooltip)); _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.errors; _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.manualMargin; _ck(_v, 0, 0, currVal_0); var currVal_3 = _co.field.label; _ck(_v, 7, 0, currVal_3); }); }
function View_BooleanFieldComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-checkbox", [], null, null, null, i1.View_CheckboxComponent_0, i1.RenderType_CheckboxComponent)), i0.ɵdid(1, 770048, null, 0, i2.CheckboxComponent, [i3.DomSanitizer, i0.ChangeDetectorRef], { control: [0, "control"], accentColor: [1, "accentColor"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.control; var currVal_1 = _co.accentColor; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_BooleanFieldComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toggle", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ToggleComponent_0, i4.RenderType_ToggleComponent)), i0.ɵdid(1, 114688, null, 0, i5.ToggleComponent, [], { orange: [0, "orange"], selected: [1, "selected"], disabled: [2, "disabled"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.field.params["orange"]; var currVal_1 = _co.currentValue; var currVal_2 = ((_co.control == null) ? null : _co.control.disabled); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_BooleanFieldComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-field-errors", [], null, null, null, i7.View_FieldErrorsComponent_0, i7.RenderType_FieldErrorsComponent)), i0.ɵdid(1, 114688, null, 0, i8.FieldErrorsComponent, [], { form: [0, "form"], fieldName: [1, "fieldName"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; var currVal_1 = _co.field.name; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_BooleanFieldComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BooleanFieldComponent_8)), i0.ɵdid(2, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BooleanFieldComponent_9)), i0.ɵdid(4, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BooleanFieldComponent_10)), i0.ɵdid(6, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.style == _co.styles.Checkbox); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.style == _co.styles.Toggle); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.errors; _ck(_v, 6, 0, currVal_2); }, null); }
function View_BooleanFieldComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 540672, null, 0, i10.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i10.ControlContainer, null, [i10.FormGroupDirective]), i0.ɵdid(3, 16384, null, 0, i10.NgControlStatusGroup, [[4, i10.ControlContainer]], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BooleanFieldComponent_2)), i0.ɵdid(5, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BooleanFieldComponent_7)), i0.ɵdid(7, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.label; _ck(_v, 5, 0, currVal_1); var currVal_2 = !_co.label; _ck(_v, 7, 0, currVal_2); }, null); }
function View_BooleanFieldComponent_13(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "field__label-help help-icon icon-help"]], null, null, null, null, null)), i0.ɵdid(1, 737280, null, 0, i6.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(2, { side: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tooltip; var currVal_1 = _ck(_v, 2, 0, "top"); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_BooleanFieldComponent_14(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "checkmark"]], [[2, "checkmark_on", null], [2, "checkmark_off", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [["class", "checkmark__icon"]], [[2, "icon-check_2", null], [2, "icon-close", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentValue; var currVal_1 = !_co.currentValue; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.currentValue; var currVal_3 = !_co.currentValue; _ck(_v, 1, 0, currVal_2, currVal_3); }); }
function View_BooleanFieldComponent_15(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" --- "]))], null, null); }
function View_BooleanFieldComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "field"]], [[2, "field_manual-margin", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "label", [["class", "field__label"]], [[8, "htmlFor", 0]], null, null, null, null)), i0.ɵppd(2, 2), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "field__label-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_BooleanFieldComponent_13)), i0.ɵdid(6, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(7, 1), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_BooleanFieldComponent_14)), i0.ɵdid(9, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(10, 1), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_BooleanFieldComponent_15)), i0.ɵdid(12, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(13, 1)], function (_ck, _v) { var _co = _v.component; var currVal_3 = i0.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i0.ɵnov(_v.parent.parent, 0), _co.tooltip)); _ck(_v, 6, 0, currVal_3); var currVal_4 = i0.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i0.ɵnov(_v.parent.parent, 0), _co.currentValue)); _ck(_v, 9, 0, currVal_4); var currVal_5 = !i0.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i0.ɵnov(_v.parent.parent, 0), _co.currentValue)); _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.manualMargin; _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent, 1), _co.field.name, _co.idToken)); _ck(_v, 1, 0, currVal_1); var currVal_2 = _co.field.label; _ck(_v, 4, 0, currVal_2); }); }
function View_BooleanFieldComponent_17(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "checkmark"]], [[2, "checkmark_on", null], [2, "checkmark_off", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [["class", "checkmark__icon"]], [[2, "icon-check_2", null], [2, "icon-close", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentValue; var currVal_1 = !_co.currentValue; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.currentValue; var currVal_3 = !_co.currentValue; _ck(_v, 1, 0, currVal_2, currVal_3); }); }
function View_BooleanFieldComponent_18(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" --- "]))], null, null); }
function View_BooleanFieldComponent_16(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_BooleanFieldComponent_17)), i0.ɵdid(2, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(3, 1), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_BooleanFieldComponent_18)), i0.ɵdid(5, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(6, 1), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent.parent, 0), _co.currentValue)); _ck(_v, 2, 0, currVal_0); var currVal_1 = !i0.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i0.ɵnov(_v.parent.parent, 0), _co.currentValue)); _ck(_v, 5, 0, currVal_1); }, null); }
function View_BooleanFieldComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BooleanFieldComponent_12)), i0.ɵdid(2, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BooleanFieldComponent_16)), i0.ɵdid(4, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.label; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_BooleanFieldComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i11.IsSetPipe, []), i0.ɵpid(0, i12.UniqueIdPipe, [i13.UniqueIdService]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BooleanFieldComponent_1)), i0.ɵdid(3, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BooleanFieldComponent_11)), i0.ɵdid(5, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.readonly; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.readonly; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_BooleanFieldComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-boolean-field", [], null, null, null, View_BooleanFieldComponent_0, RenderType_BooleanFieldComponent)), i0.ɵdid(1, 49152, null, 0, i14.BooleanFieldComponent, [], null, null)], null, null); }
var BooleanFieldComponentNgFactory = i0.ɵccf("app-boolean-field", i14.BooleanFieldComponent, View_BooleanFieldComponent_Host_0, { form: "form", field: "field", readonly: "readonly", requiredAsterisk: "requiredAsterisk", value: "value", label: "label", errors: "errors", autofocus: "autofocus", context: "context", contextElement: "contextElement", truncate: "truncate", compact: "compact", manualMargin: "manualMargin", labelButtons: "labelButtons", tooltip: "tooltip", accentColor: "accentColor" }, {}, []);
export { BooleanFieldComponentNgFactory as BooleanFieldComponentNgFactory };
