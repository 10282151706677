import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FormUtilsModule } from '@common/form-utils';
import { LocalizeModule } from '@common/localize';
import { TipsModule } from '@common/tips';
import { ColorsSharedModule } from '@modules/colors-shared';
import { SharedModule } from '@shared';

import { DialogComponent } from './components/dialog/dialog.component';
import { LoadingDialogComponent } from './components/loading-dialog/loading-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TipsModule,
    FormUtilsModule,
    LocalizeModule,
    ColorsSharedModule
  ],
  declarations: [DialogComponent, LoadingDialogComponent],
  exports: [DialogComponent],
  entryComponents: [DialogComponent, LoadingDialogComponent]
})
export class DialogsModule {}
