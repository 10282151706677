import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';

import {
  EditableField,
  fieldDescriptions,
  FieldType,
  fieldTypesOrder,
  FilterableField,
  getFieldDescriptionByType,
  SortableField,
  ToggleField
} from '@modules/fields';
import { controlValue, isSet, objectsListSort } from '@shared';

export interface FieldsEditConfigurable {
  name?: boolean;
  verboseName?: boolean;
  field?: boolean;
  required?: boolean;
  editable?: boolean;
  editableOnly?: boolean;
  sortable?: boolean;
  visible?: boolean;
  disable?: boolean;
  margin?: boolean;
  params?: boolean;
  add?: boolean;
  action?: boolean;
  onChangeActions?: boolean;
  value?: boolean;
  lookup?: boolean;
  aggregate?: boolean;
}

export interface FieldsEditItem extends EditableField, FilterableField, SortableField, ToggleField {
  protected?: boolean;
}

export interface FieldEditFormOptions {
  cleanNameChars?: boolean;
}

@Injectable()
export class FieldsEditForm {
  control: AbstractControl;
  configurable: FieldsEditConfigurable;
  serializeItem: (value: FieldsEditItem) => any;
  deserializeItem: (value: any) => FieldsEditItem;
  options: FieldEditFormOptions = {};

  form = new FormArray([]);
  fieldOptions = fieldDescriptions
    .filter(item => item.public)
    .map(item => {
      return {
        value: item.name,
        name: item.label
      };
    })
    .sort(objectsListSort(fieldTypesOrder, item => item.value));
  formUpdated = new Subject<void>();
  formIgnoreChange = false;

  init(control: AbstractControl, createNewItemIfEmpty = false, options: FieldEditFormOptions = {}) {
    this.control = control;
    this.options = options;

    control.valueChanges.subscribe(value => this.updateValueFromControl(value));

    this.updateValueFromControl(control.value);

    this.form.valueChanges
      .pipe(
        filter(() => !this.formIgnoreChange),
        debounceTime(60)
      )
      .subscribe(value => this.updateValueToControl(value));

    if (createNewItemIfEmpty) {
      const currentValue = this.deserializeValue(control.value);

      if (!currentValue.length) {
        this.formIgnoreChange = true;
        this.arraySet([this.createItem()]);
        this.formIgnoreChange = false;
      }
    }
  }

  serializeValue(value: FieldsEditItem[]): any[] {
    return value.filter(item => isSet(item.name)).map(item => (this.serializeItem ? this.serializeItem(item) : item));
  }

  deserializeValue(value: any[]): FieldsEditItem[] {
    return value.map(item => (this.deserializeItem ? this.deserializeItem(item) : item));
  }

  updateValueFromControl(value: any[]) {
    value = value || [];

    if (isEqual(this.serializeValue(this.form.value), value)) {
      return;
    }

    const newValue = this.deserializeValue(value);

    this.formIgnoreChange = true;
    this.arraySet(newValue.map(newItemValue => this.createItem(newItemValue)));
    this.formIgnoreChange = false;
  }

  updateValueToControl(value: any[]) {
    this.control.patchValue(this.serializeValue(value));
    this.control.markAsDirty();
  }

  createItem(value?: Object): FormGroup {
    const form = new FormGroup({
      name: new FormControl(''),
      verboseName: new FormControl(''),
      description: new FormControl(''),
      field: new FormControl('CharField'),
      required: new FormControl(true),
      editable: new FormControl(false),
      filterable: new FormControl(false),
      sortable: new FormControl(false),
      defaultType: new FormControl(''),
      defaultValue: new FormControl(''),
      params: new FormControl({}),
      visible: new FormControl(true),
      flex: new FormControl(false),
      query: new FormControl(''),
      code: new FormControl(''),
      valueInput: new FormControl(undefined),
      visibleInput: new FormControl(undefined),
      action: new FormControl(undefined),
      placeholder: new FormControl(''),
      validatorType: new FormControl(null),
      validatorParams: new FormControl(null),
      protected: new FormControl(false)
    });

    if (value) {
      form.patchValue(value);
    }

    if (this.options.cleanNameChars) {
      controlValue(form.controls['name']).subscribe(nameValue => {
        if (typeof nameValue == 'string') {
          const cleanNameValue = nameValue.replace(/[^\w]/g, '_').replace(/^\d/, '_');

          if (cleanNameValue != nameValue) {
            form.controls['name'].patchValue(cleanNameValue);
          }
        }
      });
    }

    return form;
  }

  toggleItemVisible(formGroup: FormGroup) {
    const visible = !formGroup.value['visible'];

    formGroup.patchValue({ visible: visible });

    // const currentIndex = this.form.controls.indexOf(formGroup);
    // const lastVisibleIndex = findLastIndex(this.form.controls, item => item !== formGroup && item.value['visible']);
    //
    // if (visible) {
    //   // if (lastVisibleIndex != -1 && currentIndex > lastVisibleIndex) {
    //   //   moveItemInArray(this.form.controls, currentIndex, lastVisibleIndex + 1);
    //   // }
    // } else {
    //   if (lastVisibleIndex != -1 && currentIndex < lastVisibleIndex) {
    //     moveItemInArray(this.form.controls, currentIndex, lastVisibleIndex + 1 - 1);
    //   }
    // }
  }

  toggleItemRequired(formGroup: FormGroup) {
    formGroup.patchValue({ required: !formGroup.value['required'] });
  }

  fieldDescriptionIcon(field: FieldType): string {
    return getFieldDescriptionByType(field).icon;
  }

  fieldIcon$(form: FormGroup): Observable<string> {
    return controlValue<FieldType>(form.controls['field']).pipe(map(value => this.fieldDescriptionIcon(value)));
  }

  arraySet(groups: FormGroup[]) {
    range(this.form.controls.length).forEach(() => this.form.removeAt(0));

    groups
      .sort((lhs, rhs) => {
        const isVisibleLhs = lhs.value['visible'] ? 1 : 0;
        const isVisibleRhs = rhs.value['visible'] ? 1 : 0;
        const indexLhs = groups.indexOf(lhs);
        const indexRhs = groups.indexOf(rhs);

        return (isVisibleLhs - isVisibleRhs) * -1 + (indexLhs - indexRhs) / 10;
      })
      .forEach(item => this.form.push(item));
    this.formUpdated.next();
  }

  arrayAppend(group: FormGroup) {
    this.form.push(group);
    this.formUpdated.next();
  }

  arrayDelete(group: FormGroup) {
    const index = this.form.controls.findIndex(item => item === group);

    if (index == -1) {
      return;
    }

    this.form.removeAt(index);
    this.formUpdated.next();
  }

  isToggledAll() {
    const items = this.form.controls.filter(item => !item.value['flex']);
    return items.every(item => item.value['visible']);
  }

  toggleAll() {
    const items = this.form.controls.filter(item => !item.value['flex']);
    const toggledAll = this.isToggledAll();
    items.forEach(item => item.patchValue({ visible: !toggledAll }));
  }
}
