<ng-container *ngIf="options.colorsEditable">
  <div class="sidebar__element">
    <app-sidebar-field [label]="'style'">
      <div class="sidebar-image-toggle sidebar-image-toggle_cols_3">
        <div *ngFor="let item of form.styleOptions" class="sidebar-image-toggle__col">
          <a
            href="javascript:void(0)"
            class="sidebar-image-toggle__item"
            [class.sidebar-image-toggle__item_active]="item.value === form.controls.style.value"
            (click)="form.controls.style.patchValue(item.value)"
          >
            <div
              class="sidebar-image-toggle__item-inner"
              [style.background-image]="'/assets/images/buttons/' + item.image + '.svg' | appDeployCssUrl"
            ></div>
          </a>
        </div>
      </div>
    </app-sidebar-field>
  </div>

  <div class="sidebar__element">
    <app-sidebar-field [label]="'color'" [required]="false">
      <app-auto-field
        [form]="form"
        [label]="false"
        [field]="
          createField({
            name: 'tint',
            field: 'ColorField',
            params: { classes: ['select_fill'], allow_empty: true, empty_accent_color: true, custom_colors: true }
          })
        "
      ></app-auto-field>
    </app-sidebar-field>
  </div>
</ng-container>

<div *ngIf="options.iconEditable" class="sidebar__element">
  <app-sidebar-field [label]="'icon'">
    <app-auto-field
      [form]="form"
      [label]="false"
      [field]="
        createField({
          name: 'icon',
          label: 'Icon',
          field: 'IconField',
          params: { classes: ['select_fill'], allow_empty: true }
        })
      "
    ></app-auto-field>
  </app-sidebar-field>
</div>

<div *ngIf="options.visibleEditable" class="sidebar__element">
  <app-sidebar-field
    [label]="'Conditional Visibility'"
    [documentation]="'conditional-visibility'"
    [documentationLabel]="'See how it works'"
    [documentationSource]="object + '_visible'"
  >
    <app-input-edit
      [itemForm]="form.controls.visible_input"
      [context]="context"
      [contextElement]="contextElement"
      [contextElementPath]="contextElementPath"
      [contextElementPaths]="contextElementPaths"
      [staticValueDisabled]="true"
      [placeholder]="'Value'"
      [fill]="true"
      [analyticsSource]="object + '_visible'"
    ></app-input-edit>

    <ng-container description>
      Example: elements.field.value >= 10
    </ng-container>
  </app-sidebar-field>
</div>

<div *ngIf="options.disabledEditable" class="sidebar__element">
  <app-sidebar-field [label]="'Conditional Disable'" [documentationSource]="object + '_disable'">
    <app-input-edit
      [itemForm]="form.controls.disabled_input"
      [context]="context"
      [contextElement]="contextElement"
      [contextElementPath]="contextElementPath"
      [contextElementPaths]="contextElementPaths"
      [staticValueDisabled]="true"
      [placeholder]="'Value'"
      [fill]="true"
      [analyticsSource]="object + '_disable'"
    ></app-input-edit>

    <ng-container description>
      Example: elements.type.value == 'Refund'
    </ng-container>
  </app-sidebar-field>
</div>

<div *ngIf="options.tooltipEditable" class="sidebar__element">
  <app-sidebar-field [label]="'tooltip'">
    <app-auto-field
      [form]="form"
      [label]="false"
      [field]="
        createField({
          name: 'tooltip',
          field: 'CharField',
          placeholder: 'Show information for users',
          reset_enabled: true,
          params: { classes: ['input_fill'] }
        })
      "
    ></app-auto-field>
  </app-sidebar-field>
</div>

<div *ngIf="options.marginEditable" class="sidebar__element">
  <app-sidebar-field [label]="'Spacing'">
    <app-margin-control [control]="form.controls.margin"></app-margin-control>
  </app-sidebar-field>
</div>
