import { ChangeDetectorRef, Injector, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { ActionControllerService, ActionService } from '@modules/action-queries';
import { CustomizeService, TintStyle, ViewContext, ViewContextElement, ViewSettingsAction } from '@modules/customize';
import { applyBooleanInput$, applyParamInput$, getInputsValid$ } from '@modules/fields';
import { ITEM_OUTPUT } from '@modules/list';
import { Model } from '@modules/models';
var ModelCardActionComponent = /** @class */ (function () {
    function ModelCardActionComponent(customizeService, actionService, actionControllerService, notificationService, injector, cd) {
        this.customizeService = customizeService;
        this.actionService = actionService;
        this.actionControllerService = actionControllerService;
        this.notificationService = notificationService;
        this.injector = injector;
        this.cd = cd;
        this.indicatorWrapper = true;
        this.submitLoading = false;
        this.isVisible = true;
        this.disabled = false;
        this.style = TintStyle.Primary;
        this.tintStyles = TintStyle;
    }
    Object.defineProperty(ModelCardActionComponent.prototype, "hidden", {
        get: function () {
            return !this.isVisible && !this.customizeService.enabled;
        },
        enumerable: true,
        configurable: true
    });
    ModelCardActionComponent.prototype.ngOnInit = function () { };
    ModelCardActionComponent.prototype.ngOnDestroy = function () { };
    ModelCardActionComponent.prototype.ngOnChanges = function (changes) {
        this.initVerboseName();
        this.initStyle();
        this.initVisible();
        this.initDisabled();
    };
    ModelCardActionComponent.prototype.initVerboseName = function () {
        var _this = this;
        var _a;
        if (this.verboseNameSubscription) {
            this.verboseNameSubscription.unsubscribe();
            this.verboseNameSubscription = undefined;
        }
        if (!this.action || !this.action.verboseNameInput) {
            this.verboseName = undefined;
            this.cd.markForCheck();
            return;
        }
        this.verboseNameSubscription = applyParamInput$(this.action.verboseNameInput, {
            context: this.context,
            contextElement: this.contextElement,
            localContext: (_a = {},
                _a[ITEM_OUTPUT] = this.model.getAttributes(),
                _a),
            defaultValue: ''
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            _this.verboseName = value;
            _this.cd.markForCheck();
        });
    };
    ModelCardActionComponent.prototype.initStyle = function () {
        this.style = this.action.style ? this.action.style : TintStyle.Primary;
        this.cd.markForCheck();
    };
    ModelCardActionComponent.prototype.initVisible = function () {
        var _this = this;
        var _a;
        if (this.visibleSubscription) {
            this.visibleSubscription.unsubscribe();
            this.visibleSubscription = undefined;
        }
        if (!this.action || !this.action.visibleInput) {
            this.isVisible = true;
            this.cd.markForCheck();
            return;
        }
        this.visibleSubscription = applyBooleanInput$(this.action.visibleInput, {
            context: this.context,
            contextElement: this.contextElement,
            localContext: (_a = {},
                _a[ITEM_OUTPUT] = this.model.getAttributes(),
                _a)
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            _this.isVisible = value;
            _this.cd.markForCheck();
        });
    };
    ModelCardActionComponent.prototype.getInputsValid$ = function () {
        var _this = this;
        return this.actionService.getActionDescription(this.action).pipe(switchMap(function (actionDescription) {
            var _a;
            if (!actionDescription) {
                return of(true);
            }
            return getInputsValid$(_this.action.inputs, {
                context: _this.context,
                contextElement: _this.contextElement,
                localContext: (_a = {},
                    _a[ITEM_OUTPUT] = _this.model.getAttributes(),
                    _a),
                parameters: actionDescription.actionParams
            });
        }));
    };
    ModelCardActionComponent.prototype.getElementDisabled$ = function () {
        var _a;
        return this.action.disabledInput && this.action.disabledInput.isSet()
            ? applyBooleanInput$(this.action.disabledInput, {
                context: this.context,
                contextElement: this.contextElement,
                localContext: (_a = {},
                    _a[ITEM_OUTPUT] = this.model.getAttributes(),
                    _a)
            })
            : of(false);
    };
    ModelCardActionComponent.prototype.initDisabled = function () {
        var _this = this;
        if (this.disabledSubscription) {
            this.disabledSubscription.unsubscribe();
            this.disabledSubscription = undefined;
        }
        if (!this.action) {
            this.disabled = false;
            this.cd.markForCheck();
            return;
        }
        combineLatest(this.getInputsValid$(), this.getElementDisabled$())
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var inputsValid = _a[0], inputDisabled = _a[1];
            _this.disabled = inputDisabled || !inputsValid;
            _this.cd.markForCheck();
        });
    };
    ModelCardActionComponent.prototype.execute = function (e) {
        var _this = this;
        var _a;
        this.submitLoading = true;
        this.cd.markForCheck();
        this.context.clickEvent = e;
        this.actionControllerService
            .execute(this.action, {
            context: this.context,
            contextElement: this.contextElement,
            localContext: (_a = {},
                _a[ITEM_OUTPUT] = this.model.getAttributes(),
                _a),
            injector: this.injector
        })
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.submitLoading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.submitLoading = false;
            _this.cd.markForCheck();
        });
    };
    return ModelCardActionComponent;
}());
export { ModelCardActionComponent };
