var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Overlay } from '@angular/cdk/overlay';
import { CdkPortalOutlet } from '@angular/cdk/portal';
import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { extent, least, max, min } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import { scaleLinear, scaleTime } from 'd3-scale';
import { pointer, select } from 'd3-selection';
import fromPairs from 'lodash/fromPairs';
import keys from 'lodash/keys';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import * as numeral from 'numeral';
import { BehaviorSubject, fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { elementResize$, generateAlphanumeric, isSet } from '@shared';
// TODO: Refactor import
import { getColorHex, getColorHexStr, parseColor } from '../../../colors/utils/colors';
import { CHART_COLORS } from '../../data/chart-colors';
import { DataGroup } from '../../data/data-group';
import { datasetGroupDateLookups } from '../../data/dataset';
import { DataTooltipController } from '../../services/data-tooltip-controller/data-tooltip.controller';
import { fitXAxisLabelWithVisibility, getYAxisWidth } from '../../utils/d3';
import { applyDatasetsDefaultColors, getDatasetsGroupLookup, getDatasetsGroups, getDatasetsUniqueGroups, prepareDataset } from '../../utils/dataset';
import { getDateFormatByLookup } from '../../utils/date';
var ScatterChart2Component = /** @class */ (function () {
    function ScatterChart2Component(el, overlay, dataTooltip, cd) {
        this.el = el;
        this.overlay = overlay;
        this.dataTooltip = dataTooltip;
        this.cd = cd;
        this.datasets = [];
        this.bubbles = false;
        this.animate = true;
        this.xAxisVisible = true;
        this.yAxisVisible = true;
        this.legend = true;
        this.interactive = true;
        this.tooltips = true;
        this.dataClickEnabled = false;
        this.dataClick = new EventEmitter();
        this.data = [];
        this.dataGroups = [];
        this.dataTotal = [];
        this.margin = { top: 8, right: 8, bottom: 8, left: 8 };
        this.hoverDatasetGroup$ = new BehaviorSubject(undefined);
        this.hoverLegendDatasetIndex$ = new BehaviorSubject(undefined);
        this.colors = CHART_COLORS;
        this.uid = generateAlphanumeric(8);
    }
    Object.defineProperty(ScatterChart2Component.prototype, "xScale", {
        get: function () {
            return this.xScaleTime || this.xScaleLinear;
        },
        enumerable: true,
        configurable: true
    });
    ScatterChart2Component.prototype.getId = function (name) {
        return name + "-" + this.uid;
    };
    ScatterChart2Component.prototype.ngOnInit = function () { };
    ScatterChart2Component.prototype.ngOnDestroy = function () { };
    ScatterChart2Component.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.datasets) {
            this.data = this.datasets
                .map(function (dataset) { return prepareDataset(dataset); })
                .map(function (dataset) {
                return __assign({}, dataset, { dataset: dataset.dataset.reduce(function (acc, item) {
                        if (_this.bubbles) {
                            var groupNumber = parseFloat(item.group);
                            var group2Number = parseFloat(item.group2);
                            if (!isNaN(groupNumber) && !isNaN(group2Number)) {
                                var result = new DataGroup();
                                result.group = groupNumber;
                                result.group2 = group2Number;
                                result.value = item.value;
                                acc.push(result);
                            }
                        }
                        else {
                            var groupNumber = parseFloat(item.group);
                            if (!isNaN(groupNumber)) {
                                var result = new DataGroup();
                                result.group = groupNumber;
                                result.group2 = item.group2;
                                result.value = item.value;
                                acc.push(result);
                            }
                        }
                        return acc;
                    }, []) });
            });
            this.dataGroupLookup = getDatasetsGroupLookup(this.data);
            this.dataGroups = getDatasetsUniqueGroups(this.data);
            applyDatasetsDefaultColors(this.data, this.colors);
            this.dataTotal = getDatasetsGroups(this.data);
        }
        if (this.svg) {
            this.rerender();
        }
    };
    ScatterChart2Component.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.init();
            elementResize$(_this.canvasElement.nativeElement, false)
                .pipe(untilDestroyed(_this))
                .subscribe(function () { return _this.onResize(); });
        }, 0);
    };
    ScatterChart2Component.prototype.init = function () {
        this.initBounds();
        this.initSvg();
        this.initYAxis();
        this.renderYAxis();
        this.fitYAxis();
        this.initXAxis();
        this.renderXAxis();
        this.renderScatter();
        this.renderGradients();
        this.initDatasetHover();
        this.initInteractive();
        this.renderInteractive();
        this.initEvents();
    };
    ScatterChart2Component.prototype.initBounds = function () {
        var width = this.canvasElement.nativeElement.offsetWidth;
        var height = this.canvasElement.nativeElement.offsetHeight;
        this.margin = { top: 8, right: 8, bottom: this.xAxisVisible ? 22 : 8, left: this.yAxisVisible ? 40 : 8 };
        this.width = width - this.margin.left - this.margin.right;
        this.height = height - this.margin.top - this.margin.bottom;
    };
    ScatterChart2Component.prototype.initSvg = function () {
        this.svg = select(this.svgElement.nativeElement)
            .attr('width', '100%')
            .attr('height', '100%')
            .append('g')
            .attr('transform', "translate(" + this.margin.left + "," + this.margin.top + ")");
    };
    ScatterChart2Component.prototype.initXAxis = function () {
        this.xAxis = this.svg.append('g').attr('class', 'chart-axis chart-axis_x');
    };
    ScatterChart2Component.prototype.renderXAxis = function () {
        if (datasetGroupDateLookups.includes(this.dataGroupLookup)) {
            this.xScaleTime = scaleTime()
                .domain(extent(this.dataGroups, function (d) { return d.valueOf(); }))
                .range([0, this.width]);
        }
        else {
            this.xScaleLinear = scaleLinear()
                .domain(extent(this.dataGroups, function (d) { return d; }))
                .range([0, this.width]);
        }
        if (!this.xAxisVisible) {
            this.xAxis.selectChildren().remove();
            return;
        }
        var ticks = Math.floor(this.width / 80);
        var axisGenerator = axisBottom(this.xScale).ticks(ticks).tickSize(-this.height).tickPadding(10);
        var axis = this.xAxis.attr('transform', "translate(0," + this.height + ")").call(axisGenerator);
        this.setAxisClasses(axis);
        fitXAxisLabelWithVisibility(axis, this.el.nativeElement);
    };
    ScatterChart2Component.prototype.initYAxis = function () {
        this.yAxis = this.svg.append('g').attr('class', 'chart-axis chart-axis_y');
    };
    ScatterChart2Component.prototype.renderYAxis = function () {
        var _this = this;
        var domain = this.bubbles
            ? extent(this.dataTotal, function (d) { return d.item.group2; })
            : extent(this.dataTotal, function (d) { return d.item.value; });
        var domainExpand = 0.05 * Math.abs(domain[1] - domain[0]);
        if (domain[0] !== 0) {
            domain[0] -= domainExpand;
        }
        domain[1] += domainExpand;
        if (isSet(this.min)) {
            domain[0] = this.min;
        }
        if (isSet(this.max)) {
            domain[1] = this.max;
        }
        this.yScale = scaleLinear().domain(domain).range([this.height, 0]);
        if (!this.yAxisVisible) {
            this.yAxis.selectChildren().remove();
            return;
        }
        var ticks = Math.floor(this.height / 50);
        var axisGenerator = axisLeft(this.yScale)
            .ticks(ticks)
            .tickSize(-this.width)
            .tickPadding(10)
            .tickFormat(function (value) {
            if (!isSet(_this.yFormat)) {
                return value;
            }
            return numeral(value).format(_this.yFormat);
        });
        var axis = this.yAxis.call(axisGenerator);
        this.setAxisClasses(axis);
    };
    ScatterChart2Component.prototype.setAxisClasses = function (axis) {
        axis.selectAll('.domain').attr('class', 'chart-axis-domain domain');
        axis.selectAll('.tick').attr('class', 'chart-axis-tick-group tick');
        axis.selectAll('.chart-axis-tick-group line').attr('class', 'chart-axis-tick');
        axis.selectAll('.chart-axis-tick-group text').attr('class', 'chart-axis-label');
    };
    ScatterChart2Component.prototype.fitYAxis = function () {
        var width = this.yAxis ? getYAxisWidth(this.yAxis) : 0;
        this.margin.left = width + 10;
        this.width = this.canvasElement.nativeElement.offsetWidth - this.margin.left - this.margin.right;
        this.svg.attr('transform', "translate(" + this.margin.left + "," + this.margin.top + ")");
    };
    ScatterChart2Component.prototype.renderScatter = function () {
        var _this = this;
        if (this.bubbles) {
            var valueNumbers = this.dataTotal.map(function (item) { return item.item.value; });
            var radiusMin_1 = 4;
            var radiusMax = 15;
            var radiusAmp_1 = radiusMax - radiusMin_1;
            var valuesMin_1 = valueNumbers.length ? Math.min.apply(Math, valueNumbers) : 0;
            var valuesMax = valueNumbers.length ? Math.max.apply(Math, valueNumbers) : radiusMax;
            var valuesAmp_1 = valuesMax - valuesMin_1;
            this.svg
                .selectAll('.chart-circles')
                .data(this.data)
                .join('g')
                .attr('class', function (d, i) { return "chart-circles chart-circles_index-" + i; })
                .selectAll('.chart-circle')
                .data(function (d, datasetIndex) {
                return d.dataset.map(function (group) {
                    var x;
                    var y = _this.yScale(group.group2);
                    var r = valuesAmp_1 != 0 ? radiusMin_1 + ((group.value - valuesMin_1) / valuesAmp_1) * radiusAmp_1 : radiusMin_1;
                    if (_this.xScaleTime) {
                        x = _this.xScaleTime(group.group);
                    }
                    else if (_this.xScaleLinear) {
                        x = _this.xScaleLinear(group.group);
                    }
                    return {
                        x: x,
                        y: y,
                        r: r,
                        datasetIndex: datasetIndex
                    };
                });
            })
                .join('circle')
                .attr('class', function (d, i) { return "chart-circle chart-circle_index-" + d.datasetIndex + "-" + i; })
                .attr('fill', function (d) {
                var lineGradient = _this.getId("line-gradient-" + d.datasetIndex);
                return "url(#" + lineGradient + ")";
            })
                .attr('cx', function (d) { return d.x; })
                .attr('cy', function (d) { return d.y; })
                .attr('r', function (d) { return d.r; });
        }
        else {
            this.svg
                .selectAll('.chart-circles')
                .data(this.data)
                .join('g')
                .attr('class', function (d, i) { return "chart-circles chart-circles_index-" + i; })
                .selectAll('.chart-circle')
                .data(function (d, datasetIndex) {
                return d.dataset.map(function (group) {
                    var x;
                    if (_this.xScaleTime) {
                        x = _this.xScaleTime(group.group);
                    }
                    else if (_this.xScaleLinear) {
                        x = _this.xScaleLinear(group.group);
                    }
                    return {
                        x: x,
                        y: _this.yScale(group.value),
                        datasetIndex: datasetIndex
                    };
                });
            })
                .join('circle')
                .attr('class', function (d, i) { return "chart-circle chart-circle_fixed chart-circle_index-" + d.datasetIndex + "-" + i; })
                .attr('r', 5)
                .attr('fill', function (d) {
                var lineGradient = _this.getId("line-gradient-" + d.datasetIndex);
                return "url(#" + lineGradient + ")";
            })
                .attr('cx', function (d) { return d.x; })
                .attr('cy', function (d) { return d.y; });
        }
    };
    ScatterChart2Component.prototype.renderGradients = function () {
        var _this = this;
        this.svg
            .selectAll('.chart-line-gradient')
            .data(this.data.map(function (item, i) { return ({ dataset: item, index: i }); }))
            .join('linearGradient')
            .attr('id', function (d) { return _this.getId("line-gradient-" + d.index); })
            .attr('class', 'chart-line-gradient')
            .attr('gradientUnits', 'userSpaceOnUse')
            .attr('x1', '0%')
            .attr('y1', function (d) {
            var minValue = min(d.dataset.dataset, function (item) { return item.value; });
            return _this.yScale(minValue);
        })
            .attr('x2', '0%')
            .attr('y2', function (d) {
            var maxValue = max(d.dataset.dataset, function (item) { return item.value; });
            return _this.yScale(maxValue);
        })
            .selectAll('stop')
            .data(function (d) {
            var colorHex = getColorHex(_this.data[d.index].color);
            var clr = parseColor(colorHex, '#000');
            return [
                { offset: '0%', color: clr.darken(0.2) },
                { offset: '100%', color: clr.lighten(0.2) }
            ];
        })
            .join('stop')
            .attr('offset', function (d) { return d.offset; })
            .attr('stop-color', function (d) { return d.color; });
    };
    ScatterChart2Component.prototype.initDatasetHover = function () {
        var _this = this;
        this.hoverLegendDatasetIndex$.pipe(untilDestroyed(this)).subscribe(function (hoverIndex) {
            _this.data.forEach(function (item, i) {
                var nodes = _this.svg.selectAll(".chart-circles_index-" + i).nodes();
                if (!isSet(hoverIndex) || i === hoverIndex) {
                    nodes.forEach(function (node) { return node.classList.remove('chart-circles_disabled'); });
                }
                else {
                    nodes.forEach(function (node) { return node.classList.add('chart-circles_disabled'); });
                }
            });
        });
        this.hoverDatasetGroup$.pipe(untilDestroyed(this)).subscribe(function (hoverDatasetGroup) {
            var nodes = _this.svg.selectAll(".chart-circle").nodes();
            var activeClass = hoverDatasetGroup
                ? "chart-circle_index-" + hoverDatasetGroup.datasetIndex + "-" + hoverDatasetGroup.groupIndex
                : undefined;
            nodes.forEach(function (node) {
                if (activeClass && node.classList.contains(activeClass)) {
                    node.classList.add('chart-circle_active');
                    if (!_this.bubbles) {
                        select(node).attr('r', 7);
                    }
                }
                else {
                    node.classList.remove('chart-circle_active');
                    if (!_this.bubbles) {
                        select(node).attr('r', 5);
                    }
                }
                if (!activeClass || node.classList.contains(activeClass)) {
                    node.classList.remove('chart-circle_disabled');
                }
                else {
                    node.classList.add('chart-circle_disabled');
                }
            });
            _this.updateInteractiveClasses(!!hoverDatasetGroup);
        });
    };
    ScatterChart2Component.prototype.initInteractive = function () {
        this.interactiveRect = this.svg.append('rect').attr('class', 'chart-interactive');
    };
    ScatterChart2Component.prototype.renderInteractive = function () {
        this.interactiveRect.attr('width', this.width).attr('height', this.height);
    };
    ScatterChart2Component.prototype.updateInteractiveClasses = function (hoverGroup) {
        if (!this.interactiveRect) {
            return;
        }
        var nodes = this.interactiveRect.nodes();
        var activeClass = 'chart-interactive_clickable';
        if (this.dataClickEnabled && hoverGroup) {
            nodes.forEach(function (node) {
                if (!node.classList.contains(activeClass)) {
                    node.classList.add(activeClass);
                }
            });
        }
        else {
            nodes.forEach(function (node) {
                if (node.classList.contains(activeClass)) {
                    node.classList.remove(activeClass);
                }
            });
        }
    };
    ScatterChart2Component.prototype.initEvents = function () {
        var _this = this;
        merge(fromEvent(this.interactiveRect.node(), 'mouseenter').pipe(map(function (e) { return ({ hover: true, e: e }); })), fromEvent(this.interactiveRect.node(), 'mouseleave').pipe(map(function () { return ({ hover: false, e: undefined }); })))
            .pipe(untilDestroyed(this))
            .subscribe(function (chart) {
            if (chart.hover) {
                _this.onMouseEnter(chart.e);
            }
            else {
                _this.onMouseLeave();
            }
        });
        fromEvent(this.interactiveRect.node(), 'mousemove')
            .pipe(untilDestroyed(this))
            .subscribe(function (e) { return _this.onMouseMove(e); });
        fromEvent(this.interactiveRect.node(), 'click')
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            var hoverDatasetGroup = _this.hoverDatasetGroup$.value;
            if (hoverDatasetGroup) {
                var group = _this.data[hoverDatasetGroup.datasetIndex].dataset[hoverDatasetGroup.groupIndex].group;
                var group2 = _this.data[hoverDatasetGroup.datasetIndex].dataset[hoverDatasetGroup.groupIndex].group2;
                var group3 = _this.data[hoverDatasetGroup.datasetIndex].dataset[hoverDatasetGroup.groupIndex].group3;
                var value = _this.data[hoverDatasetGroup.datasetIndex].dataset[hoverDatasetGroup.groupIndex].value;
                _this.onClick({
                    datasetIndex: hoverDatasetGroup.datasetIndex,
                    groupIndex: hoverDatasetGroup.groupIndex,
                    group: group,
                    group2: group2,
                    group3: group3,
                    value: value
                });
            }
        });
    };
    ScatterChart2Component.prototype.formatNumberValue = function (value, format) {
        format = format || this.yFormat;
        if (!isSet(format)) {
            return String(value);
        }
        return numeral(value).format(format);
    };
    ScatterChart2Component.prototype.formatCoords = function (coords, format) {
        var _this = this;
        var result = coords.map(function (item) { return _this.formatNumberValue(item, format); }).join(', ');
        return "(" + result + ")";
    };
    ScatterChart2Component.prototype.showDatasetTooltip = function (closestDataset, reuse) {
        if (reuse === void 0) { reuse = false; }
        var group;
        if (closestDataset.item.group instanceof moment) {
            var format = getDateFormatByLookup(this.dataGroupLookup) || 'lll';
            group = closestDataset.item.group.format(format);
        }
        else {
            group = String(closestDataset.item.group);
        }
        var defaultLabel = this.data.length > 1 ? "Dataset " + (closestDataset.datasetIndex + 1) : undefined;
        this.dataTooltip.show({
            datasets: [
                {
                    value: this.bubbles
                        ? this.formatCoords([group, closestDataset.item.group2, closestDataset.item.value], closestDataset.dataset.format)
                        : this.formatCoords([group, closestDataset.item.value], closestDataset.dataset.format),
                    valueFormat: closestDataset.dataset.format,
                    label: isSet(closestDataset.dataset.name) ? closestDataset.dataset.name : defaultLabel,
                    color: closestDataset.dataset.color
                }
            ],
            valueFormat: this.yFormat,
            colorCircle: true,
            x: this.margin.left + closestDataset.x,
            y: this.margin.top + closestDataset.y,
            portalOutlet: this.portalOutlet,
            reuse: reuse
        });
    };
    ScatterChart2Component.prototype.onMouseEnter = function (e) {
        if (!this.interactive) {
            return;
        }
        this.dataTooltip.close();
        var closestDataset = this.getClosestDataset(e);
        if (closestDataset) {
            this.circleHover = this.svg
                .insert('circle', '.chart-circles')
                .attr('class', 'chart-circle-hover')
                .attr('r', 12)
                .attr('cx', closestDataset.x)
                .attr('cy', closestDataset.y);
            this.hoverDatasetGroup$.next({ datasetIndex: closestDataset.datasetIndex, groupIndex: closestDataset.itemIndex });
            if (this.tooltips) {
                this.showDatasetTooltip(closestDataset);
            }
        }
    };
    ScatterChart2Component.prototype.getClosestDataset = function (e) {
        var _this = this;
        var _a = pointer(e), pointerX = _a[0], pointerY = _a[1];
        var hoverRange = this.bubbles ? 15 : 10;
        var dataTotal = this.dataTotal
            .filter(function (item) { return !_this.selectedDatasetIndexes || _this.selectedDatasetIndexes[item.datasetIndex]; })
            .map(function (item) {
            var distance = 0;
            var itemY = _this.bubbles ? _this.yScale(item.item.group2) : _this.yScale(item.item.value);
            if (_this.xScaleTime) {
                var itemX = _this.xScaleTime(item.item.group);
                distance = Math.hypot(itemX - pointerX, itemY - pointerY);
            }
            else if (_this.xScaleLinear) {
                var itemX = _this.xScaleLinear(item.item.group);
                distance = Math.hypot(itemX - pointerX, itemY - pointerY);
            }
            return __assign({}, item, { distance: distance });
        })
            .filter(function (item) { return item.distance <= hoverRange; });
        var dataClosest = least(dataTotal, function (item) { return item.distance; });
        if (!dataClosest) {
            return;
        }
        var x;
        var y = this.bubbles ? this.yScale(dataClosest.item.group2) : this.yScale(dataClosest.item.value);
        if (this.xScaleTime) {
            x = this.xScaleTime(dataClosest.item.group);
        }
        else if (this.xScaleLinear) {
            x = this.xScaleLinear(dataClosest.item.group);
        }
        return __assign({}, dataClosest, { x: x, y: y });
    };
    ScatterChart2Component.prototype.onMouseMove = function (e) {
        if (!this.interactive) {
            return;
        }
        var closestDataset = this.getClosestDataset(e);
        if (closestDataset) {
            if (this.circleHover) {
                this.circleHover.attr('cx', closestDataset.x).attr('cy', closestDataset.y);
            }
            else {
                this.circleHover = this.svg
                    .insert('circle', '.chart-circles')
                    .attr('class', 'chart-circle-hover')
                    .attr('r', 12)
                    .attr('cx', closestDataset.x)
                    .attr('cy', closestDataset.y);
            }
            this.hoverDatasetGroup$.next({ datasetIndex: closestDataset.datasetIndex, groupIndex: closestDataset.itemIndex });
            if (this.tooltips) {
                this.showDatasetTooltip(closestDataset, true);
            }
        }
        else {
            this.dataTooltip.close();
            this.hoverDatasetGroup$.next(undefined);
            if (this.circleHover) {
                this.circleHover.remove();
                this.circleHover = undefined;
            }
        }
    };
    ScatterChart2Component.prototype.onMouseLeave = function () {
        if (!this.interactive) {
            return;
        }
        if (this.circleHover) {
            this.circleHover.remove();
            this.circleHover = undefined;
        }
        this.hoverDatasetGroup$.next(undefined);
        this.dataTooltip.close();
    };
    ScatterChart2Component.prototype.onClick = function (options) {
        if (!this.dataClickEnabled) {
            return;
        }
        this.dataClick.emit(options);
    };
    Object.defineProperty(ScatterChart2Component.prototype, "selectedDatasetCount", {
        get: function () {
            return this.selectedDatasetIndexes ? keys(this.selectedDatasetIndexes).length : 0;
        },
        enumerable: true,
        configurable: true
    });
    ScatterChart2Component.prototype.toggleSelectedDatasetIndex = function (index) {
        var _this = this;
        if (!this.interactive) {
            return;
        }
        if (!this.selectedDatasetIndexes) {
            this.selectedDatasetIndexes = fromPairs(this.data.map(function (item, i) { return [i, true]; }).filter(function (_a) {
                var i = _a[0];
                return i !== index;
            }));
        }
        else if (this.selectedDatasetIndexes[index]) {
            delete this.selectedDatasetIndexes[index];
        }
        else {
            this.selectedDatasetIndexes[index] = true;
            if (this.selectedDatasetCount === this.data.length) {
                this.selectedDatasetIndexes = undefined;
            }
        }
        this.cd.markForCheck();
        this.data.forEach(function (item, i) {
            var nodes = _this.svg.selectAll(".chart-circles_index-" + i).nodes();
            if (!_this.selectedDatasetIndexes || _this.selectedDatasetIndexes[i]) {
                nodes.forEach(function (node) { return node.classList.remove('chart-circles_hidden'); });
            }
            else {
                nodes.forEach(function (node) { return node.classList.add('chart-circles_hidden'); });
            }
        });
    };
    ScatterChart2Component.prototype.onLegendDatasetMouseEnter = function (index) {
        if (!this.interactive) {
            return;
        }
        this.hoverLegendDatasetIndex$.next(index);
    };
    ScatterChart2Component.prototype.onLegendDatasetMouseLeave = function () {
        if (!this.interactive) {
            return;
        }
        this.hoverLegendDatasetIndex$.next(undefined);
    };
    ScatterChart2Component.prototype.rerender = function () {
        this.initBounds();
        this.renderYAxis();
        this.fitYAxis();
        this.renderXAxis();
        this.renderScatter();
        this.renderGradients();
        this.renderInteractive();
    };
    ScatterChart2Component.prototype.onResize = function () {
        this.rerender();
    };
    ScatterChart2Component.prototype.colorDisplay = function (value) {
        return getColorHexStr(value);
    };
    return ScatterChart2Component;
}());
export { ScatterChart2Component };
