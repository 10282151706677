import pickBy from 'lodash/pickBy';

import { ActionItem } from '@modules/actions';
import { ListModelDescriptionDataSource } from '@modules/data-sources';
import { Input, ParameterField } from '@modules/fields';
import { ListLayoutType } from '@modules/layouts';
import { ListModelDescriptionQuery, ModelDescriptionQuery } from '@modules/queries';
import { ViewMapping } from '@modules/views';
import { isSet } from '@shared';

import { migrateModelDescriptionDataSource } from '../utils/migration';
import { FieldName } from './field-name';
import { ListLayoutSettings } from './list-layout-settings';
import { ViewSettingsAction } from './view-settings-action';

export class CalendarSettings implements ListLayoutSettings {
  type = ListLayoutType.Calendar;
  header = true;
  titleInput: Input;
  dataSource: ListModelDescriptionDataSource;
  cardCustomView: string;
  cardCustomViewMappings: ViewMapping[] = [];
  actions: ViewSettingsAction[] = [];
  cardClickAction: ActionItem;
  modelActions: ViewSettingsAction[] = [];
  customActions: { name: string; action: ActionItem }[] = [];
  searchEnabled = true;
  searchResource: string;
  searchQuery: ListModelDescriptionQuery;
  searchLive = true;
  searchParameters: ParameterField[] = [];
  searchInputs: Input[] = [];
  groupResource: string;
  groupQuery: ModelDescriptionQuery;
  sortingField: string;
  sortingAsc = true;
  displayFilters = true;
  filterFields: FieldName[] = [];
  dateField: string;
  multipleSelection = false;
  tooltip: string;

  deserialize(data: Object): CalendarSettings {
    this.cardCustomView = data['card_custom_view'];
    this.searchEnabled = data['search_enabled'];
    this.searchResource = data['search_resource'];
    this.groupResource = data['group_resource'];
    this.sortingField = data['sorting_field'];
    this.dateField = data['date_field'];
    this.tooltip = data['tooltip'];

    if (data['title_input']) {
      this.titleInput = new Input().deserialize(data['title_input']);
    } else if (data['title']) {
      // Backward compatibility
      this.titleInput = new Input().deserializeFromStatic('value', data['title']);
    }

    if (data['data_source']) {
      this.dataSource = new ListModelDescriptionDataSource().deserialize(data['data_source']);
    } else if (data['resource']) {
      // Backward compatibility
      this.dataSource = migrateModelDescriptionDataSource(ListModelDescriptionDataSource, ListModelDescriptionQuery, {
        resource: data['resource'],
        query: data['get_query'],
        parameters: data['parameters'],
        inputs: data['inputs'],
        columns: data['columns']
      });
    }

    if (data['card_custom_view_mappings']) {
      this.cardCustomViewMappings = data['card_custom_view_mappings'].map(item => new ViewMapping().deserialize(item));
    }

    if (data['display_filters'] !== undefined) {
      this.displayFilters = data['display_filters'];
    }

    if (data['filter_fields']) {
      this.filterFields = data['filter_fields'].map(item => {
        return {
          name: item['name']
        };
      });
    }

    if (data['header'] != undefined) {
      this.header = data['header'];
    }

    if (data['search_query']) {
      this.searchQuery = new ListModelDescriptionQuery().deserialize(data['search_query']);
    }

    if (data['search_live'] !== undefined) {
      this.searchLive = data['search_live'];
    }

    if (data['search_parameters']) {
      this.searchParameters = data['search_parameters'].map(item => new ParameterField().deserialize(item));
    }

    if (data['search_inputs']) {
      this.searchInputs = data['search_inputs'].map(item => new Input().deserialize(item));
    }

    if (data['group_query']) {
      this.groupQuery = new ModelDescriptionQuery().deserialize(data['group_query']);
    }

    if (data['card_click_action']) {
      this.cardClickAction = new ActionItem().deserialize(data['card_click_action']);
    }

    if (data['actions']) {
      this.actions = data['actions'].map(item => new ViewSettingsAction().deserialize(item));
    }

    if (data['model_actions']) {
      this.modelActions = data['model_actions'].map(item => new ViewSettingsAction().deserialize(item));
    }

    if (data['custom_actions']) {
      this.customActions = data['custom_actions'].map(item => ({
        name: item['name'],
        action: new ActionItem().deserialize(item['action'])
      }));
    }

    if (data['sorting_asc'] != undefined) {
      this.sortingAsc = data['sorting_asc'];
    }

    if (isSet(data['multiple_selection'])) {
      this.multipleSelection = data['multiple_selection'];
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      type: this.type,
      header: this.header,
      title_input: this.titleInput ? this.titleInput.serialize() : null,
      data_source: this.dataSource ? this.dataSource.serialize() : undefined,
      card_custom_view: this.cardCustomView,
      card_custom_view_mappings: this.cardCustomViewMappings.map(item => item.serialize()),
      search_enabled: this.searchEnabled,
      search_resource: this.searchResource,
      search_query: this.searchQuery ? this.searchQuery.serialize() : undefined,
      search_live: this.searchLive,
      search_parameters: this.searchParameters.map(item => item.serialize()),
      search_inputs: this.searchInputs.map(item => item.serialize()),
      group_resource: this.groupResource,
      group_query: this.groupQuery ? this.groupQuery.serialize() : undefined,
      card_click_action: this.cardClickAction ? this.cardClickAction.serialize() : undefined,
      date_field: this.dateField,
      actions: this.actions.map(item => item.serialize()),
      model_actions: this.modelActions.map(item => item.serialize()),
      custom_actions: this.customActions.map(item => ({ name: item.name, action: item.action.serialize() })),
      sorting_field: this.sortingField,
      sorting_asc: this.sortingAsc,
      display_filters: this.displayFilters,
      filter_fields: this.filterFields.map(item => {
        return {
          name: item.name
        };
      }),
      multiple_selection: this.multipleSelection,
      tooltip: this.tooltip
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  isConfigured() {
    return this.dateField != undefined;
  }

  get analyticsName(): string {
    return 'calendar';
  }

  get analyticsGenericName(): string {
    if (!this.analyticsName) {
      return;
    }
    return ['component', this.analyticsName].join('_');
  }
}
