import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import {
  FieldType,
  formatDateTimeField,
  getDateTimeFieldMomentFormat,
  isDateTimeFieldClock12Format,
  registerFieldComponent
} from '@modules/fields';

import { FieldComponent } from '../field/field.component';

@Component({
  selector: 'app-date-time-field',
  templateUrl: 'date-time-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateTimeFieldComponent extends FieldComponent implements OnInit {
  time: boolean;
  editFormat: string;
  editFormatClock12 = false;

  ngOnInit(): void {
    this.time = !!this.field.params['time'];
    this.editFormat = getDateTimeFieldMomentFormat(this.field, true);
    this.editFormatClock12 = isDateTimeFieldClock12Format(this.field, true);
  }

  // TODO: Move to separate params option
  get inputSmall() {
    return this.field.params['classes'] && this.field.params['classes'].indexOf('input_small') != -1;
  }

  get valueStr() {
    if (!this.currentValue) {
      return this.formatEmpty(this.currentValue);
    }

    return formatDateTimeField(this.currentValue, this.field);
  }
}

registerFieldComponent(FieldType.DateTime, DateTimeFieldComponent);
