import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material';

import { FormUtilsModule } from '@common/form-utils';
import { TipsModule } from '@common/tips';
import { ColorsSharedModule } from '@modules/colors-shared';
import { RoutingModule } from '@modules/routing';
import { TutorialModule } from '@modules/tutorial';
import { SharedModule } from '@shared';

import { AlertComponent } from './components/alert/alert.component';
import { BackgroundLayoutComponent } from './components/background-layout/background-layout.component';
import { BackgroundTabComponent } from './components/background-tab/background-tab.component';
import { BackgroundTabsComponent } from './components/background-tabs/background-tabs.component';
import { BrowserHeaderComponent } from './components/browser-header/browser-header.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { FormLayoutComponent } from './components/form-layout/form-layout.component';
import { HelpIconComponent } from './components/help-icon/help-icon.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { LayoutComponent } from './components/layout/layout.component';
import { PageBlockComponent } from './components/page-block/page-block.component';
import { PageCardComponent } from './components/page-card/page-card.component';
import { PageColumnComponent } from './components/page-column/page-column.component';
import { PageColumnsComponent } from './components/page-columns/page-columns.component';
import { PageDescriptionComponent } from './components/page-description/page-description.component';
import { PageHeaderAdditionalComponent } from './components/page-header-additional/page-header-additional.component';
import { PageHeaderRightComponent } from './components/page-header-right/page-header-right.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PageHeadingComponent } from './components/page-heading/page-heading.component';
import { PageIconComponent } from './components/page-icon/page-icon.component';
import { PageLinkComponent } from './components/page-link/page-link.component';
import { PageLogoComponent } from './components/page-logo/page-logo.component';
import { PageSeparatorComponent } from './components/page-separator/page-separator.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { PageComponent } from './components/page/page.component';
import { PasswordControlComponent } from './components/password-control/password-control.component';
import { Popup2CloseComponent } from './components/popup2-close/popup2-close.component';
import { Popup2DescriptionComponent } from './components/popup2-description/popup2-description.component';
import { Popup2FooterComponent } from './components/popup2-footer/popup2-footer.component';
import { Popup2HeaderComponent } from './components/popup2-header/popup2-header.component';
import { Popup2SideComponent } from './components/popup2-side/popup2-side.component';
import { Popup2TitleComponent } from './components/popup2-title/popup2-title.component';
import { Popup2Component } from './components/popup2/popup2.component';
import { PosterLayoutComponent } from './components/poster-layout/poster-layout.component';
import { ProjectLogoComponent } from './components/project-logo/project-logo.component';
import { SectionColumnComponent } from './components/section-column/section-column.component';
import { SectionFooterComponent } from './components/section-footer/section-footer.component';
import { SectionTabComponent } from './components/section-tab/section-tab.component';
import { SectionTabsComponent } from './components/section-tabs/section-tabs.component';
import { SectionToolbarComponent } from './components/section-toolbar/section-toolbar.component';
import { SectionComponent } from './components/section/section.component';
import { SliderComponent } from './components/slider/slider.component';
import { StackComponent } from './components/stack/stack.component';
import { StarComponent } from './components/star/star.component';
import { TextControlComponent } from './components/text-control/text-control.component';
import { PageBlockDefaultsDirective } from './directives/page-block-defaults/page-block-defaults.directive';

@NgModule({
  imports: [
    CommonModule,
    RoutingModule,
    TutorialModule,
    TipsModule,
    ReactiveFormsModule,
    SharedModule,
    FormUtilsModule,
    MatMenuModule,
    ColorsSharedModule
  ],
  declarations: [
    AlertComponent,
    SectionComponent,
    PageComponent,
    PageTitleComponent,
    PageDescriptionComponent,
    PageHeadingComponent,
    PageBlockComponent,
    PageHeaderComponent,
    PageHeaderRightComponent,
    PageHeaderAdditionalComponent,
    PageLinkComponent,
    SectionTabsComponent,
    SectionTabComponent,
    SectionColumnComponent,
    SectionFooterComponent,
    SectionToolbarComponent,
    PageBlockDefaultsDirective,
    Popup2Component,
    Popup2CloseComponent,
    Popup2HeaderComponent,
    Popup2TitleComponent,
    Popup2DescriptionComponent,
    Popup2FooterComponent,
    StackComponent,
    SliderComponent,
    BackgroundTabsComponent,
    BackgroundTabComponent,
    HelpIconComponent,
    PageCardComponent,
    PageSeparatorComponent,
    PageColumnsComponent,
    PageColumnComponent,
    PageLogoComponent,
    TextControlComponent,
    PasswordControlComponent,
    LayoutComponent,
    PosterLayoutComponent,
    FormLayoutComponent,
    BackgroundLayoutComponent,
    StarComponent,
    BrowserHeaderComponent,
    LanguageSelectorComponent,
    PageIconComponent,
    ProjectLogoComponent,
    CheckboxComponent,
    Popup2SideComponent
  ],
  exports: [
    AlertComponent,
    SectionComponent,
    PageComponent,
    PageTitleComponent,
    PageDescriptionComponent,
    PageHeadingComponent,
    PageBlockComponent,
    PageHeaderComponent,
    PageHeaderRightComponent,
    PageHeaderAdditionalComponent,
    PageLinkComponent,
    SectionTabsComponent,
    SectionTabComponent,
    SectionColumnComponent,
    SectionFooterComponent,
    SectionToolbarComponent,
    PageBlockDefaultsDirective,
    Popup2Component,
    Popup2CloseComponent,
    Popup2HeaderComponent,
    Popup2TitleComponent,
    Popup2DescriptionComponent,
    Popup2FooterComponent,
    StackComponent,
    SliderComponent,
    BackgroundTabsComponent,
    BackgroundTabComponent,
    HelpIconComponent,
    PageCardComponent,
    PageSeparatorComponent,
    PageColumnsComponent,
    PageColumnComponent,
    PageLogoComponent,
    TextControlComponent,
    PasswordControlComponent,
    LayoutComponent,
    PosterLayoutComponent,
    FormLayoutComponent,
    BackgroundLayoutComponent,
    StarComponent,
    BrowserHeaderComponent,
    LanguageSelectorComponent,
    PageIconComponent,
    ProjectLogoComponent,
    CheckboxComponent,
    Popup2SideComponent
  ]
})
export class UiModule {}
