var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import range from 'lodash/range';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { TintStyle } from '@modules/customize';
import { createFormFieldFactory, JsonStructureNodeType } from '@modules/fields';
import { controlValue } from '@shared';
import { createJsonStructureNodeControl } from '../../../utils/json-structure';
var JsonFieldNodeComponent = /** @class */ (function () {
    function JsonFieldNodeComponent(cd) {
        this.cd = cd;
        this.editable = true;
        this.createField = createFormFieldFactory();
        this.tintStyles = TintStyle;
    }
    JsonFieldNodeComponent.prototype.trackItemFn = function (i, item) {
        return item.name;
    };
    JsonFieldNodeComponent.prototype.ngOnInit = function () {
        this.initNode();
    };
    JsonFieldNodeComponent.prototype.ngOnDestroy = function () { };
    JsonFieldNodeComponent.prototype.ngOnChanges = function (changes) {
        if ((changes['node'] && !changes['node'].isFirstChange()) ||
            (changes['form'] && !changes['form'].isFirstChange())) {
            this.initNode();
        }
    };
    JsonFieldNodeComponent.prototype.initNode = function () {
        if (this.updateArrayNodesSubscription) {
            this.updateArrayNodesSubscription.unsubscribe();
            this.updateArrayNodesSubscription = undefined;
        }
        if (!this.node) {
            return;
        }
        if (this.node.type == JsonStructureNodeType.Object) {
            this.objectParams = this.node.params;
        }
        else if (this.node.type == JsonStructureNodeType.Array) {
            this.arrayParams = this.node.params;
            this.updateArrayNodes();
        }
        else if (this.node.type == JsonStructureNodeType.Field) {
            this.fieldParams = __assign({}, this.node.params, { name: this.node.name });
        }
    };
    JsonFieldNodeComponent.prototype.updateArrayNodes = function () {
        var _this = this;
        if (this.updateArrayNodesSubscription) {
            this.updateArrayNodesSubscription.unsubscribe();
            this.updateArrayNodesSubscription = undefined;
        }
        var control = this.form.controls[this.node.name];
        if (!control) {
            this.arrayNodes = [];
            this.cd.markForCheck();
            return;
        }
        this.updateArrayNodesSubscription = controlValue(control)
            .pipe(map(function (value) { return value.length; }), distinctUntilChanged(), untilDestroyed(this))
            .subscribe(function (length) {
            _this.arrayNodes = range(0, length).map(function (i) {
                var item = cloneDeep(_this.arrayParams.item);
                item.name = i;
                return item;
            });
            _this.cd.markForCheck();
        });
    };
    JsonFieldNodeComponent.prototype.add = function () {
        var form = this.form.controls[this.node.name];
        var control = createJsonStructureNodeControl(this.arrayParams.item);
        if (control) {
            form.push(control);
        }
        this.updateArrayNodes();
        this.cd.detectChanges();
    };
    JsonFieldNodeComponent.prototype.remove = function (index) {
        var form = this.form.controls[this.node.name];
        form.removeAt(index);
        this.updateArrayNodes();
        this.cd.detectChanges();
    };
    return JsonFieldNodeComponent;
}());
export { JsonFieldNodeComponent };
