import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { NotificationService } from '@common/notifications';
import { UniversalAnalyticsService } from '@modules/analytics';
import { AuthService } from '@modules/auth';
import { TintStyle } from '@modules/customize';
import { defaultSignUpLayout } from '@modules/domain';
import { createFormFieldFactory } from '@modules/fields';
import { MetaService } from '@modules/meta';
import { RoutingService } from '@modules/routing';
import { CurrentUserStore } from '@modules/users';
import { isSet } from '@shared';
import { RestoreCompleteForm } from './restore-complete.form';
var RestoreCompleteComponent = /** @class */ (function () {
    function RestoreCompleteComponent(form, routing, currentUserStore, activatedRoute, analyticsService, authService, notificationService, metaService, cd) {
        this.form = form;
        this.routing = routing;
        this.currentUserStore = currentUserStore;
        this.activatedRoute = activatedRoute;
        this.analyticsService = analyticsService;
        this.authService = authService;
        this.notificationService = notificationService;
        this.metaService = metaService;
        this.cd = cd;
        this.loading = false;
        this.createField = createFormFieldFactory();
        this.domainLoading = false;
        this.defaultLayout = defaultSignUpLayout;
        this.externalFonts = [];
        this.tintStyles = TintStyle;
    }
    RestoreCompleteComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.metaService.set({ title: 'Complete Restore' });
        this.activatedRoute.params.pipe(untilDestroyed(this)).subscribe(function (params) { return (_this.form.code = params['code']); });
        this.domainLoading = true;
        this.cd.markForCheck();
        this.activatedRoute.data.pipe(untilDestroyed(this)).subscribe(function (data) {
            _this.domain = data.domain;
            _this.externalFonts = _this.getExternalFonts(_this.domain);
            _this.domainLoading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.domainLoading = false;
            _this.cd.markForCheck();
        });
    };
    RestoreCompleteComponent.prototype.ngOnDestroy = function () { };
    RestoreCompleteComponent.prototype.getExternalFonts = function (domain) {
        if (!domain) {
            return [];
        }
        return [domain.signUpFontRegular, domain.signUpFontHeading].filter(function (item) { return isSet(item); });
    };
    RestoreCompleteComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        this.cd.markForCheck();
        this.form
            .submit()
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.routing.navigate(['/projects']);
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    RestoreCompleteComponent.prototype.cleanErrorForm = function () {
        this.form.form.setErrors({});
    };
    Object.defineProperty(RestoreCompleteComponent.prototype, "layout", {
        get: function () {
            return this.domain && isSet(this.domain.signUpLayout) ? this.domain.signUpLayout : this.defaultLayout;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RestoreCompleteComponent.prototype, "isWhiteLabel", {
        get: function () {
            return this.domain && this.domain.whiteLabel;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RestoreCompleteComponent.prototype, "signInLink", {
        get: function () {
            var projectName = this.activatedRoute.snapshot.params['project'];
            var ignoreSelfSignOn = this.activatedRoute.snapshot.data['ignoreSelfSignOn'];
            if (projectName) {
                return ['/login', projectName];
            }
            else if (ignoreSelfSignOn) {
                return ['/user', 'login'];
            }
            else {
                return ['/login'];
            }
        },
        enumerable: true,
        configurable: true
    });
    return RestoreCompleteComponent;
}());
export { RestoreCompleteComponent };
