/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./back-element.component";
import * as i3 from "../../../customize/services/customize/customize.service";
import * as i4 from "../../../action-queries/services/action-controller/action-controller.service";
import * as i5 from "../../../../common/notifications/services/notification/notification.service";
var styles_BackElementComponent = [];
var RenderType_BackElementComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BackElementComponent, data: {} });
export { RenderType_BackElementComponent as RenderType_BackElementComponent };
function View_BackElementComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "a", [["class", "back__link"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "span", [["class", "back__arrow icon-arrow_backward_3"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, [" ", " "])), (_l()(), i0.ɵted(-1, null, [" \u2013 "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.previousPageTitle; _ck(_v, 3, 0, currVal_0); }); }
function View_BackElementComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "h1", [["class", "back"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BackElementComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.backEnabled; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.title; _ck(_v, 5, 0, currVal_1); }); }
export function View_BackElementComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_BackElementComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.visible || i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.customizeService.enabled$))); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BackElementComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-back-element", [], null, null, null, View_BackElementComponent_0, RenderType_BackElementComponent)), i0.ɵdid(1, 770048, null, 0, i2.BackElementComponent, [i3.CustomizeService, i4.ActionControllerService, i5.NotificationService, i0.Injector, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BackElementComponentNgFactory = i0.ɵccf("app-back-element", i2.BackElementComponent, View_BackElementComponent_Host_0, { element: "element", elementActive: "elementActive", context: "context", visible: "visible", actionClicked: "actionClicked", accentColor: "accentColor" }, { updated: "updated", replaceRequested: "replaceRequested", deleteRequested: "deleteRequested" }, []);
export { BackElementComponentNgFactory as BackElementComponentNgFactory };
