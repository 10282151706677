import { Injectable } from '@angular/core';

import { ActionDescription, ActionType, LinkAction, SegueType } from '@modules/actions';
import {
  FieldElementItem,
  FormElementItem,
  FormSubmitElementItem,
  generateElementName,
  SUBMIT_RESULT_OUTPUT,
  VALUE_OUTPUT,
  ViewSettingsAction
} from '@modules/customize';
import { fieldsEditItemFromParameterField } from '@modules/field-components';
import { Input, InputValueType } from '@modules/fields';
import { ModelDescription } from '@modules/models';
import { Resource } from '@modules/projects';
import { Template } from '@modules/template';
import { isSet } from '@shared';

import { PersistentIdGenerator } from '../../utils/elements';
import { GeneratorUtils } from '../generator-utils/generator-utils.service';

@Injectable()
export class CreateFormGenerator {
  constructor(private generatorUtils: GeneratorUtils) {}

  getCreateParameters(modelDescription: ModelDescription) {
    return modelDescription.createParametersOrDefaults;
  }

  getElement(
    resource: Resource,
    modelDescription: ModelDescription,
    options: {
      templates: Template[];
      fields?: string[];
      detailUniqueName?: string;
      listUniqueName?: string;
      idGenerator?: PersistentIdGenerator;
      idUniqueName?: string;
    }
  ): FormElementItem {
    let fieldElements = this.getCreateParameters(modelDescription).map(item => {
      const element = new FieldElementItem();

      element.settings = { field: item.field };
      this.generatorUtils.applyElementTemplate(element, options.templates);

      if (options.idGenerator) {
        element.uid = options.idGenerator
          ? options.idGenerator.elementId(`${options.idUniqueName}_${item.name}`)
          : undefined;
      } else {
        element.generateUid();
      }

      element.settings = {
        ...fieldsEditItemFromParameterField(item),
        verboseName: item.verboseName || item.name,
        editable: true
      };
      element.updateFormField();
      element.name = generateElementName(element, {});

      return element;
    });

    if (options.fields) {
      fieldElements = fieldElements
        .filter(item => options.fields.includes(item.settings.name))
        .sort((lhs, rhs) => this.generatorUtils.fieldsSort(options.fields, lhs.settings.name, rhs.settings.name));
    }

    const formElement = new FormElementItem();

    if (options.idGenerator && isSet(options.idUniqueName)) {
      formElement.uid = options.idGenerator ? options.idGenerator.elementId(options.idUniqueName) : undefined;
    } else {
      formElement.generateUid();
    }

    formElement.generated = true;
    formElement.children = [...fieldElements];

    const autoAction = modelDescription.autoActions().find(item => item.name == 'create');

    if (autoAction) {
      const submitElement = new FormSubmitElementItem();

      if (options.idGenerator) {
        submitElement.uid = options.idGenerator
          ? options.idGenerator.elementId(`${options.idUniqueName}___submit__`)
          : undefined;
      } else {
        submitElement.generateUid();
      }

      submitElement.verboseNameInput = new Input().deserializeFromStatic('value', autoAction.label);

      formElement.children.push(submitElement);

      const action = new ViewSettingsAction();

      action.verboseNameInput = new Input().deserializeFromStatic('value', autoAction.label);
      action.sharedActionDescription = [resource.uniqueName, autoAction.uniqueName].join('.');

      action.inputs = modelDescription.createParametersOrDefaults
        .map(item => {
          const input = new Input();

          input.name = item.name;
          input.valueType = InputValueType.Context;

          const fieldElement = fieldElements.find(element => element.settings.name == item.name);

          if (fieldElement) {
            input.contextValue = ['elements', fieldElement.uid, VALUE_OUTPUT];
          }

          return input;
        })
        .filter(item => !options.fields || options.fields.includes(item.name));

      if (options.detailUniqueName) {
        const successLinkAction = new LinkAction();

        successLinkAction.type = SegueType.Page;
        successLinkAction.page = options.detailUniqueName;

        const successActionDescription = new ActionDescription();

        successActionDescription.type = ActionType.Link;
        successActionDescription.linkAction = successLinkAction;

        const input = new Input();

        input.name = 'ID';
        input.valueType = InputValueType.Context;
        input.contextValue = [SUBMIT_RESULT_OUTPUT, modelDescription.primaryKeyField];

        const successAction = new ViewSettingsAction();

        successAction.verboseNameInput = new Input().deserializeFromStatic('value', 'Open Record');
        successAction.actionDescription = successActionDescription;
        successAction.inputs = [input];

        action.onSuccessActions = [successAction];
      }

      formElement.submitAction = action;
    }

    return formElement;
  }
}
