import pickBy from 'lodash/pickBy';

import { ActionItem } from '@modules/actions';
import { Input } from '@modules/fields';

export enum TintStyle {
  Default = 'default',
  Primary = 'primary',
  Transparent = 'transparent'
}

export class ViewSettingsAction extends ActionItem {
  visible = true;
  pinned = false;
  style: TintStyle = TintStyle.Primary;
  tint: string;
  icon: string;
  order: number;
  disabledInput: Input;
  visibleInput: Input;

  deserialize(data: Object) {
    super.deserialize(data);
    this.icon = data['icon'];
    this.order = data['order'];

    if (data['style'] != undefined) {
      this.style = data['style'];
    }

    if (this.style == TintStyle.Default && data['color_text']) {
      // Backward compatibility
      this.tint = data['color_text'];
    } else if (this.style == TintStyle.Primary && data['color']) {
      // Backward compatibility
      this.tint = data['color'];
    } else if (this.style == TintStyle.Transparent && data['color_text']) {
      // Backward compatibility
      this.tint = data['color_text'];
    } else {
      this.tint = data['tint'];
    }

    if (data['visible'] != undefined) {
      this.visible = data['visible'];
    }

    if (data['pinned'] != undefined) {
      this.pinned = data['pinned'];
    }

    if (data['disabled_input']) {
      this.disabledInput = new Input().deserialize(data['disabled_input']);
    }

    if (data['visible_input']) {
      this.visibleInput = new Input().deserialize(data['visible_input']);
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      ...super.serialize(fields),
      style: this.style,
      visible: this.visible,
      pinned: this.pinned,
      tint: this.tint,
      icon: this.icon,
      order: this.order,
      disabled_input: this.disabledInput ? this.disabledInput.serialize() : null,
      visible_input: this.visibleInput ? this.visibleInput.serialize() : null
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }
}
