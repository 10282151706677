import { Injectable, OnDestroy } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, merge, Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { ApiService } from '@modules/api';
import { DomainStore } from '@modules/domain';
import { FieldType, getFieldDescriptionByType, ParameterArray } from '@modules/fields';
import { ProjectApiService } from '@modules/project-api';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { Query, QueryPagination } from '@modules/queries';
import { strictParamsTokens } from '@modules/queries-tokens';
import { SSOType } from '@modules/sso';
import { Token } from '@modules/tokens';
import { CurrentUserStore } from '@modules/users';
import { controlValue, EMPTY, isSet, objectGet, objectSet, splitmax } from '@shared';

import { PopupToken } from '../components/input-tokens/input-tokens.component';
import { QueryBuilderHttpForm } from '../components/query-builder-http/query-builder-http.form';
import { QueryBuilderSqlForm } from '../components/query-builder-sql/query-builder-sql.form';
import { QueryBuilderForm } from '../components/query-builder/query-builder.form';

const pagePaginationTokens: PopupToken[] = [
  {
    name: ['paging', 'page'].join('.'),
    field: FieldType.Number,
    label: 'page number',
    icon: 'number',
    defaultValue: 1,
    reference: QueryPagination.Page
  },
  {
    name: ['paging', 'limit'].join('.'),
    field: FieldType.Number,
    label: 'per page limit',
    icon: 'number',
    defaultValue: 20,
    reference: QueryPagination.Page
  }
];

const offsetPaginationTokens: PopupToken[] = [
  {
    name: ['paging', 'offset'].join('.'),
    field: FieldType.Number,
    label: 'pagination offset',
    icon: 'number',
    defaultValue: 0,
    reference: QueryPagination.Offset
  },
  {
    name: ['paging', 'limit'].join('.'),
    field: FieldType.Number,
    label: 'per page limit',
    icon: 'number',
    defaultValue: 20,
    reference: QueryPagination.Offset
  }
];

const cursorPaginationTokens: PopupToken[] = [
  {
    name: ['paging', 'cursor_next'].join('.'),
    label: 'cursor next',
    icon: 'number',
    reference: QueryPagination.Cursor
  },
  {
    name: ['paging', 'cursor_prev'].join('.'),
    label: 'cursor previous',
    icon: 'number',
    reference: QueryPagination.Cursor
  },
  {
    name: ['paging', 'limit'].join('.'),
    field: FieldType.Number,
    label: 'per page limit',
    icon: 'number',
    defaultValue: 20,
    reference: QueryPagination.Cursor
  }
];

const sortingTokens: PopupToken[] = [
  {
    name: 'sorting.field',
    field: FieldType.Text,
    label: 'sorting field',
    icon: 'text'
  },
  {
    name: 'sorting.asc',
    field: FieldType.Boolean,
    label: 'is sorting ascending',
    icon: 'filter_down'
  }
  // {
  //   name: 'sorting.desc',
  //   field: FieldType.Boolean,
  //   label: 'sorting descending',
  //   icon: 'filter_up'
  // }
];

@Injectable()
export class QueryBuilderContext implements OnDestroy {
  public form: QueryBuilderForm;
  public httpForm: QueryBuilderHttpForm;
  public sqlForm: QueryBuilderSqlForm;
  public parametersControl: ParameterArray;
  public paginationTokens = false;
  public sortingTokens = false;
  public currentUserTokens = true;
  public useFileObjects = false;

  _tokens = new BehaviorSubject<Token[]>([]);
  _systemTokens = new BehaviorSubject<Token[]>([]);
  _tokenValues = new BehaviorSubject<Object>({});
  _lastExecutedQuery = new BehaviorSubject<Query>(undefined);
  _lastExecutedResponse = new BehaviorSubject<any>(undefined);
  inputs = {};
  _state = new BehaviorSubject<Object>({});

  constructor(
    private currentUserStore: CurrentUserStore,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private domainStore: DomainStore,
    private apiService: ApiService,
    private projectApiService: ProjectApiService
  ) {
    this.updateSystemTokens();
  }

  ngOnDestroy(): void {}

  init(form: QueryBuilderForm, currentUserTokens: boolean) {
    this.form = form;
    this.currentUserTokens = currentUserTokens;

    if (this.form) {
      combineLatest(
        controlValue(this.form.controls.pagination),
        controlValue(this.form.controls.sorting),
        this.currentUserStore.get(),
        this.domainStore.get(),
        this.currentProjectStore.instance$
      )
        .pipe(delay(0), untilDestroyed(this))
        .subscribe(() => {
          this.updateSystemTokens();
        });
    }
  }

  get tokens(): Token[] {
    return this._tokens.value;
  }

  get tokens$(): Observable<Token[]> {
    return this._tokens.asObservable();
  }

  set tokens(value: Token[]) {
    this._tokens.next(value);
  }

  get systemTokens(): Token[] {
    return this._systemTokens.value;
  }

  get systemTokens$(): Observable<Token[]> {
    return this._systemTokens.asObservable();
  }

  set systemTokens(value: Token[]) {
    this._systemTokens.next(value);
  }

  get tokenValues(): Object {
    return this._tokenValues.value;
  }

  get tokenValues$(): Observable<Object> {
    return this._tokenValues.asObservable();
  }

  set tokenValues(value: Object) {
    this._tokenValues.next(value);
  }

  get lastExecutedQuery() {
    return this._lastExecutedQuery.value;
  }

  get lastExecutedQuery$() {
    return this._lastExecutedQuery.asObservable();
  }

  set lastExecutedQuery(value) {
    this._lastExecutedQuery.next(value);
  }

  get lastExecutedResponse() {
    return this._lastExecutedResponse.value;
  }

  get lastExecutedResponse$() {
    return this._lastExecutedResponse.asObservable();
  }

  set lastExecutedResponse(value) {
    this._lastExecutedResponse.next(value);
  }

  get state() {
    return this._state.value;
  }

  get state$() {
    return this._state.asObservable();
  }

  set state(value) {
    this._state.next(value);
  }

  setState(state: Object) {
    this._state.next({ ...this._state.value, ...state });
  }

  getPaginationTokens() {
    if (!this.form) {
      return;
    }

    if (this.form.controls.pagination.value == QueryPagination.Page) {
      return pagePaginationTokens;
    } else if (this.form.controls.pagination.value == QueryPagination.Offset) {
      return offsetPaginationTokens;
    } else if (this.form.controls.pagination.value == QueryPagination.Cursor) {
      return cursorPaginationTokens;
    }
  }

  updateSystemTokens() {
    const result: Token[] = [];
    const paginationTokens = this.getPaginationTokens();
    const user = this.currentUserStore.instance;
    const project = this.currentProjectStore.instance;
    const environment = this.currentEnvironmentStore.instance;
    const domain = this.domainStore.instance;

    if (paginationTokens) {
      result.push({
        name: undefined,
        label: 'Pagination',
        children: paginationTokens
      });
    }

    if (this.form && this.form.controls.sorting.value) {
      result.push({
        name: undefined,
        label: 'Sorting',
        children: sortingTokens
      });
    }

    if (domain) {
      result.push({
        name: undefined,
        label: 'SSO credentials',
        children: domain.ssoSettings
          .map(item => {
            const context = item.getSecretTokenContext();
            if (!context) {
              return;
            }

            return {
              name: ['sso', item.uid.replace(/-/g, ''), context.name].join('.'),
              label: `${item.name} ${context.label}`,
              icon: 'key',
              data: {
                secret: true
              }
            };
          })
          .filter(item => item)
      });
    }

    if (this.currentUserTokens && user && environment) {
      result.push({
        name: undefined,
        label: 'Current User',
        children: [
          {
            name: ['user', 'uid'].join('.'),
            label: 'ID',
            defaultValue: environment.user ? environment.user.uid : undefined,
            resetToDefault: true,
            icon: 'key'
          },
          {
            name: ['user', 'email'].join('.'),
            label: 'email',
            defaultValue: user.email,
            resetToDefault: true,
            icon: 'text'
          },
          {
            name: ['user', 'token'].join('.'),
            label: 'token',
            defaultValue: this.apiService.getAccessToken() ? this.apiService.getAccessToken() : undefined,
            resetToDefault: true,
            icon: 'key'
          },
          {
            name: ['user', 'project_token'].join('.'),
            label: 'project token',
            defaultValue: this.projectApiService.getAccessToken() ? this.projectApiService.getAccessToken() : undefined,
            resetToDefault: true,
            icon: 'key'
          },
          {
            name: ['user', 'properties'].join('.'),
            field: FieldType.JSON,
            label: 'properties',
            defaultValue: environment.user ? environment.user.properties : undefined,
            icon: 'components'
          },
          {
            name: ['user', 'group', 'uid'].join('.'),
            label: 'team ID',
            defaultValue: environment.group ? environment.group.uid : undefined,
            resetToDefault: true,
            icon: 'key'
          },
          {
            name: ['user', 'group', 'properties'].join('.'),
            field: FieldType.JSON,
            label: 'team properties',
            defaultValue: environment.group ? environment.group.properties : undefined,
            icon: 'components'
          },
          {
            name: ['project', 'unique_name'].join('.'),
            label: 'project unique name',
            defaultValue: this.currentProjectStore.instance.uniqueName,
            fixedValue: true,
            icon: 'key'
          },
          {
            name: ['env', 'unique_name'].join('.'),
            label: 'environment unique name',
            defaultValue: this.currentEnvironmentStore.instance.uniqueName,
            fixedValue: true,
            icon: 'key'
          }
        ]
      });
    }

    result.push({
      name: undefined,
      label: 'Helpers',
      children: [
        {
          name: ['jet', 'uuid'].join('.'),
          label: `generate unique UUID`,
          icon: 'key'
        }
      ]
    });

    this._systemTokens.next(result);
  }

  getTokenValues(save?: boolean): Object {
    // TODO: Remove deprecated tokens
    const compatibility = {
      'paging.page': 'page',
      'paging.limit': 'limit',
      'paging.offset': 'offset',
      'paging.cursor_prev': 'cursor_first',
      'paging.cursor_next': 'cursor_last',

      'user.uid': 'user_uid',
      'user.email': 'user_email',
      'user.token': 'user_token',
      'user.properties': 'user_properties',
      'user.group.uid': 'user_group_uid',
      'user.group.properties': 'user_group_properties',

      'sorting.field': 'sorting_field',
      'sorting.asc': 'sorting_asc',
      'sorting.desc': 'sorting_desc'
    };

    const result = {
      params: {}
    };
    const tokens = [...this.tokens, ...this.systemTokens];

    const iterToken = token => {
      if (token.children) {
        token.children.forEach(item => iterToken(item));
      } else if (token.fixedValue || (save && token.resetToDefault)) {
        objectSet(result, token.name, token.value || token.defaultValue);
      } else {
        const fieldDescription = getFieldDescriptionByType(token.field);
        let value = objectGet(this.tokenValues, token.name, token.defaultValue);

        if (value !== EMPTY) {
          if (fieldDescription.deserializeValue) {
            value = fieldDescription.deserializeValue(value);
          }

          objectSet(result, token.name, value);
        } else {
          objectSet(result, token.name, undefined);
        }
      }
    };

    tokens.forEach(item => iterToken(item));

    toPairs(compatibility).forEach(([k, v]) => {
      const value = objectGet(result, k);
      if (value !== EMPTY) {
        result[v] = value;
      }
    });

    // result['params'] = strictParamsTokens(result['params'] ? result['params'] : {});
    return result;
  }

  serializeTokenValues(save?: boolean) {
    const tokens = this.getTokenValues(save);

    if (tokens['params']) {
      this.parametersControl.controls.forEach(control => {
        let value = tokens['params'][control.controls.name.value];

        if (isSet(value)) {
          const fieldDescription = getFieldDescriptionByType(control.controls.field.value);

          if (fieldDescription && fieldDescription.serializeValue) {
            const parameter = control.serialize();
            value = fieldDescription.serializeValue(value, parameter);
            tokens['params'][control.controls.name.value] = value;
          }
        }
      });
    }

    return tokens;
  }

  getTokenValue(token: Token): string {
    const fieldDescription = getFieldDescriptionByType(token.field);
    let value = objectGet(this.getTokenValues(), token.name);

    if (value === EMPTY) {
      return;
    }

    if (fieldDescription.deserializeValue) {
      value = fieldDescription.deserializeValue(value);
    }

    return value;
  }

  setTokenValue(token: Token, value: string) {
    const fieldDescription = getFieldDescriptionByType(token.field);

    if (fieldDescription.serializeValue) {
      value = fieldDescription.serializeValue(value);
    }

    const tokenValues = cloneDeep(this.tokenValues);
    objectSet(tokenValues, token.name, value);
    this.tokenValues = tokenValues;
  }
}
