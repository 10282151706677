import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription } from 'rxjs';

import { TintStyle, ViewContext } from '@modules/customize';
import { ValueWidget } from '@modules/dashboard';
import { applyParamInput$ } from '@modules/fields';
import { isSet, parseNumber } from '@shared';

@Component({
  selector: 'app-value-widget-counter',
  templateUrl: './value-widget-counter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValueWidgetCounterComponent implements OnInit, OnDestroy, OnChanges {
  @Input() widget: ValueWidget;
  @Input() value: any;
  @Input() icon: string;
  @Input() compareValue: any;
  @Input() compareLabel: string;
  @Input() growthNegative = false;
  @Input() reloadEnabled = false;
  @Input() context: ViewContext;
  @Input() accentColor: string;
  @Input() valueClickEnabled = false;
  @Output() valueClick = new EventEmitter<void>();
  @Output() reload = new EventEmitter<void>();

  valueNumber: number;
  compareValueNumber: number;
  isNumber = false;
  comparePositive: boolean;
  title: string;
  titleSubscription: Subscription;
  tintStyles = TintStyle;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      this.valueNumber = parseNumber(this.value);
    }

    if (changes['compareValue']) {
      this.compareValueNumber = parseNumber(this.compareValue);
    }

    if (changes['value'] || changes['compareValue']) {
      this.isNumber =
        typeof this.valueNumber === 'number' &&
        (!isSet(this.compareValue) || typeof this.compareValueNumber === 'number');
    }

    if (changes['value'] || changes['compareValue'] || changes['growthNegative']) {
      this.updateComparePositive();
    }

    if (changes['widget']) {
      this.initTitle();
    }
  }

  initTitle() {
    if (this.titleSubscription) {
      this.titleSubscription.unsubscribe();
      this.titleSubscription = undefined;
    }

    if (!this.widget.nameInput) {
      this.title = undefined;
      this.cd.markForCheck();
      return;
    }

    this.titleSubscription = applyParamInput$<string>(this.widget.nameInput, {
      context: this.context,
      defaultValue: ''
    })
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.title = value;
        this.cd.markForCheck();
      });
  }

  updateComparePositive() {
    if (!this.isNumber || !isSet(this.valueNumber) || !isSet(this.compareValueNumber)) {
      this.comparePositive = undefined;
      return;
    }

    if (this.growthNegative) {
      if (this.valueNumber < this.compareValueNumber) {
        this.comparePositive = true;
      } else if (this.valueNumber > this.compareValueNumber) {
        this.comparePositive = false;
      } else {
        this.comparePositive = undefined;
      }
    } else {
      if (this.valueNumber > this.compareValueNumber) {
        this.comparePositive = true;
      } else if (this.valueNumber < this.compareValueNumber) {
        this.comparePositive = false;
      } else {
        this.comparePositive = undefined;
      }
    }
  }
}
