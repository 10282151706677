var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, Injector, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { ActionControllerService, ActionService, ActionStore } from '@modules/action-queries';
import { ActionElementItem, AlignHorizontal, CustomizeService, ElementType, registerElementComponent, SUBMIT_RESULT_OUTPUT, TintStyle, ViewContextElement } from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { applyBooleanInput$, applyParamInput$, FieldType, getInputsValid$ } from '@modules/fields';
import { CustomPagePopupComponent } from '../custom-page-popup/custom-page-popup.component';
var ActionElementComponent = /** @class */ (function (_super) {
    __extends(ActionElementComponent, _super);
    function ActionElementComponent(customizeService, viewContextElement, actionService, actionControllerService, actionStore, notificationService, injector, cd, popup) {
        var _this = _super.call(this) || this;
        _this.customizeService = customizeService;
        _this.viewContextElement = viewContextElement;
        _this.actionService = actionService;
        _this.actionControllerService = actionControllerService;
        _this.actionStore = actionStore;
        _this.notificationService = notificationService;
        _this.injector = injector;
        _this.cd = cd;
        _this.popup = popup;
        _this.loading = false;
        _this.submitLoading = false;
        _this.hidden = false;
        _this.disabled = false;
        _this.style = TintStyle.Primary;
        _this.tintStyles = TintStyle;
        _this.alignHorizontals = AlignHorizontal;
        return _this;
    }
    ActionElementComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(function (item) { return !!item; }));
        this.viewContextElement.initElement({
            uniqueName: this.element.uid,
            name: this.element.name,
            icon: 'cursor',
            element: this.element,
            popup: this.popup ? this.popup.popup : undefined
        });
        if (!this.element.actionItem) {
            return;
        }
        this.initVerboseName();
        this.initStyle();
        this.initDisabled();
        this.updateContextOutputs();
        this.actionService
            .getActionDescription(this.element.actionItem)
            .pipe(untilDestroyed(this))
            .subscribe(function (action) {
            _this.action = action;
            _this.cd.markForCheck();
            // if (this.action && this.action.dynamicStatus && this.context.model) {
            //   const context = {};
            //
            //   if (this.context.modelDescription) {
            //     context['model'] = this.context.modelDescription.model;
            //   }
            //
            //   if (this.context.model) {
            //     context['model_primary_key'] = this.context.model.primaryKey;
            //   }
            //
            //   this.loading = true;
            //   this.cd.markForCheck();
            //
            //   this.actionService
            //     .getElementStatus('action_element', this.element.actionItem, context)
            //     .pipe(untilDestroyed(this))
            //     .subscribe(
            //       status => {
            //         this.loading = false;
            //
            //         if (status.hidden) {
            //           this.hidden = true;
            //         }
            //
            //         if (status.disabled) {
            //           this.disabled = true;
            //         }
            //
            //         this.cd.markForCheck();
            //       },
            //       () => {
            //         this.loading = false;
            //         this.hidden = false;
            //         this.disabled = false;
            //         this.cd.markForCheck();
            //       }
            //     );
            // }
        });
    };
    ActionElementComponent.prototype.ngOnDestroy = function () { };
    ActionElementComponent.prototype.ngOnChanges = function (changes) {
        if (changes.element) {
            this.viewContextElement.initInfo({ name: this.element.name, element: this.element });
            this.initVerboseName();
            this.initStyle();
            this.initDisabled();
            this.updateContextOutputs();
        }
    };
    ActionElementComponent.prototype.initVerboseName = function () {
        var _this = this;
        if (this.verboseNameSubscription) {
            this.verboseNameSubscription.unsubscribe();
            this.verboseNameSubscription = undefined;
        }
        if (!this.element.actionItem || !this.element.actionItem.verboseNameInput) {
            this.verboseName = undefined;
            this.cd.markForCheck();
            return;
        }
        this.verboseNameSubscription = applyParamInput$(this.element.actionItem.verboseNameInput, {
            context: this.context,
            contextElement: this.viewContextElement,
            defaultValue: ''
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            _this.verboseName = value;
            _this.cd.markForCheck();
        });
    };
    ActionElementComponent.prototype.initStyle = function () {
        this.style =
            this.element.actionItem && this.element.actionItem.style ? this.element.actionItem.style : TintStyle.Primary;
        this.cd.markForCheck();
    };
    ActionElementComponent.prototype.getInputsValid$ = function () {
        var _this = this;
        return this.actionService.getActionDescription(this.element.actionItem).pipe(switchMap(function (actionDescription) {
            if (!actionDescription) {
                return of(true);
            }
            return getInputsValid$(_this.element.actionItem.inputs, {
                context: _this.context,
                contextElement: _this.viewContextElement,
                parameters: actionDescription.actionParams
            });
        }));
    };
    ActionElementComponent.prototype.getElementDisabled$ = function () {
        return this.element.actionItem.disabledInput && this.element.actionItem.disabledInput.isSet()
            ? applyBooleanInput$(this.element.actionItem.disabledInput, {
                context: this.context,
                contextElement: this.viewContextElement
            })
            : of(false);
    };
    ActionElementComponent.prototype.initDisabled = function () {
        var _this = this;
        if (this.disabledSubscription) {
            this.disabledSubscription.unsubscribe();
            this.disabledSubscription = undefined;
        }
        if (!this.element.actionItem) {
            this.disabled = false;
            this.cd.markForCheck();
            return;
        }
        combineLatest(this.getInputsValid$(), this.getElementDisabled$())
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var inputsValid = _a[0], inputDisabled = _a[1];
            _this.disabled = inputDisabled || !inputsValid;
            _this.cd.markForCheck();
        });
    };
    ActionElementComponent.prototype.updateContextOutputs = function () {
        var _this = this;
        this.actionService
            .getActionOutputs(this.element.actionItem)
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (result.outputs.length && !result.arrayOutput) {
                _this.viewContextElement.setOutputs([
                    {
                        uniqueName: SUBMIT_RESULT_OUTPUT,
                        name: 'Operation Result',
                        icon: 'components',
                        fieldType: FieldType.JSON,
                        external: true,
                        children: result.outputs.map(function (item) {
                            return {
                                uniqueName: item.name,
                                name: item.verboseName,
                                icon: item.fieldDescription.icon,
                                fieldType: item.field,
                                fieldParams: item.params
                            };
                        })
                    }
                ]);
            }
            else {
                _this.viewContextElement.setOutputs([
                    {
                        uniqueName: SUBMIT_RESULT_OUTPUT,
                        name: 'Operation Result',
                        icon: 'components',
                        fieldType: FieldType.JSON,
                        external: true
                    }
                ]);
            }
        });
    };
    ActionElementComponent.prototype.execute = function () {
        var _this = this;
        this.submitLoading = true;
        this.cd.markForCheck();
        this.actionControllerService
            .execute(this.element.actionItem, {
            context: this.context,
            contextElement: this.viewContextElement,
            saveResultTo: SUBMIT_RESULT_OUTPUT,
            injector: this.injector,
            analyticsSource: this.element.analyticsGenericName
        })
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.submitLoading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.submitLoading = false;
            _this.cd.markForCheck();
        });
    };
    return ActionElementComponent;
}(BaseElementComponent));
export { ActionElementComponent };
registerElementComponent({
    type: ElementType.Action,
    component: ActionElementComponent,
    label: 'Action',
    actions: []
});
