<div *ngIf="(title | appIsSet) || reloadEnabled" class="value-widget__label">
  <span class="value-widget__label-text">{{ title }}</span>

  <span
    *ngIf="widget.tooltip | appIsSet"
    class="value-widget__label-help help-icon icon-help"
    [appTip]="widget.tooltip"
    [appTipOptions]="{ side: 'top' }"
  ></span>

  <span
    *ngIf="reloadEnabled"
    class="value-widget__label-button icon-repeat"
    [class.value-widget__label-button_right]="title | appIsSet"
    [appTintColor]="accentColor"
    [appTintColorStyle]="tintStyles.Default"
    (click)="reload.emit()"
  ></span>
</div>

<div
  class="value-widget__value"
  [class.value-widget__value_interactive]="valueClickEnabled"
  [title]="(value | appIsSet) ? value : null"
  (click)="valueClick.emit()"
>
  <div *ngIf="icon" class="value-widget__value-icon" [ngClass]="'icon-' + icon"></div>

  <div *ngIf="value | appIsSet" class="value-widget__value-content">
    <span *ngIf="widget.prefix" class="value-widget__small value-widget__small_left">{{ widget.prefix }}</span>
    {{ value | appDomainFormatNumber: widget.format | async }}
    <span *ngIf="widget.postfix" class="value-widget__small value-widget__small_right">{{ widget.postfix }}</span>
  </div>

  <div *ngIf="!(value | appIsSet)" class="value-widget__value-content">
    <span [class.loading-animation]="false"><span class="stub-text">45</span></span>
  </div>
</div>

<div *ngIf="(compareValue | appIsSet) && isNumber" class="value-widget__compare">
  <span
    *ngIf="valueNumber > compareValueNumber || valueNumber < compareValueNumber"
    class="value-widget__compare-item"
    [appTip]="(compareValue | appFormatNumber: widget.format) + ' → ' + (value | appFormatNumber: widget.format)"
    [appTipOptions]="{ side: 'bottom' }"
  >
    <span
      class="value-widget__compare-icon"
      [ngClass]="{
        'icon-arrow_up': valueNumber > compareValueNumber,
        'icon-arrow_down': valueNumber < compareValueNumber,
        'value-widget__compare-icon_positive': valueNumber > compareValueNumber,
        'value-widget__compare-icon_negative': valueNumber < compareValueNumber,
        color_green_1: comparePositive === true,
        color_red_1: comparePositive === false,
        'background-color_green_2': comparePositive === true,
        'background-color_red_2': comparePositive === false
      }"
    ></span>

    <span
      *ngIf="compareValueNumber !== 0"
      class="value-widget__compare-value"
      [ngClass]="{
        color_green_1: comparePositive === true,
        color_red_1: comparePositive === false
      }"
    >
      <ng-container *ngIf="isNumber && valueNumber > compareValueNumber">+</ng-container
      >{{ valueNumber | appRatio: compareValueNumber | appFormatNumber: '0.00%' }}
    </span>
  </span>

  <span *ngIf="compareLabel" class="value-widget__compare-label">{{ compareLabel }}</span>
</div>
