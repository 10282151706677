var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import range from 'lodash/range';
import { combineLatest, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { FormUtils } from '@common/form-utils';
import { ActionStore } from '@modules/action-queries';
import { processElementItemPageSegues, processElementItemResources, traverseElementItemQueries, ViewSettingsStore } from '@modules/customize';
import { ButtonMenuItem, MenuSettingsStore, SectionMenuItem, SimpleMenuItem } from '@modules/menu';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { PER_PAGE_PARAM } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore, isResourceTypeItemReplicable, ResourceName } from '@modules/projects';
import { ActionQuery, ChartWidgetQuery, ModelDescriptionQuery, QueryType, ValueWidgetQuery } from '@modules/queries';
import { ResourceGeneratorResolver } from '@modules/resource-generators';
import { Template, TemplateImage, TemplateService, TemplateTag, TemplateType } from '@modules/template';
function validateType() {
    return function (control) {
        if (control.value['type'] == TemplateType.Widget && control.value['update_element'] && !control.value['element']) {
            return { local: ['No element selected'] };
        }
        if (control.value['type'] == TemplateType.Page && (!control.value['pages'] || !control.value['pages'].length)) {
            return { local: ['No pages selected'] };
        }
    };
}
var TemplatesItemForm = /** @class */ (function (_super) {
    __extends(TemplatesItemForm, _super);
    function TemplatesItemForm(templateService, modelDescriptionStore, modelService, actionStore, viewSettingsStore, currentProjectStore, currentEnvironmentStore, menuSettingsStore, resourceGeneratorResolver, formUtils) {
        var _this = _super.call(this, {
            type: new FormControl('', Validators.required),
            name: new FormControl('', Validators.required),
            subtitle: new FormControl(''),
            description: new FormControl(''),
            logo: new FormControl(''),
            logo_fill: new FormControl(false),
            icon: new FormControl(''),
            color: new FormControl(''),
            featured: new FormControl(false),
            images: new FormArray([]),
            tags: new FormArray([]),
            // resource_type_items: new FormControl([]),
            pages: new FormControl(''),
            update_element: new FormControl(true),
            element: new FormControl(''),
            active: new FormControl(false),
            ordering: new FormControl(0)
        }, validateType()) || this;
        _this.templateService = templateService;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.modelService = modelService;
        _this.actionStore = actionStore;
        _this.viewSettingsStore = viewSettingsStore;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.menuSettingsStore = menuSettingsStore;
        _this.resourceGeneratorResolver = resourceGeneratorResolver;
        _this.formUtils = formUtils;
        _this.typeOptions = [
            {
                value: TemplateType.Widget,
                name: 'Component'
            },
            {
                value: TemplateType.Page,
                name: 'Pages'
            },
            {
                value: TemplateType.ResourceModelDescriptionsTemplate,
                name: 'Data'
            }
        ];
        _this.uploaderResourceName = 'jet_templates_uploader';
        _this.uploaderResourceStorageName = 'jetadmin';
        return _this;
    }
    TemplatesItemForm.prototype.initElement = function (element) {
        this.controls.element.patchValue(element);
        console.log('element', element);
    };
    TemplatesItemForm.prototype.ngOnDestroy = function () { };
    TemplatesItemForm.prototype.init = function (value) {
        this.template = value;
        if (!value) {
            return;
        }
        this.patchTemplate(value);
    };
    TemplatesItemForm.prototype.patchTemplate = function (value) {
        var _this = this;
        this.controls.type.patchValue(value.type);
        this.controls.name.patchValue(value.name);
        this.controls.subtitle.patchValue(value.subtitle);
        this.controls.description.patchValue(value.description);
        this.controls.logo.patchValue(value.logo);
        this.controls.logo_fill.patchValue(value.logoFill);
        this.controls.icon.patchValue(value.icon);
        this.controls.color.patchValue(value.color);
        this.controls.featured.patchValue(value.featured);
        // this.controls.resource_type_items.patchValue(value.resourceTypeItems.map(item => item.name));
        this.controls.pages.patchValue(value.pages.map(function (item) { return item.uniqueName; }));
        this.controls.active.patchValue(value.active);
        this.controls.ordering.patchValue(value.ordering);
        this.arraySet('tags', value.tags.map(function (item) {
            return _this.createTagItem({
                name: item.name,
                color: item.color
            });
        }));
        this.arraySet('images', value.images.map(function (item) {
            var prefix = '';
            var uploaderResource = _this.getUploaderResource();
            if (uploaderResource) {
                prefix = uploaderResource.mediaUrlTemplate.replace('{}', '');
            }
            return _this.createImageItem({
                image: item.image ? item.image.replace(prefix, '') : item.image
            });
        }));
    };
    TemplatesItemForm.prototype.getUploaderResource = function () {
        var _this = this;
        return this.currentEnvironmentStore.resources.find(function (item) { return item.name == _this.uploaderResourceName; });
    };
    TemplatesItemForm.prototype.getUploaderResourceStorage = function () {
        var _this = this;
        var result = this.getUploaderResource();
        if (!result) {
            return;
        }
        return result.storages.find(function (item) { return item.uniqueName == _this.uploaderResourceStorageName; });
    };
    TemplatesItemForm.prototype.arrayControls = function (name) {
        return this.controls[name].controls;
    };
    TemplatesItemForm.prototype.arraySet = function (name, groups) {
        var array = this.controls[name];
        range(array.controls.length).forEach(function () { return array.removeAt(0); });
        groups.forEach(function (item) { return array.push(item); });
        array.updateValueAndValidity();
    };
    TemplatesItemForm.prototype.arrayAppend = function (name, group) {
        var array = this.controls[name];
        array.push(group);
        array.updateValueAndValidity();
    };
    TemplatesItemForm.prototype.arrayRemove = function (name, group) {
        var array = this.controls[name];
        var index = array.controls.findIndex(function (item) { return item === group; });
        if (index == -1) {
            return;
        }
        array.removeAt(index);
        array.updateValueAndValidity();
    };
    TemplatesItemForm.prototype.createTagItem = function (value) {
        var form = new FormGroup({
            name: new FormControl('', Validators.required),
            color: new FormControl('')
        });
        if (value) {
            form.patchValue(value);
        }
        return form;
    };
    TemplatesItemForm.prototype.createImageItem = function (value) {
        var form = new FormGroup({
            image: new FormControl('', Validators.required)
        });
        if (value) {
            form.patchValue(value);
        }
        return form;
    };
    TemplatesItemForm.prototype.prepareUsedResourceModel = function (modelDescription) {
        var _a;
        var project = this.currentProjectStore.instance;
        var environment = this.currentEnvironmentStore.instance;
        var params = (_a = {}, _a[PER_PAGE_PARAM] = 100, _a);
        return this.modelService.getAll(project, environment, modelDescription.modelId, params).pipe(map(function (result) {
            return {
                modelDescription: modelDescription,
                instances: result.map(function (item) { return item.serialize(); })
            };
        }));
    };
    TemplatesItemForm.prototype.prepareUsedResource = function (item) {
        var _this = this;
        var project = this.currentProjectStore.instance;
        var environment = this.currentEnvironmentStore.instance;
        var generator = this.resourceGeneratorResolver.get(item.typeItem.name);
        var options$ = generator && item.typeItem.name != ResourceName.JetDatabase
            ? generator.getParamsOptions(project, environment, item.resource).pipe(map(function (options) {
                var result = __assign({}, options);
                if (generator.tokenRequired && options.hasOwnProperty('token')) {
                    result['token'] = undefined;
                }
                return result;
            }))
            : of({});
        var models$ = item.models
            ? combineLatest(item.models.map(function (model) { return _this.prepareUsedResourceModel(model.modelDescription); }))
            : of(undefined);
        return combineLatest(options$, models$).pipe(map(function (_a) {
            var options = _a[0], models = _a[1];
            return {
                type: item.type,
                typeItem: item.typeItem,
                token: item.token,
                options: options,
                models: models
                    ? models.map(function (model) {
                        var templateModelDescription = cloneDeep(model.modelDescription);
                        templateModelDescription.resource = item.token;
                        return __assign({}, model, { modelDescription: templateModelDescription });
                    })
                    : undefined
            };
        }));
    };
    TemplatesItemForm.prototype.setUsedResources = function (instance) {
        var _this = this;
        var modelDescriptions = this.modelDescriptionStore.instance;
        var actionsDescriptions = this.actionStore.instance;
        var usedResources = [];
        var forResources = [];
        var processResource = function (resourceName) {
            if (usedResources.find(function (item) { return item.token == resourceName; })) {
                return resourceName;
            }
            var resource = _this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == resourceName; });
            if (!resource) {
                return resourceName;
            }
            if (!forResources.find(function (item) { return item.name == resource.typeItem.name; })) {
                forResources.push(resource.typeItem);
            }
            if (resource.demo && !resource.isSimpleIntegration()) {
                return resourceName;
            }
            var usedResource = usedResources.find(function (item) { return item.uniqueName == resource.uniqueName; });
            if (!usedResource) {
                var number = usedResources.length + 1;
                var token = "{{TEMPLATE_RESOURCE_" + number + "}}";
                usedResource = {
                    type: resource.type,
                    typeItem: resource.typeItem,
                    token: token,
                    models: isResourceTypeItemReplicable(resource.typeItem) ? [] : undefined,
                    uniqueName: resource.uniqueName,
                    resource: resource
                };
                usedResources.push(usedResource);
            }
            return usedResource.token;
        };
        var processQuery = function (resourceName, query) {
            var usedResource = usedResources.find(function (item) { return item.token == resourceName; });
            if (!usedResource || !isResourceTypeItemReplicable(usedResource.typeItem)) {
                return;
            }
            if ((query instanceof ModelDescriptionQuery ||
                query instanceof ValueWidgetQuery ||
                query instanceof ChartWidgetQuery) &&
                query.queryType == QueryType.Simple &&
                query.simpleQuery) {
                var modelDescription_1 = modelDescriptions.find(function (item) { return item.resource == usedResource.uniqueName && item.model == query.simpleQuery.model; });
                if (modelDescription_1 && !usedResource.models.find(function (item) { return item.modelDescription.isSame(modelDescription_1); })) {
                    usedResource.models.push({
                        modelDescription: modelDescription_1,
                        instances: []
                    });
                }
            }
            else if (query instanceof ActionQuery && query.queryType == QueryType.Simple && query.simpleQuery) {
                var actionsDescription_1 = actionsDescriptions.find(function (item) { return item.resource == usedResource.uniqueName && item.name == query.simpleQuery.name; });
                var modelDescription_2 = actionsDescription_1
                    ? modelDescriptions.find(function (item) { return item.resource == usedResource.uniqueName && item.model == actionsDescription_1.model; })
                    : undefined;
                if (modelDescription_2 && !usedResource.models.find(function (item) { return item.modelDescription.isSame(modelDescription_2); })) {
                    usedResource.models.push({
                        modelDescription: modelDescription_2,
                        instances: []
                    });
                }
            }
        };
        if (instance.type == TemplateType.Page) {
            processElementItemResources(instance.pages, processResource);
            traverseElementItemQueries(instance.pages, processQuery);
        }
        else if (instance.type == TemplateType.Widget) {
            processElementItemResources(instance.element, processResource);
            traverseElementItemQueries(instance.element, processQuery);
        }
        instance.forResources = forResources
            .filter(function (item) { return !item.installConfigurationNeeded; })
            .map(function (item) {
            return {
                type: item.resourceType,
                typeItem: item
            };
        });
        if (!usedResources.length) {
            instance.usedResources = [];
            return of(instance);
        }
        return combineLatest(usedResources.map(function (item) { return _this.prepareUsedResource(item); })).pipe(map(function (result) {
            instance.usedResources = result;
            return instance;
        }));
    };
    TemplatesItemForm.prototype.setUsedPages = function (instance) {
        return this.viewSettingsStore.getFirst().pipe(map(function (viewSettings) {
            var usedPages = [];
            var processPageSegue = function (pageName) {
                if (usedPages.find(function (item) { return item.uniqueName == pageName; })) {
                    return;
                }
                var page = viewSettings.find(function (item) { return item.uniqueName && item.uniqueName == pageName; });
                if (!page) {
                    return;
                }
                var usedPage = usedPages.find(function (item) { return item.uniqueName == page.uniqueName; });
                if (!usedPage) {
                    var number = usedPages.length + 1;
                    var token = "{{TEMPLATE_PAGE_SEGUE_" + number + "}}";
                    usedPage = {
                        token: token,
                        uniqueName: page.uniqueName
                    };
                    usedPages.push(usedPage);
                }
                return usedPage.token;
            };
            if (instance.type == TemplateType.Page) {
                processElementItemPageSegues(instance.pages, processPageSegue);
            }
            instance.usedPages = usedPages.map(function (item) {
                return {
                    token: item.token,
                    uniqueName: item.uniqueName
                };
            });
            return instance;
        }));
    };
    TemplatesItemForm.prototype.setUsedMenuItems = function (instance) {
        return this.menuSettingsStore.getFirst().pipe(map(function (menuSettings) {
            var processMenuItems = function (menuItems) {
                return menuItems.reduce(function (prev, current) {
                    if (current instanceof SimpleMenuItem || current instanceof ButtonMenuItem) {
                        var page = instance.pages.find(function (p) { return current.isForPage(p); });
                        if (page) {
                            prev.push(current);
                        }
                    }
                    else if (current instanceof SectionMenuItem) {
                        var children = processMenuItems(current.children);
                        if (children.length) {
                            var item = cloneDeep(current);
                            item.children = children;
                            prev.push(item);
                        }
                    }
                    return prev;
                }, []);
            };
            instance.menuItems = processMenuItems(menuSettings.getAllItems());
            return instance;
        }));
    };
    TemplatesItemForm.prototype.getInstance = function () {
        var instance = this.template ? cloneDeep(this.template) : new Template();
        if (this.controls.type.value) {
            instance.type = this.controls.type.value;
        }
        instance.name = this.controls.name.value;
        instance.subtitle = this.controls.subtitle.value;
        instance.description = this.controls.description.value;
        instance.logo = this.controls.logo.value;
        instance.logoFill = this.controls.logo_fill.value;
        instance.icon = this.controls.icon.value;
        instance.color = this.controls.color.value;
        instance.featured = this.controls.featured.value;
        // instance.resourceTypeItems = this.controls.resource_type_items.value
        //   .map(item => resourceTypeItems.find(i => i.name == item));
        instance.tags = this.controls.tags.value.map(function (item) {
            var tag = new TemplateTag();
            tag.name = item['name'];
            tag.color = item['color'];
            return tag;
        });
        var uploaderResource = this.getUploaderResource();
        instance.images = this.controls.images.value.map(function (item) {
            var image = new TemplateImage();
            image.image = uploaderResource ? uploaderResource.mediaUrl(item['image']) : item['image'];
            return image;
        });
        if (this.controls.type.value == TemplateType.Widget && this.controls.update_element.value) {
            instance.element = cloneDeep(this.controls.element.value);
            instance.element.uid = undefined;
        }
        instance.active = this.controls.active.value;
        instance.ordering = this.controls.ordering.value;
        return instance;
    };
    TemplatesItemForm.prototype.submit = function () {
        var _this = this;
        var instance = this.getInstance();
        return this.viewSettingsStore.getFirst().pipe(switchMap(function (viewSettings) {
            if (_this.controls.type.value == TemplateType.Page) {
                instance.pages = cloneDeep(viewSettings.filter(function (item) { return _this.controls.pages.value.includes(item.uniqueName); }));
                return _this.setUsedResources(instance);
            }
            else if (_this.controls.type.value == TemplateType.Widget) {
                return _this.setUsedResources(instance);
            }
            else {
                return of(instance);
            }
        }), switchMap(function () {
            if (_this.controls.type.value == TemplateType.Page) {
                return _this.setUsedPages(instance).pipe(switchMap(function () { return _this.setUsedMenuItems(instance); }));
            }
            else {
                return of(instance);
            }
        }), switchMap(function () {
            if (_this.template) {
                return _this.templateService.update(instance);
            }
            else {
                return _this.templateService.create(instance);
            }
        }), catchError(function (error) {
            _this.formUtils.showFormErrors(_this, error);
            return throwError(error);
        }));
    };
    return TemplatesItemForm;
}(FormGroup));
export { TemplatesItemForm };
