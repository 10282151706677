import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { AppConfigService } from '@core';
import { AnalyticsEvent } from '@modules/analytics';
import { TintStyle } from '@modules/customize';
import { defaultSignUpLayout, Domain } from '@modules/domain';
import { createFormFieldFactory } from '@modules/fields';
import { Field, ProjectInvite, ProjectPublicInvite } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { SSOSettings, SSOType } from '@modules/sso';
import { ThemeContext } from '@modules/theme-components';
import { isSet, TypedChanges } from '@shared';

import { RegisterForm } from '../register/register.form';

@Component({
  selector: 'app-register-template',
  templateUrl: './register-template.component.html',
  providers: [ThemeContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegisterTemplateComponent implements OnInit, OnChanges {
  @Input() form: RegisterForm;
  @Input() domainLoading = false;
  @Input() submitLoading = false;
  @Input() screenLoader = false;
  @Input() domain: Domain;
  @Input() customFields: Field[] = [];
  @Input() invite: ProjectInvite;
  @Input() inviteLoading = false;
  @Input() publicInvite: ProjectPublicInvite;
  @Input() publicInviteLoading = false;
  @Input() signInLink: any[];
  @Input() fillScreen = false;
  @Input() preview = false;
  @Output() socialLogin = new EventEmitter<string>();
  @Output() ssoLogin = new EventEmitter<SSOSettings>();
  @Output() cleanErrorForm = new EventEmitter<void>();
  @Output() submit = new EventEmitter<void>();

  createField = createFormFieldFactory();
  defaultLayout = defaultSignUpLayout;
  externalFonts: string[] = [];
  tintStyles = TintStyle;
  analyticsEvents = AnalyticsEvent;

  constructor(public appConfigService: AppConfigService, private routing: RoutingService) {}

  ngOnInit() {
    const disabledEmailSignUp = this.domain ? this.domain.disableEmailSignUp : false;
    const disabledEmailSignIn = this.domain ? this.domain.disableEmailSignIn : false;

    if (disabledEmailSignUp && !disabledEmailSignIn && !this.invite && this.signInLink) {
      this.routing.navigate(this.signInLink, { queryParamsHandling: 'preserve' });
    }
  }

  ngOnChanges(changes: TypedChanges<RegisterTemplateComponent>): void {
    if (changes.domain) {
      this.externalFonts = this.getExternalFonts(this.domain);
    }
  }

  getExternalFonts(domain: Domain): string[] {
    if (!domain) {
      return [];
    }

    return [domain.signUpFontRegular, domain.signUpFontHeading].filter(item => isSet(item));
  }

  get layout() {
    return this.domain && isSet(this.domain.signUpLayout) ? this.domain.signUpLayout : this.defaultLayout;
  }

  get headerHasContent() {
    const hasLogo = !this.isWhiteLabel || (this.domain && (this.domain.logo || this.domain.initials));
    const hasTitle = !this.domain || this.domain.signUpTitle;
    const hasDescription = !this.domain || this.domain.signUpDescription;

    return hasLogo || hasTitle || hasDescription;
  }

  get isWhiteLabel() {
    return this.domain && this.domain.whiteLabel;
  }

  get isGoogleSignEnabled() {
    const disabledGoogleSignIn = this.domain ? this.domain.disableGoogleSignIn : false;
    return !this.isWhiteLabel && this.appConfigService.socialAuthGoogleEnabled && !disabledGoogleSignIn;
  }

  get isFacebookSignEnabled() {
    const disabledFacebookSignIn = this.domain ? this.domain.disableFacebookSignIn : false;
    return !this.isWhiteLabel && this.appConfigService.socialAuthFacebookEnabled && !disabledFacebookSignIn;
  }

  get isSignInWithEnabled() {
    const ssoSettings = this.domain ? this.domain.ssoSettings : [];
    return this.isGoogleSignEnabled || this.isFacebookSignEnabled || ssoSettings.length;
  }

  startSSOLogin(sso: SSOSettings) {
    if (sso.type == SSOType.Custom) {
      this.routing.navigate(this.signInLink, { queryParams: { sso: sso.uid }, queryParamsHandling: 'merge' });
    } else {
      this.ssoLogin.emit(sso);
    }
  }
}
