<app-field-wrapper
  *ngIf="!readonly"
  [formGroup]="form"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
>
  <ng-template let-trigger="trigger" let-margin="margin" #color_selector>
    <ng-template
      (backdropClick)="openEditPopover(false)"
      (detach)="openEditPopover(false)"
      [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
      [cdkConnectedOverlayGrowAfterOpen]="true"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayOpen]="editPopoverOpened"
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
      [cdkConnectedOverlayPositions]="[
        { originX: 'start', overlayX: 'start', originY: 'bottom', overlayY: 'top', offsetX: -8, offsetY: -8 + margin },
        { originX: 'center', overlayX: 'center', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: -8 + margin },
        { originX: 'end', overlayX: 'end', originY: 'bottom', overlayY: 'top', offsetX: 8, offsetY: -8 + margin },
        { originX: 'start', overlayX: 'start', originY: 'top', overlayY: 'bottom', offsetX: -8, offsetY: 8 - margin },
        { originX: 'center', overlayX: 'center', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: 8 - margin },
        { originX: 'end', overlayX: 'end', originY: 'top', overlayY: 'bottom', offsetX: 8, offsetY: 8 - margin },
        { originX: 'end', overlayX: 'start', originY: 'center', overlayY: 'center', offsetX: margin, offsetY: 0 },
        { originX: 'start', overlayX: 'end', originY: 'center', overlayY: 'center', offsetX: 0 - margin, offsetY: 0 }
      ]"
      [cdkConnectedOverlayPush]="true"
      cdkConnectedOverlay
    >
      <div class="popover2 overlay-content overlay-content_animated">
        <div class="popover2__inner">
          <app-icon-selector
            [current]="currentValue"
            (selected)="setIcon($event); openEditPopover(false)"
          ></app-icon-selector>
        </div>
      </div>
    </ng-template>
  </ng-template>

  <ng-container *ngIf="!field.params['minimal']">
    <div class="select select_theme_jet" [ngClass]="field.params['classes']">
      <a
        href="javascript:void(0)"
        class="select__input"
        [class.select__input_opened]="editPopoverOpened"
        [id]="field.name | appUniqueId: idToken"
        cdkOverlayOrigin
        #field_trigger="cdkOverlayOrigin"
        (click)="openEditPopover(true)"
      >
        <span
          class="select__input-icon"
          [class.select__input-icon_empty]="!currentValue"
          [ngClass]="['icon-' + currentValue]"
        ></span>
        <span class="select__input-label">
          <ng-container *ngIf="currentValue">{{ getLabel(currentValue) }}</ng-container>
          <ng-container *ngIf="!currentValue">{{
            (field.placeholder | appIsSet) ? field.placeholder : 'not specified'
          }}</ng-container>
        </span>
        <span
          *ngIf="currentValue && field.params['allow_empty']"
          class="select__input-reset icon-close"
          (click)="$event.stopPropagation(); setIcon(undefined)"
        ></span>
        <span *ngIf="!currentValue || !field.params['allow_empty']" class="select__input-arrow"></span>
      </a>
    </div>

    <ng-container *ngTemplateOutlet="color_selector; context: { trigger: field_trigger, margin: 0 }"></ng-container>
  </ng-container>

  <ng-container *ngIf="field.params['minimal']">
    <a
      href="javascript:void(0)"
      class="icon-button"
      [class.icon-button_empty]="!currentValue"
      [ngClass]="currentValue ? ['icon-' + currentValue] : []"
      (click)="openEditPopover(true)"
      [id]="field.name | appUniqueId: idToken"
      cdkOverlayOrigin
      #button_trigger="cdkOverlayOrigin"
    >
    </a>

    <ng-container *ngTemplateOutlet="color_selector; context: { trigger: button_trigger, margin: 8 }"></ng-container>
  </ng-container>
</app-field-wrapper>
<app-field-wrapper
  *ngIf="readonly"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
>
  <span
    class="field__button-icon"
    [class.field__button-icon_empty]="!currentValue"
    [ngClass]="['icon-' + currentValue]"
  ></span>
  <span class="field__button-label">{{ formatEmpty(currentValue) }}</span>
</app-field-wrapper>
