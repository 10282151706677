import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

import { isSet } from '@shared';

import { Icon, icons } from '../../data/icons';

@Component({
  selector: 'app-icon-selector',
  templateUrl: './icon-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconSelectorComponent implements OnInit, AfterViewInit {
  @Input() current: string;
  @Input() emptyEnabled = false;
  @Input() emptyValue: any;
  @Output() selected = new EventEmitter<string>();

  loading = true;
  items = icons;
  filteredItems: Icon[] = [];
  search = '';

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.updateFilteredItems();
  }

  ngAfterViewInit(): void {
    this.loading = false;
    this.cd.markForCheck();
  }

  get query() {
    return this.search.trim().toLowerCase();
  }

  updateFilteredItems() {
    const query = this.query;

    if (!isSet(query)) {
      this.filteredItems = this.items;
    } else {
      this.filteredItems = this.items.filter(item => item.label.toLowerCase().includes(query));
    }

    this.cd.markForCheck();
  }

  onSearchChanged() {
    this.updateFilteredItems();
  }

  save(item: Icon) {
    this.selected.emit(item.name);
  }

  reset() {
    this.selected.emit(this.emptyValue);
  }
}
