import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

import { DialogService } from '@common/dialogs';
import { PopupSettings, ViewContext } from '@modules/customize';
import { BooleanFieldStyle } from '@modules/field-components';
import { createFormFieldFactory } from '@modules/fields';
import { QueryBuilderContext } from '@modules/queries-components';

import { CustomizeBarEditComponent } from '../../data/customize-bar-edit-component';
import { CustomizeBarEditEvent } from '../../data/customize-bar-edit-event';
import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { CustomizeBarPopupEditForm } from './customize-bar-popup-edit.form';

@Component({
  selector: 'app-customize-bar-popup-edit',
  templateUrl: './customize-bar-popup-edit.component.html',
  providers: [CustomizeBarPopupEditForm, QueryBuilderContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarPopupEditComponent implements OnInit, OnDestroy, CustomizeBarEditComponent {
  @Input() popup: PopupSettings;
  @Input() context: ViewContext;
  @Input() firstInit = false;
  @Input() setupOnCreate = false;
  @Output() event = new EventEmitter<CustomizeBarEditEvent>();

  createField = createFormFieldFactory();
  loading = false;
  actionsValid$: Observable<boolean>;
  booleanFieldStyle = BooleanFieldStyle;
  analyticsObject = 'popup';

  constructor(
    public form: CustomizeBarPopupEditForm,
    private dialogService: DialogService,
    private customizeBarContext: CustomizeBarContext,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.form.init(this.popup, this.firstInit);

    this.form.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(() => {
      const result = this.form.submit();
      this.emitUpdate(result);
    });

    this.actionsValid$ = this.form.actionsValid$();
  }

  ngOnDestroy(): void {}

  close() {
    this.customizeBarContext.popSettingsComponent();
  }

  submit() {
    const result = this.form.submit();

    this.emitUpdate(result, true);
    this.close();
  }

  emitUpdate(result: PopupSettings, submit = false) {
    this.event.emit({ type: CustomizeBarEditEventType.Updated, args: { result: result, submit: submit } });
  }

  cancel() {
    this.event.emit({ type: CustomizeBarEditEventType.Canceled });
    this.close();
  }

  delete() {
    this.dialogService
      .warning({
        title: 'Deleting',
        description: `Are you sure want to delete popup ${this.popup.name}?`,
        style: 'orange'
      })
      .pipe(
        filter(result => result == true),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.event.emit({ type: CustomizeBarEditEventType.Deleted });
        this.close();
      });
  }

  get title() {
    if (this.form.controls.name.value) {
      return this.form.controls.name.value;
    } else {
      return 'Modal';
    }
  }
}
