import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgGxScrollableModule } from 'ng-gxscrollable';

import { DialogsModule } from '@common/dialogs';
import { DragDropModule } from '@common/drag-drop';
import { FixedComponentModule } from '@common/fixed-component';
import { LocalizeModule } from '@common/localize';
import { TipsModule } from '@common/tips';
import { AnalyticsModule } from '@modules/analytics';
import { ColorsSharedModule } from '@modules/colors-shared';
import { DemoModule } from '@modules/demo';
import { ExportModule } from '@modules/export';
import { FieldComponentsModule } from '@modules/field-components';
import { FieldsModule } from '@modules/fields';
import { ListComponentsModule } from '@modules/list-components';
import { ModelQueriesModule } from '@modules/model-queries';
import { RoutingModule } from '@modules/routing';
import { TableModule } from '@modules/table';
import { ViewsComponentsModule } from '@modules/views-components';
import { SharedModule } from '@shared';

import { CarouselPaginationComponent } from './components/carousel-pagination/carousel-pagination.component';
import { CarouselStubComponent } from './components/carousel-stub/carousel-stub.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { GridItemComponent } from './components/grid-item/grid-item.component';
import { GridStubItemComponent } from './components/grid-stub-item/grid-stub-item.component';
import { GridStubComponent } from './components/grid-stub/grid-stub.component';
import { GridComponent } from './components/grid/grid.component';

@NgModule({
  imports: [
    CommonModule,
    RoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgGxScrollableModule,
    SharedModule,
    DemoModule,
    ListComponentsModule,
    FieldsModule,
    FieldComponentsModule,
    TableModule,
    TipsModule,
    LocalizeModule,
    DialogsModule,
    ModelQueriesModule,
    DragDropModule,
    ExportModule,
    AnalyticsModule,
    FixedComponentModule,
    ViewsComponentsModule,
    ColorsSharedModule
  ],
  declarations: [
    GridComponent,
    GridItemComponent,
    GridStubComponent,
    GridStubItemComponent,
    CarouselComponent,
    CarouselStubComponent,
    CarouselPaginationComponent
  ],
  exports: [GridComponent, GridItemComponent, CarouselComponent]
})
export class GridModule {}
