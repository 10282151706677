import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  FieldType,
  GeographyOutputFormat,
  MultipleSelectOutputFormat,
  NumberValueFormat,
  OptionsType
} from '@modules/fields';
import {
  SMART_SUITE_ID_FIELD_NAME,
  SMART_SUITE_SYSTEM_APPLICATION_MEMBERS_SLUG,
  SmartSuiteApplicationField,
  SmartSuiteApplicationFieldType,
  SmartSuiteApplicationResponse,
  SOURCE_FIELD_PARAMS
} from '@modules/resources';
import { forceObservable } from '@shared';

export interface SmartSuiteFieldMappingFieldWithParams {
  field: FieldType;
  params?: Record<string, unknown>;
}

export interface SmartSuiteMappingOptions {
  name: string;
  field: SmartSuiteApplicationField;
  applications: SmartSuiteApplicationResponse[];
}

export interface SmartSuiteFieldMapping {
  field?: FieldType;
  params?: Record<string, unknown>;
  getField?: (
    options: SmartSuiteMappingOptions
  ) => SmartSuiteFieldMappingFieldWithParams | Observable<SmartSuiteFieldMappingFieldWithParams>;
  getParams?: (options: SmartSuiteMappingOptions) => Record<string, unknown> | Observable<Record<string, unknown>>;
}

const smartSuiteDateRangeStructure = () => {
  return {
    type: 'object',
    name: null,
    label: null,
    params: {
      items: [
        {
          type: 'object',
          name: 'from_date',
          label: 'from date',
          params: {
            items: [
              {
                type: 'field',
                name: 'date',
                label: 'date',
                params: {
                  field: FieldType.DateTime,
                  params: {},
                  required: true,
                  name: 'date',
                  label: 'date'
                }
              }
            ]
          }
        },
        {
          type: 'object',
          name: 'to_date',
          label: 'to date',
          params: {
            items: [
              {
                type: 'field',
                name: 'date',
                label: 'date',
                params: {
                  field: FieldType.DateTime,
                  params: {},
                  required: true,
                  name: 'date',
                  label: 'date'
                }
              }
            ]
          }
        }
      ]
    }
  };
};

const smartSuiteByStructure = () => {
  return {
    type: 'object',
    name: null,
    label: null,
    params: {
      items: [
        {
          type: 'field',
          name: 'by',
          label: 'by',
          params: {
            field: FieldType.RelatedModel,
            params: {
              related_model: {
                model: `{{resource}}.${SMART_SUITE_SYSTEM_APPLICATION_MEMBERS_SLUG}`
              },
              custom_primary_key: SMART_SUITE_ID_FIELD_NAME,
              custom_display_field: 'full_name'
            },
            required: true,
            name: 'by',
            label: 'by'
          }
        },
        {
          type: 'field',
          name: 'on',
          label: 'on',
          params: {
            field: FieldType.DateTime,
            params: {},
            required: true,
            name: 'on',
            label: 'on'
          }
        }
      ]
    }
  };
};

export const smartSuiteFieldMapping: {
  type: SmartSuiteApplicationFieldType;
  mapping: SmartSuiteFieldMapping;
}[] = [
  {
    type: SmartSuiteApplicationFieldType.TextField,
    mapping: { field: FieldType.Text }
  },
  {
    type: SmartSuiteApplicationFieldType.TextAreaField,
    mapping: { field: FieldType.Text, params: { multiline: true } }
  },
  {
    type: SmartSuiteApplicationFieldType.RichTextAreaField,
    mapping: { field: FieldType.RichText }
  },
  {
    type: SmartSuiteApplicationFieldType.HiddenTextField,
    mapping: { field: FieldType.Text }
  },
  {
    type: SmartSuiteApplicationFieldType.EmailField,
    mapping: { field: FieldType.Text }
  },
  {
    type: SmartSuiteApplicationFieldType.AddressField,
    mapping: {
      field: FieldType.Location,
      getParams: (options: SmartSuiteMappingOptions) => {
        return {
          output_format: GeographyOutputFormat.Object,
          object_lat_field: 'location_longitude',
          object_lng_field: 'location_latitude'
        };
      }
    }
  },
  {
    type: SmartSuiteApplicationFieldType.PhoneField,
    mapping: { field: FieldType.Text }
  },
  {
    type: SmartSuiteApplicationFieldType.LinkField,
    mapping: { field: FieldType.URL }
  },
  {
    type: SmartSuiteApplicationFieldType.IpAddressField,
    mapping: { field: FieldType.Text }
  },
  {
    type: SmartSuiteApplicationFieldType.DateField,
    mapping: {
      field: FieldType.DateTime,
      getParams: (options: SmartSuiteMappingOptions) => {
        return {
          date: true,
          time: options.field.params.include_time
        };
      }
    }
  },
  {
    type: SmartSuiteApplicationFieldType.DateRangeField,
    mapping: {
      field: FieldType.JSON,
      params: {
        store_object: true,
        display_fields: true,
        structure: smartSuiteDateRangeStructure()
      }
    }
  },
  {
    type: SmartSuiteApplicationFieldType.DueDateField,
    mapping: { field: FieldType.DateTime }
  },
  {
    type: SmartSuiteApplicationFieldType.NumberField,
    mapping: {
      field: FieldType.Number,
      getParams: (options: SmartSuiteMappingOptions) => {
        return {
          value_format: {
            number_fraction: options.field.params.precision > 0 ? options.field.params.precision : 2
          }
        };
      }
    }
  },
  {
    type: SmartSuiteApplicationFieldType.AutoNumberField,
    mapping: { field: FieldType.Number }
  },
  {
    type: SmartSuiteApplicationFieldType.RatingField,
    mapping: {
      field: FieldType.Rating,
      getParams: (options: SmartSuiteMappingOptions) => {
        return {
          max_value: options.field.params.max_value
        };
      }
    }
  },
  {
    type: SmartSuiteApplicationFieldType.NumberSliderField,
    mapping: {
      field: FieldType.Slider,
      getParams: (options: SmartSuiteMappingOptions) => {
        return {
          min_value: options.field.params.min_value,
          max_value: options.field.params.max_value,
          step: options.field.params.value_increment
        };
      }
    }
  },
  {
    type: SmartSuiteApplicationFieldType.PercentCompleteField,
    mapping: {
      field: FieldType.Number,
      getParams: (options: SmartSuiteMappingOptions) => {
        return {
          value_format: {
            number_format: NumberValueFormat.Percentage,
            number_fraction: options.field.params.precision > 0 ? options.field.params.precision : 2
          }
        };
      }
    }
  },
  {
    type: SmartSuiteApplicationFieldType.YesNoField,
    mapping: { field: FieldType.Boolean }
  },
  {
    type: SmartSuiteApplicationFieldType.SingleSelectField,
    mapping: {
      field: FieldType.Select,
      getParams: (options: SmartSuiteMappingOptions) => {
        return {
          options_type: OptionsType.Static,
          ...(options.field.params.choices && {
            options: options.field.params.choices.map((item, i) => {
              return {
                value: item.value,
                name: item.label,
                color: item.value_color
              };
            })
          })
        };
      }
    }
  },
  {
    type: SmartSuiteApplicationFieldType.MultipleSelectField,
    mapping: {
      field: FieldType.MultipleSelect,
      getParams: (options: SmartSuiteMappingOptions) => {
        return {
          options_type: OptionsType.Static,
          ...(options.field.params.choices && {
            options: options.field.params.choices.map((item, i) => {
              return {
                value: item.value,
                name: item.label,
                color: item.value_color
              };
            })
          })
        };
      }
    }
  },
  {
    type: SmartSuiteApplicationFieldType.LinkedRecordField,
    mapping: {
      field: FieldType.RelatedModel,
      getField: (options: SmartSuiteMappingOptions) => {
        const application = options.field.params.linked_application
          ? options.applications.find(item => item.id == options.field.params.linked_application)
          : undefined;

        if (options.field.params.entries_allowed == 'multiple') {
          return {
            field: FieldType.MultipleSelect,
            params: {
              [SOURCE_FIELD_PARAMS]: {
                entries_allowed: options.field.params.entries_allowed
              },
              output_format: MultipleSelectOutputFormat.Array,
              options_type: OptionsType.Query,
              resource: '{{resource}}',
              query: {
                query_type: 'simple',
                simple_query: {
                  model: application.slug
                }
              },
              value_field: SMART_SUITE_ID_FIELD_NAME,
              label_field: application.primary_field
            }
          };
        } else {
          return {
            field: FieldType.RelatedModel,
            params: {
              [SOURCE_FIELD_PARAMS]: {
                entries_allowed: options.field.params.entries_allowed
              },
              ...(application && {
                related_model: {
                  model: `{{resource}}.${application.slug}`
                },
                custom_primary_key: SMART_SUITE_ID_FIELD_NAME,
                custom_display_field: application.primary_field
              })
            }
          };
        }
      }
    }
  },
  {
    type: SmartSuiteApplicationFieldType.TagsField,
    mapping: {
      field: FieldType.MultipleSelect,
      getParams: (options: SmartSuiteMappingOptions) => {
        return {
          options_type: OptionsType.Static,
          ...(options.field.params.choices && {
            options: options.field.params.choices.map((item, i) => {
              return {
                value: item.value,
                name: item.label,
                color: item.value_color
              };
            })
          })
        };
      }
    }
  },
  {
    type: SmartSuiteApplicationFieldType.StatusField,
    mapping: {
      field: FieldType.Select,
      getParams: (options: SmartSuiteMappingOptions) => {
        return {
          options_type: OptionsType.Static,
          ...(options.field.params.choices && {
            options: options.field.params.choices.map((item, i) => {
              return {
                value: item.value,
                name: item.label,
                color: item.value_color
              };
            })
          })
        };
      }
    }
  },
  {
    type: SmartSuiteApplicationFieldType.FileField,
    mapping: {
      field: FieldType.File,
      params: { storage_resource: 'demo_rest_api', storage_name: 'temporary', multiple: true }
    }
  },
  {
    type: SmartSuiteApplicationFieldType.ColorPickerField,
    mapping: { field: FieldType.Color }
  },
  {
    type: SmartSuiteApplicationFieldType.UserField,
    mapping: {
      getField: (options: SmartSuiteMappingOptions) => {
        if (options.field.params.entries_allowed == 'multiple') {
          return {
            field: FieldType.MultipleSelect,
            params: {
              [SOURCE_FIELD_PARAMS]: {
                entries_allowed: options.field.params.entries_allowed
              },
              output_format: MultipleSelectOutputFormat.Array,
              options_type: OptionsType.Query,
              resource: '{{resource}}',
              query: {
                query_type: 'simple',
                simple_query: {
                  model: SMART_SUITE_SYSTEM_APPLICATION_MEMBERS_SLUG
                }
              },
              value_field: SMART_SUITE_ID_FIELD_NAME,
              label_field: 'full_name'
            }
          };
        } else {
          return {
            field: FieldType.RelatedModel,
            params: {
              [SOURCE_FIELD_PARAMS]: {
                entries_allowed: options.field.params.entries_allowed
              },
              related_model: {
                model: `{{resource}}.${SMART_SUITE_SYSTEM_APPLICATION_MEMBERS_SLUG}`
              },
              custom_primary_key: SMART_SUITE_ID_FIELD_NAME,
              custom_display_field: 'full_name'
            }
          };
        }
      }
    }
  },
  {
    type: SmartSuiteApplicationFieldType.FullNameField,
    mapping: { field: FieldType.Text }
  },
  {
    type: SmartSuiteApplicationFieldType.RecordTitleField,
    mapping: { field: FieldType.Text }
  },
  {
    type: SmartSuiteApplicationFieldType.AvailabilityStatusField,
    mapping: { field: FieldType.JSON }
  },
  {
    type: SmartSuiteApplicationFieldType.ChecklistField,
    mapping: {
      field: FieldType.MultipleSelect,
      getParams: (options: SmartSuiteMappingOptions) => {
        return {
          options_type: OptionsType.Static,
          ...(options.field.params.choices && {
            options: options.field.params.choices.map((item, i) => {
              return {
                value: item.value,
                name: item.label,
                color: item.value_color
              };
            })
          })
        };
      }
    }
  },
  {
    type: SmartSuiteApplicationFieldType.CommentsCountField,
    mapping: { field: FieldType.Number }
  },
  {
    type: SmartSuiteApplicationFieldType.FirstCreatedField,
    mapping: {
      field: FieldType.JSON,
      params: {
        store_object: true,
        display_fields: true,
        structure: smartSuiteByStructure()
      }
    }
  },
  {
    type: SmartSuiteApplicationFieldType.LastUpdatedField,
    mapping: {
      field: FieldType.JSON,
      params: {
        store_object: true,
        display_fields: true,
        structure: smartSuiteByStructure()
      }
    }
  },
  {
    type: SmartSuiteApplicationFieldType.FormulaField,
    mapping: { field: FieldType.Text }
  },
  {
    type: SmartSuiteApplicationFieldType.CountField,
    mapping: { field: FieldType.Number }
  },
  {
    type: SmartSuiteApplicationFieldType.LookupField,
    mapping: {
      getField: (options: SmartSuiteMappingOptions) => {
        const mapping = options.field.params.target_field_structure
          ? smartSuiteFieldMapping.find(i => i.type == options.field.params.target_field_structure.field_type)
          : undefined;

        if (mapping) {
          if (mapping.mapping.getField) {
            return forceObservable(mapping.mapping.getField(options)).pipe(
              map(fieldWithParams => {
                return {
                  ...fieldWithParams,
                  params: {
                    [SOURCE_FIELD_PARAMS]: options.field.params,
                    ...fieldWithParams.params
                  }
                };
              })
            );
          } else if (mapping.mapping.getParams) {
            return forceObservable(mapping.mapping.getParams(options)).pipe(
              map(params => {
                return {
                  field: mapping.mapping.field || FieldType.Text,
                  params: {
                    [SOURCE_FIELD_PARAMS]: options.field.params,
                    ...params
                  }
                };
              })
            );
          } else {
            return {
              field: mapping.mapping.field || FieldType.Text,
              params: {
                [SOURCE_FIELD_PARAMS]: options.field.params,
                ...mapping.mapping.params
              }
            };
          }
        } else {
          return {
            field: FieldType.Text,
            params: {
              [SOURCE_FIELD_PARAMS]: options.field.params
            }
          };
        }
      }
    }
  },
  {
    type: SmartSuiteApplicationFieldType.RollupField,
    mapping: { field: FieldType.Number }
  }
];

export const defaultSmartSuiteFieldMapping: SmartSuiteFieldMapping = {
  field: FieldType.Text
};
