import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import isPlainObject from 'lodash/isPlainObject';
import toPairs from 'lodash/toPairs';
import uniq from 'lodash/uniq';
import values from 'lodash/values';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { ActionControllerService } from '@modules/action-queries';
import { ServerRequestError } from '@modules/api';
import {
  CHART_COLORS,
  DataClickEvent,
  DataGroup,
  Dataset,
  datasetGroupDateLookups,
  DatasetGroupLookup,
  datasetGroupTimeLookups
} from '@modules/charts';
import { RawListViewSettingsColumn, ViewContextOutput } from '@modules/customize';
import { DataSourceGeneratorService } from '@modules/customize-generators';
import {
  ChartType,
  ChartWidget,
  ChartWidgetGroup,
  DATASET_INDEX_OUTPUT,
  GROUP1_OUTPUT,
  GROUP2_OUTPUT,
  GROUP3_OUTPUT,
  GROUP_INDEX_OUTPUT,
  singleColorDatasetChartTypes,
  VALUE_OUTPUT
} from '@modules/dashboard';
import { WidgetDataSourceService } from '@modules/dashboard-queries';
import { ChartWidgetDataSource, DataSourceType } from '@modules/data-sources';
import { Option } from '@modules/field-components';
import { applyParamInput$, applyParamInputs$, FieldType, LOADING_VALUE, NOT_SET_VALUE } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ascComparator, controlValue, getCircleIndex, getTimezoneOffset, isSet, parseNumber } from '@shared';

import { WidgetComponent } from '../widget/widget.component';
import { barChartSampleData } from './bar-chart-sample-data';
import { bubbleSampleData } from './bubble-chart-sample-data';
import { doughnutChartSampleData } from './doughnut-chart-sample-data';
import { lineChartSampleData } from './line-chart-sample-data';
import { pieChartSampleData } from './pie-chart-sample-data';
import { radarChartSampleData } from './radar-chart-sample-data';
import { scatterSampleData } from './scatter-chart-sample-data';

interface ElementState {
  widget?: ChartWidget;
  chartType?: ChartType;
  datasets?: {
    name?: string;
    color?: string;
    format?: string;
    dataSource?: ChartWidgetDataSource;
    originalDataSourceXLookup?: DatasetGroupLookup;
    params?: Object;
    staticData?: Object;
  }[];
  groups?: ChartWidgetGroup[];
  inputsLoading?: boolean;
  inputsNotSet?: boolean;
  datasetColumn?: string;
}

export function serializeDataSourceColumns(columns: RawListViewSettingsColumn[]): Object[] {
  return columns
    .filter(item => !item.flex)
    .map(item => {
      return {
        name: item.name
      };
    })
    .sort((lhs, rhs) => ascComparator(lhs.name, rhs.name));
}

function getElementStateFetch(state: ElementState): Object {
  return {
    datasets: state.datasets
      ? state.datasets.map(dataset => {
          return {
            dataSource: dataset.dataSource
              ? {
                  ...dataset.dataSource.serialize(),
                  columns: serializeDataSourceColumns(dataset.dataSource.columns)
                }
              : undefined,
            staticData: dataset.staticData,
            params: dataset.params
          };
        })
      : undefined,
    inputsLoading: state.inputsLoading,
    inputsNotSet: state.inputsNotSet,
    datasetColumn: state.datasetColumn
  };
}

function getElementStateDisplay(state: ElementState): Object {
  return {
    datasets: state.datasets
      ? state.datasets.map(dataset => {
          return {
            dataSource: dataset.dataSource
              ? {
                  ...dataset.dataSource.serialize(),
                  columns: serializeDataSourceColumns(dataset.dataSource.columns)
                }
              : undefined,
            staticData: dataset.staticData,
            params: dataset.params,
            name: dataset.name,
            color: dataset.color,
            format: dataset.format
          };
        })
      : undefined,
    groups: state.groups ? state.groups.map(item => item.serialize()) : undefined
  };
}

function getElementStateOutputs(state: ElementState): Object {
  return {
    chartType: state.chartType,
    datasetColumn: state.datasetColumn
  };
}

function getElementStateTitle(state: ElementState): Object {
  return {
    nameInput: state.widget && state.widget.nameInput ? state.widget.nameInput.serialize() : undefined
  };
}

function getElementStateXDateLookup(state: ElementState): Object {
  return {
    datasets: state.datasets
      ? state.datasets.map(dataset => {
          const xColumn = dataset.dataSource
            ? dataset.dataSource.columns.find(item => item.name == dataset.dataSource.xColumn)
            : undefined;
          return {
            originalDataSourceXLookup: dataset.originalDataSourceXLookup,
            xColumn: xColumn ? serializeDataSourceColumns([xColumn]) : undefined
          };
        })
      : undefined
  };
}

export function datasetGroupComparator(lhsGroup: any, rhsGroup: any, groups?: ChartWidgetGroup[]): number {
  const lhsGroupIndex = groups ? groups.findIndex(item => item.value == lhsGroup) : -1;
  const rhsGroupIndex = groups ? groups.findIndex(item => item.value == rhsGroup) : -1;

  if (lhsGroupIndex === -1 && rhsGroupIndex === -1) {
    return 0;
  } else if (lhsGroupIndex !== -1 && rhsGroupIndex === -1) {
    return -1;
  } else if (lhsGroupIndex === -1 && rhsGroupIndex !== -1) {
    return 1;
  } else {
    return lhsGroupIndex - rhsGroupIndex;
  }
}

@Component({
  selector: 'app-chart-widget',
  templateUrl: './chart-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartWidgetComponent extends WidgetComponent implements OnInit, OnDestroy, OnChanges {
  @Input() widget: ChartWidget;

  widget$ = new BehaviorSubject<ChartWidget>(undefined);
  firstVisible$ = new BehaviorSubject<boolean>(false);
  elementState: ElementState = {};
  displayState$: BehaviorSubject<ElementState>;

  loadingSubscription: Subscription;
  data = new BehaviorSubject<Dataset[]>(undefined);
  datasets: Dataset[];
  title: string;
  titleSubscription: Subscription;
  loading = true;
  configured = true;
  error: string;
  chartTypes = ChartType;
  xColumnDate = false;
  xColumnHasDate = false;
  xColumnHasTime = false;
  xColumnFromControl = new FormControl();
  xColumnToControl = new FormControl();
  xLookupControl = new FormControl();
  xLookupOptions: Option<DatasetGroupLookup>[] = [
    {
      value: DatasetGroupLookup.DateDay,
      name: 'Daily'
    },
    {
      value: DatasetGroupLookup.DateWeek,
      name: 'Weekly'
    },
    {
      value: DatasetGroupLookup.DateMonth,
      name: 'Monthly'
    },
    {
      value: DatasetGroupLookup.DateQuarter,
      name: 'Quarterly'
    },
    {
      value: DatasetGroupLookup.DateYear,
      name: 'Yearly'
    },
    {
      value: DatasetGroupLookup.DateHour,
      name: 'Hourly'
    },
    {
      value: DatasetGroupLookup.DateMinute,
      name: 'Minutely'
    }
  ];

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private widgetDataSourceService: WidgetDataSourceService,
    private dataSourceGeneratorService: DataSourceGeneratorService,
    private actionControllerService: ActionControllerService,
    private notificationService: NotificationService,
    private injector: Injector,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.widgetOnChange(this.widget);
    this.trackChanges();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['widget']) {
      this.widgetOnChange(this.widget);
    }
  }

  widgetOnChange(value: ChartWidget) {
    this.widget$.next(value);
  }

  trackChanges() {
    this.firstVisible$
      .pipe(
        first(value => value),
        switchMap(() => {
          return combineLatest(
            this.widget$,
            controlValue(this.xLookupControl).pipe(distinctUntilChanged()),
            combineLatest(controlValue(this.xColumnFromControl), controlValue(this.xColumnToControl)).pipe(
              map(([xColumnFrom, xColumnTo]) => (this.xColumnDate ? [xColumnFrom, xColumnTo] : false)),
              distinctUntilChanged()
            )
          );
        }),
        switchMap(([element]) => {
          if (!element.datasets.length) {
            return of(element);
          }

          return combineLatest(
            element.datasets.map(dataset =>
              this.dataSourceGeneratorService
                .applyDataSourceDefaults<ChartWidgetDataSource>(ChartWidgetDataSource, dataset.dataSource)
                .pipe(
                  map(dataSource => {
                    dataset.dataSource = dataSource;
                    return dataset;
                  })
                )
            )
          ).pipe(map(() => element));
        }),
        switchMap(element => this.getElementState(element)),
        untilDestroyed(this)
      )
      .subscribe(state => {
        this.onStateUpdated(state);
        this.elementState = state;
      });
  }

  getElementState(widget: ChartWidget): Observable<ElementState> {
    const datasets$ = widget.datasets.length
      ? combineLatest(
          widget.datasets.map(dataset => {
            const staticData$ =
              dataset.dataSource && dataset.dataSource.type == DataSourceType.Input && dataset.dataSource.input
                ? applyParamInput$<Object>(dataset.dataSource.input, {
                    context: this.context,
                    defaultValue: {},
                    handleLoading: true,
                    ignoreEmpty: true
                  }).pipe(distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)))
                : of({});
            const params$ = dataset.dataSource
              ? applyParamInputs$({}, dataset.dataSource.queryInputs, {
                  context: this.context,
                  parameters: dataset.dataSource.queryParameters,
                  handleLoading: true,
                  ignoreEmpty: true
                }).pipe(distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)))
              : of({});

            return combineLatest(staticData$, params$);
          })
        )
      : of([]);

    return datasets$.pipe(
      map(datasets => {
        return {
          widget: widget,
          chartType: widget ? widget.chartType : undefined,
          datasets: datasets.map(([staticData, params], i) => {
            const dataset = widget.datasets[i];
            const datasetXLookup = dataset.dataSource ? dataset.dataSource.xLookup : undefined;
            let dataSource: ChartWidgetDataSource;

            if (isSet(this.xLookupControl.value)) {
              dataSource = cloneDeep(dataset.dataSource);

              if (dataSource) {
                dataSource.xLookup = this.xLookupControl.value;
              }
            } else {
              dataSource = dataset.dataSource;
            }

            if (!values(params).some(item => item === LOADING_VALUE)) {
              if (isSet(this.xColumnFromControl.value)) {
                const fromDate = moment(this.xColumnFromControl.value).utcOffset(getTimezoneOffset()).startOf('day');
                if (fromDate.isValid()) {
                  params[`${dataSource.xColumn}__gte`] = fromDate.toISOString(true);
                }
              }

              if (isSet(this.xColumnToControl.value)) {
                const toDate = moment(this.xColumnToControl.value).utcOffset(getTimezoneOffset()).endOf('day');
                if (toDate.isValid()) {
                  params[`${dataSource.xColumn}__lte`] = toDate.toISOString(true);
                }
              }
            }

            return {
              name: dataset.name,
              color: dataset.color,
              format: dataset.format,
              dataSource: dataSource,
              originalDataSourceXLookup: datasetXLookup,
              staticData: staticData,
              params: params
            };
          }),
          groups: widget.groups,
          inputsLoading: datasets.some(([staticData, params]) => {
            return [params, staticData].some(obj => {
              return obj == LOADING_VALUE || values(obj).some(item => item === LOADING_VALUE);
            });
          }),
          inputsNotSet: datasets.some(([staticData, params]) => {
            return [params, staticData].some(obj => {
              return obj == NOT_SET_VALUE || values(obj).some(item => item === NOT_SET_VALUE);
            });
          }),
          datasetColumn: widget.datasetColumnEnabled ? widget.datasetColumn : undefined
        };
      })
    );
  }

  onStateUpdated(state: ElementState) {
    if (!isEqual(getElementStateFetch(state), getElementStateFetch(this.elementState))) {
      const displayState$ = new BehaviorSubject<ElementState>(state);
      this.fetch(state, displayState$);
      this.displayState$ = displayState$;
    } else if (
      this.displayState$ &&
      !isEqual(getElementStateDisplay(state), getElementStateDisplay(this.elementState))
    ) {
      this.displayState$.next(state);
    }

    if (!isEqual(getElementStateOutputs(state), getElementStateOutputs(this.elementState))) {
      this.updateContextOutputs(state);
    }

    if (!isEqual(getElementStateTitle(state), getElementStateTitle(this.elementState))) {
      this.initTitle(state);
    }

    if (!isEqual(getElementStateXDateLookup(state), getElementStateXDateLookup(this.elementState))) {
      this.updateXDateLookup(state);
    }
  }

  fetchDatasets(state: ElementState, displayState$: BehaviorSubject<ElementState>): Observable<Dataset[]> {
    if (isSet(state.datasetColumn)) {
      const dataset = state.datasets[0];

      return combineLatest(
        this.widgetDataSourceService.group({
          project: this.currentProjectStore.instance,
          environment: this.currentEnvironmentStore.instance,
          datasetColumn: state.datasetColumn,
          dataSource: dataset.dataSource,
          params: dataset.params,
          staticData: dataset.staticData,
          context: this.context
        }),
        displayState$
      ).pipe(
        map(([result, displayState]) => {
          if (!result) {
            return [];
          }

          const resultGroups = result.reduce((acc, item) => {
            if (!acc[item.group]) {
              acc[item.group] = [];
            }

            const newItem = new DataGroup();
            newItem.group = item.group2;
            newItem.group2 = item.group3;
            newItem.value = item.value;

            acc[item.group].push(newItem);
            return acc;
          }, {});

          const avg = (items: DataGroup[]): number => {
            if (!items.length) {
              return 0;
            }
            return items.reduce((acc, item) => acc + parseNumber(item), 0) / items.length;
          };

          return toPairs<DataGroup[]>(resultGroups)
            .map(([group, items], i) => {
              const displayDataset = displayState.datasets[0];

              return {
                name: group,
                format: displayDataset.format,
                groupLookup: dataset.dataSource.xLookup,
                dataset: items
              };
            })
            .sort((lhs, rhs) => {
              return (avg(lhs.dataset) - avg(rhs.dataset)) * -1;
            });
        })
      );
    } else {
      return combineLatest(
        state.datasets.map((dataset, i) => {
          return combineLatest(
            this.widgetDataSourceService.group({
              project: this.currentProjectStore.instance,
              environment: this.currentEnvironmentStore.instance,
              datasetColumn: state.datasetColumn,
              dataSource: dataset.dataSource,
              params: dataset.params,
              staticData: dataset.staticData,
              context: this.context
            }),
            displayState$
          ).pipe(
            map(([result, displayState]) => {
              if (!result) {
                return;
              }

              const displayDataset = displayState.datasets[i];

              return {
                name: displayDataset.name,
                color: displayDataset.color,
                format: displayDataset.format,
                groupLookup: dataset.dataSource.xLookup,
                dataset: result
              };
            })
          );
        })
      );
    }
  }

  fetch(state: ElementState, displayState$: BehaviorSubject<ElementState>) {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
      this.loadingSubscription = undefined;
    }

    let datasets = state.datasets;

    if (state.datasetColumn) {
      datasets = datasets.slice(0, 1);
    }

    this.configured =
      datasets && datasets.length && datasets.every(dataset => dataset.dataSource && dataset.dataSource.isConfigured());
    this.error = undefined;
    this.loading = false;
    this.cd.markForCheck();

    if (!this.configured) {
      this.data.next(undefined);
      this.datasets = undefined;
      // TODO: Temp fix for changes not applying on create
      this.cd.detectChanges();
      return;
    }

    if (state.inputsNotSet) {
      this.data.next(undefined);
      this.datasets = undefined;
      this.loading = false;
      this.cd.markForCheck();
      return;
    }

    this.loading = true;
    this.cd.markForCheck();

    if (state.inputsLoading) {
      return;
    }

    this.loadingSubscription = combineLatest(this.fetchDatasets(state, displayState$), displayState$)
      .pipe(untilDestroyed(this))
      .subscribe(
        ([result, displayState]) => {
          const data = result.filter(item => item != undefined);

          this.data.next(data);
          this.datasets = data;

          if (isSet(state.datasetColumn) && this.isSingleColorDataset()) {
            this.datasets = this.datasets.sort((lhs, rhs) => {
              return datasetGroupComparator(lhs.name, rhs.name, displayState.groups);
            });
          } else {
            this.datasets = this.datasets.map(dataset => {
              return {
                ...dataset,
                dataset: dataset.dataset.sort((lhs, rhs) => {
                  return datasetGroupComparator(lhs.group, rhs.group, displayState.groups);
                })
              };
            });
          }

          this.datasets = this.datasets.map((dataset, i) => {
            if (isSet(state.datasetColumn) && this.isSingleColorDataset()) {
              const chartGroup = displayState.groups
                ? displayState.groups.find(item => item.value == dataset.name)
                : undefined;

              return {
                ...dataset,
                color: getCircleIndex(CHART_COLORS, i),
                ...(chartGroup && isSet(chartGroup.name) && { name: chartGroup.name }),
                ...(chartGroup && isSet(chartGroup.color) && { color: chartGroup.color })
              };
            } else {
              return {
                ...dataset,
                dataset: dataset.dataset.map(group => {
                  const chartGroup = displayState.groups
                    ? displayState.groups.find(item => item.value == group.group)
                    : undefined;
                  const newItem = new DataGroup();

                  newItem.group = chartGroup && isSet(chartGroup.name) ? chartGroup.name : group.group;
                  newItem.group2 = group.group2;
                  newItem.group3 = group.group3;
                  newItem.value = group.value;
                  newItem.color = chartGroup && isSet(chartGroup.color) ? chartGroup.color : group.color;

                  return newItem;
                })
              };
            }
          });

          this.loading = false;
          this.cd.markForCheck();
        },
        error => {
          console.error(error);
          this.data.next(undefined);
          this.datasets = undefined;
          this.loading = false;

          if (error instanceof ServerRequestError && error.errors.length) {
            this.error = error.errors[0];
          } else if (isPlainObject(error)) {
            this.error = JSON.stringify(error);
          } else if (error.hasOwnProperty('message')) {
            this.error = error.message;
          } else {
            this.error = error;
          }

          this.cd.markForCheck();
        }
      );
  }

  updateContextOutputs(state: ElementState) {
    const groupNames = [GROUP1_OUTPUT, GROUP2_OUTPUT, GROUP3_OUTPUT];
    let groupNameIndex = 0;
    const getGroupName = () => {
      return groupNames[groupNameIndex++];
    };
    const outputs: ViewContextOutput[] = [
      {
        uniqueName: DATASET_INDEX_OUTPUT,
        name: 'Dataset index',
        icon: 'number',
        fieldType: FieldType.Number,
        internal: true
      },
      {
        uniqueName: GROUP_INDEX_OUTPUT,
        name: 'Group index',
        icon: 'number',
        fieldType: FieldType.Number,
        internal: true
      }
    ];

    if (isSet(state.datasetColumn)) {
      outputs.push({
        uniqueName: getGroupName(),
        name: 'Breakdown',
        icon: 'layers',
        internal: true
      });
    }

    if ([ChartType.Line, ChartType.Bar, ChartType.StackedBar, ChartType.Scatter].includes(state.chartType)) {
      const xGroup = getGroupName();
      outputs.push(
        ...[
          {
            uniqueName: xGroup,
            name: 'X axis',
            icon: 'arrow_forward',
            internal: true
          },
          {
            uniqueName: VALUE_OUTPUT,
            name: 'Y axis',
            icon: 'arrow_up',
            fieldType: FieldType.Number,
            internal: true
          }
        ]
      );
    } else if ([ChartType.Pie, ChartType.Doughnut, ChartType.Radar, ChartType.PolarArea].includes(state.chartType)) {
      const segmentGroup = getGroupName();
      outputs.push(
        ...[
          {
            uniqueName: segmentGroup,
            name: 'Segment',
            icon: 'diagram',
            internal: true
          },
          {
            uniqueName: VALUE_OUTPUT,
            name: 'Value',
            icon: 'power_measure',
            fieldType: FieldType.Number,
            internal: true
          }
        ]
      );
    } else if ([ChartType.Bubble].includes(state.chartType)) {
      const xGroup = getGroupName();
      const yGroup = getGroupName();

      outputs.push(
        ...[
          {
            uniqueName: xGroup,
            name: 'X axis',
            icon: 'arrow_forward',
            internal: true
          },
          {
            uniqueName: yGroup,
            name: 'Y axis',
            icon: 'arrow_up',
            internal: true
          },
          {
            uniqueName: VALUE_OUTPUT,
            name: 'Size',
            icon: 'power_measure',
            fieldType: FieldType.Number,
            internal: true
          }
        ]
      );
    }

    this.contextElement.setOutputs(outputs);
  }

  initTitle(state: ElementState) {
    if (this.titleSubscription) {
      this.titleSubscription.unsubscribe();
      this.titleSubscription = undefined;
    }

    if (!state.widget.nameInput) {
      this.title = undefined;
      this.cd.markForCheck();
      return;
    }

    this.titleSubscription = applyParamInput$<string>(state.widget.nameInput, {
      context: this.context,
      defaultValue: ''
    })
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.title = value;
        this.cd.markForCheck();
      });
  }

  updateXDateLookup(state: ElementState) {
    const xLookups = uniq<DatasetGroupLookup>(
      state.datasets.filter(item => isSet(item.originalDataSourceXLookup)).map(item => item.originalDataSourceXLookup)
    );
    const xColumns = state.datasets.map(item => {
      if (!item.dataSource) {
        return;
      }
      return item.dataSource.columns.find(column => column.name == item.dataSource.xColumn);
    });
    this.xColumnDate = xLookups.length && xLookups.every(item => datasetGroupDateLookups.includes(item));
    this.xColumnHasDate = this.xColumnDate && xColumns.every(item => item && item.field == FieldType.DateTime);
    this.xColumnHasTime =
      this.xColumnDate &&
      xLookups.every(item => datasetGroupTimeLookups.includes(item)) &&
      xColumns.every(
        item => item && ((item.field == FieldType.DateTime && item.params['time']) || item.field == FieldType.Time)
      );
    this.cd.markForCheck();

    if (xLookups.length == 1) {
      this.xLookupControl.patchValue(xLookups[0]);
    }
  }

  getData$(): Observable<Dataset[]> {
    return this.data;
  }

  reloadData() {
    this.fetch(this.elementState, this.displayState$);
  }

  isSingleColorDataset(): boolean {
    return singleColorDatasetChartTypes.includes(this.widget.chartType);
  }

  onDataClick(options: DataClickEvent) {
    if (this.widget.clickAction) {
      this.actionControllerService
        .execute(this.widget.clickAction, {
          context: this.context,
          contextElement: this.contextElement,
          localContext: {
            [DATASET_INDEX_OUTPUT]: options.datasetIndex,
            [GROUP_INDEX_OUTPUT]: options.groupIndex,
            [GROUP1_OUTPUT]: options.group,
            [GROUP2_OUTPUT]: options.group2,
            [GROUP3_OUTPUT]: options.group3,
            [VALUE_OUTPUT]: options.value
          },
          injector: this.injector
        })
        .subscribe();
    }
  }

  get sampleDatasets() {
    if (this.widget.chartType == ChartType.Line) {
      return lineChartSampleData;
    } else if (this.widget.chartType == ChartType.Bar) {
      return barChartSampleData;
    } else if (this.widget.chartType == ChartType.StackedBar) {
      return barChartSampleData;
    } else if (this.widget.chartType == ChartType.Pie) {
      return pieChartSampleData;
    } else if (this.widget.chartType == ChartType.Doughnut) {
      return doughnutChartSampleData;
    } else if (this.widget.chartType == ChartType.Radar) {
      return radarChartSampleData;
    } else if (this.widget.chartType == ChartType.PolarArea) {
      return doughnutChartSampleData;
    } else if (this.widget.chartType == ChartType.Scatter) {
      return scatterSampleData;
    } else if (this.widget.chartType == ChartType.Bubble) {
      return bubbleSampleData;
    }
  }
}
