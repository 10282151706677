<app-external-fonts [fonts]="externalFonts"></app-external-fonts>

<div
  class="custom-element-view"
  [style.width.px]="!view.widthResize.enabled ? view.frame.width : null"
  [style.height.px]="!view.heightResize.enabled ? view.frame.height : null"
  [style.border-radius]="borderRadius"
  [style.opacity]="view.opacity"
  [style.box-shadow]="boxShadow"
  [style.cursor]="view.getCursor() || null"
  #view_element
>
  <app-layer-interaction
    *ngFor="let item of view.interactions"
    [interaction]="item"
    [element]="view_element"
    [context]="viewContext"
    [contextElement]="layerTokenContextElement"
  ></app-layer-interaction>

  <div
    *ngFor="let fill of fills; trackBy: trackFillFn"
    class="custom-element-view__fill"
    [class.custom-element-view__fill_inner]="fill.transform"
    [style.background]="fill.transform ? null : fill.background"
    [style.border-radius]="borderRadius"
    [style.opacity]="(fill.opacity | appIsSet) ? fill.opacity : null"
  >
    <span
      *ngIf="fill.transform"
      class="custom-element-view__fill-inner"
      [style.background]="fill.background"
      [style.width]="fill.width"
      [style.height]="fill.height"
      [style.transform]="fill.transform"
    ></span>

    <span
      *ngIf="fill.icon"
      class="custom-element-view__fill-icon"
      [style.color]="fill.icon.color"
      [style.font-size.px]="fill.icon.size"
      [ngClass]="'icon-' + fill.icon.icon"
    ></span>
  </div>

  <div
    *ngFor="let border of borders"
    class="custom-element-view__fill custom-element-view__fill_no-interaction"
    [style.border]="border.border"
    [style.top.px]="border.position"
    [style.right.px]="border.position"
    [style.bottom.px]="border.position"
    [style.left.px]="border.position"
    [style.border-radius]="border.borderRadius"
  ></div>

  <div class="custom-element-view__content">
    <app-auto-layer
      *ngFor="let item of displayItems; let i = index; trackBy: trackLayerFn"
      [layer]="item"
      [container]="view.layers"
      [frame]="view.frame"
      [viewContext]="viewContext"
    ></app-auto-layer>
  </div>
</div>
