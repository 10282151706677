import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { NotificationService } from '@common/notifications';
import { ElementItem, TableSettings, ViewContext, ViewContextElement } from '@modules/customize';
import { createFormFieldFactory } from '@modules/fields';
import { SidebarCollapseContext } from '@modules/sidebar';

import { CustomizeBarTableSettingsForm } from '../list-layouts/customize-bar-table-settings/customize-bar-table-settings.form';
import { TableGroupControl } from '../list-layouts/customize-bar-table-settings/table-group.control';

@Component({
  selector: 'app-table-groups-edit',
  templateUrl: './table-groups-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableGroupsEditComponent implements OnInit, OnDestroy {
  @Input() form: CustomizeBarTableSettingsForm;
  @Input() settings: TableSettings;
  @Input() element: ElementItem;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() addingMissing = false;

  createField = createFormFieldFactory();
  collapseContext = new SidebarCollapseContext();
  createdGroup: TableGroupControl;
  addMissingLoading = false;

  constructor(private notificationService: NotificationService, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.form.controls.groups.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.cd.markForCheck());
  }

  ngOnDestroy(): void {}

  createGroup() {
    this.createdGroup = this.form.controls.groups.appendControl();
    this.cd.markForCheck();
  }

  removeGroup(group: TableGroupControl, i) {
    // this.dialogService
    //   .warning({
    //     title: 'Delete Stage',
    //     description: `Are you sure want to delete <strong>Stage #${i + 1}</strong>?`
    //   })
    //   .pipe(untilDestroyed(this))
    //   .subscribe(result => {
    //     if (result) {
    // this.form.formArrayRemove('stages', group);
    this.form.controls.groups.removeControl(group);
    this.cd.markForCheck();
    //   }
    // });
  }

  addMissing() {
    this.addMissingLoading = true;
    this.cd.markForCheck();

    this.form
      .getMissing()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          if (!result) {
            this.notificationService.error('Failed', 'Query failed');
            return;
          }

          if (result.length == 0) {
            this.notificationService.warning('No missing values', 'All found values are already added');
          } else {
            const maxValues = 10;
            let values = result;

            if (result.length > maxValues) {
              values = values.slice(0, maxValues);
              this.notificationService.success(
                'Missing values added',
                `
                Found <strong>${result.length}</strong> missing values,
                added first <strong>${values.length}</strong> of them
              `
              );
            } else {
              this.notificationService.success(
                'Missing values added',
                `
                <strong>${values.length}</strong> values were added
              `
              );
            }

            this.form.controls.groups.addMissingByValues(values);
          }

          this.addMissingLoading = false;
          this.cd.markForCheck();
        },
        () => {
          this.addMissingLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  dragDrop(event: CdkDragDrop<TableGroupControl[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.form.controls.groups.controls, event.previousIndex, event.currentIndex);
      this.form.controls.groups.updateValueAndValidity();
    }
  }
}
