import pickBy from 'lodash/pickBy';

import { Input } from '@modules/fields';

import { ViewSettingsAction } from '../../view-settings-action';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
import { ResizableElementItem } from './widget';

export enum AlertTint {
  Default = 'default',
  Info = 'info',
  Danger = 'danger',
  Warning = 'warning',
  Success = 'success',
  Special = 'special'
}

export enum AlertStyle {
  Fill = 'fill',
  Border = 'border'
}

export class AlertElementItem extends ElementItem implements ResizableElementItem {
  public type = ElementType.Alert;
  public tint: AlertTint = AlertTint.Default;
  public color: string;
  public style: AlertStyle = AlertStyle.Fill;
  public title: Input;
  public message: Input;
  public icon: string;
  public actions: ViewSettingsAction[] = [];
  public width: number;
  public height: number;

  deserialize(data: Object): AlertElementItem {
    super.deserialize(data);
    this.color = this.params['color'];
    this.icon = this.params['icon'];
    this.width = this.params['width'];
    this.height = this.params['height'];

    if (this.params['tint']) {
      this.tint = this.params['tint'];
    }

    if (this.params['style']) {
      this.style = this.params['style'];
    }

    if (this.params['title']) {
      this.title = new Input().deserialize(this.params['title']);
    }

    if (this.params['message']) {
      this.message = new Input().deserialize(this.params['message']);
    }

    if (this.params['actions']) {
      this.actions = this.params['actions'].map(item => new ViewSettingsAction().deserialize(item));
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = {};
    this.params['tint'] = this.tint;
    this.params['color'] = this.color;
    this.params['style'] = this.style;
    this.params['title'] = this.title ? this.title.serialize() : null;
    this.params['message'] = this.message ? this.message.serialize() : null;
    this.params['icon'] = this.icon;
    this.params['width'] = this.width;
    this.params['height'] = this.height;
    this.params['actions'] = this.actions.map(item => item.serialize());

    let data = super.serialize();
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  get typeStr(): string {
    return 'alert';
  }

  get analyticsName(): string {
    return 'alert';
  }

  defaultName() {
    return 'Alert';
  }
}

registerElementForType(ElementType.Alert, AlertElementItem);
