var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import pickBy from 'lodash/pickBy';
import { ActionItem } from '@modules/actions';
import { Input } from '@modules/fields';
export var TintStyle;
(function (TintStyle) {
    TintStyle["Default"] = "default";
    TintStyle["Primary"] = "primary";
    TintStyle["Transparent"] = "transparent";
})(TintStyle || (TintStyle = {}));
var ViewSettingsAction = /** @class */ (function (_super) {
    __extends(ViewSettingsAction, _super);
    function ViewSettingsAction() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.visible = true;
        _this.pinned = false;
        _this.style = TintStyle.Primary;
        return _this;
    }
    ViewSettingsAction.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.icon = data['icon'];
        this.order = data['order'];
        if (data['style'] != undefined) {
            this.style = data['style'];
        }
        if (this.style == TintStyle.Default && data['color_text']) {
            // Backward compatibility
            this.tint = data['color_text'];
        }
        else if (this.style == TintStyle.Primary && data['color']) {
            // Backward compatibility
            this.tint = data['color'];
        }
        else if (this.style == TintStyle.Transparent && data['color_text']) {
            // Backward compatibility
            this.tint = data['color_text'];
        }
        else {
            this.tint = data['tint'];
        }
        if (data['visible'] != undefined) {
            this.visible = data['visible'];
        }
        if (data['pinned'] != undefined) {
            this.pinned = data['pinned'];
        }
        if (data['disabled_input']) {
            this.disabledInput = new Input().deserialize(data['disabled_input']);
        }
        if (data['visible_input']) {
            this.visibleInput = new Input().deserialize(data['visible_input']);
        }
        return this;
    };
    ViewSettingsAction.prototype.serialize = function (fields) {
        var data = __assign({}, _super.prototype.serialize.call(this, fields), { style: this.style, visible: this.visible, pinned: this.pinned, tint: this.tint, icon: this.icon, order: this.order, disabled_input: this.disabledInput ? this.disabledInput.serialize() : null, visible_input: this.visibleInput ? this.visibleInput.serialize() : null });
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    return ViewSettingsAction;
}(ActionItem));
export { ViewSettingsAction };
