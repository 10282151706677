import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { LocalStorage } from '@core';
import { ActionType } from '@modules/actions';
import { CurrentEnvironmentStore, CurrentProjectStore, ResourceName, resourceTypeItems } from '@modules/projects';
import { ConditionWorkflowStepType, WorkflowStepType } from '@modules/workflow';
import { deployUrl, objectsSortPredicate } from '@shared';
export var AddStepOption;
(function (AddStepOption) {
    AddStepOption["CreateResource"] = "create_resource";
    AddStepOption["AddStep"] = "add_step";
})(AddStepOption || (AddStepOption = {}));
var AddStepDropdownComponent = /** @class */ (function () {
    function AddStepDropdownComponent(currentProjectStore, currentEnvironmentStore, localStorage, cd) {
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.localStorage = localStorage;
        this.cd = cd;
        this.buttonClick = new EventEmitter();
        this.items = [];
    }
    AddStepDropdownComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currentEnvironmentStore.resources$
            .pipe(untilDestroyed(this))
            .subscribe(function (resourcesAll) { return _this.updateItems(resourcesAll); });
    };
    AddStepDropdownComponent.prototype.ngOnDestroy = function () { };
    AddStepDropdownComponent.prototype.getResourceItems = function (resourcesAll, type, actionType) {
        var _this = this;
        var resourceTypes = resourceTypeItems.filter(function (item) { return !item.hidden && !item.protected && _this.isResourceEnabled(item); });
        var userResourcesKey = "last_used_resources_" + this.currentProjectStore.uniqueName;
        var localStorageResources = this.localStorage.get(userResourcesKey);
        var lastUsedResources = localStorageResources ? JSON.parse(localStorageResources) : {};
        var defaultPredicate = objectsSortPredicate('demo', 'name');
        var resources = resourcesAll
            ? resourcesAll
                .filter(function (item) { return _this.isResourceEnabled(item.typeItem, item); })
                .sort(function (lhs, rhs) {
                var lhsLastUsed = lastUsedResources[lhs.uniqueName]
                    ? moment(lastUsedResources[lhs.uniqueName])
                    : undefined;
                var rhsLastUsed = lastUsedResources[rhs.uniqueName]
                    ? moment(lastUsedResources[rhs.uniqueName])
                    : undefined;
                if (!lhsLastUsed && !rhsLastUsed) {
                    return defaultPredicate(lhs, rhs);
                }
                else if (lhsLastUsed && !rhsLastUsed) {
                    return -1;
                }
                else if (!lhsLastUsed && rhsLastUsed) {
                    return 1;
                }
                else if (lhsLastUsed && rhsLastUsed) {
                    return (lhsLastUsed.unix() - rhsLastUsed.unix()) * -1;
                }
            })
            : [];
        var userResources = resources.filter(function (item) { return !item.demo; });
        var demoResources = resources.filter(function (item) { return item.demo && item.featured; });
        return userResources.length
            ? [
                {
                    button: {
                        icon: 'plus',
                        label: 'Connect Resource'
                    },
                    children: resourceTypes.map(function (item) {
                        return {
                            button: {
                                name: AddStepOption.CreateResource,
                                label: item.label,
                                image: deployUrl("/assets/images/resources/icons/" + item.icon + ".svg"),
                                data: { type: type, actionType: actionType, resourceTypeItem: item }
                            }
                        };
                    }),
                    stickyTop: true,
                    orange: true
                }
            ].concat(userResources.map(function (item) {
                return {
                    // option: {
                    //   value: item.uniqueName,
                    //   name: item.name,
                    //   image: item.icon
                    // }
                    button: {
                        name: AddStepOption.AddStep,
                        label: item.name,
                        image: item.icon,
                        data: { type: type, actionType: actionType, resource: item }
                    }
                };
            })
            // {
            //   button: {
            //     icon: 'play_2',
            //     label: 'Demo'
            //   },
            //   children: demoResources.map(item => {
            //     return {
            //       // option: {
            //       //   value: item.uniqueName,
            //       //   name: item.name,
            //       //   image: item.icon
            //       // },
            //       button: {
            //         name: AddStepOption.AddStep,
            //         label: item.name,
            //         image: item.icon,
            //         data: { type: type, actionType: actionType, resource: item }
            //       },
            //       valueTag: 'DEMO'
            //     };
            //   }),
            //   stickyBottom: true,
            //   large: true
            // }
            ) : resourceTypes.map(function (item) {
            return {
                button: {
                    name: AddStepOption.CreateResource,
                    label: item.label,
                    image: deployUrl("/assets/images/resources/icons/" + item.icon + ".svg"),
                    data: { type: type, actionType: actionType, resourceTypeItem: item }
                },
                subtitle: 'Connect Resource'
            };
        }).slice();
    };
    AddStepDropdownComponent.prototype.updateItems = function (resourcesAll) {
        var _this = this;
        var actionOptions = (!this.actionTypesEnabled || this.actionTypesEnabled.includes(ActionType.Query)
            ? [
                {
                    label: 'Run Operation',
                    icon: 'cloud_upload',
                    children: this.getResourceItems(resourcesAll, WorkflowStepType.Action, ActionType.Query)
                }
            ]
            : []).concat([
            {
                label: 'Condition',
                icon: 'workflow',
                children: [
                    {
                        button: {
                            label: 'Yes/No',
                            icon: 'switch',
                            name: AddStepOption.AddStep,
                            data: { type: WorkflowStepType.Condition, conditionType: ConditionWorkflowStepType.Boolean }
                        }
                    },
                    {
                        button: {
                            label: 'Switch',
                            icon: 'versions',
                            name: AddStepOption.AddStep,
                            data: { type: WorkflowStepType.Condition, conditionType: ConditionWorkflowStepType.Switch }
                        }
                    }
                ]
            },
            {
                label: 'Parallelize',
                icon: 'matches',
                name: AddStepOption.AddStep,
                data: { type: WorkflowStepType.Fork }
            },
            {
                label: 'Iterator',
                icon: 'repeat',
                name: AddStepOption.AddStep,
                data: { type: WorkflowStepType.Iterator }
            },
            {
                label: 'Transform',
                icon: 'reflect',
                name: AddStepOption.AddStep,
                data: { type: WorkflowStepType.Transform }
            },
            {
                label: 'Delay',
                icon: 'time',
                name: AddStepOption.AddStep,
                data: { type: WorkflowStepType.Delay }
            },
            {
                label: 'Exit',
                icon: 'log_out',
                name: AddStepOption.AddStep,
                data: { type: WorkflowStepType.Exit }
            },
            {
                label: 'Navigate to Page',
                icon: 'redo',
                name: AddStepOption.AddStep,
                data: { type: WorkflowStepType.Action, actionType: ActionType.Link }
            },
            {
                label: 'Open URL',
                icon: 'model_link',
                name: AddStepOption.AddStep,
                data: { type: WorkflowStepType.Action, actionType: ActionType.ExternalLink }
            },
            {
                label: 'Open Modal',
                icon: 'copy',
                name: AddStepOption.AddStep,
                data: { type: WorkflowStepType.Action, actionType: ActionType.OpenPopup }
            },
            {
                label: 'Close Modal',
                icon: 'windows',
                name: AddStepOption.AddStep,
                data: { type: WorkflowStepType.Action, actionType: ActionType.ClosePopup }
            },
            {
                label: 'Run Component action',
                icon: 'components',
                name: AddStepOption.AddStep,
                data: { type: WorkflowStepType.Action, actionType: ActionType.ElementAction }
            },
            {
                label: 'Show Notification',
                icon: 'notification',
                name: AddStepOption.AddStep,
                data: { type: WorkflowStepType.Action, actionType: ActionType.ShowNotification }
            },
            {
                label: 'Set Variable',
                icon: 'variable',
                name: AddStepOption.AddStep,
                data: { type: WorkflowStepType.Action, actionType: ActionType.SetProperty }
            },
            {
                label: 'Run JavaScript',
                icon: 'console',
                name: AddStepOption.AddStep,
                data: { type: WorkflowStepType.Action, actionType: ActionType.RunJavaScript }
            },
            {
                label: 'Copy to Clipboard',
                icon: 'documents',
                name: AddStepOption.AddStep,
                data: { type: WorkflowStepType.Action, actionType: ActionType.CopyToClipboard }
            },
            {
                label: 'Export Data',
                icon: 'download',
                name: AddStepOption.AddStep,
                data: { type: WorkflowStepType.Action, actionType: ActionType.Export }
            },
            {
                label: 'Import Data',
                icon: 'upload',
                name: AddStepOption.AddStep,
                data: { type: WorkflowStepType.Action, actionType: ActionType.Import }
            }
        ], (!this.actionTypesEnabled || this.actionTypesEnabled.includes(ActionType.Download)
            ? [
                {
                    label: 'Download File',
                    icon: 'save',
                    children: this.getResourceItems(resourcesAll, WorkflowStepType.Action, ActionType.Download)
                }
            ]
            : []), [
            {
                label: 'Scan QR/Bar code',
                icon: 'qr_code',
                name: AddStepOption.AddStep,
                data: { type: WorkflowStepType.Action, actionType: ActionType.ScanCode }
            }
        ]);
        this.items = actionOptions
            .filter(function (item) {
            if (item.name == AddStepOption.AddStep && item.data) {
                var type = item.data['type'];
                var actionType = item.data['actionType'];
                if (type == WorkflowStepType.Action) {
                    return !_this.actionTypesEnabled || _this.actionTypesEnabled.includes(actionType);
                }
            }
            return true;
        })
            .map(function (item) {
            return {
                button: {
                    name: item.name,
                    label: item.label,
                    icon: item.icon,
                    data: item.data
                },
                children: item.children
            };
        });
        this.cd.markForCheck();
    };
    AddStepDropdownComponent.prototype.isResourceEnabled = function (typeItem, resource) {
        if (!typeItem) {
            return false;
        }
        if ((resource && (resource.isSynced() || resource.hasCollectionSync())) || (!resource && typeItem.syncEnabled)) {
            typeItem = resourceTypeItems.find(function (item) { return item.name == ResourceName.PostgreSQL; });
        }
        // Storages are implemented on frontend only
        if (!this.actionTypesEnabled || this.actionTypesEnabled.includes(ActionType.ElementAction)) {
            return typeItem.actionsEnabled || typeItem.storagesEnabled;
        }
        else {
            return typeItem.actionsEnabled;
        }
    };
    AddStepDropdownComponent.prototype.updateLastUsed = function (resourceName) {
        var userResourcesKey = "last_used_resources_" + this.currentProjectStore.uniqueName;
        var resources = this.localStorage.get(userResourcesKey);
        var lastUsedResources = resources ? JSON.parse(resources) : {};
        lastUsedResources[resourceName] = moment().toISOString();
        this.localStorage.set(userResourcesKey, JSON.stringify(lastUsedResources));
        this.updateItems(this.currentEnvironmentStore.resources);
    };
    AddStepDropdownComponent.prototype.onButtonClick = function (button) {
        if (button.name == AddStepOption.AddStep && button.data['resource']) {
            this.updateLastUsed(button.data['resource'].uniqueName);
        }
        this.buttonClick.emit(button);
    };
    return AddStepDropdownComponent;
}());
export { AddStepDropdownComponent };
