import { Injectable, Injector } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { PopupDynamicComponentArguments, PopupService } from '@common/popups';
import { TimelinePopupComponent, UserActivitiesController } from '@modules/activities-components';
import { CustomizeService, ViewSettings, ViewSettingsStore } from '@modules/customize';
import { applyParamInputs } from '@modules/fields';
import { MenuContext } from '@modules/menu-components';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { ThemeService } from '@modules/theme';
import { controlValue, isAbsoluteUrl, isSet } from '@shared';

// TODO: Refactor import
import { MenuItemActionControl } from '../../../layout-routes/components/customize-bar-pages-edit/menu-item-action.control';

import {
  getMenuItemSystemActionTypeInfo,
  MenuItemAction,
  MenuItemActionType,
  MenuItemSystemActionType
} from '../../data/menu-item-action';

@Injectable()
export class MenuItemActionService {
  timelinePopup: PopupDynamicComponentArguments;
  activityLogPopup: PopupDynamicComponentArguments;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private viewSettingsStore: ViewSettingsStore,
    private themeService: ThemeService,
    private routing: RoutingService,
    private userActivitiesController: UserActivitiesController,
    private customizeService: CustomizeService,
    private popupService: PopupService,
    private injector: Injector
  ) {}

  getActionExecution(
    action: MenuItemAction,
    options: { context?: MenuContext } = {}
  ): Observable<{ link?: any[]; href?: string; queryParams?: Object; handler?: () => void }> {
    if (!action) {
      return of({});
    }

    const project = this.currentProjectStore.instance;
    const environment = this.currentEnvironmentStore.instance;

    if (action.type == MenuItemActionType.Page) {
      if (!project.hasEnvironmentPagePermission(environment, action.pageUid, 'r')) {
        return of({});
      }

      return this.viewSettingsStore.getDetailByUid(action.pageUid).pipe(
        map(page => {
          const params = applyParamInputs({}, action.inputs, {
            context: options.context,
            parameters: page ? page.parameters : undefined
          });

          return {
            link: page ? this.routing.appLink(page.link) : undefined,
            queryParams: params
            // link: result ? result.link : undefined
          };
        })
      );
    } else if (action.type == MenuItemActionType.URL) {
      if (!isSet(action.url)) {
        return of({});
      }

      if (isAbsoluteUrl(action.url)) {
        return of({
          href: action.url
        });
      } else {
        return of({
          link: [action.url]
        });
      }
    } else if (action.type == MenuItemActionType.System) {
      if (action.systemType == MenuItemSystemActionType.Home) {
        return of({
          link: this.routing.appLink(this.currentProjectStore.instance.homeLink)
          // link: this.currentProjectStore.instance.homeLink
        });
      } else if (action.systemType == MenuItemSystemActionType.Profile) {
        return of({
          link: ['/profile/update']
        });
      } else if (action.systemType == MenuItemSystemActionType.Users) {
        if (!project.hasEnvironmentAccessPermission(environment)) {
          return of({});
        }

        return of({
          link: this.routing.appLink(this.currentProjectStore.instance.settingsUsersLink)
          // link: this.currentProjectStore.instance.settingsUsersLink
        });
      } else if (action.systemType == MenuItemSystemActionType.ActivityLog) {
        return of({
          handler: () => {
            this.openActivityLogPopup();
          }
        });
      } else if (action.systemType == MenuItemSystemActionType.ActivityLogPage) {
        return of({
          link: this.routing.appLink(this.currentProjectStore.instance.settingsUserActivitiesLink)
          // link: this.currentProjectStore.instance.settingsUserActivitiesLink
        });
      } else if (action.systemType == MenuItemSystemActionType.Collaboration) {
        return of({
          handler: () => {
            this.openTimelinePopup();
          }
        });
      } else if (action.systemType == MenuItemSystemActionType.CollaborationTasksPage) {
        return of({
          link: this.routing.appLink(this.currentProjectStore.instance.settingsCollaborationLink('tasks'))
          // link: this.currentProjectStore.instance.settingsCollaborationLink('tasks')
        });
      } else if (action.systemType == MenuItemSystemActionType.CollaborationMessagesPage) {
        return of({
          link: this.routing.appLink(this.currentProjectStore.instance.settingsCollaborationLink('messages'))
          // link: this.currentProjectStore.instance.settingsCollaborationLink('messages')
        });
      } else if (action.systemType == MenuItemSystemActionType.ToggleTheme) {
        return of({
          handler: () => {
            this.themeService.toggleTheme();
          }
        });
      } else if (action.systemType == MenuItemSystemActionType.Logout) {
        return of({
          link: ['/logout']
        });
      }
    }

    return of({});
  }

  getActionValueDisplay$(
    control: MenuItemActionControl,
    options: { excludeUrl?: boolean } = {}
  ): Observable<{ label: string; labelShort?: string; icon?: string }> {
    return combineLatest(
      controlValue<MenuItemActionType>(control.controls.type),
      controlValue<string>(control.controls.page_uid),
      controlValue<string>(control.controls.url),
      controlValue<MenuItemSystemActionType>(control.controls.system_type)
    ).pipe(
      switchMap(([type, pageUid, url, systemType]) => {
        if (type == MenuItemActionType.Page) {
          return this.viewSettingsStore.getDetailByUid<ViewSettings>(pageUid).pipe(
            map(page => {
              if (!page) {
                return {
                  label: 'Open page (not selected)',
                  labelShort: 'Open page',
                  icon: 'document'
                };
              }

              return {
                label: `Open page - ${page.name}`,
                labelShort: `/${page.uniqueName}`,
                icon: 'document'
              };
            })
          );
        } else if (type == MenuItemActionType.URL) {
          return of({
            label: options.excludeUrl || !isSet(url) ? 'Open URL' : `Open URL - ${url}`,
            labelShort: options.excludeUrl ? 'Open URL' : url,
            icon: 'external_link'
          });
        } else if (type == MenuItemActionType.System) {
          if (!isSet(systemType)) {
            return of({
              label: 'Built-in action',
              icon: 'gear'
            });
          }

          const info = getMenuItemSystemActionTypeInfo(systemType);

          return of({
            label: info.actionLabel,
            labelShort: info.label,
            icon: info.icon
          });
        }

        return of(undefined);
      })
    );
  }

  openTimelinePopup(): void {
    if (this.popupService.items.find(item => item === this.timelinePopup)) {
      return;
    }

    const handler = this.customizeService.handler;
    const params = handler && handler.getCollaborationParams ? handler.getCollaborationParams() : {};

    this.timelinePopup = this.popupService.push({
      component: TimelinePopupComponent,
      disablePointerEvents: true,
      enableWindowScroll: true,
      inputs: { baseParams: params },
      injector: this.injector
    });
  }

  openActivityLogPopup(): void {
    if (this.popupService.items.find(item => item === this.activityLogPopup)) {
      return;
    }

    const handler = this.customizeService.handler;
    const params = handler && handler.getUserActivitiesParams ? handler.getUserActivitiesParams() : {};

    this.activityLogPopup = this.userActivitiesController.open(params);
  }
}
