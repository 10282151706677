import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChange,
  ViewChild
} from '@angular/core';
import toPairs from 'lodash/toPairs';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, fromEvent, Subject } from 'rxjs';
import { debounceTime, filter, map, startWith } from 'rxjs/operators';

import { DynamicComponent, DynamicComponentArguments } from '@common/dynamic-component';
import { BasePopupComponent, PopupService } from '@common/popups';
import { ActionService, WorkflowExecuteEvent, WorkflowExecuteEventType } from '@modules/action-queries';
import { ActionType } from '@modules/actions';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { SUBMIT_RESULT_OUTPUT, ViewContext, ViewContextElement } from '@modules/customize';
import { WorkflowRun, WorkflowStep, WorkflowStepRun } from '@modules/workflow';
import { isControlElement, isSet, KeyboardEventKeyCode, TypedChanges } from '@shared';

import { getCustomizeWorkflowStepComponent } from '../../../data/customize-workflow-step-components';
import { getWorkflowStepComponent } from '../../../data/workflow-step-components';
import { CustomizeBarContext } from '../../../services/customize-bar-context/customize-bar.context';
import { WorkflowEditContext } from '../../../services/workflow-edit-context/workflow-edit.context';
import { CustomizeWorkflowStepComponent } from '../customize-steps/base-customize-workflow-step/base-customize-workflow-step.component';
import { WorkflowStepComponent } from '../steps/base-workflow-step/base-workflow-step.component';

const markWorkflowElementClickProperty = '_markWorkflowElementClickProperty';

export function markWorkflowElementClick(clickEvent: MouseEvent) {
  clickEvent[markWorkflowElementClickProperty] = true;
}

export function isWorkflowElementClick(clickEvent: MouseEvent) {
  return !!clickEvent[markWorkflowElementClickProperty];
}

@Component({
  selector: 'app-auto-workflow-step',
  templateUrl: './auto-workflow-step.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoWorkflowStepComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() step: WorkflowStep;
  @Input() prevStep: WorkflowStep;
  @Input() workflowEditable = false;
  @Input() index: number;
  @Input() context: ViewContext;
  @Input() actionTypesEnabled: ActionType[];
  @Input() analyticsSource: string;
  @Output() stepAddBefore = new EventEmitter<WorkflowStep>();
  @Output() stepDuplicate = new EventEmitter<void>();
  @Output() stepDelete = new EventEmitter<void>();

  @ViewChild(DynamicComponent) dynamicComponent: DynamicComponent<WorkflowStepComponent>;

  @HostBinding('attr.data-step-id') get stepId() {
    return this.step.uid;
  }

  @HostBinding('attr.data-step-name') get stepName() {
    return this.step.name;
  }

  componentData: DynamicComponentArguments<WorkflowStepComponent>;
  customizeComponentData$ = new BehaviorSubject<DynamicComponentArguments>(undefined);
  customizing$ = new BehaviorSubject<boolean>(false);
  loadingExecute = false;

  constructor(
    private customizeBarContext: CustomizeBarContext,
    private workflowEditContext: WorkflowEditContext,
    private actionService: ActionService,
    private popupService: PopupService,
    @Optional() private popupComponent: BasePopupComponent,
    private cd: ChangeDetectorRef,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnInit() {
    this.initComponent();

    combineLatest(this.customizeComponentData$, this.customizeBarContext.settingsComponents$)
      .pipe(
        debounceTime(10),
        map(([customizeComponentData, components]) => {
          return customizeComponentData && components[0] === customizeComponentData;
        }),
        startWith(false)
      )
      .pipe(untilDestroyed(this))
      .subscribe(value => this.customizing$.next(value));

    fromEvent<KeyboardEvent>(document, 'keydown')
      .pipe(
        filter(() => {
          return (
            this.customizing$.value &&
            this.popupService.last() === this.popupComponent.data &&
            !isControlElement(document.activeElement)
          );
        }),
        untilDestroyed(this)
      )
      .subscribe(e => {
        if (e.keyCode == KeyboardEventKeyCode.Escape) {
          this.closeCustomize();
        } else if (e.keyCode == KeyboardEventKeyCode.Backspace) {
          if (this.workflowEditable) {
            this.delete();
          }
        }
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<AutoWorkflowStepComponent>): void {
    if ([changes.step, changes.index].some(item => item && !item.firstChange)) {
      if (
        changes.step &&
        (changes.step.previousValue ? changes.step.previousValue.type : undefined) !==
          (changes.step.currentValue ? changes.step.currentValue.type : undefined)
      ) {
        this.initComponent();
      } else {
        this.updateComponent({ closeCustomize: true });
      }
    }
  }

  ngAfterViewInit(): void {
    const createdElement = this.workflowEditContext.initCreatedElement(this.step);

    if (createdElement) {
      this.customize({ contextElement: createdElement.contextElement });
    }
  }

  getComponentInputs(): Partial<WorkflowStepComponent> {
    return {
      step: this.step,
      prevStep: this.prevStep,
      workflowEditable: this.workflowEditable,
      index: this.index,
      context: this.context,
      customizing$: this.customizing$,
      actionTypesEnabled: this.actionTypesEnabled,
      analyticsSource: this.analyticsSource
    };
  }

  initComponent() {
    const component = getWorkflowStepComponent(this.step.type);

    if (!component) {
      this.componentData = undefined;
      this.cd.markForCheck();
      console.error(`No such step type registered: ${this.step.type}`);
      return;
    }

    this.componentData = {
      component: component,
      inputs: this.getComponentInputs(),
      outputs: {
        stepAddBefore: [
          step => {
            this.stepAddBefore.emit(step);
          }
        ],
        stepCustomize: [
          (e?: { contextElement?: ViewContextElement }) => {
            this.customize({ contextElement: e ? e.contextElement : undefined });
          }
        ],
        stepExecute: [
          () => {
            this.execute();
          }
        ],
        stepDuplicate: [
          () => {
            this.duplicate();
          }
        ],
        stepDelete: [
          () => {
            this.delete();
          }
        ]
      }
    };
    this.cd.markForCheck();
  }

  updateComponent(options: { forcePropUpdate?: keyof WorkflowStepComponent; closeCustomize?: boolean } = {}) {
    if (
      !this.dynamicComponent ||
      !this.dynamicComponent.currentComponent ||
      !this.dynamicComponent.currentComponent.instance
    ) {
      return;
    }

    const ref = this.dynamicComponent.currentComponent;
    const inputs = this.getComponentInputs();
    const changes: TypedChanges<WorkflowStepComponent> = toPairs(inputs).reduce((acc, [prop, currentValue]) => {
      const prevValue = ref.instance[prop];

      if ((isSet(options.forcePropUpdate) && prop == options.forcePropUpdate) || prevValue !== currentValue) {
        acc[prop] = new SimpleChange(prevValue, currentValue, false);
        ref.instance[prop] = currentValue;
      }

      return acc;
    }, {});

    this.componentData.inputs = inputs;

    if (values(changes).length) {
      if (ref.instance['ngOnChanges']) {
        ref.instance['ngOnChanges'](changes);
      }

      ref.changeDetectorRef.markForCheck();

      if (options.closeCustomize && this.customizeComponentData$.value) {
        this.closeCustomize();
      }
    }
  }

  duplicate() {
    this.closeCustomize();
    this.stepDuplicate.emit();
  }

  delete() {
    this.closeCustomize();
    this.stepDelete.emit();
  }

  customize(options: { contextElement?: ViewContextElement } = {}) {
    if (this.customizing$.value) {
      return;
    }

    const component = getCustomizeWorkflowStepComponent(this.step.type);

    if (!component) {
      return;
    }

    const dynamicComponent: DynamicComponentArguments<CustomizeWorkflowStepComponent> = {
      component: component,
      inputs: {
        step: this.step,
        workflowEditable: this.workflowEditable,
        context: this.context,
        contextElement: options.contextElement
      },
      outputs: {
        stepChange: [
          result => {
            this.onCustomized(result);
          }
        ],
        stepDuplicate: [
          () => {
            this.duplicate();
          }
        ],
        stepDelete: [
          () => {
            this.delete();
          }
        ],
        stepExecute: [
          () => {
            this.execute();
          }
        ],
        closeCustomize: [
          () => {
            this.customizeBarContext.closeSettingsComponent(dynamicComponent);
          }
        ]
      }
    };

    this.customizeBarContext.setSettingsComponent(dynamicComponent);
    this.customizeComponentData$.next(dynamicComponent);
  }

  updateStepRun(stepRun?: WorkflowStepRun) {
    const existingRun = this.workflowEditContext.run$.value;
    const run = new WorkflowRun();

    if (existingRun) {
      run.stepRuns = existingRun.stepRuns.filter(item => item.uid != this.step.uid);
    }

    if (stepRun) {
      run.stepRuns.push(stepRun);
    }

    run.resultStepRun = existingRun ? existingRun.resultStepRun : undefined;

    this.workflowEditContext.run$.next(run);
  }

  execute() {
    this.loadingExecute = true;
    this.cd.markForCheck();

    const event$ = new Subject<WorkflowExecuteEvent>();

    event$.pipe(untilDestroyed(this)).subscribe(event => {
      this.workflowEditContext.testExecuteEvents$.next(event);

      if (event.type == WorkflowExecuteEventType.StepStarted) {
        this.updateStepRun();
      } else if (event.type == WorkflowExecuteEventType.StepFinished) {
        const run = new WorkflowStepRun();

        run.uid = this.step.uid;
        run.params = event.params;
        run.result = event.result;
        run.error = event.error;

        this.updateStepRun(run);
      }
    });

    this.actionService
      .executeWorkflowSteps([this.step], event$, {
        context: this.context,
        showSuccess: true,
        showError: true,
        delayBefore: 200,
        disableRouting: true,
        disablePopups: true
      })
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.loadingExecute = false;
          this.cd.markForCheck();

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.WorkflowBuilder.StepTest, {
            Success: true,
            Step: this.step.analyticsName,
            Source: this.analyticsSource
          });
        },
        error => {
          this.loadingExecute = false;
          this.cd.markForCheck();

          let errorMessage: string;

          if (error instanceof ServerRequestError && error.nonFieldErrors.length) {
            errorMessage = error.nonFieldErrors[0];
          } else {
            errorMessage = 'Unknown error';
          }

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.WorkflowBuilder.StepTest, {
            Success: false,
            Error: errorMessage,
            Step: this.step.analyticsName,
            Source: this.analyticsSource
          });
        }
      );
  }

  onCustomized(step: WorkflowStep) {
    this.step.deserialize(step.serialize());
    this.updateComponent({ forcePropUpdate: 'step' });
    this.workflowEditContext.markChanged();
  }

  closeCustomize() {
    if (this.customizing$.value && this.customizeComponentData$.value) {
      this.customizeBarContext.closeSettingsComponent(this.customizeComponentData$.value);
      this.customizeComponentData$.next(undefined);
    }
  }

  @HostListener('click', ['$event']) onClick(e: MouseEvent) {
    markWorkflowElementClick(e);
  }
}
