export enum InputValueType {
  Prompt = 'prompt',
  StaticValue = 'static_value',
  EmptyString = 'empty_string',
  Null = 'null',
  Filter = 'filter',
  Context = 'context',
  Formula = 'formula',
  Js = 'js'
}
