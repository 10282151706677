import { HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import fromPairs from 'lodash/fromPairs';
import toPairs from 'lodash/toPairs';

import { FieldType, parseFieldType } from '@modules/fields';
import { Model, ModelDescription } from '@modules/models';
import { isSet } from '@shared';

export interface ColumnsDescription {
  [k: string]: { field?: FieldType };
}

export namespace ModelResponse {
  @Injectable()
  export class GetResponse {
    public results: Model[];
    public next: string;
    public previous: string;
    public count: number;
    public hasMore: boolean;
    public numPages: number;
    public perPage: number;
    public cursorPrev: any;
    public cursorNext: any;

    constructor(private injector: Injector) {}

    createModel(): Model {
      return Injector.create({
        providers: [{ provide: Model, deps: [Injector] }],
        parent: this.injector
      }).get<Model>(Model);
    }

    deserialize(data: Object, model?: string, modelDescription?: ModelDescription) {
      this.results = data['results'].map((item, i) => {
        const relations = (data['relations'] || [])[i];
        const instance = this.createModel().deserialize(model, item, relations);
        if (modelDescription) {
          instance.setUp(modelDescription);
        }
        return instance;
      });
      this.next = data['next'];
      this.previous = data['previous'];
      this.count = data['count'];
      this.hasMore = !!data['has_more'];
      this.numPages = data['num_pages'];
      this.perPage = data['per_page'];
      this.cursorPrev = data['cursor_prev'];
      this.cursorNext = data['cursor_next'];
      return this;
    }
  }

  export class SqlResponse {
    public data: any[][];
    public columns: string[];
    public columnDescriptions: ColumnsDescription = {};
    public count: number;

    deserialize(data: Object) {
      this.data = data['data'];
      this.columns = data['columns'];

      if (data['column_descriptions']) {
        this.columnDescriptions = fromPairs(
          toPairs(data['column_descriptions'])
            .filter(([k]) => isSet(k))
            .map(([k, v]) => [
              k,
              {
                ...(isSet(v['field']) ? { field: parseFieldType(v['field']) } : {})
              }
            ])
        );
      }

      if (!this.columnDescriptions.length) {
        this.columnDescriptions = undefined;
      }

      this.count = data['count'];

      return this;
    }

    serialize() {
      return {
        data: this.data,
        columns: this.columns,
        column_descriptions: this.columnDescriptions,
        count: this.count
      };
    }

    toObject(): any {
      return this.data.map(item => {
        if (this.columns.length == 1 && this.columns[0] === null) {
          return item[0];
        } else if (this.columns) {
          return fromPairs(this.columns.map((column, i) => [column, item[i]]));
        } else {
          return this.data.map(row => {
            return fromPairs(row.map((value, i) => [i, value]));
          });
        }
      });
    }
  }

  export interface UploadFileState {
    uploadProgress: number;
    uploadLoaded?: number;
    uploadTotal?: number;
    downloadProgress: number;
    downloadLoaded?: number;
    downloadTotal?: number;
  }

  export interface UploadFileResult {
    uploadedPath: string;
    uploadedUrl: string;
    response?: HttpResponse<any>;
  }

  export interface UploadFileResponse {
    state: UploadFileState;
    result?: UploadFileResult;
  }

  export interface SiblingsResponse {
    prev: Model;
    next: Model;
  }
}
