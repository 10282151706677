import { Injectable, OnDestroy } from '@angular/core';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import isPlainObject from 'lodash/isPlainObject';
import keys from 'lodash/keys';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { asyncScheduler, BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { auditTime, distinctUntilChanged, filter, map, switchMap, throttleTime } from 'rxjs/operators';

import { localize } from '@common/localize';
import {
  CustomViewSettings,
  elementItemCategories,
  ElementType,
  getTokenSubtitle,
  ViewContext,
  ViewContextElement,
  ViewContextToken
} from '@modules/customize';
import {
  detectFieldByValue,
  FieldType,
  FormFieldSerialized,
  getFieldDescriptionByType,
  JsonStructureArrayParams,
  JsonStructureNode,
  JsonStructureNodeType,
  JsonStructureObjectParams
} from '@modules/fields';
import { contextToFormulaValue, transformFormulaElementAccessors } from '@modules/parameters';
import { ProjectGroupStore } from '@modules/projects';
import { ascComparator, EMPTY, forceObservable, isSet, objectGet } from '@shared';

import { FormulaSection, FormulaSectionItem } from '../../data/formula-section';
import { FormulaCategory, viewContextTokenProviderFunctions } from './view-context-token-provider-functions.stub';

function sectionItemAscComparator(lhs: FormulaSectionItem, rhs: FormulaSectionItem): number {
  return ascComparator(
    lhs.section ? String(lhs.section.label).toLowerCase() : String(lhs.item.label).toLowerCase(),
    rhs.section ? String(rhs.section.label).toLowerCase() : String(rhs.item.label).toLowerCase()
  );
}

@Injectable()
export class ViewContextTokenProvider implements OnDestroy {
  private sections$: BehaviorSubject<FormulaSection[]>;

  constructor(private context: ViewContext, private projectGroupStore: ProjectGroupStore) {}

  ngOnDestroy(): void {}

  ensureSectionsObserving() {
    if (!this.sections$) {
      this.sections$ = new BehaviorSubject<FormulaSection[]>(undefined);

      this.getCurrentSections$()
        .pipe(untilDestroyed(this))
        .subscribe(value => this.sections$.next(value));
    }
  }

  getSections$(): Observable<FormulaSection[]> {
    this.ensureSectionsObserving();
    return this.sections$.pipe(filter(item => item !== undefined));
  }

  getCurrentSections$(): Observable<FormulaSection[]> {
    return this.context.getOutputTokens$(true).pipe(
      auditTime(60),
      switchMap(contextTokens => {
        const obs$ = [
          this.getStateSection(contextTokens),
          this.getWorkflowSection(contextTokens),
          this.getWorkflowStepsSection(contextTokens),
          this.getComponentParametersSection(contextTokens),
          this.getElementsSection(contextTokens),
          this.getPageSection(contextTokens),
          this.getQueriesSection(contextTokens),
          this.getPopupSection(contextTokens),
          this.getRecordSection(contextTokens),
          this.getUserSection(contextTokens),
          this.getTeamSection(contextTokens),
          ...this.getPropertiesSections(contextTokens),
          this.getFunctionsSection()
        ].map(value => forceObservable<FormulaSection>(value));

        return combineLatest(obs$);
      }),
      map(sections => {
        return sections
          .filter(item => item)
          .filter(item => item.alwaysVisible || (!item.alwaysVisible && item.items.length));
      })
    );
  }

  getContextElementSection(
    contextElement: ViewContextElement,
    contextElementPath?: (string | number)[],
    contextElementPaths?: (string | number)[][]
  ): Observable<FormulaSection> {
    if (!contextElement) {
      return of(undefined);
    }

    return this.context.getElementTokens$(contextElement, contextElementPath, contextElementPaths).pipe(
      map(contextElementTokens => {
        return {
          label: `<strong>${contextElement.name}</strong> - Current Component`,
          icon: contextElement.icon || 'components',
          orange: true,
          items: this.mapElementTokens(contextElementTokens, ['item'], { contextElement: contextElement })
        };
      })
    );
  }

  getWorkflowSection(contextTokens: ViewContextToken[]): FormulaSection {
    const workflowToken = contextTokens.find(item => item.uniqueName == 'workflow');
    if (!workflowToken) {
      return;
    }

    return {
      label: 'Workflow parameters',
      icon: 'input',
      alwaysVisible: true,
      items: this.mapElementTokens(workflowToken.children, ['workflow'], { sort: true })
    };
  }

  getComponentParametersSection(contextTokens: ViewContextToken[]): FormulaSection {
    const componentToken = contextTokens.find(item => item.uniqueName == 'component');
    if (!componentToken) {
      return;
    }

    return {
      label: 'Component parameters',
      icon: 'input',
      alwaysVisible: true,
      items: this.mapElementTokens(componentToken.children, ['component'], { sort: true })
    };
  }

  getStateSection(contextTokens: ViewContextToken[]): FormulaSection {
    const stateToken = contextTokens.find(item => item.uniqueName == 'state');
    if (!stateToken) {
      return;
    }

    return {
      label: 'State',
      icon: 'select',
      alwaysVisible: true,
      items: this.mapElementTokens(stateToken.children, ['state'], { sort: true })
    };
  }

  getWorkflowStepsSection(contextTokens: ViewContextToken[]): FormulaSection {
    const workflowStepsToken = contextTokens.find(item => item.uniqueName == 'steps');
    if (!workflowStepsToken) {
      return;
    }

    const items = workflowStepsToken.children.filter(item => item.children && item.children.length);
    if (!items.length) {
      return;
    }

    return {
      label: 'Workflow steps',
      icon: 'workflow',
      alwaysVisible: true,
      items: this.mapElementTokens(items, ['steps'], { sort: true, ignoreContextElement: true })
    };
  }

  getElementsSection(contextTokens: ViewContextToken[]): FormulaSection {
    const elementsToken = contextTokens.find(item => item.uniqueName == 'elements');
    if (!elementsToken) {
      return;
    }

    const elementItemCategoriesValues = [
      elementItemCategories[ElementType.List],
      ...(this.context.viewSettings instanceof CustomViewSettings
        ? this.context.viewSettings.popups.map(item => item.name)
        : []),
      elementItemCategories[ElementType.Field],
      elementItemCategories[ElementType.Custom],
      elementItemCategories[ElementType.Form],
      elementItemCategories[ElementType.Action],
      elementItemCategories[ElementType.Model],
      elementItemCategories[ElementType.Widget]
    ];

    return {
      label: 'Other Components',
      icon: 'components',
      alwaysVisible: true,
      items: this.mapElementTokens(elementsToken.children, ['components'], {
        sort: false,
        ignoreContextElement: true
      }).sort((lhs, rhs) => {
        if (isSet(lhs.subtitle) && isSet(rhs.subtitle)) {
          const lhsIndex = elementItemCategoriesValues.indexOf(lhs.subtitle);
          const rhsIndex = elementItemCategoriesValues.indexOf(rhs.subtitle);

          if (lhsIndex !== rhsIndex) {
            return lhsIndex - rhsIndex;
          } else {
            return sectionItemAscComparator(lhs, rhs);
          }
        } else if (isSet(lhs.subtitle) && !isSet(rhs.subtitle)) {
          return -1;
        } else if (!isSet(lhs.subtitle) && isSet(rhs.subtitle)) {
          return 1;
        } else {
          return sectionItemAscComparator(lhs, rhs);
        }
      })
    };
  }

  getPageSection(contextTokens: ViewContextToken[]): FormulaSection {
    const pageToken = contextTokens.find(item => item.uniqueName == 'page');
    if (!pageToken) {
      return;
    }

    return {
      label: 'Page Inputs',
      icon: 'input',
      alwaysVisible: true,
      items: this.mapElementTokens(pageToken.children, ['page'], { sort: true })
    };
  }

  getQueriesSection(contextTokens: ViewContextToken[]): FormulaSection {
    const queriesToken = contextTokens.find(item => item.uniqueName == 'queries');
    if (!queriesToken) {
      return;
    }

    return {
      label: 'Page Queries',
      icon: 'cloud_download',
      alwaysVisible: true,
      items: this.mapElementTokens(queriesToken.children, ['queries'], { sort: true })
    };
  }

  getPopupSection(contextTokens: ViewContextToken[]): FormulaSection {
    const popupsToken = contextTokens.find(item => item.uniqueName == 'modals');
    if (!popupsToken) {
      return;
    }

    const items = popupsToken.children.filter(item => item.children && item.children.length);
    if (items.length) {
      return {
        label: 'Modal inputs',
        icon: 'cloud_download',
        alwaysVisible: true,
        items: this.mapElementTokens(items, ['modals'], { sort: true })
      };
    }
  }

  getRecordSection(contextTokens: ViewContextToken[]): FormulaSection {
    const recordToken = contextTokens.find(item => item.uniqueName == 'record');
    if (!recordToken) {
      return;
    }

    return {
      label: 'Record',
      icon: 'document',
      items: this.mapElementTokens(recordToken.children, ['record'], { sort: true })
    };
  }

  getUserSection(contextTokens: ViewContextToken[]): FormulaSection {
    const userToken = contextTokens.find(item => item.uniqueName == 'user');
    const userPropertiesToken = contextTokens.find(item => item.uniqueName == 'user_properties');

    if (!userToken && !userPropertiesToken) {
      return;
    }

    const items = [
      ...(userPropertiesToken ? userPropertiesToken.children : []),
      ...(userToken ? userToken.children : [])
    ];

    return {
      label: 'User Properties',
      icon: 'user',
      alwaysVisible: true,
      action: userPropertiesToken.action,
      documentation: userPropertiesToken.documentation,
      items: this.mapElementTokens(items, ['user'], { sort: false })
    };
  }

  getTeamSection(contextTokens: ViewContextToken[]): Observable<FormulaSection> {
    return this.projectGroupStore.get().pipe(
      map(groups => {
        groups = groups || [];

        const teamToken = contextTokens.find(item => item.uniqueName == 'group');
        const teamPropertiesToken = contextTokens.find(item => item.uniqueName == 'team_properties');

        if (!teamToken && !teamPropertiesToken) {
          return;
        }

        const items = [
          ...(teamPropertiesToken ? teamPropertiesToken.children : []),
          ...(teamToken ? teamToken.children : [])
        ];

        const projectGroups = groups.filter(item => !item.permissionsGroup);
        const builtInGroups = groups.filter(item => item.permissionsGroup);

        return {
          label: 'Team Properties',
          icon: 'users_teams',
          alwaysVisible: true,
          action: teamPropertiesToken.action,
          documentation: teamPropertiesToken.documentation,
          items: [
            ...this.mapElementTokens(items, ['group'], { sort: false }),
            {
              path: ['group', 'teams'],
              section: {
                label: 'Is member of a team',
                icon: 'human_being',
                items: [
                  ...projectGroups.map(group => {
                    return {
                      path: ['group', 'teams', group.uid],
                      item: {
                        token: [''],
                        label: group.name,
                        icon: 'users_teams',
                        formula: `group.uid == "${group.uid}"`
                      },
                      subtitle: localize('App Teams')
                    };
                  }),
                  ...builtInGroups.map(group => {
                    return {
                      path: ['group', 'teams', group.uid],
                      item: {
                        token: [''],
                        label: group.name,
                        labelAdditional: group.getDescription(),
                        icon: group.getIcon(),
                        formula: `group.uid == "${group.uid}"`
                      },
                      subtitle: projectGroups.length ? localize('Built-In Teams') : undefined
                    };
                  })
                ]
              }
            }
          ]
        };
      })
    );
  }

  getPropertiesSections(contextTokens: ViewContextToken[]): FormulaSection[] {
    const result: FormulaSection[] = [];
    const globalPropertiesToken = contextTokens.find(item => item.uniqueName == 'global_variables');
    const pagePropertiesToken = contextTokens.find(item => item.uniqueName == 'page_variables');
    const appPropertiesToken = contextTokens.find(item => item.uniqueName == 'app');
    const appDeviceToken = contextTokens.find(item => item.uniqueName == 'device');

    if (globalPropertiesToken) {
      result.push({
        label: 'Global Variables',
        icon: 'variable',
        alwaysVisible: true,
        hideTab: true,
        action: globalPropertiesToken.action,
        documentation: globalPropertiesToken.documentation,
        items: this.mapElementTokens(globalPropertiesToken.children, ['global_variables'], { sort: false })
      });
    }

    if (pagePropertiesToken) {
      result.push({
        label: 'Page Variables',
        icon: 'variable',
        alwaysVisible: true,
        hideTab: true,
        action: pagePropertiesToken.action,
        documentation: pagePropertiesToken.documentation,
        items: this.mapElementTokens(pagePropertiesToken.children, ['page_variables'], { sort: false })
      });
    }

    if (appPropertiesToken) {
      result.push({
        label: 'Application',
        hideTab: true,
        items: this.mapElementTokens(appPropertiesToken.children, ['app'], { sort: false })
      });
    }

    if (appDeviceToken) {
      const viewportTypeNames = ['is_desktop', 'is_mobile', 'is_phone', 'is_tablet'];
      const viewportTypesItems = appDeviceToken.children.filter(item => viewportTypeNames.includes(item.uniqueName));
      const otherItems = appDeviceToken.children.filter(item => !viewportTypeNames.includes(item.uniqueName));

      result.push({
        label: 'Device',
        hideTab: true,
        items: [
          {
            path: ['device', 'viewport_types'],
            section: {
              label: 'Is Desktop/Mobile/Tablet/Phone',
              icon: 'pages',
              items: this.mapElementTokens(viewportTypesItems, ['device', 'viewport_types'], { sort: false })
            }
          },
          ...this.mapElementTokens(otherItems, ['device'], { sort: false })
        ]
      });
    }

    return result;
  }

  getFunctionsSection(): FormulaSection {
    return {
      name: 'functions',
      label: 'Functions',
      icon: 'function',
      items: viewContextTokenProviderFunctions.map(item => {
        const formulaCategoryLabels = {
          [FormulaCategory.General]: 'General functions',
          [FormulaCategory.Logic]: 'Logical functions',
          [FormulaCategory.Math]: 'Mathematical functions',
          [FormulaCategory.DateTime]: 'Date & Time Functions'
        };

        return {
          path: ['functions', item.function.name],
          item: {
            token: [item.function.name],
            label: `${item.function.name}()`,
            type: 'function',
            insert: [item.function.name],
            caretIndex: item.function.arguments.length ? item.function.name.length + 1 : item.function.name.length + 2,
            insertSelection: true,
            description: item.description,
            function: item.function
          },
          subtitle: formulaCategoryLabels[item.category]
        };
      })
    };
  }

  mapElementTokens(
    tokens: ViewContextToken[],
    path: string[],
    options: {
      contextElement?: ViewContextElement;
      sort?: boolean;
      ignoreContextElement?: boolean;
      depth?: number;
    } = {}
  ): FormulaSectionItem[] {
    const depth = options.depth || 0;
    const result = tokens
      .filter(item => item.token || item.children)
      .map(item => {
        const mapSection = (i: ViewContextToken, itemPath: string[], override = {}): FormulaSectionItem => {
          const tokenPath = [...itemPath, i.uniqueName];
          return {
            path: tokenPath,
            section: {
              token: i.token,
              insert: i.token,
              // caretIndex: token ? token.length : undefined,
              label: i.name,
              icon: i.icon,
              allowSkip: i.allowSkip,
              action: i.action,
              documentation: i.documentation,
              items: this.mapElementTokens(i.children, tokenPath, {
                ...options,
                sort: false,
                ignoreContextElement: undefined,
                depth: depth + 1
              }),
              ...override
            },
            subtitle: getTokenSubtitle(i.element),
            ...(options.ignoreContextElement && {
              data: {
                ignoreContextElement: item.element
              }
            })
          };
        };
        const mapItem = (i: ViewContextToken, itemPath: string[], override = {}): FormulaSectionItem => {
          const tokenPath = [...itemPath, i.uniqueName];
          const tokenValue$ = this.getTokenValue(i, options.contextElement);

          return {
            path: tokenPath,
            item: {
              token: i.token,
              // label: item.token.split('.').slice(-1).join(''),
              label: i.name,
              labelAdditional: i.subtitle,
              icon: i.icon,
              iconOrange: i.iconOrange,
              insert: i.token,
              fieldType: i.fieldType,
              fieldParams: i.fieldParams,
              value: tokenValue$,
              // caretIndex: token.length,
              ...override
            },
            subtitle: getTokenSubtitle(i.element),
            ...(options.ignoreContextElement && {
              data: {
                ignoreContextElement: item.element
              }
            })
          };
        };

        if (item.children) {
          const singleItem = item.children.length == 1 ? item.children[0] : undefined;

          if (item.allowSkip && singleItem) {
            if (singleItem.children) {
              return {
                ...mapSection(singleItem, [...path, item.uniqueName], { label: item.name }),
                subtitle: getTokenSubtitle(item.element)
              };
            } else if (singleItem.token) {
              return {
                ...mapItem(singleItem, [...path, item.uniqueName], { label: item.name }),
                subtitle: getTokenSubtitle(item.element)
              };
            }
          } else {
            return mapSection(item, path);
          }
        } else if (item.token) {
          return mapItem(item, path);
        }
      })
      .filter(item => {
        if (item.item) {
          return item.item.token;
        } else if (item.section) {
          return item.section.allowSkip ? item.section.items.length : true;
        } else {
          return true;
        }
      });

    if (options.sort) {
      return result.sort((lhs, rhs) => {
        const lhsOrange = lhs.section ? lhs.section.orange : lhs.item.orange || lhs.item.iconOrange;
        const rhsOrange = rhs.section ? rhs.section.orange : rhs.item.orange || rhs.item.iconOrange;

        return -10 * ((lhsOrange ? 1 : -1) - (rhsOrange ? 1 : -1)) + sectionItemAscComparator(lhs, rhs);
      });
    } else {
      return result;
    }
  }

  mapJsonToken(
    token: (string | number)[],
    path: string[],
    tokenLabel: string,
    tokenValue: any,
    depth: number,
    options: {
      override?: Object;
      structure?: JsonStructureNode;
    } = {}
  ): FormulaSectionItem {
    let fieldType: FieldType;
    let isTokenArray: boolean;
    let isTokenObject: boolean;

    if (options.structure && options.structure.type == JsonStructureNodeType.Field) {
      fieldType = (options.structure.params as FormFieldSerialized).field as FieldType;
      isTokenArray = false;
      isTokenObject = false;
    } else if (options.structure && options.structure.type == JsonStructureNodeType.Object) {
      fieldType = FieldType.JSON;
      isTokenArray = false;
      isTokenObject = true;
    } else if (options.structure && options.structure.type == JsonStructureNodeType.Array) {
      fieldType = FieldType.JSON;
      isTokenArray = true;
      isTokenObject = false;
    } else {
      fieldType = detectFieldByValue(tokenValue).field;
      isTokenArray = isArray(tokenValue);
      isTokenObject = isPlainObject(tokenValue);
    }

    const name = String(token[token.length - 1]);
    const labelSecondary = this.getValueDisplay(tokenValue, {
      name: name,
      type: fieldType
    });

    if (isTokenArray) {
      let childStructure: JsonStructureNode;

      if (options.structure && options.structure.type == JsonStructureNodeType.Array) {
        childStructure = (options.structure.params as JsonStructureArrayParams).item;
      }

      return {
        path: path,
        section: {
          token: token,
          insert: token,
          // caretIndex: token ? token.length : undefined,
          label: tokenLabel,
          labelSecondary: labelSecondary,
          icon: 'layers_2',
          allowSkip: false,
          items: (isArray(tokenValue) ? tokenValue : []).map((child, i) => {
            const childToken = [...token, i];
            const childName = `Item #${i + 1}`;

            return this.mapJsonToken(childToken, [...path, String(i)], childName, child, depth + 1, {
              ...options,
              structure: childStructure,
              override: undefined
            });
          }),
          ...(options.override || {})
        }
      };
    } else if (isTokenObject) {
      let childrenKeys: string[];
      let childrenStructure: JsonStructureNode[];

      if (options.structure && options.structure.type == JsonStructureNodeType.Object) {
        childrenStructure = (options.structure.params as JsonStructureObjectParams).items;
        childrenKeys = childrenStructure.map(item => item.name);
      } else {
        childrenKeys = isPlainObject(tokenValue) ? keys(tokenValue) : [];
        childrenStructure = [];
      }

      return {
        path: path,
        section: {
          token: token,
          insert: token,
          // caretIndex: token ? token.length : undefined,
          label: tokenLabel,
          labelSecondary: labelSecondary,
          icon: 'components',
          allowSkip: false,
          items: childrenKeys.map(key => {
            const childValue = isPlainObject(tokenValue) ? tokenValue[key] : undefined;
            const childToken = [...token, key];
            const childStructure = childrenStructure.find(item => item.name == key);

            return this.mapJsonToken(childToken, [...path, key], key, childValue, depth + 1, {
              ...options,
              structure: childStructure,
              override: undefined
            });
          }),
          ...(options.override || {})
        }
      };
    } else {
      const fieldDescription = getFieldDescriptionByType(fieldType);

      return {
        path: path,
        item: {
          token: token,
          label: tokenLabel,
          icon: fieldDescription.icon,
          labelSecondary: labelSecondary,
          insert: token,
          // caretIndex: token.length,
          ...(options.override || {})
        }
      };
    }
  }

  getValueDisplay(value: any, options: { name?: string; type?: FieldType; params?: Object } = {}): any {
    const fieldDescription = getFieldDescriptionByType(options.type);

    if (value === EMPTY || !isSet(value)) {
      return fieldDescription.label;
    }

    if (fieldDescription.serializeValue) {
      value = fieldDescription.serializeValue(value, {
        name: options.name,
        field: options.type,
        params: options.params
      });
    }

    if (!isSet(value)) {
      return fieldDescription.label;
    }

    if (isObject(value)) {
      try {
        value = JSON.stringify(value);
      } catch (e) {}
    }

    return String(value).substring(0, 40);
  }

  getTokenValue(token: ViewContextToken, contextElement?: ViewContextElement): Observable<any> {
    return this.context.outputValues$.pipe(
      map(globalCtx => {
        const formula = contextToFormulaValue(token.token);
        const internalToken = transformFormulaElementAccessors(formula, this.context, false);
        const elementPath = this.context.getElementPath(contextElement);
        const elementCtx = elementPath ? objectGet(globalCtx, elementPath) : EMPTY;
        const ctx = {
          ...(elementCtx !== EMPTY ? elementCtx : {}),
          ...globalCtx
        };

        return objectGet(ctx, internalToken, null);
      }),
      throttleTime(60, asyncScheduler, { leading: true, trailing: true }),
      distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs))
    );
  }
}
