var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FocusMonitor } from '@angular/cdk/a11y';
import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { AfterViewInit, ChangeDetectorRef, ElementRef, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import camelCase from 'lodash/camelCase';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { asyncScheduler, BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map, take, throttleTime } from 'rxjs/operators';
import { ResizeType } from '@common/resizable';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { CodeFieldComponent } from '@modules/field-components';
import { createFormFieldFactory, getJavaScriptSyntaxError, InputValueType } from '@modules/fields';
import { contextToFormulaValue, FieldInputControl, transformFormulaElementAccessors } from '@modules/parameters';
import { isSet, removeClass } from '@shared';
// Refactor imports
import { AutoFieldComponent } from '@modules/fields/components/auto-field/auto-field.component';
import { getFormulaStateAnalyticsParams } from '../../utils/analytics';
import { InputEditFormulaValueComponent } from '../input-edit-formula-value/input-edit-formula-value.component';
import { ViewContextTokenPopoverOverlayComponent } from '../view-context-token-popover-overlay/view-context-token-popover-overlay.component';
var InlineInputEditComponent = /** @class */ (function () {
    function InlineInputEditComponent(focusMonitor, analyticsService, zone, cd) {
        this.focusMonitor = focusMonitor;
        this.analyticsService = analyticsService;
        this.zone = zone;
        this.cd = cd;
        this.focusedInitial = false;
        this.placeholder = 'Formula';
        this.jsPlaceholder = 'return 2 * 3;';
        this.classes = [];
        this.createField = createFormFieldFactory();
        this.contentHover$ = new BehaviorSubject(false);
        this.editorHover$ = new BehaviorSubject(false);
        this.editorOpened$ = combineLatest(this.contentHover$, this.editorHover$).pipe(throttleTime(10, asyncScheduler, { leading: true, trailing: true }), map(function (_a) {
            var contentHover = _a[0], editorHover = _a[1];
            return contentHover || editorHover;
        }));
        this.editorActive = false;
        this.editorPositions = [
            {
                panelClass: ['overlay_position_bottom-left'],
                originX: 'start',
                originY: 'bottom',
                overlayX: 'start',
                overlayY: 'top',
                weight: 4,
                offsetX: -4
            },
            {
                panelClass: ['overlay_position_bottom-right'],
                originX: 'end',
                originY: 'bottom',
                overlayX: 'end',
                overlayY: 'top',
                weight: 3,
                offsetX: 4
            },
            {
                panelClass: ['overlay_position_top-left'],
                originX: 'start',
                originY: 'top',
                overlayX: 'start',
                overlayY: 'bottom',
                weight: 2,
                offsetX: -4
            },
            {
                panelClass: ['overlay_position_top-right'],
                originX: 'end',
                originY: 'top',
                overlayX: 'end',
                overlayY: 'bottom',
                weight: 1,
                offsetX: 4
            }
        ];
        this.resizeTypes = ResizeType;
        this.startedFormulaEditing = false;
        this.inputValueTypes = InputValueType;
    }
    InlineInputEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.control = new FormControl(undefined, { updateOn: this.itemForm ? this.itemForm.updateOn : undefined });
        this.control.valueChanges.pipe(untilDestroyed(this)).subscribe(function (value) {
            var toExternalValue = transformFormulaElementAccessors(value, _this.context, false);
            _this.itemForm.controls['value_type'].patchValue(InputValueType.Formula);
            _this.itemForm.controls['formula_value'].patchValue(toExternalValue);
            _this.itemForm.markAsDirty();
        });
        this.itemForm.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(function () {
            _this.updateInputErrors();
            _this.onInteracting();
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Formula.Updated, {
                Source: _this.analyticsSource
            });
        });
        this.setInitialValue();
        // Workaround for overlays overlap issue
        if (this.focusedInitial) {
            this.contentHover$.next(true);
        }
    };
    InlineInputEditComponent.prototype.ngOnDestroy = function () {
        if (this.startedFormulaEditing) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Formula.FinishedToSetUp, __assign({}, getFormulaStateAnalyticsParams(this.itemForm, this.context, this.initialValue), { Source: this.analyticsSource }));
        }
    };
    InlineInputEditComponent.prototype.ngAfterViewInit = function () {
        if (this.focusedInitial) {
            this.activateEditor();
            this.focusedInitial = false;
        }
    };
    InlineInputEditComponent.prototype.clearInputErrors = function () {
        this.jsError = undefined;
        this.cd.markForCheck();
    };
    InlineInputEditComponent.prototype.updateInputErrors = function () {
        var value = this.itemForm.value;
        this.clearInputErrors();
        if (value['value_type'] == InputValueType.Js) {
            if (isSet(value['js_value'])) {
                this.jsError = getJavaScriptSyntaxError(value['js_value']);
                this.cd.markForCheck();
            }
        }
    };
    InlineInputEditComponent.prototype.activateEditor = function () {
        var _this = this;
        if (this.editorActive) {
            return;
        }
        this.editorActive = true;
        this.cd.markForCheck();
        this.zone.onStable
            .pipe(take(1))
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            removeClass(_this.editorOverlay.overlayRef.backdropElement, 'popover2-backdrop2');
        });
        this.focusValueOnStable();
    };
    InlineInputEditComponent.prototype.deactivateEditor = function () {
        this.editorActive = false;
        this.contentHover$.next(false);
        this.editorHover$.next(false);
        this.cd.markForCheck();
    };
    InlineInputEditComponent.prototype.onEditorOpened = function () {
        this.onInteracting();
    };
    InlineInputEditComponent.prototype.onInteracting = function () {
        if (!this.startedFormulaEditing) {
            this.startedFormulaEditing = true;
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Formula.StartedToSetUp, {
                Source: this.analyticsSource
            });
        }
    };
    InlineInputEditComponent.prototype.focusValue = function () {
        if (this.formulaValueComponent) {
            this.formulaValueComponent.focus();
        }
        if (this.jsField) {
            this.jsField.focus();
        }
    };
    InlineInputEditComponent.prototype.focusValueOnStable = function () {
        var _this = this;
        this.zone.onStable
            .pipe(take(1))
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.focusValue(); });
    };
    InlineInputEditComponent.prototype.switchToFormula = function () {
        this.itemForm.controls['value_type'].patchValue(InputValueType.Formula);
        this.itemForm.markAsDirty();
    };
    InlineInputEditComponent.prototype.switchToJs = function () {
        this.itemForm.controls['value_type'].patchValue(InputValueType.Js);
        this.itemForm.markAsDirty();
    };
    InlineInputEditComponent.prototype.setInitialValue = function () {
        var formValue = this.itemForm.value;
        var fromExternalValue = transformFormulaElementAccessors(formValue['formula_value'], this.context, true);
        this.control.patchValue(fromExternalValue, { emitEvent: false });
        this.initialValue = formValue;
        this.itemForm.markAsPristine();
        this.updateInputErrors();
    };
    InlineInputEditComponent.prototype.onEditorOverlayContentChanged = function () {
        if (this.editorOverlay) {
            this.editorOverlay.overlayRef.updatePosition();
        }
        if (this.tokenPopoverOverlayComponent) {
            this.tokenPopoverOverlayComponent.updatePosition();
        }
    };
    InlineInputEditComponent.prototype.insertJsToken = function (item) {
        if (!isSet(item.insert) && !isSet(item.formula)) {
            return;
        }
        if (this.jsField &&
            this.jsField.dynamicComponent.currentComponent &&
            this.jsField.dynamicComponent.currentComponent.instance instanceof CodeFieldComponent) {
            var ace = this.jsField.dynamicComponent.currentComponent.instance.ace;
            var selectionRange = ace.editor.selection.getRange();
            var insert = void 0;
            if (isSet(item.formula)) {
                insert = item.formula;
            }
            else {
                var formulaValue = contextToFormulaValue(item.insert);
                insert = transformFormulaElementAccessors(formulaValue, this.context, false);
            }
            var insertRow = selectionRange.start.row;
            var insertLast = isSet(item['label']) ? item.label : item.insert[item.insert.length - 1];
            var insertRowValue = ace.editor.session.getLine(insertRow);
            var varName = typeof insertLast == 'number' ? "item" + insertLast : camelCase(String(insertLast));
            var prefixMatch = insertRowValue.match(/^([\s\t]+)/);
            var prefix = prefixMatch ? prefixMatch[1] : '';
            ace.editor.session.insert({ row: insertRow, column: 0 }, prefix + "let " + varName + " = " + insert + ";\n");
        }
    };
    InlineInputEditComponent.prototype.onJsTokenSelected = function (item) {
        if (isSet(item.insert) || isSet(item.formula)) {
            this.insertJsToken(item);
        }
    };
    return InlineInputEditComponent;
}());
export { InlineInputEditComponent };
