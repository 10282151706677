import { ObserversModule } from '@angular/cdk/observers';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgGxDatepickerModule } from 'ng-gxdatepicker';
import { NgGxScrollableModule } from 'ng-gxscrollable';

import { DialogsModule } from '@common/dialogs';
import { DragDropModule } from '@common/drag-drop';
import { DynamicComponentModule } from '@common/dynamic-component';
import { FixedComponentModule } from '@common/fixed-component';
import { LocalizeModule } from '@common/localize';
import { SelectModule } from '@common/select';
import { TipsModule } from '@common/tips';
import { AnalyticsModule } from '@modules/analytics';
import { ChartsModule } from '@modules/charts';
import { ColorsSharedModule } from '@modules/colors-shared';
import { DashboardModule } from '@modules/dashboard';
import { FeaturesModule } from '@modules/features';
import { FieldComponentsModule } from '@modules/field-components';
import { FieldsModule } from '@modules/fields';
import { QueriesComponentsModule } from '@modules/queries-components';
import { RoutingModule } from '@modules/routing';
import { SharedModule } from '@shared';

import { AddDashboardComponent } from './components/add-dashboard/add-dashboard.component';
import { DashboardStubComponent } from './components/dashboard-stub/dashboard-stub.component';
import { WidgetDateSelectorComponent } from './components/widget-date-selector/widget-date-selector.component';
import { AutoWidgetComponent } from './components/widgets/auto-widget/auto-widget.component';
import { ChartWidgetComponent } from './components/widgets/chart-widget/chart-widget.component';
import { UnknownWidgetComponent } from './components/widgets/unknown-widget/unknown-widget.component';
import { ValueWidgetCounterComponent } from './components/widgets/value-widget/value-widget-counter/value-widget-counter.component';
import { ValueWidgetComponent } from './components/widgets/value-widget/value-widget.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DashboardModule,
    NgGxScrollableModule,
    SharedModule,
    FixedComponentModule,
    DynamicComponentModule,
    TipsModule,
    ChartsModule,
    FieldsModule,
    DialogsModule,
    QueriesComponentsModule,
    DragDropModule,
    RoutingModule,
    FeaturesModule,
    AnalyticsModule,
    SelectModule,
    FieldComponentsModule,
    NgGxDatepickerModule,
    OverlayModule,
    ObserversModule,
    LocalizeModule,
    ColorsSharedModule
  ],
  declarations: [
    AutoWidgetComponent,
    UnknownWidgetComponent,
    ChartWidgetComponent,
    ValueWidgetComponent,
    AddDashboardComponent,
    DashboardStubComponent,
    WidgetDateSelectorComponent,
    ValueWidgetCounterComponent
  ],
  exports: [AutoWidgetComponent, AddDashboardComponent, DashboardStubComponent],
  entryComponents: [
    AutoWidgetComponent,
    UnknownWidgetComponent,
    ChartWidgetComponent,
    ValueWidgetComponent,
    AddDashboardComponent
  ]
})
export class DashboardComponentsModule {}
