var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, Injector, OnDestroy, OnInit } from '@angular/core';
import groupBy from 'lodash/groupBy';
import range from 'lodash/range';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { NotificationService } from '@common/notifications';
import { ActionControllerService } from '@modules/action-queries';
import { TintStyle } from '@modules/customize';
import { gteFieldLookup, lteFieldLookup } from '@modules/field-lookups';
import { FilterItem2 } from '@modules/filters';
import { ColumnsModelListStore } from '@modules/list';
import { DATE_PARAM, TYPE_PARAM } from '@modules/models';
import { paramsToGetQueryOptions } from '@modules/resources';
import { CalendarType } from '../../data/calendar-type';
import { CalendarBaseComponent } from '../calendar-base/calendar-base.component';
var CalendarWeekComponent = /** @class */ (function (_super) {
    __extends(CalendarWeekComponent, _super);
    function CalendarWeekComponent(injector, actionControllerService, listStore, notificationService, cd) {
        var _this = _super.call(this, injector, actionControllerService, listStore, notificationService, cd) || this;
        _this.weekDays = [];
        _this.hours = [];
        _this.weekDayItems = {};
        _this.tintStyles = TintStyle;
        return _this;
    }
    CalendarWeekComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.listStore.items$.pipe(untilDestroyed(this)).subscribe(function (items) {
            _this.weekDayItems = items
                ? groupBy(items, function (item) { return moment(item.model.getAttribute(_this.listState.settings.dateField)).format('d'); })
                : {};
            _this.cd.markForCheck();
        });
    };
    CalendarWeekComponent.prototype.fetch = function (state) {
        var queryOptions = paramsToGetQueryOptions(state.dataSourceParams);
        queryOptions.filters = queryOptions.filters.concat(state.filters, this.dateFilterFilters(state));
        queryOptions.search = state.search;
        queryOptions.sort = state.sort;
        this.listStore.dataSource = state.dataSource;
        this.listStore.useDataSourceColumns = true;
        this.listStore.staticData = state.dataSourceStaticData;
        this.listStore.queryOptions = queryOptions;
        this.listStore.context = this.context;
        this.listStore.contextElement = this.contextElement;
        this.getItems();
        this.updateVisibleColumns(state);
        this.updateHours(state);
        this.prevDate = state.date;
    };
    Object.defineProperty(CalendarWeekComponent.prototype, "now", {
        get: function () {
            return moment();
        },
        enumerable: true,
        configurable: true
    });
    CalendarWeekComponent.prototype.updateHours = function (state) {
        var _this = this;
        if (!state.date) {
            this.weekDays = [];
            this.hours = [];
            this.cd.markForCheck();
            return;
        }
        this.weekDays = range(0, 6 + 1).map(function (day) {
            var date = state.date.clone().startOf('isoWeek').add(day, 'days');
            return {
                date: date,
                weekend: [6, 0].includes(date.day()),
                today: date.isSame(_this.now, 'day') && date.isSame(_this.now, 'month') && date.isSame(_this.now, 'year')
            };
        });
        this.hours = range(0, 24).map(function (hour) {
            return {
                date: state.date.clone().startOf('isoWeek').add(hour, 'hours'),
                items: range(0, 7).map(function (day) {
                    var date = state.date.clone().startOf('isoWeek').add(hour, 'hours').add(day, 'days');
                    return {
                        date: date,
                        weekend: [6, 0].includes(date.day()),
                        key: date.format(_this.itemsGroupBy())
                    };
                })
            };
        });
        this.cd.markForCheck();
    };
    CalendarWeekComponent.prototype.dayParams = function (date) {
        var params = {};
        params[TYPE_PARAM] = CalendarType.Day;
        params[DATE_PARAM] = date.toISOString();
        return this.applyParams(params);
    };
    CalendarWeekComponent.prototype.dateFilterFilters = function (state) {
        var firstDay = state.date.clone().startOf('isoWeek');
        var lastDay = state.date.clone().endOf('isoWeek');
        return [
            new FilterItem2({
                field: [state.dateField],
                lookup: gteFieldLookup,
                value: firstDay.toISOString()
            }),
            new FilterItem2({
                field: [state.dateField],
                lookup: lteFieldLookup,
                value: lastDay.toISOString()
            })
        ];
    };
    CalendarWeekComponent.prototype.itemsGroupBy = function () {
        return 'DD.MM.YYYY HH';
    };
    return CalendarWeekComponent;
}(CalendarBaseComponent));
export { CalendarWeekComponent };
