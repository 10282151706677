/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../colors-shared/directives/button-tint-color/button-tint-color.directive";
import * as i2 from "@angular/platform-browser";
import * as i3 from "@angular/common";
import * as i4 from "../../../theme-components/pipes/theme-color-hex-option/theme-color-hex-option.pipe";
import * as i5 from "../../../theme-components/data/theme-context";
import * as i6 from "./table-stub-content-body.component";
var styles_TableStubContentBodyComponent = [];
var RenderType_TableStubContentBodyComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TableStubContentBodyComponent, data: {} });
export { RenderType_TableStubContentBodyComponent as RenderType_TableStubContentBodyComponent };
function View_TableStubContentBodyComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "td", [["class", "table__column table__column_left-padding table__stub-column"]], null, null, null, null, null))], null, null); }
function View_TableStubContentBodyComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Column"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.animating; _ck(_v, 0, 0, currVal_0); }); }
function View_TableStubContentBodyComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "table__column-action"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "button button_small button_tint-color"]], [[8, "style", 2], [2, "button_tint-color", null]], null, null, null, null)), i0.ɵdid(2, 540672, null, 0, i1.ButtonTintColorDirective, [i2.DomSanitizer], { appTintColor: [0, "appTintColor"], appTintColorStyle: [1, "appTintColorStyle"] }, null), i0.ɵppd(3, 1), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(5, 0, null, null, 3, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 4).transform(i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent.parent.parent.parent, 0), "accentColor")))); var currVal_3 = _co.tintStyles.Default; _ck(_v, 2, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 2).style; var currVal_1 = i0.ɵnov(_v, 2).cls; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = _co.animating; _ck(_v, 6, 0, currVal_4); var currVal_5 = _v.context.$implicit; _ck(_v, 8, 0, currVal_5); }); }
function View_TableStubContentBodyComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "table__column-actions"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_TableStubContentBodyComponent_6)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpad(3, 2)], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, "edit", "ok"); _ck(_v, 2, 0, currVal_0); }, null); }
function View_TableStubContentBodyComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "td", [["class", "table__column table__stub-column"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TableStubContentBodyComponent_4)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TableStubContentBodyComponent_5)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.action || !_v.context.last); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.action && _v.context.last); _ck(_v, 4, 0, currVal_1); }, null); }
function View_TableStubContentBodyComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "td", [["class", "table__column table__column_disabled"]], null, null, null, null, null))], null, null); }
function View_TableStubContentBodyComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "tr", [["class", "table__row"]], [[2, "table__row_selected", null], [2, "inverse", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TableStubContentBodyComponent_2)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_TableStubContentBodyComponent_3)), i0.ɵdid(4, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpad(5, 8), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TableStubContentBodyComponent_7)), i0.ɵdid(7, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.leftPaddingColumn; _ck(_v, 2, 0, currVal_2); var currVal_3 = (_co.scrollable ? _ck(_v, 5, 0, 1, 2, 3, 4, 5, 6, 7, 8) : _co.columnsRange); _ck(_v, 4, 0, currVal_3); var currVal_4 = _co.newColumn; _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selection && (_v.context.$implicit == 1)); var currVal_1 = (_co.selection && (_v.context.$implicit == 1)); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_TableStubContentBodyComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i4.ThemeColorHexOptionPipe, [[2, i5.ThemeContext]]), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_TableStubContentBodyComponent_1)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpad(3, 8)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.scrollable ? _ck(_v, 3, 0, 1, 2, 3, 4, 5, 6, 7, 8) : _co.rowsRange); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TableStubContentBodyComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-table-stub-content-body", [], null, null, null, View_TableStubContentBodyComponent_0, RenderType_TableStubContentBodyComponent)), i0.ɵdid(1, 638976, null, 0, i6.TableStubContentBodyComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TableStubContentBodyComponentNgFactory = i0.ɵccf("app-table-stub-content-body, [app-table-stub-content-body]", i6.TableStubContentBodyComponent, View_TableStubContentBodyComponent_Host_0, { columns: "columns", rows: "rows", leftPaddingColumn: "leftPaddingColumn", newColumn: "newColumn", action: "action", selection: "selection", animating: "animating", scrollable: "scrollable" }, {}, []);
export { TableStubContentBodyComponentNgFactory as TableStubContentBodyComponentNgFactory };
