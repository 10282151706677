import { Injector, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ActionControllerService } from '@modules/action-queries';
import { ITEM_OUTPUT, ViewContext, ViewContextElement } from '@modules/customize';
import { applyParamInput, ComputedDisplayField, createFormFieldFactory, DisplayField } from '@modules/fields';
import { getDefaultValue, Model } from '@modules/models';
import { EMPTY } from '@shared';
var ModelElementItemComponent = /** @class */ (function () {
    function ModelElementItemComponent(actionControllerService, injector) {
        this.actionControllerService = actionControllerService;
        this.injector = injector;
        this.columnActions = [];
        this.createField = createFormFieldFactory();
        this.labelButtons = [];
    }
    ModelElementItemComponent.prototype.ngOnInit = function () { };
    ModelElementItemComponent.prototype.ngOnDestroy = function () { };
    ModelElementItemComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.item || changes.columnActions) {
            var columnActions = this.columnActions.find(function (item) { return item.name == _this.item.name; });
            var actions = columnActions ? columnActions.actions : [];
            this.labelButtons = actions.map(function (item) {
                return {
                    click: _this.onExternalLinkClick(item),
                    tip: _this.resolveActionVerboseName(item),
                    icon: item.icon || 'power'
                };
            });
        }
    };
    ModelElementItemComponent.prototype.resolveFlexItemValue = function (column) {
        if (column.valueInput) {
            try {
                var value = applyParamInput(column.valueInput, {
                    context: this.context,
                    contextElement: this.contextElement
                    // field: { field: column.field, params: column.params }
                });
                if (value !== EMPTY) {
                    return value;
                }
            }
            catch (e) { }
        }
        return getDefaultValue(column);
    };
    ModelElementItemComponent.prototype.resolveItemColumn = function (column) {
        if (column instanceof ComputedDisplayField) {
            return this.resolveFlexItemValue(column);
        }
        else {
            return this.model.getAttribute(column.name);
        }
    };
    ModelElementItemComponent.prototype.resolveActionVerboseName = function (action) {
        if (action.verboseNameInput) {
            try {
                var value = applyParamInput(action.verboseNameInput, {
                    context: this.context,
                    contextElement: this.contextElement,
                    defaultValue: ''
                });
                if (value !== EMPTY) {
                    return value;
                }
            }
            catch (e) { }
        }
    };
    ModelElementItemComponent.prototype.onExternalLinkClick = function (action) {
        var _this = this;
        return function (e) {
            var _a;
            _this.context.clickEvent = e;
            if (action) {
                _this.actionControllerService
                    .execute(action, {
                    context: _this.context,
                    contextElement: _this.contextElement,
                    localContext: (_a = {},
                        _a[ITEM_OUTPUT] = _this.model.getAttributes(),
                        _a),
                    injector: _this.injector
                })
                    .subscribe();
            }
        };
    };
    return ModelElementItemComponent;
}());
export { ModelElementItemComponent };
