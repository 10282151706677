import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TintStyle, ViewContext, ViewContextElement } from '@modules/customize';
import { ListModelDescriptionDataSource } from '@modules/data-sources';
import { FilterItem2 } from '@modules/filters';

@Component({
  selector: 'app-filters-list',
  templateUrl: './filters-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FiltersListComponent implements OnInit {
  @Input() filters: FilterItem2[] = [];
  @Input() dataSource: ListModelDescriptionDataSource;
  @Input() bright = false;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Output() filtersUpdated = new EventEmitter<FilterItem2[]>();

  tintStyles = TintStyle;

  constructor() {}

  ngOnInit() {}
}
