import { Injectable, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import assign from 'lodash/assign';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, merge, Observable, of } from 'rxjs';
import { debounceTime, delay, map } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { ActionItem } from '@modules/actions';
import { AggregateFunc } from '@modules/charts';
import { Margin, ViewSettingsAction } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { CustomSelectItem, FieldsEditConfigurable, Option } from '@modules/field-components';
import {
  AggregateDisplayField,
  AnyField,
  BaseField,
  DefaultType,
  EditableField,
  FieldType,
  FilterableField,
  FlexField,
  Input,
  LookupDisplayField,
  validateValidatorParams,
  ValidatorType
} from '@modules/fields';
import { ModelDescriptionService, ModelDescriptionStore } from '@modules/model-queries';
import { ModelDbField, ModelDescription, ModelFlexField, traverseModelPath } from '@modules/models';
import { FieldInputControl } from '@modules/parameters';
import { CurrentProjectStore } from '@modules/projects';
import { controlValid, controlValue, isSet } from '@shared';

import { MarginControl } from '../margin-control/margin-control.component';

export interface CustomizeColumnOptions {
  field?: BaseField & EditableField;
  modelDescription?: ModelDescription;
  actions?: ViewSettingsAction[];
  onChangeActions?: ActionItem[];
  configurable: FieldsEditConfigurable;
  visibleInput?: Input;
  disableInput?: Input;
  title?: string;
  titleEditable?: boolean;
  titleAutoUpdate?: boolean;
  titleCleanValue?: (value: string) => string;
  tooltipEditable?: boolean;
  tooltip?: string;
  margin?: Margin;
  firstInit?: boolean;
}

export interface CustomizeColumnResult {
  field: BaseField;
  actions: ViewSettingsAction[];
  onChangeActions: ActionItem[];
  visibleInput?: Input;
  disableInput?: Input;
  title?: string;
  tooltip?: string;
  margin?: Margin;
}

export function isRegexValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let regex: RegExp;

    try {
      regex = new RegExp(control.value);
    } catch (e) {
      return { local: ['Incorrect regular expression'] };
    }

    if (!regex) {
      return { local: ['Incorrect regular expression'] };
    }
    return null;
  };
}

export function isNumberValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === null) {
      return null;
    }

    const isNumber = /^[0-9]*$/.test(control.value);
    if (!isNumber) {
      return { local: ['Is not a number'] };
    }
    return null;
  };
}

export function validateValidatorType(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return;
    }

    const parent = control.parent as CustomizeBarColumnEditForm;

    if (!parent) {
      return;
    }

    if (parent.options.configurable.editable) {
      const params = parent.getValidatorParams();

      if (!validateValidatorParams(control.value, params)) {
        return { local: ['Is not configured'] };
      }
    }
  };
}

export function validateActions(): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    const parent = control.parent as CustomizeBarColumnEditForm;

    if (!parent) {
      return of(null);
    }

    if (!control.value || !control.value.length) {
      return of(null);
    }

    return combineLatest(control.value.map(item => parent.elementConfigurationService.isActionConfigured(item))).pipe(
      map(result => {
        if (result.some(configured => !configured)) {
          return { required: true };
        }
      })
    );
  };
}

@Injectable()
export class CustomizeBarColumnEditForm extends FormGroup implements OnDestroy {
  controls: {
    verbose_name: FormControl;
    description: FormControl;
    field: FormControl;
    required: FormControl;
    editable: FormControl;
    filterable: FormControl;
    sortable: FormControl;
    placeholder: FormControl;
    actions: FormControl;
    on_change_actions: FormControl;
    default_type: FormControl;
    default_value: FormControl;
    params: FormControl;
    value_input: FieldInputControl;
    validator_type: FormControl;
    validator_custom: FormControl;
    validator_custom_input: FieldInputControl;
    validator_error: FormControl;
    validator_length: FormControl;
    validator_min_length: FormControl;
    validator_max_length: FormControl;
    validator_min_value: FormControl;
    validator_max_value: FormControl;
    reset_enabled: FormControl;
    visible_input: FieldInputControl;
    disable_input: FieldInputControl;
    lookup_path: FormControl;
    aggregate_path: FormControl;
    aggregate_func: FormControl;
    aggregate_column: FormControl;
    title: FormControl;
    tooltip: FormControl;
    margin: MarginControl;
  };
  options: CustomizeColumnOptions;
  editableField = false;
  filterableField = false;
  modelField = false;

  defaultTypeOptions = [
    { value: undefined, name: 'None' },
    { value: DefaultType.Value, name: 'Fixed value' },
    { value: DefaultType.DatetimeNow, name: 'Current time' },
    { value: DefaultType.UUID, name: 'UUID' }
  ];

  validatorOptions: CustomSelectItem<ValidatorType>[] = [
    { option: { name: 'No validation', value: null } },
    { option: { name: 'Email', value: ValidatorType.Email, icon: 'email' } },
    { option: { name: 'Phone', value: ValidatorType.Phone, icon: 'phone' } },
    { option: { name: 'Text length', value: ValidatorType.LengthRange, icon: 'text' } },
    { option: { name: 'Number in range', value: ValidatorType.ValueRange, icon: 'number' } },
    { option: { name: 'Use Regular expression', value: ValidatorType.Custom, icon: 'one_of' }, orange: true },
    { option: { name: 'Use Formula/JavaScript', value: ValidatorType.CustomInput, icon: 'function' }, orange: true }
  ];

  aggregateFuncOptions: {
    value: AggregateFunc;
    name: string;
    hasField?: boolean;
    valueDisplay: (field: string) => string;
  }[] = [
    { value: AggregateFunc.Count, name: 'Number of records', valueDisplay: () => `Number of records` },
    { value: AggregateFunc.Sum, name: 'Sum of field', hasField: true, valueDisplay: field => `Sum of ${field}` },
    {
      value: AggregateFunc.Min,
      name: 'Minimum of field',
      hasField: true,
      valueDisplay: field => `Minimum of ${field}`
    },
    {
      value: AggregateFunc.Max,
      name: 'Maximum of field',
      hasField: true,
      valueDisplay: field => `Maximum of ${field}`
    },
    { value: AggregateFunc.Avg, name: 'Average of field', hasField: true, valueDisplay: field => `Average of ${field}` }
  ];

  constructor(
    private formUtils: FormUtils,
    private currentProjectStore: CurrentProjectStore,
    private modelDescriptionService: ModelDescriptionService,
    private modelDescriptionStore: ModelDescriptionStore,
    private fb: FormBuilder,
    public elementConfigurationService: ElementConfigurationService
  ) {
    super({
      verbose_name: new FormControl(''),
      description: new FormControl(''),
      field: new FormControl('', Validators.required),
      required: new FormControl(false),
      editable: new FormControl(false),
      filterable: new FormControl(false),
      sortable: new FormControl(false),
      placeholder: new FormControl(''),
      actions: new FormControl([], undefined, validateActions()),
      on_change_actions: new FormControl([], undefined, validateActions()),
      default_type: new FormControl(''),
      default_value: new FormControl(''),
      params: new FormControl(''),
      value_input: new FieldInputControl({ name: 'value' }),
      validator_type: new FormControl(null, validateValidatorType()),
      validator_custom: new FormControl('', isRegexValidator()),
      validator_custom_input: new FieldInputControl({ name: 'value' }),
      validator_error: new FormControl(''),
      validator_length: new FormControl('', isNumberValidator()),
      validator_min_length: new FormControl('', isNumberValidator()),
      validator_max_length: new FormControl('', isNumberValidator()),
      validator_min_value: new FormControl('', isNumberValidator()),
      validator_max_value: new FormControl('', isNumberValidator()),
      visible_input: new FieldInputControl({ name: 'value' }),
      disable_input: new FieldInputControl({ name: 'value' }),

      lookup_path: new FormControl(),
      aggregate_path: new FormControl(),
      aggregate_func: new FormControl(),
      aggregate_column: new FormControl(),

      reset_enabled: new FormControl(false),
      title: new FormControl(''),
      tooltip: new FormControl(''),
      margin: new MarginControl()
    });

    merge(
      ...[
        this.controls.validator_custom,
        this.controls.validator_custom_input,
        this.controls.validator_length,
        this.controls.validator_min_length,
        this.controls.validator_max_length,
        this.controls.validator_min_value,
        this.controls.validator_max_value
      ].map(item => item.valueChanges)
    )
      .pipe(delay(0), untilDestroyed(this))
      .subscribe(() => {
        this.controls.validator_type.updateValueAndValidity();
      });
  }

  ngOnDestroy(): void {}

  init(options: CustomizeColumnOptions) {
    this.options = options;

    let value = {
      ...(options.field
        ? {
            verbose_name: options.field.verboseName,
            field: options.field.field,
            params: options.field.params,
            description: options.field.description
          }
        : {}),
      ...(options.configurable.margin ? { margin: options.margin } : {}),
      ...(options.configurable.visible
        ? { visible_input: options.visibleInput ? options.visibleInput.serialize() : {} }
        : {}),
      ...(options.configurable.disable
        ? { disable_input: options.disableInput ? options.disableInput.serialize() : {} }
        : {})
    };

    if (options.configurable.value) {
      const flexField = options.field as FlexField;
      if (flexField && flexField.valueInput) {
        value['value_input'] = flexField.valueInput ? flexField.valueInput.serialize() : {};
      }
    }

    if (options.configurable.editable) {
      const editableField = options.field as EditableField;
      const validatorParams = editableField.validatorParams || {};

      if (editableField) {
        value = assign(value, {
          editable: editableField.editable,
          required: !!editableField.required,
          default_type: editableField.defaultType,
          default_value: editableField.defaultValue,
          placeholder: editableField.placeholder,
          validator_type: editableField.validatorType ? editableField.validatorType : null,
          validator_custom: editableField.validatorType === ValidatorType.Custom ? validatorParams['regex'] : null,
          validator_custom_input:
            editableField.validatorType === ValidatorType.CustomInput ? validatorParams['input'] : {},
          validator_error: this.isValidatorTypeHasError(editableField.validatorType) ? validatorParams['error'] : null,
          validator_length: isSet(validatorParams['validator_length']) ? validatorParams['validator_length'] : null,
          validator_min_length: isSet(validatorParams['validator_min_length'])
            ? validatorParams['validator_min_length']
            : null,
          validator_max_length: isSet(validatorParams['validator_max_length'])
            ? validatorParams['validator_max_length']
            : null,
          validator_min_value: isSet(validatorParams['validator_min_value'])
            ? validatorParams['validator_min_value']
            : null,
          validator_max_value: isSet(validatorParams['validator_max_value'])
            ? validatorParams['validator_max_value']
            : null,
          reset_enabled: editableField.resetEnabled
        });
      }

      this.editableField = true;
    }

    if (options.configurable.sortable) {
      const filterableField = options.field as FilterableField;
      if (filterableField) {
        value = assign(value, {
          filterable: filterableField.filterable,
          sortable: filterableField.sortable
        });
      }

      this.filterableField = true;
    }

    if (options.configurable.action) {
      value['actions'] = options.actions || [];
    }

    if (options.configurable.onChangeActions) {
      value['on_change_actions'] = options.onChangeActions || [];
    }

    if (options.field instanceof ModelDbField || options.field instanceof ModelFlexField) {
      //   value = assign(value, {
      //     filterable: field.filterable,
      //     sortable: field.sortable
      //   });
      this.modelField = true;
    }

    // if (field instanceof ModelDbField) {
    //   value = assign(value, {
    //     editable: field.editable
    //   });
    //   this.modelDbField = true;
    // }

    if (options.titleEditable) {
      value['title'] = options.title;
    }

    if (options.tooltipEditable) {
      value['tooltip'] = options.tooltip;
    }

    if (options.configurable.lookup) {
      const lookupField = options.field as LookupDisplayField;
      if (lookupField) {
        value['lookup_path'] = lookupField.path;
      }
    }

    if (options.configurable.aggregate) {
      const aggregateField = options.field as AggregateDisplayField;
      if (aggregateField) {
        value['aggregate_path'] = aggregateField.path;
        value['aggregate_func'] = aggregateField.func;
        value['aggregate_column'] = aggregateField.column;
      }
    }

    this.patchValue(value);

    if (!options.firstInit) {
      this.markAsDirty();
    }

    if (options.titleEditable && options.titleAutoUpdate) {
      this.controls.verbose_name.valueChanges.subscribe(item => {
        if (options.titleCleanValue) {
          item = options.titleCleanValue(item);
        }

        this.controls.title.patchValue(item);
      });
    }
  }

  isConfigured(instance: BaseField): Observable<boolean> {
    return this.elementConfigurationService.isColumnConfigured(instance, {
      editable: this.options.configurable.editable,
      restrictDemo: true
    });
  }

  isResetEnabledSupported() {
    return [
      FieldType.Text,
      FieldType.Password,
      FieldType.Number,
      FieldType.URL,
      FieldType.DateTime,
      FieldType.Time,
      FieldType.Select,
      FieldType.MultipleSelect,
      FieldType.RelatedModel
    ].includes(this.controls['field'].value);
  }

  isValidatorTypeHasOption(type: ValidatorType) {
    return [
      ValidatorType.Length,
      ValidatorType.MaxLength,
      ValidatorType.MinLength,
      ValidatorType.LengthRange,
      ValidatorType.ValueRange,
      ValidatorType.Custom,
      ValidatorType.CustomInput
    ].includes(type);
  }

  isValidatorTypeHasError(type: ValidatorType) {
    return [ValidatorType.Custom, ValidatorType.CustomInput].includes(type);
  }

  getValidatorParams(): Object {
    return {
      regex: this.controls.validator_custom.value,
      input: this.controls.validator_custom_input.value,
      error: this.controls.validator_error.value,
      validator_length: this.controls.validator_length.value,
      validator_min_length: this.controls.validator_min_length.value,
      validator_max_length: this.controls.validator_max_length.value,
      validator_min_value: this.controls.validator_min_value.value,
      validator_max_value: this.controls.validator_max_value.value
    };
  }

  getLookupPath$(): Observable<string[]> {
    return combineLatest(controlValue<string[]>(this.controls.lookup_path), this.modelDescriptionStore.get()).pipe(
      map(([path, modelDescriptions]) => {
        const result = traverseModelPath(this.options.modelDescription, path, modelDescriptions);
        return result ? result.map(item => item.verboseName) : undefined;
      })
    );
  }

  getAggregatePath$(): Observable<string[]> {
    return combineLatest(controlValue<string[]>(this.controls.aggregate_path), this.modelDescriptionStore.get()).pipe(
      map(([path, modelDescriptions]) => {
        const result = traverseModelPath(this.options.modelDescription, path, modelDescriptions);
        return result ? result.map(item => item.verboseName) : undefined;
      })
    );
  }

  getAggregateFuncLabel$(): Observable<string> {
    return combineLatest(
      controlValue<AggregateFunc>(this.controls.aggregate_func),
      controlValue<string>(this.controls.aggregate_column)
    ).pipe(
      map(([func, column]) => {
        const funcOption = this.aggregateFuncOptions.find(item => item.value == func);

        if (!isSet(func) && isSet(column)) {
          return `Pre-aggregated Field - ${column}`;
        } else if (funcOption) {
          return funcOption.valueDisplay(column);
        }
      })
    );
  }

  getAggregateColumnOptions$(): Observable<Option[]> {
    return combineLatest(controlValue<string[]>(this.controls.aggregate_path), this.modelDescriptionStore.get()).pipe(
      map(([path, modelDescriptions]) => {
        const modelPath = traverseModelPath(this.options.modelDescription, path, modelDescriptions);
        const target = modelPath ? modelPath[path.length - 1] : undefined;
        if (!target || !target.relatedModel) {
          return [];
        }

        return target.relatedModel.dbFields.map(item => {
          return {
            value: item.name,
            name: item.verboseName || item.name,
            icon: item.fieldDescription.icon
          };
        });
      })
    );
  }

  controlsValid$(controls: AbstractControl[]): Observable<boolean> {
    if (!controls.length) {
      return of(true);
    }

    return combineLatest(controls.map(control => controlValid(control))).pipe(
      map(result => result.every(item => item))
      // debounceTime(60) TODO: Too long wait with debounceTime
    );
  }

  actionsValid$(): Observable<boolean> {
    return this.controlsValid$([
      ...(this.options.configurable.action ? [this.controls.actions] : []),
      ...(this.options.configurable.onChangeActions ? [this.controls.on_change_actions] : [])
    ]);
  }

  submit(): CustomizeColumnResult {
    const value = this.value;
    const instance: AnyField = this.options.field ? cloneDeep(this.options.field) : {};

    instance.verboseName = value.verbose_name;
    instance.field = value.field;

    if (this.options.configurable.value) {
      instance.valueInput = value.value_input ? new Input().deserialize(value.value_input) : undefined;
    }

    if (this.editableField) {
      instance.editable = value.editable;
      instance.required = value.required;
      instance.defaultType = value.default_type;
      instance.defaultValue = value.default_value;
      instance.placeholder = value.placeholder;
      instance.validatorType = value.validator_type;
      instance.validatorParams = this.getValidatorParams();
      instance.resetEnabled = value.reset_enabled;
    }

    if (this.filterableField) {
      instance.filterable = value.filterable;
      instance.sortable = value.sortable;
    }

    if (this.modelField) {
      //   instance['filterable'] = value.filterable;
      //   instance['sortable'] = value.sortable;
      instance['updateFieldDescription']();
    }

    // if (this.modelDbField) {
    //   instance['editable'] = value.editable;
    // }

    instance.params = value.params;
    instance.description = value.description;

    if (this.options.configurable.lookup && instance instanceof LookupDisplayField) {
      instance.path = value.lookup_path;
    }

    if (this.options.configurable.aggregate && instance instanceof AggregateDisplayField) {
      instance.path = value.aggregate_path;
      instance.func = value.aggregate_func;
      instance.column = value.aggregate_column;
    }

    return {
      field: instance,
      actions: value.actions,
      onChangeActions: value.on_change_actions,
      visibleInput: value.visible_input ? new Input().deserialize(value.visible_input) : undefined,
      disableInput: value.disable_input ? new Input().deserialize(value.disable_input) : undefined,
      title: value.title,
      tooltip: isSet(value.tooltip) ? value.tooltip.trim() : undefined,
      margin: value.margin
    };
  }
}
