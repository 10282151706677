import { Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CustomViewSource, CustomViewsStore } from '@modules/custom-views';
import { ChartType, ChartWidget, ValueWidget, WidgetType } from '@modules/dashboard';
import { FieldType, fileFieldTypes, getFieldDescriptionByType } from '@modules/fields';
import { ListLayoutType } from '@modules/layouts';
import { isSet } from '@shared';

import { ElementType } from '../data/elements/element-type';
import { ElementItem } from '../data/elements/items/base';
import { CustomElementItem } from '../data/elements/items/custom';
import { FieldElementItem } from '../data/elements/items/field';
import { ListElementItem } from '../data/elements/items/list-element';
import { WidgetElementItem } from '../data/elements/items/widget';

export function isElementTypeContainer(type: ElementType): boolean {
  return [
    ElementType.Section,
    ElementType.Card,
    ElementType.Stack,
    ElementType.Tabs,
    ElementType.Columns,
    ElementType.Form
  ].includes(type);
}

export function isElementTypeAddable(type: ElementType): boolean {
  return ![ElementType.FormSubmit].includes(type);
}

export function isElementTypeDeletable(type: ElementType): boolean {
  return ![ElementType.FormSubmit].includes(type);
}

export function isElementTypeCustomizable(type: ElementType): boolean {
  return [
    ElementType.Field,
    ElementType.Action,
    ElementType.ActionGroup,
    ElementType.ActionDropdown,
    ElementType.List,
    ElementType.Model,
    ElementType.Form,
    ElementType.FormSubmit,
    ElementType.Widget,
    ElementType.Image,
    ElementType.Custom,
    ElementType.Columns,
    ElementType.Card,
    ElementType.Stack,
    ElementType.Tabs,
    ElementType.Text,
    ElementType.Back,
    ElementType.IFrame,
    ElementType.QrCode,
    ElementType.BarCode,
    ElementType.Scanner,
    ElementType.Alert,
    ElementType.FileViewer,
    ElementType.RangeSlider,
    ElementType.Spacing,
    ElementType.Separator,
    ElementType.Steps,
    ElementType.Filter
  ].includes(type);
}

export function isElementTypeCustomizeOnAdd(type: ElementType): boolean {
  return [
    ElementType.Field,
    ElementType.Action,
    ElementType.ActionGroup,
    ElementType.ActionDropdown,
    ElementType.List,
    ElementType.Model,
    ElementType.Form,
    ElementType.Widget,
    ElementType.Image,
    ElementType.Custom,
    // ElementType.Columns,
    // ElementType.Card,
    // ElementType.Stack,
    // ElementType.Tabs,
    ElementType.Text,
    ElementType.Back,
    ElementType.IFrame,
    ElementType.QrCode,
    ElementType.Alert,
    ElementType.BarCode,
    ElementType.Scanner,
    ElementType.FileViewer,
    ElementType.RangeSlider,
    ElementType.Spacing,
    ElementType.Separator,
    ElementType.Steps,
    ElementType.Filter
  ].includes(type);
}

export function isElementTypeCustomizableAlways(type: ElementType): boolean {
  return [ElementType.Title, ElementType.Text].includes(type);
}

export function isElementTypeGroupable(type: ElementType): boolean {
  return [
    ElementType.Field,
    ElementType.Action,
    ElementType.ActionGroup,
    ElementType.ActionDropdown,
    ElementType.List,
    ElementType.Back,
    ElementType.Model,
    // ElementType.Form,
    ElementType.FormSubmit,
    ElementType.Title,
    ElementType.Text,
    ElementType.Image,
    ElementType.IFrame,
    ElementType.QrCode,
    ElementType.BarCode,
    ElementType.Scanner,
    ElementType.Alert,
    ElementType.FileViewer,
    ElementType.RangeSlider,
    ElementType.Separator,
    ElementType.Steps,
    // ElementType.Filter,
    ElementType.Custom
  ].includes(type);
}

export function isElementTypeWrappable(type: ElementType): boolean {
  return [
    ElementType.Card,
    ElementType.Stack,
    // ElementType.Tabs,
    ElementType.Field,
    ElementType.Action,
    ElementType.ActionGroup,
    ElementType.ActionDropdown,
    ElementType.List,
    ElementType.Back,
    ElementType.Model,
    // ElementType.Form,
    ElementType.FormSubmit,
    ElementType.Widget,
    ElementType.Title,
    ElementType.Text,
    ElementType.Image,
    ElementType.IFrame,
    ElementType.QrCode,
    ElementType.BarCode,
    ElementType.Scanner,
    ElementType.Alert,
    ElementType.FileViewer,
    ElementType.RangeSlider,
    ElementType.Separator,
    ElementType.Steps,
    // ElementType.Filter,
    ElementType.Custom
  ].includes(type);
}

export function isElementTypeAlignHorizontal(type: ElementType): boolean {
  return [
    ElementType.Field,
    ElementType.Action,
    ElementType.ActionGroup,
    ElementType.ActionDropdown,
    ElementType.Back,
    ElementType.Image,
    ElementType.FormSubmit,
    ElementType.IFrame,
    ElementType.QrCode,
    ElementType.BarCode,
    ElementType.Scanner,
    ElementType.Alert,
    ElementType.FileViewer,
    ElementType.RangeSlider,
    ElementType.Custom,
    ElementType.Columns
  ].includes(type);
}

export function isElementTypeAlignVertical(type: ElementType): boolean {
  return [
    ElementType.Field,
    ElementType.Action,
    ElementType.ActionGroup,
    ElementType.ActionDropdown,
    ElementType.Back,
    ElementType.Image,
    ElementType.IFrame,
    ElementType.QrCode,
    ElementType.BarCode,
    ElementType.Scanner,
    ElementType.Alert,
    ElementType.FileViewer,
    ElementType.RangeSlider,
    ElementType.Widget,
    ElementType.Custom
  ].includes(type);
}

export enum ElementPaddingType {
  Normal = 'normal',
  DoubleAfter = 'double_after',
  DoubleBefore = 'double_before'
}

export interface ElementPadding {
  horizontal?: ElementPaddingType;
  vertical?: ElementPaddingType;
}

export function elementTypePadding(type: ElementType): ElementPadding {
  if (
    [
      ElementType.Field,
      ElementType.Action,
      ElementType.ActionGroup,
      ElementType.ActionDropdown,
      ElementType.List,
      ElementType.Model,
      // ElementType.Form,
      ElementType.FormSubmit,
      ElementType.Widget,
      ElementType.Back,
      ElementType.Title,
      ElementType.Text,
      ElementType.Image,
      ElementType.IFrame,
      ElementType.QrCode,
      ElementType.BarCode,
      ElementType.Scanner,
      ElementType.Alert,
      ElementType.FileViewer,
      ElementType.RangeSlider,
      ElementType.Separator,
      ElementType.Steps,
      // ElementType.Filter,
      ElementType.Custom
    ].includes(type)
  ) {
    return { horizontal: ElementPaddingType.Normal, vertical: ElementPaddingType.Normal };
  } else {
    return {};
  }
}

export function elementTypeResize(
  type: ElementType,
  options: { injector: Injector; element?: ElementItem }
): { width: boolean; height: boolean } | Observable<{ width: boolean; height: boolean }> {
  if (
    [
      ElementType.Image,
      ElementType.IFrame,
      ElementType.QrCode,
      ElementType.Scanner,
      ElementType.Alert,
      ElementType.FileViewer
    ].includes(type)
  ) {
    return {
      width: true,
      height: true
    };
  } else if ([ElementType.RangeSlider].includes(type)) {
    return {
      width: true,
      height: false
    };
  } else if ([ElementType.Field].includes(type)) {
    const fieldElement = options.element as FieldElementItem;
    const field = fieldElement.settings ? fieldElement.settings.field : undefined;
    const params = fieldElement.settings && fieldElement.settings.params ? fieldElement.settings.params : {};
    const editable = fieldElement.settings ? fieldElement.settings.editable || false : false;

    if ([FieldType.Signature].includes(field)) {
      return {
        width: true,
        height: true
      };
    } else if ([FieldType.RichText].includes(field) && editable) {
      return {
        width: true,
        height: true
      };
    } else if (fileFieldTypes.includes(field)) {
      const multiple = !!params['multiple'];
      return {
        width: true,
        height: !multiple
      };
    } else {
      return {
        width: true,
        height: false
      };
    }
  } else if ([ElementType.Widget, ElementType.BarCode, ElementType.Spacing].includes(type)) {
    return {
      width: false,
      height: true
    };
  } else if ([ElementType.List].includes(type)) {
    const listElement = options.element as ListElementItem;
    const layout = listElement.layouts ? listElement.layouts[0] : undefined;

    return {
      width: false,
      height: layout && [ListLayoutType.Map, ListLayoutType.KanbanBoard, ListLayoutType.Calendar].includes(layout.type)
    };
  } else if (type == ElementType.Custom) {
    const customElement = options.element as CustomElementItem;
    const customViewsStore = options.injector.get<CustomViewsStore>(CustomViewsStore);

    if (isSet(customElement.customView)) {
      return customViewsStore.getDetail(customElement.customView).pipe(
        map(customView => {
          if (customView && customView.source == CustomViewSource.View && customView.view) {
            return {
              width: customView.view.widthResize.enabled,
              height: customView.view.heightResize.enabled
            };
          } else {
            return {
              width: true,
              height: true
            };
          }
        })
      );
    } else {
      return {
        width: true,
        height: true
      };
    }
  }

  return {
    width: false,
    height: false
  };
}

export function elementTypeResizeStep(type: ElementType): { width?: number; height?: number } {
  if ([ElementType.Spacing].includes(type)) {
    return { width: 5, height: 5 };
  } else {
    return { width: 50, height: 50 };
  }
}

export function elementTypeResizeMin(
  type: ElementType,
  options: { injector: Injector; element?: ElementItem }
): { width?: number; height?: number } | Observable<{ width?: number; height?: number }> {
  if (type == ElementType.Field) {
    const fieldElement = options.element as FieldElementItem;
    const field = fieldElement.settings ? fieldElement.settings.field : undefined;
    const editable = fieldElement.settings ? fieldElement.settings.editable || false : false;
    const params = fieldElement.settings ? fieldElement.settings.params || {} : {};
    // horizontal: 20 + 20 = 40
    // vertical: 15 + 15 = 30

    if ([FieldType.Number].includes(field)) {
      return {
        width: 60
      };
    } else if (field == FieldType.RichText && editable) {
      return {
        width: 560,
        height: 320
      };
    } else if (field == FieldType.DateTime && params['time'] !== false) {
      return {
        width: 160
      };
    } else if (field == FieldType.JSON && (!params['display_fields'] || !params['structure'])) {
      return {
        width: 260
      };
    } else if ([FieldType.File].includes(field)) {
      return {
        width: 160,
        height: 120
      };
    } else if ([FieldType.Image].includes(field)) {
      return {
        width: 160,
        height: 70
      };
    } else if ([FieldType.Audio].includes(field)) {
      return {
        width: 310,
        height: 80
      };
    } else if ([FieldType.Video].includes(field)) {
      return {
        width: 240,
        height: 170
      };
    } else if (field == FieldType.Location) {
      return {
        width: 210
      };
    } else if (field == FieldType.Color) {
      return {
        width: 160
      };
    } else if (field == FieldType.Rating) {
      return {
        width: 130
      };
    } else if (field == FieldType.Signature) {
      return {
        width: 200,
        height: 150
      };
    } else {
      return {
        width: 110
      };
    }
  } else if (type == ElementType.Image) {
    return {
      width: 30,
      height: 30
    };
  } else if (type == ElementType.Widget) {
    const widgetElement = options.element as WidgetElementItem;
    const widgetType = widgetElement.widget ? widgetElement.widget.type : undefined;

    if (widgetType == WidgetType.Value) {
      return {
        height: 70
      };
    } else {
      return {
        height: 120
      };
    }
  } else if (type == ElementType.List) {
    const listElement = options.element as ListElementItem;
    const layout = listElement.layouts && listElement.layouts.length ? listElement.layouts[0].type : undefined;

    if (layout == ListLayoutType.Map) {
      return {
        height: 170
      };
    } else if (layout == ListLayoutType.KanbanBoard) {
      return {
        height: 270
      };
    } else if (layout == ListLayoutType.Calendar) {
      return {
        height: 370
      };
    }
  } else if ([ElementType.IFrame, ElementType.QrCode, ElementType.FileViewer].includes(type)) {
    return {
      width: 60,
      height: 60
    };
  } else if ([ElementType.BarCode].includes(type)) {
    return {
      height: 30
    };
  } else if ([ElementType.Scanner].includes(type)) {
    return {
      width: 200,
      height: 200
    };
  } else if ([ElementType.Alert].includes(type)) {
    return {
      width: 100,
      height: 40
    };
  } else if ([ElementType.RangeSlider].includes(type)) {
    return {
      width: 110
    };
  } else if (type == ElementType.Custom) {
    const defaultWidth = 20;
    const defaultHeight = 20;
    const customElement = options.element as CustomElementItem;
    const customViewsStore = options.injector.get<CustomViewsStore>(CustomViewsStore);

    if (isSet(customElement.customView)) {
      return customViewsStore.getDetail(customElement.customView).pipe(
        map(customView => {
          if (customView && customView.source == CustomViewSource.View && customView.view) {
            return {
              width: isSet(customView.view.widthResize.min) ? customView.view.widthResize.min : defaultWidth,
              height: isSet(customView.view.heightResize.min) ? customView.view.heightResize.min : defaultHeight
            };
          } else {
            return {
              width: defaultWidth,
              height: defaultHeight
            };
          }
        })
      );
    } else {
      return {
        width: defaultWidth,
        height: defaultHeight
      };
    }
  }

  return {};
}

export function elementTypeResizeMax(
  type: ElementType,
  options: { injector: Injector; element?: ElementItem }
): { width?: number; height?: number } | Observable<{ width?: number; height?: number }> {
  if (type == ElementType.Custom) {
    const customElement = options.element as CustomElementItem;
    const customViewsStore = options.injector.get<CustomViewsStore>(CustomViewsStore);

    if (isSet(customElement.customView)) {
      return customViewsStore.getDetail(customElement.customView).pipe(
        map(customView => {
          if (customView && customView.source == CustomViewSource.View && customView.view) {
            return {
              ...(isSet(customView.view.widthResize.max) && { width: customView.view.widthResize.max }),
              ...(isSet(customView.view.heightResize.max) && { height: customView.view.heightResize.max })
            };
          } else {
            return {};
          }
        })
      );
    }
  }

  return {};
}

export function elementImage(element: ElementItem): string {
  if (!element) {
    return;
  }

  if (element.type == ElementType.Card) {
    return 'card_layout';
  } else if (element.type == ElementType.Columns) {
    return 'columns_layout';
  } else if (element.type == ElementType.Tabs) {
    return 'tabs_layout';
  } else if (element.type == ElementType.Spacing) {
    return 'spacing';
  } else if (element.type == ElementType.Stack) {
    return 'stack_layout';
  } else if (element.type == ElementType.Separator) {
    return 'separator';
  } else if (element.type == ElementType.Section) {
    return 'section_layout';
  } else if (element.type == ElementType.Text) {
    return 'text';
  } else if (element.type == ElementType.Image) {
    return 'image';
  } else if (element.type == ElementType.Action) {
    return 'button';
  } else if (element.type == ElementType.ActionGroup) {
    return 'button_group';
  } else if (element.type == ElementType.ActionDropdown) {
    return 'button_dropdown';
  } else if (element.type == ElementType.Back) {
    return 'back';
  } else if (element instanceof ListElementItem) {
    const layout = element.listLayout;

    if (!layout) {
      return;
    } else if (layout.type == ListLayoutType.Table) {
      return 'table_list';
    } else if (layout.type == ListLayoutType.Map) {
      return 'map_list';
    } else if (layout.type == ListLayoutType.KanbanBoard) {
      return 'kanban_list';
    } else if (layout.type == ListLayoutType.Calendar) {
      return 'calendar_list';
    } else if (layout.type == ListLayoutType.Grid) {
      return 'gallery_list2';
    } else if (layout.type == ListLayoutType.Carousel) {
      return 'carousel_list';
    } else if (layout.type == ListLayoutType.Timeline) {
      return 'timeline_list';
    }
  } else if (element instanceof WidgetElementItem) {
    const type = element.widget ? element.widget.type : undefined;

    if (!type) {
      return;
    } else if (element.widget instanceof ChartWidget) {
      const chartType = element.widget.chartType;

      if (!chartType) {
        return;
      } else if (chartType == ChartType.Line) {
        return 'line_chart';
      } else if (chartType == ChartType.Bar) {
        return 'bar_chart';
      } else if (chartType == ChartType.StackedBar) {
        return 'stacked_bar_chart';
      } else if (chartType == ChartType.Pie) {
        return 'pie_chart';
      } else if (chartType == ChartType.Doughnut) {
        return 'doughnut_chart';
      } else if (chartType == ChartType.Radar) {
        return 'radar_chart';
      } else if (chartType == ChartType.PolarArea) {
        return 'polar_area_chart';
      } else if (chartType == ChartType.Scatter) {
        return 'scatter_chart';
      } else if (chartType == ChartType.Bubble) {
        return 'bubble_chart';
      }
    } else if (element.widget instanceof ValueWidget) {
      return 'single_value_2';
    }
  } else if (element.type == ElementType.Form) {
    return 'form';
  } else if (element.type == ElementType.Model) {
    return 'detail';
  } else if (element instanceof FieldElementItem) {
    const field = element.settings ? element.settings.field : undefined;
    const fieldDescription = getFieldDescriptionByType(field);

    return fieldDescription.image;
  } else if (element.type == ElementType.IFrame) {
    return 'iframe';
  } else if (element.type == ElementType.QrCode) {
    return 'qr_code';
  } else if (element.type == ElementType.BarCode) {
    return 'bar_code';
  } else if (element.type == ElementType.Scanner) {
    return 'scanner';
  } else if (element.type == ElementType.Alert) {
    return 'alert';
  } else if (element.type == ElementType.FileViewer) {
    return 'file_viewer';
  } else if (element.type == ElementType.RangeSlider) {
    return 'range_slider_field';
  } else if (element.type == ElementType.Custom) {
    return 'plugin';
  }
}
