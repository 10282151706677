var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, Injector, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import isPlainObject from 'lodash/isPlainObject';
import toPairs from 'lodash/toPairs';
import uniq from 'lodash/uniq';
import values from 'lodash/values';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { ActionControllerService } from '@modules/action-queries';
import { ServerRequestError } from '@modules/api';
import { CHART_COLORS, DataGroup, datasetGroupDateLookups, DatasetGroupLookup, datasetGroupTimeLookups } from '@modules/charts';
import { DataSourceGeneratorService } from '@modules/customize-generators';
import { ChartType, ChartWidget, DATASET_INDEX_OUTPUT, GROUP1_OUTPUT, GROUP2_OUTPUT, GROUP3_OUTPUT, GROUP_INDEX_OUTPUT, singleColorDatasetChartTypes, VALUE_OUTPUT } from '@modules/dashboard';
import { WidgetDataSourceService } from '@modules/dashboard-queries';
import { ChartWidgetDataSource, DataSourceType } from '@modules/data-sources';
import { applyParamInput$, applyParamInputs$, FieldType, LOADING_VALUE, NOT_SET_VALUE } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ascComparator, controlValue, getCircleIndex, getTimezoneOffset, isSet, parseNumber } from '@shared';
import { WidgetComponent } from '../widget/widget.component';
import { barChartSampleData } from './bar-chart-sample-data';
import { bubbleSampleData } from './bubble-chart-sample-data';
import { doughnutChartSampleData } from './doughnut-chart-sample-data';
import { lineChartSampleData } from './line-chart-sample-data';
import { pieChartSampleData } from './pie-chart-sample-data';
import { radarChartSampleData } from './radar-chart-sample-data';
import { scatterSampleData } from './scatter-chart-sample-data';
export function serializeDataSourceColumns(columns) {
    return columns
        .filter(function (item) { return !item.flex; })
        .map(function (item) {
        return {
            name: item.name
        };
    })
        .sort(function (lhs, rhs) { return ascComparator(lhs.name, rhs.name); });
}
function getElementStateFetch(state) {
    return {
        datasets: state.datasets
            ? state.datasets.map(function (dataset) {
                return {
                    dataSource: dataset.dataSource
                        ? __assign({}, dataset.dataSource.serialize(), { columns: serializeDataSourceColumns(dataset.dataSource.columns) }) : undefined,
                    staticData: dataset.staticData,
                    params: dataset.params
                };
            })
            : undefined,
        inputsLoading: state.inputsLoading,
        inputsNotSet: state.inputsNotSet,
        datasetColumn: state.datasetColumn
    };
}
function getElementStateDisplay(state) {
    return {
        datasets: state.datasets
            ? state.datasets.map(function (dataset) {
                return {
                    dataSource: dataset.dataSource
                        ? __assign({}, dataset.dataSource.serialize(), { columns: serializeDataSourceColumns(dataset.dataSource.columns) }) : undefined,
                    staticData: dataset.staticData,
                    params: dataset.params,
                    name: dataset.name,
                    color: dataset.color,
                    format: dataset.format
                };
            })
            : undefined,
        groups: state.groups ? state.groups.map(function (item) { return item.serialize(); }) : undefined
    };
}
function getElementStateOutputs(state) {
    return {
        chartType: state.chartType,
        datasetColumn: state.datasetColumn
    };
}
function getElementStateTitle(state) {
    return {
        nameInput: state.widget && state.widget.nameInput ? state.widget.nameInput.serialize() : undefined
    };
}
function getElementStateXDateLookup(state) {
    return {
        datasets: state.datasets
            ? state.datasets.map(function (dataset) {
                var xColumn = dataset.dataSource
                    ? dataset.dataSource.columns.find(function (item) { return item.name == dataset.dataSource.xColumn; })
                    : undefined;
                return {
                    originalDataSourceXLookup: dataset.originalDataSourceXLookup,
                    xColumn: xColumn ? serializeDataSourceColumns([xColumn]) : undefined
                };
            })
            : undefined
    };
}
export function datasetGroupComparator(lhsGroup, rhsGroup, groups) {
    var lhsGroupIndex = groups ? groups.findIndex(function (item) { return item.value == lhsGroup; }) : -1;
    var rhsGroupIndex = groups ? groups.findIndex(function (item) { return item.value == rhsGroup; }) : -1;
    if (lhsGroupIndex === -1 && rhsGroupIndex === -1) {
        return 0;
    }
    else if (lhsGroupIndex !== -1 && rhsGroupIndex === -1) {
        return -1;
    }
    else if (lhsGroupIndex === -1 && rhsGroupIndex !== -1) {
        return 1;
    }
    else {
        return lhsGroupIndex - rhsGroupIndex;
    }
}
var ChartWidgetComponent = /** @class */ (function (_super) {
    __extends(ChartWidgetComponent, _super);
    function ChartWidgetComponent(currentProjectStore, currentEnvironmentStore, widgetDataSourceService, dataSourceGeneratorService, actionControllerService, notificationService, injector, cd) {
        var _this = _super.call(this) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.widgetDataSourceService = widgetDataSourceService;
        _this.dataSourceGeneratorService = dataSourceGeneratorService;
        _this.actionControllerService = actionControllerService;
        _this.notificationService = notificationService;
        _this.injector = injector;
        _this.cd = cd;
        _this.widget$ = new BehaviorSubject(undefined);
        _this.firstVisible$ = new BehaviorSubject(false);
        _this.elementState = {};
        _this.data = new BehaviorSubject(undefined);
        _this.loading = true;
        _this.configured = true;
        _this.chartTypes = ChartType;
        _this.xColumnDate = false;
        _this.xColumnHasDate = false;
        _this.xColumnHasTime = false;
        _this.xColumnFromControl = new FormControl();
        _this.xColumnToControl = new FormControl();
        _this.xLookupControl = new FormControl();
        _this.xLookupOptions = [
            {
                value: DatasetGroupLookup.DateDay,
                name: 'Daily'
            },
            {
                value: DatasetGroupLookup.DateWeek,
                name: 'Weekly'
            },
            {
                value: DatasetGroupLookup.DateMonth,
                name: 'Monthly'
            },
            {
                value: DatasetGroupLookup.DateQuarter,
                name: 'Quarterly'
            },
            {
                value: DatasetGroupLookup.DateYear,
                name: 'Yearly'
            },
            {
                value: DatasetGroupLookup.DateHour,
                name: 'Hourly'
            },
            {
                value: DatasetGroupLookup.DateMinute,
                name: 'Minutely'
            }
        ];
        return _this;
    }
    ChartWidgetComponent.prototype.ngOnInit = function () {
        this.widgetOnChange(this.widget);
        this.trackChanges();
    };
    ChartWidgetComponent.prototype.ngOnDestroy = function () { };
    ChartWidgetComponent.prototype.ngOnChanges = function (changes) {
        if (changes['widget']) {
            this.widgetOnChange(this.widget);
        }
    };
    ChartWidgetComponent.prototype.widgetOnChange = function (value) {
        this.widget$.next(value);
    };
    ChartWidgetComponent.prototype.trackChanges = function () {
        var _this = this;
        this.firstVisible$
            .pipe(first(function (value) { return value; }), switchMap(function () {
            return combineLatest(_this.widget$, controlValue(_this.xLookupControl).pipe(distinctUntilChanged()), combineLatest(controlValue(_this.xColumnFromControl), controlValue(_this.xColumnToControl)).pipe(map(function (_a) {
                var xColumnFrom = _a[0], xColumnTo = _a[1];
                return (_this.xColumnDate ? [xColumnFrom, xColumnTo] : false);
            }), distinctUntilChanged()));
        }), switchMap(function (_a) {
            var element = _a[0];
            if (!element.datasets.length) {
                return of(element);
            }
            return combineLatest(element.datasets.map(function (dataset) {
                return _this.dataSourceGeneratorService
                    .applyDataSourceDefaults(ChartWidgetDataSource, dataset.dataSource)
                    .pipe(map(function (dataSource) {
                    dataset.dataSource = dataSource;
                    return dataset;
                }));
            })).pipe(map(function () { return element; }));
        }), switchMap(function (element) { return _this.getElementState(element); }), untilDestroyed(this))
            .subscribe(function (state) {
            _this.onStateUpdated(state);
            _this.elementState = state;
        });
    };
    ChartWidgetComponent.prototype.getElementState = function (widget) {
        var _this = this;
        var datasets$ = widget.datasets.length
            ? combineLatest(widget.datasets.map(function (dataset) {
                var staticData$ = dataset.dataSource && dataset.dataSource.type == DataSourceType.Input && dataset.dataSource.input
                    ? applyParamInput$(dataset.dataSource.input, {
                        context: _this.context,
                        defaultValue: {},
                        handleLoading: true,
                        ignoreEmpty: true
                    }).pipe(distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }))
                    : of({});
                var params$ = dataset.dataSource
                    ? applyParamInputs$({}, dataset.dataSource.queryInputs, {
                        context: _this.context,
                        parameters: dataset.dataSource.queryParameters,
                        handleLoading: true,
                        ignoreEmpty: true
                    }).pipe(distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }))
                    : of({});
                return combineLatest(staticData$, params$);
            }))
            : of([]);
        return datasets$.pipe(map(function (datasets) {
            return {
                widget: widget,
                chartType: widget ? widget.chartType : undefined,
                datasets: datasets.map(function (_a, i) {
                    var staticData = _a[0], params = _a[1];
                    var dataset = widget.datasets[i];
                    var datasetXLookup = dataset.dataSource ? dataset.dataSource.xLookup : undefined;
                    var dataSource;
                    if (isSet(_this.xLookupControl.value)) {
                        dataSource = cloneDeep(dataset.dataSource);
                        if (dataSource) {
                            dataSource.xLookup = _this.xLookupControl.value;
                        }
                    }
                    else {
                        dataSource = dataset.dataSource;
                    }
                    if (!values(params).some(function (item) { return item === LOADING_VALUE; })) {
                        if (isSet(_this.xColumnFromControl.value)) {
                            var fromDate = moment(_this.xColumnFromControl.value).utcOffset(getTimezoneOffset()).startOf('day');
                            if (fromDate.isValid()) {
                                params[dataSource.xColumn + "__gte"] = fromDate.toISOString(true);
                            }
                        }
                        if (isSet(_this.xColumnToControl.value)) {
                            var toDate = moment(_this.xColumnToControl.value).utcOffset(getTimezoneOffset()).endOf('day');
                            if (toDate.isValid()) {
                                params[dataSource.xColumn + "__lte"] = toDate.toISOString(true);
                            }
                        }
                    }
                    return {
                        name: dataset.name,
                        color: dataset.color,
                        format: dataset.format,
                        dataSource: dataSource,
                        originalDataSourceXLookup: datasetXLookup,
                        staticData: staticData,
                        params: params
                    };
                }),
                groups: widget.groups,
                inputsLoading: datasets.some(function (_a) {
                    var staticData = _a[0], params = _a[1];
                    return [params, staticData].some(function (obj) {
                        return obj == LOADING_VALUE || values(obj).some(function (item) { return item === LOADING_VALUE; });
                    });
                }),
                inputsNotSet: datasets.some(function (_a) {
                    var staticData = _a[0], params = _a[1];
                    return [params, staticData].some(function (obj) {
                        return obj == NOT_SET_VALUE || values(obj).some(function (item) { return item === NOT_SET_VALUE; });
                    });
                }),
                datasetColumn: widget.datasetColumnEnabled ? widget.datasetColumn : undefined
            };
        }));
    };
    ChartWidgetComponent.prototype.onStateUpdated = function (state) {
        if (!isEqual(getElementStateFetch(state), getElementStateFetch(this.elementState))) {
            var displayState$ = new BehaviorSubject(state);
            this.fetch(state, displayState$);
            this.displayState$ = displayState$;
        }
        else if (this.displayState$ &&
            !isEqual(getElementStateDisplay(state), getElementStateDisplay(this.elementState))) {
            this.displayState$.next(state);
        }
        if (!isEqual(getElementStateOutputs(state), getElementStateOutputs(this.elementState))) {
            this.updateContextOutputs(state);
        }
        if (!isEqual(getElementStateTitle(state), getElementStateTitle(this.elementState))) {
            this.initTitle(state);
        }
        if (!isEqual(getElementStateXDateLookup(state), getElementStateXDateLookup(this.elementState))) {
            this.updateXDateLookup(state);
        }
    };
    ChartWidgetComponent.prototype.fetchDatasets = function (state, displayState$) {
        var _this = this;
        if (isSet(state.datasetColumn)) {
            var dataset_1 = state.datasets[0];
            return combineLatest(this.widgetDataSourceService.group({
                project: this.currentProjectStore.instance,
                environment: this.currentEnvironmentStore.instance,
                datasetColumn: state.datasetColumn,
                dataSource: dataset_1.dataSource,
                params: dataset_1.params,
                staticData: dataset_1.staticData,
                context: this.context
            }), displayState$).pipe(map(function (_a) {
                var result = _a[0], displayState = _a[1];
                if (!result) {
                    return [];
                }
                var resultGroups = result.reduce(function (acc, item) {
                    if (!acc[item.group]) {
                        acc[item.group] = [];
                    }
                    var newItem = new DataGroup();
                    newItem.group = item.group2;
                    newItem.group2 = item.group3;
                    newItem.value = item.value;
                    acc[item.group].push(newItem);
                    return acc;
                }, {});
                var avg = function (items) {
                    if (!items.length) {
                        return 0;
                    }
                    return items.reduce(function (acc, item) { return acc + parseNumber(item); }, 0) / items.length;
                };
                return toPairs(resultGroups)
                    .map(function (_a, i) {
                    var group = _a[0], items = _a[1];
                    var displayDataset = displayState.datasets[0];
                    return {
                        name: group,
                        format: displayDataset.format,
                        groupLookup: dataset_1.dataSource.xLookup,
                        dataset: items
                    };
                })
                    .sort(function (lhs, rhs) {
                    return (avg(lhs.dataset) - avg(rhs.dataset)) * -1;
                });
            }));
        }
        else {
            return combineLatest(state.datasets.map(function (dataset, i) {
                return combineLatest(_this.widgetDataSourceService.group({
                    project: _this.currentProjectStore.instance,
                    environment: _this.currentEnvironmentStore.instance,
                    datasetColumn: state.datasetColumn,
                    dataSource: dataset.dataSource,
                    params: dataset.params,
                    staticData: dataset.staticData,
                    context: _this.context
                }), displayState$).pipe(map(function (_a) {
                    var result = _a[0], displayState = _a[1];
                    if (!result) {
                        return;
                    }
                    var displayDataset = displayState.datasets[i];
                    return {
                        name: displayDataset.name,
                        color: displayDataset.color,
                        format: displayDataset.format,
                        groupLookup: dataset.dataSource.xLookup,
                        dataset: result
                    };
                }));
            }));
        }
    };
    ChartWidgetComponent.prototype.fetch = function (state, displayState$) {
        var _this = this;
        if (this.loadingSubscription) {
            this.loadingSubscription.unsubscribe();
            this.loadingSubscription = undefined;
        }
        var datasets = state.datasets;
        if (state.datasetColumn) {
            datasets = datasets.slice(0, 1);
        }
        this.configured =
            datasets && datasets.length && datasets.every(function (dataset) { return dataset.dataSource && dataset.dataSource.isConfigured(); });
        this.error = undefined;
        this.loading = false;
        this.cd.markForCheck();
        if (!this.configured) {
            this.data.next(undefined);
            this.datasets = undefined;
            // TODO: Temp fix for changes not applying on create
            this.cd.detectChanges();
            return;
        }
        if (state.inputsNotSet) {
            this.data.next(undefined);
            this.datasets = undefined;
            this.loading = false;
            this.cd.markForCheck();
            return;
        }
        this.loading = true;
        this.cd.markForCheck();
        if (state.inputsLoading) {
            return;
        }
        this.loadingSubscription = combineLatest(this.fetchDatasets(state, displayState$), displayState$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var result = _a[0], displayState = _a[1];
            var data = result.filter(function (item) { return item != undefined; });
            _this.data.next(data);
            _this.datasets = data;
            if (isSet(state.datasetColumn) && _this.isSingleColorDataset()) {
                _this.datasets = _this.datasets.sort(function (lhs, rhs) {
                    return datasetGroupComparator(lhs.name, rhs.name, displayState.groups);
                });
            }
            else {
                _this.datasets = _this.datasets.map(function (dataset) {
                    return __assign({}, dataset, { dataset: dataset.dataset.sort(function (lhs, rhs) {
                            return datasetGroupComparator(lhs.group, rhs.group, displayState.groups);
                        }) });
                });
            }
            _this.datasets = _this.datasets.map(function (dataset, i) {
                if (isSet(state.datasetColumn) && _this.isSingleColorDataset()) {
                    var chartGroup = displayState.groups
                        ? displayState.groups.find(function (item) { return item.value == dataset.name; })
                        : undefined;
                    return __assign({}, dataset, { color: getCircleIndex(CHART_COLORS, i) }, (chartGroup && isSet(chartGroup.name) && { name: chartGroup.name }), (chartGroup && isSet(chartGroup.color) && { color: chartGroup.color }));
                }
                else {
                    return __assign({}, dataset, { dataset: dataset.dataset.map(function (group) {
                            var chartGroup = displayState.groups
                                ? displayState.groups.find(function (item) { return item.value == group.group; })
                                : undefined;
                            var newItem = new DataGroup();
                            newItem.group = chartGroup && isSet(chartGroup.name) ? chartGroup.name : group.group;
                            newItem.group2 = group.group2;
                            newItem.group3 = group.group3;
                            newItem.value = group.value;
                            newItem.color = chartGroup && isSet(chartGroup.color) ? chartGroup.color : group.color;
                            return newItem;
                        }) });
                }
            });
            _this.loading = false;
            _this.cd.markForCheck();
        }, function (error) {
            console.error(error);
            _this.data.next(undefined);
            _this.datasets = undefined;
            _this.loading = false;
            if (error instanceof ServerRequestError && error.errors.length) {
                _this.error = error.errors[0];
            }
            else if (isPlainObject(error)) {
                _this.error = JSON.stringify(error);
            }
            else if (error.hasOwnProperty('message')) {
                _this.error = error.message;
            }
            else {
                _this.error = error;
            }
            _this.cd.markForCheck();
        });
    };
    ChartWidgetComponent.prototype.updateContextOutputs = function (state) {
        var groupNames = [GROUP1_OUTPUT, GROUP2_OUTPUT, GROUP3_OUTPUT];
        var groupNameIndex = 0;
        var getGroupName = function () {
            return groupNames[groupNameIndex++];
        };
        var outputs = [
            {
                uniqueName: DATASET_INDEX_OUTPUT,
                name: 'Dataset index',
                icon: 'number',
                fieldType: FieldType.Number,
                internal: true
            },
            {
                uniqueName: GROUP_INDEX_OUTPUT,
                name: 'Group index',
                icon: 'number',
                fieldType: FieldType.Number,
                internal: true
            }
        ];
        if (isSet(state.datasetColumn)) {
            outputs.push({
                uniqueName: getGroupName(),
                name: 'Breakdown',
                icon: 'layers',
                internal: true
            });
        }
        if ([ChartType.Line, ChartType.Bar, ChartType.StackedBar, ChartType.Scatter].includes(state.chartType)) {
            var xGroup = getGroupName();
            outputs.push.apply(outputs, [
                {
                    uniqueName: xGroup,
                    name: 'X axis',
                    icon: 'arrow_forward',
                    internal: true
                },
                {
                    uniqueName: VALUE_OUTPUT,
                    name: 'Y axis',
                    icon: 'arrow_up',
                    fieldType: FieldType.Number,
                    internal: true
                }
            ]);
        }
        else if ([ChartType.Pie, ChartType.Doughnut, ChartType.Radar, ChartType.PolarArea].includes(state.chartType)) {
            var segmentGroup = getGroupName();
            outputs.push.apply(outputs, [
                {
                    uniqueName: segmentGroup,
                    name: 'Segment',
                    icon: 'diagram',
                    internal: true
                },
                {
                    uniqueName: VALUE_OUTPUT,
                    name: 'Value',
                    icon: 'power_measure',
                    fieldType: FieldType.Number,
                    internal: true
                }
            ]);
        }
        else if ([ChartType.Bubble].includes(state.chartType)) {
            var xGroup = getGroupName();
            var yGroup = getGroupName();
            outputs.push.apply(outputs, [
                {
                    uniqueName: xGroup,
                    name: 'X axis',
                    icon: 'arrow_forward',
                    internal: true
                },
                {
                    uniqueName: yGroup,
                    name: 'Y axis',
                    icon: 'arrow_up',
                    internal: true
                },
                {
                    uniqueName: VALUE_OUTPUT,
                    name: 'Size',
                    icon: 'power_measure',
                    fieldType: FieldType.Number,
                    internal: true
                }
            ]);
        }
        this.contextElement.setOutputs(outputs);
    };
    ChartWidgetComponent.prototype.initTitle = function (state) {
        var _this = this;
        if (this.titleSubscription) {
            this.titleSubscription.unsubscribe();
            this.titleSubscription = undefined;
        }
        if (!state.widget.nameInput) {
            this.title = undefined;
            this.cd.markForCheck();
            return;
        }
        this.titleSubscription = applyParamInput$(state.widget.nameInput, {
            context: this.context,
            defaultValue: ''
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            _this.title = value;
            _this.cd.markForCheck();
        });
    };
    ChartWidgetComponent.prototype.updateXDateLookup = function (state) {
        var xLookups = uniq(state.datasets.filter(function (item) { return isSet(item.originalDataSourceXLookup); }).map(function (item) { return item.originalDataSourceXLookup; }));
        var xColumns = state.datasets.map(function (item) {
            if (!item.dataSource) {
                return;
            }
            return item.dataSource.columns.find(function (column) { return column.name == item.dataSource.xColumn; });
        });
        this.xColumnDate = xLookups.length && xLookups.every(function (item) { return datasetGroupDateLookups.includes(item); });
        this.xColumnHasDate = this.xColumnDate && xColumns.every(function (item) { return item && item.field == FieldType.DateTime; });
        this.xColumnHasTime =
            this.xColumnDate &&
                xLookups.every(function (item) { return datasetGroupTimeLookups.includes(item); }) &&
                xColumns.every(function (item) { return item && ((item.field == FieldType.DateTime && item.params['time']) || item.field == FieldType.Time); });
        this.cd.markForCheck();
        if (xLookups.length == 1) {
            this.xLookupControl.patchValue(xLookups[0]);
        }
    };
    ChartWidgetComponent.prototype.getData$ = function () {
        return this.data;
    };
    ChartWidgetComponent.prototype.reloadData = function () {
        this.fetch(this.elementState, this.displayState$);
    };
    ChartWidgetComponent.prototype.isSingleColorDataset = function () {
        return singleColorDatasetChartTypes.includes(this.widget.chartType);
    };
    ChartWidgetComponent.prototype.onDataClick = function (options) {
        var _a;
        if (this.widget.clickAction) {
            this.actionControllerService
                .execute(this.widget.clickAction, {
                context: this.context,
                contextElement: this.contextElement,
                localContext: (_a = {},
                    _a[DATASET_INDEX_OUTPUT] = options.datasetIndex,
                    _a[GROUP_INDEX_OUTPUT] = options.groupIndex,
                    _a[GROUP1_OUTPUT] = options.group,
                    _a[GROUP2_OUTPUT] = options.group2,
                    _a[GROUP3_OUTPUT] = options.group3,
                    _a[VALUE_OUTPUT] = options.value,
                    _a),
                injector: this.injector
            })
                .subscribe();
        }
    };
    Object.defineProperty(ChartWidgetComponent.prototype, "sampleDatasets", {
        get: function () {
            if (this.widget.chartType == ChartType.Line) {
                return lineChartSampleData;
            }
            else if (this.widget.chartType == ChartType.Bar) {
                return barChartSampleData;
            }
            else if (this.widget.chartType == ChartType.StackedBar) {
                return barChartSampleData;
            }
            else if (this.widget.chartType == ChartType.Pie) {
                return pieChartSampleData;
            }
            else if (this.widget.chartType == ChartType.Doughnut) {
                return doughnutChartSampleData;
            }
            else if (this.widget.chartType == ChartType.Radar) {
                return radarChartSampleData;
            }
            else if (this.widget.chartType == ChartType.PolarArea) {
                return doughnutChartSampleData;
            }
            else if (this.widget.chartType == ChartType.Scatter) {
                return scatterSampleData;
            }
            else if (this.widget.chartType == ChartType.Bubble) {
                return bubbleSampleData;
            }
        },
        enumerable: true,
        configurable: true
    });
    return ChartWidgetComponent;
}(WidgetComponent));
export { ChartWidgetComponent };
