<ng-template let-column="column" let-readonly="readonly" #field_template>
  <ng-container *ngIf="!view">
    <app-auto-field
      *ngIf="!readonly || !(column.listItem?.str | appIsSet)"
      [form]="readonly ? undefined : form.form"
      [field]="createField(column.column)"
      [readonly]="readonly"
      [value]="model.getAttribute(column.column.name)"
      [label]="false"
      [autofocus]="true"
      [context]="contextElement.context"
      [truncate]="!(column.column.field == fieldTypes.Text && column.column.params['multiline'])"
      [compact]="true"
    >
    </app-auto-field>

    <ng-container *ngIf="readonly && (column.listItem?.str | appIsSet)">
      {{ column.listItem.str | appSafeAsync }}
    </ng-container>
  </ng-container>

  <div
    *ngIf="view"
    [style.height.px]="view.frame.height"
    [style.min-width.px]="view.widthResize.enabled && (view.widthResize.min | appIsSet) ? view.widthResize.min : null"
    [style.max-width.px]="view.widthResize.enabled && (view.widthResize.max | appIsSet) ? view.widthResize.max : null"
    [style.min-height.px]="
      view.heightResize.enabled && (view.heightResize.min | appIsSet) ? view.heightResize.min : null
    "
    [style.max-height.px]="
      view.heightResize.enabled && (view.heightResize.max | appIsSet) ? view.heightResize.max : null
    "
  >
    <app-custom-element-view
      [view]="view"
      [parameters]="viewParameters"
      [params]="viewParams$ | async"
      [stateSelectedEnabled]="true"
      [stateSelected]="selected"
    ></app-custom-element-view>
  </div>
</ng-template>

<ng-template let-column="column" #link_template>
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayPanelClass]="['overlay']"
    [cdkConnectedOverlayPositions]="[
      {
        panelClass: ['overlay_position_top-center'],
        originX: 'center',
        overlayX: 'center',
        originY: 'top',
        overlayY: 'bottom',
        offsetX: 0,
        offsetY: 0
      },
      {
        panelClass: ['overlay_position_bottom-center'],
        originX: 'center',
        overlayX: 'center',
        originY: 'bottom',
        overlayY: 'top',
        offsetX: 0,
        offsetY: 0
      }
    ]"
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="actionOpened$ | async"
    [cdkConnectedOverlayPush]="true"
    [cdkConnectedOverlayGrowAfterOpen]="true"
    [cdkConnectedOverlayHasBackdrop]="false"
    [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
    #overlay
  >
    <div class="popover2" [ngClass]="overlayClasses" #action_element>
      <div class="popover2__inner">
        <div class="popover2__content">
          <div class="related-link">
            <app-table-item-column-action
              *ngFor="let item of actions"
              class="related-link__action"
              [row]="row"
              [item]="item"
              [context]="context"
              [contextElement]="contextElement"
              (click)="$event.stopPropagation()"
            ></app-table-item-column-action>
          </div>
        </div>
        <div class="popover2__arrow"></div>
      </div>
    </div>
  </ng-template>

  <div
    [class.edit-link]="column.column.editable"
    (mousedown)="$event.stopPropagation()"
    (mouseenter)="onColumnMouseEnter()"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    #column_element
  >
    <ng-container *ngIf="!edit">
      <div [class.table__column-inner]="!(column.column.field == fieldTypes.Text && column.column.params['wide'])">
        <ng-container
          [ngTemplateOutlet]="field_template"
          [ngTemplateOutletContext]="{ column: column, readonly: true }"
        >
        </ng-container>
      </div>
      <!--      <a *ngIf="column.column.editable && modelDescription.updateEnabled && (currentProjectStore.instance$ | async)?.hasModelPermission(modelDescription.model, 'w') && columnsHoverDelay[index]"-->
      <!--         href="javascript:void(0)"-->
      <!--         class="edit-link__button icon icon-pen"-->
      <!--         (click)="$event.stopPropagation(); startEdit(index)">-->
      <!--      </a>-->
    </ng-container>

    <ng-container *ngIf="edit">
      <ng-container [ngTemplateOutlet]="field_template" [ngTemplateOutletContext]="{ column: column, readonly: false }">
      </ng-container>
      <span class="edit-link__actions">
        <ng-container *ngIf="!editLoading">
          <a
            href="javascript:void(0)"
            class="edit-link__action icon icon-check_2"
            [class.edit-link__action_disabled]="!editValid"
            (click)="$event.stopPropagation(); saveEdit()"
          >
          </a>
          <a
            href="javascript:void(0)"
            class="edit-link__action icon icon-close"
            (click)="$event.stopPropagation(); cancelEdit()"
          ></a>
        </ng-container>
        <span *ngIf="editLoading" class="edit-link__loader"></span>
      </span>
    </ng-container>
  </div>
</ng-template>

<ng-container [ngTemplateOutlet]="link_template" [ngTemplateOutletContext]="{ column: column }"> </ng-container>
