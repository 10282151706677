import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import fromPairs from 'lodash/fromPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { merge, Observable, of, Subject } from 'rxjs';
import { delayWhen, filter, map, switchMap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { PopoverService } from '@common/popover';
import { UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { AggregateFunc } from '@modules/charts';
import { CustomView, CustomViewService, CustomViewsStore, CustomViewType } from '@modules/custom-views';
import { ElementItem, ViewContext, ViewContextElement, ViewSettingsAction } from '@modules/customize';
import {
  AggregateDisplayField,
  ComputedDisplayField,
  CustomViewDisplayField,
  DisplayFieldType,
  FieldType,
  LookupDisplayField
} from '@modules/fields';
import { ModelOptionSelectedEvent } from '@modules/filters-components';
import { ModelService } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import { ViewContextTokenProvider } from '@modules/parameters-components';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource } from '@modules/projects';
import { QueryType } from '@modules/queries';
import { CustomViewTemplateType, Frame, View, ViewMapping } from '@modules/views';
import { AutofocusDirective, controlValue, generateAlphanumeric, isSet, TypedChanges } from '@shared';

// TODO: Refactor import
import { CustomViewMapParametersController } from '../../../views-components/services/custom-view-map-parameters-controller/custom-view-map-parameters.controller';
import { CustomViewTemplatesController } from '../../../views-components/services/custom-view-templates-controller/custom-view-templates.controller';
import { ViewEditorController } from '../../../views-components/services/view-editor-controller/view-editor.controller';

import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { CustomizeBarService } from '../../services/customize-bar/customize-bar.service';
import { DisplayFieldArray } from './display-field.array';
import { DisplayFieldControl } from './display-field.control';
import { FieldActionsArray } from './field-actions.array';

@Component({
  selector: 'app-display-fields-edit',
  templateUrl: './display-fields-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayFieldsEditComponent implements OnInit, OnDestroy, OnChanges {
  @Input() form: DisplayFieldArray;
  @Input() fieldActionsControl: FieldActionsArray;
  @Input() resource: Resource;
  @Input() modelDescription: ModelDescription;
  @Input() itemName = 'field';
  @Input() componentName = 'component';
  @Input() element: ElementItem;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() collapsible = true;
  @Input() searchEnabled = false;
  @Input() searchFocus = false;
  @Input() visibleEditable = true;
  @Input() customViewEnabled = false;
  @Input() customViewStateSelectedEnabled = false;
  @Input() actionsLabels: {
    title?: string;
    emptyAction?: string;
    actionLabel?: string;
  };
  @Input() firstInit = false;
  @Input() analyticsSource: string;
  @Output() searchCleared = new EventEmitter<void>();

  @ViewChild('search_autofocus', { read: AutofocusDirective }) searchAutoFocus: AutofocusDirective;

  isLookupsSupported = false;
  displayItems: DisplayFieldControl[] = [];
  maxDisplayInitial = 8;
  collapsed = true;
  search = '';
  searchUpdated = new Subject<string>();
  submitLoading = false;
  submitControlLoading: DisplayFieldControl;
  displayFieldTypes = DisplayFieldType;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private customizeBarService: CustomizeBarService,
    private customizeBarContext: CustomizeBarContext,
    private modelService: ModelService,
    private viewEditorController: ViewEditorController,
    private customViewService: CustomViewService,
    private customViewsStore: CustomViewsStore,
    private customViewTemplatesController: CustomViewTemplatesController,
    private customViewMapParametersController: CustomViewMapParametersController,
    private contextTokenProvider: ViewContextTokenProvider,
    private notificationService: NotificationService,
    private analyticsService: UniversalAnalyticsService,
    private popoverService: PopoverService,
    private injector: Injector,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    merge(controlValue(this.form), this.searchUpdated)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.updateDisplayItems());
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<DisplayFieldsEditComponent>): void {
    if (changes.resource || changes.modelDescription) {
      this.isLookupsSupported =
        this.modelService.isGetAdvSupported(this.resource, this.modelDescription) &&
        this.modelDescription &&
        this.modelDescription.queryType != QueryType.SQL;
    }

    if (changes.searchEnabled && !this.searchEnabled) {
      this.clearSearch();
    }
  }

  dragDrop(event: CdkDragDrop<DisplayFieldControl[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.form.controls, event.previousIndex, event.currentIndex);
      this.form.updateValueAndValidity();
    }
  }

  getDistinctName(baseName: string, template = (n, i) => `${n}_${i}`, startIndex = 1) {
    const names = this.form.controls.map(item => {
      const value = item.controls.name.value;
      return isSet(value) ? value : '';
    });
    let name: string;
    let index = startIndex;

    do {
      name = template(baseName, index);
      ++index;
    } while (names.find(item => item.toLowerCase() == name.toLowerCase()));

    return name;
  }

  customize(control: DisplayFieldControl) {
    if (!control.controls.visible.value) {
      control.controls.visible.patchValue(true);
    }

    const column = control.serialize();
    const initialElement = cloneDeep(control.serialize());
    const valueEditable = control.instance && control.instance.type == DisplayFieldType.Computed;
    const lookupEditable = control.instance && control.instance.type == DisplayFieldType.Lookup;
    const aggregateEditable = control.instance && control.instance.type == DisplayFieldType.Aggregate;
    const actionsValue = this.fieldActionsControl
      ? this.fieldActionsControl.getColumnActions(control.controls.name.value)
      : [];

    this.customizeBarService
      .customizeColumn({
        context: this.customizeBarContext,
        column: column,
        modelDescription: this.modelDescription,
        actions: actionsValue,
        configurable: {
          verboseName: true,
          value: valueEditable,
          lookup: lookupEditable,
          aggregate: aggregateEditable,
          action: !!this.fieldActionsControl
        },
        viewContext: this.context,
        viewContextElement: this.contextElement,
        viewContextElementPath: this.contextElementPath,
        viewContextElementPaths: this.contextElementPaths,
        actionsLabels: this.actionsLabels,
        append: true,
        firstInit: this.firstInit
      })
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (e.type == CustomizeBarEditEventType.Updated) {
          control.deserialize(e.args['result']);
          control.markAsDirty();

          if (this.fieldActionsControl) {
            const actionsControl = this.fieldActionsControl.getColumnControl(control.controls.name.value);
            const actions = (e.args['actions'] as ViewSettingsAction[]) || [];

            if (actionsControl) {
              actionsControl.controls.actions.setValue(actions);
            } else {
              this.fieldActionsControl.appendControl({
                name: control.controls.name.value,
                actions: actions
              });
            }
          }
        } else if (e.type == CustomizeBarEditEventType.Canceled) {
          control.deserialize(initialElement);
          control.markAsDirty();
        }
      });

    this.clearSearch();
  }

  getControlUniqueName() {
    return `_jet_${generateAlphanumeric(4)}`;
  }

  getControlUniqueField(
    fieldValueGetter: (control: DisplayFieldControl) => any,
    prefix: string,
    separator = '_',
    skipFirst = false
  ) {
    let nextNumber = 1;
    let result: string;

    do {
      result = skipFirst && nextNumber == 1 ? prefix : `${prefix}${separator}${nextNumber}`;
      ++nextNumber;
    } while (this.form.controls.find(group => fieldValueGetter(group) == result));

    return result;
  }

  addComputedItem(type: string = FieldType.Text) {
    const uniqueName = this.getControlUniqueName();
    const defaultVerboseName = 'Computed Field';
    const verboseName = this.getControlUniqueField(
      item => item.controls.verboseName.value,
      defaultVerboseName,
      ' ',
      true
    );
    const instance = new ComputedDisplayField({
      name: uniqueName,
      verboseName: verboseName,
      field: type as FieldType,
      visible: true
    });

    const control = this.form.appendControl(instance);

    this.customize(control);
    this.clearSearch();
  }

  createView(): View {
    const result = new View();

    result.generateId();
    result.name = 'New View';
    result.frame = new Frame({ width: 110, height: 40 });

    const sourceParameters = this.form.getParameters();

    if (sourceParameters) {
      result.parameters = sourceParameters;

      if (this.contextElement) {
        const testValues = sourceParameters
          .map(parameter => {
            const testValue = this.contextElement.getFieldValue(parameter.name);
            return [parameter.name, testValue];
          })
          .filter(item => isSet(item));

        result.testParameters = fromPairs(testValues);
      }
    }

    return result;
  }

  submitCardView(options: { uniqueName?: string; view?: View; columnUniqueName?: string } = {}): Observable<string> {
    if (!options.view) {
      return of(undefined);
    }

    const customView$ = isSet(options.uniqueName)
      ? this.customViewsStore.getDetailFirst(options.uniqueName)
      : of(undefined);

    return customView$.pipe(
      switchMap(customView => {
        const pageUid = this.context && this.context.viewSettings ? this.context.viewSettings.uid : undefined;
        const elementUid = this.element ? this.element.uid : undefined;
        const fields = ['unique_name', 'view_type', 'view', 'params'];

        if (customView) {
          const instance = cloneDeep(customView);

          instance.view = options.view;
          instance.pageUid = pageUid;
          instance.elementUid = elementUid;
          instance.columnUniqueName = options.columnUniqueName;

          return this.customViewService.update(
            this.currentProjectStore.instance.uniqueName,
            this.currentEnvironmentStore.instance.uniqueName,
            instance,
            { draft: true, fields: fields }
          );
        } else {
          const instance = new CustomView();

          instance.uniqueName =
            isSet(pageUid) && isSet(elementUid) && isSet(options.columnUniqueName)
              ? [CustomViewType.ItemColumn, pageUid, elementUid, options.columnUniqueName].join('.')
              : [CustomViewType.ItemColumn, generateAlphanumeric(8, { letterFirst: true })].join('.');
          instance.viewType = CustomViewType.ItemColumn;
          instance.view = options.view;
          instance.pageUid = pageUid;
          instance.elementUid = elementUid;
          instance.columnUniqueName = options.columnUniqueName;

          return this.customViewService.create(
            this.currentProjectStore.instance.uniqueName,
            this.currentEnvironmentStore.instance.uniqueName,
            instance,
            { draft: true, fields: fields }
          );
        }
      }),
      delayWhen(() => this.customViewsStore.getFirst(true)),
      map(result => result.uniqueName)
    );
  }

  addCustomViewField(view: View, options: { mapping?: ViewMapping[] } = {}) {
    const uniqueName = this.getControlUniqueName();
    const defaultVerboseName = view.name;
    const verboseName = this.getControlUniqueField(
      item => item.controls.verboseName.value,
      defaultVerboseName,
      ' ',
      true
    );

    this.submitLoading = true;
    this.cd.markForCheck();

    this.submitCardView({ view: view, columnUniqueName: uniqueName })
      .pipe(untilDestroyed(this))
      .subscribe(
        customView => {
          this.submitLoading = false;
          this.cd.markForCheck();

          if (!customView) {
            return;
          }

          const instance = new CustomViewDisplayField({
            name: uniqueName,
            verboseName: verboseName,
            visible: true
          });

          instance.customView = customView;

          if (options.mapping) {
            instance.customViewMappings = options.mapping;
          }

          this.form.appendControl(instance);

          this.clearSearch();
        },
        error => {
          this.submitLoading = false;
          this.cd.markForCheck();

          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Error', error.errors[0]);
          } else {
            this.notificationService.error('Error', error);
          }
        }
      );
  }

  updateCustomViewField(
    control: DisplayFieldControl,
    view: View,
    options: { mapping?: ViewMapping[]; updateName?: boolean } = {}
  ) {
    const uniqueName = control.controls.customViewUniqueName.value;
    const columnUniqueName = control.instance ? control.instance.name : uniqueName;

    this.submitControlLoading = control;
    this.cd.markForCheck();

    this.submitCardView({ uniqueName: uniqueName, view: view, columnUniqueName: columnUniqueName })
      .pipe(untilDestroyed(this))
      .subscribe(
        customView => {
          this.submitControlLoading = undefined;
          this.cd.markForCheck();

          if (!customView) {
            return;
          }

          control.controls.customViewUniqueName.patchValue(customView);

          if (options.mapping) {
            control.controls.customViewMappings.patchValue(options.mapping);
          }

          if (options.updateName) {
            control.controls.verboseName.patchValue(view.name);
          }
        },
        error => {
          this.submitControlLoading = undefined;
          this.cd.markForCheck();

          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Error', error.errors[0]);
          } else {
            this.notificationService.error('Error', error);
          }
        }
      );
  }

  openViewEditor(control?: DisplayFieldControl, view?: View) {
    const create = !control;

    if (!view) {
      view = this.createView();
    }

    return this.viewEditorController
      .open({
        create: create,
        view: view,
        componentLabel: this.componentName,
        submitLabel: create ? 'Create cell' : 'Save changes',
        nameEditingEnabled: create,
        stateSelectedEnabled: this.customViewStateSelectedEnabled,
        analyticsSource: this.analyticsSource
      })
      .pipe(
        filter(result => !result.cancelled),
        untilDestroyed(this)
      )
      .subscribe(result => {
        if (create) {
          this.addCustomViewField(result.view);
        } else {
          this.updateCustomViewField(control, result.view);
        }
      });
  }

  openCustomViewTemplates(control?: DisplayFieldControl) {
    const create = !control;

    this.customViewTemplatesController
      .chooseTemplate({
        initialFilter: { type: CustomViewTemplateType.ItemColumn },
        nameEditingEnabled: create,
        viewCreateEnabled: true,
        stateSelectedEnabled: this.customViewStateSelectedEnabled,
        componentLabel: this.componentName,
        analyticsSource: this.analyticsSource
      })
      .pipe(
        filter(result => !result.cancelled),
        untilDestroyed(this)
      )
      .subscribe(viewResult => {
        const sourceParameters = this.form.getParameters();

        this.customViewMapParametersController
          .open({
            sourceParameters: sourceParameters,
            view: viewResult.view,
            context: this.context,
            contextElement: this.contextElement,
            contextElementPath: this.contextElementPath,
            contextElementPaths: this.contextElementPaths,
            contextTokenProvider: this.contextTokenProvider,
            analyticsSource: this.analyticsSource
          })
          .pipe(
            filter(mappingResult => !mappingResult.cancelled),
            untilDestroyed(this)
          )
          .subscribe(mappingResult => {
            if (create) {
              this.addCustomViewField(viewResult.view, { mapping: mappingResult.mappings });
            } else {
              this.updateCustomViewField(control, viewResult.view, {
                mapping: mappingResult.mappings,
                updateName: true
              });
            }
          });
      });
  }

  changeMapping(control: DisplayFieldControl, view: View) {
    const mappings: ViewMapping[] = control.controls.customViewMappings.value;
    const sourceParameters = this.form.getParameters();

    return this.customViewMapParametersController
      .open({
        sourceParameters: sourceParameters,
        view: view,
        mappings: mappings,
        context: this.context,
        contextElement: this.contextElement,
        contextElementPath: this.contextElementPath,
        contextElementPaths: this.contextElementPaths,
        contextTokenProvider: this.contextTokenProvider,
        analyticsSource: this.analyticsSource
      })
      .pipe(
        filter(mappingResult => !mappingResult.cancelled),
        untilDestroyed(this)
      )
      .subscribe(mappingResult => {
        control.controls.customViewMappings.patchValue(mappingResult.mappings);
      });
  }

  updateCustomViewTemplate(control: DisplayFieldControl, view: View) {
    this.customViewTemplatesController
      .setTemplateView(view, {
        stateSelectedEnabled: this.customViewStateSelectedEnabled,
        componentLabel: this.componentName
      })
      .pipe(
        filter(result => !result.cancelled),
        untilDestroyed(this)
      )
      .subscribe();
  }

  renameCustomView(control: DisplayFieldControl, name: string) {
    control.controls.verboseName.patchValue(name);
  }

  addLookupItem(e: ModelOptionSelectedEvent) {
    if (!e.field) {
      return;
    }

    const uniqueName = this.getControlUniqueName();
    const defaultVerboseName = e.path.map(item => item.verboseName).join(' ');
    const verboseName = this.getControlUniqueField(
      item => item.controls.verboseName.value,
      defaultVerboseName,
      ' ',
      true
    );
    const instance = new LookupDisplayField({
      name: uniqueName,
      verboseName: verboseName,
      ...(e.field && {
        field: e.field.field,
        params: e.field.params
      }),
      visible: true,
      path: e.path.map(item => item.name)
    });

    const control = this.form.appendControl(instance);

    this.customize(control);
    this.clearSearch();
  }

  addAggregateItem(e: ModelOptionSelectedEvent) {
    if (!e.relation) {
      return;
    }

    const uniqueName = this.getControlUniqueName();
    const defaultVerboseName = `${e.path.map(item => item.verboseName).join(' ')} ${
      e.aggregation ? e.aggregation.func.toLowerCase() : 'Count'
    }`;
    const verboseName = this.getControlUniqueField(
      item => item.controls.verboseName.value,
      defaultVerboseName,
      ' ',
      true
    );
    const instance = new AggregateDisplayField({
      name: uniqueName,
      verboseName: verboseName,
      ...(e.field && {
        field: e.field.field,
        params: e.field.params
      }),
      ...(e.relation && {
        field: FieldType.Number
      }),
      path: e.path.map(item => item.name),
      ...(e.aggregation
        ? {
            func: e.aggregation.func,
            column: e.aggregation.field
          }
        : { func: AggregateFunc.Count, column: undefined }),
      visible: true
    });

    const control = this.form.appendControl(instance);

    this.customize(control);
    this.clearSearch();
  }

  updateDisplayItems() {
    const processSearch = str => (str || '').trim().toLowerCase();
    const search = processSearch(this.search);

    if (isSet(search)) {
      this.displayItems = this.form.controls.filter(item => {
        return (
          processSearch(item.controls.verboseName.value).indexOf(search) !== -1 ||
          processSearch(item.controls.name.value).indexOf(search) !== -1
        );
      });
    } else if (this.collapsible && this.collapsed) {
      this.displayItems = this.form.controls.slice(0, this.maxDisplayInitial);
    } else {
      this.displayItems = this.form.controls;
    }

    this.cd.markForCheck();
  }

  setCollapsed(value: boolean) {
    this.collapsed = value;
    this.cd.markForCheck();
    this.updateDisplayItems();
  }

  public isToggledAll(): boolean {
    return this.form.isToggledAll();
  }

  public toggleAll() {
    this.form.toggleAll();
  }

  public isEmpty() {
    return this.displayItems.length == 0;
  }

  public hasMultipleItems() {
    return this.displayItems.length > 1;
  }

  clearSearch() {
    if (!isSet(this.search)) {
      return;
    }

    this.search = '';
    this.searchUpdated.next();
    this.cd.markForCheck();
    this.searchCleared.emit();
  }

  onSearchBlur() {
    if (!isSet(this.search)) {
      this.searchCleared.emit();
    }
  }

  focusSearch() {
    if (this.searchAutoFocus) {
      this.searchAutoFocus.focus();
    }
  }
}
