import { Injectable, Type } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import keys from 'lodash/keys';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ActionDescription, ActionItem, ActionType, LinkAction, SegueType } from '@modules/actions';
import { modelFieldToDisplayField, ViewSettingsAction } from '@modules/customize';
import {
  DataSource,
  DataSourceType,
  ListModelDescriptionDataSource,
  ModelDescriptionDataSource
} from '@modules/data-sources';
import { DisplayField, DisplayFieldType, FieldType, Input, InputValueType } from '@modules/fields';
import { SELECTED_ITEM_OUTPUT } from '@modules/list';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription, ModelField } from '@modules/models';
import { CurrentEnvironmentStore } from '@modules/projects';
import { ListModelDescriptionQuery, ModelDescriptionQuery, QueryType } from '@modules/queries';
import { prepareDataSourceColumnForGet } from '@modules/resources';

export interface ApplyDataSourceDefaultsOptions {
  maxColumns?: number;
}

@Injectable()
export class DataSourceGeneratorService {
  constructor(
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private modelDescriptionStore: ModelDescriptionStore
  ) {}

  applyModelQueryDefaults<T extends ModelDescriptionQuery>(
    modelDescription: ModelDescription,
    queryClass: Type<T>,
    query?: T
  ): T {
    if (!query) {
      query = new queryClass();
    }

    if (!query.queryType) {
      query.queryType = QueryType.Simple;
    }

    if (!query.simpleQuery) {
      query.simpleQuery = new query.simpleQueryClass();
      query.simpleQuery.model = modelDescription.model;
    }

    return query;
  }

  getModelColumns(modelDescription: ModelDescription, maxVisible?: number): DisplayField[] {
    const resource = this.currentEnvironmentStore.resources.find(item => item.uniqueName == modelDescription.resource);

    if (!resource) {
      return [];
    }

    return modelDescription.fields
      .map((item, i) => {
        let visible =
          (maxVisible === undefined || i < maxVisible) &&
          modelDescription.displayFields.find(displayItem => displayItem === item) != undefined;

        if (item.name == modelDescription.orderingField || item.item.field == FieldType.JSON) {
          visible = false;
        }

        const result = modelFieldToDisplayField(item);
        result.visible = visible;
        return result;
      })
      .map(item => prepareDataSourceColumnForGet(resource, modelDescription, item));
  }

  syncModelColumns(modelDescription: ModelDescription, columns: DisplayField[]): DisplayField[] {
    const actualColumns = this.getModelColumns(modelDescription);
    const addColumns = actualColumns
      .filter(actualColumn => !columns.find(column => column.name == actualColumn.name))
      .map(item => {
        item = cloneDeep(item);
        item.visible = false;
        return item;
      });
    const oldColumns = columns
      .filter(column => {
        return (
          column.type != DisplayFieldType.Base || actualColumns.find(actualColumn => actualColumn.name == column.name)
        );
      })
      .map(column => {
        const actualColumn = actualColumns.find(item => item.name == column.name);

        if (actualColumn) {
          keys(actualColumn).forEach(actualColumnName => {
            if (!column.hasOwnProperty(actualColumnName)) {
              column[actualColumnName] = actualColumn[actualColumnName];
            }
          });
        }

        return column;
      });

    return [...oldColumns, ...addColumns];
  }

  resetModelColumns(modelDescription: ModelDescription, columns: DisplayField[]): DisplayField[] {
    const resource = this.currentEnvironmentStore.resources.find(item => item.uniqueName == modelDescription.resource);

    if (!resource) {
      return [];
    }

    return columns
      .map(item => {
        const field = modelDescription.field(item.name) as ModelField;

        if (!field) {
          return item;
        }

        const result = modelFieldToDisplayField(field);
        result.visible = item.visible;
        return result;
      })
      .map(item => prepareDataSourceColumnForGet(resource, modelDescription, item));
  }

  getModelColumnActions(
    modelDescription: ModelDescription,
    columns: DisplayField[]
  ): { name: string; actions: ViewSettingsAction[] }[] {
    return [];
    // return columns
    //   .map(item => {
    //     const field = modelDescription.field(item.name) as ModelField;
    //
    //     if (!field) {
    //       return;
    //     }
    //
    //     if (field.item.field == FieldType.RelatedModel) {
    //       const relatedModel =
    //         field.item.params['related_model'] && this.modelDescriptionStore.instance
    //           ? this.modelDescriptionStore.instance.find(
    //               i => i.resource == modelDescription.resource && i.model == field.item.params['related_model']['model']
    //             )
    //           : undefined;
    //
    //       if (relatedModel) {
    //         const actionDescription = new ActionDescription();
    //         const input = new Input();
    //         const action = new ViewSettingsAction();
    //
    //         actionDescription.verboseName = relatedModel.verboseName;
    //         actionDescription.icon = 'model_link';
    //         actionDescription.type = ActionType.Link;
    //         actionDescription.linkAction = new LinkAction();
    //         actionDescription.linkAction.type = SegueType.ModelChange;
    //         actionDescription.linkAction.model = relatedModel.modelId;
    //
    //         input.path = ['id'];
    //         input.valueType = InputValueType.Context;
    //         input.contextValue = [SELECTED_ITEM_OUTPUT, item.name];
    //
    //         action.verboseNameInput = new Input().deserializeFromStatic('value', actionDescription.verboseName);
    //         action.icon = 'model_link';
    //         action.actionDescription = actionDescription;
    //         action.inputs = [input];
    //
    //         return {
    //           name: field.name,
    //           actions: [action]
    //         };
    //       }
    //     }
    //   })
    //   .filter(item => item);
  }

  applyModelDataSourceDefaults<T extends ModelDescriptionDataSource | ListModelDescriptionDataSource>(
    cls: Type<T>,
    dataSource: T,
    modelDescription: ModelDescription,
    options: ApplyDataSourceDefaultsOptions = {}
  ): T {
    if (!dataSource) {
      dataSource = new cls();
      dataSource.type = DataSourceType.Query;
    }

    if (dataSource.type == DataSourceType.Query) {
      dataSource.query = this.applyModelQueryDefaults(modelDescription, ListModelDescriptionQuery, dataSource.query);
    }

    if (!dataSource.queryResource) {
      dataSource.queryResource = modelDescription.resource;
    }

    // if (modelDescription.getParameters && dataSource.query && dataSource.query.queryType == QueryType.Simple) {
    //   dataSource.queryParameters = [...modelDescription.getParameters];
    // }

    if (
      dataSource.type == DataSourceType.Query &&
      !dataSource.queryInputs.length &&
      modelDescription.getInputs &&
      modelDescription.getInputs.length
    ) {
      dataSource.queryInputs = [...modelDescription.getInputs];
    }

    if (!dataSource.columns || !dataSource.columns.filter(item => item.visible).length) {
      dataSource.columns = this.getModelColumns(modelDescription, options.maxColumns);
      dataSource.columns = this.resetModelColumns(modelDescription, dataSource.columns);
    }

    dataSource.columns = this.syncModelColumns(modelDescription, dataSource.columns);

    return dataSource;
  }

  applyDataSourceDefaults<T extends DataSource>(
    cls: Type<T>,
    dataSource: T,
    options: ApplyDataSourceDefaultsOptions = {}
  ): Observable<T> {
    if (
      dataSource &&
      (dataSource instanceof ModelDescriptionDataSource || dataSource instanceof ListModelDescriptionDataSource) &&
      dataSource.type == DataSourceType.Query &&
      dataSource.query &&
      dataSource.query.queryType == QueryType.Simple &&
      dataSource.query.simpleQuery
    ) {
      const modelId = [dataSource.queryResource, dataSource.query.simpleQuery.model].join('.');

      return this.modelDescriptionStore.getDetailFirst(modelId).pipe(
        map(modelDescription => {
          if (!modelDescription) {
            return dataSource;
          }

          return (this.applyModelDataSourceDefaults(
            (cls as unknown) as Type<ModelDescriptionDataSource | ListModelDescriptionDataSource>,
            dataSource,
            modelDescription,
            options
          ) as unknown) as T;
        })
      );
    } else {
      return of(dataSource);
    }
  }
}
