<div class="inline-input" [class.inline-input_edit]="editorActive && (editable$ | async)">
  <ng-container *ngIf="editable$ | async">
    <div class="inline-input__content">
      <span
        class="inline-input-content"
        (click)="activateEditor()"
        cdkOverlayOrigin
        #content_trigger="cdkOverlayOrigin"
        (mouseenter)="contentHover$.next(true)"
        (mouseleave)="contentHover$.next(false)"
      >
        <!--        <ng-container *ngIf="!editorActive">-->
        <!--          <span class="inline-input-content__icon inline-input-content__icon_normal icon-function"></span>-->
        <!--          <span class="inline-input-content__icon inline-input-content__icon_hover icon-pen"></span>-->
        <!--        </ng-container>-->
        <!--        <span *ngIf="editorActive" class="inline-input-content__icon icon-pen"></span>-->

        <span
          class="inline-input-content__main"
          [innerHTML]="
            itemForm
              | appControlValue
              | appThrottleTime: 200:{ leading: true, trailing: true }
              | async
              | appFieldInput
              | appApplyParamInput: { context: context, defaultValue: '' }
              | async
          "
        ></span>
      </span>
    </div>

    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayPanelClass]="['overlay']"
      [cdkConnectedOverlayPositions]="editorPositions"
      [cdkConnectedOverlayOrigin]="content_trigger"
      [cdkConnectedOverlayOpen]="(editorOpened$ | async) || editorActive"
      [cdkConnectedOverlayPush]="true"
      [cdkConnectedOverlayGrowAfterOpen]="true"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayBackdropClass]="'popover2-backdrop2'"
      (attach)="onEditorOpened()"
      (backdropClick)="deactivateEditor()"
      (detach)="deactivateEditor()"
      #editor_overlay
    >
      <div
        (cdkObserveContent)="onEditorOverlayContentChanged()"
        (mouseenter)="editorHover$.next(true)"
        (mouseleave)="editorHover$.next(false)"
        (click)="activateEditor()"
        #input_popover
      >
        <div *ngIf="itemForm.value['name']" class="inline-input__editor">
          <ng-container
            *ngIf="!itemForm.value['value_type'] || itemForm.value['value_type'] == inputValueTypes.Formula"
          >
            <div class="inline-input-editor" cdkOverlayOrigin #input_origin="cdkOverlayOrigin">
              <div class="input-icon-wrapper">
                <app-input-edit-formula-value
                  [control]="control"
                  [context]="context"
                  [contextElement]="contextElement"
                  [contextElementPath]="contextElementPath"
                  [contextElementPaths]="contextElementPaths"
                  [contextValueEnabled]="false"
                  [focusedInitial]="focusedInitial"
                  [placeholder]="placeholder"
                  [fill]="true"
                  [fillVertical]="false"
                  [inline]="true"
                  [popoverOrigin]="input_origin"
                  (popoverOpened)="onEditorOpened()"
                  (switchToJs)="switchToJs()"
                ></app-input-edit-formula-value>
              </div>

              <div class="inline-input-editor__buttons">
                <div class="button-tags">
                  <div class="button-tag button-tags__item button-tags__item_fill" (click)="switchToJs()">
                    <span class="button-tag__icon icon-console"></span>
                    <span class="button-tag__label">JavaScript</span>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="itemForm.value['value_type'] == inputValueTypes.Js">
            <app-view-context-token-popover-overlay
              [context]="context"
              [contextElement]="contextElement"
              [contextElementPath]="contextElementPath"
              [contextElementPaths]="contextElementPaths"
              [origin]="input_origin"
              [control]="control"
              [forceOpened]="editorActive"
              [hideSections]="['functions']"
              (selected)="onJsTokenSelected($event)"
            ></app-view-context-token-popover-overlay>

            <div class="inline-input-editor" cdkOverlayOrigin #input_origin="cdkOverlayOrigin">
              <app-auto-field
                [form]="itemForm"
                [label]="false"
                [field]="
                  createField({
                    name: 'js_value',
                    field: 'CodeField',
                    placeholder: jsPlaceholder,
                    params: {
                      language: 'javascript',
                      autogrow: true,
                      field: true,
                      field_contrast: true,
                      wrap: true,
                      classes: [true ? 'input_fill' : '', 'inline-input-editor__code'].concat(classes || [])
                    }
                  })
                "
                #js_field
              ></app-auto-field>

              <div *ngIf="jsError" class="input-error">
                <div class="input-error__inner" [title]="jsError">{{ jsError }}</div>
              </div>

              <div class="inline-input-editor__buttons">
                <div class="button-tags">
                  <div class="button-tag button-tags__item button-tags__item_fill" (click)="switchToFormula()">
                    <span class="button-tag__icon icon-console"></span>
                    <span class="button-tag__label">Disable JavaScript</span>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="!(editable$ | async)">{{
    itemForm
      | appControlValue
      | appThrottleTime: 200:{ leading: true, trailing: true }
      | async
      | appFieldInput
      | appApplyParamInput: { context: context, defaultValue: '' }
      | async
  }}</ng-container>
</div>
