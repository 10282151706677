<app-field-wrapper
  *ngIf="!readonly"
  [formGroup]="form"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelButtons]="buttons"
  [tooltip]="tooltip"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [idToken]="idToken"
>
  <app-select
    *ngIf="control"
    [placeholder]="field.placeholder || ('choose' | localize | appCapitalize)"
    [control]="control"
    [fill]="selectFill"
    [small]="selectSmall"
    [segment]="selectSegment"
    [source]="source?.initialized ? source : undefined"
    [resetEnabled]="!field['required'] && field['resetEnabled']"
    [searchEnabled]="true"
    [searchMinimumLength]="1"
    [searchDebounce]="200"
    [id]="field.name | appUniqueId: idToken"
    [classes]="field.params['classes']"
  >
  </app-select>
</app-field-wrapper>

<app-field-wrapper
  *ngIf="readonly"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelButtons]="buttons"
  [tooltip]="tooltip"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [idToken]="idToken"
>
  <app-auto-field
    *ngIf="relatedFormField"
    [field]="relatedFormField"
    [value]="relatedValue"
    [readonly]="true"
    [label]="false"
  >
  </app-auto-field>
  <ng-container *ngIf="!relatedFormField">
    <span *ngIf="valueStrLoading" class="loading-animation"><span class="stub-text">Record Name</span></span>
    <ng-container *ngIf="!valueStrLoading">
      <ng-container *ngIf="valueStr != undefined">{{ valueStr | appSafeAsync }}</ng-container>
      <ng-container *ngIf="valueStr == undefined">{{ formatEmpty(undefined) }}</ng-container>
    </ng-container>
  </ng-container>
</app-field-wrapper>
