import { Injectable, Injector } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { DynamicComponentArguments } from '@common/dynamic-component';
import { PopupService } from '@common/popups';

import { DialogComponent } from '../../components/dialog/dialog.component';
import { LoadingDialogComponent } from '../../components/loading-dialog/loading-dialog.component';
import { DialogResult } from '../../data/dialog-result';
import { DialogButtonHotkey, DialogButtonType, DialogOptions } from '../../data/options';

export class DialogInstance {
  constructor(private popup: DynamicComponentArguments, private popupService: PopupService) {}

  close() {
    this.popupService.remove(this.popup);
  }
}

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(private popupService: PopupService, private injector: Injector) {}

  dialog<R = any>(options: DialogOptions<R>): Observable<DialogResult<R>> {
    const obs = new Subject<DialogResult<R>>();

    this.popupService.push({
      component: DialogComponent,
      popupComponentDark: options.dark,
      inputs: {
        options: options
      },
      outputs: {
        closed: [result => obs.next(result)]
      },
      injector: this.injector
    });

    return obs;
  }

  confirm(options: DialogOptions): Observable<boolean> {
    return this.dialog(options).pipe(
      map(result => {
        if (result.executorError) {
          throw result.executorError;
        }

        return result.button == 'ok';
      })
    );
  }

  warning(options: DialogOptions): Observable<boolean> {
    if (!options.buttons) {
      options.buttons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: DialogButtonType.Default,
          hotkey: DialogButtonHotkey.Cancel
        },
        {
          name: 'ok',
          label: 'OK',
          type: DialogButtonType.Danger,
          hotkey: DialogButtonHotkey.Submit
        }
      ];
    }

    return this.dialog(options).pipe(
      map(result => {
        if (result.executorError) {
          throw result.executorError;
        }

        return result.button == 'ok';
      })
    );
  }

  alert(options: DialogOptions): Observable<boolean> {
    if (!options.buttons) {
      options.buttons = [
        {
          name: 'ok',
          label: 'OK',
          type: DialogButtonType.Primary,
          hotkey: DialogButtonHotkey.Submit
        }
      ];
    }

    return this.dialog(options).pipe(
      map(result => {
        if (result.executorError) {
          throw result.executorError;
        }

        return result.button == 'ok';
      })
    );
  }

  prompt(options: DialogOptions): Observable<DialogResult> {
    if (!options.buttons) {
      options.buttons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: DialogButtonType.Default,
          hotkey: DialogButtonHotkey.Cancel
        },
        {
          name: 'submit',
          label: 'Submit',
          type: DialogButtonType.Submit,
          hotkey: DialogButtonHotkey.Submit
        }
      ];
    }

    return this.dialog(options);
  }

  loading(options: DialogOptions): DialogInstance {
    const popup = {
      component: LoadingDialogComponent,
      popupComponentDark: options.dark,
      inputs: {
        options: options
      }
    };
    const dialog = new DialogInstance(popup, this.popupService);
    this.popupService.push(popup);
    return dialog;
  }
}
