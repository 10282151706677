import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AdminMode, ROUTE_ADMIN_MODE$ } from '@modules/admin-mode';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  ProjectSettings,
  ProjectSettingsService
} from '@modules/projects';
import { SingletonStore } from '@shared';

import { AllProjectSettings } from '../data/all-project-settings';

@Injectable()
export class ProjectSettingsStore extends SingletonStore<ProjectSettings[]> {
  constructor(
    @Inject(ROUTE_ADMIN_MODE$) private mode$: Observable<AdminMode>,
    private projectSettingsService: ProjectSettingsService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore
  ) {
    super();
  }

  protected fetch(): Observable<ProjectSettings[]> {
    if (!this.currentProjectStore.instance) {
      return of(undefined);
    }

    return this.mode$.pipe(
      switchMap(mode =>
        this.projectSettingsService.get(
          this.currentProjectStore.instance.uniqueName,
          this.currentEnvironmentStore.instance.uniqueName,
          mode == AdminMode.Builder
        )
      )
    );
  }

  get(forceUpdate: boolean = false, options: { includeDeleted?: boolean } = {}): Observable<ProjectSettings[]> {
    return super.get(forceUpdate, options).pipe(
      map(result => {
        if (!result) {
          return result;
        }

        return result.filter(item => options.includeDeleted || !item.deleted);
      })
    );
  }

  getFirst(forceUpdate: boolean = false, options: { includeDeleted?: boolean } = {}): Observable<ProjectSettings[]> {
    return super.getFirst(forceUpdate, options).pipe(
      map(result => {
        if (!result) {
          return result;
        }

        return result.filter(item => options.includeDeleted || !item.deleted);
      })
    );
  }

  createAllProjectSettings(settings: ProjectSettings[]): AllProjectSettings {
    return new AllProjectSettings().deserialize(settings);
  }

  getAllSettings(): AllProjectSettings {
    return this.instance ? this.createAllProjectSettings(this.instance) : undefined;
  }

  getAllSettings$(forceUpdate: boolean = false): Observable<AllProjectSettings> {
    return this.get(forceUpdate).pipe(
      map(result => {
        return result ? this.createAllProjectSettings(result) : undefined;
      })
    );
  }

  getAllSettingsFirst$(forceUpdate: boolean = false): Observable<AllProjectSettings> {
    return this.getFirst(forceUpdate).pipe(
      map(result => {
        return result ? this.createAllProjectSettings(result) : undefined;
      })
    );
  }
}
