import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { combineLatest, of, throwError } from 'rxjs';
import { catchError, delayWhen, map } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { ActionStore } from '@modules/action-queries';
import { TaskQueueStore } from '@modules/collaboration';
import { ViewSettingsStore } from '@modules/customize';
import { Option } from '@modules/field-components';
import { MenuSettingsStore } from '@modules/menu';
import { ModelDescriptionStore } from '@modules/model-queries';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  Environment,
  EnvironmentService,
  ProjectPropertyStore
} from '@modules/projects';
import { controlValue } from '@shared';

@Injectable()
export class ProjectEnvironmentMergePopupForm extends FormGroup {
  controls: {
    from_environment: FormControl;
    to_environment: FormControl;
  };
  environmentOptions: Option<Environment>[] = [];

  constructor(
    private environmentService: EnvironmentService,
    private formUtils: FormUtils,
    private currentProjectStore: CurrentProjectStore,
    private viewSettingsStore: ViewSettingsStore,
    private modelDescriptionStore: ModelDescriptionStore,
    private actionStore: ActionStore,
    private menuSettingsStore: MenuSettingsStore,
    private taskQueueStore: TaskQueueStore,
    private projectPropertyStore: ProjectPropertyStore,
    private currentEnvironmentStore: CurrentEnvironmentStore
  ) {
    super({
      from_environment: new FormControl(undefined, [Validators.required]),
      to_environment: new FormControl(undefined, [Validators.required])
    });
  }

  init(options: { environmentFrom?: Environment; environmentTo?: Environment } = {}) {
    this.environmentOptions = this.currentProjectStore.instance.environments.map(item => {
      return {
        value: item,
        name: item.name
      };
    });

    if (options.environmentFrom) {
      const value = this.environmentOptions.find(
        item => item.value && item.value.uniqueName == options.environmentFrom.uniqueName
      );
      this.controls.from_environment.patchValue(value ? value.value : undefined);
    }

    if (options.environmentTo) {
      const value = this.environmentOptions.find(
        item => item.value && item.value.uniqueName == options.environmentTo.uniqueName
      );
      this.controls.to_environment.patchValue(value ? value.value : undefined);
    }
  }

  environmentOptionsExcept$(control: AbstractControl) {
    return controlValue<Environment>(control).pipe(
      map(environment => {
        return this.environmentOptions.filter(item => !environment || item.value !== environment);
      })
    );
  }

  submit() {
    this.markAsDirty();

    const fromEnvironment = this.controls.from_environment.value;
    const toEnvironment = this.controls.to_environment.value;

    return this.environmentService
      .merge(this.currentProjectStore.instance.uniqueName, fromEnvironment, toEnvironment)
      .pipe(
        delayWhen(() => {
          if (this.currentEnvironmentStore.instance.uniqueName != toEnvironment.uniqueName) {
            return of(undefined);
          }

          return combineLatest(
            this.currentProjectStore.getFirst(true),
            this.viewSettingsStore.getFirst(true),
            this.modelDescriptionStore.getFirst(true),
            this.actionStore.getFirst(true),
            this.menuSettingsStore.getFirst(true),
            this.taskQueueStore.getFirst(true),
            this.projectPropertyStore.getFirst(true)
          );
        }),
        catchError(error => {
          this.formUtils.showFormErrors(this, error);
          return throwError(error);
        })
      );
  }
}
