<div class="menu-pages-group" [class.menu-pages-group_border]="group.border">
  <div class="menu-pages-group__header">
    <div class="menu-pages-group__header-main">
      <div class="menu-pages-group__title">{{ group.title }}</div>
    </div>

    <div *ngIf="group.actions?.length" class="menu-pages-group__header-right">
      <a
        *ngFor="let item of group.actions"
        [appLink]="item.link"
        class="menu-pages-group__header-action"
        (click)="actionClick.emit()"
      >
        <div *ngIf="item.icon" class="menu-pages-group__header-action-icon" [ngClass]="'icon-' + item.icon"></div>

        <div class="menu-pages-group__header-action-label">
          {{ item.label }}
        </div>
      </a>
    </div>
  </div>

  <div class="menu-pages-group__content">
    <a
      *ngFor="let item of displayItems"
      [appLink]="!editing ? item.link : null"
      class="menu-pages__item"
      [class.menu-pages__item_disable-active]="!group.duplicate && hasActiveDuplicateLink && !filteredQuery.length"
      [attr.data-page-uid]="(item.pageUid | appIsSet) ? item.pageUid : null"
      [attr.data-page-unique-name]="(item.page?.uniqueName | appIsSet) ? item.page.uniqueName : null"
      [appLinkActive]="'menu-pages__item_active'"
      [appLinkActiveData]="{ duplicate: group.duplicate, item: item }"
      (click)="itemClick.emit({ item: item, event: $event })"
    >
      <div *ngIf="!editing" class="menu-pages-item menu-pages-item_interactive">
        <div class="menu-pages-item__icon" [ngClass]="[item.icon ? 'icon-' + item.icon : 'icon-document']"></div>
        <div class="menu-pages-item__title">
          {{ item.title | appCapitalize }}
        </div>
        <div *ngIf="homeItem && item === homeItem" class="menu-pages-item__right">
          <span
            class="menu-pages-item__arrow icon-home"
            [appTip]="'Home page'"
            [appTipOptions]="{ side: 'top' }"
          ></span>
        </div>
      </div>

      <div *ngIf="editing" class="menu-pages-item menu-pages-item_interactive">
        <app-checkbox
          class="menu-pages-item__checkbox"
          [value]="selectedPages[item.pageUid]"
          [square]="true"
        ></app-checkbox>
        <div class="menu-pages-item__title">
          {{ item.title | appCapitalize }}
        </div>
      </div>
    </a>

    <div *ngIf="group.collapsible && group.items.length > maxDisplayInitial" class="menu-pages__button">
      <div *ngIf="collapsed" href="javascript:void(0)" class="menu-pages-button" (click)="setCollapsed(false)">
        <span class="menu-pages-button__icon icon-arrow_down_2"></span>
        <span class="menu-pages-button__label">Show all ({{ group.items.length }})</span>
      </div>
      <div *ngIf="!collapsed" href="javascript:void(0)" class="menu-pages-button" (click)="setCollapsed(true)">
        <span class="menu-pages-button__icon icon-arrow_up_2"></span>
        <span class="menu-pages-button__label">Collapse</span>
      </div>
    </div>
  </div>
</div>
