var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnChanges, OnInit } from '@angular/core';
import isEqual from 'lodash/isEqual';
import { of } from 'rxjs';
import { SUBMIT_RESULT_OUTPUT, ViewContextElement } from '@modules/customize';
import { FieldType } from '@modules/fields';
import { WorkflowStepType } from '@modules/workflow';
import { registerWorkflowStepComponent } from '../../../../data/workflow-step-components';
import { WorkflowEditContext } from '../../../../services/workflow-edit-context/workflow-edit.context';
import { WorkflowStepComponent } from '../base-workflow-step/base-workflow-step.component';
function getStepStateContextInfo(state) {
    return {
        name: state.name,
        type: state.type,
        icon: state.icon
    };
}
var TransformWorkflowStepComponent = /** @class */ (function (_super) {
    __extends(TransformWorkflowStepComponent, _super);
    function TransformWorkflowStepComponent(workflowStepsContextElement, contextElement, workflowEditContext, cd) {
        var _this = _super.call(this, contextElement, workflowEditContext, cd) || this;
        _this.workflowStepsContextElement = workflowStepsContextElement;
        return _this;
    }
    TransformWorkflowStepComponent.prototype.ngOnInit = function () {
        this.initContext();
        this.trackRun();
        this.trackExecuteStatus();
        this.stepOnChange(this.step);
        this.trackChanges();
        this.workflowEditContext.updateCreatedElement(this.step, { contextElement: this.contextElement });
    };
    TransformWorkflowStepComponent.prototype.ngOnChanges = function (changes) {
        if (changes.step) {
            if (!changes.step.firstChange) {
                this.stepOnChange(this.step);
            }
        }
    };
    TransformWorkflowStepComponent.prototype.getStepState = function (step) {
        return of({
            step: step,
            name: step ? step.name : undefined,
            title: step ? this.getTitle(step) : undefined,
            icon: step ? step.getIcon() : undefined,
            type: step ? step.type : undefined
        });
    };
    TransformWorkflowStepComponent.prototype.onStateUpdated = function (state) {
        this.title = state.title;
        this.icon = this.step.getIcon();
        if (!isEqual(getStepStateContextInfo(state), getStepStateContextInfo(this.state))) {
            this.updateContextInfo(state);
        }
    };
    TransformWorkflowStepComponent.prototype.initContext = function () {
        this.contextElement.initGlobal({
            uniqueName: this.step.uid,
            name: this.step.name || this.step.type,
            icon: this.step.getIcon(),
            allowSkip: true,
            getFieldValue: function (field, outputs) {
                return outputs[field];
            }
        }, this.workflowStepsContextElement);
    };
    TransformWorkflowStepComponent.prototype.updateContextInfo = function (state) {
        this.contextElement.initInfo({
            name: state.name || state.type,
            icon: state.icon
        });
        this.contextElement.setOutputs([
            {
                uniqueName: SUBMIT_RESULT_OUTPUT,
                name: 'Transform result',
                icon: 'components',
                fieldType: FieldType.JSON,
                external: true
            }
        ]);
    };
    TransformWorkflowStepComponent.prototype.getTitle = function (step) {
        if (step.value) {
            return step.value.valueTypeStr({ context: 'workflow' });
        }
    };
    TransformWorkflowStepComponent.prototype.onRunUpdated = function (event) {
        if (this.contextElement) {
            var result = event.success ? event.result : event.error;
            this.contextElement.setOutputValue(SUBMIT_RESULT_OUTPUT, result);
        }
    };
    return TransformWorkflowStepComponent;
}(WorkflowStepComponent));
export { TransformWorkflowStepComponent };
registerWorkflowStepComponent(WorkflowStepType.Transform, TransformWorkflowStepComponent);
