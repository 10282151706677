var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of, Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { CustomViewLoaderService, CustomViewSource, CustomViewsStore, defaultCustomViewHtml } from '@modules/custom-views';
import { CustomElementItem, CustomizeService, ElementType, registerElementComponent, ViewContextElement } from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { applyParamInputs, LOADING_VALUE, NOT_SET_VALUE } from '@modules/fields';
import { CurrentProjectStore } from '@modules/projects';
import { QueryService } from '@modules/queries';
import { CustomPagePopupComponent } from '../custom-page-popup/custom-page-popup.component';
var CustomElementComponent = /** @class */ (function (_super) {
    __extends(CustomElementComponent, _super);
    function CustomElementComponent(viewContextElement, customViewsStore, customViewLoaderService, queryService, customizeService, currentProjectStore, cd, popup) {
        var _this = _super.call(this) || this;
        _this.viewContextElement = viewContextElement;
        _this.customViewsStore = customViewsStore;
        _this.customViewLoaderService = customViewLoaderService;
        _this.queryService = queryService;
        _this.customizeService = customizeService;
        _this.currentProjectStore = currentProjectStore;
        _this.cd = cd;
        _this.popup = popup;
        _this.loading = true;
        _this.customElementLoading = false;
        _this.customElementUploadPending = false;
        _this.params = {};
        _this.dataInputsChange = new Subject();
        _this.firstVisible = false;
        return _this;
    }
    CustomElementComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initContext(this.element);
        this.updateContextOutputs(this.element);
        this.updateCustomView();
        this.dataInputsChange
            .pipe(filter(function () { return _this.firstVisible; }), debounceTime(10), untilDestroyed(this))
            .subscribe(function () { return _this.onChange(); });
        this.context.outputValues$.pipe(untilDestroyed(this)).subscribe(function () { return _this.dataInputsChange.next(); });
    };
    CustomElementComponent.prototype.ngOnDestroy = function () { };
    CustomElementComponent.prototype.ngOnChanges = function (changes) {
        if (changes.element) {
            this.updateContextInfo(this.element);
            this.updateContextOutputs(this.element);
            this.updateCustomView();
        }
        this.dataInputsChange.next();
    };
    CustomElementComponent.prototype.onFirstVisible = function () {
        this.firstVisible = true;
        this.dataInputsChange.next();
    };
    CustomElementComponent.prototype.onChange = function () {
        var prevData = {
            params: this.params
        };
        var currentParams = applyParamInputs({}, this.element.inputs, {
            context: this.context,
            parameters: this.element.parameters,
            handleLoading: true
        });
        var currentData = this.element
            ? {
                params: pickBy(currentParams, function (v, k) { return v !== LOADING_VALUE && v !== NOT_SET_VALUE; })
            }
            : {};
        if (!isEqual(prevData, currentData)) {
            this.params = currentData.params;
            this.cd.markForCheck();
            var source = this.element.source || (this.customView ? this.customView.source : undefined) || CustomViewSource.View;
            if (source == CustomViewSource.HTML) {
                this.updateHtml();
            }
        }
    };
    CustomElementComponent.prototype.initContext = function (element) {
        this.viewContextElement.initElement({
            uniqueName: element.uid,
            name: element.name,
            element: element,
            popup: this.popup ? this.popup.popup : undefined
        });
    };
    CustomElementComponent.prototype.updateContextInfo = function (element) {
        this.viewContextElement.initInfo({ name: element.name, element: this.element });
    };
    CustomElementComponent.prototype.updateContextOutputs = function (element) {
        this.viewContextElement.setOutputs(element.outputs.map(function (item) { return ({
            uniqueName: item.name,
            name: item.verboseName || item.name,
            icon: item.fieldDescription.icon,
            fieldType: item.field,
            fieldParams: item.params,
            external: true
        }); }));
    };
    CustomElementComponent.prototype.updateCustomView = function () {
        var _this = this;
        if (this.customViewSubscription) {
            this.customViewSubscription.unsubscribe();
            this.customViewSubscription = undefined;
        }
        var customView$;
        if (this.element.customViewTemporary) {
            customView$ = of(this.element.customViewTemporary);
        }
        else if (this.element.customView) {
            customView$ = this.customViewsStore.getDetail(this.element.customView);
        }
        else {
            customView$ = of(undefined);
        }
        this.customViewSubscription = customView$.pipe(untilDestroyed(this)).subscribe(function (customView) {
            _this.customView = customView;
            _this.loading = false;
            _this.cd.detectChanges();
            var source = _this.element.source || (_this.customView ? _this.customView.source : undefined) || CustomViewSource.View;
            if (source == CustomViewSource.CustomElement) {
                _this.init();
                _this.html = undefined;
                _this.view = undefined;
                _this.cd.markForCheck();
            }
            else if (source == CustomViewSource.HTML) {
                _this.updateHtml();
                _this.view = undefined;
                _this.cd.markForCheck();
            }
            else if (source == CustomViewSource.View) {
                _this.view = _this.customView ? _this.customView.view : undefined;
                _this.html = undefined;
                _this.cd.markForCheck();
            }
        }, function () {
            _this.customView = undefined;
            _this.html = undefined;
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    CustomElementComponent.prototype.init = function () {
        var _this = this;
        if (!this.customView) {
            this.customElementUploadPending = false;
            this.cd.markForCheck();
            return;
        }
        if (this.customView.dist instanceof File) {
            this.customElementUploadPending = true;
            this.cd.markForCheck();
            return;
        }
        else {
            this.customElementUploadPending = false;
            this.cd.markForCheck();
        }
        if (this.customElementLoading) {
            return;
        }
        this.customElementLoading = true;
        this.cd.detectChanges();
        this.customViewLoaderService
            .load(this.customView)
            .pipe(untilDestroyed(this))
            .subscribe(function () { return (_this.customElementLoading = false); });
    };
    CustomElementComponent.prototype.updateHtml = function () {
        if (!this.customView || this.customView.html === undefined) {
            this.html = defaultCustomViewHtml;
            this.cd.markForCheck();
            return;
        }
        this.html = this.queryService.applyTokens(this.customView.html, {
            params: this.params
        });
        this.cd.markForCheck();
    };
    CustomElementComponent.prototype.onOutputEmitted = function (name, event) {
        this.viewContextElement.setOutputValue(name, event);
    };
    return CustomElementComponent;
}(BaseElementComponent));
export { CustomElementComponent };
registerElementComponent({
    type: ElementType.Custom,
    component: CustomElementComponent,
    label: 'Custom Component',
    actions: []
});
