<form (ngSubmit)="saveSlackChannel()">
  <app-popup2 [size]="'sm'">
    <div class="custom-page-popup custom-page-popup_style_background">
      <div class="custom-page-popup__header" [class.custom-page-popup__header_overlay]="false">
        <div class="custom-page-popup__header-main">
          <div class="custom-page-popup__title">
            Slack channel
            <div class="custom-page-popup__title-tag custom-page-popup__title-tag_blue-gradient">
              Paid feature
            </div>
          </div>
          <div class="custom-page-popup__description">
            Get the best of Jet Admin support through a personal Slack channel with tailored onboarding and direct
            communication with our team.
          </div>
        </div>
        <div class="custom-page-popup__header-right">
          <a href="javascript:void(0)" class="custom-page-popup__close icon-close" (click)="cancel()"></a>
        </div>
      </div>
      <div class="custom-page-popup__content">
        <ng-container *ngIf="loading">
          <app-page-block>
            <div class="custom-page-popup__section">
              <app-page-block>
                <app-page-heading>
                  <span [class.loading-animation]="true"><span class="stub-text">Join workspace</span></span>
                </app-page-heading>
                <app-page-description>
                  <span [class.loading-animation]="true"><span class="stub-text">Slack channel invite link</span></span>
                </app-page-description>
              </app-page-block>

              <app-page-block>
                <div class="button button_purple button_disabled popup2__footer-item">
                  <span class="button__label">
                    <span [class.loading-animation]="true"><span class="stub-text">Join Slack</span></span>
                  </span>
                </div>
              </app-page-block>
            </div>
          </app-page-block>

          <app-page-block>
            <div class="custom-page-popup__section">
              <app-page-block>
                <app-page-heading>
                  <span [class.loading-animation]="true"><span class="stub-text">Join channel</span></span>
                </app-page-heading>
                <app-page-description>
                  <span [class.loading-animation]="true"><span class="stub-text">Slack channel and user</span></span>
                </app-page-description>
              </app-page-block>

              <app-page-block>
                <div class="field">
                  <label class="field__label">
                    <span [class.loading-animation]="true"><span class="stub-text">Email</span></span>
                  </label>

                  <input
                    type="text"
                    class="input input_small input_fill input_stub stack__grow"
                    [class.loading-animation]="true"
                    [style.max-width.%]="50"
                    disabled
                  />
                </div>
              </app-page-block>

              <app-page-block>
                <div class="field">
                  <label class="field__label">
                    <span [class.loading-animation]="true"><span class="stub-text">Channel</span></span>
                  </label>

                  <input
                    type="text"
                    class="input input_small input_fill input_stub stack__grow"
                    [class.loading-animation]="true"
                    disabled
                  />
                </div>
              </app-page-block>

              <app-page-block>
                <div class="button button_disabled button_no-margin popup2__footer-item">
                  <span class="button__label">
                    <span [class.loading-animation]="true"><span class="stub-text">Join Channel</span></span>
                  </span>
                </div>
              </app-page-block>
            </div>
          </app-page-block>
        </ng-container>

        <ng-container *ngIf="!loading">
          <app-page-block>
            <div class="custom-page-popup__section">
              <app-page-block>
                <app-page-heading>Step 1. Join Slack workspace</app-page-heading>
                <app-page-description>
                  In order to join Slack channel you first need to join our workspace so we you can invite you to the
                  channel
                </app-page-description>
              </app-page-block>

              <app-page-block>
                <button
                  type="button"
                  class="button button_flex popup2__footer-item"
                  [class.button_purple]="!form.joined && !joinSlackDone"
                  [disabled]="joinSlackLoading"
                  (click)="joinSlack()"
                >
                  <app-loader-small *ngIf="joinSlackLoading" class="button__icon button__icon_left"></app-loader-small>
                  <span
                    *ngIf="!joinSlackLoading"
                    class="button__icon button__icon_left button__icon_image"
                    [style.background-image]="'url(/assets/images/resources/icons/slack.svg)'"
                  ></span>
                  <span class="button__label">Join Slack workspace</span>
                </button>
              </app-page-block>
            </div>
          </app-page-block>

          <app-page-block>
            <div class="custom-page-popup__section">
              <app-page-block>
                <app-page-heading>Step 2. Join Slack channel</app-page-heading>
                <app-page-description>
                  Slack email can be different from the Jet Admin one. You can also change default channel name.
                </app-page-description>
              </app-page-block>

              <app-page-block>
                <div class="field">
                  <label class="field__label">
                    Your Slack Email
                    <a
                      *ngIf="form.joined"
                      href="javascript:void(0)"
                      class="field__label-link"
                      [class.field__label-link_disabled]="customizing?.mode == customizeModes.Email"
                      style="margin-left: 4px;"
                      (click)="startCustomize(customizeModes.Email)"
                    >
                      <span class="field__label-link-icon icon-edit"></span>
                      Use another
                    </a>
                  </label>

                  <app-stack>
                    <input
                      type="text"
                      [formControl]="form.controls.email"
                      class="input input_small input_fill stack__grow"
                      placeholder="user@example.com"
                      [appAutofocus]="customizing?.mode == customizeModes.Email"
                      [style.max-width.%]="50"
                    />

                    <ng-container *ngIf="customizing?.mode == customizeModes.Email">
                      <button
                        type="button"
                        class="button button_small button_no-margin"
                        [style.margin-left.px]="8"
                        [disabled]="saveSlackChannelLoading"
                        (click)="cancelCustomize()"
                      >
                        <span class="button__label">Cancel</span>
                      </button>

                      <button
                        type="submit"
                        class="button button_small button_primary button_no-margin"
                        [style.margin-left.px]="8"
                        [disabled]="saveSlackChannelLoading || !form.valid"
                      >
                        <app-loader-small
                          *ngIf="saveSlackChannelLoading"
                          class="button__icon button__icon_left"
                        ></app-loader-small>
                        <span class="button__label">Save</span>
                      </button>
                    </ng-container>
                  </app-stack>
                </div>
              </app-page-block>

              <app-page-block>
                <div class="field">
                  <label class="field__label">
                    Channel name
                    <a
                      *ngIf="form.joined"
                      href="javascript:void(0)"
                      class="field__label-link"
                      [class.field__label-link_disabled]="customizing?.mode == customizeModes.Channel"
                      style="margin-left: 4px;"
                      (click)="startCustomize(customizeModes.Channel)"
                    >
                      <span class="field__label-link-icon icon-edit"></span>
                      Change
                    </a>
                  </label>

                  <app-stack>
                    <div
                      class="input input_small input_fill input_template stack__grow"
                      [class.input_focus]="focus.focused$ | async"
                      [class.input_disabled]="form.controls.channel.disabled"
                    >
                      <div
                        *ngIf="
                          slackChannel?.channelPrefix &&
                          (!slackChannel?.channel ||
                            slackChannel.channel.name.startsWith(slackChannel.channelPrefix) ||
                            customizing?.mode == customizeModes.Channel)
                        "
                        class="input__template-prefix"
                      >
                        {{ slackChannel.channelPrefix }}
                      </div>
                      <input
                        type="text"
                        [formControl]="form.controls.channel"
                        class="input__template-value input__template-value_small input__template-value_fill"
                        placeholder="channel_name"
                        [appAutofocus]="customizing?.mode == customizeModes.Channel"
                        appFocusMonitor
                        #focus="appFocusMonitor"
                      />
                    </div>

                    <ng-container *ngIf="customizing?.mode == customizeModes.Channel">
                      <button
                        type="button"
                        class="button button_small button_no-margin"
                        [style.margin-left.px]="8"
                        [disabled]="saveSlackChannelLoading"
                        (click)="cancelCustomize()"
                      >
                        <span class="button__label">Cancel</span>
                      </button>

                      <button
                        type="submit"
                        class="button button_small button_primary button_no-margin"
                        [style.margin-left.px]="8"
                        [disabled]="saveSlackChannelLoading || !form.valid"
                      >
                        <app-loader-small
                          *ngIf="saveSlackChannelLoading"
                          class="button__icon button__icon_left"
                        ></app-loader-small>
                        <span class="button__label">Save</span>
                      </button>
                    </ng-container>
                  </app-stack>
                </div>
              </app-page-block>

              <app-page-block>
                <a
                  *ngIf="form.joined"
                  [href]="slackChannel.channel.href"
                  class="button button_purple button_flex button_no-margin popup2__footer-item"
                  target="_blank"
                >
                  <span
                    class="button__icon button__icon_left button__icon_image"
                    [style.background-image]="'url(/assets/images/resources/icons/slack.svg)'"
                  ></span>
                  <span class="button__label">Open Slack channel</span>
                </a>

                <button
                  *ngIf="!form.joined"
                  type="submit"
                  class="button button_flex button_no-margin popup2__footer-item"
                  [class.button_purple]="form.joined || joinSlackDone"
                  [disabled]="saveSlackChannelLoading || !form.valid"
                >
                  <app-loader-small
                    *ngIf="saveSlackChannelLoading"
                    class="button__icon button__icon_left"
                  ></app-loader-small>
                  <span
                    *ngIf="!saveSlackChannelLoading"
                    class="button__icon button__icon_left button__icon_image"
                    [style.background-image]="'url(/assets/images/resources/icons/slack.svg)'"
                  ></span>
                  <span class="button__label">Join Slack channel</span>
                </button>
              </app-page-block>
            </div>
          </app-page-block>
        </ng-container>
      </div>
    </div>
  </app-popup2>
</form>
