<div
  *ngIf="view"
  [style.width.px]="horizontal ? view.frame.width : null"
  [style.height.px]="horizontal ? 50 : view.frame.height"
>
  <app-custom-element-view
    [view]="view"
    [parameters]="view.parameters"
    [params]="viewParams$ | async"
  ></app-custom-element-view>
</div>
