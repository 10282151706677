<mat-menu #choose_page_dropdown="matMenu">
  <ng-template matMenuContent>
    <button
      *ngFor="let page of pages"
      mat-menu-item
      class="mat-menu-item-odd"
      [disableRipple]="true"
      (click)="setPageValue(page)"
    >
      {{ page.name }}
    </button>

    <div *ngIf="!pages.length" class="mat-menu-item-message">
      No pages yet
    </div>
  </ng-template>
</mat-menu>

<mat-menu #choose_type_dropdown="matMenu">
  <ng-template matMenuContent>
    <button
      mat-menu-item
      class="mat-menu-item-odd"
      [disableRipple]="true"
      (click)="control.controls.type.patchValue(undefined)"
    >
      <span class="mat-menu-item-icon icon-close"></span>
      No action
    </button>

    <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" [matMenuTriggerFor]="choose_page_dropdown">
      <span class="mat-menu-item-icon icon-document"></span>
      Open page
    </button>

    <button
      mat-menu-item
      class="mat-menu-item-odd"
      [disableRipple]="true"
      (click)="control.controls.type.patchValue(actionTypes.URL)"
    >
      <span class="mat-menu-item-icon icon-external_link"></span>
      Open URL
    </button>

    <button
      *ngFor="let item of systemOptions"
      mat-menu-item
      class="mat-menu-item-odd"
      [disableRipple]="true"
      (click)="control.setSystemValue(item.type); selectSystem.emit(item)"
    >
      <span *ngIf="item.icon" class="mat-menu-item-icon" [ngClass]="'icon-' + item.icon"></span>
      {{ item.actionLabel }}
    </button>
  </ng-template>
</mat-menu>

<div class="sidebar__element">
  <app-sidebar-field [label]="label">
    <ng-container actions>
      <a
        *ngIf="selectedPage"
        [appLink]="selectedPage.link"
        class="sidebar__field-action sidebar__field-action_button icon-external_link"
        [class.sidebar__field-action_active]="false"
        [class.sidebar__field-action_bright-active]="false"
        [appTip]="'Open page'"
        [appTipOptions]="{ side: 'top' }"
        target="_blank"
      ></a>
    </ng-container>

    <div
      class="select select_theme_jet select_fill"
      [class.select_segment-top]="control.controls.type.value == actionTypes.URL"
      [matMenuTriggerFor]="choose_type_dropdown"
      #trigger="matMenuTrigger"
    >
      <a href="javascript:void(0)" class="select__input" [class.select__input_opened]="trigger.menuOpen">
        <ng-container *ngIf="actionValueDisplay | appIsSet">
          <span
            *ngIf="actionValueDisplay.icon"
            class="select__input-icon"
            [ngClass]="'icon-' + actionValueDisplay.icon"
          >
          </span>
          <span class="select__input-label">
            {{ actionValueDisplay.label }}
          </span>
        </ng-container>

        <ng-container *ngIf="!(actionValueDisplay | appIsSet)">
          <span class="select__input-label">Choose action</span>
        </ng-container>

        <span class="select__input-arrow"></span>
      </a>
    </div>
  </app-sidebar-field>

  <app-sidebar-field *ngIf="control.controls.type.value == actionTypes.URL">
    <app-auto-field
      [form]="control"
      [label]="false"
      [field]="
        createField({
          name: 'url',
          field: 'CharField',
          placeholder: 'https://example.com',
          params: { classes: ['input_fill', 'input_segment-bottom'] }
        })
      "
      [autofocus]="true"
    ></app-auto-field>
  </app-sidebar-field>
</div>

<div
  *ngIf="control.controls.type.value == actionTypes.Page && (control.inputFieldProvider.fields$ | async).length"
  class="sidebar__element"
>
  <app-sidebar-field [label]="'Specify Inputs'">
    <app-inputs-edit
      [control]="control.controls.inputs"
      [parameterProvider]="control.inputFieldProvider"
      [context]="context"
      [staticValueDisabled]="true"
      [sortInputs]="undefined"
      [requiredEditable]="false"
      [listWrapper]="false"
      [classes]="'menu-item-action-inputs-item'"
      [object]="analyticsSource"
    ></app-inputs-edit>
  </app-sidebar-field>
</div>
