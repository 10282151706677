import { Overlay } from '@angular/cdk/overlay';
import { CdkPortalOutlet } from '@angular/cdk/portal';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import * as Color from 'color';
import { scaleBand } from 'd3-scale';
import { pointer, select, Selection } from 'd3-selection';
import { arc, pie, PieArcDatum } from 'd3-shape';
import clamp from 'lodash/clamp';
import range from 'lodash/range';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';

import { degToRad, elementResize$, generateAlphanumeric, getCircleIndex, isSet, TypedChanges } from '@shared';

// TODO: Refactor import
import { getColorHex, getColorHexStr, parseColor } from '../../../colors/utils/colors';

import { CHART_COLORS } from '../../data/chart-colors';
import { DataGroup } from '../../data/data-group';
import { Dataset, DatasetGroupLookup } from '../../data/dataset';
import { DataClickEvent } from '../../data/events';
import { DataTooltipController } from '../../services/data-tooltip-controller/data-tooltip.controller';
import { getDatasetsGroupLookup, prepareDataset, sortDatasetsByValue } from '../../utils/dataset';
import { getDateFormatByLookup } from '../../utils/date';

interface DatasetGroup {
  datasetIndex: number;
  groupIndex: number;
}

function isDatasetGroupEqual(lhs: DatasetGroup, rhs: DatasetGroup): boolean {
  return lhs.datasetIndex == rhs.datasetIndex && lhs.groupIndex == rhs.groupIndex;
}

@Component({
  selector: 'app-pie-chart2',
  templateUrl: './pie-chart2.component.html',
  providers: [DataTooltipController],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PieChart2Component implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() datasets: Dataset[] = [];
  @Input() doughnut = false;
  @Input() yFormat: string;
  @Input() min: number;
  @Input() max: number;
  @Input() animate = true;
  @Input() legend = true;
  @Input() interactive = true;
  @Input() datasetBackground = true;
  @Input() dataClickEnabled = false;
  // @Input() width = 3;
  @Input() trackItem = false;
  @Output() itemEnter = new EventEmitter<{ x: number; y: number }>();
  @Output() itemLeave = new EventEmitter<void>();
  @Output() dataClick = new EventEmitter<DataClickEvent>();

  @ViewChild('canvas') canvasElement: ElementRef;
  @ViewChild('svg') svgElement: ElementRef;
  @ViewChild('tooltip_container') tooltipContainerElement: ElementRef;
  @ViewChild(CdkPortalOutlet) portalOutlet: CdkPortalOutlet;

  data: Dataset<number, string | moment.Moment>[] = [];
  dataGroupLookup?: DatasetGroupLookup;
  margin = { top: 0, right: 0, bottom: 0, left: 0 };
  width: number;
  height: number;
  svg: Selection<SVGGElement, {}, any, {}>;
  hoverDatasetGroup$ = new BehaviorSubject<DatasetGroup>(undefined);
  hoverLegendDatasetGroup$ = new BehaviorSubject<DatasetGroup>(undefined);
  selectedDatasetGroups: DatasetGroup[];
  colors = CHART_COLORS;
  uid = generateAlphanumeric(8);

  getId(name: string): string {
    return `${name}-${this.uid}`;
  }

  constructor(
    private el: ElementRef,
    private overlay: Overlay,
    private dataTooltip: DataTooltipController,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<PieChart2Component>): void {
    if (changes.datasets) {
      this.data = this.datasets.map(dataset => prepareDataset(dataset));
      this.dataGroupLookup = getDatasetsGroupLookup(this.data);

      sortDatasetsByValue(this.data);
    }

    if (this.svg) {
      this.rerender();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.init();

      elementResize$(this.canvasElement.nativeElement, false)
        .pipe(untilDestroyed(this))
        .subscribe(() => this.onResize());
    }, 0);
  }

  init() {
    this.initBounds();
    this.initSvg();
    this.renderPie();
    this.renderGradients();
    this.initDatasetHover();
  }

  initBounds() {
    const width = this.canvasElement.nativeElement.offsetWidth;
    const height = this.canvasElement.nativeElement.offsetHeight;

    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;
  }

  initSvg() {
    this.svg = select(this.svgElement.nativeElement)
      .attr('width', '100%')
      .attr('height', '100%')
      .append('g')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`);
  }

  renderPie() {
    const maxRadius = Math.min(this.width, this.height) / 2;
    const pieGenerator = pie<{ group: DataGroup; groupIndex: number }>().value(d => d.group.value);
    const innerRadius = this.doughnut ? maxRadius * 0.5 : 3;

    const xScale = scaleBand()
      .domain(this.data.map((item, i) => i.toString()))
      .range([0, maxRadius - innerRadius])
      .paddingInner(0.05)
      .align(0);

    this.svg
      .selectAll('.chart-pie')
      .data(this.data)
      .join('g')
      .attr('class', 'chart-pie')
      .attr('transform', `translate(${this.width / 2}, ${this.height / 2})`)
      .selectAll('.chart-pie-group')
      .data((dataset, datasetIndex) => {
        const groups = dataset.dataset
          .map((item, i) => ({ group: item, groupIndex: i }))
          .filter((item, groupIndex) =>
            this.isVisibleDatasetGroup({ datasetIndex: datasetIndex, groupIndex: groupIndex })
          );
        return pieGenerator(groups).map(item => {
          return {
            datasetIndex: datasetIndex,
            groupIndex: item.data.groupIndex,
            pieData: item
          };
        });
      })
      .join('path')
      .attr('class', d => {
        const classes = ['chart-pie-group', `chart-pie-group_index-${d.datasetIndex}-${d.groupIndex}`];

        if (this.dataClickEnabled) {
          classes.push('chart-pie-group_clickable');
        }

        return classes.join(' ');
      })
      .attr('d', d => {
        const x = xScale(d.datasetIndex.toString());
        const arcGenerator = arc<PieArcDatum<{ group: DataGroup; groupIndex: number }>>()
          .innerRadius(innerRadius + x)
          .outerRadius(innerRadius + x + xScale.bandwidth())
          .cornerRadius(4)
          .padAngle(item => {
            const arcAngle = item.endAngle - item.startAngle;
            return clamp(degToRad(1.5), 0, arcAngle * 0.5);
          });
        return arcGenerator(d.pieData);
      })
      .attr('fill', d => {
        const pieGradient = this.getId(`pie-gradient-${d.groupIndex}`);
        return `url(#${pieGradient})`;
      })
      .on('mouseenter', (e, d) =>
        this.onMouseEnter({
          datasetIndex: d.datasetIndex,
          group: this.data[d.datasetIndex].dataset[d.groupIndex].group,
          groupIndex: d.groupIndex,
          value: this.data[d.datasetIndex].dataset[d.groupIndex].value,
          event: e
        })
      )
      .on('mousemove', e => this.onMouseMove(e))
      .on('mouseleave', () => this.onMouseLeave())
      .on('click', (e, d) => {
        const group = this.data[d.datasetIndex].dataset[d.groupIndex].group;
        const group2 = this.data[d.datasetIndex].dataset[d.groupIndex].group2;
        const group3 = this.data[d.datasetIndex].dataset[d.groupIndex].group3;
        const value = this.data[d.datasetIndex].dataset[d.groupIndex].value;

        this.onClick({
          datasetIndex: d.datasetIndex,
          groupIndex: d.groupIndex,
          group: group,
          group2: group2,
          group3: group3,
          value: value
        });
      });
  }

  renderGradients() {
    const maxGroups = this.data.length ? Math.max(...this.data.map(item => item.dataset.length)) : 0;
    const gradients = this.svg
      .selectAll('.chart-pie-gradient')
      .data(range(maxGroups))
      .join('linearGradient')
      .attr('id', (d, i) => this.getId(`pie-gradient-${i}`))
      .attr('class', 'chart-pie-gradient')
      .attr('x1', '0%')
      .attr('y1', '100%')
      .attr('x2', '0%')
      .attr('y2', '0%');

    gradients
      .selectAll('stop')
      .data((d, i) => {
        const colorHex = getColorHex(this.color(0, i));
        const clr = parseColor(colorHex, '#000');
        return [
          { offset: '0%', color: clr.lighten(0.2) },
          { offset: '100%', color: clr.darken(0.2) }
        ];
      })
      .join('stop')
      .attr('offset', d => d.offset)
      .attr('stop-color', d => d.color);
  }

  initDatasetHover() {
    combineLatest(this.hoverDatasetGroup$, this.hoverLegendDatasetGroup$)
      .pipe(untilDestroyed(this))
      .subscribe(([hoverDatasetGroup$, hoverLegendDatasetGroup]) => {
        const hoverGroup = [hoverDatasetGroup$, hoverLegendDatasetGroup].find(item => isSet(item));
        this.getAllDatasetGroups().forEach(item => {
          const selector = `.chart-pie-group_index-${item.datasetIndex}-${item.groupIndex}`;
          const nodes = this.svg.selectAll<SVGRectElement, any>(selector).nodes();
          if (!isSet(hoverGroup) || isDatasetGroupEqual(item, hoverGroup)) {
            nodes.forEach(node => node.classList.remove('chart-pie-group_disabled'));
          } else {
            nodes.forEach(node => node.classList.add('chart-pie-group_disabled'));
          }
        });
      });

    this.hoverLegendDatasetGroup$.pipe(untilDestroyed(this)).subscribe(item => {
      if (item) {
        this.showDatasetGroupTooltip(item);
      } else {
        this.dataTooltip.close();
      }
    });
  }

  onMouseEnter(options: {
    datasetIndex: number;
    group: string | moment.Moment;
    groupIndex: number;
    value: number;
    event: MouseEvent;
  }) {
    if (!this.interactive) {
      return;
    }

    if ((event.target as SVGElement).classList.contains('chart-bar_hidden')) {
      return;
    }

    this.dataTooltip.close();

    this.hoverDatasetGroup$.next({ datasetIndex: options.datasetIndex, groupIndex: options.groupIndex });

    const [pointerX, pointerY] = pointer(options.event, this.el.nativeElement);
    const dataset = this.data[options.datasetIndex];

    this.showDatasetTooltip({
      dataset: dataset,
      datasetIndex: options.datasetIndex,
      group: options.group,
      groupIndex: options.groupIndex,
      x: pointerX,
      y: pointerY
    });
  }

  showDatasetTooltip(options: {
    dataset: Dataset<number, string | moment.Moment>;
    datasetIndex: number;
    group: string | moment.Moment;
    groupIndex: number;
    x: number;
    y: number;
  }) {
    const value = options.dataset.dataset[options.groupIndex].value;
    const totalValue = options.dataset.dataset.reduce((acc, item) => acc + item.value, 0);
    let group: string;

    if (options.group instanceof moment) {
      const format = getDateFormatByLookup(this.dataGroupLookup) || 'lll';
      group = (options.group as moment.Moment).format(format);
    } else {
      group = options.group as string;
    }

    const defaultLabel = this.data.length > 1 ? `Dataset ${options.datasetIndex + 1}` : undefined;

    this.dataTooltip.show({
      group: group,
      datasets: [
        {
          value: value,
          valueFormat: options.dataset.format,
          percentage: totalValue > 0 ? value / totalValue : undefined,
          label: isSet(options.dataset.name) ? options.dataset.name : defaultLabel,
          color: this.color(0, options.groupIndex)
        }
      ],
      valueFormat: this.yFormat,
      x: options.x,
      y: options.y,
      portalOutlet: this.portalOutlet,
      reuse: true
    });
  }

  showDatasetGroupTooltip(groupItem: DatasetGroup) {
    const selector = `.chart-pie-group_index-${groupItem.datasetIndex}-${groupItem.groupIndex}`;
    const rootBounds = this.el.nativeElement.getBoundingClientRect();
    const node = this.svg.select<SVGRectElement>(selector).node();

    if (!node) {
      this.dataTooltip.close();
      return;
    }

    const dataset = this.data[groupItem.datasetIndex];
    const group = dataset.dataset[groupItem.groupIndex].group;
    const nodeBounds = node.getBoundingClientRect();
    const x = nodeBounds.left - rootBounds.left + nodeBounds.width * 0.5;
    const y = nodeBounds.top - rootBounds.top + 10;

    this.showDatasetTooltip({
      dataset: dataset,
      datasetIndex: groupItem.datasetIndex,
      group: group,
      groupIndex: groupItem.groupIndex,
      x: x,
      y: y
    });
  }

  onMouseMove(e: MouseEvent) {
    if (!this.interactive) {
      return;
    }

    const [pointerX, pointerY] = pointer(e, this.el.nativeElement);

    this.dataTooltip.move(pointerX, pointerY, true);
  }

  onMouseLeave() {
    if (!this.interactive) {
      return;
    }

    this.hoverDatasetGroup$.next(undefined);

    this.dataTooltip.close();
  }

  onClick(options: DataClickEvent) {
    if (!this.dataClickEnabled) {
      return;
    }

    this.dataClick.emit(options);
  }

  isVisibleDatasetGroup(group: DatasetGroup) {
    return !this.selectedDatasetGroups || this.isSelectedDatasetGroup(group);
  }

  isSelectedDatasetGroup(group: DatasetGroup) {
    return this.selectedDatasetGroups.find(
      item => item.datasetIndex == group.datasetIndex && item.groupIndex == group.groupIndex
    );
  }

  getAllDatasetGroups(): DatasetGroup[] {
    return this.data.reduce((acc, dataset, d) => {
      dataset.dataset.forEach((group, g) => {
        acc.push({ datasetIndex: d, groupIndex: g });
      });
      return acc;
    }, []);
  }

  toggleSelectedDatasetGroup(group: DatasetGroup) {
    if (!this.interactive) {
      return;
    }

    if (!this.selectedDatasetGroups) {
      this.selectedDatasetGroups = this.getAllDatasetGroups().filter(item => !isDatasetGroupEqual(item, group));
    } else if (this.isSelectedDatasetGroup(group)) {
      this.selectedDatasetGroups = this.selectedDatasetGroups.filter(item => !isDatasetGroupEqual(item, group));
    } else {
      this.selectedDatasetGroups.push(group);

      if (this.selectedDatasetGroups.length === this.getAllDatasetGroups().length) {
        this.selectedDatasetGroups = undefined;
      }
    }

    this.cd.markForCheck();

    this.renderPie();

    if (this.isVisibleDatasetGroup(group)) {
      this.showDatasetGroupTooltip(group);
      this.onLegendDatasetGroupMouseEnter(group);
    } else {
      this.dataTooltip.close();
      this.onLegendDatasetGroupMouseLeave();
    }
  }

  onLegendDatasetGroupMouseEnter(group: DatasetGroup) {
    if (!this.interactive) {
      return;
    }

    this.hoverLegendDatasetGroup$.next(group);
  }

  onLegendDatasetGroupMouseLeave() {
    if (!this.interactive) {
      return;
    }

    this.hoverLegendDatasetGroup$.next(undefined);
  }

  rerender() {
    this.initBounds();
    this.renderPie();
    this.renderGradients();
  }

  onResize() {
    this.rerender();
  }

  color(datasetIndex: number, index: number): string {
    if (
      this.data[datasetIndex] &&
      this.data[datasetIndex].dataset[index] &&
      isSet(this.data[datasetIndex].dataset[index].color)
    ) {
      return this.data[datasetIndex].dataset[index].color;
    } else {
      return getCircleIndex(this.colors, index);
    }
  }

  colorDisplay(datasetIndex: number, index: number): string {
    const value = this.color(datasetIndex, index);
    return getColorHexStr(value);
  }

  groupDisplay(value: string | moment.Moment): string {
    if (value instanceof moment) {
      const format = getDateFormatByLookup(this.dataGroupLookup) || 'lll';
      return (value as moment.Moment).format(format);
    } else {
      return value as string;
    }
  }
}
