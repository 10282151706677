import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ActionControllerService } from '@modules/action-queries';
import {
  CustomizeService,
  ElementType,
  registerElementComponent,
  ScannerElementItem,
  ViewContextElement
} from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { FieldType } from '@modules/fields';
import { ScanResult } from '@modules/image-codes';
import { isSet, TypedChanges } from '@shared';

import { VALUE_OUTPUT } from '../auto-field-element/auto-field-element.component';
import { CustomPagePopupComponent } from '../custom-page-popup/custom-page-popup.component';

@Component({
  selector: 'app-scanner-element',
  templateUrl: './scanner-element.component.html',
  providers: [ViewContextElement],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScannerElementComponent extends BaseElementComponent implements OnInit, OnDestroy, OnChanges {
  @Input() element: ScannerElementItem;

  customizeEnabled$: Observable<boolean>;
  scanValue$ = new BehaviorSubject<string>(undefined);

  constructor(
    private customizeService: CustomizeService,
    public viewContextElement: ViewContextElement,
    private actionControllerService: ActionControllerService,
    @Optional() private popup: CustomPagePopupComponent,
    private injector: Injector
  ) {
    super();
  }

  ngOnInit() {
    this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(item => !!item));

    this.initContext();
    this.updateContextOutputs();
    this.updateContextOutputValues();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<ScannerElementComponent>): void {
    if (changes.element && !changes.element.firstChange) {
      this.updateContextInfo();
    }
  }

  initContext() {
    this.viewContextElement.initElement({
      uniqueName: this.element.uid,
      name: this.element.name,
      icon: 'qr_code',
      allowSkip: true,
      element: this.element,
      popup: this.popup ? this.popup.popup : undefined
    });
  }

  updateContextInfo() {
    this.viewContextElement.initInfo({
      name: this.element.name,
      element: this.element,
      getFieldValue: (field, outputs) => {
        return outputs[VALUE_OUTPUT];
      }
    });
  }

  updateContextOutputs() {
    this.viewContextElement.setOutputs([
      {
        uniqueName: VALUE_OUTPUT,
        name: 'Value',
        icon: 'qr_code',
        fieldType: FieldType.Text,
        external: true
      }
    ]);
  }

  updateContextOutputValues() {
    this.scanValue$.pipe(untilDestroyed(this)).subscribe(value => {
      this.viewContextElement.setOutputValue(VALUE_OUTPUT, value);
    });
  }

  onScan(event: ScanResult) {
    if (!isSet(event.text)) {
      return;
    }

    const valueChange = this.scanValue$.value != event.text;

    if (valueChange) {
      this.scanValue$.next(event.text);
    }

    if (valueChange || !this.element.ignoreDuplicateScans) {
      this.element.onScanActions.forEach(action => {
        this.actionControllerService
          .execute(action, {
            context: this.context,
            contextElement: this.viewContextElement,
            localContext: {
              [VALUE_OUTPUT]: event.text
            },
            injector: this.injector
          })
          .subscribe();
      });
    }
  }
}

registerElementComponent({
  type: ElementType.Scanner,
  component: ScannerElementComponent,
  label: 'Scanner',
  actions: []
});
