import { Injectable } from '@angular/core';
import keys from 'lodash/keys';
import * as moment from 'moment-timezone';

import { timezones } from '../../data/timezones';
import { LANGUAGE_KEY, TIMEZONE_KEY } from '../../data/user-preferences';
import { captureException } from '../../utils/logging/logging';

@Injectable({
  providedIn: 'root'
})
export class LocalStorage {
  public get(key: string, defaultValue?: any) {
    return localStorage[key] !== undefined ? localStorage[key] : defaultValue;
  }

  public getObject<T = Object>(key: string): T {
    try {
      return JSON.parse(localStorage[key]);
    } catch (e) {}
  }

  public set(key: string, value: string) {
    try {
      localStorage[key] = value;
    } catch (e) {
      if (e instanceof Error && e.name == 'QuotaExceededError') {
        const largeKeySize = 1024 * 1024 * 0.5;
        const largeKeys = this.keys()
          .map(item => {
            const itemValue = this.get(item);
            const size = itemValue !== undefined && itemValue !== null ? String(itemValue).length : 0;
            let preview: string;

            if (size >= largeKeySize) {
              preview = String(itemValue).substr(0, 200);
            }

            return {
              name: item,
              size: itemValue !== undefined && itemValue !== null ? String(itemValue).length : 0,
              preview: preview
            };
          })
          .sort((lhs, rhs) => (lhs.size - rhs.size) * -1);

        captureException(e, {
          extra: {
            local_storage_keys: largeKeys.map(item => {
              return [item.name, item.size, ...(item.preview !== undefined ? [item.preview] : [])];
            })
          }
        });

        const deleteKeys = largeKeys.filter(item => item.size >= largeKeySize);
        if (deleteKeys.length) {
          deleteKeys.forEach(item => {
            console.log(`[LocalStorage] Deleting too large key "${item.name}" value: ${item.preview}`);
            this.remove(item.name);
          });
          this.set(key, value);
          return;
        }
      }

      throw e;
    }
  }

  public setObject<T = Object>(key: string, value: T) {
    localStorage[key] = JSON.stringify(value);
  }

  public remove(key: string) {
    if (localStorage[key] != undefined) {
      delete localStorage[key];
    }
  }

  public keys(): string[] {
    return keys(localStorage);
  }

  public setLanguage(value: string) {
    this.set(LANGUAGE_KEY, value);
  }

  public getLanguage(): string {
    return this.get(LANGUAGE_KEY);
  }

  public setTimezone(value: string) {
    this.set(TIMEZONE_KEY, value);

    const timezone = value ? timezones.find(item => item.name == value) : undefined;
    const tz = timezone ? timezone.tz : undefined;

    moment.tz.setDefault(tz);
  }

  public getTimezone(): string {
    return this.get(TIMEZONE_KEY);
  }
}
