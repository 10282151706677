<div
  class="list"
  [class.list_scrollable]="scrollable"
  appElement
  [appElementDetectVisible]="{ intervalCheck: 600 }"
  (appElementFirstVisible)="firstVisible$.next(true)"
>
  <div class="list__header" [class.list__header_active]="settings.header">
    <app-list-header
      *ngIf="settings.header"
      [noBottomMargin]="true"
      [params]="params"
      [filtersEnabled]="settings.displayFilters"
      [filters]="listState.filters"
      [filterFields]="settings.filterFields"
      [hideParams]="hideParams"
      [pageSettings]="pageSettings"
      [settings]="settings"
      [dataSource]="listState.dataSource"
      [inputs]="inputs"
      [parameters]="parameters"
      [moreLink]="moreLink"
      [focus]="focus"
      [searchEnabled]="settings.searchEnabled || settings.searchQuery != undefined"
      [search]="listState.search"
      [searchLive]="settings.searchLive"
      [createSegmentAllowed]="createSegmentAllowed"
      [viewId]="viewId"
      [context]="context"
      [contextElement]="contextElement"
      [accentColor]="accentColor"
      (paramsChanged)="paramsChanged.emit($event)"
      (filtersUpdated)="onFiltersUpdated($event)"
      (searchUpdated)="onSearchUpdated($event)"
      (layoutAdd)="layoutAdd.emit($event)"
      (layoutCustomize)="layoutCustomize.emit($event)"
      (segmentCreated)="segmentCreated.emit($event)"
      (updateData)="reloadData()"
      (customizeModelDescription)="customizeModelDescription()"
      (customizeViewSettings)="customizeViewSettings()"
    >
    </app-list-header>
  </div>

  <div class="list__content">
    <div class="list__viewport list__viewport_bounds" appElementResize (elementResize)="onResize()" #viewport_element>
      <app-error *ngIf="!configured" [title]="'Not configured' | localize">
        Unfortunately, this component is not configured yet
      </app-error>

      <div *ngIf="configured && error" class="grid-message">
        <app-error [title]="'Loading failed' | localize">
          {{ error }}
        </app-error>
      </div>

      <app-grid-stub
        *ngIf="configured && !error && items == undefined"
        [animating]="loading"
        [columns]="visibleColumns.length ? visibleColumns.length : scrollable ? 8 : 5"
        [rows]="scrollable ? 8 : loadingItems"
        [rowCards]="rowCards"
        [cardHeight]="cardDefaultHeight"
        [gapHorizontal]="settings.gapHorizontal"
        [gapVertical]="settings.gapVertical"
        [scrollable]="scrollable"
        [height]="heightBeforeLoading"
      >
      </app-grid-stub>

      <ng-container *ngIf="configured && !error && items != undefined">
        <div
          class="grid"
          [style.margin-left.px]="(settings.gapHorizontal | appIsSet) ? -settings.gapHorizontal * 0.5 : null"
          [style.margin-right.px]="(settings.gapHorizontal | appIsSet) ? -settings.gapHorizontal * 0.5 : null"
          [style.margin-top.px]="(settings.gapVertical | appIsSet) ? -settings.gapVertical * 0.5 : null"
          [style.margin-bottom.px]="(settings.gapVertical | appIsSet) ? -settings.gapVertical * 0.5 : null"
        >
          <app-grid-item
            *ngFor="let item of items; let i = index; trackBy: trackByFn"
            [item]="item"
            [modelDescription]="listState.modelDescription"
            [settings]="settings"
            [visibleColumns]="visibleColumns"
            [rowCards]="rowCards"
            [view]="cardView$ | async"
            [context]="context"
            [contextElement]="contextElement"
            [selected]="isItemSelected(item, i)"
            [containerWidth]="gridWidth"
            [accentColor]="accentColor"
            (select)="toggleSelectedItem(item, i, true)"
            (modelUpdated)="onModelUpdated($event)"
          >
          </app-grid-item>

          <div *ngIf="loading" class="grid__loading">
            <app-loader [title]="'Loading'" [fill]="scrollable">
              {{
                'We are fetching {0}, please, wait...'
                  | localize: [title ? (title | appCapitalize) : ('records' | localize)]
              }}
            </app-loader>
          </div>
        </div>

        <div *ngIf="items.length == 0" class="grid-message">
          <app-error [title]="'Nothing found' | localize" [fill]="scrollable">
            {{
              'Unfortunately, no {0} matching your query found'
                | localize: [title ? (title | appCapitalize) : ('records' | localize)]
            }}
          </app-error>
        </div>
      </ng-container>
    </div>

    <app-list-stub-footer
      *ngIf="configured && !error && items == undefined && settings.displayFooter"
      [count]="true"
      [animating]="loading"
      class="list__footer"
    >
    </app-list-stub-footer>

    <app-list-store-footer
      *ngIf="configured && !error && items != undefined && settings.displayFooter"
      [title]="title"
      [listStore]="listStore"
      [viewId]="viewId"
      (pageSelected)="onPageSelected($event)"
      (reload)="reloadData()"
      class="list__footer"
    >
    </app-list-store-footer>
  </div>
</div>
