import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import trim from 'lodash/trim';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { localize } from '@common/localize';
import { TintStyle } from '@modules/customize';
import { isSet, KeyboardEventKeyCode, TypedChanges } from '@shared';

@Component({
  selector: 'app-list-search',
  templateUrl: './list-search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListSearchComponent implements OnInit, OnDestroy, OnChanges {
  @Input() listTitle: string;
  @Input() search: string;
  @Input() focus = false;
  @Input() live = false;
  @Input() accentColor: string;
  @Output() searchUpdated = new EventEmitter<string>();

  updated$ = new Subject<void>();
  placeholder: string;
  active = false;
  tintStyles = TintStyle;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.updated$.pipe(debounceTime(400), untilDestroyed(this)).subscribe(() => {
      this.submit();
    });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<ListSearchComponent>): void {
    if (changes.search) {
      this.search = isSet(this.search) ? this.search : '';
    }

    if (changes.listTitle) {
      this.placeholder = this.listTitle ? `${localize('Search')} ${this.listTitle}...` : `${localize('Search')}...`;
      this.cd.markForCheck();
    }
  }

  toggleActive() {
    this.active = !this.active;
    this.cd.markForCheck();
  }

  submit() {
    const value = trim(this.search);
    this.searchUpdated.next(isSet(value) ? value : undefined);
  }

  clear() {
    this.search = '';
    this.cd.markForCheck();
    this.submit();
  }

  onSearchKey(e) {
    if (e.keyCode == KeyboardEventKeyCode.Enter) {
      this.submit();
    } else if (e.keyCode == KeyboardEventKeyCode.Escape) {
      this.clear();
    } else {
      if (this.live) {
        this.updated$.next();
      }
    }
  }

  onSearchBlur() {
    this.submit();
  }
}
