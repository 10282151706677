<div *ngIf="visible || (customizeEnabled$ | async)" class="text-block text-block_no-margin-collapse">
  <div
    *ngIf="(mode$ | async) == adminModes.App"
    class="ql-container ql-snow ql-editor"
    [innerHTML]="html$ | async"
  ></div>

  <app-text-editor
    *ngIf="(mode$ | async) == adminModes.Builder"
    [value]="element.quillDelta"
    [context]="context"
    [editable]="isEditable"
    [toolbarElement]="toolbarElement"
    [analyticsSource]="element.analyticsGenericName"
    (changed)="save($event)"
  ></app-text-editor>
</div>
