var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Injector } from '@angular/core';
import fromPairs from 'lodash/fromPairs';
import { concat, defer, from, of, throwError } from 'rxjs';
import { catchError, delayWhen, map, tap } from 'rxjs/operators';
import { ServerRequestError } from '@modules/api';
import { exportFormats, ExportFormatType } from '@modules/export';
import { ModelService } from '@modules/model-queries';
import { Model, ModelAction } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { AppError, getExtension, isSet, readFileArrayBuffer, readFileText } from '@shared';
import { ModelImportEventType } from '../../data/model-import.event';
export var IMPORT_OBJECT_ERROR_KEY = '__import_error__';
var ImportService = /** @class */ (function () {
    function ImportService(currentProjectStore, currentEnvironmentStore, resourceControllerService, modelService, injector) {
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.resourceControllerService = resourceControllerService;
        this.modelService = modelService;
        this.injector = injector;
    }
    ImportService.prototype.createModel = function () {
        return Injector.create({
            providers: [{ provide: Model, deps: [Injector] }],
            parent: this.injector
        }).get(Model);
    };
    ImportService.prototype.parseJSONFileData = function (file) {
        return readFileText(file).pipe(map(function (text) {
            try {
                return JSON.parse(text);
            }
            catch (e) {
                throw new ServerRequestError('Incorrect JSON contents');
            }
        }));
    };
    ImportService.prototype.parseWorkbookFileData = function (file) {
        return __awaiter(this, void 0, void 0, function () {
            var XLSX, xlsx, arrayBuffer, dataArr, workbook, worksheet;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        XLSX = import(/* webpackChunkName: "xlsx" */ 'xlsx');
                        return [4 /*yield*/, XLSX];
                    case 1:
                        xlsx = _a.sent();
                        return [4 /*yield*/, readFileArrayBuffer(file).toPromise()];
                    case 2:
                        arrayBuffer = _a.sent();
                        dataArr = new Uint8Array(arrayBuffer);
                        try {
                            workbook = xlsx.read(dataArr, {
                                type: 'array',
                                codepage: 65001,
                                raw: true
                            });
                            worksheet = workbook.Sheets[workbook.SheetNames[0]];
                            return [2 /*return*/, xlsx.utils.sheet_to_json(worksheet, { defval: null })];
                        }
                        catch (e) {
                            throw new AppError("File read failed: " + e);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ImportService.prototype.getFileFormatType = function (file) {
        var extension = (getExtension(file.name) || '').toLowerCase();
        var exportFormat = exportFormats.find(function (item) { return item.extension == extension; });
        return exportFormat ? exportFormat.type : undefined;
    };
    ImportService.prototype.parseFileData = function (file) {
        var fileFormatType = this.getFileFormatType(file);
        if (!fileFormatType) {
            return throwError(new AppError("Unsupported file extension: " + getExtension(file.name)));
        }
        else if (fileFormatType == ExportFormatType.JSON) {
            return this.parseJSONFileData(file);
        }
        else {
            return from(this.parseWorkbookFileData(file));
        }
    };
    ImportService.prototype.importRecord = function (resource, controller, modelDescription, data, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var project = this.currentProjectStore.instance;
        var environment = this.currentEnvironmentStore.instance;
        var instance = this.createModel().deserialize(modelDescription.model, data);
        instance.setUp(modelDescription);
        instance.deserializeAttributes(modelDescription.dbFields);
        instance.setAttributes(data);
        return controller.modelCreate(resource, modelDescription, instance).pipe(delayWhen(function (result) {
            if (options.ignorePostCreate) {
                return of(undefined);
            }
            return _this.modelService.onModelAction(project, environment, resource, controller, modelDescription, result, ModelAction.Create);
        }));
    };
    ImportService.prototype.importRecords = function (resource, modelDescription, sourceData, mappings, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var controller = this.resourceControllerService.get(resource.type);
        var importData = mappings
            ? sourceData.map(function (item) {
                var pairs = mappings
                    .map(function (mapping) {
                    var value = item[mapping.source];
                    if (!isSet(value)) {
                        return;
                    }
                    if (mapping.field.fieldDescription.deserializeValue) {
                        value = mapping.field.fieldDescription.deserializeValue(value, mapping.field);
                    }
                    return [mapping.field.name, value];
                })
                    .filter(function (pair) { return !!pair; });
                return fromPairs(pairs);
            })
            : sourceData;
        if (!importData.length) {
            return of({
                type: ModelImportEventType.Finished,
                modelDescription: modelDescription,
                processedCount: 0,
                successCount: 0,
                failedCount: 0,
                totalCount: 0
            });
        }
        var successCount = 0;
        var failedCount = 0;
        var objectResults = [];
        return concat.apply(void 0, [of({
                type: ModelImportEventType.Started,
                modelDescription: modelDescription,
                totalCount: importData.length
            })].concat(importData.map(function (item, i) {
            return _this.importRecord(resource, controller, modelDescription, item, options).pipe(tap(function () {
                objectResults.push(item);
            }), catchError(function (error) {
                var _a;
                var errorStr;
                if (error instanceof ServerRequestError && error.errors.length) {
                    errorStr = String(error.errors[0]);
                }
                else {
                    errorStr = String(error);
                }
                objectResults.push(__assign({}, sourceData[i], (_a = {}, _a[IMPORT_OBJECT_ERROR_KEY] = errorStr, _a)));
                return of(undefined);
            }), map(function (model) {
                if (model) {
                    ++successCount;
                }
                else {
                    ++failedCount;
                }
                return {
                    type: ModelImportEventType.Progress,
                    modelDescription: modelDescription,
                    processedCount: successCount + failedCount,
                    successCount: successCount,
                    failedCount: failedCount,
                    totalCount: importData.length
                };
            }));
        }), [defer(function () {
                return of({
                    type: ModelImportEventType.Finished,
                    modelDescription: modelDescription,
                    processedCount: successCount + failedCount,
                    successCount: successCount,
                    failedCount: failedCount,
                    totalCount: importData.length,
                    objectResults: objectResults
                });
            })]));
    };
    return ImportService;
}());
export { ImportService };
