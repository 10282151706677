import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService, ServerRequestError } from '@modules/api';
import { getExtension } from '@shared';
import { Environment } from '../../data/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
export var DraftItemsType;
(function (DraftItemsType) {
    DraftItemsType["ViewSettings"] = "view_settings";
    DraftItemsType["ModelDescriptions"] = "model_descriptions";
    DraftItemsType["ActionDescriptions"] = "action_descriptions";
    DraftItemsType["MenuSettings"] = "menu_settings";
    DraftItemsType["Resources"] = "resources";
    DraftItemsType["SecretTokens"] = "secret_tokens";
    DraftItemsType["Storages"] = "storages";
    DraftItemsType["CustomViews"] = "custom_views";
    DraftItemsType["TaskQueues"] = "task_queues";
    DraftItemsType["ProjectProperties"] = "project_properties";
    DraftItemsType["ProjectSettings"] = "project_settings";
})(DraftItemsType || (DraftItemsType = {}));
var EnvironmentService = /** @class */ (function () {
    function EnvironmentService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
    }
    EnvironmentService.prototype.get = function (projectName, environmentName, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'environments/');
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({ fromObject: params });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return result.map(function (item) { return new Environment().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    EnvironmentService.prototype.getDetail = function (projectName, uid, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.projectMethodURL(projectName, "environments/" + uid + "/");
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({ fromObject: params });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return new Environment().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    EnvironmentService.prototype.create = function (projectName, environment) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.projectMethodURL(projectName, 'environments/');
            var headers = new HttpHeaders();
            var data = environment.serialize();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) { return new Environment().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    EnvironmentService.prototype.update = function (projectName, uniqueName, instance, fields) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.projectMethodURL(projectName, "environments/" + uniqueName + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.patch(url, instance.serialize(fields), { headers: headers });
        }), map(function (result) { return new Environment().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    EnvironmentService.prototype.delete = function (projectName, instance) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.projectMethodURL(projectName, "environments/" + instance.uniqueName + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.delete(url, { headers: headers });
        }), map(function () { return true; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    EnvironmentService.prototype.dump = function (projectName, instance) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.nodeEnvironmentMethodURL(projectName, instance.uniqueName, 'dump');
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, {}, { headers: headers, observe: 'response', responseType: 'blob' });
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    EnvironmentService.prototype.restore = function (projectName, instance, dump) {
        var _this = this;
        var extension = getExtension(dump.name);
        if (!extension) {
            return throwError(new ServerRequestError("Backup has no extension"));
        }
        extension = extension.toLowerCase();
        if (extension == 'zip') {
            return this.apiService.refreshToken().pipe(switchMap(function () {
                var url = _this.apiService.nodeEnvironmentMethodURL(projectName, instance.uniqueName, 'restore');
                var headers = new HttpHeaders();
                var formData = new FormData();
                formData.append('file', dump);
                headers = _this.apiService.setHeadersToken(headers);
                return _this.http.post(url, formData, { headers: headers });
            }), map(function () { return true; }), this.apiService.catchApiError(), publishLast(), refCount());
        }
        else if (extension == 'json') {
            return this.apiService.refreshToken().pipe(switchMap(function () {
                var url = _this.apiService.projectMethodURL(projectName, "environments/" + instance.uniqueName + "/restore/");
                var headers = new HttpHeaders();
                var formData = new FormData();
                formData.append('dump', dump);
                headers = _this.apiService.setHeadersToken(headers);
                return _this.http.post(url, formData, { headers: headers });
            }), map(function (result) { return result['result'] || false; }), this.apiService.catchApiError(), publishLast(), refCount());
        }
        else {
            return throwError(new ServerRequestError("Unsupported backup extension: " + extension));
        }
    };
    EnvironmentService.prototype.merge = function (projectName, from, to) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.projectMethodURL(projectName, 'environments/merge/');
            var headers = new HttpHeaders();
            var data = {
                environment_from: from.uniqueName,
                environment_to: to.uniqueName
            };
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    EnvironmentService.prototype.publishDraft = function (projectName, environmentName, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.projectMethodURL(projectName, "environments/" + environmentName + "/publish_draft/");
            var headers = new HttpHeaders();
            var data = _this.serializeEntities(options);
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    EnvironmentService.prototype.deleteDraft = function (projectName, environmentName, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.projectMethodURL(projectName, "environments/" + environmentName + "/delete_draft/");
            var headers = new HttpHeaders();
            var data = _this.serializeEntities(options);
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    EnvironmentService.prototype.serializeEntities = function (options) {
        if (options === void 0) { options = {}; }
        var data = {};
        if (options.itemsType) {
            data['items_type'] = options.itemsType;
        }
        if (options.viewSettings) {
            data['view_settings'] = options.viewSettings;
        }
        if (options.modelDescriptions) {
            data['model_descriptions'] = options.modelDescriptions;
        }
        if (options.actionDescriptions) {
            data['action_descriptions'] = options.actionDescriptions;
        }
        if (options.resources) {
            data['resources'] = options.resources;
        }
        if (options.secretTokens) {
            data['secret_tokens'] = options.secretTokens;
        }
        if (options.storages) {
            data['storages'] = options.storages;
        }
        if (options.customViews) {
            data['custom_views'] = options.customViews;
        }
        if (options.taskQueues) {
            data['task_queues'] = options.taskQueues;
        }
        if (options.projectProperties) {
            data['project_properties'] = options.projectProperties;
        }
        if (options.projectSettings) {
            data['project_settings'] = options.projectSettings;
        }
        return data;
    };
    EnvironmentService.ngInjectableDef = i0.defineInjectable({ factory: function EnvironmentService_Factory() { return new EnvironmentService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService)); }, token: EnvironmentService, providedIn: "root" });
    return EnvironmentService;
}());
export { EnvironmentService };
