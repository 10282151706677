import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import clamp from 'lodash/clamp';
import range from 'lodash/range';

import { TintStyle } from '@modules/customize';
import { TypedChanges } from '@shared';

@Component({
  selector: 'app-dot-pagination',
  templateUrl: './dot-pagination.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DotPaginationComponent implements OnInit, OnChanges {
  @Input() page: number;
  @Input() pages: number;
  @Input() maxSize = 7;
  @Input() accentColor: string;
  @Output() selectPage = new EventEmitter<number>();

  pagesRange: number[] = [];
  translate = 0;
  size: number;
  pageSize = 8 + 3 * 2;
  tintStyles = TintStyle;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: TypedChanges<DotPaginationComponent>): void {
    if (changes.pages) {
      this.pagesRange = range(1, this.pages + 1);
      this.size = Math.min(this.pages, this.maxSize);
    }

    if (changes.page || changes.pages) {
      if (this.pages > this.maxSize) {
        const translate = this.getPageTranslate(this.page);
        const min = this.getPageTranslate(this.pages - Math.floor(this.size / 2));
        const max = 0;
        this.translate = clamp(translate, min, max);
      } else {
        this.translate = 0;
      }
    }
  }

  getPageTranslate(page: number): number {
    return this.size * this.pageSize * 0.5 - (page - 0.5) * this.pageSize;
  }
}
