import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, of, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { quillDeltaToHtml } from '@common/text-editor';
import { AdminMode, ROUTE_ADMIN_MODE, ROUTE_ADMIN_MODE$ } from '@modules/admin-mode';
import {
  CustomizeService,
  CustomizeType,
  ElementType,
  registerElementComponent,
  TextElementItem
} from '@modules/customize';
import { AutoElementComponent, BaseElementComponent } from '@modules/customize-elements';
import { TypedChanges } from '@shared';

@Component({
  selector: 'app-text-element',
  templateUrl: './text-element.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextElementComponent extends BaseElementComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() element: TextElementItem;

  customizeEnabled$: Observable<boolean>;
  toolbarElement: ElementRef<HTMLElement>;
  html$: Observable<string>;
  adminModes = AdminMode;

  constructor(
    @Inject(ROUTE_ADMIN_MODE$) public mode$: Observable<AdminMode>,
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    private customizeService: CustomizeService,
    public autoElementComponent: AutoElementComponent,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(item => !!item));

    this.updateHtml();
  }

  ngAfterViewInit(): void {
    this.setToolbarElement();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<TextElementComponent>): void {
    if (changes.element || changes.context) {
      this.updateHtml();
    }
  }

  get isEditable() {
    return this.customizeService.enabled == CustomizeType.Layout && this.elementActive;
  }

  save(value) {
    this.element.quillDelta = value;
    this.updated.emit(cloneDeep(this.element));
  }

  updateHtml() {
    if (this.mode == AdminMode.App) {
      this.html$ = quillDeltaToHtml(this.element.quillDelta, { context: this.context });
    } else {
      this.html$ = undefined;
    }
  }

  setToolbarElement() {
    if (this.mode != AdminMode.Builder) {
      return;
    }

    timer(0)
      .pipe(
        switchMap(() => {
          const toolbarComponent = this.autoElementComponent.autoElementToolbarComponent;

          if (!toolbarComponent) {
            return of(undefined);
          }

          if (toolbarComponent.toolbarVcr) {
            return of(toolbarComponent.toolbarVcr);
          } else {
            return toolbarComponent.toolbarElementInit.asObservable();
          }
        }),
        untilDestroyed(this)
      )
      .subscribe(toolbarElement => {
        if (toolbarElement) {
          this.toolbarElement = toolbarElement;
          this.cd.markForCheck();
        }
      });
  }
}

registerElementComponent({
  type: ElementType.Text,
  component: TextElementComponent,
  label: 'Text',
  actions: [],
  toolbar: true
});
