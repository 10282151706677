/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../directives/custom-element/custom-element.directive";
import * as i2 from "@angular/common";
import * as i3 from "../../../views-components/components/custom-element-view/custom-element-view.component.ngfactory";
import * as i4 from "../../../customize/data/view-context";
import * as i5 from "../../../projects/stores/current-environment.store";
import * as i6 from "../../../views-components/components/custom-element-view/custom-element-view.component";
import * as i7 from "../../../customize/data/view-context-element";
import * as i8 from "../../../action-queries/services/action-controller/action-controller.service";
import * as i9 from "../../../theme/services/theme/theme.service";
import * as i10 from "../../../../common/notifications/services/notification/notification.service";
import * as i11 from "@angular/platform-browser";
import * as i12 from "../../../features/components/feature-overview-line/feature-overview-line.component.ngfactory";
import * as i13 from "../../../features/components/feature-overview-line/feature-overview-line.component";
import * as i14 from "../../../projects/stores/current-project.store";
import * as i15 from "../../../../shared/directives/element/element.directive";
import * as i16 from "../../../../core/services/document/document.service";
import * as i17 from "../../../../shared/pipes/safe-html/safe-html.pipe";
import * as i18 from "./custom-element.component";
import * as i19 from "../../../custom-views/stores/custom-views.store";
import * as i20 from "../../../custom-views/services/custom-view-loader/custom-view-loader.service";
import * as i21 from "../../../queries/services/query/query.service";
import * as i22 from "../../../customize/services/customize/customize.service";
import * as i23 from "../custom-page-popup/custom-page-popup.component";
var styles_CustomElementComponent = [];
var RenderType_CustomElementComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomElementComponent, data: {} });
export { RenderType_CustomElementComponent as RenderType_CustomElementComponent };
function View_CustomElementComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_CustomElementComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent.parent.parent, 0), _co.html)); _ck(_v, 1, 0, currVal_0); }); }
function View_CustomElementComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Save this component to initialize"]))], null, null); }
function View_CustomElementComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["appCustomElement", ""]], null, [[null, "outputEmitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("outputEmitted" === en)) {
        var pd_0 = (_co.onOutputEmitted($event.name, $event.event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 737280, null, 0, i1.CustomElementDirective, [i0.ElementRef], { tagName: [0, "tagName"], outputs: [1, "outputs"], inputs: [2, "inputs"] }, { outputEmitted: "outputEmitted" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customView.tagName; var currVal_1 = _co.element.outputs; var currVal_2 = _co.params; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_CustomElementComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomElementComponent_7)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomElementComponent_8)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customElementUploadPending; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.customElementUploadPending; _ck(_v, 4, 0, currVal_1); }, null); }
function View_CustomElementComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "app-custom-element-view", [], null, null, null, i3.View_CustomElementViewComponent_0, i3.RenderType_CustomElementViewComponent)), i0.ɵprd(131584, null, i4.ViewContext, i4.ViewContext, [i5.CurrentEnvironmentStore]), i0.ɵprd(512, null, i6.parametersToken, i7.ViewContextElement, [i4.ViewContext]), i0.ɵprd(512, null, i6.actionsToken, i7.ViewContextElement, [i4.ViewContext]), i0.ɵprd(512, null, i6.stateToken, i7.ViewContextElement, [i4.ViewContext]), i0.ɵprd(512, null, i6.layerToken, i7.ViewContextElement, [i4.ViewContext]), i0.ɵdid(6, 770048, null, 0, i6.CustomElementViewComponent, [i4.ViewContext, i6.parametersToken, i6.actionsToken, i6.stateToken, i6.layerToken, i8.ActionControllerService, i9.ThemeService, i10.NotificationService, i11.DomSanitizer, i0.Injector, i0.ChangeDetectorRef], { view: [0, "view"], parameters: [1, "parameters"], actions: [2, "actions"], params: [3, "params"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.view; var currVal_1 = _co.element.parameters; var currVal_2 = _co.element.actions; var currVal_3 = _co.params; _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_CustomElementComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" This component is not configured yet "]))], null, null); }
function View_CustomElementComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomElementComponent_5)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomElementComponent_6)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomElementComponent_9)), i0.ɵdid(6, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomElementComponent_10)), i0.ɵdid(8, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.html; _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.customView == null) ? null : _co.customView.dist); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.view; _ck(_v, 6, 0, currVal_2); var currVal_3 = ((!_co.html && !((_co.customView == null) ? null : _co.customView.dist)) && !_co.view); _ck(_v, 8, 0, currVal_3); }, null); }
function View_CustomElementComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomElementComponent_4)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loading; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CustomElementComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "app-feature-overview-line", [], null, null, null, i12.View_FeatureOverviewLineComponent_0, i12.RenderType_FeatureOverviewLineComponent)), i0.ɵdid(1, 245760, null, 0, i13.FeatureOverviewLineComponent, [i14.CurrentProjectStore], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "div", [["data-subtitle", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Paid Feature"])), (_l()(), i0.ɵeld(4, 0, null, 1, 3, "div", [["data-title", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Create any component with "])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Custom Components"])), (_l()(), i0.ɵeld(8, 0, null, 2, 1, "div", [["data-description", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Write HTML or upload JS-based component built with React/Vue/Angular or JS Vanilla "]))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_CustomElementComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["appElement", ""], ["style", "width: 100%; height: 100%;"]], null, [[null, "appElementFirstVisible"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("appElementFirstVisible" === en)) {
        var pd_0 = (_co.onFirstVisible() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 8601600, null, 0, i15.ElementDirective, [i0.ElementRef, i16.DocumentService], { appElementDetectVisible: [0, "appElementDetectVisible"] }, { appElementFirstVisible: "appElementFirstVisible" }), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_CustomElementComponent_2)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfThen: [1, "ngIfThen"], ngIfElse: [2, "ngIfElse"] }, null), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["content", 2]], null, 0, null, View_CustomElementComponent_3)), (_l()(), i0.ɵand(0, [["not_enabled", 2]], null, 0, null, View_CustomElementComponent_11))], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.currentProjectStore.instance$)).features.isCustomViewsEnabled(); var currVal_2 = i0.ɵnov(_v, 5); var currVal_3 = i0.ɵnov(_v, 6); _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_CustomElementComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i17.SafeHtmlPipe, [i11.DomSanitizer]), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_CustomElementComponent_1)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.visible || i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.customizeService.enabled$))); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CustomElementComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-custom-element", [], null, null, null, View_CustomElementComponent_0, RenderType_CustomElementComponent)), i0.ɵprd(131584, null, i7.ViewContextElement, i7.ViewContextElement, [i4.ViewContext]), i0.ɵdid(2, 770048, null, 0, i18.CustomElementComponent, [i7.ViewContextElement, i19.CustomViewsStore, i20.CustomViewLoaderService, i21.QueryService, i22.CustomizeService, i14.CurrentProjectStore, i0.ChangeDetectorRef, [2, i23.CustomPagePopupComponent]], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var CustomElementComponentNgFactory = i0.ɵccf("app-custom-element", i18.CustomElementComponent, View_CustomElementComponent_Host_0, { element: "element", elementActive: "elementActive", context: "context", visible: "visible", actionClicked: "actionClicked", accentColor: "accentColor" }, { updated: "updated", replaceRequested: "replaceRequested", deleteRequested: "deleteRequested" }, []);
export { CustomElementComponentNgFactory as CustomElementComponentNgFactory };
