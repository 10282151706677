import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  InjectionToken,
  Injector,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import cloneDeep from 'lodash/cloneDeep';
import fromPairs from 'lodash/fromPairs';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, merge, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { delayWhen, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { DialogService } from '@common/dialogs';
import { AppDropListGroup } from '@common/drag-drop2';
import { getCurrentLanguage } from '@common/localize';
import { DocumentService } from '@core';
import { ActionControllerService, ActionService } from '@modules/action-queries';
import { ActionItem } from '@modules/actions';
import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { AllProjectSettings, ProjectSettingsStore } from '@modules/all-project-settings';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ApiService } from '@modules/api';
import { ChangeCustomizeBarComponent, CustomizeBarTab } from '@modules/change-components';
import {
  AdminComponentService,
  CustomizeCreatePopupOptions,
  CustomizeHandler,
  CustomizeOpenPopupOptions,
  CustomizeService,
  CustomViewSettings,
  ElementItem,
  filterElementItems,
  isElementClickEvent,
  ModelData,
  PopupSettings,
  ViewContext,
  ViewContextElement,
  ViewContextElementFactory,
  ViewContextElementType,
  ViewContextGlobalsService,
  ViewContextOutput,
  ViewSettings,
  ViewSettingsService,
  ViewSettingsStore,
  ViewSettingsType
} from '@modules/customize';
import { CustomizeBarContext, CustomizeBarService, ProjectPropertyEditController } from '@modules/customize-bar';
import { CustomPagePopupsComponent } from '@modules/customize-components';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { BUILDER_ADD_ELEMENT, ElementContainerService } from '@modules/customize-elements';
import { DataSourceGeneratorService } from '@modules/customize-generators';
import { ViewSettingsQueries } from '@modules/customize-utils';
import { ModelDescriptionDataSourceService } from '@modules/data-sources-queries';
import { FieldType, getFieldDescriptionByType } from '@modules/fields';
import { MenuSection, MenuService, MenuSettingsStore } from '@modules/menu';
import { MetaService } from '@modules/meta';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ViewContextTokenProvider } from '@modules/parameters-components';
import { ProjectApiService } from '@modules/project-api';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  ProjectProperty,
  ProjectPropertyStorage,
  ProjectPropertyStore,
  ProjectPropertyType
} from '@modules/projects';
import { ModelDescriptionQuery, QueryType } from '@modules/queries';
import { RoutingService } from '@modules/routing';
import { CurrentUserStore } from '@modules/users';
import {
  addClass,
  ascComparator,
  capitalize,
  getLocationAppPath,
  getLocationQueryParams,
  isElementHasChild,
  isSet,
  removeClass
} from '@shared';

import { CustomPagePropertiesComponent } from '../../../customize-components/components/custom-page-properties/custom-page-properties.component';
import {
  CustomPageQueryController,
  queryControllerContextElementToken,
  queryControllerParentElementToken,
  queryControllerQueryToken
} from './custom-page-query.controller';

export const parametersToken = new InjectionToken<ViewContextElement>('parametersToken');
export const queriesToken = new InjectionToken<ViewContextElement>('queriesToken');
export const actionsToken = new InjectionToken<ViewContextElement>('actionsToken');
export const popupsToken = new InjectionToken<ViewContextElement>('popupsToken');
export const teamToken = new InjectionToken<ViewContextElement>('teamToken');
export const teamPropertiesToken = new InjectionToken<ViewContextElement>('teamPropertiesToken');
export const userToken = new InjectionToken<ViewContextElement>('userToken');
export const userPropertiesToken = new InjectionToken<ViewContextElement>('userPropertiesToken');
export const globalPropertiesToken = new InjectionToken<ViewContextElement>('globalPropertiesToken');
export const pagePropertiesToken = new InjectionToken<ViewContextElement>('pagePropertiesToken');
export const appPropertiesToken = new InjectionToken<ViewContextElement>('appPropertiesToken');
export const devicePropertiesToken = new InjectionToken<ViewContextElement>('appDeviceToken');

const markClickEventSidebarProperty = '_markClickEventSidebarProperty';

export function markSidebarClickEvent(clickEvent: MouseEvent) {
  clickEvent[markClickEventSidebarProperty] = true;
}

export function isSidebarClickEvent(clickEvent: MouseEvent) {
  return !!clickEvent[markClickEventSidebarProperty];
}

export interface CustomPageState {
  viewSettings: CustomViewSettings;
}

enum PageSettings {
  Parameters = 'parameters',
  Queries = 'queries',
  Popups = 'popups',
  Properties = 'properties'
}

@Component({
  selector: 'app-custom-page',
  templateUrl: './custom-page.component.html',
  providers: [
    CustomizeBarContext,
    ViewContext,
    ViewContextTokenProvider,
    { provide: parametersToken, useClass: ViewContextElement },
    { provide: queriesToken, useClass: ViewContextElement },
    { provide: actionsToken, useClass: ViewContextElement },
    { provide: popupsToken, useClass: ViewContextElement },
    { provide: teamToken, useClass: ViewContextElement },
    { provide: teamPropertiesToken, useClass: ViewContextElement },
    { provide: userToken, useClass: ViewContextElement },
    { provide: userPropertiesToken, useClass: ViewContextElement },
    { provide: globalPropertiesToken, useClass: ViewContextElement },
    { provide: pagePropertiesToken, useClass: ViewContextElement },
    { provide: appPropertiesToken, useClass: ViewContextElement },
    { provide: devicePropertiesToken, useClass: ViewContextElement },
    ViewContextGlobalsService
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomPageComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit, CustomizeHandler<CustomPageState> {
  @Input() uniqueName: string;
  @Input() params: Object;

  @ViewChild(ChangeCustomizeBarComponent) customizeBarComponent: ChangeCustomizeBarComponent;
  @ViewChild(CustomPagePopupsComponent) popupsComponent: CustomPagePopupsComponent;
  @ViewChild(CustomPagePropertiesComponent) propertiesComponent: CustomPagePropertiesComponent;
  @ViewChild('sidebar') sidebarElement: ElementRef;
  @ViewChildren(AppDropListGroup) dropListGroups = new QueryList<AppDropListGroup>();

  initialViewSettings: CustomViewSettings;
  currentViewSettings: CustomViewSettings;
  loadSubscription: Subscription;
  renameLoading = false;
  duplicateLoading = false;
  deleteLoading = false;
  viewContextInitialized = new ReplaySubject<void>(1);
  queriesSubscriptions: Subscription[] = [];
  pageSubscriptions: Subscription[] = [];
  queryControllers: CustomPageQueryController[] = [];
  pageActions: ViewContextElement[] = [];
  allSettings$: Observable<AllProjectSettings>;
  pageSettingsItems: { type: PageSettings; label: string; icon?: string; iconSize?: number }[] = [
    {
      type: PageSettings.Parameters,
      label: 'Parameters',
      icon: 'input',
      iconSize: 20
    },
    {
      type: PageSettings.Queries,
      label: 'Actions',
      icon: 'play_2',
      iconSize: 17
    },
    {
      type: PageSettings.Popups,
      label: 'Modals',
      icon: 'windows'
    },
    {
      type: PageSettings.Properties,
      label: 'Variables',
      icon: 'variable',
      iconSize: 19
    }
  ];
  currentPageSettings: PageSettings;
  adminModes = AdminMode;
  pageSettings = PageSettings;

  constructor(
    @Inject(ROUTE_ADMIN_MODE) public mode: AdminMode,
    private adminComponentService: AdminComponentService,
    public customizeService: CustomizeService,
    public currentProjectStore: CurrentProjectStore,
    public projectSettingsStore: ProjectSettingsStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private viewSettingsService: ViewSettingsService,
    private viewSettingsQueries: ViewSettingsQueries,
    private viewSettingsStore: ViewSettingsStore,
    private menuSettingsStore: MenuSettingsStore,
    private customizeBarService: CustomizeBarService,
    private customizeBarContext: CustomizeBarContext,
    private elementContainerService: ElementContainerService,
    private documentService: DocumentService,
    private routing: RoutingService,
    public context: ViewContext,
    private globalsService: ViewContextGlobalsService,
    @Inject(parametersToken) private viewContextElementParameters: ViewContextElement,
    @Inject(queriesToken) private viewContextElementQueries: ViewContextElement,
    @Inject(actionsToken) private viewContextElementActions: ViewContextElement,
    @Inject(popupsToken) public viewContextElementPopups: ViewContextElement,
    @Inject(teamToken) private viewContextElementTeam: ViewContextElement,
    @Inject(teamPropertiesToken) private viewContextElementTeamProperty: ViewContextElement,
    @Inject(userToken) private viewContextElementUser: ViewContextElement,
    @Inject(userPropertiesToken) private viewContextElementUserProperty: ViewContextElement,
    @Inject(globalPropertiesToken) private viewContextElementGlobalProperty: ViewContextElement,
    @Inject(pagePropertiesToken) private viewContextElementPageProperty: ViewContextElement,
    @Inject(appPropertiesToken) private viewContextElementAppProperty: ViewContextElement,
    @Inject(devicePropertiesToken) private viewContextElementDeviceProperty: ViewContextElement,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private metaService: MetaService,
    private cd: ChangeDetectorRef,
    private menuService: MenuService,
    private currentUserStore: CurrentUserStore,
    private projectPropertyStore: ProjectPropertyStore,
    private projectPropertyEditController: ProjectPropertyEditController,
    private modelDescriptionStore: ModelDescriptionStore,
    private actionControllerService: ActionControllerService,
    private apiService: ApiService,
    private projectApiService: ProjectApiService,
    private elementConfigurationService: ElementConfigurationService,
    private viewContextElementFactory: ViewContextElementFactory,
    private dialogService: DialogService,
    private injector: Injector,
    private zone: NgZone,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnInit() {
    this.menuService.section = MenuSection.Default;
    this.customizeService.setHandler(this);
    this.allSettings$ = this.projectSettingsStore.getAllSettings$();
    this.updateCustomizeHandleInfo();

    this.initViewContextElements();

    this.projectPropertyStore
      .getGlobal()
      .pipe(untilDestroyed(this))
      .subscribe(properties => {
        const outputs: ViewContextOutput[] = properties
          .map(item => {
            return {
              uniqueName: item.uid,
              name: item.name,
              icon: item.fieldDescription.icon,
              ...(item.field
                ? {
                    fieldType: item.field.field,
                    fieldParams: item.field.params
                  }
                : {})
            };
          })
          .sort((lhs, rhs) => ascComparator(lhs.name, rhs.name));
        this.viewContextElementGlobalProperty.setOutputs(outputs);
      });

    this.currentEnvironmentStore.globalStorage
      .getValues$()
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.viewContextElementGlobalProperty.setOutputValues({ ...value });
      });

    combineLatest(
      this.currentUserStore.instance$,
      this.currentProjectStore.instance$,
      this.currentEnvironmentStore.instance$,
      this.projectPropertyStore.get(),
      this.currentEnvironmentStore.globalStorage.getValues$()
    )
      .pipe(untilDestroyed(this))
      .subscribe(([currentUser, currentProject, currentEnvironment, projectProperties, globalProjectProperties]) => {
        const userProperties = projectProperties.filter(item => item.type == ProjectPropertyType.User);
        const groupProperties = projectProperties.filter(item => item.type == ProjectPropertyType.Group);

        this.updateContextUser();
        this.updateContextUserProperties(userProperties);
        this.updateContextTeam();
        this.updateContextTeamProperties(groupProperties);

        if (currentEnvironment.user) {
          const value = fromPairs(
            userProperties.map(property => [property.uid, currentEnvironment.user.getPropertyValue(property)])
          );

          this.viewContextElementUserProperty.setOutputValues(value);
        }

        if (currentEnvironment.group) {
          const value = fromPairs(
            groupProperties.map(property => [property.uid, currentEnvironment.group.getPropertyValue(property)])
          );
          this.viewContextElementTeamProperty.setOutputValues(value);
        }

        this.viewContextElementTeam.setOutputValues({
          ...(this.currentEnvironmentStore.instance.group
            ? {
                uid: this.currentEnvironmentStore.instance.group.uid,
                name: this.currentEnvironmentStore.instance.group.name
              }
            : {})
        });

        this.viewContextElementUser.setOutputValues({
          email: this.currentUserStore.instance.email,
          first_name: this.currentUserStore.instance.firstName,
          last_name: this.currentUserStore.instance.lastName,
          token: this.apiService.getAccessToken() ? this.apiService.getAccessToken() : undefined,
          project_token: this.projectApiService.getAccessToken() ? this.projectApiService.getAccessToken() : undefined,
          uid: this.currentEnvironmentStore.instance.user ? this.currentEnvironmentStore.instance.user.uid : undefined,
          language: getCurrentLanguage()
        });

        this.viewContextElementAppProperty.patchOutputValues({
          name: currentProject.uniqueName,
          env_name: currentEnvironment.uniqueName
        });

        this.viewContextInitialized.next();
      });

    merge(of({}), this.router.events.pipe(filter(item => item instanceof ActivationEnd)))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.viewContextElementAppProperty.patchOutputValues({
          app_path: getLocationAppPath(),
          query_params: getLocationQueryParams()
        });
      });

    this.documentService.viewportSize$.pipe(untilDestroyed(this)).subscribe(value => {
      const type = this.documentService.getViewportType(value.width);

      this.viewContextElementDeviceProperty.patchOutputValues({
        is_desktop: this.documentService.isDesktopViewportType(type),
        is_mobile: this.documentService.isMobileViewportType(type),
        is_phone: this.documentService.isPhoneViewportType(type),
        is_tablet: this.documentService.isTabletViewportType(type),
        screen_width: value.width,
        screen_height: value.height
      });
    });

    merge(of(this.dropListGroups.toArray()), this.dropListGroups.changes.pipe(map(() => this.dropListGroups.toArray())))
      .pipe(untilDestroyed(this))
      .subscribe(groups => {
        this.context.dropListGroups = groups;
      });
  }

  initViewContextElements() {
    this.viewContextElementParameters.unregister();
    this.viewContextElementParameters.initGlobal({ uniqueName: 'page', name: 'Page' });

    this.viewContextElementQueries.unregister();
    this.viewContextElementQueries.initGlobal({ uniqueName: 'queries', name: 'Page queries' });

    this.viewContextElementActions.unregister();
    this.viewContextElementActions.initGlobal({ uniqueName: 'actions', name: 'Page actions' });

    this.viewContextElementPopups.unregister();
    this.viewContextElementPopups.initGlobal({ uniqueName: 'modals', name: 'Modals' });

    this.viewContextElementTeam.unregister();
    this.viewContextElementTeam.initGlobal({ uniqueName: 'group', name: 'Team' });

    this.viewContextElementTeamProperty.unregister();
    this.viewContextElementTeamProperty.initGlobal({
      uniqueName: 'team_properties',
      name: 'Team Properties',
      action: {
        label: 'Add Property',
        icon: 'plus',
        handler: () => {
          return this.projectPropertyEditController
            .create({
              type: ProjectPropertyType.Group,
              context: this.context,
              analyticsSource: 'component_input'
            })
            .pipe(
              map(item => {
                return {
                  insertToken: ['team_properties', item.property.uid]
                };
              })
            );
        }
      },
      documentation: 'conditional-visibility/properties'
    });

    this.viewContextElementUser.unregister();
    this.viewContextElementUser.initGlobal({ uniqueName: 'user', name: 'User' });

    this.viewContextElementUserProperty.unregister();
    this.viewContextElementUserProperty.initGlobal({
      uniqueName: 'user_properties',
      name: 'User Properties',
      action: {
        label: 'Add Property',
        icon: 'plus',
        handler: () => {
          return this.projectPropertyEditController
            .create({
              type: ProjectPropertyType.User,
              context: this.context,
              analyticsSource: 'component_input'
            })
            .pipe(
              map(item => {
                return {
                  insertToken: ['user_properties', item.property.uid]
                };
              })
            );
        }
      },
      documentation: 'conditional-visibility/properties'
    });

    this.viewContextElementGlobalProperty.unregister();
    this.viewContextElementGlobalProperty.initGlobal({
      uniqueName: 'global_variables',
      name: 'Global variables',
      action: {
        label: 'Add Variable',
        icon: 'plus',
        handler: () => {
          return this.projectPropertyEditController
            .create({
              type: ProjectPropertyType.Global,
              defaultName: 'variable',
              defaultValueEnabled: true,
              context: this.context,
              analyticsSource: 'component_input'
            })
            .pipe(
              map(item => {
                return {
                  insertToken: ['global_variables', item.property.uid]
                };
              })
            );
        }
      },
      documentation: 'conditional-visibility/properties'
    });

    this.viewContextElementPageProperty.unregister();
    this.viewContextElementPageProperty.initGlobal({
      uniqueName: 'page_variables',
      name: 'Page variables',
      action: {
        label: 'Add Variable',
        icon: 'plus',
        handler: () => {
          return this.projectPropertyEditController
            .create({
              type: ProjectPropertyType.Page,
              defaultName: 'variable',
              defaultValueEnabled: true,
              pageUid: this.currentViewSettings.uid,
              context: this.context,
              analyticsSource: 'component_input'
            })
            .pipe(
              map(item => {
                return {
                  insertToken: ['page_variables', item.property.uid]
                };
              })
            );
        }
      },
      documentation: 'conditional-visibility/properties'
    });

    this.viewContextElementAppProperty.unregister();
    this.viewContextElementAppProperty.initGlobal({ uniqueName: 'app', name: 'Application' });
    this.viewContextElementAppProperty.setOutputs([
      {
        uniqueName: 'name',
        name: 'App name',
        icon: 'home'
      },
      {
        uniqueName: 'env_name',
        name: 'Environment name',
        icon: 'tag'
      },
      {
        uniqueName: 'app_path',
        name: 'App path',
        icon: 'console'
      },
      {
        uniqueName: 'query_params',
        name: 'URL query parameters',
        icon: 'components',
        fieldType: FieldType.JSON
      }
    ]);

    this.viewContextElementDeviceProperty.unregister();
    this.viewContextElementDeviceProperty.initGlobal({ uniqueName: 'device', name: 'Device' });
    this.viewContextElementDeviceProperty.setOutputs([
      {
        uniqueName: 'is_desktop',
        name: 'Is Desktop',
        icon: 'pages'
      },
      {
        uniqueName: 'is_mobile',
        name: 'Is Mobile',
        subtitle: '(Phone or Tablet)',
        icon: 'pages'
      },
      {
        uniqueName: 'is_phone',
        name: 'Is Phone',
        icon: 'pages'
      },
      {
        uniqueName: 'is_tablet',
        name: 'Is Tablet',
        icon: 'pages'
      },
      {
        uniqueName: 'screen_width',
        name: 'Screen width',
        icon: 'align_horizontal_fill'
      },
      {
        uniqueName: 'screen_height',
        name: 'Screen height',
        icon: 'align_vertical_fill'
      }
    ]);
  }

  updateContextUser() {
    this.viewContextElementUser.setOutputs([
      {
        uniqueName: 'email',
        name: 'Email',
        icon: 'email'
      },
      {
        uniqueName: 'first_name',
        name: 'First name',
        icon: 'user'
      },
      {
        uniqueName: 'last_name',
        name: 'Last name',
        icon: 'user'
      },
      {
        uniqueName: 'token',
        name: 'User token',
        icon: 'key'
      },
      {
        uniqueName: 'project_token',
        name: 'User app token',
        icon: 'key'
      },
      {
        uniqueName: 'uid',
        name: 'User ID',
        icon: 'number'
      },
      {
        uniqueName: 'language',
        name: 'Language',
        icon: 'earth_planet'
      }
    ]);
  }

  updateContextUserProperties(properties: ProjectProperty[]) {
    const outputs: ViewContextOutput[] = properties.map(item => {
      return {
        uniqueName: item.uid,
        name: item.name,
        icon: item.fieldDescription.icon,
        ...(item.field
          ? {
              fieldType: item.field.field,
              fieldParams: item.field.params
            }
          : {})
      };
    });
    this.viewContextElementUserProperty.setOutputs(outputs);
  }

  updateContextTeam() {
    this.viewContextElementTeam.setOutputs([
      {
        uniqueName: 'uid',
        name: 'Team ID',
        icon: 'number'
      },
      {
        uniqueName: 'name',
        name: 'Team name',
        icon: 'users_teams'
      }
    ]);
  }

  updateContextTeamProperties(properties: ProjectProperty[]) {
    const outputs: ViewContextOutput[] = properties.map(item => {
      return {
        uniqueName: item.uid,
        name: item.name,
        icon: item.fieldDescription.icon,
        ...(item.field
          ? {
              fieldType: item.field.field,
              fieldParams: item.field.params
            }
          : {})
      };
    });

    this.viewContextElementTeamProperty.setOutputs(outputs);
  }

  ngOnDestroy(): void {
    this.customizeService.layoutCustomization = undefined;
    this.customizeService.unsetHandler(this);

    this.adminComponentService.contentWithSidebar$.next(false);
  }

  onBeforeDestroy(): void {
    this.context.pauseElements();
    this.context.clear();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['uniqueName'] &&
      (!this.currentViewSettings || this.currentViewSettings.uniqueName != this.uniqueName)
    ) {
      this.context.pauseElements();

      this.loadSubscription = this.getViewSettings().subscribe(() => {
        this.cd.detectChanges();
        this.context.resumeElements();
      });
    }

    if (changes['params']) {
      this.updateContextPage();
    }
  }

  ngAfterViewInit(): void {
    let downTarget: HTMLElement;

    this.adminComponentService.contentMousedown.pipe(untilDestroyed(this)).subscribe(e => {
      downTarget = e.target as HTMLElement;
    });

    this.adminComponentService.contentClick.pipe(untilDestroyed(this)).subscribe(e => {
      if (!this.sidebarElement) {
        return;
      }

      if (
        !isElementClickEvent(e) &&
        !isSidebarClickEvent(e) &&
        !isElementHasChild(this.sidebarElement.nativeElement, downTarget, true)
      ) {
        this.customizeBarContext.resetSettingsComponent();
      }
    });

    this.adminComponentService.contentWithSidebar$.next(true);
  }

  createCustomPageQueryController(query: ModelData): CustomPageQueryController {
    const contextElement = this.viewContextElementFactory.create(this.injector);

    return Injector.create({
      providers: [
        { provide: queryControllerQueryToken, useValue: query },
        { provide: queryControllerContextElementToken, useValue: contextElement },
        { provide: queryControllerParentElementToken, useValue: this.viewContextElementQueries },
        {
          provide: CustomPageQueryController,
          deps: [
            queryControllerQueryToken,
            queryControllerContextElementToken,
            queryControllerParentElementToken,
            ViewContext,
            CurrentProjectStore,
            CurrentEnvironmentStore,
            ModelDescriptionStore,
            ActionService,
            ModelDescriptionDataSourceService,
            DataSourceGeneratorService
          ]
        }
      ],
      parent: this.injector
    }).get<CustomPageQueryController>(CustomPageQueryController);
  }

  createPageAction(action: ActionItem): ViewContextElement {
    return this.viewContextElementFactory.create(this.injector);
  }

  setInitialViewSettings(viewSettings: CustomViewSettings, resetTrack = true) {
    this.initialViewSettings = viewSettings;

    if (viewSettings) {
      this.setCurrentViewSettings(cloneDeep(viewSettings));
    } else {
      this.setCurrentViewSettings(undefined);
    }

    if (resetTrack) {
      this.customizeService.startTrackChanges();
    }
  }

  setCurrentViewSettings(viewSettings: CustomViewSettings) {
    const prevPageUid = this.currentViewSettings ? this.currentViewSettings.uid : undefined;
    const newPageUid = viewSettings ? viewSettings.uid : undefined;
    const pageChanged = newPageUid != prevPageUid;

    if (pageChanged) {
      this.pageSubscriptions.forEach(item => item.unsubscribe());
      this.pageSubscriptions = [];
    }

    this.currentViewSettings = viewSettings;
    this.context.viewSettings = viewSettings;

    if (pageChanged && viewSettings) {
      this.context.pageStorage = Injector.create({
        providers: [
          {
            provide: ProjectPropertyStorage,
            useFactory: (projectPropertyStore: ProjectPropertyStore) => {
              return new ProjectPropertyStorage(
                this.currentProjectStore.instance.uniqueName,
                this.currentEnvironmentStore.instance.uniqueName,
                projectPropertyStore,
                ProjectPropertyType.Page,
                {
                  pageUid: viewSettings.uid
                }
              );
            },
            deps: [ProjectPropertyStore]
          }
        ],
        parent: this.injector
      }).get<ProjectPropertyStorage>(ProjectPropertyStorage);

      this.pageSubscriptions.push(
        this.projectPropertyStore
          .getPage(this.currentViewSettings.uid)
          .pipe(untilDestroyed(this))
          .subscribe(properties => {
            const outputs: ViewContextOutput[] = properties
              .map(item => {
                return {
                  uniqueName: item.uid,
                  name: item.name,
                  icon: item.fieldDescription.icon,
                  ...(item.field
                    ? {
                        fieldType: item.field.field,
                        fieldParams: item.field.params
                      }
                    : {})
                };
              })
              .sort((lhs, rhs) => ascComparator(lhs.name, rhs.name));
            this.viewContextElementPageProperty.setOutputs(outputs);
          })
      );

      this.pageSubscriptions.push(
        this.context.pageStorage
          .getValues$()
          .pipe(untilDestroyed(this))
          .subscribe(value => {
            this.viewContextElementPageProperty.setOutputValues({ ...value });
          })
      );

      this.pageSubscriptions.push(
        this.zone.onStable.pipe(take(1), untilDestroyed(this)).subscribe(() => {
          viewSettings.openActions.forEach(action => this.executeAction(action));
        })
      );
    } else if (!viewSettings) {
      this.context.pageStorage = undefined;
    }

    this.updateQueries(viewSettings);
    this.updateActions(viewSettings);
    this.cd.markForCheck();
  }

  updateQueries(viewSettings: CustomViewSettings) {
    const queries = viewSettings ? viewSettings.queries : [];
    const queriesUids = queries.map(item => item.uid);
    const removeQueries = this.queryControllers.filter(item => !queriesUids.includes(item.query.uid));

    this.queryControllers = queries.map(query => {
      let controller = this.queryControllers.find(item => item.query.uid == query.uid);

      if (!controller) {
        controller = this.createCustomPageQueryController(query);
        controller.ngOnInit();
      } else {
        controller.setQuery(query);
      }

      return controller;
    });

    removeQueries.forEach(item => item.ngOnDestroy());
  }

  updateActions(viewSettings: CustomViewSettings) {
    const actions = viewSettings ? viewSettings.openActions : [];
    const actionUids = actions.map(item => item.uid);
    const removeActions = this.pageActions.filter(item => !actionUids.includes(item.uniqueName));

    this.pageActions = actions.map((action, i) => {
      let pageAction = this.pageActions.find(item => item.uniqueName == action.uid);

      if (!pageAction) {
        pageAction = this.createPageAction(action);
        pageAction.initGlobal(
          {
            uniqueName: action.uid,
            name: `Action ${i + 1}`,
            icon: 'power',
            insert: true
          },
          this.viewContextElementActions
        );
      } else {
        pageAction.initInfo({ name: `Action ${i + 1}` });
      }

      pageAction.setActions([
        {
          uniqueName: 'execute',
          name: 'Run action',
          icon: 'repeat',
          parameters: [],
          handler: () => this.executeAction(action)
        }
      ]);

      return pageAction;
    });

    removeActions.forEach(item => item.unregister());
  }

  executeAction(action: ActionItem) {
    this.actionControllerService
      .execute(action, {
        context: this.context,
        confirmRouting: !!this.customizeService.enabled,
        injector: this.injector
      })
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  addSavedElementItem() {
    if (!this.currentViewSettings) {
      return;
    }

    const createdElement = this.elementContainerService.addSavedElement(
      this.currentViewSettings,
      undefined,
      BUILDER_ADD_ELEMENT,
      this.context
    );

    if (createdElement.element) {
      this.customizeService.registerCreatedElement(createdElement.element);
      this.customizeService.markChanged();
    } else if (createdElement.popup) {
      this.customizeService.markChanged();

      setTimeout(() => {
        this.openPopup(createdElement.popup.uid, { openComponents: true });
      });
    }
  }

  getViewSettings(resetTrack = true) {
    if (this.loadSubscription) {
      this.loadSubscription.unsubscribe();
      this.loadSubscription = undefined;
    }

    this.initialViewSettings = undefined;
    this.setCurrentViewSettings(this.currentViewSettings);
    this.context.clear(ViewContextElementType.Element);
    this.cd.detectChanges();

    this.customizeBarContext.resetSettingsComponent();

    if (this.customizeBarComponent) {
      this.customizeBarComponent.setSelectedTab(CustomizeBarTab.Components);
    }

    return (isSet(this.uniqueName)
      ? this.viewSettingsStore.getDetailFirst<CustomViewSettings>(this.uniqueName, ViewSettingsType.Custom)
      : this.viewSettingsStore.getDistinctName('New Page').pipe(
          map(name => {
            const page = new CustomViewSettings();
            page.name = name;
            return page;
          })
        )
    ).pipe(
      delayWhen(() => this.viewContextInitialized),
      tap(viewSettings => {
        this.setInitialViewSettings(viewSettings, resetTrack);

        this.context.objectType = 'custom_page';
        this.context.objectId = this.uniqueName;
        this.cd.markForCheck();
        this.metaService.set({ title: viewSettings ? viewSettings.name : 'Page not found' });
        this.updateContextPage();
        this.updateCustomizeHandleInfo();
        this.addSavedElementItem();

        this.customizeService.layoutCustomization = {
          subtitle: '',
          title: viewSettings ? 'Edit Page \n' + capitalize(viewSettings.name) : 'Edit Page',
          image: 'list',
          clickEvent: undefined,
          description: []
        };
      }),
      untilDestroyed(this)
    );
  }

  getCollaborationParams(): Object {
    return {
      object_type: 'custom_page',
      object_id: this.uniqueName
    };
  }

  getUserActivitiesParams(): Object {
    return {
      view_settings: this.currentViewSettings ? this.currentViewSettings.uid : undefined
    };
  }

  updateContextPage() {
    this.viewContextElementParameters.setOutputValues(this.params);

    this.viewContextElementParameters.setOutputs(
      this.currentViewSettings
        ? this.currentViewSettings.parameters.map(item => {
            const fieldDescription = getFieldDescriptionByType(item.field);
            return {
              uniqueName: item.name,
              name: item.verboseName || item.name,
              icon: fieldDescription.icon
            };
          })
        : []
    );
  }

  getQueryModelDescription(resource: string, query: ModelDescriptionQuery) {
    if (!query || query.queryType != QueryType.Simple || !query.simpleQuery) {
      return of(undefined);
    }

    const modelId = [resource, query.simpleQuery.model].join('.');

    return this.modelDescriptionStore.getDetailFirst(modelId);
  }

  updateViewSettingsParameters(result: CustomViewSettings) {
    this.currentViewSettings.parameters = result.parameters;
    this.customizeService.markChanged();
    this.cd.markForCheck();
    this.updateContextPage();

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Page.SuccessfullySetUp, {
      PageID: this.currentViewSettings.uniqueName
    });
  }

  updateViewSettingsQueries(queries: ModelData[]) {
    this.currentViewSettings.queries = queries;
    this.updateQueries(this.currentViewSettings);
    this.customizeService.markChanged();
    this.cd.markForCheck();

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Page.SuccessfullySetUp, {
      PageID: this.currentViewSettings.uniqueName
    });
  }

  updateViewSettingsActions(actions: ActionItem[]) {
    this.currentViewSettings.openActions = actions;
    this.updateActions(this.currentViewSettings);
    this.customizeService.markChanged();
    this.cd.markForCheck();

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Page.SuccessfullySetUp, {
      PageID: this.currentViewSettings.uniqueName
    });
  }

  updateCustomizeHandleInfo() {
    this.customizeService.setHandlerInfo(this, {
      breadcrumbs: [],
      page: this.currentViewSettings
    });
  }

  getChangesState(): CustomPageState {
    return {
      viewSettings: cloneDeep(this.currentViewSettings)
    };
  }

  setChangesState(state: CustomPageState) {
    this.setCurrentViewSettings(cloneDeep(state.viewSettings));
  }

  isChangesStateEqual(lhs: CustomPageState, rhs: CustomPageState): boolean {
    const compare = ['params'];
    return isEqual(lhs.viewSettings.serialize(compare), rhs.viewSettings.serialize(compare));
  }

  saveChangesState(state: CustomPageState): Observable<CustomPageState> {
    const create = !this.initialViewSettings.uid;
    const elementItems = filterElementItems<ElementItem>(
      this.currentViewSettings.elements,
      item => item instanceof ElementItem
    );

    return this.elementConfigurationService.getElementItemsMeta(elementItems).pipe(
      tap(meta => {
        state.viewSettings.configuredElements = meta.configured;
        state.viewSettings.configuredModelElements = meta.configuredModel;
        state.viewSettings.configuredActionElements = meta.configuredAction;
      }),
      switchMap(() => {
        if (!create) {
          return this.viewSettingsService
            .update(
              this.currentProjectStore.instance.uniqueName,
              this.currentEnvironmentStore.instance.uniqueName,
              state.viewSettings
            )
            .pipe(
              tap(result => {
                this.viewSettingsStore.updateItem(result);

                this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.BuilderChange, {
                  Type: 'page',
                  PageUniqueName: result.uniqueName
                });
              })
            );
        } else {
          return this.viewSettingsStore.getDistinctName(state.viewSettings.name || 'New Page').pipe(
            switchMap(name =>
              this.viewSettingsStore.getDistinctUniqueNameForName(name).pipe(map(uniqueName => [uniqueName, name]))
            ),
            switchMap(([uniqueName, name]) => {
              state.viewSettings.uniqueName = uniqueName;
              state.viewSettings.name = name;

              return this.viewSettingsService.create(
                this.currentProjectStore.instance.uniqueName,
                this.currentEnvironmentStore.instance.uniqueName,
                state.viewSettings
              );
            }),
            tap(result => {
              this.viewSettingsStore.addItem(result);

              this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.BuilderChange, {
                Type: 'page',
                PageUniqueName: result.uniqueName
              });

              this.analyticsService.sendSimpleEvent(AnalyticsEvent.Page.PageCreated, {
                PageUniqueName: result.uniqueName
              });
            })
          );
        }
      }),
      switchMap(result => {
        return this.viewSettingsStore.getDetailFirst<CustomViewSettings>(result.uniqueName, ViewSettingsType.Custom);
      }),
      // delayWhen(result => {
      //   if (create) {
      //     return this.viewSettingsQueries.addPageToMenu(result);
      //   } else {
      //     return of(undefined);
      //   }
      // }),
      map(result => {
        if (result) {
          const created = !this.initialViewSettings.uid;

          if (created) {
            this.setInitialViewSettings(result, false);
            this.currentViewSettings.newlyCreated = true;
          }

          this.currentViewSettings.uid = result.uid;
          this.currentViewSettings.parameters = result.parameters;
          this.currentViewSettings.queries = result.queries;
          this.currentViewSettings.configuredElements = result.configuredElements;
          this.currentViewSettings.configuredModelElements = result.configuredModelElements;
          this.currentViewSettings.configuredActionElements = result.configuredActionElements;
          this.initialViewSettings = cloneDeep(this.currentViewSettings);

          if (created) {
            this.updateCustomizeHandleInfo();
            this.routing.navigateApp(result.link, {
              queryParams: this.activatedRoute.snapshot.queryParams,
              replaceUrl: true
            });
          }
        }

        // this.analyticsService.sendEvent(AnalyticsEvent.GA.ModelDetailLayout, AnalyticsEventAction.Updated);

        return {
          viewSettings: result
        };
      })
    );
  }

  reload(): Observable<any> {
    this.currentViewSettings = undefined;
    this.cd.detectChanges();

    const obs = new ReplaySubject<any>();

    this.loadSubscription = this.getViewSettings(false)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => obs.next(),
        error => obs.error(error),
        () => obs.complete()
      );

    return obs.asObservable();
  }

  renamePage(name: string) {
    if (!this.currentViewSettings.uid) {
      this.currentViewSettings.name = name;
      this.updateCustomizeHandleInfo();
      return;
    }

    this.renameLoading = true;
    this.cd.markForCheck();

    this.pageSubscriptions.push(
      this.viewSettingsStore
        .getDistinctUniqueNameForName(name, this.currentViewSettings)
        .pipe(
          switchMap(uniqueName => {
            const viewSettings = cloneDeep(this.currentViewSettings);
            viewSettings.name = name;
            viewSettings.uniqueName = uniqueName;
            return this.viewSettingsService.update(
              this.currentProjectStore.instance.uniqueName,
              this.currentEnvironmentStore.instance.uniqueName,
              viewSettings,
              ['uid', 'unique_name', 'name', 'deleted']
            );
          }),
          delayWhen(() => this.viewSettingsStore.getFirst(true)),
          delayWhen(() => this.menuSettingsStore.getFirst(true)),
          untilDestroyed(this)
        )
        .subscribe(
          result => {
            this.currentViewSettings.name = result.name;
            this.currentViewSettings.uniqueName = result.uniqueName;
            this.updateCustomizeHandleInfo();
            this.renameLoading = false;
            this.cd.markForCheck();

            if (result) {
              this.routing.navigateApp(result.link, {
                queryParams: this.activatedRoute.snapshot.queryParams,
                replaceUrl: true
              });
            }
          },
          () => {
            this.renameLoading = false;
            this.cd.markForCheck();
          }
        )
    );
  }

  duplicatePage() {
    const viewSettings = cloneDeep(this.currentViewSettings);
    const copyName = `Copy of ${viewSettings.name || 'New Page'}`;

    this.duplicateLoading = true;
    this.cd.markForCheck();

    this.pageSubscriptions.push(
      this.viewSettingsStore
        .getDistinctName(copyName)
        .pipe(
          switchMap(name =>
            this.viewSettingsStore.getDistinctUniqueNameForName(name).pipe(map(uniqueName => [uniqueName, name]))
          ),
          switchMap(([uniqueName, name]) => {
            viewSettings.uid = undefined;
            viewSettings.uniqueName = uniqueName;
            viewSettings.name = name;

            return this.viewSettingsService.create(
              this.currentProjectStore.instance.uniqueName,
              this.currentEnvironmentStore.instance.uniqueName,
              viewSettings
            );
          }),
          delayWhen(() => this.viewSettingsStore.getFirst(true)),
          untilDestroyed(this)
        )
        .subscribe(
          result => {
            this.duplicateLoading = false;
            this.cd.markForCheck();

            this.routing.navigateApp(result.link);
          },
          () => {
            this.duplicateLoading = false;
            this.cd.markForCheck();
          }
        )
    );
  }

  deletePage() {
    this.pageSubscriptions.push(
      this.dialogService
        .warning({
          title: 'Deleting',
          description: `Are you sure want to delete page ${this.currentViewSettings.name}?`,
          style: 'orange'
        })
        .pipe(
          filter(result => result == true),
          switchMap(() => {
            this.deleteLoading = true;
            this.cd.markForCheck();

            return this.viewSettingsService.delete(
              this.currentProjectStore.instance.uniqueName,
              this.currentEnvironmentStore.instance.uniqueName,
              this.currentViewSettings
            );
          }),
          delayWhen(() => this.viewSettingsStore.getFirst(true)),
          delayWhen(() => this.menuSettingsStore.getFirst(true)),
          untilDestroyed(this)
        )
        .subscribe(
          () => {
            this.deleteLoading = false;
            this.cd.markForCheck();

            const viewSettings = this.currentViewSettings;

            this.setCurrentViewSettings(undefined);
            this.routing.navigateApp(this.currentProjectStore.instance.homeLink);

            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Page.Deleted, {
              PageID: viewSettings.uniqueName
            });
          },
          () => {
            this.deleteLoading = false;
            this.cd.markForCheck();
          }
        )
    );
  }

  getPage(): ViewSettings {
    return this.currentViewSettings;
  }

  onSidebarClick(e: MouseEvent) {
    markSidebarClickEvent(e);
  }

  openPopup(uid: string, options: CustomizeOpenPopupOptions = {}) {
    this.popupsComponent.openPopup(uid, options);
  }

  closePopup(uid?: string) {
    this.popupsComponent.closePopup(uid);
  }

  createPopup(open?: boolean, options: CustomizeCreatePopupOptions = {}): PopupSettings {
    return this.popupsComponent.createPopup(open, options);
  }

  updatePopup(uid: string, newPopup: PopupSettings) {
    this.popupsComponent.updatePopup(uid, newPopup);
  }

  getOpenedPopup(): PopupSettings {
    return this.popupsComponent.openedPopup;
  }

  openPageSettings(value: PageSettings) {
    this.currentPageSettings = value;
    this.cd.markForCheck();
  }

  closeCurrentSettings() {
    this.currentPageSettings = undefined;
    this.cd.markForCheck();
  }

  closePageSettings(value: PageSettings) {
    if (this.currentPageSettings == value) {
      this.closeCurrentSettings();
    }
  }
}
