import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { first, switchMap } from 'rxjs/operators';

import { ViewContext, ViewSettings, ViewSettingsStore } from '@modules/customize';
import { createFormFieldFactory } from '@modules/fields';
import {
  getMenuItemSystemActionTypeInfo,
  MenuItemActionService,
  MenuItemActionType,
  MenuItemSystemActionType,
  menuItemSystemActionTypes
} from '@modules/menu';
import { ascComparator, isSet } from '@shared';

import { MenuItemActionControl } from '../customize-bar-pages-edit/menu-item-action.control';

export interface SystemOption {
  type: MenuItemSystemActionType;
  label: string;
  actionLabel: string;
  icon: string;
}

@Component({
  selector: 'app-edit-menu-item-action',
  templateUrl: './edit-menu-item-action.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditMenuItemActionComponent implements OnInit, OnDestroy {
  @Input() label: string;
  @Input() control: MenuItemActionControl;
  @Input() context: ViewContext;
  @Input() analyticsSource: string;
  @Output() selectPage = new EventEmitter<{ page: ViewSettings; finished: boolean }>();
  @Output() selectSystem = new EventEmitter<SystemOption>();

  createField = createFormFieldFactory();
  actionValueDisplay: { label: string; icon: string };
  systemOptions: SystemOption[] = [];
  pages: ViewSettings[] = [];
  selectedPage: ViewSettings;
  actionTypes = MenuItemActionType;

  constructor(
    private menuItemActionService: MenuItemActionService,
    private viewSettingsStore: ViewSettingsStore,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.systemOptions = menuItemSystemActionTypes
      .map(systemType => {
        const info = getMenuItemSystemActionTypeInfo(systemType);

        if (!info) {
          return;
        }

        return {
          type: systemType,
          label: info.label,
          actionLabel: info.actionLabel,
          icon: info.icon
        };
      })
      .filter(item => item !== undefined);
    this.cd.markForCheck();

    this.viewSettingsStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(viewSettings => {
        this.pages = viewSettings
          .filter(item => item.uniqueName)
          .sort((lhs, rhs) => {
            const lhsName = isSet(lhs.name) ? String(lhs.name).toLowerCase() : '';
            const rhsName = isSet(rhs.name) ? String(rhs.name).toLowerCase() : '';
            return ascComparator(lhsName, rhsName);
          });
        this.cd.markForCheck();
      });

    this.menuItemActionService
      .getActionValueDisplay$(this.control, { excludeUrl: true })
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.actionValueDisplay = value ? { label: value.label, icon: value.icon } : undefined;
        this.cd.markForCheck();
      });

    this.control
      .getPageUid$()
      .pipe(
        switchMap(pageUid => this.viewSettingsStore.getDetailByUid<ViewSettings>(pageUid)),
        untilDestroyed(this)
      )
      .subscribe(viewSettings => {
        this.selectedPage = viewSettings;
        this.cd.markForCheck();
      });

    this.control.updateInputFieldProvider().pipe(untilDestroyed(this)).subscribe();
  }

  ngOnDestroy(): void {
    this.control.inputFieldProvider.clearProvider();
  }

  setPageValue(page: ViewSettings) {
    this.control.setPageValue(page);

    this.control.inputFieldProvider.fields$.pipe(first(), untilDestroyed(this)).subscribe(items => {
      this.selectPage.emit({ page: page, finished: !items.length });
    });
  }
}
