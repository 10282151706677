import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  InjectionToken,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of, Subscription } from 'rxjs';
import { map, skip } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { ActionControllerService } from '@modules/action-queries';
import { ActionItem } from '@modules/actions';
import { DARK_THEME_OUTPUT, ViewContext, ViewContextElement, ViewContextOutput } from '@modules/customize';
import { ActionOutput, FieldType, getFieldDescriptionByType, ParameterField } from '@modules/fields';
import { HOVER_OUTPUT, PRESSED_OUTPUT, SELECTED_OUTPUT } from '@modules/list';
import { ThemeService } from '@modules/theme';
import { BorderPosition, FillType, getAllFontFamilies, Layer, LayerInteractionType, View } from '@modules/views';
import { isSet, TypedChanges } from '@shared';

interface FillItem {
  id: string;
  background?: SafeStyle;
  width?: string;
  height?: string;
  transform?: SafeStyle;
  icon?: {
    icon: string;
    color?: string;
    size: number;
  };
  opacity?: number;
  enabled: boolean;
}

interface BorderItem {
  border: SafeStyle;
  position: number;
  borderRadius: SafeStyle;
  enabled: boolean;
}

export const parametersToken = new InjectionToken<ViewContextElement>('parametersToken');
export const actionsToken = new InjectionToken<ViewContextElement>('actionsToken');
export const stateToken = new InjectionToken<ViewContextElement>('stateToken');
export const layerToken = new InjectionToken<ViewContextElement>('layerToken');

@Component({
  selector: 'app-custom-element-view',
  templateUrl: './custom-element-view.component.html',
  providers: [
    ViewContext,
    { provide: parametersToken, useClass: ViewContextElement },
    { provide: actionsToken, useClass: ViewContextElement },
    { provide: stateToken, useClass: ViewContextElement },
    { provide: layerToken, useClass: ViewContextElement }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomElementViewComponent implements OnInit, OnDestroy, OnChanges {
  @Input() view: View;
  @Input() parameters: ParameterField[] = [];
  @Input() actions: { name: string; action: ActionItem }[] = [];
  @Input() params: Object = {};
  @Input() localContext: Object;
  @Input() stateSelectedEnabled = false;
  @Input() stateSelected = true;

  fills: FillItem[] = [];
  fillsSubscription: Subscription;
  borders: BorderItem[] = [];
  bordersSubscription: Subscription;
  borderRadius: SafeStyle;
  boxShadow: SafeStyle;
  boxShadowSubscription: Subscription;
  displayItems: Layer[] = [];
  displayItemsSubscription: Subscription;
  externalFonts: string[] = [];

  trackLayerFn(i, item: Layer) {
    return item.id;
  }

  trackFillFn(i, item: FillItem) {
    return item.id;
  }

  constructor(
    public viewContext: ViewContext,
    @Inject(parametersToken) private parametersContextElement: ViewContextElement,
    @Inject(actionsToken) private actionsContextElement: ViewContextElement,
    @Inject(stateToken) private stateContextElement: ViewContextElement,
    @Inject(layerToken) public layerTokenContextElement: ViewContextElement,
    private actionControllerService: ActionControllerService,
    private themeService: ThemeService,
    private notificationService: NotificationService,
    private sanitizer: DomSanitizer,
    private injector: Injector,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.updateComponentContextOutputs();
    this.updateComponentContextActions();
    this.updateStateContextOutputs();
    this.updateComponentContextValue();
    this.updateStateContextValue();
    this.updateLayerContext();

    this.themeService.theme$.pipe(skip(1), untilDestroyed(this)).subscribe(() => this.updateStateContextValue());
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<CustomElementViewComponent>): void {
    if (changes.view) {
      this.updateComponentContextActions();
      this.updateFills();
      this.updateBorders();
      this.updateBorderRadius();
      this.updateBoxShadows();
      this.updateDisplayItems();
      this.updateExternalFonts();
      this.updateLayerContext();
    }

    if (changes.parameters && !changes.parameters.firstChange) {
      this.updateComponentContextOutputs();
    }

    if (changes.params && !changes.params.firstChange) {
      this.updateComponentContextValue();
    }

    if (changes.stateSelected && !changes.stateSelected.firstChange) {
      this.updateStateContextValue();
    }
  }

  updateComponentContextOutputs() {
    this.parametersContextElement.initGlobal({
      uniqueName: 'component',
      name: 'Component parameters'
    });

    this.parametersContextElement.setOutputs(
      this.parameters.map(item => {
        const fieldDescription = getFieldDescriptionByType(item.field);
        const icon = fieldDescription ? fieldDescription.icon : undefined;

        return {
          uniqueName: item.name,
          name: item.verboseName,
          icon: icon,
          fieldType: item.field,
          fieldParams: item.params,
          external: true
        };
      })
    );
  }

  updateComponentContextValue() {
    this.parametersContextElement.setOutputValues(this.params);
  }

  updateComponentContextActions() {
    this.actionsContextElement.initGlobal({
      uniqueName: 'component_actions',
      name: 'Component actions'
    });

    this.actionsContextElement.setActions(
      this.view.actions.map(item => {
        return {
          uniqueName: item.name,
          name: item.verboseName,
          parameters: item.parameters,
          handler: params => this.onContextAction(item, params),
          icon: item.icon
        };
      })
    );
  }

  onContextAction(output: ActionOutput, params: Object) {
    const action = this.actions.find(item => item.name == output.name);
    if (!action) {
      return;
    }

    this.actionControllerService
      .execute(action.action, {
        context: this.viewContext,
        localContext: {
          ...this.localContext
        },
        injector: this.injector
      })
      .subscribe();
  }

  updateStateContextOutputs() {
    this.stateContextElement.initGlobal({
      uniqueName: 'state',
      name: 'Component state'
    });

    this.stateContextElement.setOutputs([
      ...(this.stateSelectedEnabled
        ? [
            {
              uniqueName: SELECTED_OUTPUT,
              name: 'Component is selected',
              icon: 'select_all',
              fieldType: FieldType.Boolean,
              external: true
            }
          ]
        : []),
      {
        uniqueName: DARK_THEME_OUTPUT,
        name: 'Dark theme',
        icon: 'toggle_theme',
        fieldType: FieldType.Boolean,
        external: true
      }
    ]);
  }

  updateStateContextValue() {
    const state = {
      ...(this.stateSelectedEnabled && {
        [SELECTED_OUTPUT]: this.stateSelected
      }),
      [DARK_THEME_OUTPUT]: this.themeService.theme == 'dark'
    };

    this.stateContextElement.setOutputValues(state);
  }

  updateLayerContext() {
    const hoverOutput = this.view.interactions.some(item => item.type == LayerInteractionType.HoverOutput);
    const pressedOutput = this.view.interactions.some(item => item.type == LayerInteractionType.PressedOutput);
    const anyOutputs = hoverOutput || pressedOutput;
    const registered = this.layerTokenContextElement.isRegistered();

    if (anyOutputs && !registered) {
      this.layerTokenContextElement.initElement({
        uniqueName: 'view',
        name: 'Canvas',
        icon: 'canvas'
      });
    } else if (anyOutputs && registered) {
      this.layerTokenContextElement.initInfo(
        {
          name: 'Canvas',
          icon: 'canvas'
        },
        true
      );
    } else if (!anyOutputs && registered) {
      this.layerTokenContextElement.unregister();
    }

    if (anyOutputs) {
      const outputs: ViewContextOutput[] = [];

      if (hoverOutput) {
        outputs.push({
          uniqueName: HOVER_OUTPUT,
          name: `Layer is hovered`,
          icon: 'target',
          fieldType: FieldType.Boolean,
          defaultValue: false,
          external: true
        });
      }

      if (pressedOutput) {
        outputs.push({
          uniqueName: PRESSED_OUTPUT,
          name: `Layer is pressed`,
          icon: 'select_all',
          fieldType: FieldType.Boolean,
          defaultValue: false,
          external: true
        });
      }

      if (
        !isEqual(
          this.layerTokenContextElement.outputs.map(item => item.uniqueName),
          outputs.map(item => item.uniqueName)
        )
      ) {
        this.layerTokenContextElement.setOutputs(outputs);
      }
    }
  }

  updateFills() {
    if (this.fillsSubscription) {
      this.fillsSubscription.unsubscribe();
      this.fillsSubscription = undefined;
    }

    const fills$ = [...this.view.fills]
      .reverse()
      .filter(item => item.enabled)
      .map(item => {
        const icon$ =
          item.type == FillType.Icon && item.iconFill
            ? item.iconFill.display$({ context: this.viewContext }).pipe(
                map(value => {
                  return {
                    icon: value.icon,
                    color: value.color,
                    size: isSet(item.iconFill.size)
                      ? item.iconFill.size
                      : Math.min(this.view.frame.width, this.view.frame.height)
                  };
                })
              )
            : of(undefined);
        const css$ = item.css$({ frame: this.view.frame, context: this.viewContext });
        const enabled$ = item.enabledInput ? item.enabled$({ context: this.viewContext }) : of(true);

        return combineLatest(icon$, css$, enabled$).pipe(
          map(([icon, css, enabled]) => {
            return {
              id: item.id,
              background: isSet(css.background) ? this.sanitizer.bypassSecurityTrustStyle(css.background) : undefined,
              width: css.width,
              height: css.height,
              transform: isSet(css.transform) ? this.sanitizer.bypassSecurityTrustStyle(css.transform) : undefined,
              icon: icon,
              opacity: item.type != FillType.Color ? item.opacity : null,
              enabled: enabled
            };
          })
        );
      });

    if (!fills$.length) {
      this.fills = [];
      this.cd.markForCheck();
      return;
    }

    this.fillsSubscription = combineLatest(fills$)
      .pipe(untilDestroyed(this))
      .subscribe(fills => {
        this.fills = fills.filter(item => item.enabled);
        this.cd.markForCheck();
      });
  }

  updateBorders() {
    if (this.bordersSubscription) {
      this.bordersSubscription.unsubscribe();
      this.bordersSubscription = undefined;
    }

    const borders$ = [...this.view.borders]
      .reverse()
      .filter(item => item.enabled)
      .map(item => {
        const border$ = item.cssBorder$({ context: this.viewContext });
        const enabled$ = item.enabledInput ? item.enabled$({ context: this.viewContext }) : of(true);

        return combineLatest(border$, enabled$).pipe(
          map(([border, enabled]) => {
            let position: number;

            if (item.position == BorderPosition.Center) {
              position = -item.thickness * 0.5;
            } else if (item.position == BorderPosition.Outside) {
              position = -item.thickness;
            } else {
              position = 0;
            }

            const borderRadius = this.view.cornerRadius.cssBorderRadius(position * -1);

            return {
              border: isSet(border) ? this.sanitizer.bypassSecurityTrustStyle(border) : undefined,
              position: position,
              borderRadius: this.sanitizer.bypassSecurityTrustStyle(borderRadius),
              enabled: enabled
            };
          })
        );
      });

    if (!borders$.length) {
      this.borders = [];
      this.cd.markForCheck();
      return;
    }

    this.bordersSubscription = combineLatest(borders$)
      .pipe(untilDestroyed(this))
      .subscribe(borders => {
        this.borders = borders.filter(item => item.enabled && isSet(item.border));
        this.cd.markForCheck();
      });
  }

  updateBorderRadius() {
    this.borderRadius = this.sanitizer.bypassSecurityTrustStyle(this.view.cornerRadius.cssBorderRadius());
    this.cd.markForCheck();
  }

  updateBoxShadows() {
    if (this.boxShadowSubscription) {
      this.boxShadowSubscription.unsubscribe();
      this.boxShadowSubscription = undefined;
    }

    const shadows$ = this.view.shadows
      .filter(item => item.enabled)
      .map(item => {
        const boxShadow$ = item.cssBoxShadow$({ context: this.viewContext });
        const enabled$ = item.enabledInput ? item.enabled$({ context: this.viewContext }) : of(true);

        return combineLatest(boxShadow$, enabled$).pipe(
          map(([boxShadow, enabled]) => {
            return {
              boxShadow: boxShadow,
              enabled: enabled
            };
          })
        );
      });

    if (!shadows$.length) {
      this.boxShadow = undefined;
      this.cd.markForCheck();
      return;
    }

    this.boxShadowSubscription = combineLatest(shadows$)
      .pipe(untilDestroyed(this))
      .subscribe(shadows => {
        this.boxShadow = this.sanitizer.bypassSecurityTrustStyle(
          shadows
            .filter(item => item.enabled)
            .map(item => item.boxShadow)
            .join(',')
        );
        this.cd.markForCheck();
      });
  }

  updateDisplayItems() {
    if (this.displayItemsSubscription) {
      this.displayItemsSubscription.unsubscribe();
      this.displayItemsSubscription = undefined;
    }

    const items$ = this.view.layers
      .filter(item => item.visible)
      .map(item => {
        const visible$ = item.visibleInput ? item.visible$({ context: this.viewContext }) : of(true);

        return combineLatest(visible$).pipe(
          map(([visible]) => {
            return {
              item: item,
              visible: visible
            };
          })
        );
      });

    if (!items$.length) {
      this.displayItems = [];
      this.cd.markForCheck();
      return;
    }

    this.displayItemsSubscription = combineLatest(items$)
      .pipe(untilDestroyed(this))
      .subscribe(items => {
        this.displayItems = items.filter(item => item.visible).map(item => item.item);
        this.cd.markForCheck();
      });
  }

  updateExternalFonts() {
    this.externalFonts = getAllFontFamilies(this.view.layers);
    this.cd.markForCheck();
  }
}
