import { AfterViewInit, ChangeDetectorRef, EventEmitter, OnInit } from '@angular/core';
import { isSet } from '@shared';
import { icons } from '../../data/icons';
var IconSelectorComponent = /** @class */ (function () {
    function IconSelectorComponent(cd) {
        this.cd = cd;
        this.emptyEnabled = false;
        this.selected = new EventEmitter();
        this.loading = true;
        this.items = icons;
        this.filteredItems = [];
        this.search = '';
    }
    IconSelectorComponent.prototype.ngOnInit = function () {
        this.updateFilteredItems();
    };
    IconSelectorComponent.prototype.ngAfterViewInit = function () {
        this.loading = false;
        this.cd.markForCheck();
    };
    Object.defineProperty(IconSelectorComponent.prototype, "query", {
        get: function () {
            return this.search.trim().toLowerCase();
        },
        enumerable: true,
        configurable: true
    });
    IconSelectorComponent.prototype.updateFilteredItems = function () {
        var query = this.query;
        if (!isSet(query)) {
            this.filteredItems = this.items;
        }
        else {
            this.filteredItems = this.items.filter(function (item) { return item.label.toLowerCase().includes(query); });
        }
        this.cd.markForCheck();
    };
    IconSelectorComponent.prototype.onSearchChanged = function () {
        this.updateFilteredItems();
    };
    IconSelectorComponent.prototype.save = function (item) {
        this.selected.emit(item.name);
    };
    IconSelectorComponent.prototype.reset = function () {
        this.selected.emit(this.emptyValue);
    };
    return IconSelectorComponent;
}());
export { IconSelectorComponent };
