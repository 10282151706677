import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';

import { ProjectInvite } from '../../data/project-invite';
import { ProjectUser } from '../../data/project-user';

@Injectable({
  providedIn: 'root'
})
export class ProjectUserService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  getProject(projectName: string, params = {}): Observable<ProjectUser[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.projectMethodURL(projectName, 'users/');
        let headers = new HttpHeaders();
        const httpParams = new HttpParams({ fromObject: params });

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers, params: httpParams });
      }),
      map(result => result.map(item => new ProjectUser().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  get(projectName: string, environmentName: string, params = {}): Observable<ProjectUser[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'users/');
        let headers = new HttpHeaders();
        const httpParams = new HttpParams({ fromObject: params });

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers, params: httpParams });
      }),
      map(result => result.map(item => new ProjectUser().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getPaginate(projectName: string, environmentName: string, params = {}): Observable<ProjectUserService.GetResponse> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'users/');
        let headers = new HttpHeaders();
        const httpParams = new HttpParams({
          fromObject: {
            _paginate: '1',
            ...params
          }
        });

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get(url, { headers: headers, params: httpParams });
      }),
      map(result => new ProjectUserService.GetResponse().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getDetail(projectName: string, environmentName: string, id: string, params = {}): Observable<ProjectUser> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, `users/${id}/`);
        let headers = new HttpHeaders();
        const httpParams = new HttpParams({ fromObject: params });

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers, params: httpParams });
      }),
      map(result => new ProjectUser().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  create(projectName: string, environmentName: string, user: ProjectUser): Observable<ProjectInvite> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'users/');
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, user.serialize(), { headers: headers });
      }),
      map(result => new ProjectInvite().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  update(projectName: string, environmentName: string, user: ProjectUser): Observable<ProjectUser> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, `users/${user.uid}/`);
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.patch(url, user.serialize(), { headers: headers });
      }),
      map(result => new ProjectUser().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  delete(projectName: string, environmentName: string, user: ProjectUser): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, `users/${user.uid}/`);
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.delete(url, { headers: headers });
      }),
      map(result => true),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  stubTextsMentions(projectName: string, environmentName: string, messages: string[]): string[] {
    const regex = /{{user\.(.*?)}}/g;
    return messages.map(message => {
      return message.replace(regex, `<span class="mention stub-text">user</span>`);
    });
  }

  stubTextMentions(projectName: string, environmentName: string, messages: string): string {
    return this.stubTextsMentions(projectName, environmentName, [messages])[0];
  }

  replaceTextsMentions(projectName: string, environmentName: string, messages: string[]): Observable<string[]> {
    const regex = /{{user\.(.*?)}}/g;
    const userIds = [];

    messages.forEach(message => {
      let match: RegExpExecArray;

      while ((match = regex.exec(message))) {
        const userId = match[1];
        if (!userIds.includes(userId)) {
          userIds.push(userId);
        }
      }
    });

    if (!userIds.length) {
      return of(messages);
    }

    const params = { user_uid_in: userIds.join(',') };

    return this.get(projectName, environmentName, params).pipe(
      map(projectUsers => {
        return messages.map(message => {
          return message.replace(regex, (str, userId) => {
            const projectUser = projectUsers.find(item => item.user && item.user.uid == userId);
            const userStr = projectUser ? projectUser.user.email.split('@')[0] : 'Unknown user';

            return `<span class="mention">@${userStr}</span>`;
          });
        });
      })
    );
  }

  replaceTextMentions(projectName: string, environmentName: string, messages: string): Observable<string> {
    return this.replaceTextsMentions(projectName, environmentName, [messages]).pipe(map(result => result[0]));
  }
}

export namespace ProjectUserService {
  export class GetResponse {
    public results: ProjectUser[];
    public count: number;
    public next: string;
    public previous: string;

    deserialize(data: Object) {
      this.results = data['results'].map(item => new ProjectUser().deserialize(item));
      this.count = data['count'];
      this.next = data['next'];
      this.previous = data['previous'];

      return this;
    }
  }
}
