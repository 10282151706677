import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';

import { UniqueIdToken } from '@common/unique-id';
import { TintStyle } from '@modules/customize';

import { cropFormats } from '../../image-field/image-field.crop';

@Component({
  selector: 'app-upload-template',
  templateUrl: './upload-template.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadTemplateComponent implements OnInit, OnDestroy {
  @Input() upload = false;
  @Input() multiple = false;
  @Input() accept: string;
  @Input() name: string;
  @Input() control: FormControl;
  @Input() autofocus = false;
  @Input() disabled = false;
  @Input() background = false;
  @Input() thin = false;
  @Input() fit: string;
  @Input() cropFormat: cropFormats;
  @Input() cropFormatCustom: string;
  @Input() preview: string;
  @Input() previewInteractive = false;
  @Input() uploadProgress: number;
  @Input() uploadError: string;
  @Input() accentColor: string;
  @Output() clearValue = new EventEmitter<void>();
  @Output() fileSelected = new EventEmitter<File>();
  @Output() filesSelected = new EventEmitter<File[]>();

  idToken = new UniqueIdToken();
  urlFocus$ = new BehaviorSubject<boolean>(false);
  draggingOver = false;
  tintStyles = TintStyle;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.control.statusChanges.pipe(untilDestroyed(this)).subscribe(() => this.cd.markForCheck());
  }

  onFileChange(el: HTMLInputElement) {
    if (!el.files.length) {
      return;
    }

    const file = el.files[0];
    const files = Array.from(el.files);

    el.value = null;

    this.fileSelected.emit(file);
    this.filesSelected.emit(files);
  }

  onDragOver(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();

    this.draggingOver = true;
    this.cd.markForCheck();
  }

  onDragLeave(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();

    this.draggingOver = false;
    this.cd.markForCheck();
  }

  onDrop(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();

    if (!e.dataTransfer.files.length) {
      return;
    }

    const file = e.dataTransfer.files[0];
    const files = Array.from(e.dataTransfer.files);

    this.draggingOver = false;
    this.cd.markForCheck();

    if (file) {
      this.fileSelected.emit(file);
    }

    this.filesSelected.emit(files);
  }

  ngOnDestroy(): void {}
}
