var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FocusMonitor } from '@angular/cdk/a11y';
import { ChangeDetectorRef, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import camelCase from 'lodash/camelCase';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime, take } from 'rxjs/operators';
import { ResizeType } from '@common/resizable';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { createFormFieldFactory, FieldType, getJavaScriptSyntaxError, InputValueType } from '@modules/fields';
import { contextToFormulaValue, singleTokenFormulaToContextValue, transformFormulaElementAccessors } from '@modules/parameters';
import { controlValue, isSet } from '@shared';
// Refactor imports
import { CodeFieldComponent } from '@modules/field-components/components/code-field/code-field.component';
import { AutoFieldComponent } from '@modules/fields/components/auto-field/auto-field.component';
import { InputOverlayDirective } from '../../directives/input-overlay/input-overlay.directive';
import { getFormulaStateAnalyticsParams } from '../../utils/analytics';
import { InputEditFormulaValueComponent } from '../input-edit-formula-value/input-edit-formula-value.component';
import { ViewContextTokenPopoverOverlayComponent } from '../view-context-token-popover-overlay/view-context-token-popover-overlay.component';
import { InputTokenType } from './input-token';
var InputEditComponent = /** @class */ (function () {
    function InputEditComponent(focusMonitor, analyticsService, zone, cd) {
        this.focusMonitor = focusMonitor;
        this.analyticsService = analyticsService;
        this.zone = zone;
        this.cd = cd;
        this.staticValueDisabled = false;
        this.filterFields = [];
        this.userInput = false;
        this.focusedInitial = false;
        this.placeholder = 'Text';
        this.formulaPlaceholder = 'Formula';
        this.jsPlaceholder = 'return 2 * 3;';
        this.resetEnabled = true;
        this.displayValueTypesEnabled = true;
        this.displayValueTypes = [InputValueType.Formula];
        this.overlayEnabled = true;
        this.builtInItemsEnabled = true;
        this.classes = [];
        this.fill = false;
        this.fillVertical = false;
        this.small = false;
        this.dark = false;
        this.darker = false;
        this.createField = createFormFieldFactory();
        this.staticValueDisabledClean = false;
        this.extraSections = [];
        this.inputValueTypes = InputValueType;
        this.fieldTypes = FieldType;
        this.resizeTypes = ResizeType;
        this.startedFormulaEditing = false;
        this.booleanValueEquals = function (lhs, rhs) { return lhs === rhs; };
    }
    InputEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.control = new FormControl(undefined, { updateOn: this.itemForm ? this.itemForm.updateOn : undefined });
        // TODO: Temporary solution
        this.form = new FormGroup({ control: this.control });
        this.control.valueChanges.pipe(untilDestroyed(this)).subscribe(function (value) {
            if (_this.itemForm.value['value_type'] == InputValueType.StaticValue && value === '') {
                if (isSet(_this.itemForm.controls['value_type'].value)) {
                    _this.itemForm.controls['value_type'].patchValue('');
                    _this.itemForm.controls['static_value'].patchValue('');
                }
            }
            else if ((!_this.itemForm.value['value_type'] || _this.itemForm.value['value_type'] == InputValueType.StaticValue) &&
                !_this.staticValueDisabledClean) {
                if (_this.itemForm.controls['value_type'].value != InputValueType.StaticValue) {
                    _this.itemForm.controls['value_type'].patchValue(InputValueType.StaticValue);
                }
                _this.itemForm.controls['static_value'].patchValue(value);
            }
            else if (_this.itemForm.value['value_type'] == InputValueType.Formula || _this.staticValueDisabledClean) {
                if (_this.itemForm.controls['value_type'].value != InputValueType.Formula) {
                    _this.itemForm.controls['value_type'].patchValue(InputValueType.Formula);
                }
                var toExternalValue = transformFormulaElementAccessors(value, _this.context, false);
                _this.itemForm.controls['formula_value'].patchValue(toExternalValue);
            }
            _this.itemForm.markAsDirty();
        });
        controlValue(this.itemForm)
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            if (value['value_type'] == InputValueType.Filter) {
                _this.updateFilterField();
            }
        });
        this.itemForm.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(function (value) {
            _this.updateInputErrors();
            _this.onInteracting();
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Formula.Updated, {
                ValueType: _this.itemForm.controls['value_type'].value,
                Source: _this.analyticsSource
            });
        });
        this.setInitialValue();
        this.updateExtraSections();
    };
    InputEditComponent.prototype.ngOnDestroy = function () {
        if (this.startedFormulaEditing) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Formula.FinishedToSetUp, __assign({}, getFormulaStateAnalyticsParams(this.itemForm, this.context, this.initialValue), { Source: this.analyticsSource }));
        }
    };
    InputEditComponent.prototype.ngOnChanges = function (changes) {
        if (changes['staticValueParams'] || changes['fill'] || changes['classes']) {
            this.staticValueParamsDisplay = this.getStaticValueParams();
        }
        if (changes['staticValueField'] || changes['staticValueDisabled']) {
            if ([FieldType.JSON, FieldType.Location].includes(this.staticValueField)) {
                this.staticValueDisabledClean = true;
            }
            else {
                this.staticValueDisabledClean = this.staticValueDisabled;
            }
        }
        if ([changes.builtInItemsEnabled, changes.userInput, changes.contextElement, changes.filterFields].some(function (item) { return item && !item.firstChange; })) {
            this.updateExtraSections();
        }
    };
    InputEditComponent.prototype.getStaticValueParams = function () {
        return __assign({}, this.staticValueParams, { classes: (this.fill ? ['select_fill', 'input_fill'] : []).concat(this.classes), output_format: undefined, background: true });
    };
    InputEditComponent.prototype.clearInputErrors = function () {
        this.jsError = undefined;
        this.cd.markForCheck();
    };
    InputEditComponent.prototype.updateInputErrors = function () {
        var value = this.itemForm.value;
        this.clearInputErrors();
        if (value['value_type'] == InputValueType.Js) {
            if (isSet(value['js_value'])) {
                this.jsError = getJavaScriptSyntaxError(value['js_value']);
                this.cd.markForCheck();
            }
        }
    };
    InputEditComponent.prototype.updateFilterField = function () {
        var _this = this;
        this.filterField = this.filterFields.find(function (item) { return item.name == _this.itemForm.value['filter_field']; });
        this.filterFieldLookup = this.filterField
            ? this.filterField.lookups.find(function (item) { return item.name === _this.itemForm.value['filter_lookup']; })
            : undefined;
        this.cd.markForCheck();
    };
    InputEditComponent.prototype.getFilterTokenItems = function () {
        if (!this.contextElement || !this.contextElement.fieldFilters) {
            return;
        }
        return {
            name: 'Current Component Filters',
            data: {
                type: InputTokenType.Filter
            },
            items: this.filterFields.map(function (field) {
                return {
                    name: field.label,
                    items: field.lookups.map(function (lookup) {
                        return {
                            name: lookup.label,
                            data: {
                                field: field.name,
                                lookup: lookup.name
                            }
                        };
                    })
                };
            })
        };
    };
    InputEditComponent.prototype.setInitialValue = function () {
        var formValue = this.itemForm.value;
        if (!formValue['value_type'] || formValue['value_type'] == InputValueType.StaticValue) {
            this.control.patchValue(formValue['static_value'], { emitEvent: false });
        }
        else if (formValue['value_type'] == InputValueType.Formula) {
            var fromExternalValue = transformFormulaElementAccessors(formValue['formula_value'], this.context, true);
            this.control.patchValue(fromExternalValue, { emitEvent: false });
        }
        else if (formValue['value_type'] == InputValueType.Filter) {
            this.updateFilterField();
        }
        this.initialValue = formValue;
        this.itemForm.markAsPristine();
        this.updateInputErrors();
    };
    InputEditComponent.prototype.updateExtraSections = function () {
        var extraSections = [];
        if (this.builtInItemsEnabled) {
            var builtInItems = [
                {
                    path: ['built_in', InputTokenType.EmptyString],
                    item: {
                        token: [InputTokenType.EmptyString],
                        label: 'Empty String',
                        icon: 'delete',
                        tip: 'Send empty string'
                    }
                },
                {
                    path: ['built_in', InputTokenType.Null],
                    item: {
                        token: [InputTokenType.Null],
                        label: 'Null',
                        icon: 'deselect',
                        tip: 'Send null value'
                    }
                }
            ];
            if (this.userInput) {
                builtInItems.unshift({
                    path: ['built_in', InputTokenType.Prompt],
                    item: {
                        token: [InputTokenType.Prompt],
                        label: 'Ask user',
                        icon: 'edit',
                        tip: 'Ask user to enter value in popup window'
                    }
                });
            }
            builtInItems.unshift({
                path: ['built_in', InputTokenType.Js],
                item: {
                    token: [InputTokenType.Js],
                    label: builtInItems.length >= 3 ? 'JS' : 'JavaScript',
                    icon: 'console',
                    tip: 'Use JavaScript',
                    iconOrange: true,
                    orange: true
                }
            });
            extraSections.push({
                name: 'built_in',
                pinned: true,
                horizontal: true,
                items: builtInItems
            });
        }
        var filterItems = this.getFilterTokenItems();
        if (filterItems) {
            extraSections.push({
                label: filterItems.name,
                icon: 'filter',
                items: filterItems.items.reduce(function (acc, fieldItem) {
                    fieldItem.items.forEach(function (lookupItem) {
                        acc.push({
                            path: ['filter', fieldItem.name, lookupItem.name],
                            item: {
                                token: [InputTokenType.Filter],
                                label: [fieldItem.name, lookupItem.name].join(' '),
                                data: lookupItem.data
                            }
                        });
                    }, []);
                    return acc;
                }, [])
            });
        }
        this.extraSections = extraSections;
        this.cd.markForCheck();
    };
    InputEditComponent.prototype.onEditorOpened = function () {
        this.onInteracting();
    };
    InputEditComponent.prototype.onInteracting = function () {
        if (!this.startedFormulaEditing) {
            this.startedFormulaEditing = true;
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Formula.StartedToSetUp, {
                ValueType: this.itemForm.controls['value_type'].value,
                Source: this.analyticsSource
            });
        }
    };
    InputEditComponent.prototype.switchToFormula = function (empty) {
        if (empty === void 0) { empty = false; }
        var currentValueType = this.itemForm.controls['value_type'].value;
        var contextValue = this.itemForm.controls['context_value'].value;
        var staticValue = this.itemForm.controls['static_value'].value;
        this.itemForm.controls['value_type'].patchValue(InputValueType.Formula);
        if (empty) {
            this.control.patchValue('');
        }
        else if (currentValueType == InputValueType.Context && isSet(contextValue)) {
            var formulaValue = contextToFormulaValue(contextValue);
            formulaValue = transformFormulaElementAccessors(formulaValue, this.context, true);
            this.control.patchValue(formulaValue);
        }
        else if (currentValueType == InputValueType.StaticValue && isSet(staticValue)) {
            this.control.patchValue(JSON.stringify(staticValue));
        }
        this.focusValueOnStable();
    };
    InputEditComponent.prototype.focusValue = function () {
        if (this.formulaValueComponent) {
            this.formulaValueComponent.focus();
        }
        if (this.jsField) {
            this.jsField.focus();
        }
    };
    InputEditComponent.prototype.focusValueOnStable = function () {
        var _this = this;
        this.zone.onStable
            .pipe(take(1))
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.focusValue(); });
    };
    InputEditComponent.prototype.switchToJs = function () {
        this.itemForm.controls['value_type'].patchValue(InputValueType.Js);
        this.itemForm.markAsDirty();
    };
    InputEditComponent.prototype.switchToEmptyString = function () {
        this.itemForm.controls['value_type'].patchValue(InputValueType.EmptyString);
        this.itemForm.markAsDirty();
    };
    InputEditComponent.prototype.switchToNull = function () {
        this.itemForm.controls['value_type'].patchValue(InputValueType.Null);
        this.itemForm.markAsDirty();
    };
    InputEditComponent.prototype.switchToPrompt = function () {
        this.itemForm.controls['value_type'].patchValue(InputValueType.Prompt);
        this.itemForm.markAsDirty();
    };
    InputEditComponent.prototype.switchToContext = function (token) {
        var contextValue = singleTokenFormulaToContextValue(token);
        this.itemForm.controls['value_type'].patchValue(InputValueType.Context);
        this.itemForm.controls['context_value'].patchValue(contextValue);
        this.itemForm.markAsDirty();
    };
    InputEditComponent.prototype.switchToFieldFilter = function (field, lookup) {
        this.itemForm.controls['value_type'].patchValue(InputValueType.Filter);
        this.itemForm.controls['filter_field'].patchValue(field);
        this.itemForm.controls['filter_lookup'].patchValue(lookup);
        this.itemForm.markAsDirty();
    };
    InputEditComponent.prototype.switchToDefault = function () {
        if (this.staticValueDisabledClean) {
            this.itemForm.controls['value_type'].patchValue(InputValueType.Formula);
            this.itemForm.markAsDirty();
            this.control.patchValue('');
            this.focusValueOnStable();
        }
        else {
            this.itemForm.controls['value_type'].patchValue('');
            this.itemForm.markAsDirty();
            this.control.patchValue(this.itemForm.controls['static_value'].value);
        }
    };
    InputEditComponent.prototype.insertJsToken = function (item) {
        if (!isSet(item.insert) && !isSet(item.formula)) {
            return;
        }
        if (this.jsField &&
            this.jsField.dynamicComponent.currentComponent &&
            this.jsField.dynamicComponent.currentComponent.instance instanceof CodeFieldComponent) {
            var ace = this.jsField.dynamicComponent.currentComponent.instance.ace;
            var selectionRange = ace.editor.selection.getRange();
            var insert = void 0;
            if (isSet(item.formula)) {
                insert = item.formula;
            }
            else {
                var formulaValue = contextToFormulaValue(item.insert);
                insert = transformFormulaElementAccessors(formulaValue, this.context, false);
            }
            var insertRow = selectionRange.start.row;
            var insertLast = isSet(item['label']) ? item.label : item.insert[item.insert.length - 1];
            var insertRowValue = ace.editor.session.getLine(insertRow);
            var varName = typeof insertLast == 'number' ? "item" + insertLast : camelCase(String(insertLast));
            var prefixMatch = insertRowValue.match(/^([\s\t]+)/);
            var prefix = prefixMatch ? prefixMatch[1] : '';
            ace.editor.session.insert({ row: insertRow, column: 0 }, prefix + "let " + varName + " = " + insert + ";\n");
        }
    };
    InputEditComponent.prototype.onJsTokenSelected = function (item) {
        if (isSet(item.insert) || isSet(item.formula)) {
            this.insertJsToken(item);
        }
    };
    InputEditComponent.prototype.openTokenPopover = function () {
        if (this.tokenPopoverOverlayComponent) {
            this.tokenPopoverOverlayComponent.open();
        }
    };
    InputEditComponent.prototype.closeTokenPopover = function () {
        if (this.tokenPopoverOverlayComponent) {
            this.tokenPopoverOverlayComponent.close();
        }
    };
    InputEditComponent.prototype.isInputOverlayOpened = function () {
        if (!this.overlayEnabled || !this.inputOverlayDirective) {
            return false;
        }
        return this.inputOverlayDirective.isOpened();
    };
    InputEditComponent.prototype.onInputOverlayFinished = function () {
        this.setInitialValue();
    };
    return InputEditComponent;
}());
export { InputEditComponent };
