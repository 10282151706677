import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '@shared';

import { NotificationComponent } from './components/notification/notification.component';
import { NotificationsComponent } from './components/notifications/notifications.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [NotificationComponent, NotificationsComponent],
  exports: [NotificationsComponent]
})
export class NotificationsModule {
  constructor() {}
}
