import uniq from 'lodash/uniq';
import { Observable } from 'rxjs';

import { AUTO_OPTION_COLORS } from '@modules/colors';
import { FieldType, MultipleSelectOutputFormat, NumberValueFormat, OptionsType, OutputFormat } from '@modules/fields';
import {
  AIRTABLE_CREATED_TIME,
  AIRTABLE_PRIMARY_KEY,
  AirtableFieldType,
  AirtableNumberFieldOptions,
  AirtableRatingFieldOptions,
  AirtableRecordLinkFieldOptions,
  AirtableRecordResponse,
  AirtableResourceController,
  AirtableSelectFieldOptions,
  AirtableTableField,
  AirtableTableResponse
} from '@modules/resources';
import { getCircleIndex, isSet } from '@shared';

import { AirtableParamsOptions } from './airtable-generator.service';

export interface AirtableFieldMappingFieldWithParams {
  field: FieldType;
  params?: Object;
}

export interface AirtableFieldMapping {
  field?: FieldType;
  params?: Object;
  getField?: (options: {
    name: string;
    records: AirtableRecordResponse[];
    options: AirtableParamsOptions;
    table: AirtableTableResponse;
    field: AirtableTableField<any>;
    tables: AirtableTableResponse[];
    controller: AirtableResourceController;
  }) => AirtableFieldMappingFieldWithParams | Observable<AirtableFieldMappingFieldWithParams>;
  getParams?: (options: {
    name: string;
    records: AirtableRecordResponse[];
    options: AirtableParamsOptions;
    table: AirtableTableResponse;
    field: AirtableTableField<any>;
    tables: AirtableTableResponse[];
    controller: AirtableResourceController;
  }) => Object | Observable<Object>;
}

export function parseAirtableColor(color: string): string {
  if (!isSet(color)) {
    return;
  }

  color = color.replace(/(Bright|Light2)$/, '').toLowerCase();
  return AUTO_OPTION_COLORS.find(item => item == color);
}

const airtableCollaboratorsStructure = (max_items?: number) => {
  return {
    type: 'array',
    name: null,
    label: null,
    params: {
      ...(max_items !== undefined && { max_items: max_items }),
      item: {
        type: 'object',
        name: null,
        label: null,
        params: {
          items: [
            {
              type: 'field',
              name: 'id',
              label: 'id',
              params: {
                field: 'text',
                params: {},
                required: false,
                name: 'id',
                label: 'id'
              }
            },
            {
              type: 'field',
              name: 'email',
              label: 'email',
              params: {
                field: 'text',
                params: {},
                required: false,
                name: 'email',
                label: 'email'
              }
            },
            {
              type: 'field',
              name: 'name',
              label: 'name',
              params: {
                field: 'text',
                params: {},
                required: false,
                name: 'name',
                label: 'name'
              }
            }
          ]
        }
      }
    }
  };
};

export const airtableFieldMapping: { type: AirtableFieldType; mapping: AirtableFieldMapping }[] = [
  {
    type: AirtableFieldType.AutoNumber,
    mapping: { field: FieldType.Number }
  },
  {
    type: AirtableFieldType.Barcode,
    mapping: { field: FieldType.JSON }
  },
  {
    type: AirtableFieldType.Checkbox,
    mapping: { field: FieldType.Boolean }
  },
  {
    type: AirtableFieldType.Count,
    mapping: { field: FieldType.Number }
  },
  {
    type: AirtableFieldType.Currency,
    mapping: {
      field: FieldType.Number,
      getParams: (options: { field: AirtableTableField<AirtableNumberFieldOptions> }) => {
        return {
          value_format: {
            number_format: NumberValueFormat.Currency,
            number_fraction: options.field.options.precision > 0 ? options.field.options.precision : 2
          }
        };
      }
    }
  },
  {
    type: AirtableFieldType.Date,
    mapping: { field: FieldType.DateTime, params: { date: true, time: false, output_format: OutputFormat.ISODate } }
  },
  {
    type: AirtableFieldType.DateTime,
    mapping: { field: FieldType.DateTime }
  },
  {
    type: AirtableFieldType.Formula,
    mapping: { field: FieldType.Text }
  },
  {
    type: AirtableFieldType.LastModifiedTime,
    mapping: { field: FieldType.DateTime }
  },
  {
    type: AirtableFieldType.MultilineText,
    mapping: { field: FieldType.Text, params: { multiline: true } }
  },
  {
    type: AirtableFieldType.MultipleAttachments,
    mapping: {
      field: FieldType.File,
      params: { storage_resource: 'demo_rest_api', storage_name: 'temporary', multiple: true }
    }
  },
  {
    type: AirtableFieldType.MultipleCollaborators,
    mapping: {
      field: FieldType.JSON,
      params: {
        store_object: true,
        display_fields: true,
        structure: airtableCollaboratorsStructure()
      }
    }
  },

  {
    type: AirtableFieldType.MultipleLookupValues,
    mapping: { field: FieldType.Text }
  },
  {
    type: AirtableFieldType.MultipleRecordLinks,
    mapping: {
      getField: (options: {
        name: string;
        records: AirtableRecordResponse[];
        options: AirtableParamsOptions;
        field: AirtableTableField<AirtableRecordLinkFieldOptions>;
        tables: AirtableTableResponse[];
      }) => {
        const relatedTable = options.tables.find(item => item.id == options.field.options.linkedTableId);
        const relatedTableId = relatedTable ? [options.options.base, relatedTable.id].join('_') : undefined;

        if (relatedTable && options.field.options.prefersSingleRecordLink) {
          return {
            field: FieldType.RelatedModel,
            params: {
              related_model: {
                model: `{{resource}}.${relatedTableId}`
              }
            }
          };
        } else if (relatedTable && !options.field.options.prefersSingleRecordLink) {
          const displayField = relatedTable.fields.find(item => item.id == relatedTable.primaryFieldId);
          return {
            field: FieldType.MultipleSelect,
            params: {
              output_format: MultipleSelectOutputFormat.Array,
              options_type: OptionsType.Query,
              resource: '{{resource}}',
              query: {
                query_type: 'simple',
                simple_query: {
                  model: relatedTableId
                }
              },
              value_field: AIRTABLE_PRIMARY_KEY,
              label_field: displayField ? displayField.name : undefined,
              columns: [
                {
                  name: AIRTABLE_PRIMARY_KEY,
                  verboseName: 'ID',
                  field: FieldType.Text
                },
                ...relatedTable.fields.map(item => {
                  return {
                    name: item.name,
                    field: FieldType.Text
                  };
                }),
                {
                  name: AIRTABLE_CREATED_TIME,
                  field: FieldType.DateTime
                }
              ]
            }
          };
        } else {
          const values = uniq(
            options.records
              .reduce((acc, item) => {
                if (item.fields[options.name]) {
                  acc.push(...(item.fields[options.name] as unknown[]));
                }
                return acc;
              }, [])
              .filter(item => isSet(item))
          );

          return {
            field: FieldType.MultipleSelect,
            params: {
              output_format: MultipleSelectOutputFormat.Array,
              options_type: OptionsType.Static,
              options: values.map((item, i) => {
                return {
                  value: item,
                  name: item,
                  color: AUTO_OPTION_COLORS[i]
                };
              })
            }
          };
        }
      }
    }
  },
  {
    type: AirtableFieldType.MultipleSelects,
    mapping: {
      field: FieldType.MultipleSelect,
      getParams: (options: { field: AirtableTableField<AirtableSelectFieldOptions> }) => {
        return {
          output_format: MultipleSelectOutputFormat.Array,
          options_type: OptionsType.Static,
          options: options.field.options.choices.map((item, i) => {
            return {
              value: item.name,
              name: item.name,
              color: parseAirtableColor(item.color) || getCircleIndex(AUTO_OPTION_COLORS, i)
            };
          })
        };
      }
    }
  },
  {
    type: AirtableFieldType.Number,
    mapping: {
      field: FieldType.Number,
      getParams: (options: { field: AirtableTableField<AirtableNumberFieldOptions> }) => {
        return {
          value_format: {
            number_fraction: options.field.options.precision > 0 ? options.field.options.precision : 2
          }
        };
      }
    }
  },
  {
    type: AirtableFieldType.Percent,
    mapping: {
      field: FieldType.Number,
      getParams: (options: { field: AirtableTableField<AirtableNumberFieldOptions> }) => {
        return {
          value_format: {
            number_format: NumberValueFormat.Percentage,
            number_fraction: options.field.options.precision > 0 ? options.field.options.precision : 2
          }
        };
      }
    }
  },
  {
    type: AirtableFieldType.Rating,
    mapping: {
      field: FieldType.Rating,
      getParams: (options: { field: AirtableTableField<AirtableRatingFieldOptions> }) => {
        return {
          max_value: options.field.options.max
        };
      }
    }
  },
  {
    type: AirtableFieldType.RichText,
    // mapping: { field: FieldType.RichText, params: { output: RichTextOutput.Markdown } }
    mapping: { field: FieldType.Text, params: { multiline: true } }
  },
  {
    type: AirtableFieldType.Rollup,
    mapping: { field: FieldType.Text }
  },
  {
    type: AirtableFieldType.SingleCollaborator,
    mapping: {
      field: FieldType.JSON,
      params: {
        store_object: true,
        display_fields: true,
        structure: airtableCollaboratorsStructure(1)
      }
    }
  },
  {
    type: AirtableFieldType.SingleLineText,
    mapping: { field: FieldType.Text }
  },
  {
    type: AirtableFieldType.SingleSelect,
    mapping: {
      field: FieldType.Select,
      getParams: (options: { field: AirtableTableField<AirtableSelectFieldOptions> }) => {
        return {
          options_type: OptionsType.Static,
          options: options.field.options.choices.map((item, i) => {
            return {
              value: item.name,
              name: item.name,
              color: parseAirtableColor(item.color) || getCircleIndex(AUTO_OPTION_COLORS, i)
            };
          })
        };
      }
    }
  },
  {
    type: AirtableFieldType.Url,
    mapping: { field: FieldType.URL }
  }
];

export const defaultAirtableFieldMapping: AirtableFieldMapping = {
  field: FieldType.Text
};
