var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, InjectionToken, Injector, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { map, skip } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { ActionControllerService } from '@modules/action-queries';
import { DARK_THEME_OUTPUT, ViewContext, ViewContextElement } from '@modules/customize';
import { FieldType, getFieldDescriptionByType } from '@modules/fields';
import { HOVER_OUTPUT, PRESSED_OUTPUT, SELECTED_OUTPUT } from '@modules/list';
import { ThemeService } from '@modules/theme';
import { BorderPosition, FillType, getAllFontFamilies, LayerInteractionType, View } from '@modules/views';
import { isSet } from '@shared';
export var parametersToken = new InjectionToken('parametersToken');
export var actionsToken = new InjectionToken('actionsToken');
export var stateToken = new InjectionToken('stateToken');
export var layerToken = new InjectionToken('layerToken');
var CustomElementViewComponent = /** @class */ (function () {
    function CustomElementViewComponent(viewContext, parametersContextElement, actionsContextElement, stateContextElement, layerTokenContextElement, actionControllerService, themeService, notificationService, sanitizer, injector, cd) {
        this.viewContext = viewContext;
        this.parametersContextElement = parametersContextElement;
        this.actionsContextElement = actionsContextElement;
        this.stateContextElement = stateContextElement;
        this.layerTokenContextElement = layerTokenContextElement;
        this.actionControllerService = actionControllerService;
        this.themeService = themeService;
        this.notificationService = notificationService;
        this.sanitizer = sanitizer;
        this.injector = injector;
        this.cd = cd;
        this.parameters = [];
        this.actions = [];
        this.params = {};
        this.stateSelectedEnabled = false;
        this.stateSelected = true;
        this.fills = [];
        this.borders = [];
        this.displayItems = [];
        this.externalFonts = [];
    }
    CustomElementViewComponent.prototype.trackLayerFn = function (i, item) {
        return item.id;
    };
    CustomElementViewComponent.prototype.trackFillFn = function (i, item) {
        return item.id;
    };
    CustomElementViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.updateComponentContextOutputs();
        this.updateComponentContextActions();
        this.updateStateContextOutputs();
        this.updateComponentContextValue();
        this.updateStateContextValue();
        this.updateLayerContext();
        this.themeService.theme$.pipe(skip(1), untilDestroyed(this)).subscribe(function () { return _this.updateStateContextValue(); });
    };
    CustomElementViewComponent.prototype.ngOnDestroy = function () { };
    CustomElementViewComponent.prototype.ngOnChanges = function (changes) {
        if (changes.view) {
            this.updateComponentContextActions();
            this.updateFills();
            this.updateBorders();
            this.updateBorderRadius();
            this.updateBoxShadows();
            this.updateDisplayItems();
            this.updateExternalFonts();
            this.updateLayerContext();
        }
        if (changes.parameters && !changes.parameters.firstChange) {
            this.updateComponentContextOutputs();
        }
        if (changes.params && !changes.params.firstChange) {
            this.updateComponentContextValue();
        }
        if (changes.stateSelected && !changes.stateSelected.firstChange) {
            this.updateStateContextValue();
        }
    };
    CustomElementViewComponent.prototype.updateComponentContextOutputs = function () {
        this.parametersContextElement.initGlobal({
            uniqueName: 'component',
            name: 'Component parameters'
        });
        this.parametersContextElement.setOutputs(this.parameters.map(function (item) {
            var fieldDescription = getFieldDescriptionByType(item.field);
            var icon = fieldDescription ? fieldDescription.icon : undefined;
            return {
                uniqueName: item.name,
                name: item.verboseName,
                icon: icon,
                fieldType: item.field,
                fieldParams: item.params,
                external: true
            };
        }));
    };
    CustomElementViewComponent.prototype.updateComponentContextValue = function () {
        this.parametersContextElement.setOutputValues(this.params);
    };
    CustomElementViewComponent.prototype.updateComponentContextActions = function () {
        var _this = this;
        this.actionsContextElement.initGlobal({
            uniqueName: 'component_actions',
            name: 'Component actions'
        });
        this.actionsContextElement.setActions(this.view.actions.map(function (item) {
            return {
                uniqueName: item.name,
                name: item.verboseName,
                parameters: item.parameters,
                handler: function (params) { return _this.onContextAction(item, params); },
                icon: item.icon
            };
        }));
    };
    CustomElementViewComponent.prototype.onContextAction = function (output, params) {
        var action = this.actions.find(function (item) { return item.name == output.name; });
        if (!action) {
            return;
        }
        this.actionControllerService
            .execute(action.action, {
            context: this.viewContext,
            localContext: __assign({}, this.localContext),
            injector: this.injector
        })
            .subscribe();
    };
    CustomElementViewComponent.prototype.updateStateContextOutputs = function () {
        this.stateContextElement.initGlobal({
            uniqueName: 'state',
            name: 'Component state'
        });
        this.stateContextElement.setOutputs((this.stateSelectedEnabled
            ? [
                {
                    uniqueName: SELECTED_OUTPUT,
                    name: 'Component is selected',
                    icon: 'select_all',
                    fieldType: FieldType.Boolean,
                    external: true
                }
            ]
            : []).concat([
            {
                uniqueName: DARK_THEME_OUTPUT,
                name: 'Dark theme',
                icon: 'toggle_theme',
                fieldType: FieldType.Boolean,
                external: true
            }
        ]));
    };
    CustomElementViewComponent.prototype.updateStateContextValue = function () {
        var _a, _b;
        var state = __assign({}, (this.stateSelectedEnabled && (_a = {},
            _a[SELECTED_OUTPUT] = this.stateSelected,
            _a)), (_b = {}, _b[DARK_THEME_OUTPUT] = this.themeService.theme == 'dark', _b));
        this.stateContextElement.setOutputValues(state);
    };
    CustomElementViewComponent.prototype.updateLayerContext = function () {
        var hoverOutput = this.view.interactions.some(function (item) { return item.type == LayerInteractionType.HoverOutput; });
        var pressedOutput = this.view.interactions.some(function (item) { return item.type == LayerInteractionType.PressedOutput; });
        var anyOutputs = hoverOutput || pressedOutput;
        var registered = this.layerTokenContextElement.isRegistered();
        if (anyOutputs && !registered) {
            this.layerTokenContextElement.initElement({
                uniqueName: 'view',
                name: 'Canvas',
                icon: 'canvas'
            });
        }
        else if (anyOutputs && registered) {
            this.layerTokenContextElement.initInfo({
                name: 'Canvas',
                icon: 'canvas'
            }, true);
        }
        else if (!anyOutputs && registered) {
            this.layerTokenContextElement.unregister();
        }
        if (anyOutputs) {
            var outputs = [];
            if (hoverOutput) {
                outputs.push({
                    uniqueName: HOVER_OUTPUT,
                    name: "Layer is hovered",
                    icon: 'target',
                    fieldType: FieldType.Boolean,
                    defaultValue: false,
                    external: true
                });
            }
            if (pressedOutput) {
                outputs.push({
                    uniqueName: PRESSED_OUTPUT,
                    name: "Layer is pressed",
                    icon: 'select_all',
                    fieldType: FieldType.Boolean,
                    defaultValue: false,
                    external: true
                });
            }
            if (!isEqual(this.layerTokenContextElement.outputs.map(function (item) { return item.uniqueName; }), outputs.map(function (item) { return item.uniqueName; }))) {
                this.layerTokenContextElement.setOutputs(outputs);
            }
        }
    };
    CustomElementViewComponent.prototype.updateFills = function () {
        var _this = this;
        if (this.fillsSubscription) {
            this.fillsSubscription.unsubscribe();
            this.fillsSubscription = undefined;
        }
        var fills$ = this.view.fills.slice().reverse()
            .filter(function (item) { return item.enabled; })
            .map(function (item) {
            var icon$ = item.type == FillType.Icon && item.iconFill
                ? item.iconFill.display$({ context: _this.viewContext }).pipe(map(function (value) {
                    return {
                        icon: value.icon,
                        color: value.color,
                        size: isSet(item.iconFill.size)
                            ? item.iconFill.size
                            : Math.min(_this.view.frame.width, _this.view.frame.height)
                    };
                }))
                : of(undefined);
            var css$ = item.css$({ frame: _this.view.frame, context: _this.viewContext });
            var enabled$ = item.enabledInput ? item.enabled$({ context: _this.viewContext }) : of(true);
            return combineLatest(icon$, css$, enabled$).pipe(map(function (_a) {
                var icon = _a[0], css = _a[1], enabled = _a[2];
                return {
                    id: item.id,
                    background: isSet(css.background) ? _this.sanitizer.bypassSecurityTrustStyle(css.background) : undefined,
                    width: css.width,
                    height: css.height,
                    transform: isSet(css.transform) ? _this.sanitizer.bypassSecurityTrustStyle(css.transform) : undefined,
                    icon: icon,
                    opacity: item.type != FillType.Color ? item.opacity : null,
                    enabled: enabled
                };
            }));
        });
        if (!fills$.length) {
            this.fills = [];
            this.cd.markForCheck();
            return;
        }
        this.fillsSubscription = combineLatest(fills$)
            .pipe(untilDestroyed(this))
            .subscribe(function (fills) {
            _this.fills = fills.filter(function (item) { return item.enabled; });
            _this.cd.markForCheck();
        });
    };
    CustomElementViewComponent.prototype.updateBorders = function () {
        var _this = this;
        if (this.bordersSubscription) {
            this.bordersSubscription.unsubscribe();
            this.bordersSubscription = undefined;
        }
        var borders$ = this.view.borders.slice().reverse()
            .filter(function (item) { return item.enabled; })
            .map(function (item) {
            var border$ = item.cssBorder$({ context: _this.viewContext });
            var enabled$ = item.enabledInput ? item.enabled$({ context: _this.viewContext }) : of(true);
            return combineLatest(border$, enabled$).pipe(map(function (_a) {
                var border = _a[0], enabled = _a[1];
                var position;
                if (item.position == BorderPosition.Center) {
                    position = -item.thickness * 0.5;
                }
                else if (item.position == BorderPosition.Outside) {
                    position = -item.thickness;
                }
                else {
                    position = 0;
                }
                var borderRadius = _this.view.cornerRadius.cssBorderRadius(position * -1);
                return {
                    border: isSet(border) ? _this.sanitizer.bypassSecurityTrustStyle(border) : undefined,
                    position: position,
                    borderRadius: _this.sanitizer.bypassSecurityTrustStyle(borderRadius),
                    enabled: enabled
                };
            }));
        });
        if (!borders$.length) {
            this.borders = [];
            this.cd.markForCheck();
            return;
        }
        this.bordersSubscription = combineLatest(borders$)
            .pipe(untilDestroyed(this))
            .subscribe(function (borders) {
            _this.borders = borders.filter(function (item) { return item.enabled && isSet(item.border); });
            _this.cd.markForCheck();
        });
    };
    CustomElementViewComponent.prototype.updateBorderRadius = function () {
        this.borderRadius = this.sanitizer.bypassSecurityTrustStyle(this.view.cornerRadius.cssBorderRadius());
        this.cd.markForCheck();
    };
    CustomElementViewComponent.prototype.updateBoxShadows = function () {
        var _this = this;
        if (this.boxShadowSubscription) {
            this.boxShadowSubscription.unsubscribe();
            this.boxShadowSubscription = undefined;
        }
        var shadows$ = this.view.shadows
            .filter(function (item) { return item.enabled; })
            .map(function (item) {
            var boxShadow$ = item.cssBoxShadow$({ context: _this.viewContext });
            var enabled$ = item.enabledInput ? item.enabled$({ context: _this.viewContext }) : of(true);
            return combineLatest(boxShadow$, enabled$).pipe(map(function (_a) {
                var boxShadow = _a[0], enabled = _a[1];
                return {
                    boxShadow: boxShadow,
                    enabled: enabled
                };
            }));
        });
        if (!shadows$.length) {
            this.boxShadow = undefined;
            this.cd.markForCheck();
            return;
        }
        this.boxShadowSubscription = combineLatest(shadows$)
            .pipe(untilDestroyed(this))
            .subscribe(function (shadows) {
            _this.boxShadow = _this.sanitizer.bypassSecurityTrustStyle(shadows
                .filter(function (item) { return item.enabled; })
                .map(function (item) { return item.boxShadow; })
                .join(','));
            _this.cd.markForCheck();
        });
    };
    CustomElementViewComponent.prototype.updateDisplayItems = function () {
        var _this = this;
        if (this.displayItemsSubscription) {
            this.displayItemsSubscription.unsubscribe();
            this.displayItemsSubscription = undefined;
        }
        var items$ = this.view.layers
            .filter(function (item) { return item.visible; })
            .map(function (item) {
            var visible$ = item.visibleInput ? item.visible$({ context: _this.viewContext }) : of(true);
            return combineLatest(visible$).pipe(map(function (_a) {
                var visible = _a[0];
                return {
                    item: item,
                    visible: visible
                };
            }));
        });
        if (!items$.length) {
            this.displayItems = [];
            this.cd.markForCheck();
            return;
        }
        this.displayItemsSubscription = combineLatest(items$)
            .pipe(untilDestroyed(this))
            .subscribe(function (items) {
            _this.displayItems = items.filter(function (item) { return item.visible; }).map(function (item) { return item.item; });
            _this.cd.markForCheck();
        });
    };
    CustomElementViewComponent.prototype.updateExternalFonts = function () {
        this.externalFonts = getAllFontFamilies(this.view.layers);
        this.cd.markForCheck();
    };
    return CustomElementViewComponent;
}());
export { CustomElementViewComponent };
