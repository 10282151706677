import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material';
import { NgGxScrollableModule } from 'ng-gxscrollable';

import { FormUtilsModule } from '@common/form-utils';
import { TipsModule } from '@common/tips';
import { AdminHeaderModule } from '@modules/admin-header';
import { ColorsComponentsModule } from '@modules/colors-components';
import { CustomizeBarModule } from '@modules/customize-bar';
import { CustomizeComponentsModule } from '@modules/customize-components';
import { CustomizeUiModule } from '@modules/customize-ui';
import { FieldComponentsModule } from '@modules/field-components';
import { FieldsModule } from '@modules/fields';
import { IconsModule } from '@modules/icons';
import { MenuComponentsModule } from '@modules/menu-components';
import { ProjectSettingsComponentsModule } from '@modules/project-settings-components';
import { ProjectsComponentsModule } from '@modules/projects-components';
import { SidebarModule } from '@modules/sidebar';
import { TableModule } from '@modules/table';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { LogoUploaderComponent } from './components/logo-uploader/logo-uploader.component';
import { ParameterValuesItemComponent } from './components/parameter-values-item/parameter-values-item.component';
import { ParameterValuesComponent } from './components/parameter-values/parameter-values.component';
import { ProjectPropertyMenuOverlayComponent } from './components/project-property-menu-overlay/project-property-menu-overlay.component';
import { ProjectPropertyMenuComponent } from './components/project-property-menu/project-property-menu.component';
import { ProjectUniqueNameComponent } from './components/project-unique-name/project-unique-name.component';
import { SSOSettingsEditPopupComponent } from './components/sso-settings-edit-popup/sso-settings-edit-popup.component';
import { SSOTypeMenuOverlayComponent } from './components/sso-type-menu-overlay/sso-type-menu-overlay.component';
import { SSOTypeMenuComponent } from './components/sso-type-menu/sso-type-menu.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgGxScrollableModule,
    TableModule,
    MenuComponentsModule,
    AdminHeaderModule,
    CustomizeComponentsModule,
    UiModule,
    FieldsModule,
    FieldComponentsModule,
    SharedModule,
    OverlayModule,
    SidebarModule,
    TipsModule,
    ProjectsComponentsModule,
    ProjectSettingsComponentsModule,
    FormUtilsModule,
    MatMenuModule,
    IconsModule,
    CustomizeBarModule,
    DragDropModule,
    CustomizeUiModule,
    ColorsComponentsModule
  ],
  declarations: [
    LogoUploaderComponent,
    ProjectUniqueNameComponent,
    SSOSettingsEditPopupComponent,
    SSOTypeMenuComponent,
    SSOTypeMenuOverlayComponent,
    ProjectPropertyMenuComponent,
    ProjectPropertyMenuOverlayComponent,
    ParameterValuesItemComponent,
    ParameterValuesComponent
  ],
  exports: [
    LogoUploaderComponent,
    SSOTypeMenuComponent,
    SSOTypeMenuOverlayComponent,
    ProjectPropertyMenuOverlayComponent
  ],
  entryComponents: [ProjectUniqueNameComponent, SSOSettingsEditPopupComponent]
})
export class LayoutComponentsModule {}
