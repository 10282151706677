var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit, Provider } from '@angular/core';
import defaults from 'lodash/defaults';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of } from 'rxjs';
import { debounceTime, first, map, switchMap } from 'rxjs/operators';
import { FormUtils } from '@common/form-utils';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ActionElementItem, cleanElementName, SUBMIT_RESULT_OUTPUT, ViewContext, ViewContextElement } from '@modules/customize';
import { FeatureService } from '@modules/features';
import { createFormFieldFactory } from '@modules/fields';
import { CurrentProjectStore } from '@modules/projects';
import { SidebarCollapseContext } from '@modules/sidebar';
import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { CUSTOMIZE_ACTION_COMPONENT, CustomizeBarService } from '../../services/customize-bar/customize-bar.service';
import { ElementConfiguration, trackConfigured } from '../../utils/analytics';
import { CustomizeBarActionEditOperationComponent } from './customize-bar-action-edit-operation/customize-bar-action-edit-operation.component';
import { CustomizeBarActionEditForm } from './customize-bar-action-edit.form';
export var CUSTOMIZE_ACTION_COMPONENT_PROVIDER = {
    provide: CUSTOMIZE_ACTION_COMPONENT,
    useFactory: customizeActionComponentFactory
};
export function customizeActionComponentFactory() {
    return CustomizeBarActionEditComponent;
}
var CustomizeBarActionEditComponent = /** @class */ (function () {
    function CustomizeBarActionEditComponent(newForm, formUtils, customizeBarContext, currentProjectStore, cd, featureService, customizeBarService, analyticsService) {
        this.newForm = newForm;
        this.formUtils = formUtils;
        this.customizeBarContext = customizeBarContext;
        this.currentProjectStore = currentProjectStore;
        this.cd = cd;
        this.featureService = featureService;
        this.customizeBarService = customizeBarService;
        this.analyticsService = analyticsService;
        this.label = 'Click action';
        this.wrapperEnabled = true;
        this.titleEnabled = true;
        this.backLabel = 'Back';
        this.backPopSettingsComponent = true;
        this.deleteEnabled = false;
        this.trackConfigured = false;
        this.firstInit = false;
        this.setupOnCreate = false;
        this.event = new EventEmitter();
        this.back = new EventEmitter();
        this._options = {};
        this.createField = createFormFieldFactory();
        this.collapseContext = new SidebarCollapseContext();
        this.actionEditSeparateComponent = false;
        this.configurationStarted = false;
        this.completionContextElementPaths = [[SUBMIT_RESULT_OUTPUT]];
    }
    Object.defineProperty(CustomizeBarActionEditComponent.prototype, "options", {
        get: function () {
            return this._options;
        },
        set: function (value) {
            this._options = defaults(value, {
                parametersEditable: true
            });
        },
        enumerable: true,
        configurable: true
    });
    CustomizeBarActionEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.existingForm) {
            this.form = this.existingForm;
        }
        else {
            this.form = this.newForm;
            this.form.init(__assign({}, this.options, { titleCleanValue: this.titleCleanValue, context: this.context }), this.firstInit);
        }
        this.result = {
            action: this.options.actionItem,
            visibleInput: this.options.visibleInput,
            margin: this.options.margin,
            tooltip: this.options.tooltip,
            title: this.options.title
        };
        this.titleCleanValue = this.titleCleanValue || this.getDefaultTitleCleanValue();
        this.actionEditSeparateComponent = [
            this.options.nameEditable,
            this.options.iconEditable,
            this.options.colorsEditable,
            this.options.approveEnabled,
            this.options.visibleEditable,
            this.options.tooltipEditable,
            this.options.completionEditable
        ].some(function (item) { return item; });
        var resultObs = this.form.valueChangesWithSilent.pipe(debounceTime(200), map(function () { return _this.form.submit(); }));
        resultObs.pipe(untilDestroyed(this)).subscribe(function (result) {
            _this.result = result;
            _this.emitUpdate();
        });
        if (this.trackConfigured) {
            resultObs
                .pipe(switchMap(function (result) { return _this.form.isConfigured(result.action); }), trackConfigured(), first(function (configuration) { return configuration == ElementConfiguration.Started; }), untilDestroyed(this))
                .subscribe(function () {
                _this.configurationStarted = true;
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.StartedConfiguration, {
                    ComponentTypeID: _this.object
                });
            });
        }
        this.operationValid$ = this.form.operationValid$();
        this.actionsValid$ = this.form.actionsValid$();
        if (this.setupOnCreate) {
            this.editInSeparateComponent();
        }
    };
    CustomizeBarActionEditComponent.prototype.ngOnDestroy = function () { };
    CustomizeBarActionEditComponent.prototype.close = function () {
        var _this = this;
        (this.configurationStarted ? this.form.isConfigured(this.result.action) : of(false))
            .pipe(untilDestroyed(this))
            .subscribe(function (configured) {
            if (configured) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.SuccessfullyConfigured, {
                    ComponentTypeID: _this.object
                });
            }
            _this.customizeBarContext.popSettingsComponent();
        });
    };
    CustomizeBarActionEditComponent.prototype.submit = function () {
        this.result = this.form.submit();
        this.emitUpdate(true);
        this.close();
    };
    CustomizeBarActionEditComponent.prototype.getDefaultTitleCleanValue = function () {
        var _this = this;
        return function (value) {
            if (_this.element) {
                return cleanElementName(value, _this.element, _this.context.getElementItems());
            }
            else {
                return value;
            }
        };
    };
    CustomizeBarActionEditComponent.prototype.onTitleChanged = function (title) {
        this.form.controls.title.patchValue(title);
    };
    CustomizeBarActionEditComponent.prototype.onApprovalOpenedChanged = function (opened) {
        if (opened) {
            this.form.controls.approve_enabled.patchValue(true);
            this.form.markAsDirty();
        }
    };
    CustomizeBarActionEditComponent.prototype.emitUpdate = function (submit) {
        if (submit === void 0) { submit = false; }
        var args = { result: this.result.action, submit: submit };
        if (this.options.titleEditable) {
            args['title'] = this.result.title;
        }
        if (this.options.visibleEditable) {
            args['visible_input'] = this.result.visibleInput;
        }
        if (this.options.tooltipEditable) {
            args['tooltip'] = this.result.tooltip;
        }
        if (this.options.marginEditable) {
            args['margin'] = this.result.margin;
        }
        if (this.options.actionItem) {
            this.event.emit({
                type: CustomizeBarEditEventType.Updated,
                args: args
            });
        }
        else {
            this.event.emit({
                type: CustomizeBarEditEventType.Created,
                args: args
            });
        }
    };
    CustomizeBarActionEditComponent.prototype.cancel = function () {
        this.event.emit({ type: CustomizeBarEditEventType.Canceled });
        this.close();
    };
    CustomizeBarActionEditComponent.prototype.delete = function () {
        this.event.emit({ type: CustomizeBarEditEventType.Deleted });
        this.close();
    };
    CustomizeBarActionEditComponent.prototype.openTaskFeatureOverview = function () {
        this.featureService.showFeatureOverview({
            subtitle: 'Paid Feature',
            title: 'Collaborate on a project with <strong>Collaboration</strong>',
            description: "\n          Assign tasks, leave notes, and write comments on a specific record page. Chat with your teammates directly\n          through Jet and make sure everyone is up-to-date.\n        "
        });
    };
    CustomizeBarActionEditComponent.prototype.editInSeparateComponent = function () {
        var _this = this;
        this.customizeBarContext.appendSettingsComponent({
            component: CustomizeBarActionEditOperationComponent,
            inputs: {
                options: __assign({}, this.options, { titleEditable: false }),
                form: this.form,
                label: this.label,
                context: this.context,
                contextElement: this.contextElement,
                contextElementPath: this.contextElementPath,
                contextElementPaths: this.contextElementPaths,
                firstInit: this.firstInit,
                setupOnCreate: this.setupOnCreate,
                object: this.object
            },
            outputs: {
                submitImmediate: [
                    function () {
                        _this.submit();
                    }
                ],
                event: [
                    function () {
                        _this.customizeBarContext.popSettingsComponent();
                    }
                ]
            }
        });
    };
    CustomizeBarActionEditComponent.prototype.onConfirmationClick = function () {
        if (!this.form.controls.confirmation_enabled.value) {
            this.form.controls.confirmation_enabled.patchValue(true);
        }
    };
    return CustomizeBarActionEditComponent;
}());
export { CustomizeBarActionEditComponent };
