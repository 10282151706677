import pickBy from 'lodash/pickBy';

import { ActionItem } from '@modules/actions';
import { ModelDescriptionDataSource } from '@modules/data-sources';
import { Input, InputValueType } from '@modules/fields';
import { ModelDescriptionQuery } from '@modules/queries';

import { migrateModelDescriptionDataSource } from '../../../utils/migration';
import { ITEM_OUTPUT, VALUE_OUTPUT } from '../../form-outputs';
import { RawListViewSettingsColumn } from '../../raw-list-view-settings-column';
import { TintStyle } from '../../view-settings-action';
import { getElementByType, registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
import { FieldElementItem } from './field';
import { FormSubmitElementItem } from './form-submit-element';
import { editorJsOutputDataToQuillDelta, TextElementItem } from './text';

export enum FormStyle {
  Wrap = 'wrap',
  Background = 'background'
}

export class FormElementItem extends ElementItem {
  public type = ElementType.Form;
  public getDataSource: ModelDescriptionDataSource;
  public submitAction: ActionItem;
  public columns: RawListViewSettingsColumn[] = [];
  public children: ElementItem[] = [];
  public style: FormStyle = FormStyle.Wrap;
  public generated = false;
  public loadInvisible = false;

  deserialize(data: Object): FormElementItem {
    super.deserialize(data);

    if (this.params['generated'] !== undefined) {
      this.generated = this.params['generated'];
    }

    if (this.params['get_data_source']) {
      this.getDataSource = new ModelDescriptionDataSource().deserialize(this.params['get_data_source']);
    } else if (this.params['resource']) {
      // Backward compatibility
      this.getDataSource = migrateModelDescriptionDataSource(ModelDescriptionDataSource, ModelDescriptionQuery, {
        resource: this.params['resource'],
        query: this.params['get_query'],
        parameters: this.params['parameters'],
        inputs: this.params['inputs'],
        columns: this.params['get_columns']
      });
    } else {
      this.getDataSource = undefined;
    }

    if (this.params['submit_action']) {
      this.submitAction = new ActionItem().deserialize(this.params['submit_action']);
    }

    if (this.params['style']) {
      this.style = this.params['style'];
    }

    if (this.params['children']) {
      this.children = this.params['children']
        .map(item => {
          const element = getElementByType(item['type']);
          if (!element) {
            console.error(`Unsupported element type: ${item['type']}`);
            return;
          }
          return new element().deserialize(item);
        })
        .filter(item => item != undefined);
    } else {
      // Backward compatibility

      const children = [];

      if (this.params['title']) {
        const element = new TextElementItem();

        element.generateUid();
        element.name = element.defaultName();
        element.quillDelta = editorJsOutputDataToQuillDelta({
          blocks: [
            {
              type: 'header',
              data: {
                text: this.params['title'],
                level: 1
              }
            }
          ]
        });

        children.push(element);
      }

      if (this.params['columns']) {
        children.push(
          ...(this.params['columns'] as RawListViewSettingsColumn[]).map(item => {
            const element = new FieldElementItem();

            element.generateUid();
            element.name = item.verboseName || item.name;
            element.settings = item;
            element.version = 1;
            element.width = 220;

            if (this.getDataSource) {
              const input = new Input();

              input.name = 'value';
              input.valueType = InputValueType.Context;
              input.contextValue = ['elements', this.uid, ITEM_OUTPUT, item.name];

              element.settings.valueInput = input;
            }

            return element;
          })
        );

        if (this.submitAction) {
          this.submitAction.inputs = this.submitAction.inputs.map(input => {
            if (
              input.valueType == InputValueType.Context &&
              input.contextValue &&
              input.contextValue[0] == VALUE_OUTPUT
            ) {
              const element = children.find(
                item => item instanceof FieldElementItem && item.settings.name == input.name
              );

              if (element) {
                input.contextValue = ['elements', element.uid, VALUE_OUTPUT];
              }
            }

            return input;
          });
        }
      }

      const submitElement = new FormSubmitElementItem();

      submitElement.generateUid();

      if (this.params['submit_action']) {
        submitElement.verboseNameInput = new Input().deserializeFromStatic(
          'value',
          this.params['submit_action']['verbose_name']
        );
        submitElement.icon = this.params['submit_action']['icon'];
        submitElement.style = this.params['submit_action']['style'];

        if (this.params['submit_action']['tint'] != undefined) {
          submitElement.tint = this.params['submit_action']['tint'];
        } else if (submitElement.style == TintStyle.Default && this.params['submit_action']['color_text']) {
          submitElement.tint = this.params['submit_action']['color_text'];
        } else if (submitElement.style == TintStyle.Primary && this.params['submit_action']['color']) {
          submitElement.tint = this.params['submit_action']['color'];
        } else if (submitElement.style == TintStyle.Transparent && this.params['submit_action']['color_text']) {
          submitElement.tint = this.params['submit_action']['color_text'];
        }
      }

      children.push(submitElement);

      this.children = children;
      this.generated = this.params['columns'] && this.params['columns'].length;
    }

    if (this.params['load_invisible'] != undefined) {
      this.loadInvisible = this.params['load_invisible'];
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = {};
    this.params['generated'] = this.generated;
    this.params['get_data_source'] = this.getDataSource ? this.getDataSource.serialize() : undefined;
    this.params['columns'] = this.columns;
    this.params['submit_action'] = this.submitAction ? this.submitAction.serialize() : undefined;
    this.params['children'] = this.children.map(item => item.serialize());
    this.params['style'] = this.style;
    this.params['load_invisible'] = this.loadInvisible;

    let data = super.serialize();
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  get typeStr(): string {
    return 'send form data';
  }

  get analyticsName(): string {
    return 'form';
  }

  defaultName() {
    return 'Form';
  }
}

registerElementForType(ElementType.Form, FormElementItem);
