import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ThemeModule } from '@modules/theme';
import { SharedModule } from '@shared';

import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { BarChart2Component } from './components/bar-chart2/bar-chart2.component';
import { BubbleChartComponent } from './components/bubble-chart/bubble-chart.component';
import { DataTooltipComponent } from './components/data-tooltip/data-tooltip.component';
import { DoughnutChartComponent } from './components/doughnut-chart/doughnut-chart.component';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { LineChart2Component } from './components/line-chart2/line-chart2.component';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
import { PieChart2Component } from './components/pie-chart2/pie-chart2.component';
import { PolarAreaChartComponent } from './components/polar-area-chart/polar-area-chart.component';
import { RadarChartComponent } from './components/radar-chart/radar-chart.component';
import { ScatterChartComponent } from './components/scatter-chart/scatter-chart.component';
import { ScatterChart2Component } from './components/scatter-chart2/scatter-chart2.component';

@NgModule({
  imports: [CommonModule, ThemeModule, PortalModule, SharedModule],
  declarations: [
    LineChartComponent,
    BarChartComponent,
    PieChartComponent,
    DoughnutChartComponent,
    RadarChartComponent,
    PolarAreaChartComponent,
    ScatterChartComponent,
    BubbleChartComponent,
    LineChart2Component,
    DataTooltipComponent,
    BarChart2Component,
    PieChart2Component,
    ScatterChart2Component
  ],
  exports: [
    LineChartComponent,
    BarChartComponent,
    PieChartComponent,
    DoughnutChartComponent,
    RadarChartComponent,
    PolarAreaChartComponent,
    ScatterChartComponent,
    BubbleChartComponent,
    LineChart2Component,
    BarChart2Component,
    PieChart2Component,
    ScatterChart2Component
  ],
  entryComponents: [DataTooltipComponent]
})
export class ChartsModule {}
