var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { asyncScheduler, concat, of } from 'rxjs';
import { map, throttleTime } from 'rxjs/operators';
import { applyParamInput, contextInputValueTypes, Input as FieldInput } from '@modules/fields';
import { isSet } from '@shared';
export function quillDeltaToHtml(delta, options) {
    if (options === void 0) { options = {}; }
    if (!delta) {
        return of(undefined);
    }
    var hasContextFormula = options.context &&
        delta.ops.some(function (item) {
            return (item['insert'] &&
                item['insert']['context-formula'] &&
                contextInputValueTypes.includes(item['insert']['context-formula']['value_type']));
        });
    var converter = new QuillDeltaToHtmlConverter(delta.ops, {});
    converter.renderCustomWith(function (customOp, contextOp) {
        if (customOp.insert.type === 'context-formula') {
            var input = new FieldInput();
            if (customOp.insert.value) {
                input.deserialize(customOp.insert.value);
            }
            var value = void 0;
            try {
                value = applyParamInput(input, __assign({}, options, { defaultValue: '' }));
            }
            catch (e) { }
            var html = "<span class=\"inline-input\">" + (isSet(value) ? value : '') + "</span>";
            return customOp.attributes ? surroundHtmlWithQuillAttributesApplied(html, customOp.attributes) : html;
        }
    });
    var obs = hasContextFormula
        ? concat(of(options.context.outputValues), options.context.outputValues$).pipe(throttleTime(10, asyncScheduler, { leading: true, trailing: true }))
        : of({});
    return obs.pipe(map(function () { return converter.convert(); }));
}
export function surroundHtmlWithQuillAttributesApplied(html, attributes) {
    var placeholder = '____jet-context-formula____';
    var ops = [{ insert: placeholder, attributes: attributes }];
    var converter = new QuillDeltaToHtmlConverter(ops, { paragraphTag: 'span' });
    var result = converter.convert();
    return result.replace(placeholder, html);
}
