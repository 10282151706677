<a
  href="javascript:void(0)"
  class="related-link-action"
  [class.related-link-action_disabled]="disabled || submitLoading"
  [appTintColor]="'accentColor' | appThemeColorHexOption | async"
  [appTintColorStyle]="tintStyles.Default"
  (click)="$event.stopPropagation(); executeAction(item)"
>
  <app-loader-small
    *ngIf="submitLoading"
    class="related-link-action__icon"
    [class.related-link-action__icon_left]="verboseName | appIsSet"
  ></app-loader-small>
  <span
    *ngIf="item.icon && !submitLoading"
    class="related-link-action__icon"
    [class.related-link-action__icon_left]="verboseName | appIsSet"
    [ngClass]="'icon-' + item.icon"
  >
  </span>

  <span *ngIf="verboseName | appIsSet">
    {{ verboseName | appCapitalize }}
  </span>
</a>

<span
  *ngIf="!isVisible && (customizeService.enabled$ | async)"
  class="element__indicator element__indicator_outside icon-eye"
  [appTip]="'Action is hidden'"
></span>
