<div class="sidebar__list">
  <div class="sidebar-list__separator">
    <app-sidebar-list-separator [title]="'When action succeeded'"></app-sidebar-list-separator>
  </div>
  <app-actions-edit
    [control]="form.controls.on_success_actions"
    [context]="context"
    [contextElement]="contextElement"
    [contextElementPath]="contextElementPath"
    [contextElementPaths]="contextElementPaths"
    [emptyAction]="'Success action'"
    [emptyActionValue]="'Not specified'"
    [emptyIcon]="'check_circle'"
    [actionLabel]="'Action succeeded'"
    [object]="object"
    [firstInit]="firstInit"
  >
  </app-actions-edit>
  <div class="sidebar-list__separator">
    <app-sidebar-list-separator [title]="'When action failed'"></app-sidebar-list-separator>
  </div>
  <app-actions-edit
    [control]="form.controls.on_error_actions"
    [context]="context"
    [contextElement]="contextElement"
    [contextElementPath]="contextElementPath"
    [contextElementPaths]="contextElementPaths"
    [emptyAction]="'Error action'"
    [emptyActionValue]="'Not specified'"
    [emptyIcon]="'close'"
    [actionLabel]="'Action failed'"
    [object]="object"
    [firstInit]="firstInit"
  >
  </app-actions-edit>
</div>
