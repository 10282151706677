import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TimelineMax } from 'gsap';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { NotificationService } from '@common/notifications';
import { AuthService } from '@modules/auth';
import { TintStyle } from '@modules/customize';
import { defaultSignUpLayout, Domain } from '@modules/domain';
import { createFormFieldFactory } from '@modules/fields';
import { MetaService } from '@modules/meta';
import { RoutingService } from '@modules/routing';
import { ThemeContext } from '@modules/theme-components';
import { CurrentUserStore } from '@modules/users';
import { isSet } from '@shared';

import { RestoreForm } from './restore.form';

@Component({
  selector: 'app-restore2',
  templateUrl: './restore.component.html',
  providers: [RestoreForm, ThemeContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RestoreComponent implements OnInit, OnDestroy {
  loading = false;
  submitted = false;
  createField = createFormFieldFactory();
  tl = new TimelineMax();
  domain: Domain;
  domainLoading = false;
  defaultLayout = defaultSignUpLayout;
  externalFonts: string[] = [];
  tintStyles = TintStyle;

  constructor(
    public form: RestoreForm,
    protected routing: RoutingService,
    protected currentUserStore: CurrentUserStore,
    protected activatedRoute: ActivatedRoute,
    protected authService: AuthService,
    protected notificationService: NotificationService,
    private metaService: MetaService,
    protected cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.metaService.set({ title: 'Restore' });

    this.domainLoading = true;
    this.cd.markForCheck();

    this.activatedRoute.data.pipe(untilDestroyed(this)).subscribe(
      data => {
        this.domain = data.domain;
        this.externalFonts = this.getExternalFonts(this.domain);
        this.domainLoading = false;
        this.cd.markForCheck();
      },
      () => {
        this.domainLoading = false;
        this.cd.markForCheck();
      }
    );
  }

  ngOnDestroy(): void {}

  getExternalFonts(domain: Domain): string[] {
    if (!domain) {
      return [];
    }

    return [domain.signUpFontRegular, domain.signUpFontHeading].filter(item => isSet(item));
  }

  submit() {
    this.loading = true;
    this.cd.markForCheck();

    this.form
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.submitted = true;
          this.cd.markForCheck();
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  cleanErrorForm() {
    this.form.form.setErrors({});
  }

  get layout() {
    return this.domain && isSet(this.domain.signUpLayout) ? this.domain.signUpLayout : this.defaultLayout;
  }

  get isWhiteLabel() {
    return this.domain && this.domain.whiteLabel;
  }

  get signInLink() {
    const projectName = this.activatedRoute.snapshot.params['project'];
    const ignoreSelfSignOn = this.activatedRoute.snapshot.data['ignoreSelfSignOn'];

    if (projectName) {
      return ['/login', projectName];
    } else if (ignoreSelfSignOn) {
      return ['/user', 'login'];
    } else {
      return ['/login'];
    }
  }
}
