import { ElementType, PopupPosition } from '@modules/customize';

export const changeCustomizeBarComponentsLayouts = [
  {
    title: 'Container',
    image: 'card_layout',
    type: ElementType.Card,
    defaultParams: { children: [] }
  },
  {
    title: 'Columns',
    image: 'columns_layout',
    type: ElementType.Columns,
    defaultParams: {
      columns: [
        { weight: 2, children: [] },
        { weight: 1, children: [] }
      ]
    }
  },
  {
    title: 'Tabs',
    image: 'tabs_layout',
    type: ElementType.Tabs,
    defaultParams: {
      items: [
        { title: 'General', children: [] },
        { title: 'More', children: [] }
      ]
    }
  },
  // {
  //   title: 'Stack',
  //   image: 'stack_layout',
  //   type: ElementType.Stack,
  //   defaultParams: {}
  // },
  {
    title: 'Modal',
    image: 'modal',
    action: 'Add Modal',
    popup: true
  },
  {
    title: 'Slideout',
    image: 'slideout',
    action: 'Add Slideout',
    popup: true,
    defaultParams: { width: 400, position: PopupPosition.SideRight }
  },
  {
    title: 'Spacing',
    image: 'spacing',
    type: ElementType.Spacing,
    defaultParams: { height: 40 }
  },
  {
    title: 'Separator',
    image: 'separator',
    type: ElementType.Separator,
    defaultParams: {}
  }
  // {
  //   title: 'Section',
  //   image: 'section_layout',
  //   type: ElementType.Section,
  //   defaultParams: { children: [] }
  // }
];
