var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import isPlainObject from 'lodash/isPlainObject';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { map } from 'rxjs/operators';
import { ApiType } from '@modules/api';
import { deserializeMultipleFileValue, deserializeSingleFileValue, FieldType, getFieldDescriptionByType, ImageOutputFormat, Input } from '@modules/fields';
import { getExtension, getFilename, getFilenameWithExtension, isSet } from '@shared';
// TODO: Move to data layer
export var VALUE_OUTPUT = 'value';
export var UPLOAD_RESULT_OUTPUT = 'upload_result';
export var FILE_NAME_OUTPUT = 'file_name';
export var FILE_EXTENSION_OUTPUT = 'file_extension';
import { FieldComponent } from '../field/field.component';
var UploadFieldComponent = /** @class */ (function (_super) {
    __extends(UploadFieldComponent, _super);
    function UploadFieldComponent(modelService, currentProjectStore, currentEnvironmentStore, storageService, resourceControllerService, cd) {
        var _this = _super.call(this) || this;
        _this.modelService = modelService;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.storageService = storageService;
        _this.resourceControllerService = resourceControllerService;
        _this.cd = cd;
        _this.uploadedFiles = [];
        return _this;
    }
    UploadFieldComponent.prototype.getApiInfo = function () {
        return this.storageResource ? this.storageResource.apiInfo : undefined;
    };
    UploadFieldComponent.prototype.ngOnDestroy = function () { };
    UploadFieldComponent.prototype.initOutputs = function () {
        if (this.contextElement) {
            var fieldDescription = getFieldDescriptionByType(this.field.field);
            this.contextElement.setOutputs([
                {
                    uniqueName: VALUE_OUTPUT,
                    name: 'Value',
                    icon: fieldDescription.icon,
                    fieldType: this.field.field,
                    fieldParams: this.field.params,
                    external: true
                },
                {
                    uniqueName: UPLOAD_RESULT_OUTPUT,
                    name: 'Upload operation result',
                    icon: 'cloud_upload',
                    fieldType: FieldType.JSON,
                    external: true
                },
                {
                    uniqueName: FILE_NAME_OUTPUT,
                    name: 'File name',
                    icon: 'one_of',
                    fieldType: FieldType.Text
                },
                {
                    uniqueName: FILE_EXTENSION_OUTPUT,
                    name: 'File extension',
                    icon: 'attach_clip',
                    fieldType: FieldType.Text
                }
            ]);
        }
    };
    UploadFieldComponent.prototype.updateStorage = function () {
        var storage = this.currentProjectStore.instance.getStorage(this.currentEnvironmentStore.instance.uniqueName, this.field.params['storage_resource'], this.field.params['storage_name'], { defaultFirst: true });
        this.storageResource = storage ? storage.resource : undefined;
        this.storage = storage ? storage.storage : undefined;
        // Backward compatibility
        if (typeof this.field.params['path'] == 'string') {
            this.storagePath = new Input().deserializeFromStatic('value', this.field.params['path']);
        }
        else if (isPlainObject(this.field.params['path'])) {
            this.storagePath = new Input().deserialize(this.field.params['path']);
        }
        else {
            this.storagePath = undefined;
        }
        this.cd.markForCheck();
    };
    UploadFieldComponent.prototype.getUploadUrl = function (value) {
        if (!isSet(value)) {
            return;
        }
        var apiInfo = this.getApiInfo();
        if (apiInfo &&
            apiInfo.type == ApiType.JetDjango &&
            apiInfo.versionLessThan('0.7.7') &&
            !this.field.params['ignore_old_django_format']) {
            value = value['value'];
        }
        if (this.field.params['output_format'] != ImageOutputFormat.URL) {
            var controller = this.storageResource
                ? this.resourceControllerService.get(this.storageResource.type)
                : undefined;
            if (controller && controller.fileUrl) {
                return controller.fileUrl(this.storageResource, value);
            }
        }
        return value;
    };
    UploadFieldComponent.prototype.updateUploaded = function () {
        if (this.field.params['multiple']) {
            this.updateMultipleUploaded();
        }
        else {
            this.updateSingleUploaded();
        }
    };
    UploadFieldComponent.prototype.updateSingleUploaded = function () {
        var _this = this;
        if (this.updateUploadedSubscription) {
            this.updateUploadedSubscription.unsubscribe();
        }
        this.updateUploadedSubscription = this.currentValue$
            .pipe(map(function (value) { return deserializeSingleFileValue(value); }), map(function (value) { return _this.getUploadUrl(value); }), untilDestroyed(this))
            .subscribe(function (result) {
            _this.uploadedFile = {
                url: result,
                filename: getFilenameWithExtension(result),
                extension: getExtension(result)
            };
            _this.cd.markForCheck();
            _this.onUploadedUpdated();
            if (_this.contextElement) {
                _this.contextElement.setOutputValue(FILE_NAME_OUTPUT, getFilename(_this.uploadedFile.url));
                _this.contextElement.setOutputValue(FILE_EXTENSION_OUTPUT, _this.uploadedFile.extension);
            }
        }, function () {
            _this.uploadedFile = undefined;
            _this.cd.markForCheck();
            _this.onUploadedUpdated();
            if (_this.contextElement) {
                _this.contextElement.setOutputValue(FILE_NAME_OUTPUT, undefined);
                _this.contextElement.setOutputValue(FILE_EXTENSION_OUTPUT, undefined);
            }
        });
    };
    UploadFieldComponent.prototype.updateMultipleUploaded = function () {
        var _this = this;
        if (this.updateUploadedSubscription) {
            this.updateUploadedSubscription.unsubscribe();
        }
        this.updateUploadedSubscription = this.currentValue$
            .pipe(map(function (value) { return deserializeMultipleFileValue(value); }), map(function (value) { return value.map(function (item) { return _this.getUploadUrl(item); }); }), untilDestroyed(this))
            .subscribe(function (result) {
            _this.uploadedFiles = result.map(function (item) {
                return {
                    url: item,
                    filename: getFilenameWithExtension(item),
                    extension: getExtension(item)
                };
            });
            _this.cd.markForCheck();
            _this.onUploadedUpdated();
            // if (this.contextElement) {
            //   this.contextElement.setOutputValue(FILE_NAME_OUTPUT, getFilename(this.uploadedFile.url));
            //   this.contextElement.setOutputValue(FILE_EXTENSION_OUTPUT, this.uploadedFile.extension);
            // }
        }, function () {
            // this.uploadedFiles = undefined;
            _this.cd.markForCheck();
            _this.onUploadedUpdated();
            // if (this.contextElement) {
            //   this.contextElement.setOutputValue(FILE_NAME_OUTPUT, undefined);
            //   this.contextElement.setOutputValue(FILE_EXTENSION_OUTPUT, undefined);
            // }
        });
    };
    UploadFieldComponent.prototype.onUploadedUpdated = function () { };
    return UploadFieldComponent;
}(FieldComponent));
export { UploadFieldComponent };
