<div class="icon-selector">
  <div class="icon-selector__search">
    <input
      class="input input_fill input_small"
      type="text"
      placeholder="Search..."
      [(ngModel)]="search"
      (keyup)="onSearchChanged()"
      [appAutofocus]="true"
    />
  </div>
  <div class="icon-selector__items">
    <div class="icon-selector__items-inner" xsScrollable #scrollable="scrollableDirective">
      <ng-container *ngIf="!loading">
        <div *ngIf="emptyEnabled && (current | appIsSet) && !(search | appIsSet)" class="icon-selector__item">
          <a
            href="javascript:void(0)"
            class="icon-selector-item icon-selector-item_secondary"
            [appTip]="'Reset icon'"
            (click)="reset()"
          >
            <span class="icon-selector-item__icon icon-close"></span>
          </a>
        </div>

        <div *ngFor="let item of filteredItems" class="icon-selector__item">
          <a
            href="javascript:void(0)"
            class="icon-selector-item"
            [class.icon-selector-item_current]="current == item.name"
            [appTip]="item.label"
            (click)="save(item)"
          >
            <span class="icon-selector-item__icon" [ngClass]="['icon-' + item.name]"></span>
          </a>
        </div>

        <div *ngIf="query && !filteredItems.length" class="icon-selector__items-empty">
          No icons found
        </div>
      </ng-container>

      <ng-container *ngIf="loading">
        <div class="icon-selector__items-empty">
          Loading icons...
        </div>
      </ng-container>
    </div>
    <gxs-scrollbar [theme]="'jet-mini'" [scrollable]="scrollable"></gxs-scrollbar>
  </div>
</div>
