<form [formGroup]="form" (ngSubmit)="submit()">
  <app-customize-bar-header
    [title]="'Range Slider'"
    [titleEnabled]="titleEnabled"
    [backLabel]="'All components'"
    [deleteEnabled]="deleteEnabled"
    [parentLabel]="parentElement?.displayName || parentPopup?.displayName"
    (parentClick)="parentElement?.customize({ event: $event }) || parentPopup?.customize({ event: $event })"
    (deleteClick)="delete()"
  >
  </app-customize-bar-header>

  <div class="sidebar__content">
    <app-sidebar-tabs>
      <app-sidebar-tab [label]="'General'">
        <div class="sidebar__element">
          <app-sidebar-field [label]="'label'" [additional]="'(optional)'">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="createField({ name: 'verbose_name', field: 'CharField', params: { classes: ['input_fill'] } })"
            ></app-auto-field>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-customize-bar-input-edit
            [label]="'From'"
            [itemForm]="form.controls.from"
            [context]="context"
            [placeholder]="'From'"
            [fill]="true"
            [analyticsSource]="element.analyticsGenericName + '_from'"
          ></app-customize-bar-input-edit>
        </div>

        <div class="sidebar__element">
          <app-customize-bar-input-edit
            [label]="'To'"
            [itemForm]="form.controls.to"
            [context]="context"
            [placeholder]="'to'"
            [fill]="true"
            [analyticsSource]="element.analyticsGenericName + '_to'"
          ></app-customize-bar-input-edit>
        </div>

        <div class="sidebar__element">
          <div class="sidebar-cols">
            <div class="sidebar-cols__item">
              <app-customize-bar-input-edit
                [label]="'Minimum value'"
                [itemForm]="form.controls.min_value_input"
                [context]="context"
                [placeholder]="'1'"
                [resetEnabled]="false"
                [fill]="true"
                [analyticsSource]="element.analyticsGenericName + '_min_value'"
              ></app-customize-bar-input-edit>
            </div>

            <div class="sidebar-cols__item">
              <app-customize-bar-input-edit
                [label]="'Maximum value'"
                [itemForm]="form.controls.max_value_input"
                [context]="context"
                [placeholder]="'10'"
                [resetEnabled]="false"
                [fill]="true"
                [analyticsSource]="element.analyticsGenericName + '_max_value'"
              ></app-customize-bar-input-edit>
            </div>
          </div>
        </div>

        <div class="sidebar__element">
          <app-customize-bar-input-edit
            [label]="'Step size'"
            [itemForm]="form.controls.step_size_input"
            [context]="context"
            [placeholder]="'10'"
            [resetEnabled]="false"
            [fill]="true"
            [analyticsSource]="element.analyticsGenericName + '_step_size'"
          ></app-customize-bar-input-edit>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'color'" [required]="false">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'tint',
                  field: 'ColorField',
                  params: { classes: ['select_fill'], allow_empty: true, empty_accent_color: true, custom_colors: true }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </div>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Display'">
        <div class="sidebar__element">
          <app-sidebar-field [label]="'required'" [layoutLeft]="true">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'required',
                  label: 'required',
                  field: 'BooleanField',
                  params: { style: booleanFieldStyle.Toggle, orange: true }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field
            [label]="'Conditional Visibility'"
            [documentation]="'conditional-visibility'"
            [documentationLabel]="'See how it works'"
            [documentationSource]="element.analyticsGenericName + '_visible'"
          >
            <app-input-edit
              [itemForm]="form.controls.visible_input"
              [context]="context"
              [staticValueDisabled]="true"
              [placeholder]="'Value'"
              [fill]="true"
              [analyticsSource]="element.analyticsGenericName + '_visible'"
            ></app-input-edit>

            <ng-container description>
              Example: elements.field.value >= 10
            </ng-container>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field
            [label]="'Conditional Disable'"
            [documentationSource]="element.analyticsGenericName + '_disable'"
          >
            <app-input-edit
              [itemForm]="form.controls.disable_input"
              [context]="context"
              [staticValueDisabled]="true"
              [placeholder]="'Value'"
              [fill]="true"
              [analyticsSource]="element.analyticsGenericName + '_disable'"
            ></app-input-edit>

            <ng-container description>
              Example: elements.type.value == 'Refund'
            </ng-container>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'tooltip'">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'tooltip',
                  field: 'CharField',
                  placeholder: 'Show information for users',
                  reset_enabled: true,
                  params: { classes: ['input_fill'] }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'Spacing'">
            <app-margin-control [control]="form.controls.margin"></app-margin-control>
          </app-sidebar-field>
        </div>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Actions'" [error]="(actionsValid$ | async) !== false ? undefined : 'Not configured'">
        <app-sidebar-section>
          <div class="sidebar__list">
            <div class="sidebar-list__separator">
              <app-sidebar-list-separator [title]="'When value changes'"></app-sidebar-list-separator>
            </div>
            <div class="sidebar-list">
              <div class="sidebar-list__item">
                <app-actions-edit
                  [control]="form.controls.on_change_actions"
                  [context]="context"
                  [contextElement]="contextElement"
                  [contextElementPaths]="itemContextElementPaths"
                  [emptyAction]="'Value changes'"
                  [emptyActionValue]="'Not specified'"
                  [emptyIcon]="'console'"
                  [actionLabel]="'Value changes action'"
                  [object]="element.analyticsGenericName"
                  [firstInit]="firstInit"
                >
                </app-actions-edit>
              </div>
            </div>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>
    </app-sidebar-tabs>
  </div>
</form>
