import keys from 'lodash/keys';
import * as moment from 'moment-timezone';
import { timezones } from '../../data/timezones';
import { LANGUAGE_KEY, TIMEZONE_KEY } from '../../data/user-preferences';
import { captureException } from '../../utils/logging/logging';
import * as i0 from "@angular/core";
var LocalStorage = /** @class */ (function () {
    function LocalStorage() {
    }
    LocalStorage.prototype.get = function (key, defaultValue) {
        return localStorage[key] !== undefined ? localStorage[key] : defaultValue;
    };
    LocalStorage.prototype.getObject = function (key) {
        try {
            return JSON.parse(localStorage[key]);
        }
        catch (e) { }
    };
    LocalStorage.prototype.set = function (key, value) {
        var _this = this;
        try {
            localStorage[key] = value;
        }
        catch (e) {
            if (e instanceof Error && e.name == 'QuotaExceededError') {
                var largeKeySize_1 = 1024 * 1024 * 0.5;
                var largeKeys = this.keys()
                    .map(function (item) {
                    var itemValue = _this.get(item);
                    var size = itemValue !== undefined && itemValue !== null ? String(itemValue).length : 0;
                    var preview;
                    if (size >= largeKeySize_1) {
                        preview = String(itemValue).substr(0, 200);
                    }
                    return {
                        name: item,
                        size: itemValue !== undefined && itemValue !== null ? String(itemValue).length : 0,
                        preview: preview
                    };
                })
                    .sort(function (lhs, rhs) { return (lhs.size - rhs.size) * -1; });
                captureException(e, {
                    extra: {
                        local_storage_keys: largeKeys.map(function (item) {
                            return [item.name, item.size].concat((item.preview !== undefined ? [item.preview] : []));
                        })
                    }
                });
                var deleteKeys = largeKeys.filter(function (item) { return item.size >= largeKeySize_1; });
                if (deleteKeys.length) {
                    deleteKeys.forEach(function (item) {
                        console.log("[LocalStorage] Deleting too large key \"" + item.name + "\" value: " + item.preview);
                        _this.remove(item.name);
                    });
                    this.set(key, value);
                    return;
                }
            }
            throw e;
        }
    };
    LocalStorage.prototype.setObject = function (key, value) {
        localStorage[key] = JSON.stringify(value);
    };
    LocalStorage.prototype.remove = function (key) {
        if (localStorage[key] != undefined) {
            delete localStorage[key];
        }
    };
    LocalStorage.prototype.keys = function () {
        return keys(localStorage);
    };
    LocalStorage.prototype.setLanguage = function (value) {
        this.set(LANGUAGE_KEY, value);
    };
    LocalStorage.prototype.getLanguage = function () {
        return this.get(LANGUAGE_KEY);
    };
    LocalStorage.prototype.setTimezone = function (value) {
        this.set(TIMEZONE_KEY, value);
        var timezone = value ? timezones.find(function (item) { return item.name == value; }) : undefined;
        var tz = timezone ? timezone.tz : undefined;
        moment.tz.setDefault(tz);
    };
    LocalStorage.prototype.getTimezone = function () {
        return this.get(TIMEZONE_KEY);
    };
    LocalStorage.ngInjectableDef = i0.defineInjectable({ factory: function LocalStorage_Factory() { return new LocalStorage(); }, token: LocalStorage, providedIn: "root" });
    return LocalStorage;
}());
export { LocalStorage };
