import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgGxDatepickerModule } from 'ng-gxdatepicker';
import { NgGxScrollableModule } from 'ng-gxscrollable';
import { NgGxSelectModule } from 'ng-gxselect';

import { DialogPopupModule } from '@common/dialog-popup';
import { DialogsModule } from '@common/dialogs';
import { DragDropModule } from '@common/drag-drop';
import { DragDrop2Module } from '@common/drag-drop2';
import { DynamicComponentModule } from '@common/dynamic-component';
import { FixedComponentModule } from '@common/fixed-component';
import { LocalizeModule } from '@common/localize';
import { NotificationsModule } from '@common/notifications';
import { TipsModule } from '@common/tips';
import { ActionsComponentsModule } from '@modules/actions-components';
import { AdminHeaderModule } from '@modules/admin-header';
import { AnalyticsModule } from '@modules/analytics';
import { ChangeModule } from '@modules/change';
import { ChangeComponentsModule } from '@modules/change-components';
import { ChartsModule } from '@modules/charts';
import { CustomizeBarModule } from '@modules/customize-bar';
import { CustomizeComponentsModule } from '@modules/customize-components';
import { FieldsModule } from '@modules/fields';
import { GridModule } from '@modules/grid';
import { ListComponentsModule } from '@modules/list-components';
import { ModelQueriesModule } from '@modules/model-queries';
import { RoutingModule } from '@modules/routing';
import { TableModule } from '@modules/table';
import { SharedModule } from '@shared';

import { ChangeHeaderActionComponent } from './components/change-header/change-header-action/change-header-action.component';
import { ChangeHeaderComponent } from './components/change-header/change-header.component';
import { ChangeRouteComponent } from './components/change-route/change-route.component';
import { ChangeSubmitComponent } from './components/change-submit/change-submit.component';
import { ChangeComponent } from './components/change/change.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgGxScrollableModule,
    NgGxDatepickerModule,
    NgGxSelectModule,
    DialogsModule,
    DialogPopupModule,
    NotificationsModule,
    RoutingModule,
    LocalizeModule,
    ChangeModule,
    DragDropModule,
    CustomizeComponentsModule,
    ModelQueriesModule,
    TipsModule,
    DynamicComponentModule,
    SharedModule,
    FixedComponentModule,
    AnalyticsModule,
    FieldsModule,
    TableModule,
    GridModule,
    ChartsModule,
    ListComponentsModule,
    CustomizeBarModule,
    ActionsComponentsModule,
    ChangeComponentsModule,
    AdminHeaderModule,
    DragDrop2Module,
    OverlayModule
  ],
  declarations: [
    ChangeComponent,
    ChangeHeaderComponent,
    ChangeSubmitComponent,
    ChangeHeaderActionComponent,
    ChangeRouteComponent
  ],
  exports: [ChangeComponent, ChangeRouteComponent],
  entryComponents: []
})
export class ChangeRoutesModule {}
