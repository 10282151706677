var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { InjectionToken, OnDestroy } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import defaults from 'lodash/defaults';
import { of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ActionDropdownElementItem, ActionElementItem, ActionGroupElementItem, AlertElementItem, BackElementItem, BarCodeElementItem, CardLayoutElementItem, ColumnsLayoutElementItem, CustomElementItem, ElementItem, FieldElementItem, FileViewerElementItem, FilterElementItem, FormElementItem, FormSubmitElementItem, IFrameElementItem, ImageElementItem, ListElementItem, ListLayoutSettings, Margin, ModelElementItem, PopupSettings, QrCodeElementItem, RangeSliderElementItem, ScannerElementItem, SeparatorElementItem, SpacingElementItem, StackLayoutElementItem, StepsElementItem, TabsLayoutElementItem, TextElementItem, ViewContext, ViewContextElement, ViewSettings, ViewSettingsAction, WidgetElementItem } from '@modules/customize';
import { ChartWidget, ValueWidget } from '@modules/dashboard';
import { ModelDescription } from '@modules/models';
import { isSet } from '@shared';
import { CustomizeBarActionDropdownEditComponent } from '../../components/customize-bar-action-dropdown-edit/customize-bar-action-dropdown-edit.component';
import { CustomizeActionOptions, CustomizeBarActionEditForm } from '../../components/customize-bar-action-edit/customize-bar-action-edit.form';
import { CustomizeBarActionGroupEditComponent } from '../../components/customize-bar-action-group-edit/customize-bar-action-group-edit.component';
import { CustomizeBarAlertEditComponent } from '../../components/customize-bar-alert-edit/customize-bar-alert-edit.component';
import { CustomizeBarBackEditComponent } from '../../components/customize-bar-back-edit/customize-bar-back-edit.component';
import { CustomizeBarBarCodeEditComponent } from '../../components/customize-bar-bar-code-edit/customize-bar-bar-code-edit.component';
import { CustomizeBarCardEditComponent } from '../../components/customize-bar-card-edit/customize-bar-card-edit.component';
import { CustomizeBarColumnEditComponent } from '../../components/customize-bar-column-edit/customize-bar-column-edit.component';
import { CustomizeBarColumnsEditComponent } from '../../components/customize-bar-columns-edit/customize-bar-columns-edit.component';
import { CustomizeBarCustomEditComponent } from '../../components/customize-bar-custom-edit/customize-bar-custom-edit.component';
import { CustomizeBarFileViewerEditComponent } from '../../components/customize-bar-file-viewer-edit/customize-bar-file-viewer-edit.component';
import { CustomizeBarFilterEditComponent } from '../../components/customize-bar-filter-edit/customize-bar-filter-edit.component';
import { CustomizeBarFormEditComponent } from '../../components/customize-bar-form-edit/customize-bar-form-edit.component';
import { CustomizeBarFormSubmitEditComponent } from '../../components/customize-bar-form-submit-edit/customize-bar-form-submit-edit.component';
import { CustomizeBarIFrameEditComponent } from '../../components/customize-bar-iframe-edit/customize-bar-iframe-edit.component';
import { CustomizeBarImageEditComponent } from '../../components/customize-bar-image-edit/customize-bar-image-edit.component';
import { CustomizeBarListEditComponent } from '../../components/customize-bar-list-edit/customize-bar-list-edit.component';
import { CustomizeBarPageEditComponent } from '../../components/customize-bar-page-edit/customize-bar-page-edit.component';
import { CustomizeBarPopupEditComponent } from '../../components/customize-bar-popup-edit/customize-bar-popup-edit.component';
import { CustomizeBarQrCodeEditComponent } from '../../components/customize-bar-qr-code-edit/customize-bar-qr-code-edit.component';
import { CustomizeBarRangeSliderEditComponent } from '../../components/customize-bar-range-slider-edit/customize-bar-range-slider-edit.component';
import { CustomizeBarScannerEditComponent } from '../../components/customize-bar-scanner-edit/customize-bar-scanner-edit.component';
import { CustomizeBarSeparatorEditComponent } from '../../components/customize-bar-separator-edit/customize-bar-separator-edit.component';
import { CustomizeBarSpacingEditComponent } from '../../components/customize-bar-spacing-edit/customize-bar-spacing-edit.component';
import { CustomizeBarStackEditComponent } from '../../components/customize-bar-stack-edit/customize-bar-stack-edit.component';
import { CustomizeBarStepsEditComponent } from '../../components/customize-bar-steps-edit/customize-bar-steps-edit.component';
import { CustomizeBarTabsEditComponent } from '../../components/customize-bar-tabs-edit/customize-bar-tabs-edit.component';
import { CustomizeBarTextEditComponent } from '../../components/customize-bar-text-edit/customize-bar-text-edit.component';
import { CustomizeBarChartWidgetEditComponent } from '../../components/widgets/customize-bar-chart-widget-edit/customize-bar-chart-widget-edit.component';
import { CustomizeBarValueWidgetEditComponent } from '../../components/widgets/customize-bar-value-widget-edit/customize-bar-value-widget-edit.component';
import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
export var CUSTOMIZE_ACTION_COMPONENT = new InjectionToken('CUSTOMIZE_ACTION_COMPONENT');
export var CUSTOMIZE_MODEL_COMPONENT = new InjectionToken('CUSTOMIZE_MODEL_COMPONENT');
var CustomizeBarService = /** @class */ (function () {
    function CustomizeBarService(customizeActionComponent, customizeModelComponent, analyticsService) {
        this.customizeActionComponent = customizeActionComponent;
        this.customizeModelComponent = customizeModelComponent;
        this.analyticsService = analyticsService;
    }
    CustomizeBarService.prototype.ngOnDestroy = function () { };
    CustomizeBarService.prototype.customizeActionItem = function (options) {
        options = defaults(options, { options: {} });
        var result = new Subject();
        options.context.setSettingsComponent({
            component: this.customizeActionComponent,
            inputs: __assign({ options: options.options }, (options.label && { label: options.label }), (isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }), (options.backLabel && { backLabel: options.backLabel }), { element: options.element, context: options.viewContext, contextElement: options.viewContextElement, contextElementPath: options.viewContextElementPath, contextElementPaths: options.viewContextElementPaths, deleteEnabled: options.deleteEnabled, existingForm: options.existingForm, parentElement: options.parentElement, parentPopup: options.parentPopup, object: options.object, trackConfigured: options.trackConfigured, firstInit: options.firstInit, setupOnCreate: options.setupOnCreate }),
            outputs: {
                event: [
                    function (e) {
                        result.next(e);
                    }
                ]
            }
        }, { append: options.append });
        return result.asObservable();
    };
    CustomizeBarService.prototype.customizeModelElementItem = function (options) {
        var result = new Subject();
        options.context.setSettingsComponent({
            component: this.customizeModelComponent,
            inputs: __assign({ element: options.element, context: options.viewContext, contextElement: options.viewContextElement, deleteEnabled: options.deleteEnabled }, (isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }), { titleEditable: options.titleEditable, parentElement: options.parentElement, parentPopup: options.parentPopup, firstInit: options.firstInit, setupOnCreate: options.setupOnCreate }),
            outputs: {
                event: [
                    function (e) {
                        result.next(e);
                    }
                ]
            }
        }, { append: options.append });
        return result.asObservable();
    };
    CustomizeBarService.prototype.customizeFormElementItem = function (options) {
        var result = new Subject();
        options.context.setSettingsComponent({
            component: CustomizeBarFormEditComponent,
            inputs: __assign({ element: options.element, context: options.viewContext, contextElement: options.viewContextElement }, (options.backLabel && { backLabel: options.backLabel }), { deleteEnabled: options.deleteEnabled }, (isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }), { titleEditable: options.titleEditable, parentElement: options.parentElement, parentPopup: options.parentPopup, firstInit: options.firstInit, setupOnCreate: options.setupOnCreate }),
            outputs: {
                event: [
                    function (e) {
                        result.next(e);
                    }
                ]
            }
        }, { append: options.append });
        return result.asObservable();
    };
    CustomizeBarService.prototype.customizeFormSubmitElementItem = function (options) {
        var result = new Subject();
        options.context.setSettingsComponent({
            component: CustomizeBarFormSubmitEditComponent,
            inputs: __assign({ title: options.title, element: options.element, elementUpdated: options.elementUpdated, context: options.viewContext }, (isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }), { titleEditable: options.titleEditable, deleteEnabled: options.deleteEnabled, visibleInput: options.visibleInput, visibleEditable: options.visibleEditable, parentElement: options.parentElement, parentPopup: options.parentPopup, parentForm: options.parentForm, firstInit: options.firstInit }),
            outputs: {
                event: [
                    function (e) {
                        result.next(e);
                    }
                ]
            }
        }, { append: options.append });
        return result.asObservable();
    };
    CustomizeBarService.prototype.customizeTextElementItem = function (options) {
        var result = new Subject();
        options.context.setSettingsComponent({
            component: CustomizeBarTextEditComponent,
            inputs: __assign({ element: options.element, context: options.viewContext, contextElement: options.viewContextElement, deleteEnabled: options.deleteEnabled }, (isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }), { titleEditable: options.titleEditable, parentElement: options.parentElement, parentPopup: options.parentPopup, firstInit: options.firstInit }),
            outputs: {
                event: [
                    function (e) {
                        result.next(e);
                    }
                ]
            }
        }, { append: options.append });
        return result.asObservable();
    };
    CustomizeBarService.prototype.customizeSpacingElementItem = function (options) {
        var result = new Subject();
        options.context.setSettingsComponent({
            component: CustomizeBarSpacingEditComponent,
            inputs: __assign({ element: options.element, context: options.viewContext, contextElement: options.viewContextElement, deleteEnabled: options.deleteEnabled }, (isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }), { titleEditable: options.titleEditable, parentElement: options.parentElement, parentPopup: options.parentPopup, firstInit: options.firstInit }),
            outputs: {
                event: [
                    function (e) {
                        result.next(e);
                    }
                ]
            }
        }, { append: options.append });
        return result.asObservable();
    };
    CustomizeBarService.prototype.customizeSeparatorElementItem = function (options) {
        var result = new Subject();
        options.context.setSettingsComponent({
            component: CustomizeBarSeparatorEditComponent,
            inputs: __assign({ element: options.element, context: options.viewContext, contextElement: options.viewContextElement, deleteEnabled: options.deleteEnabled }, (isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }), { titleEditable: options.titleEditable, parentElement: options.parentElement, parentPopup: options.parentPopup, firstInit: options.firstInit }),
            outputs: {
                event: [
                    function (e) {
                        result.next(e);
                    }
                ]
            }
        }, { append: options.append });
        return result.asObservable();
    };
    CustomizeBarService.prototype.customizeStepsElementItem = function (options) {
        var result = new Subject();
        options.context.setSettingsComponent({
            component: CustomizeBarStepsEditComponent,
            inputs: __assign({ element: options.element, context: options.viewContext, contextElement: options.viewContextElement, deleteEnabled: options.deleteEnabled }, (isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }), { titleEditable: options.titleEditable, parentElement: options.parentElement, parentPopup: options.parentPopup, firstInit: options.firstInit }),
            outputs: {
                event: [
                    function (e) {
                        result.next(e);
                    }
                ]
            }
        }, { append: options.append });
        return result.asObservable();
    };
    CustomizeBarService.prototype.customizeFilterElementItem = function (options) {
        var result = new Subject();
        options.context.setSettingsComponent({
            component: CustomizeBarFilterEditComponent,
            inputs: __assign({ element: options.element, context: options.viewContext, contextElement: options.viewContextElement, deleteEnabled: options.deleteEnabled }, (isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }), { titleEditable: options.titleEditable, parentElement: options.parentElement, parentPopup: options.parentPopup, firstInit: options.firstInit }),
            outputs: {
                event: [
                    function (e) {
                        result.next(e);
                    }
                ]
            }
        }, { append: options.append });
        return result.asObservable();
    };
    CustomizeBarService.prototype.customizeCardElementItem = function (options) {
        var result = new Subject();
        options.context.setSettingsComponent({
            component: CustomizeBarCardEditComponent,
            inputs: __assign({ element: options.element, context: options.viewContext, contextElement: options.viewContextElement, deleteEnabled: options.deleteEnabled }, (isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }), { titleEditable: options.titleEditable, parentElement: options.parentElement, parentPopup: options.parentPopup, firstInit: options.firstInit }),
            outputs: {
                event: [
                    function (e) {
                        result.next(e);
                    }
                ]
            }
        }, { append: options.append });
        return result.asObservable();
    };
    CustomizeBarService.prototype.customizeStackElementItem = function (options) {
        var result = new Subject();
        options.context.setSettingsComponent({
            component: CustomizeBarStackEditComponent,
            inputs: __assign({ element: options.element, context: options.viewContext, contextElement: options.viewContextElement, deleteEnabled: options.deleteEnabled }, (isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }), { titleEditable: options.titleEditable, parentElement: options.parentElement, parentPopup: options.parentPopup, firstInit: options.firstInit }),
            outputs: {
                event: [
                    function (e) {
                        result.next(e);
                    }
                ]
            }
        }, { append: options.append });
        return result.asObservable();
    };
    CustomizeBarService.prototype.customizeTabsElementItem = function (options) {
        var result = new Subject();
        options.context.setSettingsComponent({
            component: CustomizeBarTabsEditComponent,
            inputs: __assign({ element: options.element, context: options.viewContext, contextElement: options.viewContextElement, deleteEnabled: options.deleteEnabled }, (isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }), { titleEditable: options.titleEditable, parentElement: options.parentElement, parentPopup: options.parentPopup, firstInit: options.firstInit }),
            outputs: {
                event: [
                    function (e) {
                        result.next(e);
                    }
                ]
            }
        }, { append: options.append });
        return result.asObservable();
    };
    CustomizeBarService.prototype.customizeColumnsElementItem = function (options) {
        var result = new Subject();
        options.context.setSettingsComponent({
            component: CustomizeBarColumnsEditComponent,
            inputs: __assign({ element: options.element, context: options.viewContext, contextElement: options.viewContextElement, deleteEnabled: options.deleteEnabled }, (isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }), { titleEditable: options.titleEditable, parentElement: options.parentElement, parentPopup: options.parentPopup, firstInit: options.firstInit }),
            outputs: {
                event: [
                    function (e) {
                        result.next(e);
                    }
                ]
            }
        }, { append: options.append });
        return result.asObservable();
    };
    CustomizeBarService.prototype.customizeWidget = function (options) {
        var result = new Subject();
        var component;
        if (options.item instanceof ValueWidget) {
            component = CustomizeBarValueWidgetEditComponent;
        }
        else if (options.item instanceof ChartWidget) {
            component = CustomizeBarChartWidgetEditComponent;
        }
        if (!component) {
            return of();
        }
        options.context.setSettingsComponent({
            component: component,
            inputs: __assign({ element: options.element, widget: options.item, title: options.title }, (isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }), { titleEditable: options.titleEditable, visibleInput: options.visibleInput, visibleEditable: options.visibleEditable, margin: options.margin, marginEditable: options.marginEditable, context: options.viewContext, contextElement: options.viewContextElement, deleteAllowed: options.deleteAllowed, deleteEnabled: options.deleteEnabled, parentElement: options.parentElement, parentPopup: options.parentPopup, component: options.component, firstInit: options.firstInit, setupOnCreate: options.setupOnCreate }),
            outputs: {
                event: [
                    function (e) {
                        result.next(e);
                    }
                ]
            }
        }, { append: options.append });
        return result.asObservable();
    };
    CustomizeBarService.prototype.customizeListLayoutSettings = function (options) {
        options = defaults(options, { submitEnabled: true, backEnabled: true });
        var result = new Subject();
        options.context.setSettingsComponent({
            component: CustomizeBarListEditComponent,
            inputs: __assign({ settings: options.settings, visibleInput: options.visibleInput, visibleEditable: options.visibleEditable, margin: options.margin, marginEditable: options.marginEditable, title: options.title }, (isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }), { titleEditable: options.titleEditable, element: options.element, context: options.viewContext, contextElement: options.viewContextElement, submitEnabled: options.submitEnabled, backEnabled: options.backEnabled, deleteEnabled: options.deleteEnabled, parentElement: options.parentElement, parentPopup: options.parentPopup, firstInit: options.firstInit, setupOnCreate: options.setupOnCreate }),
            outputs: {
                event: [
                    function (e) {
                        result.next(e);
                    }
                ]
            }
        }, { append: options.append });
        return result.asObservable();
    };
    CustomizeBarService.prototype.customizeColumn = function (options) {
        options = __assign({ margin: {}, backEnabled: true }, options, { configurable: __assign({ field: true, required: true }, options.configurable) });
        var result = new Subject();
        options.context.setSettingsComponent({
            component: CustomizeBarColumnEditComponent,
            inputs: __assign({ field: options.column, modelDescription: options.modelDescription, actions: options.actions, onChangeActions: options.onChangeActions, element: options.element, context: options.viewContext, contextElement: options.viewContextElement, contextElementPath: options.viewContextElementPath, contextElementPaths: options.viewContextElementPaths, configurable: options.configurable, title: options.title }, (isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }), { titleEditable: options.titleEditable, titleAutoUpdate: options.titleAutoUpdate }, (options.backLabel && { backLabel: options.backLabel }), { visibleInput: options.visibleInput, visibleEditable: options.visibleEditable, disableInput: options.disableInput, disableEditable: options.disableEditable, tooltip: options.tooltip }, (isSet(options.tooltipEditable) && { tooltipEditable: options.tooltipEditable }), { margin: options.margin }, (isSet(options.marginEditable) && { marginEditable: options.marginEditable }), { backEnabled: options.backEnabled, submitEnabled: options.submitEnabled, deleteEnabled: options.deleteEnabled }, (options.actionsLabels && { actionsLabels: options.actionsLabels }), { parentElement: options.parentElement, parentPopup: options.parentPopup, parentForm: options.parentForm, object: options.object, trackConfigured: options.trackConfigured, firstInit: options.firstInit }),
            outputs: {
                event: [
                    function (e) {
                        result.next(e);
                    }
                ]
            }
        }, { append: options.append });
        return result.asObservable();
    };
    CustomizeBarService.prototype.customizePage = function (options) {
        var result = new Subject();
        options.context.setSettingsComponent({
            component: CustomizeBarPageEditComponent,
            inputs: {
                viewSettings: options.viewSettings,
                context: options.viewContext
            },
            outputs: {
                event: [
                    function (e) {
                        result.next(e);
                    }
                ]
            }
        }, { append: options.append });
        return result.asObservable();
    };
    CustomizeBarService.prototype.customizePopup = function (options) {
        var result = new Subject();
        options.context.setSettingsComponent({
            component: CustomizeBarPopupEditComponent,
            inputs: {
                popup: options.popup,
                context: options.viewContext
            },
            outputs: {
                event: [
                    function (e) {
                        result.next(e);
                    }
                ]
            }
        }, { append: options.append });
        return result.asObservable();
    };
    CustomizeBarService.prototype.customizeImage = function (options) {
        var result = new Subject();
        options.context.setSettingsComponent({
            component: CustomizeBarImageEditComponent,
            inputs: __assign({ element: options.element, elementUpdated: options.elementUpdated, context: options.viewContext }, (isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }), { deleteEnabled: options.deleteEnabled, parentElement: options.parentElement, parentPopup: options.parentPopup, firstInit: options.firstInit }),
            outputs: {
                event: [
                    function (e) {
                        result.next(e);
                    }
                ]
            }
        }, { append: options.append });
        return result.asObservable();
    };
    CustomizeBarService.prototype.customizeIFrame = function (options) {
        var result = new Subject();
        options.context.setSettingsComponent({
            component: CustomizeBarIFrameEditComponent,
            inputs: __assign({ element: options.element, context: options.viewContext }, (isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }), { deleteEnabled: options.deleteEnabled, parentElement: options.parentElement, parentPopup: options.parentPopup, firstInit: options.firstInit }),
            outputs: {
                event: [
                    function (e) {
                        result.next(e);
                    }
                ]
            }
        }, { append: options.append });
        return result.asObservable();
    };
    CustomizeBarService.prototype.customizeQrCode = function (options) {
        var result = new Subject();
        options.context.setSettingsComponent({
            component: CustomizeBarQrCodeEditComponent,
            inputs: __assign({ element: options.element, context: options.viewContext }, (isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }), { deleteEnabled: options.deleteEnabled, parentElement: options.parentElement, parentPopup: options.parentPopup, firstInit: options.firstInit }),
            outputs: {
                event: [
                    function (e) {
                        result.next(e);
                    }
                ]
            }
        }, { append: options.append });
        return result.asObservable();
    };
    CustomizeBarService.prototype.customizeBarCode = function (options) {
        var result = new Subject();
        options.context.setSettingsComponent({
            component: CustomizeBarBarCodeEditComponent,
            inputs: __assign({ element: options.element, context: options.viewContext }, (isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }), { deleteEnabled: options.deleteEnabled, parentElement: options.parentElement, parentPopup: options.parentPopup, firstInit: options.firstInit }),
            outputs: {
                event: [
                    function (e) {
                        result.next(e);
                    }
                ]
            }
        }, { append: options.append });
        return result.asObservable();
    };
    CustomizeBarService.prototype.customizeScanner = function (options) {
        var result = new Subject();
        options.context.setSettingsComponent({
            component: CustomizeBarScannerEditComponent,
            inputs: __assign({ element: options.element, context: options.viewContext, contextElement: options.viewContextElement }, (isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }), { deleteEnabled: options.deleteEnabled, parentElement: options.parentElement, parentPopup: options.parentPopup, firstInit: options.firstInit, analyticsSource: options.analyticsSource }),
            outputs: {
                event: [
                    function (e) {
                        result.next(e);
                    }
                ]
            }
        }, { append: options.append });
        return result.asObservable();
    };
    CustomizeBarService.prototype.customizeAlert = function (options) {
        var result = new Subject();
        options.context.setSettingsComponent({
            component: CustomizeBarAlertEditComponent,
            inputs: __assign({ element: options.element, context: options.viewContext }, (isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }), { deleteEnabled: options.deleteEnabled, parentElement: options.parentElement, parentPopup: options.parentPopup, firstInit: options.firstInit, analyticsSource: options.analyticsSource }),
            outputs: {
                event: [
                    function (e) {
                        result.next(e);
                    }
                ]
            }
        }, { append: options.append });
        return result.asObservable();
    };
    CustomizeBarService.prototype.customizeFileViewer = function (options) {
        var result = new Subject();
        options.context.setSettingsComponent({
            component: CustomizeBarFileViewerEditComponent,
            inputs: __assign({ element: options.element, context: options.viewContext }, (isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }), { deleteEnabled: options.deleteEnabled, parentElement: options.parentElement, parentPopup: options.parentPopup, firstInit: options.firstInit }),
            outputs: {
                event: [
                    function (e) {
                        result.next(e);
                    }
                ]
            }
        }, { append: options.append });
        return result.asObservable();
    };
    CustomizeBarService.prototype.customizeRangeSlider = function (options) {
        var result = new Subject();
        options.context.setSettingsComponent({
            component: CustomizeBarRangeSliderEditComponent,
            inputs: __assign({ element: options.element, context: options.viewContext, contextElement: options.viewContextElement }, (isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }), { deleteEnabled: options.deleteEnabled, parentElement: options.parentElement, parentPopup: options.parentPopup, firstInit: options.firstInit }),
            outputs: {
                event: [
                    function (e) {
                        result.next(e);
                    }
                ]
            }
        }, { append: options.append });
        return result.asObservable();
    };
    CustomizeBarService.prototype.customizeCustom = function (options) {
        var result = new Subject();
        options.context.setSettingsComponent({
            component: CustomizeBarCustomEditComponent,
            inputs: __assign({ title: options.title, element: options.element, elementUpdated: options.elementUpdated, context: options.viewContext }, (isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }), { titleEditable: options.titleEditable, deleteEnabled: options.deleteEnabled, parentElement: options.parentElement, parentPopup: options.parentPopup, firstInit: options.firstInit }),
            outputs: {
                event: [
                    function (e) {
                        result.next(e);
                    }
                ]
            }
        }, { append: options.append });
        return result.asObservable();
    };
    CustomizeBarService.prototype.customizeActionGroup = function (options) {
        var result = new Subject();
        options.context.setSettingsComponent({
            component: CustomizeBarActionGroupEditComponent,
            inputs: __assign({ title: options.title, element: options.element, elementUpdated: options.elementUpdated, context: options.viewContext }, (isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }), { titleEditable: options.titleEditable, deleteEnabled: options.deleteEnabled, visibleInput: options.visibleInput, visibleEditable: options.visibleEditable, parentElement: options.parentElement, parentPopup: options.parentPopup, firstInit: options.firstInit }),
            outputs: {
                event: [
                    function (e) {
                        result.next(e);
                    }
                ]
            }
        }, { append: options.append });
        return result.asObservable();
    };
    CustomizeBarService.prototype.customizeActionDropdown = function (options) {
        var result = new Subject();
        options.context.setSettingsComponent({
            component: CustomizeBarActionDropdownEditComponent,
            inputs: __assign({ title: options.title, element: options.element, elementUpdated: options.elementUpdated, context: options.viewContext }, (isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }), { titleEditable: options.titleEditable, deleteEnabled: options.deleteEnabled, visibleInput: options.visibleInput, visibleEditable: options.visibleEditable, parentElement: options.parentElement, parentPopup: options.parentPopup, firstInit: options.firstInit }),
            outputs: {
                event: [
                    function (e) {
                        result.next(e);
                    }
                ]
            }
        }, { append: options.append });
        return result.asObservable();
    };
    CustomizeBarService.prototype.customizeBack = function (options) {
        var result = new Subject();
        options.context.setSettingsComponent({
            component: CustomizeBarBackEditComponent,
            inputs: __assign({ title: options.title, element: options.element, context: options.viewContext }, (isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }), { titleEditable: options.titleEditable, deleteEnabled: options.deleteEnabled, parentElement: options.parentElement, parentPopup: options.parentPopup, firstInit: options.firstInit }),
            outputs: {
                event: [
                    function (e) {
                        result.next(e);
                    }
                ]
            }
        }, { append: options.append });
        return result.asObservable();
    };
    CustomizeBarService.prototype.customize = function (context, element, viewContext, component, elementUpdated, options) {
        if (options === void 0) { options = {}; }
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.StartedSetUp, {
            ComponentTypeID: element.analyticsName
        });
        var result = new Subject();
        if (element instanceof FieldElementItem) {
            var viewContextElement = component ? component.viewContextElement : undefined;
            var initialElement_1 = cloneDeep(element);
            var configurable = {
                name: true,
                verboseName: isSet(options.fieldLabelEditable) ? options.fieldLabelEditable : true,
                field: true,
                required: true,
                editable: true,
                editableOnly: isSet(options.fieldReadonlyEditable) ? !options.fieldLabelEditable : false,
                params: true,
                add: true,
                value: true,
                visible: true,
                disable: true,
                margin: true,
                onChangeActions: true
            };
            return this.customizeColumn(__assign({ context: context, column: element.settings, configurable: configurable, onChangeActions: element.onChangeActions, element: element, viewContext: viewContext, viewContextElement: viewContextElement, title: element.name, titleEnabled: options.titleEnabled, titleEditable: true, titleAutoUpdate: true, backLabel: 'All components', visibleInput: element.visibleInput, visibleEditable: true, disableInput: element.disableInput, disableEditable: true, tooltip: element.tooltip, tooltipEditable: isSet(options.tooltipEditable) ? options.tooltipEditable : true, margin: element.margin, marginEditable: isSet(options.marginEditable) ? options.marginEditable : true, deleteEnabled: true, object: element.analyticsName, trackConfigured: true }, options)).pipe(map(function (e) {
                if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
                    var newElement = cloneDeep(element);
                    newElement.name = e.args['title'];
                    newElement.settings = e.args['result'];
                    newElement.visibleInput = e.args['visible_input'];
                    newElement.disableInput = e.args['disable_input'];
                    newElement.onChangeActions = e.args['on_change_actions'];
                    newElement.tooltip = e.args['tooltip'];
                    newElement.margin = e.args['margin'];
                    newElement.updateFormField();
                    return {
                        el: newElement
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Canceled) {
                    return {
                        el: initialElement_1
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Deleted) {
                    return {
                        el: initialElement_1,
                        event: CustomizeBarEditEventType.Deleted
                    };
                }
            }));
        }
        else if (element instanceof ActionElementItem) {
            var viewContextElement = component ? component.viewContextElement : undefined;
            var initialElement_2 = cloneDeep(element);
            return this.customizeActionItem(__assign({ element: element, context: context, options: {
                    actionItem: element.actionItem,
                    actionLabel: 'Button click',
                    title: initialElement_2.name,
                    visibleInput: element.visibleInput,
                    actionItemClass: ViewSettingsAction,
                    titleEditable: true,
                    nameEditable: true,
                    iconEditable: true,
                    colorsEditable: true,
                    approveEnabled: true,
                    confirmationEnabled: true,
                    completionEditable: true,
                    disabledEditable: true,
                    visibleEditable: true,
                    tooltip: element.tooltip,
                    tooltipEditable: isSet(options.tooltipEditable) ? options.tooltipEditable : true,
                    margin: element.margin,
                    marginEditable: isSet(options.marginEditable) ? options.marginEditable : true,
                    typesOnly: options.actionTypesOnly
                }, viewContext: viewContext, viewContextElement: viewContextElement, titleEnabled: options.titleEnabled, backLabel: 'All components', tooltipEditable: options.tooltipEditable, marginEditable: options.marginEditable, deleteEnabled: true, object: element.analyticsName, trackConfigured: true }, options)).pipe(map(function (e) {
                if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
                    var newElement = cloneDeep(element);
                    newElement.name = e.args['title'];
                    newElement.actionItem = e.args['result'];
                    newElement.visibleInput = e.args['visible_input'];
                    newElement.tooltip = e.args['tooltip'];
                    newElement.margin = e.args['margin'];
                    return {
                        el: newElement
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Canceled) {
                    return {
                        el: initialElement_2
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Deleted) {
                    return {
                        el: initialElement_2,
                        event: CustomizeBarEditEventType.Deleted
                    };
                }
            }));
        }
        else if (element instanceof ListElementItem) {
            var initialElement_3 = cloneDeep(element);
            // TODO: Refactor index get
            var index_1 = component ? component.getCurrentLayoutIndex() : 0;
            var viewContextElement_1 = component ? component.viewContextElement : undefined;
            var layoutContextElement = component
                ? component.getContextElements()[index_1]
                : undefined;
            return this.customizeListLayoutSettings(__assign({ context: context, settings: element.layouts[index_1], visibleInput: element.visibleInput, visibleEditable: true, margin: element.margin, marginEditable: true, title: element.name, titleEnabled: options.titleEnabled, titleEditable: true, element: element, viewContext: viewContext, viewContextElement: layoutContextElement, deleteEnabled: true }, options)).pipe(map(function (e) {
                if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
                    var newElement = cloneDeep(element);
                    var instance_1 = e.args['result'];
                    var title = e.args['title'];
                    newElement.name = title;
                    newElement.layouts = newElement.layouts.map(function (item, i) {
                        if (i == index_1) {
                            return instance_1;
                        }
                        else {
                            return item;
                        }
                    });
                    newElement.visibleInput = e.args['visible_input'];
                    newElement.margin = e.args['margin'];
                    if (viewContextElement_1) {
                        viewContextElement_1.initInfo({ name: title });
                    }
                    return {
                        el: newElement
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Canceled) {
                    return {
                        el: initialElement_3
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Deleted) {
                    return {
                        el: initialElement_3,
                        event: CustomizeBarEditEventType.Deleted
                    };
                }
            }));
        }
        else if (element instanceof ModelElementItem) {
            var viewContextElement = component ? component.viewContextElement : undefined;
            var initialElement_4 = cloneDeep(element);
            return this.customizeModelElementItem(__assign({ context: context, element: element, viewContext: viewContext, viewContextElement: viewContextElement, titleEnabled: options.titleEnabled, titleEditable: true, deleteEnabled: true }, options)).pipe(map(function (e) {
                if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
                    return {
                        el: e.args['result']
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Canceled) {
                    return {
                        el: initialElement_4
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Deleted) {
                    return {
                        el: initialElement_4,
                        event: CustomizeBarEditEventType.Deleted
                    };
                }
            }));
        }
        else if (element instanceof FormElementItem) {
            var viewContextElement = component ? component.viewContextElement : undefined;
            var initialElement_5 = cloneDeep(element);
            return this.customizeFormElementItem(__assign({ context: context, element: element, viewContext: viewContext, viewContextElement: viewContextElement, titleEnabled: options.titleEnabled, titleEditable: true, backLabel: 'All components', deleteEnabled: true }, options)).pipe(map(function (e) {
                if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
                    return {
                        el: e.args['result']
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Canceled) {
                    return {
                        el: initialElement_5
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Deleted) {
                    return {
                        el: initialElement_5,
                        event: CustomizeBarEditEventType.Deleted
                    };
                }
            }));
        }
        else if (element instanceof FormSubmitElementItem) {
            var initialElement_6 = cloneDeep(element);
            return this.customizeFormSubmitElementItem(__assign({ context: context, element: element, viewContext: viewContext, elementUpdated: elementUpdated, append: false, title: element.name, titleEnabled: options.titleEnabled, titleEditable: true, deleteEnabled: true, visibleInput: element.visibleInput, visibleEditable: true }, options)).pipe(map(function (e) {
                if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
                    var newElement = e.args['result'];
                    newElement.name = e.args['title'];
                    return {
                        el: newElement
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Canceled) {
                    return {
                        el: initialElement_6
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Deleted) {
                    return {
                        el: initialElement_6,
                        event: CustomizeBarEditEventType.Deleted
                    };
                }
            }));
        }
        else if (element instanceof FormElementItem) {
            var viewContextElement = component ? component.viewContextElement : undefined;
            var initialElement_7 = cloneDeep(element);
            return this.customizeFormElementItem(__assign({ context: context, element: element, viewContext: viewContext, viewContextElement: viewContextElement, titleEnabled: options.titleEnabled, titleEditable: true, deleteEnabled: true }, options)).pipe(map(function (e) {
                if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
                    return {
                        el: e.args['result']
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Canceled) {
                    return {
                        el: initialElement_7
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Deleted) {
                    return {
                        el: initialElement_7,
                        event: CustomizeBarEditEventType.Deleted
                    };
                }
            }));
        }
        else if (element instanceof TextElementItem) {
            var initialElement_8 = cloneDeep(element);
            return this.customizeTextElementItem(__assign({ context: context, element: element, viewContext: viewContext, titleEnabled: options.titleEnabled, titleEditable: true, deleteEnabled: true }, options)).pipe(map(function (e) {
                if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
                    return {
                        el: e.args['result']
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Canceled) {
                    return {
                        el: initialElement_8
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Deleted) {
                    return {
                        el: initialElement_8,
                        event: CustomizeBarEditEventType.Deleted
                    };
                }
            }));
        }
        else if (element instanceof ColumnsLayoutElementItem) {
            var initialElement_9 = cloneDeep(element);
            return this.customizeColumnsElementItem(__assign({ context: context, element: element, viewContext: viewContext, titleEnabled: options.titleEnabled, titleEditable: true, deleteEnabled: true }, options)).pipe(map(function (e) {
                if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
                    return {
                        el: e.args['result']
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Canceled) {
                    return {
                        el: initialElement_9
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Deleted) {
                    return {
                        el: initialElement_9,
                        event: CustomizeBarEditEventType.Deleted
                    };
                }
            }));
        }
        else if (element instanceof SpacingElementItem) {
            var initialElement_10 = cloneDeep(element);
            return this.customizeSpacingElementItem(__assign({ context: context, element: element, viewContext: viewContext, titleEnabled: options.titleEnabled, titleEditable: true, deleteEnabled: true }, options)).pipe(map(function (e) {
                if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
                    return {
                        el: e.args['result']
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Canceled) {
                    return {
                        el: initialElement_10
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Deleted) {
                    return {
                        el: initialElement_10,
                        event: CustomizeBarEditEventType.Deleted
                    };
                }
            }));
        }
        else if (element instanceof SeparatorElementItem) {
            var initialElement_11 = cloneDeep(element);
            return this.customizeSeparatorElementItem(__assign({ context: context, element: element, viewContext: viewContext, titleEnabled: options.titleEnabled, titleEditable: true, deleteEnabled: true }, options)).pipe(map(function (e) {
                if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
                    return {
                        el: e.args['result']
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Canceled) {
                    return {
                        el: initialElement_11
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Deleted) {
                    return {
                        el: initialElement_11,
                        event: CustomizeBarEditEventType.Deleted
                    };
                }
            }));
        }
        else if (element instanceof StepsElementItem) {
            var initialElement_12 = cloneDeep(element);
            return this.customizeStepsElementItem(__assign({ context: context, element: element, viewContext: viewContext, titleEnabled: options.titleEnabled, titleEditable: true, deleteEnabled: true }, options)).pipe(map(function (e) {
                if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
                    return {
                        el: e.args['result']
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Canceled) {
                    return {
                        el: initialElement_12
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Deleted) {
                    return {
                        el: initialElement_12,
                        event: CustomizeBarEditEventType.Deleted
                    };
                }
            }));
        }
        else if (element instanceof FilterElementItem) {
            var initialElement_13 = cloneDeep(element);
            return this.customizeFilterElementItem(__assign({ context: context, element: element, viewContext: viewContext, titleEnabled: options.titleEnabled, titleEditable: true, deleteEnabled: true }, options)).pipe(map(function (e) {
                if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
                    return {
                        el: e.args['result']
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Canceled) {
                    return {
                        el: initialElement_13
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Deleted) {
                    return {
                        el: initialElement_13,
                        event: CustomizeBarEditEventType.Deleted
                    };
                }
            }));
        }
        else if (element instanceof CardLayoutElementItem) {
            var initialElement_14 = cloneDeep(element);
            return this.customizeCardElementItem(__assign({ context: context, element: element, viewContext: viewContext, titleEnabled: options.titleEnabled, titleEditable: true, deleteEnabled: true }, options)).pipe(map(function (e) {
                if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
                    return {
                        el: e.args['result']
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Canceled) {
                    return {
                        el: initialElement_14
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Deleted) {
                    return {
                        el: initialElement_14,
                        event: CustomizeBarEditEventType.Deleted
                    };
                }
            }));
        }
        else if (element instanceof StackLayoutElementItem) {
            var initialElement_15 = cloneDeep(element);
            return this.customizeStackElementItem(__assign({ context: context, element: element, viewContext: viewContext, titleEnabled: options.titleEnabled, titleEditable: true, deleteEnabled: true }, options)).pipe(map(function (e) {
                if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
                    return {
                        el: e.args['result']
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Canceled) {
                    return {
                        el: initialElement_15
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Deleted) {
                    return {
                        el: initialElement_15,
                        event: CustomizeBarEditEventType.Deleted
                    };
                }
            }));
        }
        else if (element instanceof TabsLayoutElementItem) {
            var initialElement_16 = cloneDeep(element);
            return this.customizeTabsElementItem(__assign({ context: context, element: element, viewContext: viewContext, titleEnabled: options.titleEnabled, titleEditable: true, deleteEnabled: true }, options)).pipe(map(function (e) {
                if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
                    return {
                        el: e.args['result']
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Canceled) {
                    return {
                        el: initialElement_16
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Deleted) {
                    return {
                        el: initialElement_16,
                        event: CustomizeBarEditEventType.Deleted
                    };
                }
            }));
        }
        else if (element instanceof WidgetElementItem) {
            var viewContextElement = component ? component.viewContextElement : undefined;
            var initialElement_17 = cloneDeep(element);
            return this.customizeWidget(__assign({ context: context, element: element, item: element.widget, title: element.name, titleEnabled: options.titleEnabled, titleEditable: true, visibleInput: element.visibleInput, visibleEditable: true, margin: element.margin, marginEditable: true, viewContext: viewContext, viewContextElement: viewContextElement, deleteEnabled: true, component: component }, options)).pipe(map(function (e) {
                if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
                    var newElement = cloneDeep(element);
                    newElement.name = e.args['title'];
                    newElement.widget = e.args['result'];
                    newElement.visibleInput = e.args['visible_input'];
                    newElement.margin = e.args['margin'];
                    return {
                        el: newElement
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Canceled) {
                    return {
                        el: initialElement_17
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Deleted) {
                    return {
                        el: initialElement_17,
                        event: CustomizeBarEditEventType.Deleted
                    };
                }
            }));
        }
        else if (element instanceof ImageElementItem) {
            var initialElement_18 = cloneDeep(element);
            return this.customizeImage(__assign({ context: context, element: element, viewContext: viewContext, elementUpdated: elementUpdated, titleEnabled: options.titleEnabled, deleteEnabled: true }, options)).pipe(map(function (e) {
                if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
                    return {
                        el: e.args['result']
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Canceled) {
                    return {
                        el: initialElement_18
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Deleted) {
                    return {
                        el: initialElement_18,
                        event: CustomizeBarEditEventType.Deleted
                    };
                }
            }));
        }
        else if (element instanceof IFrameElementItem) {
            var initialElement_19 = cloneDeep(element);
            return this.customizeIFrame(__assign({ context: context, element: element, viewContext: viewContext, titleEnabled: options.titleEnabled, deleteEnabled: true }, options)).pipe(map(function (e) {
                if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
                    return {
                        el: e.args['result']
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Canceled) {
                    return {
                        el: initialElement_19
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Deleted) {
                    return {
                        el: initialElement_19,
                        event: CustomizeBarEditEventType.Deleted
                    };
                }
            }));
        }
        else if (element instanceof QrCodeElementItem) {
            var initialElement_20 = cloneDeep(element);
            return this.customizeQrCode(__assign({ context: context, element: element, viewContext: viewContext, titleEnabled: options.titleEnabled, deleteEnabled: true }, options)).pipe(map(function (e) {
                if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
                    return {
                        el: e.args['result']
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Canceled) {
                    return {
                        el: initialElement_20
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Deleted) {
                    return {
                        el: initialElement_20,
                        event: CustomizeBarEditEventType.Deleted
                    };
                }
            }));
        }
        else if (element instanceof BarCodeElementItem) {
            var initialElement_21 = cloneDeep(element);
            return this.customizeBarCode(__assign({ context: context, element: element, viewContext: viewContext, titleEnabled: options.titleEnabled, deleteEnabled: true }, options)).pipe(map(function (e) {
                if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
                    return {
                        el: e.args['result']
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Canceled) {
                    return {
                        el: initialElement_21
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Deleted) {
                    return {
                        el: initialElement_21,
                        event: CustomizeBarEditEventType.Deleted
                    };
                }
            }));
        }
        else if (element instanceof ScannerElementItem) {
            var initialElement_22 = cloneDeep(element);
            var viewContextElement = component ? component.viewContextElement : undefined;
            return this.customizeScanner(__assign({ context: context, element: element, viewContext: viewContext, viewContextElement: viewContextElement, titleEnabled: options.titleEnabled, deleteEnabled: true, analyticsSource: element.analyticsName }, options)).pipe(map(function (e) {
                if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
                    return {
                        el: e.args['result']
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Canceled) {
                    return {
                        el: initialElement_22
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Deleted) {
                    return {
                        el: initialElement_22,
                        event: CustomizeBarEditEventType.Deleted
                    };
                }
            }));
        }
        else if (element instanceof AlertElementItem) {
            var initialElement_23 = cloneDeep(element);
            return this.customizeAlert(__assign({ context: context, element: element, viewContext: viewContext, titleEnabled: options.titleEnabled, deleteEnabled: true, analyticsSource: element.analyticsName }, options)).pipe(map(function (e) {
                if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
                    return {
                        el: e.args['result']
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Canceled) {
                    return {
                        el: initialElement_23
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Deleted) {
                    return {
                        el: initialElement_23,
                        event: CustomizeBarEditEventType.Deleted
                    };
                }
            }));
        }
        else if (element instanceof FileViewerElementItem) {
            var initialElement_24 = cloneDeep(element);
            return this.customizeFileViewer(__assign({ context: context, element: element, viewContext: viewContext, titleEnabled: options.titleEnabled, deleteEnabled: true }, options)).pipe(map(function (e) {
                if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
                    return {
                        el: e.args['result']
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Canceled) {
                    return {
                        el: initialElement_24
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Deleted) {
                    return {
                        el: initialElement_24,
                        event: CustomizeBarEditEventType.Deleted
                    };
                }
            }));
        }
        else if (element instanceof RangeSliderElementItem) {
            var initialElement_25 = cloneDeep(element);
            var viewContextElement = component ? component.viewContextElement : undefined;
            return this.customizeRangeSlider(__assign({ context: context, element: element, viewContext: viewContext, viewContextElement: viewContextElement, titleEnabled: options.titleEnabled, deleteEnabled: true }, options)).pipe(map(function (e) {
                if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
                    return {
                        el: e.args['result']
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Canceled) {
                    return {
                        el: initialElement_25
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Deleted) {
                    return {
                        el: initialElement_25,
                        event: CustomizeBarEditEventType.Deleted
                    };
                }
            }));
        }
        else if (element instanceof BackElementItem) {
            var initialElement_26 = cloneDeep(element);
            return this.customizeBack(__assign({ context: context, element: element, viewContext: viewContext, append: false, title: element.name, titleEnabled: options.titleEnabled, titleEditable: true, deleteEnabled: true }, options)).pipe(map(function (e) {
                if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
                    var newElement = e.args['result'];
                    newElement.name = e.args['title'];
                    return {
                        el: newElement
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Canceled) {
                    return {
                        el: initialElement_26
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Deleted) {
                    return {
                        el: initialElement_26,
                        event: CustomizeBarEditEventType.Deleted
                    };
                }
            }));
        }
        else if (element instanceof CustomElementItem) {
            var initialElement_27 = cloneDeep(element);
            return this.customizeCustom(__assign({ context: context, element: element, viewContext: viewContext, elementUpdated: elementUpdated, append: false, title: element.name, titleEnabled: options.titleEnabled, titleEditable: true, deleteEnabled: true }, options)).pipe(map(function (e) {
                if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
                    var newElement = e.args['result'];
                    newElement.name = e.args['title'];
                    return {
                        el: newElement
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Canceled) {
                    return {
                        el: initialElement_27
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Deleted) {
                    return {
                        el: initialElement_27,
                        event: CustomizeBarEditEventType.Deleted
                    };
                }
            }));
        }
        else if (element instanceof ActionGroupElementItem) {
            var initialElement_28 = cloneDeep(element);
            return this.customizeActionGroup(__assign({ context: context, element: element, viewContext: viewContext, elementUpdated: elementUpdated, append: false, title: element.name, titleEnabled: options.titleEnabled, titleEditable: true, deleteEnabled: true, visibleInput: element.visibleInput, visibleEditable: true }, options)).pipe(map(function (e) {
                if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
                    var newElement = e.args['result'];
                    return {
                        el: newElement
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Canceled) {
                    return {
                        el: initialElement_28
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Deleted) {
                    return {
                        el: initialElement_28,
                        event: CustomizeBarEditEventType.Deleted
                    };
                }
            }));
        }
        else if (element instanceof ActionDropdownElementItem) {
            var initialElement_29 = cloneDeep(element);
            return this.customizeActionDropdown(__assign({ context: context, element: element, viewContext: viewContext, elementUpdated: elementUpdated, append: false, title: element.name, titleEnabled: options.titleEnabled, titleEditable: true, deleteEnabled: true, visibleInput: element.visibleInput, visibleEditable: true }, options)).pipe(map(function (e) {
                if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
                    var newElement = e.args['result'];
                    return {
                        el: newElement
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Canceled) {
                    return {
                        el: initialElement_29
                    };
                }
                else if (e.type == CustomizeBarEditEventType.Deleted) {
                    return {
                        el: initialElement_29,
                        event: CustomizeBarEditEventType.Deleted
                    };
                }
            }));
        }
        return result;
    };
    return CustomizeBarService;
}());
export { CustomizeBarService };
