import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import fromPairs from 'lodash/fromPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { ActionService, isModelUpdateEventMatch, patchModel } from '@modules/action-queries';
import { CustomViewsStore } from '@modules/custom-views';
import { getModelAttributesByColumns, TimelineSettings, ViewContext, ViewContextElement } from '@modules/customize';
import { ListModelDescriptionDataSource } from '@modules/data-sources';
import { applyParamInput$, createFormFieldFactory, DisplayField, ParameterField } from '@modules/fields';
import { ColumnListItem, ITEM_OUTPUT, ListItem, SELECTED_ITEM_OUTPUT } from '@modules/list';
import { Model, ModelDescription } from '@modules/models';
import { View } from '@modules/views';
import { EMPTY, isSet, TypedChanges } from '@shared';

@Component({
  selector: 'app-timeline-item',
  templateUrl: './timeline-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() first: boolean;
  @Input() item: ListItem;
  @Input() modelDescription: ModelDescription;
  @Input() settings: TimelineSettings;
  @Input() dataSource: ListModelDescriptionDataSource;
  @Input() visibleColumns: DisplayField[] = [];
  @Input() inverse = false;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() selected = false;
  @Input() accentColor: string;
  @Output() select = new EventEmitter<ListItem>();
  @Output() modelUpdated = new EventEmitter<Model>();

  createField = createFormFieldFactory();
  dateColumnListItem: ColumnListItem;
  settings$ = new BehaviorSubject<TimelineSettings>(undefined);
  view: View;
  viewParameters: ParameterField[] = [];
  viewParams$: Observable<Object> = of({});
  localContext: Object = {};

  constructor(
    public el: ElementRef,
    private actionService: ActionService,
    private customViewsStore: CustomViewsStore,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.actionService.modelUpdated$.pipe(untilDestroyed(this)).subscribe(e => {
      if (isModelUpdateEventMatch(e, this.modelDescription, this.item.model)) {
        this.item.model = patchModel(this.item.model, e.model);
        this.cd.markForCheck();
        this.modelUpdated.next();
      }
    });

    this.settings$
      .pipe(
        filter(settings => !!settings),
        switchMap(settings => {
          if (!isSet(settings.cardCustomView)) {
            return of(undefined);
          }

          return this.customViewsStore.getDetail(settings.cardCustomView);
        }),
        untilDestroyed(this)
      )
      .subscribe(customView => {
        this.view = customView ? customView.view : undefined;
        this.cd.markForCheck();
        this.updateViewParameters();
        this.updateViewParams();
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<TimelineItemComponent>): void {
    if (changes.item) {
      this.localContext = {
        [ITEM_OUTPUT]: this.item.model.getAttributes()
      };
    }

    if (changes.item || changes.dataSource) {
      const column = this.dataSource
        ? this.dataSource.columns.find(item => item.name == this.settings.dateField)
        : undefined;
      this.dateColumnListItem = column
        ? {
            listItem: this.item.columns.find(i => i.column == column.name),
            column: column
          }
        : undefined;
    }

    if (changes.settings) {
      this.settings$.next(this.settings);
      this.updateViewParameters();
    }

    if (changes.settings || changes.item) {
      this.updateViewParams();
    }
  }

  onClick(e: MouseEvent) {
    this.contextElement.context.clickEvent = e;
    this.select.emit(this.item);
  }

  updateViewParameters() {
    if (!this.view) {
      this.viewParameters = [];
      this.cd.markForCheck();
      return;
    }

    const columns = this.settings.dataSource ? this.settings.dataSource.columns : [];

    this.viewParameters = columns.map(item => {
      const parameter = new ParameterField();

      parameter.name = item.name;
      parameter.verboseName = item.verboseName;
      parameter.field = item.field;
      parameter.params = item.params;

      return parameter;
    });
    this.cd.markForCheck();
  }

  updateViewParams() {
    if (!this.view) {
      this.viewParams$ = of({});
      this.cd.markForCheck();
      return;
    }

    const columns = this.settings.dataSource ? this.settings.dataSource.columns : [];
    const modelParams = getModelAttributesByColumns(this.item.model, columns);

    this.viewParams$ = combineLatest(
      this.settings.cardCustomViewMappings.map(mapping => {
        if (mapping.sourceParameterInput) {
          return applyParamInput$(mapping.sourceParameterInput, {
            context: this.context,
            contextElement: this.contextElement,
            localContext: {
              [ITEM_OUTPUT]: modelParams
            }
          }).pipe(
            map(value => {
              return {
                name: mapping.targetParameter,
                value: value !== EMPTY ? value : undefined
              };
            })
          );
        } else {
          const value = modelParams[mapping.sourceParameter];
          return of({
            name: mapping.targetParameter,
            value: value
          });
        }
      })
    ).pipe(
      map(values => {
        return fromPairs(values.map(item => [item.name, item.value]));
      })
    );
    this.cd.markForCheck();
  }
}
