import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnChanges, OnInit } from '@angular/core';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { ActionService, OutputsInfo } from '@modules/action-queries';
import { ActionDescription, ActionItem, ActionType } from '@modules/actions';
import {
  HTTP_BODY_OUTPUT,
  HTTP_CODE_OUTPUT,
  HTTP_STATUS_OUTPUT,
  SUBMIT_ERROR_OUTPUT,
  SUBMIT_RESULT_OUTPUT,
  ViewContextElement
} from '@modules/customize';
import { FieldType } from '@modules/fields';
import { CurrentEnvironmentStore, Resource } from '@modules/projects';
import { QueryService } from '@modules/queries';
import { ActionWorkflowStep, WorkflowStepType } from '@modules/workflow';
import { isSet, TypedChanges } from '@shared';

import { registerWorkflowStepComponent } from '../../../../data/workflow-step-components';
import { WorkflowEditContext } from '../../../../services/workflow-edit-context/workflow-edit.context';
import { workflowStepsToken } from '../../workflow/workflow.component';
import { WorkflowRunParams, WorkflowStepComponent } from '../base-workflow-step/base-workflow-step.component';

interface State {
  step?: ActionWorkflowStep;
  name?: string;
  icon?: string;
  type?: WorkflowStepType;
  action?: ActionItem;
  actionDescription?: ActionDescription;
  resource?: Resource;
}

function getStepStateContextAction(state: State): Object {
  return {
    type: state.type,
    icon: state.icon,
    action: state.actionDescription ? state.actionDescription.serialize() : undefined,
    inputs: state.action && state.action.inputs ? state.action.inputs.map(item => item.serialize()) : undefined
  };
}

function getStepStateContextInfo(state: State): Object {
  return {
    name: state.name,
    type: state.type,
    icon: state.actionDescription ? state.actionDescription.typeIcon : undefined
  };
}

function getStepStateContextOutputs(state: State): Object {
  return {
    name: state.name,
    actionDescription: state.actionDescription ? state.actionDescription.serialize() : undefined
  };
}

@Component({
  selector: 'app-action-workflow-step',
  templateUrl: './action-workflow-step.component.html',
  providers: [ViewContextElement],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionWorkflowStepComponent extends WorkflowStepComponent<ActionWorkflowStep, State>
  implements OnInit, OnChanges {
  outputsState: OutputsInfo;
  contextSubmitResultInit = false;
  loading = true;
  defaultTitle = 'Not specified';
  displayIcon: string;
  displayImage: string;
  titles: string[] = [];
  titlesSubscription: Subscription;

  constructor(
    private actionService: ActionService,
    private queryService: QueryService,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    @Inject(workflowStepsToken) private workflowStepsContextElement: ViewContextElement,
    contextElement: ViewContextElement,
    workflowEditContext: WorkflowEditContext,
    cd: ChangeDetectorRef
  ) {
    super(contextElement, workflowEditContext, cd);
  }

  ngOnInit() {
    this.initContext();
    this.trackRun();
    this.trackExecuteStatus();
    this.stepOnChange(this.step);
    this.trackChanges();

    this.workflowEditContext.updateCreatedElement(this.step, { contextElement: this.contextElement });
  }

  ngOnChanges(changes: TypedChanges<ActionWorkflowStepComponent>): void {
    if (changes.step) {
      if (!changes.step.firstChange) {
        this.stepOnChange(this.step);
      }
    }
  }

  getStepState(step: ActionWorkflowStep): Observable<State> {
    return this.actionService.getActionDescription(step.action).pipe(
      map(actionDescription => {
        const resource = actionDescription
          ? this.currentEnvironmentStore.resources.find(i => i.uniqueName == actionDescription.resource)
          : undefined;

        return {
          step: step,
          name: step ? step.name : undefined,
          icon: step ? step.getIcon() : undefined,
          type: step ? step.type : undefined,
          action: step ? step.action : undefined,
          actionDescription: actionDescription,
          resource: resource
        };
      })
    );
  }

  onStateUpdated(state: State) {
    if (!isEqual(getStepStateContextAction(state), getStepStateContextAction(this.state))) {
      this.updateAction(state);
    }

    if (!isEqual(getStepStateContextInfo(state), getStepStateContextInfo(this.state))) {
      this.updateContextInfo(state);
    }

    if (!isEqual(getStepStateContextOutputs(state), getStepStateContextOutputs(this.state))) {
      this.updateContextOutputs(state);
    }
  }

  initContext() {
    this.actionService
      .getActionDescription(this.step.action)
      .pipe(untilDestroyed(this))
      .subscribe(actionDescription => {
        this.contextElement.initGlobal(
          {
            uniqueName: this.step.uid,
            name: this.step.name || this.step.type,
            icon: actionDescription ? actionDescription.typeIcon : 'power',
            // allowSkip: true,
            getFieldValue: (field, outputs) => {
              return outputs[field];
            }
          },
          this.workflowStepsContextElement
        );

        if (
          actionDescription &&
          actionDescription.type == ActionType.Query &&
          actionDescription.queryAction &&
          actionDescription.queryAction.query
        ) {
          const response = this.queryService.getResponseProcessed(actionDescription.queryAction.query);

          if (isSet(response)) {
            this.contextElement.setOutputValue(SUBMIT_RESULT_OUTPUT, response);
          }
        }
      });
  }

  updateAction(state: State) {
    this.updateDisplayIcon(state);
    this.updateTitles(state);

    this.loading = false;
    this.cd.markForCheck();
  }

  updateDisplayIcon(state: State) {
    this.displayIcon = undefined;
    this.displayImage = undefined;

    if (state.resource) {
      this.displayImage = state.resource.icon;
    } else if (isSet(state.icon)) {
      this.displayIcon = state.icon;
    }

    this.cd.markForCheck();
  }

  updateTitles(state: State) {
    if (this.titlesSubscription) {
      this.titlesSubscription.unsubscribe();
      this.titlesSubscription = undefined;
    }

    this.titlesSubscription = this.actionService
      .getActionDescriptionLabel(
        state.actionDescription,
        state.action ? state.action.inputs : [],
        this.context,
        this.contextElement
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        actionDescriptionLabel => {
          this.titles = [
            ...(isSet(actionDescriptionLabel) && state.actionDescription
              ? actionDescriptionLabel.filter(item => isSet(item) && item != state.actionDescription.typeStr)
              : []),
            this.defaultTitle
          ];
          this.loading = false;
          this.cd.markForCheck();
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  updateContextInfo(state: State) {
    this.contextElement.initInfo({
      name: state.name || state.type,
      icon: state.actionDescription ? state.actionDescription.typeIcon : 'power'
    });
  }

  updateContextOutputs(state: State) {
    this.actionService
      .getActionDescriptionOutputs(state.actionDescription)
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (isEqual(result, this.outputsState)) {
          return;
        }

        this.outputsState = result;

        this.contextElement.setOutputs([
          {
            uniqueName: SUBMIT_RESULT_OUTPUT,
            name: 'Operation Result',
            icon: 'components',
            fieldType: FieldType.JSON,
            external: true,
            ...(result.outputs.length &&
              !result.arrayOutput && {
                children: result.outputs.map(item => {
                  return {
                    uniqueName: item.name,
                    name: item.verboseName,
                    icon: item.fieldDescription.icon,
                    fieldType: item.field,
                    fieldParams: item.params
                  };
                })
              })
          },
          {
            uniqueName: SUBMIT_ERROR_OUTPUT,
            name: 'Operation Error',
            icon: 'alert',
            fieldType: FieldType.Text
          },
          {
            uniqueName: HTTP_BODY_OUTPUT,
            name: 'HTTP data',
            icon: 'components',
            fieldType: FieldType.JSON
          },
          {
            uniqueName: HTTP_CODE_OUTPUT,
            name: 'HTTP code',
            icon: 'number',
            fieldType: FieldType.Number
          },
          {
            uniqueName: HTTP_STATUS_OUTPUT,
            name: 'HTTP message',
            icon: 'comments',
            fieldType: FieldType.Text
          }
        ]);
      });
  }

  onRunUpdated(event: WorkflowRunParams) {
    if (this.contextElement) {
      const result = event.success ? event.result : event.error;
      this.contextElement.setOutputValue(SUBMIT_RESULT_OUTPUT, result);
    }
  }
}

registerWorkflowStepComponent(WorkflowStepType.Action, ActionWorkflowStepComponent);
