import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import isArray from 'lodash/isArray';
import { Option as SelectOption, SelectSource } from 'ng-gxselect';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { EMPTY, fromEvent, Subject, Subscription, timer } from 'rxjs';
import { debounceTime, filter, switchMap } from 'rxjs/operators';

import { localize } from '@common/localize';
import { Option } from '@modules/field-components';
import { AutofocusDirective, coerceArray, controlValue, isSet, KeyboardEventKeyCode } from '@shared';

import '../../utils/mat-select-override';

export enum SelectSegment {
  Top = 'top',
  Middle = 'middle',
  Bottom = 'bottom'
}

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() control: FormControl;
  @Input() multiple = false;
  @Input() placeholder: string;
  @Input() emptyPlaceholder: string;
  @Input() orange = false;
  @Input() fill = false;
  @Input() small = false;
  @Input() expand = false;
  @Input() segment: SelectSegment;
  @Input() compareWith: (o1: any, o2: any) => boolean;
  @Input() source: SelectSource;
  @Input() options: Option[] = [];
  @Input() resetEnabled = false;
  @Input() searchEnabled = true;
  @Input() searchDebounce = 0;
  @Input() searchMinimumLength = 1;
  @Input() id: string;
  @Input() classes: string | string[];
  @Output() valueChange = new EventEmitter<any>();
  @ViewChild(MatSelect) matSelect: MatSelect;
  @ViewChild(AutofocusDirective) searchFocus: AutofocusDirective;

  search = '';
  searchChanged = new Subject<void>();
  searchChangedSubscription: Subscription;
  scrollSubscription: Subscription;
  selectSegments = SelectSegment;

  sourceValueLoading = false;
  sourceValueSubscription: Subscription;
  sourceCurrentOptionSubscription: Subscription;
  sourceCurrentOption: SelectOption | SelectOption[];

  staticOptionsFiltered: Option[];

  panelScrollTop: number;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.initSearch();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.placeholder = this.placeholder || localize('Choose');
    this.emptyPlaceholder = this.emptyPlaceholder || localize('Nothing found');
    this.compareWith = this.compareWith || this.defaultCompare;

    if (changes['options']) {
      this.updateStaticOptionsFiltered();
    }

    if (changes['searchDebounce']) {
      this.initSearch();
    }

    if (changes['source']) {
      this.initSource();
    }
  }

  ngAfterViewInit(): void {
    this.initScrollFix();

    // Fix for multiple select initial value display
    timer(0)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.matSelect) {
          this.matSelect['_initializeSelection']();
        }
      });
  }

  initSearch() {
    if (this.searchChangedSubscription) {
      this.searchChangedSubscription.unsubscribe();
    }

    this.searchChangedSubscription = this.searchChanged
      .pipe(debounceTime(this.searchDebounce), untilDestroyed(this))
      .subscribe(() => this.onSearch());
  }

  initSource() {
    if (!this.source) {
      return;
    }

    if (this.source) {
      this.source.config = { searchMinimumLength: this.searchMinimumLength };
    }

    if (this.sourceValueSubscription) {
      this.sourceValueSubscription.unsubscribe();
    }

    if (this.control) {
      const isSameOption = (currentOption: Option | Option[], value: any | any[]): boolean => {
        if (!currentOption) {
          return false;
        }

        if (this.multiple) {
          const currentOptions = currentOption as Option[];
          return (
            currentOptions.length == value.length &&
            currentOptions.every((item, i) => this.compareWith(item.value, value[i]))
          );
        } else {
          const currentSingleOption = currentOption as Option;
          return this.compareWith(currentSingleOption.value, value);
        }
      };

      this.sourceValueSubscription = controlValue(this.control)
        .pipe(
          filter(value => isSet(value) && !isSameOption(this.sourceCurrentOption, value)),
          switchMap(value => {
            this.sourceValueLoading = true;
            this.cd.markForCheck();
            return this.source.loadValue(value);
          }),
          untilDestroyed(this)
        )
        .subscribe(
          () => {
            this.sourceValueLoading = false;
            this.cd.markForCheck();
          },
          () => {
            this.sourceValueLoading = false;
            this.cd.markForCheck();
          }
        );
    }

    if (this.sourceCurrentOptionSubscription) {
      this.sourceCurrentOptionSubscription.unsubscribe();
    }

    this.sourceCurrentOptionSubscription = this.source.valueOption$
      .pipe(untilDestroyed(this))
      .subscribe(valueOption => {
        this.sourceCurrentOption = this.multiple ? [] : undefined;
        this.cd.detectChanges();
        this.sourceCurrentOption = valueOption;
        this.cd.markForCheck();
      });
  }

  initScroll() {
    if (!this.matSelect || !this.matSelect.panel) {
      return;
    }

    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }

    if (this.source) {
      this.initSourceScroll();
    }
  }

  initSourceScroll() {
    this.scrollSubscription = fromEvent<Event>(this.matSelect.panel.nativeElement, 'scroll')
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        const srcElement = e.srcElement as HTMLElement;
        if (srcElement.scrollTop + srcElement.offsetHeight >= srcElement.scrollHeight - 20) {
          this.source.loadMore();
        }
      });
  }

  defaultCompare(o1: any, o2: any): boolean {
    return o1 == o2;
  }

  resetSearch() {
    if (!isSet(this.search)) {
      return;
    }

    this.search = '';
    this.cd.markForCheck();

    if (this.source) {
      this.source.setSearch(this.search);
    } else {
      this.onSearch();
    }
  }

  onSearch() {
    if (this.source) {
      this.source.search(this.search);
    } else {
      this.updateStaticOptionsFiltered();
    }
  }

  updateStaticOptionsFiltered() {
    if (!this.search) {
      this.staticOptionsFiltered = this.options;
      this.cd.markForCheck();
      return;
    } else if (!this.options) {
      this.staticOptionsFiltered = [];
      this.cd.markForCheck();
      return;
    }

    this.staticOptionsFiltered = this.options.filter(item => {
      return isSet(item.name) && String(item.name).toLowerCase().includes(this.search.toLowerCase());
    });
    this.cd.markForCheck();
  }

  onOpened() {
    if (this.searchFocus) {
      this.searchFocus.focus();
    }

    this.initScroll();

    if (this.source && !this.source.loaded && !this.source.loading) {
      this.source.loadMore();
    }
  }

  onClosed() {
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }

    this.resetSearch();
  }

  onOptionSelectionChange(option: Option, e: MatOptionSelectionChange) {
    if (e.source.selected) {
      if (this.multiple) {
        this.sourceCurrentOption = [
          option,
          ...((this.sourceCurrentOption || []) as Option[]).filter(item => item !== option)
        ];
      } else {
        this.sourceCurrentOption = option;
      }
      this.cd.markForCheck();
    }
  }

  ignoreSpaceSelect(e: KeyboardEvent) {
    if (e.keyCode == KeyboardEventKeyCode.Space) {
      e.stopPropagation();
    }
  }

  get empty(): boolean {
    return this.matSelect ? this.matSelect.empty : undefined;
  }

  get triggerOption(): Option | Option[] {
    // TODO: Optimize computed property
    if (this.source) {
      if (this.multiple) {
        return this.sourceCurrentOption
          ? (this.sourceCurrentOption as Option[]).reduce<Option[]>((acc, option) => {
              if (!acc.find(item => this.compareWith(item.value, option.value))) {
                acc.push(option);
              }

              return acc;
            }, [])
          : undefined;
      } else {
        return this.sourceCurrentOption ? (this.sourceCurrentOption as Option) : undefined;
      }
    } else {
      if (this.multiple) {
        const currentValue = isSet(this.control.value) ? coerceArray(this.control.value) : [];
        return this.options.filter(item => currentValue.some(value => this.compareWith(item.value, value)));
      } else {
        return this.options.find(item => this.compareWith(item.value, this.control.value));
      }
    }
  }

  get triggerIcon(): string {
    const triggerOption = this.triggerOption;

    if (!triggerOption) {
      return;
    } else if (isArray(triggerOption)) {
      return;
    } else {
      return (triggerOption as Option).icon;
    }
  }

  get triggerValue(): string {
    // return this.matSelect ? this.matSelect.triggerValue : undefined;

    const triggerOption = this.triggerOption;

    if (!triggerOption) {
      return;
    } else if (isArray(triggerOption)) {
      return (triggerOption as Option[]).map(item => item.name).join(',');
    } else {
      return (triggerOption as Option).name;
    }
  }

  get isControlSet(): boolean {
    if (!this.control) {
      return false;
    }

    return this.control.value !== null && this.control.value !== undefined;
  }

  open() {
    if (this.matSelect) {
      this.matSelect.open();
    }
  }

  initScrollFix() {
    if (!this.matSelect) {
      return;
    }

    this.matSelect.openedChange
      .pipe(
        switchMap(open => {
          if (open && this.matSelect.panel) {
            return fromEvent<Event>(this.matSelect.panel.nativeElement, 'scroll');
          } else {
            this.panelScrollTop = undefined;
            return EMPTY;
          }
        }),
        untilDestroyed(this)
      )
      .subscribe(event => {
        this.panelScrollTop = (event.target as HTMLElement).scrollTop;
      });

    this.matSelect.selectionChange.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.matSelect.panel && isSet(this.panelScrollTop)) {
        this.matSelect.panel.nativeElement.scrollTop = this.panelScrollTop;
      }
    });
  }
}
