<app-external-fonts [fonts]="externalFonts"></app-external-fonts>

<div
  class="view-editor-view"
  [class.view-editor-view_add-layer]="editorContext.isCreateTool$ | async"
  [class.view-editor-view_pristine]="!fills.length && !borders.length && !boxShadow"
  #root_element
>
  <div
    class="view-editor-view__content"
    [class.view-editor-view__content_clip]="view.clipContent"
    [class.view-editor-view__content_dark]="dark"
    [style.border-radius]="borderRadius"
    [style.opacity]="view.opacity"
    [style.box-shadow]="boxShadow"
    [style.cursor]="view.getCursor() || null"
    #view_element
  >
    <app-layer-interaction
      *ngFor="let item of view.interactions"
      [interaction]="item"
      [element]="view_element"
      [context]="viewContext"
      [contextElement]="contextElement"
      [analyticsSource]="analyticsSource"
    ></app-layer-interaction>

    <div
      *ngFor="let fill of fills; trackBy: trackFillFn"
      class="view-editor-view__fill"
      [class.view-editor-view__fill_inner]="fill.transform"
      [style.background]="fill.transform ? null : fill.background"
      [style.border-radius]="borderRadius"
      [style.opacity]="(fill.opacity | appIsSet) ? fill.opacity : null"
    >
      <span
        *ngIf="fill.transform"
        class="view-editor-view__fill-inner"
        [style.background]="fill.background"
        [style.width]="fill.width"
        [style.height]="fill.height"
        [style.transform]="fill.transform"
      ></span>

      <span
        *ngIf="fill.icon"
        class="view-editor-view__fill-icon"
        [style.color]="fill.icon.color"
        [style.font-size.px]="fill.icon.size"
        [ngClass]="'icon-' + fill.icon.icon"
      ></span>
    </div>

    <div
      class="view-editor-view__layers"
      (mousedown)="onMouseDown($event)"
      (mouseenter)="editorContext.hoverView$.next(true)"
      (mouseleave)="editorContext.hoverView$.next(false)"
      #container_element
    >
      <app-auto-layer
        *ngFor="let item of displayItems; let i = index; trackBy: trackLayerFn"
        [layer]="item"
        [container]="view.layers"
        [containerElement]="container_element"
        [frame]="view.frame"
        [viewContext]="viewContext"
        [analyticsSource]="analyticsSource"
        (layerDelete)="deleteLayer(item)"
      ></app-auto-layer>
    </div>

    <div
      *ngFor="let border of borders"
      class="view-editor-view__fill view-editor-view__fill_no-interaction"
      [style.border]="border.border"
      [style.top.px]="border.position"
      [style.right.px]="border.position"
      [style.bottom.px]="border.position"
      [style.left.px]="border.position"
      [style.border-radius]="border.borderRadius"
    ></div>

    <div class="view-editor-view__tips view-editor-viewport-tips" [class.view-editor-viewport-tips_dark]="dark">
      <div class="view-editor-viewport-tip">
        Use toolbar at the top to draw new component in canvas
      </div>

      <div class="view-editor-viewport-list">
        <div class="view-editor-viewport-list__title">Import components</div>

        <div class="view-editor-viewport-list__item" (click)="openCustomViewTemplates()">
          <div
            class="view-editor-viewport-list__item-icon view-editor-viewport-list__item-icon_purple-gradient icon-blocks"
          ></div>
          <div class="view-editor-viewport-list__item-content">
            <div class="view-editor-viewport-list__item-label">Start from Template</div>
            <div class="view-editor-viewport-list__item-description">
              <ng-container *ngIf="templatesApprox | appIsSet">{{ templatesApprox }}+ components</ng-container>
              <ng-container *ngIf="!(templatesApprox | appIsSet)">Components library</ng-container>
            </div>
          </div>
        </div>

        <div class="view-editor-viewport-list__item" (click)="importFigmaNode.emit()">
          <div class="view-editor-viewport-list__item-icon view-editor-viewport-list__item-icon_figma"></div>
          <div class="view-editor-viewport-list__item-label">Import from Figma</div>
        </div>

        <div class="view-editor-viewport-list__item" (click)="importSketchFile.emit()">
          <div class="view-editor-viewport-list__item-icon view-editor-viewport-list__item-icon_sketch"></div>
          <div class="view-editor-viewport-list__item-label">Import from Sketch</div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *appViewEditorViewportOverlay="{ interactive: true }">
    <div
      class="view-editor-view__label"
      [class.view-editor-view__label_no-interaction]="editorContext.customizingGradient$ | async"
    >
      <div class="view-editor-label" (click)="onLabelClick($event)">
        <span class="view-editor-label__icon icon-canvas"></span>
        <span class="view-editor-label__label">Canvas</span>
      </div>
    </div>

    <app-view-editor-bounds
      class="view-editor-view__bounds"
      [class.view-editor-view__bounds_active]="
        (editorContext.customizingView$ | async) && !(editorContext.customizingGradient$ | async)
      "
      [frame]="view.frame"
      [constrainProportions]="!!view.constrainProportion"
      [active]="editorContext.customizingView$ | async"
      [resizeHorizontal]="editorContext.customizingView$ | async"
      [resizeVertical]="editorContext.customizingView$ | async"
      (resizeStarted)="onResizeStarted()"
      (resizeFinished)="onResizeFinished()"
      (updateFrame)="onResizeUpdateFrame($event)"
    ></app-view-editor-bounds>
  </ng-container>
</div>
