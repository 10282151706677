var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, OnDestroy, OnInit, QueryList, Renderer2 } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { Power2, TimelineMax } from 'gsap';
import concat from 'lodash/concat';
import values from 'lodash/values';
import moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { BasePopupComponent } from '@common/popups';
import { MessageUserActivity, UserActivityListStore, UserActivityService, UserActivityType } from '@modules/activities';
import { CustomizeService, TintStyle } from '@modules/customize';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { TimelinePopupFooterComponent } from '../timeline-popup-footer/timeline-popup-footer.component';
var TimelinePopupComponent = /** @class */ (function () {
    function TimelinePopupComponent(customizeService, renderer2, userActivityService, popupComponent, router, userActivityListStore, currentProjectStore, currentEnvironmentStore, cd) {
        this.customizeService = customizeService;
        this.renderer2 = renderer2;
        this.userActivityService = userActivityService;
        this.popupComponent = popupComponent;
        this.router = router;
        this.userActivityListStore = userActivityListStore;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.cd = cd;
        this.baseParams = {};
        this.closeObsEvent = new EventEmitter();
        this.separator = new QueryList();
        this.params = {};
        this.initItems = false;
        this.sendMessage = false;
        this.newMessages = false;
        this.loadingItems = true;
        this.error = undefined;
        this.items = [];
        this.showAnimation = true;
        this.filter = {
            messages: [UserActivityType.Message],
            activity: [
                UserActivityType.DashboardList,
                UserActivityType.ModelList,
                UserActivityType.ModelDetail,
                UserActivityType.ModelCreate,
                UserActivityType.ModelUpdate,
                UserActivityType.ModelDelete,
                UserActivityType.ModelMassDelete,
                UserActivityType.ModelMassEdit
            ]
        };
        this.showPopover = false;
        this.formFilter = new FormGroup({
            messages: new FormControl(true),
            activity: new FormControl(true)
        });
        this.tlVisible = new TimelineMax();
        this.tintStyles = TintStyle;
    }
    TimelinePopupComponent.prototype.trackByFn = function (index, item) {
        return item.item.dateAdd;
    };
    TimelinePopupComponent.prototype.resetFilter = function () {
        this.showPopover = false;
        this.formFilter.reset({
            messages: true,
            activity: true
        });
    };
    TimelinePopupComponent.prototype.hasFilters = function () {
        return values(this.formFilter.value).some(function (item) { return item === false; });
    };
    TimelinePopupComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.closeObs) {
            this.closeObs.pipe(untilDestroyed(this)).subscribe(function () {
                _this.close();
            });
        }
        // this.customizeService.enabled$.pipe(untilDestroyed(this)).subscribe(value => {
        //   if (value) {
        //     this.close();
        //   }
        // });
        this.params = __assign({}, this.baseParams, { order_by: '-date_add', activity_type_in: concat(this.filter.messages, this.filter.activity).join() });
        this.userActivityListStore.projectName = this.currentProjectStore.instance.uniqueName;
        this.userActivityListStore.environmentName = this.currentEnvironmentStore.instance.uniqueName;
        this.userActivityListStore.params = this.params;
        this.userActivityListStore.reset();
        this.userActivityListStore.getNext();
        this.userActivityListStore.items$
            .pipe(map(function (value) {
            if (value) {
                var date_1;
                return value.slice().reverse().map(function (item) {
                    if (date_1 === undefined) {
                        date_1 = moment(item.dateAdd).format('LL');
                        return {
                            item: item,
                            firstDayActivity: true
                        };
                    }
                    else {
                        if (moment(item.dateAdd).format('LL') !== date_1) {
                            date_1 = moment(item.dateAdd).format('LL');
                            return {
                                item: item,
                                firstDayActivity: true
                            };
                        }
                        else {
                            return {
                                item: item,
                                firstDayActivity: false
                            };
                        }
                    }
                });
            }
            else {
                return undefined;
            }
        }))
            .subscribe(function (value) {
            _this.items = value;
            if (_this.items === undefined) {
                return;
            }
            _this.loadingItems = false;
            _this.cd.detectChanges();
            _this.setFormBottomPadding();
            if (!_this.initItems) {
                _this.initItems = true;
                setTimeout(function () {
                    _this.scrollHeight = _this.sidePopup.nativeElement.scrollHeight;
                    _this.sidePopup.nativeElement.scrollTo(0, _this.sidePopup.nativeElement.scrollHeight);
                    _this.setSeparators();
                }, 500);
            }
            else if (_this.sendMessage) {
                _this.sendMessage = false;
                _this.scrollHeight = _this.sidePopup.nativeElement.scrollHeight;
                _this.sidePopup.nativeElement.scrollTo(0, _this.sidePopup.nativeElement.scrollHeight);
                _this.setSeparators();
            }
            else if (_this.newMessages) {
                _this.newMessages = false;
                if (_this.scrollHeight ===
                    _this.sidePopup.nativeElement.scrollTop + _this.sidePopup.nativeElement.offsetHeight) {
                    _this.scrollHeight = _this.sidePopup.nativeElement.scrollHeight;
                    _this.sidePopup.nativeElement.scrollTo(0, _this.sidePopup.nativeElement.scrollHeight);
                }
                _this.setSeparators();
            }
            else {
                _this.sidePopup.nativeElement.scrollTo(0, _this.sidePopup.nativeElement.scrollHeight - _this.scrollHeight);
                _this.scrollHeight = _this.sidePopup.nativeElement.scrollHeight;
                _this.setSeparators();
            }
        });
        this.userActivityService
            .subscribeUserActivities(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, this.params['object_type'], this.params['object_id'])
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            _this.newMessages = true;
            _this.userActivityListStore.prependItems([value]);
            _this.cd.markForCheck();
        });
    };
    TimelinePopupComponent.prototype.ngOnDestroy = function () { };
    TimelinePopupComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.formFilter.valueChanges
            .pipe(debounceTime(300), switchMap(function (value) {
            var activity_type_in = [];
            if (value.messages) {
                activity_type_in.push(_this.filter.messages);
            }
            if (value.activity) {
                activity_type_in.push(_this.filter.activity);
            }
            var params = __assign({}, _this.params, { activity_type_in: activity_type_in.length === 0 ? 'void' : activity_type_in.join() });
            _this.loadingItems = true;
            _this.error = undefined;
            _this.cd.markForCheck();
            _this.userActivityListStore.params = params;
            _this.userActivityListStore.reset();
            return _this.userActivityListStore.getNext();
        }), untilDestroyed(this))
            .subscribe(function () {
            _this.loadingItems = false;
            setTimeout(function () {
                _this.scrollHeight = _this.sidePopup.nativeElement.scrollHeight;
                _this.sidePopup.nativeElement.scrollTo(0, _this.sidePopup.nativeElement.scrollHeight);
                _this.setSeparators();
            }, 500);
            _this.cd.markForCheck();
        }, function (error) {
            _this.loadingItems = false;
            _this.error = error;
            _this.cd.markForCheck();
        });
        this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationStart; }), untilDestroyed(this))
            .subscribe(function () {
            _this.close();
        });
        this.show();
    };
    TimelinePopupComponent.prototype.setFormBottomPadding = function () {
        if (this.formHeight === undefined) {
            this.formHeight = this.footerElement.nativeElement.offsetHeight;
            this.cd.detectChanges();
        }
        else if (this.footerElement.nativeElement.offsetHeight !== this.formHeight) {
            if (this.sidePopup.nativeElement.scrollHeight ===
                this.sidePopup.nativeElement.scrollTop + this.sidePopup.nativeElement.offsetHeight) {
                this.formHeight = this.footerElement.nativeElement.offsetHeight;
                this.cd.detectChanges();
                this.sidePopup.nativeElement.scrollTo(0, this.sidePopup.nativeElement.scrollHeight);
            }
            else {
                this.formHeight = this.footerElement.nativeElement.offsetHeight;
                this.cd.detectChanges();
            }
        }
    };
    TimelinePopupComponent.prototype.popoverToggle = function () {
        this.showPopover = !this.showPopover;
    };
    TimelinePopupComponent.prototype.submit = function (event) {
        var _this = this;
        this.sendMessage = true;
        this.cd.markForCheck();
        this.userActivityService
            .createInstance(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, event.activity, event.mentions)
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.footer.reset();
            _this.setSeparators();
            _this.sendMessage = false;
            _this.footer.focus();
            _this.cd.markForCheck();
        }, function () {
            _this.sendMessage = false;
            _this.footer.focus();
            _this.cd.markForCheck();
        });
    };
    TimelinePopupComponent.prototype.show = function () {
        var _this = this;
        this.tlVisible
            .fromTo(this.sidePopupWrap.nativeElement, 0.6, {
            xPercent: 100
        }, {
            xPercent: 0,
            ease: Power2.easeOut
        })
            .add(function () {
            _this.showAnimation = false;
            _this.footer.focus();
            _this.cd.markForCheck();
            fromEvent(_this.sidePopup.nativeElement, 'wheel')
                .pipe(untilDestroyed(_this))
                .subscribe(function () {
                if (_this.sidePopup.nativeElement.scrollHeight !== _this.scrollHeight) {
                    _this.scrollHeight = _this.sidePopup.nativeElement.scrollHeight;
                }
                _this.setSeparators();
                var scrollTop = _this.sidePopup.nativeElement.scrollTop;
                if (scrollTop <= 0 && !_this.loadingItems) {
                    _this.loadingItems = true;
                    _this.userActivityListStore.getNext();
                }
            });
        });
    };
    TimelinePopupComponent.prototype.setSeparators = function () {
        var _this = this;
        var topSeparators = this.separator.filter(function (item) {
            return item.nativeElement.getBoundingClientRect().top <=
                _this.header.nativeElement.getBoundingClientRect().height + item.nativeElement.getBoundingClientRect().height / 2;
        });
        var bottomSeparators = this.separator.filter(function (item) { return item.nativeElement.getBoundingClientRect().top > _this.header.nativeElement.getBoundingClientRect().height; });
        topSeparators.forEach(function (item, index) {
            if (index === topSeparators.length - 1) {
                _this.renderer2.setStyle(item.nativeElement, 'opacity', 1);
            }
            else {
                _this.renderer2.setStyle(item.nativeElement, 'opacity', 0);
            }
        });
        bottomSeparators.forEach(function (item) {
            _this.renderer2.setStyle(item.nativeElement, 'opacity', 1);
        });
    };
    TimelinePopupComponent.prototype.close = function () {
        var _this = this;
        this.closeObsEvent.emit();
        this.tlVisible
            .clear()
            .set(this.closeButton.nativeElement, {
            pointerEvents: 'none'
        }, 0)
            .to(this.sidePopupWrap.nativeElement, 0.4, {
            xPercent: 100,
            ease: Power2.easeOut
        }, 0)
            .add(function () {
            _this.popupComponent.close();
        });
    };
    return TimelinePopupComponent;
}());
export { TimelinePopupComponent };
