import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { NotificationService } from '@common/notifications';
import { ServerRequestError } from '@modules/api';
import { TintStyle } from '@modules/customize';
import { defaultSignUpLayout } from '@modules/domain';
import { MetaService } from '@modules/meta';
import { RoutingService } from '@modules/routing';
import { CurrentUserStore, UserService } from '@modules/users';
import { isSet } from '@shared';
var ChangeEmailCompleteComponent = /** @class */ (function () {
    function ChangeEmailCompleteComponent(activatedRoute, userService, currentUserStore, notificationService, routing, metaService, cd) {
        this.activatedRoute = activatedRoute;
        this.userService = userService;
        this.currentUserStore = currentUserStore;
        this.notificationService = notificationService;
        this.routing = routing;
        this.metaService = metaService;
        this.cd = cd;
        this.loading = false;
        this.domainLoading = false;
        this.defaultLayout = defaultSignUpLayout;
        this.externalFonts = [];
        this.tintStyles = TintStyle;
    }
    ChangeEmailCompleteComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.metaService.set({ title: 'Email change' });
        this.domainLoading = true;
        this.cd.markForCheck();
        this.activatedRoute.data.pipe(untilDestroyed(this)).subscribe(function (data) {
            _this.domain = data.domain;
            _this.externalFonts = _this.getExternalFonts(_this.domain);
            _this.domainLoading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.domainLoading = false;
            _this.cd.markForCheck();
        });
        this.confirmEmailChange();
    };
    ChangeEmailCompleteComponent.prototype.confirmEmailChange = function () {
        var _this = this;
        var code = this.activatedRoute.snapshot.params['code'];
        this.loading = true;
        this.cd.markForCheck();
        this.userService
            .changeEmailConfirm(code)
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.currentUserStore.getFirst(true);
            _this.notificationService.success('Email changed', "Email was successfully changed from " + result.oldEmail + " to " + result.newEmail);
            _this.routing.navigate(_this.homeLink);
        }, function (error) {
            if (error instanceof ServerRequestError && error.errors.length) {
                _this.error = error.errors[0];
            }
            else {
                _this.error = String(error);
            }
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    ChangeEmailCompleteComponent.prototype.ngOnDestroy = function () { };
    ChangeEmailCompleteComponent.prototype.getExternalFonts = function (domain) {
        if (!domain) {
            return [];
        }
        return [domain.signUpFontRegular, domain.signUpFontHeading].filter(function (item) { return isSet(item); });
    };
    Object.defineProperty(ChangeEmailCompleteComponent.prototype, "layout", {
        get: function () {
            return this.domain && isSet(this.domain.signUpLayout) ? this.domain.signUpLayout : this.defaultLayout;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChangeEmailCompleteComponent.prototype, "isWhiteLabel", {
        get: function () {
            return this.domain && this.domain.whiteLabel;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChangeEmailCompleteComponent.prototype, "homeLink", {
        get: function () {
            return ['/'];
        },
        enumerable: true,
        configurable: true
    });
    return ChangeEmailCompleteComponent;
}());
export { ChangeEmailCompleteComponent };
