import { ChangeDetectionStrategy, Component, Injector, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';

import { ActionControllerService } from '@modules/action-queries';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { HOVER_OUTPUT, PRESSED_OUTPUT } from '@modules/list';
import { ActionLayerInteraction, LayerInteraction, LayerInteractionType } from '@modules/views';
import { TypedChanges } from '@shared';

@Component({
  selector: 'app-layer-interaction',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayerInteractionComponent implements OnInit, OnDestroy, OnChanges {
  @Input() interaction: LayerInteraction;
  @Input() element: HTMLElement;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() analyticsSource: string;

  submitLoading = false;

  constructor(private actionControllerService: ActionControllerService, private injector: Injector) {}

  ngOnInit(): void {
    this.initInteraction(this.interaction);
  }

  initInteraction(interaction: LayerInteraction) {
    if (interaction instanceof ActionLayerInteraction) {
      const action = interaction.action;

      if (interaction.type == LayerInteractionType.Click) {
        fromEvent(this.element, 'click')
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            if (!action) {
              return;
            }

            this.actionControllerService
              .execute(action, {
                context: this.context,
                contextElement: this.contextElement,
                injector: this.injector,
                analyticsSource: this.analyticsSource
              })
              .subscribe();
          });
      }
    } else {
      if (interaction.type == LayerInteractionType.HoverOutput) {
        merge(
          fromEvent(this.element, 'mouseover').pipe(map(() => true)),
          fromEvent(this.element, 'mouseout').pipe(map(() => false))
        )
          .pipe(untilDestroyed(this))
          .subscribe(value => {
            this.contextElement.patchOutputValues({
              [HOVER_OUTPUT]: value
            });
          });
      } else if (interaction.type == LayerInteractionType.PressedOutput) {
        merge(
          fromEvent(this.element, 'mousedown').pipe(map(() => true)),
          fromEvent(window.document, 'mouseup').pipe(map(() => false))
        )
          .pipe(untilDestroyed(this))
          .subscribe(value => {
            this.contextElement.patchOutputValues({
              [PRESSED_OUTPUT]: value
            });
          });
      }
    }
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<LayerInteractionComponent>): void {}
}
