import { FormControl, FormGroup, Validators } from '@angular/forms';

import { TableGroup } from '@modules/customize';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { isSet } from '@shared';

export class TableGroupControl extends FormGroup {
  instance: TableGroup;

  controls: {
    value: FormControl;
    name: FormControl;
    color: FormControl;
    opened_initial: FormControl;
    visible_input: FieldInputControl;
  };

  constructor(value?: TableGroup) {
    super({
      value: new FormControl(value && isSet(value.value) ? value.value : ''),
      name: new FormControl(value && isSet(value.name) ? value.name : ''),
      color: new FormControl(value && isSet(value.color) ? value.color : ''),
      opened_initial: new FormControl(value && isSet(value.openedInitial) ? value.openedInitial : true),
      visible_input: new FieldInputControl(
        value && value.visibleInput ? value.visibleInput.serialize() : { name: 'value' }
      )
    });
  }

  deserialize(value: TableGroup) {
    this.instance = value;

    this.controls.value.patchValue(value.value);
    this.controls.name.patchValue(value.name);
    this.controls.color.patchValue(value.color);
    this.controls.opened_initial.patchValue(value.openedInitial);
    this.controls.visible_input.patchValue(value.visibleInput ? value.visibleInput.serialize() : {});
  }

  serialize(): TableGroup {
    if (!this.instance) {
      this.instance = new TableGroup();
      this.instance.generateUid();
    }

    const result = this.instance;

    result.value = this.controls.value.value;
    result.name = this.controls.name.value;
    result.color = this.controls.color.value;
    result.openedInitial = this.controls.opened_initial.value;

    if (this.controls.visible_input.value) {
      result.visibleInput = new Input().deserialize(this.controls.visible_input.value);
    } else {
      result.visibleInput = undefined;
    }

    return result;
  }
}
