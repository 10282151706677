var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { DomainStore } from '@modules/domain';
import { FieldType, getFieldDescriptionByType } from '@modules/fields';
import { ProjectApiService } from '@modules/project-api';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { QueryPagination } from '@modules/queries';
import { CurrentUserStore } from '@modules/users';
import { controlValue, EMPTY, isSet, objectGet, objectSet } from '@shared';
var pagePaginationTokens = [
    {
        name: ['paging', 'page'].join('.'),
        field: FieldType.Number,
        label: 'page number',
        icon: 'number',
        defaultValue: 1,
        reference: QueryPagination.Page
    },
    {
        name: ['paging', 'limit'].join('.'),
        field: FieldType.Number,
        label: 'per page limit',
        icon: 'number',
        defaultValue: 20,
        reference: QueryPagination.Page
    }
];
var offsetPaginationTokens = [
    {
        name: ['paging', 'offset'].join('.'),
        field: FieldType.Number,
        label: 'pagination offset',
        icon: 'number',
        defaultValue: 0,
        reference: QueryPagination.Offset
    },
    {
        name: ['paging', 'limit'].join('.'),
        field: FieldType.Number,
        label: 'per page limit',
        icon: 'number',
        defaultValue: 20,
        reference: QueryPagination.Offset
    }
];
var cursorPaginationTokens = [
    {
        name: ['paging', 'cursor_next'].join('.'),
        label: 'cursor next',
        icon: 'number',
        reference: QueryPagination.Cursor
    },
    {
        name: ['paging', 'cursor_prev'].join('.'),
        label: 'cursor previous',
        icon: 'number',
        reference: QueryPagination.Cursor
    },
    {
        name: ['paging', 'limit'].join('.'),
        field: FieldType.Number,
        label: 'per page limit',
        icon: 'number',
        defaultValue: 20,
        reference: QueryPagination.Cursor
    }
];
var sortingTokens = [
    {
        name: 'sorting.field',
        field: FieldType.Text,
        label: 'sorting field',
        icon: 'text'
    },
    {
        name: 'sorting.asc',
        field: FieldType.Boolean,
        label: 'is sorting ascending',
        icon: 'filter_down'
    }
    // {
    //   name: 'sorting.desc',
    //   field: FieldType.Boolean,
    //   label: 'sorting descending',
    //   icon: 'filter_up'
    // }
];
var QueryBuilderContext = /** @class */ (function () {
    function QueryBuilderContext(currentUserStore, currentProjectStore, currentEnvironmentStore, domainStore, apiService, projectApiService) {
        this.currentUserStore = currentUserStore;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.domainStore = domainStore;
        this.apiService = apiService;
        this.projectApiService = projectApiService;
        this.paginationTokens = false;
        this.sortingTokens = false;
        this.currentUserTokens = true;
        this.useFileObjects = false;
        this._tokens = new BehaviorSubject([]);
        this._systemTokens = new BehaviorSubject([]);
        this._tokenValues = new BehaviorSubject({});
        this._lastExecutedQuery = new BehaviorSubject(undefined);
        this._lastExecutedResponse = new BehaviorSubject(undefined);
        this.inputs = {};
        this._state = new BehaviorSubject({});
        this.updateSystemTokens();
    }
    QueryBuilderContext.prototype.ngOnDestroy = function () { };
    QueryBuilderContext.prototype.init = function (form, currentUserTokens) {
        var _this = this;
        this.form = form;
        this.currentUserTokens = currentUserTokens;
        if (this.form) {
            combineLatest(controlValue(this.form.controls.pagination), controlValue(this.form.controls.sorting), this.currentUserStore.get(), this.domainStore.get(), this.currentProjectStore.instance$)
                .pipe(delay(0), untilDestroyed(this))
                .subscribe(function () {
                _this.updateSystemTokens();
            });
        }
    };
    Object.defineProperty(QueryBuilderContext.prototype, "tokens", {
        get: function () {
            return this._tokens.value;
        },
        set: function (value) {
            this._tokens.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QueryBuilderContext.prototype, "tokens$", {
        get: function () {
            return this._tokens.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QueryBuilderContext.prototype, "systemTokens", {
        get: function () {
            return this._systemTokens.value;
        },
        set: function (value) {
            this._systemTokens.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QueryBuilderContext.prototype, "systemTokens$", {
        get: function () {
            return this._systemTokens.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QueryBuilderContext.prototype, "tokenValues", {
        get: function () {
            return this._tokenValues.value;
        },
        set: function (value) {
            this._tokenValues.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QueryBuilderContext.prototype, "tokenValues$", {
        get: function () {
            return this._tokenValues.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QueryBuilderContext.prototype, "lastExecutedQuery", {
        get: function () {
            return this._lastExecutedQuery.value;
        },
        set: function (value) {
            this._lastExecutedQuery.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QueryBuilderContext.prototype, "lastExecutedQuery$", {
        get: function () {
            return this._lastExecutedQuery.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QueryBuilderContext.prototype, "lastExecutedResponse", {
        get: function () {
            return this._lastExecutedResponse.value;
        },
        set: function (value) {
            this._lastExecutedResponse.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QueryBuilderContext.prototype, "lastExecutedResponse$", {
        get: function () {
            return this._lastExecutedResponse.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QueryBuilderContext.prototype, "state", {
        get: function () {
            return this._state.value;
        },
        set: function (value) {
            this._state.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QueryBuilderContext.prototype, "state$", {
        get: function () {
            return this._state.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    QueryBuilderContext.prototype.setState = function (state) {
        this._state.next(__assign({}, this._state.value, state));
    };
    QueryBuilderContext.prototype.getPaginationTokens = function () {
        if (!this.form) {
            return;
        }
        if (this.form.controls.pagination.value == QueryPagination.Page) {
            return pagePaginationTokens;
        }
        else if (this.form.controls.pagination.value == QueryPagination.Offset) {
            return offsetPaginationTokens;
        }
        else if (this.form.controls.pagination.value == QueryPagination.Cursor) {
            return cursorPaginationTokens;
        }
    };
    QueryBuilderContext.prototype.updateSystemTokens = function () {
        var result = [];
        var paginationTokens = this.getPaginationTokens();
        var user = this.currentUserStore.instance;
        var project = this.currentProjectStore.instance;
        var environment = this.currentEnvironmentStore.instance;
        var domain = this.domainStore.instance;
        if (paginationTokens) {
            result.push({
                name: undefined,
                label: 'Pagination',
                children: paginationTokens
            });
        }
        if (this.form && this.form.controls.sorting.value) {
            result.push({
                name: undefined,
                label: 'Sorting',
                children: sortingTokens
            });
        }
        if (domain) {
            result.push({
                name: undefined,
                label: 'SSO credentials',
                children: domain.ssoSettings
                    .map(function (item) {
                    var context = item.getSecretTokenContext();
                    if (!context) {
                        return;
                    }
                    return {
                        name: ['sso', item.uid.replace(/-/g, ''), context.name].join('.'),
                        label: item.name + " " + context.label,
                        icon: 'key',
                        data: {
                            secret: true
                        }
                    };
                })
                    .filter(function (item) { return item; })
            });
        }
        if (this.currentUserTokens && user && environment) {
            result.push({
                name: undefined,
                label: 'Current User',
                children: [
                    {
                        name: ['user', 'uid'].join('.'),
                        label: 'ID',
                        defaultValue: environment.user ? environment.user.uid : undefined,
                        resetToDefault: true,
                        icon: 'key'
                    },
                    {
                        name: ['user', 'email'].join('.'),
                        label: 'email',
                        defaultValue: user.email,
                        resetToDefault: true,
                        icon: 'text'
                    },
                    {
                        name: ['user', 'token'].join('.'),
                        label: 'token',
                        defaultValue: this.apiService.getAccessToken() ? this.apiService.getAccessToken() : undefined,
                        resetToDefault: true,
                        icon: 'key'
                    },
                    {
                        name: ['user', 'project_token'].join('.'),
                        label: 'project token',
                        defaultValue: this.projectApiService.getAccessToken() ? this.projectApiService.getAccessToken() : undefined,
                        resetToDefault: true,
                        icon: 'key'
                    },
                    {
                        name: ['user', 'properties'].join('.'),
                        field: FieldType.JSON,
                        label: 'properties',
                        defaultValue: environment.user ? environment.user.properties : undefined,
                        icon: 'components'
                    },
                    {
                        name: ['user', 'group', 'uid'].join('.'),
                        label: 'team ID',
                        defaultValue: environment.group ? environment.group.uid : undefined,
                        resetToDefault: true,
                        icon: 'key'
                    },
                    {
                        name: ['user', 'group', 'properties'].join('.'),
                        field: FieldType.JSON,
                        label: 'team properties',
                        defaultValue: environment.group ? environment.group.properties : undefined,
                        icon: 'components'
                    },
                    {
                        name: ['project', 'unique_name'].join('.'),
                        label: 'project unique name',
                        defaultValue: this.currentProjectStore.instance.uniqueName,
                        fixedValue: true,
                        icon: 'key'
                    },
                    {
                        name: ['env', 'unique_name'].join('.'),
                        label: 'environment unique name',
                        defaultValue: this.currentEnvironmentStore.instance.uniqueName,
                        fixedValue: true,
                        icon: 'key'
                    }
                ]
            });
        }
        result.push({
            name: undefined,
            label: 'Helpers',
            children: [
                {
                    name: ['jet', 'uuid'].join('.'),
                    label: "generate unique UUID",
                    icon: 'key'
                }
            ]
        });
        this._systemTokens.next(result);
    };
    QueryBuilderContext.prototype.getTokenValues = function (save) {
        var _this = this;
        // TODO: Remove deprecated tokens
        var compatibility = {
            'paging.page': 'page',
            'paging.limit': 'limit',
            'paging.offset': 'offset',
            'paging.cursor_prev': 'cursor_first',
            'paging.cursor_next': 'cursor_last',
            'user.uid': 'user_uid',
            'user.email': 'user_email',
            'user.token': 'user_token',
            'user.properties': 'user_properties',
            'user.group.uid': 'user_group_uid',
            'user.group.properties': 'user_group_properties',
            'sorting.field': 'sorting_field',
            'sorting.asc': 'sorting_asc',
            'sorting.desc': 'sorting_desc'
        };
        var result = {
            params: {}
        };
        var tokens = this.tokens.concat(this.systemTokens);
        var iterToken = function (token) {
            if (token.children) {
                token.children.forEach(function (item) { return iterToken(item); });
            }
            else if (token.fixedValue || (save && token.resetToDefault)) {
                objectSet(result, token.name, token.value || token.defaultValue);
            }
            else {
                var fieldDescription = getFieldDescriptionByType(token.field);
                var value = objectGet(_this.tokenValues, token.name, token.defaultValue);
                if (value !== EMPTY) {
                    if (fieldDescription.deserializeValue) {
                        value = fieldDescription.deserializeValue(value);
                    }
                    objectSet(result, token.name, value);
                }
                else {
                    objectSet(result, token.name, undefined);
                }
            }
        };
        tokens.forEach(function (item) { return iterToken(item); });
        toPairs(compatibility).forEach(function (_a) {
            var k = _a[0], v = _a[1];
            var value = objectGet(result, k);
            if (value !== EMPTY) {
                result[v] = value;
            }
        });
        // result['params'] = strictParamsTokens(result['params'] ? result['params'] : {});
        return result;
    };
    QueryBuilderContext.prototype.serializeTokenValues = function (save) {
        var tokens = this.getTokenValues(save);
        if (tokens['params']) {
            this.parametersControl.controls.forEach(function (control) {
                var value = tokens['params'][control.controls.name.value];
                if (isSet(value)) {
                    var fieldDescription = getFieldDescriptionByType(control.controls.field.value);
                    if (fieldDescription && fieldDescription.serializeValue) {
                        var parameter = control.serialize();
                        value = fieldDescription.serializeValue(value, parameter);
                        tokens['params'][control.controls.name.value] = value;
                    }
                }
            });
        }
        return tokens;
    };
    QueryBuilderContext.prototype.getTokenValue = function (token) {
        var fieldDescription = getFieldDescriptionByType(token.field);
        var value = objectGet(this.getTokenValues(), token.name);
        if (value === EMPTY) {
            return;
        }
        if (fieldDescription.deserializeValue) {
            value = fieldDescription.deserializeValue(value);
        }
        return value;
    };
    QueryBuilderContext.prototype.setTokenValue = function (token, value) {
        var fieldDescription = getFieldDescriptionByType(token.field);
        if (fieldDescription.serializeValue) {
            value = fieldDescription.serializeValue(value);
        }
        var tokenValues = cloneDeep(this.tokenValues);
        objectSet(tokenValues, token.name, value);
        this.tokenValues = tokenValues;
    };
    return QueryBuilderContext;
}());
export { QueryBuilderContext };
