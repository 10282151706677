<div class="chart-tooltip" [style.max-height.px]="(maxHeight | appIsSet) ? maxHeight : null" #root>
  <div class="chart-tooltip__header">{{ group }}</div>
  <div class="chart-tooltip__content">
    <table class="chart-tooltip__table">
      <tr
        *ngFor="let item of datasets; let i = index"
        class="chart-tooltip__item"
        [class.chart-tooltip__item_disabled]="(datasetActiveIndex | appIsSet) && datasetActiveIndex !== i"
      >
        <td class="chart-tooltip__table-column">
          <div class="chart-tooltip__item-main">
            <div
              *ngIf="item.color"
              class="chart-tooltip__item-color"
              [class.chart-tooltip__item-color_line]="colorLine"
              [class.chart-tooltip__item-color_circle]="colorCircle"
              [style.background-color]="!colorCircle ? colorDisplay(item.color) : null"
              [style.border-color]="colorCircle ? colorDisplay(item.color) : null"
            ></div>

            <div *ngIf="item.label | appIsSet" class="chart-tooltip__item-label">
              {{ item.label }}
            </div>
          </div>
        </td>

        <td class="chart-tooltip__table-column">
          <div class="chart-tooltip__item-right">
            <div class="chart-tooltip__item-value">
              {{ valueDisplay(item.value, item.valueFormat) }}
              <ng-container *ngIf="item.percentage | appIsSet">
                ({{ item.percentage | appFormatNumber: '0.00%' }})
              </ng-container>
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>
