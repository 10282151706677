import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import fromPairs from 'lodash/fromPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, fromEvent, merge, Observable, of, Subscription, timer } from 'rxjs';
import { debounce, map, startWith, switchMap } from 'rxjs/operators';

import { ActionControllerService } from '@modules/action-queries';
import { ChangeForm } from '@modules/change';
import { CustomViewsStore } from '@modules/custom-views';
import {
  getModelAttributesByColumns,
  TableSettings,
  ViewContext,
  ViewContextElement,
  ViewSettingsAction
} from '@modules/customize';
import {
  applyParamInput$,
  createFormFieldFactory,
  CustomViewDisplayField,
  FieldType,
  ParameterField
} from '@modules/fields';
import { ColumnListItem, ITEM_OUTPUT, ListItem } from '@modules/list';
import { Model, ModelDescription } from '@modules/models';
import { View } from '@modules/views';
import { EMPTY, TypedChanges } from '@shared';

@Component({
  selector: 'app-table-item-column, [app-table-item-column]',
  templateUrl: './table-item-column.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableItemColumnComponent implements OnInit, OnDestroy, OnChanges {
  @Input() row: ListItem;
  @Input() column: ColumnListItem;
  @Input() model: Model;
  @Input() modelDescription: ModelDescription;
  @Input() form: ChangeForm;
  @Input() settings: TableSettings;
  @Input() selected = false;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;

  @ViewChild('column_element') columnElement: ElementRef;
  @ViewChild('action_element') actionElement: ElementRef;
  @ViewChild('overlay', { read: CdkConnectedOverlay }) overlay: CdkConnectedOverlay;

  createField = createFormFieldFactory();
  column$ = new BehaviorSubject<ColumnListItem>(undefined);
  fieldTypes = FieldType;
  view: View;
  viewParameters: ParameterField[] = [];
  viewParams$: Observable<Object> = of({});
  edit = false;
  editLoading = false;
  actions: ViewSettingsAction[] = [];
  actionOpened$ = new BehaviorSubject<boolean>(false);
  actionSubscriptions: Subscription[] = [];
  overlayClasses: string[] = ['overlay'];

  constructor(
    private customViewsStore: CustomViewsStore,
    private actionControllerService: ActionControllerService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.column$
      .pipe(
        switchMap(column => {
          if (column && column.column instanceof CustomViewDisplayField) {
            return this.customViewsStore.getDetail(column.column.customView);
          } else {
            return of(undefined);
          }
        }),
        untilDestroyed(this)
      )
      .subscribe(customView => {
        this.view = customView ? customView.view : undefined;
        this.cd.markForCheck();
        this.updateViewParameters();
        this.updateViewParams();
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<TableItemColumnComponent>): void {
    if (changes.column) {
      this.column$.next(this.column);
    }

    if (changes.column || changes.settings) {
      const columnActions = this.settings.columnActions.find(item => item.name == this.column.column.name);
      this.actions = columnActions ? columnActions.actions : [];
    }
  }

  updateViewParameters() {
    if (!this.view) {
      this.viewParameters = [];
      this.cd.markForCheck();
      return;
    }

    const columns = this.settings.dataSource ? this.settings.dataSource.columns : [];

    this.viewParameters = columns.map(item => {
      const parameter = new ParameterField();

      parameter.name = item.name;
      parameter.verboseName = item.verboseName;
      parameter.field = item.field;
      parameter.params = item.params;

      return parameter;
    });
    this.cd.markForCheck();
  }

  updateViewParams() {
    if (!this.view) {
      this.viewParams$ = of({});
      this.cd.markForCheck();
      return;
    }

    const columns = this.settings.dataSource ? this.settings.dataSource.columns : [];
    const modelParams = getModelAttributesByColumns(this.model, columns);
    const customViewField = this.column.column instanceof CustomViewDisplayField ? this.column.column : undefined;
    const mappings = customViewField ? customViewField.customViewMappings : [];

    this.viewParams$ = combineLatest(
      mappings.map(mapping => {
        if (mapping.sourceParameterInput) {
          return applyParamInput$(mapping.sourceParameterInput, {
            context: this.context,
            contextElement: this.contextElement,
            localContext: {
              [ITEM_OUTPUT]: modelParams
            }
          }).pipe(
            map(value => {
              return {
                name: mapping.targetParameter,
                value: value !== EMPTY ? value : undefined
              };
            })
          );
        } else {
          const value = modelParams[mapping.sourceParameter];
          return of({
            name: mapping.targetParameter,
            value: value
          });
        }
      })
    ).pipe(
      map(values => {
        return fromPairs(values.map(item => [item.name, item.value]));
      })
    );
    this.cd.markForCheck();
  }

  initAction() {
    this.actionSubscriptions.forEach(item => item.unsubscribe());
    this.actionSubscriptions = [];

    const overlayClasses = ['overlay'];

    if (!this.actions.length) {
      return;
    }

    this.actionSubscriptions.push(
      this.overlay.positionChange.pipe(untilDestroyed(this)).subscribe(positionChange => {
        if (positionChange.connectionPair.originY == 'top') {
          this.overlayClasses = [...overlayClasses, 'overlay_position_top-center'];
        } else if (positionChange.connectionPair.originY == 'bottom') {
          this.overlayClasses = [...overlayClasses, 'overlay_position_bottom-center'];
        }

        this.cd.markForCheck();
      })
    );

    this.actionSubscriptions.push(
      combineLatest(
        merge(
          fromEvent<MouseEvent>(this.columnElement.nativeElement, 'mouseenter').pipe(map(() => true)),
          fromEvent<MouseEvent>(this.columnElement.nativeElement, 'mouseleave').pipe(map(() => false))
        ).pipe(
          debounce(value => timer(value ? 0 : 10)),
          startWith(true)
        ),
        merge(
          fromEvent<MouseEvent>(this.actionElement.nativeElement, 'mouseenter').pipe(map(() => true)),
          fromEvent<MouseEvent>(this.actionElement.nativeElement, 'mouseleave').pipe(map(() => false))
        ).pipe(startWith(false))
      )
        .pipe(untilDestroyed(this))
        .subscribe(([columnHover, actionHover]) => {
          if (!columnHover && !actionHover) {
            this.actionOpened$.next(false);

            this.actionSubscriptions.forEach(item => item.unsubscribe());
            this.actionSubscriptions = [];
          }
        })
    );
  }

  toggleEdit() {
    if (!this.edit) {
      this.startEdit();
    } else {
      this.saveEdit();
    }
  }

  onColumnMouseEnter() {
    if (!this.actions.length) {
      return;
    }

    this.actionOpened$.next(true);
    this.cd.detectChanges();

    this.initAction();
  }

  // get editColumn() {
  //   return this.row.columns[this.visibleColumnIndexes[this.editColumnIndex]];
  // }

  startEdit() {
    // this.form.initialize(this.modelDescription, this.row.model);
    // this.editColumnIndex = index;
    // this.cd.markForCheck();
    // this.listContextService.editingField = this.editColumn;
  }

  get editValid() {
    return false;
    // if (!this.form.form || !this.editColumn) {
    //   return false;
    // }
    //
    // return this.form.form.controls[this.editColumn.column.name].valid;
  }

  saveEdit() {
    // if (!this.editValid) {
    //   return;
    // }
    //
    // const field = this.editColumn.column.name;
    // const obs = this.form.submit([field]);
    // const changes = this.form.getChanges();
    //
    // if (!obs) {
    //   this.cancelEdit();
    //   return;
    // }
    //
    // this.editLoading = true;
    // this.cd.markForCheck();
    //
    // obs
    //   .pipe(
    //     switchMap<Model, any>(
    //       model => this.columnsModelListStore.resolveItemColumns(model).pipe(map(columns => {
    //         return [model, columns];
    //       }))
    //     ),
    //     untilDestroyed(this)
    //   )
    //   .subscribe(
    //     ([model, columns]) => {
    //       this.row = clone(this.row);
    //       this.row.model = model;
    //       this.row.columns = columns;
    //
    //       this.cancelEdit();
    //
    //       this.editLoading = false;
    //       this.cd.markForCheck();
    //
    //       this.userActivityService.createForCurrent(UserActivityType.ModelUpdate, {
    //         resource: this.modelDescription.resource,
    //         model: this.modelDescription.model,
    //         id: model.primaryKey,
    //         changes: changes
    //       }).subscribe(() => {});
    //     },
    //     error => {
    //       this.editLoading = false;
    //       this.cd.markForCheck();
    //       this.notificationService.error(
    //         'Error',
    //         error.errors[0]
    //       );
    //     }
    //   );
  }

  cancelEdit() {
    // this.form.deinit();
    // this.editColumnIndex = undefined;
    // this.cd.markForCheck();
    //
    // if (this.listContextService.editingField === this.editColumn) {
    //   this.listContextService.editingField = undefined;
    // }
  }
}
