import pickBy from 'lodash/pickBy';
import { ChangeViewSettings, CustomViewSettings, FieldElementItem, getElementByType, ListElementItem, ListViewSettings, ViewSettings, ViewSettingsType, WidgetElementItem } from '@modules/customize';
import { ChartWidget } from '@modules/dashboard';
import { deserializeMenuItem } from '@modules/menu';
import { ModelDescription } from '@modules/models';
import { resourceTypeItems } from '@modules/projects';
export var TemplateType;
(function (TemplateType) {
    TemplateType["Page"] = "page";
    TemplateType["Widget"] = "widget";
    TemplateType["DefaultComponent"] = "default_component";
    TemplateType["ResourceInitialModelDescriptions"] = "resource_initial_model_descriptions";
    TemplateType["ResourceModelDescriptionsTemplate"] = "resource_model_descriptions_template";
    TemplateType["ResourceDefaultModelDescription"] = "resource_default_model_description";
})(TemplateType || (TemplateType = {}));
export function defaultComponentTemplateName(element) {
    if (element instanceof ListElementItem) {
        return [element.type, element.layouts.length ? element.layouts[0].type : ''].join('_');
    }
    else if (element instanceof WidgetElementItem) {
        if (element.widget instanceof ChartWidget) {
            return [
                element.type,
                element.widget ? element.widget.type : '',
                element.widget ? element.widget.chartType : ''
            ].join('_');
        }
        else {
            return [element.type, element.widget ? element.widget.type : ''].join('_');
        }
    }
    else if (element instanceof FieldElementItem) {
        return [element.type, element.settings.field].join('_');
    }
    else {
        return element.type;
    }
}
var TemplateTag = /** @class */ (function () {
    function TemplateTag() {
    }
    TemplateTag.prototype.deserialize = function (data) {
        this.name = data['name'];
        this.color = data['color'];
        return this;
    };
    TemplateTag.prototype.serialize = function (fields) {
        var data = {
            name: this.name,
            color: this.color
        };
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    return TemplateTag;
}());
export { TemplateTag };
var TemplateImage = /** @class */ (function () {
    function TemplateImage() {
    }
    TemplateImage.prototype.deserialize = function (data) {
        this.image = data['image'];
        return this;
    };
    TemplateImage.prototype.serialize = function (fields) {
        var data = {
            image: this.image
        };
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    return TemplateImage;
}());
export { TemplateImage };
var Template = /** @class */ (function () {
    function Template() {
        this.params = {};
        this.logoFill = false;
        this.featured = false;
        this.tags = [];
        this.images = [];
        this.forResources = [];
        this.usedResources = [];
        this.usedPages = [];
        this.pages = [];
        this.menuItems = [];
        this.modelDescriptions = [];
        this.models = [];
        this.forEmptyResources = false;
        this.active = false;
    }
    Template.prototype.deserialize = function (data) {
        this.id = data['id'];
        this.type = data['type'];
        this.uniqueName = data['unique_name'];
        this.name = data['name'];
        this.description = data['description'];
        this.color = data['color'];
        this.icon = data['icon'];
        this.params = data['params'] || {};
        this.subtitle = this.params['subtitle'];
        this.logo = this.params['logo'];
        this.icon = this.params['icon'];
        this.color = this.params['color'];
        this.ordering = data['ordering'];
        if (data['active'] != undefined) {
            this.active = data['active'];
        }
        if (this.params['logo_fill'] != undefined) {
            this.logoFill = this.params['logo_fill'];
        }
        if (this.params['featured'] != undefined) {
            this.featured = this.params['featured'];
        }
        if (this.params['tags']) {
            this.tags = this.params['tags'].map(function (item) { return new TemplateTag().deserialize(item); });
        }
        if (this.params['images']) {
            this.images = this.params['images'].map(function (item) { return new TemplateImage().deserialize(item); });
        }
        if (this.params['for_resources']) {
            this.forResources = this.params['for_resources']
                .map(function (item) {
                return {
                    type: item['type'],
                    typeItem: resourceTypeItems.find(function (i) { return i.name == item['type_item']; })
                };
            })
                .filter(function (item) { return item.typeItem != undefined; });
        }
        if (this.params['used_resources']) {
            this.usedResources = this.params['used_resources']
                .map(function (item) {
                return {
                    token: item['token'],
                    type: item['type'],
                    typeItem: resourceTypeItems.find(function (i) { return i.name == item['type_item']; }),
                    options: item['options'],
                    models: item['models']
                        ? item['models'].map(function (model) {
                            return {
                                modelDescription: new ModelDescription().deserialize(model['model_description']),
                                instances: model['instances']
                            };
                        })
                        : undefined
                };
            })
                .filter(function (item) { return item.typeItem != undefined; });
        }
        if (this.params['used_pages']) {
            this.usedPages = this.params['used_pages'].map(function (item) {
                return {
                    token: item['token'],
                    uniqueName: item['unique_name']
                };
            });
        }
        if (this.params['menu_items']) {
            this.menuItems = this.params['menu_items']
                .map(function (item) { return deserializeMenuItem(item); })
                .filter(function (item) { return item != undefined; });
        }
        // if (data['resource_type_items']) {
        //   this.resourceTypeItems = data['resource_type_items']
        //     .map(item => resourceTypeItems.find(i => i.name == item))
        //     .filter(item => item != undefined);
        // }
        if (this.params['pages']) {
            this.pages = this.params['pages'].map(function (item) {
                if (item['view'] == ViewSettingsType.Custom) {
                    return new CustomViewSettings().deserialize(item);
                }
                else if (item['view'] == ViewSettingsType.List) {
                    return new ListViewSettings().deserialize(item);
                }
                else if (item['view'] == ViewSettingsType.Change) {
                    return new ChangeViewSettings().deserialize(item);
                }
                else {
                    return new ViewSettings().deserialize(item);
                }
            });
        }
        if (this.params['element']) {
            var Element_1 = getElementByType(this.params['element']['type']);
            this.element = new Element_1().deserialize(this.params['element']);
        }
        if (this.params['model_descriptions']) {
            this.modelDescriptions = this.params['model_descriptions'].map(function (item) { return new ModelDescription().deserialize(item); });
        }
        if (this.params['model_description']) {
            this.modelDescription = new ModelDescription().deserialize(this.params['model_description']);
        }
        if (this.params['models']) {
            this.models = this.params['models'];
        }
        return this;
    };
    Template.prototype.serialize = function (fields) {
        this.params = {};
        this.params['subtitle'] = this.subtitle;
        this.params['logo'] = this.logo;
        this.params['logo_fill'] = this.logoFill;
        this.params['icon'] = this.icon;
        this.params['color'] = this.color;
        this.params['featured'] = this.featured;
        this.params['tags'] = this.tags.map(function (item) { return item.serialize(); });
        this.params['images'] = this.images.map(function (item) { return item.serialize(); });
        this.params['for_resources'] = this.forResources.map(function (item) {
            return {
                type: item.type,
                type_item: item.typeItem.name
            };
        });
        this.params['used_resources'] = this.usedResources.map(function (item) {
            return {
                type: item.type,
                type_item: item.typeItem.name,
                token: item.token,
                options: item.options,
                models: item.models
                    ? item.models.map(function (model) {
                        return {
                            model_description: model.modelDescription.serialize(),
                            instances: model.instances
                        };
                    })
                    : undefined
            };
        });
        this.params['used_pages'] = this.usedPages.map(function (item) {
            return {
                token: item.token,
                unique_name: item.uniqueName
            };
        });
        if (this.menuItems.length) {
            this.params['menu_items'] = this.menuItems.map(function (item) { return item.serialize(); });
        }
        if (this.pages.length) {
            this.params['pages'] = this.pages.map(function (item) { return item.serialize(); });
        }
        if (this.element) {
            this.params['element'] = this.element.serialize();
        }
        if (this.modelDescriptions) {
            this.params['model_descriptions'] = this.modelDescriptions.map(function (item) { return item.serialize(); });
        }
        if (this.modelDescription) {
            this.params['model_description'] = this.modelDescription.serialize(['fields']);
        }
        this.params['models'] = this.models;
        var data = {
            id: this.id,
            type: this.type,
            unique_name: this.uniqueName,
            name: this.name,
            description: this.description,
            resource_type_items: [],
            // resource_type_items: this.resourceTypeItems.map(item => item.name),
            // resource_type_items: uniq(this.usedResources.map(item => item.typeItem.name)),
            params: this.params,
            active: this.active,
            ordering: this.ordering
        };
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Object.defineProperty(Template.prototype, "link", {
        get: function () {
            return ['templates', this.id];
        },
        enumerable: true,
        configurable: true
    });
    return Template;
}());
export { Template };
