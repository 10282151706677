import isArray from 'lodash/isArray';

import { NotificationType } from '@common/notifications';
import { DataSourceType, ListModelDescriptionDataSource } from '@modules/data-sources';
import { FieldOutput, Input, ParameterField } from '@modules/fields';
import { FilterItem2, Sort } from '@modules/filters';
import { ActionQuery, ListModelDescriptionQuery, QueryType } from '@modules/queries';
import { ascComparator, generateAlphanumeric, isSet, sortUsingAfterItem, splitmax } from '@shared';

// TODO: Refactor import
import { Workflow } from '../../workflow/data/workflow';

import { ActionType } from './action-type';

export function sortActionDescriptions(items: ActionDescription[]): ActionDescription[] {
  if (items.length <= 1) {
    return items;
  }

  return sortUsingAfterItem<ActionDescription>({
    items: items,
    getAfterItem: item => item.orderAfter,
    getItemId: item => item.name,
    defaultSort: (lhs, rhs) => {
      return ascComparator(lhs.verboseName.toLowerCase(), rhs.verboseName.toLowerCase());
    }
  });
}

export class QueryAction {
  public query: ActionQuery;

  deserialize(data: Object): QueryAction {
    if (data['query']) {
      this.query = new ActionQuery().deserialize(data['query']);
    }

    return this;
  }

  serialize() {
    return {
      query: this.query ? this.query.serialize() : undefined
    };
  }
}

export enum DownloadActionType {
  Input = 'input',
  Query = 'query'
}

export class DownloadAction {
  public type: DownloadActionType;
  public fileColumn: string;
  public query: ActionQuery;
  public input: Input;

  deserialize(data: Object): DownloadAction {
    this.type = data['type'] || DownloadActionType.Query;
    this.fileColumn = data['file_column'];

    if (data['query']) {
      this.query = new ActionQuery().deserialize(data['query']);
    }

    if (data['input']) {
      this.input = new Input().deserialize(data['input']);
    }

    return this;
  }

  serialize() {
    return {
      type: this.type,
      query: this.query ? this.query.serialize() : undefined,
      file_column: this.fileColumn,
      input: this.input ? this.input.serialize() : undefined
    };
  }
}

export enum SegueType {
  Page = 'page',
  PreviousPage = 'previous_page',
  ModelCreate = 'model_create',
  ModelChange = 'model_change',
  ModelMassEdit = 'model_mass_edit',
  ModelActivityLog = 'model_activity_log',
  ModelDelete = 'model_delete'
}

export const modelSegueTypes = [
  SegueType.ModelCreate,
  SegueType.ModelChange,
  SegueType.ModelMassEdit,
  SegueType.ModelActivityLog,
  SegueType.ModelDelete
];

export interface Segue {
  type: SegueType;
  page?: string;
  model?: string;
}

export class LinkAction implements Segue {
  type: SegueType;
  page?: string;
  model?: string;

  deserialize(data: Object): LinkAction {
    this.type = data['type'];
    this.page = data['page'];
    this.model = data['model'];

    return this;
  }

  serialize() {
    return {
      type: this.type,
      page: this.page,
      model: this.model
    };
  }
}

export class NotificationAction {
  public title: Input;
  public description: Input;
  public icon: string;
  public color: string;
  public type: NotificationType;
  public closeTimeoutEnabled = true;
  public closeTimeout: number;

  deserialize(data: Object): NotificationAction {
    this.type = data['type'];
    this.icon = data['icon'];
    this.color = data['color'];

    if (data['title']) {
      // Backward compatibility
      if (typeof data['title'] == 'string') {
        this.title = new Input().deserializeFromStatic('value', data['title']);
      } else {
        this.title = new Input().deserialize(data['title']);
      }
    }

    if (data['description']) {
      // Backward compatibility
      if (typeof data['description'] == 'string') {
        this.description = new Input().deserializeFromStatic('value', data['description']);
      } else {
        this.description = new Input().deserialize(data['description']);
      }
    }

    if (data['close_timeout_enabled'] !== undefined) {
      this.closeTimeoutEnabled = data['close_timeout_enabled'];
    }

    this.closeTimeout = data['close_timeout'];

    return this;
  }

  serialize() {
    return {
      title: this.title ? this.title.serialize() : null,
      description: this.description ? this.description.serialize() : null,
      icon: this.icon,
      color: this.color,
      type: this.type,
      close_timeout_enabled: this.closeTimeoutEnabled,
      close_timeout: this.closeTimeout
    };
  }
}

export class SetPropertyAction {
  public property: string;
  public value: Input;

  deserialize(data: Object): SetPropertyAction {
    this.property = data['property'];

    if (data['value']) {
      this.value = new Input().deserialize(data['value']);
    }

    return this;
  }

  serialize() {
    return {
      property: this.property,
      value: this.value ? this.value.serialize() : null
    };
  }
}

export class RunJavaScriptAction {
  public js: string;

  deserialize(data: Object): RunJavaScriptAction {
    this.js = data['js'];

    return this;
  }

  serialize() {
    return {
      js: this.js
    };
  }
}

export class CopyToClipboardAction {
  public value: Input;

  deserialize(data: Object): CopyToClipboardAction {
    if (data['value']) {
      this.value = new Input().deserialize(data['value']);
    }

    return this;
  }

  serialize() {
    return {
      value: this.value ? this.value.serialize() : null
    };
  }
}

export class ExportAction {
  public dataSource: ListModelDescriptionDataSource;
  public queryOptions: {
    filters?: FilterItem2[];
    search?: string;
    sort?: Sort[];
  };

  deserialize(data: Object): ExportAction {
    if (data['data_source']) {
      this.dataSource = new ListModelDescriptionDataSource().deserialize(data['data_source']);
    }

    return this;
  }

  serialize() {
    return {
      data_source: this.dataSource ? this.dataSource.serialize() : undefined
    };
  }

  getModelId() {
    return this.dataSource &&
      this.dataSource.type == DataSourceType.Query &&
      this.dataSource.query &&
      this.dataSource.query.queryType == QueryType.Simple &&
      this.dataSource.query.simpleQuery
      ? [this.dataSource.queryResource, this.dataSource.query.simpleQuery.model].join('.')
      : undefined;
  }
}

export class ImportAction {
  resource: string;
  model: string;

  deserialize(data: Object): ImportAction {
    this.resource = data['resource'];
    this.model = data['model'];

    return this;
  }

  serialize() {
    return {
      resource: this.resource,
      model: this.model
    };
  }

  getModelId(): string {
    if (!isSet(this.resource) || !isSet(this.model)) {
      return;
    }

    return [this.resource, this.model].join('.');
  }
}

export class OpenPopupAction {
  public popup: string;

  deserialize(data: Object): OpenPopupAction {
    this.popup = data['popup'];

    return this;
  }

  serialize() {
    return {
      popup: this.popup
    };
  }
}

export class ClosePopupAction {
  public popup: string;

  deserialize(data: Object): ClosePopupAction {
    this.popup = data['popup'];

    return this;
  }

  serialize() {
    return {
      popup: this.popup
    };
  }
}

export class WorkflowAction {
  public workflow: Workflow;

  deserialize(data: Object): WorkflowAction {
    if (data['workflow']) {
      this.workflow = new Workflow().deserialize(data['workflow']);
    }

    return this;
  }

  serialize() {
    return {
      workflow: this.workflow ? this.workflow.serialize() : undefined
    };
  }
}

export class ActionDescription {
  public project: string;
  public resource: string;
  public model: string;
  public modelAction: string;
  public storage: string;
  public storageAction: string;
  public name: string;
  public params: Object;
  public type: ActionType;
  public verboseName: string;
  public icon: string;
  public actionParams: ParameterField[] = [];
  public outputs: FieldOutput[] = [];
  public arrayOutput = false;
  public description: string;
  public protected = false;
  public queryAction: QueryAction;
  public downloadAction: DownloadAction;
  public linkAction: LinkAction;
  public elementAction: (string | number)[];
  public notificationAction: NotificationAction;
  public exportAction: ExportAction;
  public importAction: ImportAction;
  public openPopupAction: OpenPopupAction;
  public closePopupAction: ClosePopupAction;
  public workflowAction: WorkflowAction;
  public setPropertyAction: SetPropertyAction;
  public runJavaScriptAction: RunJavaScriptAction;
  public copyToClipboardAction: CopyToClipboardAction;
  public virtual = false;
  public featured = false;
  public orderAfter: string = undefined;
  public draft = false;
  public deleted = false;

  public deprecatedDynamicStatus = false;
  public deprecatedModelAction: {
    model: string;
    bulk: boolean;
    for_instance: boolean;
  };

  static generateName(): string {
    return generateAlphanumeric(8);
  }

  deserialize(data: Object): ActionDescription {
    this.resource = data['resource'];
    this.name = data['name'];
    this.verboseName = data['verbose_name'] || this.name;

    if (data['icon']) {
      this.icon = data['icon'];
    }

    if (data['params']) {
      if (isArray(data['params'])) {
        this.actionParams = data['params'].map(item => new ParameterField().deserialize(item));
        this.params = {};
      } else {
        this.params = data['params'];
        this.type = this.params['type'];
        this.verboseName = this.params['verbose_name'] || this.name;
        this.icon = this.params['icon'];
        this.arrayOutput = this.params['array_output'];

        if (this.params['protected'] != undefined) {
          this.protected = this.params['protected'];
        }

        if (this.params['action_params']) {
          this.actionParams = this.params['action_params'].map(item => new ParameterField().deserialize(item));
        }

        if (this.params['outputs']) {
          this.outputs = this.params['outputs'].map(item => new FieldOutput().deserialize(item));
        }

        this.description = this.params['description'];

        if (this.params['query_action']) {
          this.queryAction = new QueryAction().deserialize(this.params['query_action']);
        } else if (this.params['query']) {
          this.queryAction = new QueryAction().deserialize({
            query: this.params['query']
          });
        }

        if (this.params['download_action']) {
          this.downloadAction = new DownloadAction().deserialize(this.params['download_action']);
        }

        if (this.params['link_action']) {
          this.linkAction = new LinkAction().deserialize(this.params['link_action']);
        }

        if (this.params['element_action']) {
          if (isArray(this.params['element_action'])) {
            this.elementAction = this.params['element_action'];
          } else if (typeof this.params['element_action'] == 'string') {
            this.elementAction = this.params['element_action'].split('.');
          }
        }

        if (this.params['notification_action']) {
          this.notificationAction = new NotificationAction().deserialize(this.params['notification_action']);
        }

        if (this.params['export_action']) {
          // Backward compatibility
          if (this.params['export_action']['model']) {
            this.exportAction = new ExportAction();
            this.exportAction.dataSource = new ListModelDescriptionDataSource();
            this.exportAction.dataSource.type = DataSourceType.Query;
            this.exportAction.dataSource.queryResource = data['resource'];
            this.exportAction.dataSource.query = new ListModelDescriptionQuery();
            this.exportAction.dataSource.query.queryType = QueryType.Simple;
            this.exportAction.dataSource.query.simpleQuery = new this.exportAction.dataSource.query.simpleQueryClass();
            this.exportAction.dataSource.query.simpleQuery.model = this.params['export_action']['model'];
          } else {
            this.exportAction = new ExportAction().deserialize(this.params['export_action']);
          }
        }

        if (this.params['import_action']) {
          this.importAction = new ImportAction().deserialize(this.params['import_action']);
        }

        if (this.params['open_popup_action']) {
          this.openPopupAction = new OpenPopupAction().deserialize(this.params['open_popup_action']);
        }

        if (this.params['close_popup_action']) {
          this.closePopupAction = new ClosePopupAction().deserialize(this.params['close_popup_action']);
        }

        if (this.params['workflow_action']) {
          this.workflowAction = new WorkflowAction().deserialize(this.params['workflow_action']);
        }

        if (this.params['set_property_action']) {
          this.setPropertyAction = new SetPropertyAction().deserialize(this.params['set_property_action']);
        }

        if (this.params['run_javascript_action']) {
          this.runJavaScriptAction = new RunJavaScriptAction().deserialize(this.params['run_javascript_action']);
        }

        if (this.params['copy_to_clipboard_action']) {
          this.copyToClipboardAction = new CopyToClipboardAction().deserialize(this.params['copy_to_clipboard_action']);
        }

        if (this.params['virtual']) {
          this.virtual = this.params['virtual'];
        }

        if (this.params['featured']) {
          this.featured = this.params['featured'];
        }

        if (this.params['order_after'] !== undefined) {
          this.orderAfter = this.params['order_after'];
        }
      }
    }

    // Backward compatibility
    if (!this.type) {
      if (this.queryAction) {
        this.type = ActionType.Query;
      } else if (this.linkAction) {
        this.type = ActionType.Link;
      } else if (this.linkAction && this.linkAction.type == ('model_export' as SegueType)) {
        // Backward compatibility (<2.2.6)
        const model = this.linkAction.model ? splitmax(this.linkAction.model, '.', 2) : undefined;
        this.type = ActionType.Export;

        if (model && model.length == 2) {
          this.exportAction = new ExportAction();
          this.exportAction.dataSource = new ListModelDescriptionDataSource();
          this.exportAction.dataSource.type = DataSourceType.Query;
          this.exportAction.dataSource.query = new ListModelDescriptionQuery();
          this.exportAction.dataSource.query.queryType = QueryType.Simple;
          this.exportAction.dataSource.query.simpleQuery = new this.exportAction.dataSource.query.simpleQueryClass();

          [this.exportAction.dataSource.queryResource, this.exportAction.dataSource.query.simpleQuery.model] = model;
        }
      }
    }

    if (data['draft'] !== undefined) {
      this.draft = data['draft'];
    }

    if (data['deleted'] !== undefined) {
      this.deleted = data['deleted'];
    }

    // Backward compatibility

    if (data['dynamic_status'] != undefined) {
      this.deprecatedDynamicStatus = data['dynamic_status'];
    }

    if (data['model_action'] != undefined) {
      this.deprecatedModelAction = data['model_action'];
    }

    return this;
  }

  get id() {
    return [this.resource, this.name].join('.');
  }

  isSame(action: string | ActionDescription) {
    if (!action) {
      return false;
    }

    if (action instanceof ActionDescription) {
      return action.resource == this.resource && action.name == this.name;
    } else {
      const id = action as string;
      const params = splitmax(id, '.', 2);

      return params.length == 2
        ? (!this.resource || this.resource == params[0]) && this.name == params[1]
        : this.name == params[0];
    }
  }

  serialize() {
    return {
      resource: this.resource,
      name: this.name,
      params: {
        type: this.type,
        verbose_name: this.verboseName,
        icon: this.icon,
        action_params: this.actionParams.map(item => item.serialize()),
        outputs: this.outputs.map(item => item.serialize()),
        array_output: this.arrayOutput,
        description: this.description,
        protected: this.protected,
        query_action: this.queryAction ? this.queryAction.serialize() : undefined,
        download_action: this.downloadAction ? this.downloadAction.serialize() : undefined,
        link_action: this.linkAction ? this.linkAction.serialize() : undefined,
        element_action: this.elementAction,
        notification_action: this.notificationAction ? this.notificationAction.serialize() : undefined,
        export_action: this.exportAction ? this.exportAction.serialize() : undefined,
        import_action: this.importAction ? this.importAction.serialize() : undefined,
        open_popup_action: this.openPopupAction ? this.openPopupAction.serialize() : undefined,
        close_popup_action: this.closePopupAction ? this.closePopupAction.serialize() : undefined,
        workflow_action: this.workflowAction ? this.workflowAction.serialize() : undefined,
        set_property_action: this.setPropertyAction ? this.setPropertyAction.serialize() : undefined,
        run_javascript_action: this.runJavaScriptAction ? this.runJavaScriptAction.serialize() : undefined,
        copy_to_clipboard_action: this.copyToClipboardAction ? this.copyToClipboardAction.serialize() : undefined,
        virtual: this.virtual,
        featured: this.featured,
        order_after: this.orderAfter
      },
      draft: this.draft,
      deleted: this.deleted
    };
  }

  getLink(modelId?: string) {
    if (this.queryAction) {
      return ['action', this.id];
    } else if (this.linkAction) {
      // return [this.linkAction.link];
    }
  }

  get queryLink() {
    return ['action', this.id];
  }

  clone() {
    return new ActionDescription().deserialize(this.serialize());
  }

  get typeStr() {
    if (this.type == ActionType.Query) {
      return 'Run Operation';
    } else if (this.type == ActionType.Download) {
      return 'Download File';
    } else if (this.type == ActionType.Link) {
      return 'Navigate to Page';
    } else if (this.type == ActionType.ExternalLink) {
      return 'Open URL';
    } else if (this.type == ActionType.ElementAction) {
      return 'Component Action';
    } else if (this.type == ActionType.ShowNotification) {
      return 'Show Notification';
    } else if (this.type == ActionType.SetProperty) {
      return 'Set Variable';
    } else if (this.type == ActionType.RunJavaScript) {
      return 'Run JavaScript';
    } else if (this.type == ActionType.CopyToClipboard) {
      return 'Copy to Clipboard';
    } else if (this.type == ActionType.Export) {
      return 'Export Data';
    } else if (this.type == ActionType.Import) {
      return 'Import Data';
    } else if (this.type == ActionType.OpenPopup) {
      return 'Open Modal';
    } else if (this.type == ActionType.ClosePopup) {
      return 'Close Modal';
    } else if (this.type == ActionType.ScanCode) {
      return 'Scan QR/Bar code';
    } else if (this.type == ActionType.Workflow) {
      return 'Run Workflow';
    }
  }

  get typeIcon() {
    if (this.type == ActionType.Query) {
      return 'cloud_upload';
    } else if (this.type == ActionType.Download) {
      return 'save';
    } else if (this.type == ActionType.Link) {
      return 'redo';
    } else if (this.type == ActionType.ExternalLink) {
      return 'model_link';
    } else if (this.type == ActionType.ElementAction) {
      return 'components';
    } else if (this.type == ActionType.ShowNotification) {
      return 'notification';
    } else if (this.type == ActionType.SetProperty) {
      return 'variable';
    } else if (this.type == ActionType.RunJavaScript) {
      return 'console';
    } else if (this.type == ActionType.CopyToClipboard) {
      return 'documents';
    } else if (this.type == ActionType.Export) {
      return 'download';
    } else if (this.type == ActionType.Import) {
      return 'upload';
    } else if (this.type == ActionType.OpenPopup) {
      return 'copy';
    } else if (this.type == ActionType.ClosePopup) {
      return 'windows';
    } else if (this.type == ActionType.Workflow) {
      return 'workflow';
    } else if (this.type == ActionType.ScanCode) {
      return 'qr_code';
    } else {
      return 'power';
    }
  }

  get editLink() {
    return ['resources', this.resource, 'actions', this.name];
  }

  get anyName(): string {
    return isSet(this.verboseName) ? this.verboseName : this.name;
  }
}
