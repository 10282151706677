import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import * as moment from 'moment';

import { TintStyle } from '@modules/customize';
import { DATE_PARAM, TYPE_PARAM } from '@modules/models';

import { CalendarType } from '../../data/calendar-type';

@Component({
  selector: 'app-calendar-header',
  templateUrl: './calendar-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarHeaderComponent implements OnInit, OnDestroy, OnChanges {
  @Input() type: CalendarType;
  @Input() date: moment.Moment;
  @Input() params: Object;
  @Output() paramsChanged = new EventEmitter<Object>();

  calendarTypes = CalendarType;
  title: string;
  prevParams;
  nowParams;
  nextParams;
  tintStyles = TintStyle;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.prevParams = {};
    this.nextParams = {};
    this.nowParams = {};

    if (this.type == CalendarType.Day) {
      this.title = this.date.format('D MMMM, YYYY');
      this.prevParams[DATE_PARAM] = this.date.clone().startOf('date').subtract(1, 'day').toISOString();
      this.nextParams[DATE_PARAM] = this.date.clone().startOf('date').add(1, 'day').toISOString();
    } else if (this.type == CalendarType.Week) {
      this.title = this.date.clone().startOf('isoWeek').format('D MMMM, YYYY');
      this.prevParams[DATE_PARAM] = this.date.clone().startOf('isoWeek').subtract(1, 'week').toISOString();
      this.nextParams[DATE_PARAM] = this.date.clone().startOf('isoWeek').add(1, 'week').toISOString();
    } else if (this.type == CalendarType.Month) {
      this.title = this.date.format('MMMM, YYYY');
      this.prevParams[DATE_PARAM] = this.date.clone().startOf('month').subtract(1, 'month').toISOString();
      this.nextParams[DATE_PARAM] = this.date.clone().startOf('month').add(1, 'month').toISOString();
    } else if (this.type == CalendarType.Year) {
      this.title = this.date.format('YYYY');
      this.prevParams[DATE_PARAM] = this.date.clone().startOf('year').subtract(1, 'year').toISOString();
      this.nextParams[DATE_PARAM] = this.date.clone().startOf('year').add(1, 'year').toISOString();
    }

    this.nowParams[DATE_PARAM] = this.now.clone().startOf('date').toISOString();
    this.cd.markForCheck();
  }

  get now() {
    return moment();
  }

  applyParams(params: Object) {
    return { ...this.params, ...params };
  }

  typeParams(type: CalendarType) {
    const params = {};
    params[TYPE_PARAM] = type;
    return this.applyParams(params);
  }

  setParams(params: Object) {
    this.paramsChanged.emit(this.applyParams(params));
  }
}
