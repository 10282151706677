<form [formGroup]="form">
  <app-popup2 [size]="fileContent?.content ? 'l' : 'm'">
    <div class="choose-template__header">
      <div *ngIf="!fileContent" class="choose-template__detail-title">
        Choose File
      </div>

      <div *ngIf="fileContent" class="choose-template__detail-title">
        <a href="javascript:void(0)" (click)="backToUpload()">Choose File</a>
        <span class="choose-template__detail-title-separator icon-arrow_forward_2"></span>

        <ng-container *ngIf="!modelDescription">Create a new table</ng-container>
        <ng-container *ngIf="modelDescription">Import into {{ modelDescription.verboseNamePlural }}</ng-container>
      </div>
    </div>

    <ng-container *ngIf="progress === undefined">
      <app-page-block *ngIf="!fileContent">
        <div class="field">
          <div class="field__label">
            <span class="field__label-text">Data</span>
            <span class="field__label-optional">(csv, xls, xlsx, json)</span>
          </div>
          <div class="field__value">
            <div
              class="upload"
              [class.upload_empty]="!form.controls.file.value"
              [class.upload_uploadable]="true"
              [class.upload_active]="draggingOver"
              style="height: 120px;"
              (dragover)="onDragOver($event)"
              (dragleave)="onDragLeave($event)"
              (drop)="onDrop($event)"
            >
              <div *ngIf="form.controls.file.value" class="upload__preview">
                <div class="upload__background upload__background_padding">
                  <app-file-preview
                    [previewFilename]="previewFilename"
                    [previewExtension]="previewExtension"
                    [previewSize]="previewSize"
                    [additional]="fileContentInfo"
                  ></app-file-preview>
                </div>
              </div>

              <ng-container *ngIf="!form.controls.file.value">
                <div class="upload__placeholder">
                  <div class="upload__placeholder-icon icon-cloud_upload"></div>
                  <div class="upload__placeholder-label">
                    Drop file here or
                    <label [for]="'upload' | appUniqueId: idToken" class="upload__placeholder-button"
                      >Choose file</label
                    >
                  </div>
                </div>
              </ng-container>

              <div class="upload__controls">
                <input
                  class="input input_file"
                  type="file"
                  accept=".csv,.xls,.xlsx,.json"
                  (change)="onFileChange($event.target)"
                  [id]="'upload' | appUniqueId: idToken"
                />

                <ng-container *ngIf="form.controls.file.value">
                  <div class="upload__buttons">
                    <a
                      href="javascript:void(0)"
                      class="upload__button icon-bin"
                      (click)="form.controls.file.setValue(null)"
                      [appTip]="'Clear'"
                      [appTipOptions]="{ side: 'top', margin: -4 }"
                    ></a>

                    <label
                      [for]="'upload' | appUniqueId: idToken"
                      class="upload__button icon-cloud_upload"
                      [appTip]="'Upload new'"
                      [appTipOptions]="{ side: 'top', margin: -4 }"
                    >
                    </label>

                    <ng-content select="ng-container[upload-actions]"></ng-content>
                  </div>
                </ng-container>
              </div>

              <label *ngIf="!form.controls.file.value" class="upload__overlay" [for]="'upload' | appUniqueId: idToken">
              </label>
            </div>
          </div>
        </div>
      </app-page-block>

      <app-page-block *ngIf="fileContent?.error">
        <app-alert [title]="'File import failed'" [danger]="true" [border]="true">
          {{ fileContent.error }}
        </app-alert>
      </app-page-block>

      <ng-container *ngIf="fileContent?.content">
        <ng-container *ngIf="!modelDescription">
          <app-page-block>
            <app-auto-field
              [form]="form"
              [field]="
                createField({
                  name: 'verbose_name',
                  label: 'Collection Name',
                  field: 'CharField',
                  required: true,
                  params: { classes: ['input_fill'] }
                })
              "
            >
            </app-auto-field>
          </app-page-block>

          <app-page-block>
            <div class="field">
              <!--                Choose how file fields should be mapped-->
              <div class="field__label">Choose fields to import</div>
            </div>
          </app-page-block>
        </ng-container>

        <app-page-block>
          <div class="list">
            <div class="list__content list__content_border">
              <div class="list__viewport list__viewport_scrollable">
                <app-import-data
                  [fieldsControl]="form.controls.fields"
                  [primaryKeyControl]="form.controls.custom_primary_key"
                  [data]="fileContent?.content?.data"
                  [sourceOptionsEnabled]="!!modelDescription"
                  [sourceOptions]="sourceOptions"
                ></app-import-data>
              </div>
            </div>
          </div>
        </app-page-block>
      </ng-container>
    </ng-container>

    <app-page-block *ngIf="progress !== undefined">
      <app-bar-progress
        [label]="progress == 1 ? 'Import is finished' : 'Importing records...'"
        [progress]="progress"
        [processed]="successCount + failedCount"
        [errors]="failedCount"
        [total]="totalCount"
      >
      </app-bar-progress>
    </app-page-block>

    <app-page-block>
      <app-form-errors [form]="form"></app-form-errors>
    </app-page-block>

    <div class="choose-template__footer">
      <app-page-block>
        <app-stack [align]="'right'">
          <button *ngIf="progress != 1" type="button" class="button popup2__footer-item" (click)="cancel()">
            Cancel
          </button>

          <button
            *ngIf="!progress || progress < 1"
            type="button"
            class="button button_primary popup2__footer-item"
            [class.button_disabled]="!form.valid || fileContent?.error || submitLoading"
            (click)="submit()"
          >
            <app-loader-small *ngIf="submitLoading" class="button__icon button__icon_left"></app-loader-small>
            <span class="button__label">Import file</span>
          </button>

          <ng-container *ngIf="progress == 1">
            <button
              *ngIf="failedCount && result?.objectResults"
              type="button"
              class="button popup2__footer-item"
              (click)="downloadReport()"
            >
              <span class="button__icon button__icon_left icon-documents"></span>
              <span class="button__label">Download error report</span>
            </button>

            <button type="button" class="button button_primary popup2__footer-item" (click)="finish()">
              <span class="button__label">Continue</span>
            </button>
          </ng-container>
        </app-stack>
      </app-page-block>
    </div>
  </app-popup2>
</form>
