<div class="sidebar__element">
  <app-sidebar-field [label]="'Modal'" [required]="true">
    <mat-menu #queue_dropdown="matMenu">
      <ng-template matMenuContent>
        <button
          *ngFor="let item of popupOptions"
          mat-menu-item
          [disableRipple]="true"
          (click)="form.controls.open_popup.patchValue(item.value)"
        >
          <span *ngIf="item.icon" class="choose-items-item__dropdown-icon" [ngClass]="'icon-' + item.icon"></span>
          {{ item.name }}
        </button>
        <div *ngIf="!popupOptions.length" class="mat-menu-item-message">
          Modals not found
        </div>
        <button mat-menu-item [disableRipple]="true" (click)="createPopup()">
          <span class="choose-items-item__dropdown-icon icon-plus"></span>
          Add new
        </button>
      </ng-template>
    </mat-menu>

    <div class="control">
      <div class="control__input">
        <div class="select select_theme_jet select_fill" [matMenuTriggerFor]="queue_dropdown" #trigger="matMenuTrigger">
          <a href="javascript:void(0)" class="select__input" [class.select__input_opened]="trigger.menuOpen">
            <span
              *ngIf="selectedPopupOption?.icon"
              class="select__input-icon"
              [ngClass]="['icon-' + selectedPopupOption.icon]"
            ></span>
            <span class="select__input-label">
              <ng-container *ngIf="selectedPopupOption">{{ selectedPopupOption?.name }}</ng-container>
              <ng-container *ngIf="!selectedPopupOption">Choose Modal</ng-container>
            </span>
            <span class="select__input-arrow"></span>
          </a>
        </div>
      </div>
      <div *ngIf="form.controls.open_popup.value" class="control__right">
        <a
          (click)="openPopup(form.controls.open_popup.value)"
          href="javascript:void(0)"
          class="button button_bigger button_icon button_orange-alternative control__button"
          [appTip]="'Open Modal'"
        >
          <span class="icon icon-open_folder button__icon"></span>
        </a>
      </div>
    </div>
  </app-sidebar-field>
</div>
