import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { BooleanFieldStyle, Option } from '@modules/field-components';
import { BaseField, createFormFieldFactory, Input as FieldInput, InputValueType } from '@modules/fields';
import { getInputRepresentation } from '@modules/parameters';
import { SidebarCollapseContext } from '@modules/sidebar';
import { controlValue, forceObservable, isSet } from '@shared';

import { InputsEditForm } from '../inputs-edit/inputs-edit.form';

@Component({
  selector: 'app-inputs-edit-item',
  templateUrl: './inputs-edit-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputsEditItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() itemForm: FormGroup;
  @Input() form: InputsEditForm;
  @Input() openedInitial = false;
  @Input() focusedOnOpen = false;
  @Input() placeholder = 'Text';
  @Input() formulaPlaceholder = 'Formula';
  @Input() jsPlaceholder = 'return 2 * 3;';
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() staticValueDisabled = false;
  @Input() collapse = true;
  @Input() collapseContext: SidebarCollapseContext;
  @Input() remove = false;
  @Input() displayRequiredIndicator = false;
  @Input() requiredEditable = true;
  @Input() userInput = false;
  @Input() warning: string;
  @Input() displayValueTypes = [InputValueType.Formula];
  @Input() classes: string | string[];
  @Input() object: string;

  createField = createFormFieldFactory();
  title: string;
  description: string;
  valueDescription: string;
  infoSubscription: Subscription;
  analyticsSubscription: Subscription;
  titleAdditional$: Observable<string>;
  titleAdditionalOptions: Option[] = [
    {
      value: true,
      name: 'Required to load data',
      subtitle: 'Component <u>will not display data</u> if this parameter is empty string, NULL or is not set'
    },
    {
      value: false,
      name: 'Optional to load data',
      subtitle: 'Component <u>will ignore this parameter</u> if it is empty string, NULL or is not set'
    }
  ];
  booleanFieldStyle = BooleanFieldStyle;

  constructor(private analyticsService: UniversalAnalyticsService, private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['itemForm']) {
      this.initItemGroup();
    }
  }

  initItemGroup() {
    if (this.infoSubscription) {
      this.infoSubscription.unsubscribe();
    }

    if (this.analyticsSubscription) {
      this.analyticsSubscription.unsubscribe();
    }

    if (!this.itemForm) {
      return;
    }

    this.infoSubscription = combineLatest(
      // Title
      this.form.parameter$(this.itemForm).pipe(map(parameter => this.getTitle(this.itemForm.value['name'], parameter))),

      // Description
      this.form.parameter$(this.itemForm).pipe(map(parameter => this.getDescription(parameter))),

      // Value description
      combineLatest(this.form.parameter$(this.itemForm), this.form.value$(this.itemForm)).pipe(
        switchMap(([parameter, value]) => this.getValueDescription(parameter, value))
      )
    )
      .pipe(untilDestroyed(this))
      .subscribe(([title, description, valueDescription]) => {
        this.title = title;
        this.description = description;
        this.valueDescription = valueDescription;
        this.cd.markForCheck();
      });

    this.analyticsSubscription = this.itemForm.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      const input = this.form.serializeValueItem(value);
      if (!input) {
        return;
      }

      if (
        (input.valueType == InputValueType.StaticValue && input.staticValue) ||
        (input.valueType == InputValueType.Filter && input.filterField && input.filterLookup) ||
        (input.valueType == InputValueType.Context && input.contextValue)
      ) {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.SetParameter.SuccessfullySetUp, {
          Object: this.object,
          Type: input.valueType
        });
      }
    });

    this.titleAdditional$ = combineLatest(
      controlValue(this.itemForm.controls['field']),
      controlValue(this.itemForm.controls['required']),
      this.form.parameter$(this.itemForm)
    ).pipe(
      map(([field, inputRequired, parameter]) => {
        if (!field) {
          return;
        } else if (inputRequired || (parameter && parameter.required)) {
          return 'required';
        } else {
          return 'optional';
        }
      })
    );
  }

  getTitle(name: string, parameter: BaseField): string {
    if (!parameter) {
      return name;
    }

    return parameter.verboseName || parameter.name;
  }

  getInputRepresentation(input: FieldInput, parameter: BaseField): Observable<string> {
    return this.form.filterFields$().pipe(
      switchMap(filterFields => {
        const result = getInputRepresentation(input, {
          context: this.context,
          contextElement: this.contextElement,
          contextElementPath: this.contextElementPath,
          contextElementPaths: this.contextElementPaths,
          filterFields: filterFields
        });
        return forceObservable<string>(result);
      }),
      map(result => {
        return isSet(result) ? result : parameter.description;
      })
    );
  }

  getDescription(parameter: BaseField): string {
    if (!parameter) {
      return 'Not existing';
    } else if (!parameter.field) {
      return;
    }

    return parameter.description;
  }

  getValueDescription(parameter: BaseField, value: Object): Observable<string> {
    if (!parameter) {
      return of('Not existing');
    } else if (!parameter.field) {
      return of(null);
    }

    const input = this.form.serializeValueItem(value);

    if (!input) {
      return of(null);
    }

    if (!input.isSet() && isSet(parameter.description)) {
      return of(null);
    }

    return this.getInputRepresentation(input, parameter);
  }

  removeItem() {
    this.form.arrayRemove(this.itemForm);

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.SetParameter.Deleted, {
      Object: this.object
    });
  }
}
