import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, Provider } from '@angular/core';

import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { ACTION_CONTROLLER_PROMPT_COMPONENT, ActionControllerService, ActionService } from '@modules/action-queries';
import { TintStyle } from '@modules/customize';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';

import { BaseActionExecuteComponent } from '../base-action-execute/base-action-execute.component';
import { BaseActionExecuteForm } from '../base-action-execute/base-action-execute.form';

export const ACTION_CONTROLLER_PROMPT_COMPONENT_PROVIDER: Provider = {
  provide: ACTION_CONTROLLER_PROMPT_COMPONENT,
  useFactory: actionControllerPromptComponentFactory
};

export function actionControllerPromptComponentFactory(): any {
  return ActionExecutePopupComponent;
}

@Component({
  selector: 'app-action-execute-popup',
  templateUrl: './action-execute-popup.component.html',
  providers: [BaseActionExecuteForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionExecutePopupComponent extends BaseActionExecuteComponent implements OnInit {
  tintStyles = TintStyle;

  constructor(
    protected currentProjectStore: CurrentProjectStore,
    protected currentEnvironmentStore: CurrentEnvironmentStore,
    private popupComponent: BasePopupComponent,
    protected actionService: ActionService,
    protected actionControllerService: ActionControllerService,
    public form: BaseActionExecuteForm,
    protected notificationService: NotificationService,
    protected injector: Injector,
    protected cd: ChangeDetectorRef
  ) {
    super(
      currentProjectStore,
      currentEnvironmentStore,
      actionService,
      actionControllerService,
      form,
      notificationService,
      injector,
      cd
    );
  }

  ngOnInit() {
    super.ngOnInit();

    if (!this.actionParams.length) {
      this.execute();
    }
  }

  close() {
    if (this.popupComponent) {
      this.popupComponent.close();
    }
  }

  cancel() {
    this.cancelled.emit();
    this.close();
  }

  onSubmitCompleted(successResult?: boolean, result?: any) {
    super.onSubmitCompleted(successResult, result);

    if (successResult === true) {
      this.close();
    }
  }
}
