<app-customize-bar-action-edit-wrapper
  [enabled]="wrapperEnabled"
  [options]="options"
  [form]="form"
  [titleEnabled]="titleEnabled"
  [backLabel]="backLabel"
  [backPopSettingsComponent]="backPopSettingsComponent"
  [deleteEnabled]="deleteEnabled"
  [parentElement]="parentElement"
  [parentPopup]="parentPopup"
  [titleCleanValue]="titleCleanValue"
  (titleChange)="onTitleChanged($event)"
  (submit)="submit()"
  (cancel)="cancel()"
  (delete)="delete()"
  (back)="back.emit()"
>
  <app-sidebar-tabs>
    <app-sidebar-tab [label]="'General'" [error]="(operationValid$ | async) !== false ? undefined : 'Not configured'">
      <app-sidebar-section *ngIf="actionEditSeparateComponent">
        <div class="sidebar__element">
          <app-sidebar-field [label]="label" [required]="true">
            <app-actions-edit-item
              [actionItemClass]="options.actionItemClass"
              [action]="result.action"
              [context]="context"
              [contextElement]="contextElement"
              [disabled]="false"
              [defaultSubtitle]="'Not specified'"
              [icon]="icon"
              [error]="(operationValid$ | async) !== false ? undefined : 'Not configured'"
              [classes]="'component-action-button'"
              (open)="editInSeparateComponent()"
            >
            </app-actions-edit-item>
          </app-sidebar-field>
        </div>
      </app-sidebar-section>

      <app-customize-bar-action-edit-operation
        *ngIf="!actionEditSeparateComponent"
        [wrapper]="false"
        [options]="options"
        [form]="form"
        [label]="label"
        [context]="context"
        [contextElement]="contextElement"
        [contextElementPath]="contextElementPath"
        [contextElementPaths]="contextElementPaths"
        [firstInit]="firstInit"
        [setupOnCreate]="setupOnCreate"
        [object]="object"
        (submitImmediate)="submit()"
      ></app-customize-bar-action-edit-operation>

      <div *ngIf="options.nameEditable" class="sidebar__element">
        <app-customize-bar-input-edit
          [label]="'text'"
          [required]="true"
          [itemForm]="form.controls.verbose_name"
          [context]="context"
          [contextElement]="contextElement"
          [contextElementPath]="contextElementPath"
          [contextElementPaths]="contextElementPaths"
          [fill]="true"
          [analyticsSource]="object + '_verbose_name'"
        ></app-customize-bar-input-edit>
      </div>

      <div *ngIf="options.confirmationEnabled" class="sidebar__element">
        <app-sidebar-field [label]="'Confirm on execute'" [additional]="'(optional)'">
          <app-confirmation-edit-overlay
            [control]="form.controls.confirmation"
            [context]="context"
            [contextElement]="contextElement"
            [contextElementPath]="contextElementPath"
            [contextElementPaths]="contextElementPaths"
            [analyticsSource]="object"
            [origin]="confirmation_edit_origin"
            (disable)="form.controls.confirmation_enabled.patchValue(false)"
            #confirmation_edit_overlay="appConfirmationEditOverlay"
          ></app-confirmation-edit-overlay>

          <div
            class="sidebar-list-item sidebar-list-item_clickable"
            [class.sidebar-list-item_disabled]="confirmation_edit_overlay.isOpened()"
            cdkOverlayOrigin
            #confirmation_edit_origin="cdkOverlayOrigin"
            (click)="onConfirmationClick()"
          >
            <div class="sidebar-list-item__left">
              <span class="sidebar-list-item__icon icon-comments"></span>
            </div>
            <div class="sidebar-list-item__main">
              <ng-container *ngIf="form.controls.confirmation_enabled.value">
                <div class="sidebar-list-item__title">
                  Confirmation dialog
                </div>
                <div class="sidebar-list-item__description">
                  Enabled
                </div>
              </ng-container>

              <div
                *ngIf="!form.controls.confirmation_enabled.value"
                class="sidebar-list-item__title sidebar-list-item__title_secondary"
              >
                Add confirmation dialog
              </div>
            </div>
            <div class="sidebar-list-item__right">
              <div
                *ngIf="form.controls.confirmation_enabled.value"
                class="sidebar-list-item__action sidebar-list-item__action_button icon-gear_2"
              ></div>
              <div
                *ngIf="!form.controls.confirmation_enabled.value"
                class="sidebar-list-item__action sidebar-list-item__action_button icon-plus"
              ></div>
            </div>
          </div>
        </app-sidebar-field>
      </div>
    </app-sidebar-tab>

    <app-sidebar-tab
      *ngIf="options.colorsEditable || options.iconEditable || options.visibleEditable || options.marginEditable"
      [label]="'Display'"
    >
      <app-customize-bar-action-edit-display
        [options]="options"
        [form]="form"
        [context]="context"
        [contextElement]="contextElement"
        [contextElementPath]="contextElementPath"
        [contextElementPaths]="contextElementPaths"
        [object]="object"
      ></app-customize-bar-action-edit-display>
    </app-sidebar-tab>

    <app-sidebar-tab
      *ngIf="options.completionEditable || options.approveEnabled"
      [label]="'Actions'"
      [error]="(actionsValid$ | async) !== false ? undefined : 'Not configured'"
    >
      <div *ngIf="'action_element__actions' | appBannerVisible" class="sidebar__element">
        <a
          [appDocumentation]="'completion-actions'"
          [appDocumentationLeft]="true"
          [appDocumentationSource]="object + '_actions'"
          class="sidebar-float-button"
          target="_blank"
        >
          <div class="sidebar-float-button__icon icon-help"></div>
          <div class="sidebar-float-button__label">See how it works</div>
          <div
            [appHideBanner]="'action_element__actions'"
            class="sidebar-float-button__close icon-close"
            (click)="$event.stopPropagation(); $event.preventDefault()"
          ></div>
        </a>
      </div>

      <app-customize-bar-action-edit-completion
        *ngIf="options.completionEditable"
        [form]="form"
        [context]="context"
        [contextElement]="contextElement"
        [contextElementPath]="contextElementPath"
        [contextElementPaths]="completionContextElementPaths"
        [object]="object"
        [firstInit]="firstInit"
      ></app-customize-bar-action-edit-completion>

      <ng-container
        *ngIf="(currentProjectStore.instance$ | async).features.isTasksEnabled(); then content; else not_enabled"
      ></ng-container>

      <ng-template #content>
        <app-sidebar-collapse
          *ngIf="options.approveEnabled"
          [title]="'Approval'"
          [description]="'Create a new approval task'"
          [descriptionWhenOpened]="'Create a new approval task'"
          [context]="collapseContext"
          (openedChanged)="onApprovalOpenedChanged($event)"
          #collapse
        >
          <ng-container right>
            <span class="sidebar-collapse__action">
              <app-toggle
                [orange]="true"
                [selected]="form.value['approve_enabled']"
                (click)="
                  form.controls.approve_enabled.patchValue(!form.value['approve_enabled']);
                  form.markAsDirty();
                  $event.stopPropagation()
                "
              >
              </app-toggle>
            </span>
          </ng-container>

          <app-customize-bar-approve-edit
            *ngIf="collapse.opened"
            [control]="form.controls.approve"
            [context]="context"
            [contextElement]="contextElement"
            [contextElementPath]="contextElementPath"
            [contextElementPaths]="contextElementPaths"
            [firstInit]="firstInit"
            [source]="object"
          ></app-customize-bar-approve-edit>
        </app-sidebar-collapse>
      </ng-template>

      <ng-template #not_enabled>
        <div class="sidebar-collapse">
          <a href="javascript:void(0)" class="sidebar-collapse__header" (click)="openTaskFeatureOverview()">
            <div class="sidebar-collapse__header-left">
              <span
                class="sidebar-collapse__header-icon sidebar-collapse__header-icon_arrow icon-arrow_forward_2"
              ></span>
            </div>
            <div class="sidebar-collapse__header-main">
              <div class="sidebar-collapse__header-title">Approval</div>
              <div class="sidebar-collapse__header-description">Create a new approval task</div>
            </div>
            <div class="sidebar-collapse__header-right">
              <app-toggle [orange]="true"></app-toggle>
            </div>
          </a>
        </div>
      </ng-template>
    </app-sidebar-tab>
  </app-sidebar-tabs>
</app-customize-bar-action-edit-wrapper>
