import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

export namespace EmbedScripts {
  export const CKEditor = 'https://cdn.ckeditor.com/4.10.1/full/ckeditor.js';
  export const Paddle = 'https://cdn.paddle.com/paddle/paddle.js';
  export const Tally = 'https://tally.so/widgets/embed.js';
}

@Injectable({
  providedIn: 'root'
})
export class ScriptsService {
  private loaded: { [k: string]: { src: string; loaded$: Observable<void> } } = {};

  constructor() {}

  public loadSingleton(src: string): Observable<void> {
    if (this.loaded[src]) {
      return this.loaded[src].loaded$;
    }

    const result = new ReplaySubject<void>();
    const script = document.createElement('script');

    script.onload = () => result.next();
    script.src = src;
    script.async = true;
    script.defer = true;

    document.head.appendChild(script);

    this.loaded[src] = {
      src: src,
      loaded$: result
    };

    return result.asObservable();
  }

  public initTagManager(gtmId: string, dataLayerKey = 'dataLayer'): Observable<void> {
    window[dataLayerKey] = window[dataLayerKey] || [];
    window[dataLayerKey].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

    let src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;

    if (dataLayerKey != 'dataLayer') {
      src += '&l=' + dataLayerKey;
    }

    return this.loadSingleton(src);
  }
}
