import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgGxScrollableModule } from 'ng-gxscrollable';

import { PopupsModule } from '@common/popups';
import { TipsModule } from '@common/tips';
import { SharedModule } from '@shared';

import { IconSelectorComponent } from './components/icon-selector/icon-selector.component';

@NgModule({
  imports: [CommonModule, FormsModule, NgGxScrollableModule, PopupsModule, TipsModule, SharedModule],
  declarations: [IconSelectorComponent],
  exports: [IconSelectorComponent],
  entryComponents: [IconSelectorComponent]
})
export class IconsModule {}
