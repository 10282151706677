var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AgmMap } from '@agm/core';
import { AgmMarkerCluster } from '@agm/js-marker-clusterer';
import { ChangeDetectorRef, ElementRef, Injector, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import * as Color from 'color';
import clamp from 'lodash/clamp';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { PopoverService } from '@common/popover';
import { PopupService } from '@common/popups';
import { AppConfigService } from '@core';
import { UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { colors, parseColor } from '@modules/colors';
import { CustomViewsStore } from '@modules/custom-views';
import { CustomizeService, getModelAttributesByColumns, MapLocationStorage, rawListViewSettingsColumnsToViewContextOutputs, ViewSettingsService, ViewSettingsStore } from '@modules/customize';
import { CustomizeBarContext, CustomizeBarService } from '@modules/customize-bar';
import { DataSourceType } from '@modules/data-sources';
import { coveredByFieldLookup, gteFieldLookup, lteFieldLookup } from '@modules/field-lookups';
import { applyParamInput$, applyParamInputs$, DisplayFieldType, FieldType, LOADING_VALUE, NOT_SET_VALUE } from '@modules/fields';
import { EMPTY_FILTER_VALUES, FilterItem2 } from '@modules/filters';
import { ListLayoutType } from '@modules/layouts';
import { ColumnsModelListStore, EMPTY_OUTPUT, HAS_SELECTED_ITEM_OUTPUT, ITEM_OUTPUT, NO_SELECTED_ITEM_OUTPUT, SELECTED_ITEM_OUTPUT } from '@modules/list';
import { ListLayoutComponent, serializeDataSourceColumns } from '@modules/list-components';
import { MenuSettingsStore } from '@modules/menu';
import { ModelDescriptionStore, ModelService, ModelUtilsService } from '@modules/model-queries';
import { ORDER_BY_PARAM, PAGE_PARAM } from '@modules/models';
import { InputService } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { paramsToGetQueryOptions } from '@modules/resources';
import { RoutingService } from '@modules/routing';
import { ThemeService } from '@modules/theme';
import { isColorHex, MapDarkStyles, MapStyles, numberToHex } from '@shared';
import { containsBounds, coordinatesBounds, coordinatesCenter, getBoundsZoomLevel } from '../../utils/map';
function getListStateFetch(state) {
    return {
        dataSource: state.dataSource
            ? __assign({}, state.dataSource.serialize(), { columns: serializeDataSourceColumns(state.dataSource.columns) }) : undefined,
        dataSourceStaticData: state.dataSourceStaticData,
        dataSourceParams: state.dataSourceParams,
        bounds: state.bounds,
        filters: state.filters ? state.filters.map(function (item) { return item.serialize(); }) : [],
        search: state.search,
        // sort: state.sort,
        inputsLoading: state.inputsLoading,
        inputsNotSet: state.inputsNotSet,
        locationStorage: state.locationStorage,
        locationField: state.locationField,
        locationLatitudeField: state.locationLatitudeField,
        locationLongitudeField: state.locationLongitudeField
    };
}
function getListStateFetchNewParams(state) {
    return {
        bounds: state.bounds,
        params: pickBy(state.dataSourceParams, function (v, k) { return PAGE_PARAM != k; }),
        filters: state.filters ? state.filters.map(function (item) { return item.serialize(); }) : [],
        search: state.search,
        sort: state.sort
    };
}
function getListStateColumns(state) {
    return {
        columns: state.dataSource ? state.dataSource.columns : undefined
    };
}
function getListStateMarkers(state) {
    return {
        markerColor: state.settings ? state.settings.markerColor : undefined,
        markerSize: state.settings ? state.settings.markerSize : undefined,
        theme: state.theme
    };
}
var MapComponent = /** @class */ (function (_super) {
    __extends(MapComponent, _super);
    function MapComponent(modelService, customViewsStore, themeService, listStore, modelUtilsService, customizeService, notificationService, zone, appConfigService, injector, cd, customizeBarContext, customizeBarService, analyticsService, viewSettingsService, viewSettingsStore, menuSettingsStore, modelDescriptionStore, inputService, routing, currentProjectStore, currentEnvironmentStore, popupService, popoverService) {
        var _this = _super.call(this, injector, cd, customizeBarContext, customizeBarService, analyticsService, viewSettingsService, viewSettingsStore, menuSettingsStore, modelDescriptionStore, inputService, routing, currentProjectStore, currentEnvironmentStore, popupService, popoverService) || this;
        _this.modelService = modelService;
        _this.customViewsStore = customViewsStore;
        _this.themeService = themeService;
        _this.listStore = listStore;
        _this.modelUtilsService = modelUtilsService;
        _this.customizeService = customizeService;
        _this.notificationService = notificationService;
        _this.zone = zone;
        _this.appConfigService = appConfigService;
        _this.layout = ListLayoutType.Map;
        _this.visibleColumns = [];
        _this.configured = true;
        _this.viewportZoom = 4;
        _this.boundsChanged$ = new Subject();
        _this.fetchBounds$ = new BehaviorSubject(undefined);
        _this.markers = [];
        _this.mapStyles = [];
        return _this;
    }
    MapComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.initContext();
        this.boundsChanged$.pipe(debounceTime(300), untilDestroyed(this)).subscribe(function (value) { return _this.onBoundsChanged(value); });
        if (localStorage[this.mapLastPositionKey]) {
            var position = JSON.parse(localStorage[this.mapLastPositionKey]);
            this.latitude = position['latitude'];
            this.longitude = position['longitude'];
            this.viewportZoom = position['zoom'];
        }
    };
    MapComponent.prototype.ngOnDestroy = function () { };
    MapComponent.prototype.ngOnChanges = function (changes) {
        _super.prototype.ngOnChanges.call(this, changes);
    };
    MapComponent.prototype.getListState = function (settings, params, filters, search, sort) {
        var _this = this;
        params = cloneDeep(params);
        delete params[ORDER_BY_PARAM];
        var staticData$ = settings.dataSource && settings.dataSource.type == DataSourceType.Input && settings.dataSource.input
            ? applyParamInput$(settings.dataSource.input, {
                context: this.context,
                defaultValue: [],
                handleLoading: true,
                ignoreEmpty: true
            }).pipe(distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }))
            : of([]);
        var inputParams$ = settings.dataSource
            ? applyParamInputs$({}, settings.dataSource.queryInputs, {
                context: this.context,
                parameters: settings.dataSource.queryParameters,
                handleLoading: true,
                ignoreEmpty: true,
                emptyValues: EMPTY_FILTER_VALUES
            }).pipe(distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }))
            : of({});
        return combineLatest(staticData$, inputParams$, this.getQueryModelDescription(settings.dataSource), this.themeService.theme$, this.fetchBounds$).pipe(map(function (_a) {
            var staticData = _a[0], inputParams = _a[1], modelDescription = _a[2], theme = _a[3], bounds = _a[4];
            var resource = settings.dataSource
                ? _this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == settings.dataSource.queryResource; })
                : undefined;
            return {
                settings: settings,
                dataSource: settings.dataSource,
                dataSourceStaticData: staticData,
                dataSourceParams: __assign({}, inputParams, params),
                userParams: params,
                filters: filters,
                search: search,
                sort: sort,
                resource: resource,
                modelDescription: modelDescription,
                inputsLoading: [inputParams, staticData].some(function (obj) {
                    return obj == LOADING_VALUE || values(obj).some(function (item) { return item === LOADING_VALUE; });
                }),
                inputsNotSet: [inputParams, staticData].some(function (obj) {
                    return obj == NOT_SET_VALUE || values(obj).some(function (item) { return item === NOT_SET_VALUE; });
                }),
                locationStorage: settings ? settings.locationStorage : undefined,
                locationField: settings ? settings.locationField : undefined,
                locationLatitudeField: settings ? settings.locationLatitudeField : undefined,
                locationLongitudeField: settings ? settings.locationLongitudeField : undefined,
                bounds: bounds,
                theme: theme
            };
        }));
    };
    MapComponent.prototype.onStateUpdated = function (state) {
        if (!isEqual(getListStateColumns(state), getListStateColumns(this.listState))) {
            this.updateContextOutputs(state);
            this.updateVisibleColumns(state);
        }
        if (!isEqual(getListStateMarkers(state), getListStateMarkers(this.listState))) {
            this.updateMapStyles(state);
        }
        if (!isEqual(getListStateFetch(state), getListStateFetch(this.listState))) {
            var newParams = !isEqual(getListStateFetchNewParams(state), getListStateFetchNewParams(this.listState));
            var paramsNeedUpdate = false;
            if (newParams && this.setPage(1)) {
                paramsNeedUpdate = true;
            }
            if (!paramsNeedUpdate) {
                this.fetch(state);
            }
        }
        else {
            if (!isEqual(getListStateColumns(state), getListStateColumns(this.listState))) {
                if (this.listStore.dataSource) {
                    this.listStore.dataSource.columns = state.dataSource ? state.dataSource.columns : [];
                    this.listStore.deserializeModelAttributes();
                }
            }
        }
    };
    MapComponent.prototype.fetch = function (state) {
        var _this = this;
        if (this.fetchSubscription) {
            this.fetchSubscription.unsubscribe();
            this.fetchSubscription = undefined;
        }
        this.configured = state.dataSource && state.dataSource.isConfigured() && state.settings.isConfigured();
        this.parameters = this.getParameters(state);
        this.inputs = this.getInputs(state);
        this.cd.markForCheck();
        this.contextElement.patchOutputValueMeta(EMPTY_OUTPUT, { loading: true });
        if (!this.configured) {
            this.listStore.dataSource = undefined;
            this.listStore.params = {};
            this.listStore.queryOptions = undefined;
            this.cd.markForCheck();
            this.listStore.reset();
            return;
        }
        if (state.inputsNotSet) {
            this.listStore.reset();
            this.markers = [];
            this.cd.markForCheck();
            this.listStore.reset();
            return;
        }
        // this.loading = true;
        // this.cd.markForCheck();
        if (state.inputsLoading) {
            this.listStore.reset();
            return;
        }
        var queryOptions = paramsToGetQueryOptions(state.dataSourceParams);
        queryOptions.filters = queryOptions.filters.concat(state.filters, this.boundsToFilters(state.bounds));
        queryOptions.search = state.search;
        queryOptions.sort = state.sort;
        this.listStore.dataSource = state.dataSource;
        this.listStore.useDataSourceColumns = true;
        this.listStore.staticData = state.dataSourceStaticData;
        this.listStore.queryOptions = queryOptions;
        this.listStore.context = this.context;
        this.listStore.contextElement = this.contextElement;
        this.listStore.reset();
        this.fetchSubscription = this.listStore
            .getNext()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            var primaryKey;
            if (_this.openedWindowIndex != undefined && _this.markers[_this.openedWindowIndex]) {
                primaryKey = _this.markers[_this.openedWindowIndex].item.model.primaryKey;
            }
            _this.markers = [];
            var index = primaryKey != undefined ? result.findIndex(function (item) { return item.model.primaryKey == primaryKey; }) : undefined;
            _this.openWindow(index);
            _this.appendMarkers(result);
            _this.contextElement.setOutputValue(EMPTY_OUTPUT, result ? !result.length : false, { loading: false });
        }, function (error) {
            var message;
            if (error instanceof ServerRequestError && error.errors.length) {
                message = error.errors[0];
            }
            else if (error.hasOwnProperty('message')) {
                console.error(error);
                message = error.message;
            }
            else {
                console.error(error);
                message = error;
            }
            _this.contextElement.setOutputValue(EMPTY_OUTPUT, false, { loading: false, error: true });
            _this.notificationService.error('Loading failed', message);
        });
    };
    MapComponent.prototype.initContext = function () {
        var _this = this;
        this.contextElement.setActions([
            {
                uniqueName: 'update_data',
                name: 'Update Data',
                icon: 'repeat',
                parameters: [],
                handler: function () { return _this.reloadData(); }
            },
            {
                uniqueName: 'clear_selected_item',
                name: 'Reset Selected Marker',
                icon: 'deselect',
                parameters: [],
                handler: function () { return _this.closeWindow(); }
            },
            {
                uniqueName: 'clear_filters',
                name: 'Reset Filters',
                icon: 'delete',
                parameters: [],
                handler: function () { return _this.resetParams(); }
            }
        ]);
    };
    MapComponent.prototype.updateContextOutputs = function (state) {
        var columns = state.dataSource ? state.dataSource.columns : [];
        this.contextElement.setOutputs([
            {
                uniqueName: ITEM_OUTPUT,
                name: 'Current Marker',
                icon: 'duplicate_2',
                internal: true,
                byPathOnly: true,
                allowSkip: true,
                children: rawListViewSettingsColumnsToViewContextOutputs(columns.filter(function (item) { return item.type != DisplayFieldType.Computed; }), state.modelDescription)
            },
            {
                uniqueName: SELECTED_ITEM_OUTPUT,
                name: 'Selected Marker',
                icon: 'hand',
                children: rawListViewSettingsColumnsToViewContextOutputs(columns, state.modelDescription)
            },
            {
                uniqueName: HAS_SELECTED_ITEM_OUTPUT,
                name: 'Is any Marker selected',
                icon: 'select_all',
                fieldType: FieldType.Boolean,
                defaultValue: false
            },
            {
                uniqueName: NO_SELECTED_ITEM_OUTPUT,
                name: 'No Marker selected',
                icon: 'deselect',
                fieldType: FieldType.Boolean,
                defaultValue: true
            },
            {
                uniqueName: EMPTY_OUTPUT,
                name: 'Is Empty',
                icon: 'uncheck',
                fieldType: FieldType.Boolean,
                defaultValue: false
            }
        ]);
    };
    MapComponent.prototype.updateVisibleColumns = function (state) {
        this.visibleColumns = state.dataSource.columns.filter(function (item) { return item.visible; });
        this.cd.markForCheck();
    };
    MapComponent.prototype.updateMapStyles = function (state) {
        var _this = this;
        this.mapStyles = this.getMapStyles(state);
        this.markersClusterStyles = this.getMarkersClusterStyles(state);
        this.zone.onStable.pipe(first(), untilDestroyed(this)).subscribe(function () { return _this.repaintMarkerClusters(); });
    };
    MapComponent.prototype.repaintMarkerClusters = function () {
        if (!this.markerCluster) {
            return;
        }
        this.markerCluster['_clusterManager'].getClustererInstance().then(function (clusterer) {
            clusterer.repaint();
        });
    };
    MapComponent.prototype.getMapStyles = function (state) {
        return state.theme == 'dark' ? MapDarkStyles : MapStyles;
    };
    MapComponent.prototype.getMarkersClusterStyles = function (state) {
        var _this = this;
        var colorHex = isColorHex(this.settings.markerColor)
            ? this.settings.markerColor.substring(1)
            : colors.filter(function (item) { return item.name == _this.settings.markerColor; }).map(function (item) { return numberToHex(item.hex); })[0];
        var clr = colorHex ? parseColor('#' + colorHex) : undefined;
        var isDark = !clr || clr.contrast(Color('white')) >= 2;
        var size = this.settings.markerSize ? this.settings.markerSize * 2 : 52;
        var textSize = clamp(12, 8, size * 0.6);
        var textColor = !clr || isDark ? '#fff' : clr.darken(0.8).string();
        var url = new URL(this.appConfigService.serverBaseUrl + "/assets/marker-cluster.svg");
        if (colorHex) {
            url.searchParams.append('color', colorHex);
        }
        if (state.theme == 'dark') {
            url.searchParams.append('dark', '1');
        }
        if (size) {
            url.searchParams.append('size', size.toString());
        }
        return [
            {
                url: url.toString(),
                height: size,
                width: size,
                textSize: textSize,
                textColor: textColor
            }
        ];
    };
    Object.defineProperty(MapComponent.prototype, "mapLastPositionKey", {
        get: function () {
            return "map_last_position_" + this.viewId;
        },
        enumerable: true,
        configurable: true
    });
    MapComponent.prototype.openWindow = function (index) {
        if (this.openedWindowIndex == index) {
            return;
        }
        this.openedWindowIndex = index;
        this.cd.markForCheck();
        this.updateSelectedContext();
    };
    MapComponent.prototype.closeWindow = function (index) {
        if (index !== undefined && index !== this.openedWindowIndex) {
            return;
        }
        this.openedWindowIndex = undefined;
        this.cd.markForCheck();
        this.updateSelectedContext();
    };
    MapComponent.prototype.isInfoWindowOpen = function (index) {
        return this.openedWindowIndex === index;
    };
    MapComponent.prototype.updateSelectedContext = function () {
        var model = this.openedWindowIndex != undefined && this.markers[this.openedWindowIndex]
            ? this.markers[this.openedWindowIndex].item.model
            : undefined;
        if (model) {
            var columns = this.settings.dataSource ? this.settings.dataSource.columns : [];
            this.contextElement.setOutputValue(SELECTED_ITEM_OUTPUT, getModelAttributesByColumns(model, columns));
            this.contextElement.setOutputValue(HAS_SELECTED_ITEM_OUTPUT, true);
            this.contextElement.setOutputValue(NO_SELECTED_ITEM_OUTPUT, false);
        }
        else {
            this.contextElement.setOutputValue(SELECTED_ITEM_OUTPUT, undefined);
            this.contextElement.setOutputValue(HAS_SELECTED_ITEM_OUTPUT, false);
            this.contextElement.setOutputValue(NO_SELECTED_ITEM_OUTPUT, true);
        }
    };
    MapComponent.prototype.appendMarkers = function (items) {
        var _this = this;
        var markers = items
            .map(function (item) {
            if ([MapLocationStorage.PostgreSQL, MapLocationStorage.Object].includes(_this.settings.locationStorage)) {
                var location_1 = item.model.getAttribute(_this.settings.locationField);
                if (location_1 && location_1['lat'] && location_1['lng']) {
                    return {
                        latitude: location_1['lat'],
                        longitude: location_1['lng'],
                        title: _this.modelUtilsService.str(item.model),
                        item: item
                    };
                }
            }
            else if (_this.settings.locationStorage == MapLocationStorage.TwoFields) {
                var latitudeLocation = item.model.getAttribute(_this.settings.locationLatitudeField);
                var longitudeLocation = item.model.getAttribute(_this.settings.locationLongitudeField);
                return {
                    latitude: latitudeLocation,
                    longitude: longitudeLocation,
                    title: _this.modelUtilsService.str(item.model),
                    item: item
                };
            }
        })
            .filter(function (item) { return item != undefined; });
        this.markers = this.markers.concat(markers);
        this.cd.markForCheck();
        if (this.latitude == undefined && this.markers.length) {
            var markersCoords = this.markers.map(function (item) {
                return {
                    lat: item.latitude,
                    lng: item.longitude
                };
            });
            var center = coordinatesCenter(markersCoords);
            var bounds = coordinatesBounds(markersCoords);
            var padding = 20;
            this.latitude = center.latitude;
            this.longitude = center.longitude;
            this.zoom = bounds
                ? getBoundsZoomLevel(bounds, this.container.nativeElement.offsetWidth - padding * 2, this.container.nativeElement.offsetHeight - padding * 2)
                : 4;
            this.viewportZoom = this.zoom;
            this.cd.markForCheck();
        }
    };
    MapComponent.prototype.boundsToFilters = function (bounds) {
        var _this = this;
        if (!bounds || isNaN(bounds.south)) {
            return [];
        }
        if (this.settings.locationStorage == MapLocationStorage.PostgreSQL) {
            var field = this.settings.dataSource.columns.find(function (item) { return item.name == _this.settings.locationField; });
            var boundsArray = field && field.params['inverted_coordinates']
                ? [
                    [bounds.north, bounds.west],
                    [bounds.north, bounds.east],
                    [bounds.south, bounds.east],
                    [bounds.south, bounds.west],
                    [bounds.north, bounds.west]
                ]
                : [
                    [bounds.west, bounds.north],
                    [bounds.east, bounds.north],
                    [bounds.east, bounds.south],
                    [bounds.west, bounds.south],
                    [bounds.west, bounds.north]
                ];
            var polygonArgs = boundsArray.map(function (item) { return item.join(' '); }).join(',');
            return [
                new FilterItem2({
                    field: [this.settings.locationField],
                    lookup: coveredByFieldLookup,
                    value: "POLYGON((" + polygonArgs + "))"
                })
            ];
        }
        else if (this.settings.locationStorage == MapLocationStorage.TwoFields) {
            return [
                new FilterItem2({
                    field: [this.settings.locationLatitudeField],
                    lookup: gteFieldLookup,
                    value: bounds.south
                }),
                new FilterItem2({
                    field: [this.settings.locationLatitudeField],
                    lookup: lteFieldLookup,
                    value: bounds.north
                }),
                new FilterItem2({
                    field: [this.settings.locationLongitudeField],
                    lookup: gteFieldLookup,
                    value: bounds.west
                }),
                new FilterItem2({
                    field: [this.settings.locationLongitudeField],
                    lookup: lteFieldLookup,
                    value: bounds.east
                })
            ];
        }
        else if (this.settings.locationStorage == MapLocationStorage.Object) {
            return [
                new FilterItem2({
                    field: [this.settings.locationField + "_latitude"],
                    lookup: gteFieldLookup,
                    value: bounds.south
                }),
                new FilterItem2({
                    field: [this.settings.locationField + "_latitude"],
                    lookup: lteFieldLookup,
                    value: bounds.north
                }),
                new FilterItem2({
                    field: [this.settings.locationField + "_longitude"],
                    lookup: gteFieldLookup,
                    value: bounds.west
                }),
                new FilterItem2({
                    field: [this.settings.locationField + "_longitude"],
                    lookup: lteFieldLookup,
                    value: bounds.east
                })
            ];
        }
    };
    MapComponent.prototype.onModelUpdated = function (model) {
        var openedMarker = this.markers[this.openedWindowIndex];
        if (openedMarker && openedMarker.item.model.isSame(model)) {
            this.updateSelectedContext();
        }
    };
    MapComponent.prototype.trackByFn = function (index, item) {
        return item.item.model.primaryKey;
    };
    MapComponent.prototype.onBoundsChanged = function (googleBounds) {
        if (!this.map || !googleBounds) {
            return;
        }
        var currentBounds = this.fetchBounds$.value;
        var bounds = googleBounds.toJSON();
        if (this.zoom == this.map.zoom && currentBounds && containsBounds(currentBounds, bounds)) {
            return;
        }
        var paddingVertical = (bounds.north - bounds.south) * 0.1;
        var paddingHorizontal = (bounds.east - bounds.west) * 0.1;
        bounds.east += paddingHorizontal;
        bounds.north += paddingVertical;
        bounds.south -= paddingVertical;
        bounds.west -= paddingHorizontal;
        this.zoom = this.map.zoom;
        var center = coordinatesCenter([
            { lat: bounds.north, lng: bounds.west },
            { lat: bounds.south, lng: bounds.east }
        ]);
        center['zoom'] = this.map.zoom;
        localStorage[this.mapLastPositionKey] = JSON.stringify(center);
        this.fetchBounds$.next(bounds);
    };
    MapComponent.prototype.getAnyModel = function () {
        if (!this.listStore.items || !this.listStore.items.length) {
            return;
        }
        return this.listStore.items[0].model;
    };
    return MapComponent;
}(ListLayoutComponent));
export { MapComponent };
