import * as moment from 'moment';

import { parseTime } from '@shared';

import { DateFormat, defaultDateFormat, momentDateEditFormats, momentDateFormats } from './date-format';
import { defaultTimeFormat, isClock12TimeEditFormat, isClock12TimeFormat } from './time-format';
import { momentTimeEditFormats, momentTimeFormats } from './time-format';

export function getDateFieldMomentFormat(field, edit: boolean) {
  const params = { date: true, time: true, ...field.params };
  const dateFormat = params['date_format'] || defaultDateFormat;

  if (edit) {
    if (momentDateEditFormats[dateFormat]) {
      return momentDateEditFormats[dateFormat];
    } else {
      return dateFormat;
    }
  } else {
    if (momentDateFormats[dateFormat]) {
      return momentDateFormats[dateFormat];
    } else {
      return dateFormat;
    }
  }
}

export function isTimeFieldClock12Format(field, edit: boolean): boolean {
  const params = { date: true, time: true, ...field.params };
  const timeFormat = params['time_format'] || defaultTimeFormat;

  if (edit) {
    return isClock12TimeEditFormat(timeFormat);
  } else {
    return isClock12TimeFormat(timeFormat);
  }
}

export function getTimeFieldMomentFormat(field, edit: boolean) {
  const params = { date: true, time: true, ...field.params };
  const timeFormat = params['time_format'] || defaultTimeFormat;

  if (edit) {
    if (momentTimeEditFormats[timeFormat]) {
      return momentTimeEditFormats[timeFormat];
    } else {
      return timeFormat;
    }
  } else {
    if (momentTimeFormats[timeFormat]) {
      return momentTimeFormats[timeFormat];
    } else {
      return timeFormat;
    }
  }
}

export function isDateTimeFieldClock12Format(field, edit: boolean): boolean {
  const params = { date: true, time: true, ...field.params };

  if (params['time']) {
    return isTimeFieldClock12Format(field, edit);
  }

  return false;
}

export function getDateTimeFieldMomentFormat(field, edit: boolean) {
  const params = { date: true, time: true, ...field.params };
  const parts = [getDateFieldMomentFormat(field, edit)];

  if (params['time']) {
    parts.push(getTimeFieldMomentFormat(field, edit));
  }

  return parts.join(' ');
}

export function formatDateTimeField(value: string | moment.Moment, field) {
  if (!value) {
    return;
  }

  const params = { date: true, time: true, ...field.params };
  const relative = params['relative'];
  // TODO: Optimize when relative=false
  const valueDate = moment(value);
  const valueDateStart = valueDate.clone().startOf('day');
  const nowDate = moment();
  const nowDateStart = nowDate.clone().startOf('day');
  const daysDiff = Math.abs(nowDateStart.diff(valueDateStart, 'days'));

  if (relative && daysDiff < 7) {
    if (params['time']) {
      return valueDate.from(nowDate);
    } else {
      if (daysDiff == 0) {
        return 'Today';
      } else {
        return valueDateStart.from(nowDateStart);
      }
    }
  } else {
    const format = getDateTimeFieldMomentFormat(field, false);
    return valueDate.format(format);
  }
}

export function formatTimeField(value: string | moment.Moment, field) {
  if (!value) {
    return;
  }

  const params = { date: true, time: true, ...field.params };
  const relative = params['relative'];
  const valueDate = parseTime(value);

  if (relative) {
    return valueDate.fromNow();
  } else {
    const format = getTimeFieldMomentFormat(field, false);
    return valueDate.format(format);
  }
}
