var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ActionItem } from '@modules/actions';
import { FormField, getFieldDescriptionByType, Input, parseFieldType } from '@modules/fields';
import { ModelDbField, ModelField, modelFieldToField, ModelFieldType } from '@modules/models';
import { AlignHorizontal } from '../../align-horizontal';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
export var FIELD_ELEMENT_VERSION = 2;
var FieldElementItem = /** @class */ (function (_super) {
    __extends(FieldElementItem, _super);
    function FieldElementItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = ElementType.Field;
        _this.alignHorizontalDefault = AlignHorizontal.Left;
        _this.onChangeActions = [];
        _this.version = 1;
        return _this;
    }
    FieldElementItem.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.version = this.params['v'] || 1;
        this.height = this.params['height'];
        this.tooltip = this.params['tooltip'];
        if (this.version >= 2) {
            this.width = this.params['width'];
        }
        else {
            // Backward compatibility
            this.width = 220;
            this.version = FIELD_ELEMENT_VERSION;
        }
        if (this.params['settings']) {
            this.settings = this.params['settings'];
            this.settings.field = parseFieldType(this.settings.field);
            this.settings.valueInput = this.params['settings']['valueInput']
                ? new Input().deserialize(this.params['settings']['valueInput'])
                : undefined;
            this._formField = undefined;
        }
        if (this.params['field']) {
            var modelField = void 0;
            if (this.params['field']['type'] != undefined) {
                modelField = new ModelField().deserialize(this.params['field']);
            }
            else {
                modelField = new ModelField();
                modelField.type = ModelFieldType.Db;
                modelField.item = new ModelDbField().deserialize(this.params['field']);
                modelField.name = modelField.item.name;
            }
            this.settings = modelFieldToField(modelField);
            this._formField = undefined;
        }
        this.relatedModelFromField = this.params['related_model_from_field'];
        if (this.params['disable_input']) {
            this.disableInput = new Input().deserialize(this.params['disable_input']);
        }
        if (this.params['on_change_actions']) {
            this.onChangeActions = this.params['on_change_actions'].map(function (item) { return new ActionItem().deserialize(item); });
        }
        return this;
    };
    FieldElementItem.prototype.serialize = function () {
        this.params = {
            settings: __assign({}, this.settings, { valueInput: this.settings.valueInput ? this.settings.valueInput.serialize() : undefined }),
            // field: this.modelField ? this.modelField.serialize() : undefined,
            related_model_from_field: this.relatedModelFromField,
            width: this.width,
            height: this.height,
            disable_input: this.disableInput ? this.disableInput.serialize() : null,
            tooltip: this.tooltip,
            on_change_actions: this.onChangeActions.map(function (item) { return item.serialize(); }),
            v: this.version
        };
        return _super.prototype.serialize.call(this);
    };
    Object.defineProperty(FieldElementItem.prototype, "formField", {
        get: function () {
            if (!this._formField) {
                this.updateFormField();
            }
            return this._formField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FieldElementItem.prototype, "fieldDescription", {
        get: function () {
            if (!this._fieldDescription) {
                this.updateFieldDescription();
            }
            return this._fieldDescription;
        },
        enumerable: true,
        configurable: true
    });
    FieldElementItem.prototype.updateFieldDescription = function () {
        this._fieldDescription = getFieldDescriptionByType(this.formField.field);
    };
    FieldElementItem.prototype.updateFormField = function () {
        this._formField = new FormField().deserialize({
            name: this.settings.name,
            label: this.settings.verboseName,
            field: this.settings.field,
            required: this.settings.required,
            reset_enabled: this.settings.resetEnabled,
            editable: this.settings.editable,
            placeholder: this.settings.placeholder,
            validator_type: this.settings.validatorType,
            validator_params: this.settings.validatorParams,
            params: this.settings.params,
            description: this.settings.description
        });
    };
    Object.defineProperty(FieldElementItem.prototype, "analyticsName", {
        get: function () {
            return this.settings ? "field_" + this.settings.field : 'field';
        },
        enumerable: true,
        configurable: true
    });
    FieldElementItem.prototype.defaultName = function () {
        return 'field';
    };
    return FieldElementItem;
}(ElementItem));
export { FieldElementItem };
registerElementForType(ElementType.Field, FieldElementItem);
