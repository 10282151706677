import { AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { TweenMax } from 'gsap';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, merge } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { preventDragMouseDownEvent } from '@common/drag-drop2';
import { addClass, isSet, KeyboardEventKeyCode, MouseButton, removeClass } from '@shared';
import { ResizableDirective, ResizeFinishedEvent, ResizeType } from '../resizable/resizable.directive';
var RESIZABLE_RESIZING_CLASS = 'app-resizable-resizing';
var RESIZABLE_RESIZING_HORIZONTAL_CLASS = 'app-resizable-resizing-horizontal';
var RESIZABLE_RESIZING_HORIZONTAL_REVERSE_CLASS = 'app-resizable-resizing-horizontal-reverse';
var RESIZABLE_RESIZING_VERTICAL_CLASS = 'app-resizable-resizing-vertical';
var RESIZABLE_RESIZING_VERTICAL_REVERSE_CLASS = 'app-resizable-resizing-vertical-reverse';
var ResizableHandleDirective = /** @class */ (function () {
    function ResizableHandleDirective(el, autoParent) {
        this.el = el;
        this.autoParent = autoParent;
        this.types = [];
        this.speedHorizontal = 1;
        this.speedVertical = 1;
        this.reverseHorizontal = false;
        this.reverseVertical = false;
        this.minSize = {};
        this.maxSize = {};
        this.checkHorizontalBounds = true;
        this.checkVerticalBounds = false;
        this.resizing = false;
        this.altPressed = false;
    }
    ResizableHandleDirective.prototype.ngOnDestroy = function () {
        if (this.resizing) {
            removeClass(document.body, RESIZABLE_RESIZING_CLASS);
            removeClass(document.body, RESIZABLE_RESIZING_HORIZONTAL_CLASS);
            removeClass(document.body, RESIZABLE_RESIZING_HORIZONTAL_REVERSE_CLASS);
            removeClass(document.body, RESIZABLE_RESIZING_VERTICAL_CLASS);
            removeClass(document.body, RESIZABLE_RESIZING_VERTICAL_REVERSE_CLASS);
            if (isSet(this.bodyClass)) {
                removeClass(document.body, this.bodyClass);
            }
        }
    };
    ResizableHandleDirective.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (!this.resizableElement) {
            return;
        }
        fromEvent(this.el.nativeElement, 'mousedown')
            .pipe(filter(function (e) { return e.button == MouseButton.Main && _this.types.length > 0; }), untilDestroyed(this))
            .subscribe(function (e) {
            _this.initResize(e, _this.types);
        });
        merge(fromEvent(document, 'keydown').pipe(filter(function (e) { return e.keyCode == KeyboardEventKeyCode.Alt; }), map(function () { return true; })), fromEvent(document, 'keyup').pipe(filter(function (e) { return e.keyCode == KeyboardEventKeyCode.Alt; }), map(function () { return false; })))
            .pipe(untilDestroyed(this))
            .subscribe(function (pressed) { return (_this.altPressed = pressed); });
    };
    Object.defineProperty(ResizableHandleDirective.prototype, "parent", {
        get: function () {
            return this.manualParent || this.autoParent;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ResizableHandleDirective.prototype, "resizableElement", {
        get: function () {
            return this.parent ? this.parent.el.nativeElement : undefined;
        },
        enumerable: true,
        configurable: true
    });
    ResizableHandleDirective.prototype.initResize = function (downEvent, types) {
        var _this = this;
        preventDragMouseDownEvent(downEvent);
        var subscriptions = [];
        var originalWidth = this.resizableElement.offsetWidth;
        var originalHeight = this.resizableElement.offsetHeight;
        var newWidth = null;
        var newHeight = null;
        this.resizing = true;
        this.parent.resizeStarted.next({
            mouseDownEvent: downEvent
        });
        addClass(document.body, RESIZABLE_RESIZING_CLASS);
        if (this.types.includes(ResizeType.Horizontal)) {
            addClass(document.body, this.reverseHorizontal ? RESIZABLE_RESIZING_HORIZONTAL_REVERSE_CLASS : RESIZABLE_RESIZING_HORIZONTAL_CLASS);
        }
        if (this.types.includes(ResizeType.Vertical)) {
            addClass(document.body, this.reverseVertical ? RESIZABLE_RESIZING_VERTICAL_REVERSE_CLASS : RESIZABLE_RESIZING_VERTICAL_CLASS);
        }
        if (isSet(this.bodyClass)) {
            addClass(document.body, this.bodyClass);
        }
        subscriptions.push(fromEvent(window.document, 'mousemove')
            .pipe(untilDestroyed(this))
            .subscribe(function (moveEvent) {
            moveEvent.preventDefault();
            moveEvent.stopPropagation();
            var attrs = {};
            var widthDelta;
            var heightDelta;
            if (types.includes(ResizeType.Horizontal)) {
                var speed = _this.speedHorizontal;
                var reverse = _this.reverseHorizontal ? -1 : 1;
                widthDelta = (moveEvent.clientX - downEvent.clientX) * speed * reverse;
                var width = _this.clamp(_this.stepRound(originalWidth + widthDelta, !_this.altPressed ? _this.stepHorizontal : undefined), _this.minSize.width, _this.maxSize.width);
                newWidth =
                    _this.checkHorizontalBounds && width > _this.resizableElement.parentElement.offsetWidth ? undefined : width;
                attrs['width'] = newWidth === undefined ? '100%' : newWidth;
            }
            if (types.includes(ResizeType.Vertical)) {
                var speed = _this.speedVertical;
                var reverse = _this.reverseVertical ? -1 : 1;
                heightDelta = (moveEvent.clientY - downEvent.clientY) * speed * reverse;
                var height = _this.clamp(_this.stepRound(originalHeight + heightDelta, !_this.altPressed ? _this.stepVertical : undefined), _this.minSize.height, _this.maxSize.height);
                newHeight =
                    _this.checkVerticalBounds && height > _this.resizableElement.parentElement.offsetHeight
                        ? undefined
                        : height;
                attrs['height'] = newHeight === undefined ? 'auto' : newHeight;
            }
            if (_this.parent && _this.parent.resizeElement) {
                TweenMax.set(_this.resizableElement, attrs);
            }
            _this.parent.resize.next({
                types: types,
                widthDelta: widthDelta,
                heightDelta: heightDelta
            });
        }));
        subscriptions.push(fromEvent(window.document, 'mouseup')
            .pipe(filter(function (e) { return e.button == MouseButton.Main; }), untilDestroyed(this))
            .subscribe(function (upEvent) {
            upEvent.preventDefault();
            subscriptions.forEach(function (item) { return item.unsubscribe(); });
            removeClass(document.body, RESIZABLE_RESIZING_CLASS);
            removeClass(document.body, RESIZABLE_RESIZING_HORIZONTAL_CLASS);
            removeClass(document.body, RESIZABLE_RESIZING_HORIZONTAL_REVERSE_CLASS);
            removeClass(document.body, RESIZABLE_RESIZING_VERTICAL_CLASS);
            removeClass(document.body, RESIZABLE_RESIZING_VERTICAL_REVERSE_CLASS);
            if (isSet(_this.bodyClass)) {
                removeClass(document.body, _this.bodyClass);
            }
            var widthChanged = newWidth !== null;
            var heightChanged = newHeight !== null;
            var event = {
                mouseUpEvent: upEvent,
                types: types,
                widthChanged: widthChanged,
                heightChanged: heightChanged
            };
            if (types.includes(ResizeType.Horizontal) && widthChanged) {
                event['width'] = newWidth;
            }
            if (types.includes(ResizeType.Vertical) && heightChanged) {
                event['height'] = newHeight;
            }
            _this.parent.resizeFinished.next(event);
            _this.resizing = false;
        }));
    };
    ResizableHandleDirective.prototype.clamp = function (num, min, max) {
        if (typeof num !== 'number') {
            return num;
        }
        if (typeof min === 'number') {
            num = Math.max(num, min);
        }
        if (typeof max === 'number') {
            num = Math.min(num, max);
        }
        return num;
    };
    ResizableHandleDirective.prototype.stepRound = function (num, step) {
        if (typeof num !== 'number') {
            return num;
        }
        if (typeof step === 'number') {
            var delta = num % step;
            num -= delta;
            if (delta / step >= 0.5) {
                num += step;
            }
        }
        return num;
    };
    return ResizableHandleDirective;
}());
export { ResizableHandleDirective };
