<div class="list" [class.list_scrollable]="scrollable">
  <div class="list__header list__header_active inactive" #header_element>
    <div class="list-header">
      <div class="list-header__row">
        <div class="list-header__main">
          <h1 class="list__title">
            <span [class.loading-animation]="animating && !title"
              ><span [class.stub-text]="!title">
                <ng-container *ngIf="title">{{ title }}</ng-container>
                <ng-container *ngIf="!title">Model Description</ng-container>
              </span></span
            >
          </h1>
        </div>
        <div class="list-header__right list__actions">
          <input
            class="input input_stub input_toolbar input_background input_icon-left"
            [class.loading-animation]="animating"
            type="text"
            value="Search model description..."
          />
          <span
            class="button list__actions-item"
            [class.button_orange]="orange"
            [appButtonTintColor]="'accentColor' | appThemeColorHexOption | async"
            [appButtonTintColorStyle]="tintStyles.Default"
          >
            <span class="button__label">
              <span [class.loading-animation]="animating"><span class="stub-text">Filter</span></span>
            </span>
          </span>
          <span
            class="button button_primary list__actions-item"
            [class.button_orange]="orange"
            [appButtonTintColor]="'accentColor' | appThemeColorHexOption | async"
            [appButtonTintColorStyle]="tintStyles.Primary"
          >
            <span class="button__label">
              <span [class.loading-animation]="animating"><span class="stub-text stub-text_button">Create</span></span>
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="list__content list__content_border list__viewport list__viewport_scrollable">
    <app-table-stub-content
      [action]="action"
      [selection]="selection"
      [animating]="animating"
      [scrollable]="scrollable"
    ></app-table-stub-content>
  </div>
</div>
