import { Injectable } from '@angular/core';

import { ActionDescription, ActionType, LinkAction, OpenPopupAction, SegueType } from '@modules/actions';
import {
  ListDefaultSelection,
  ListElementItem,
  TableSettings,
  TintStyle,
  ViewSettingsAction
} from '@modules/customize';
import { Input, InputValueType } from '@modules/fields';
import { ITEM_OUTPUT } from '@modules/list';
import { ModelDescription } from '@modules/models';
import { Resource } from '@modules/projects';
import { Template } from '@modules/template';
import { capitalize, isSet } from '@shared';

import { GeneratorUtils } from '../generator-utils/generator-utils.service';

import { ViewSettingsGeneratorService } from '../view-settings-generator/view-settings-generator.service';

@Injectable()
export class ListGenerator {
  constructor(
    private viewSettingsGeneratorService: ViewSettingsGeneratorService,
    private generatorUtils: GeneratorUtils
  ) {}

  getTableElement(
    resource: Resource,
    modelDescription: ModelDescription,
    uniqueName: string,
    options: {
      templates: Template[];
      fields?: string[];
      createUniqueName?: string;
      detailUniqueName?: string;
      detailPopup?: string;
      sortingField?: string;
      sortingAsc?: boolean;
      perPage?: number;
      defaultSelection?: ListDefaultSelection;
      uid?: string;
    }
  ): ListElementItem {
    const list = new ListElementItem();

    if (options.uid) {
      list.uid = options.uid;
    } else {
      list.generateUid();
    }

    let table = new TableSettings();
    const layoutSettings = this.viewSettingsGeneratorService.layoutSettings.find(item => item.layout == table.type);

    table = this.viewSettingsGeneratorService.applyModelListLayoutDefaults(
      table,
      modelDescription,
      layoutSettings
    ) as TableSettings;
    table = this.viewSettingsGeneratorService.addModelListLayoutDefaultActions(
      table,
      resource,
      modelDescription,
      layoutSettings
    ) as TableSettings;

    if (options.fields) {
      table.dataSource.columns = table.dataSource.columns
        .map(item => {
          item.visible = options.fields.includes(item.name);
          return item;
        })
        .sort((lhs, rhs) => this.generatorUtils.fieldsSort(options.fields, lhs.name, rhs.name));
    }

    if (isSet(options.sortingField)) {
      table.sortingField = options.sortingField;
    }

    if (isSet(options.sortingAsc)) {
      table.sortingAsc = options.sortingAsc;
    }

    if (isSet(options.perPage)) {
      table.perPage = options.perPage;
    }

    if (options.defaultSelection) {
      table.defaultSelection = options.defaultSelection;
    }

    if (options.detailUniqueName) {
      const clickLinkAction = new LinkAction();

      clickLinkAction.type = SegueType.Page;
      clickLinkAction.page = options.detailUniqueName;

      const clickActionDescription = new ActionDescription();

      clickActionDescription.type = ActionType.Link;
      clickActionDescription.linkAction = clickLinkAction;

      const input = new Input();

      input.name = 'ID';
      input.valueType = InputValueType.Context;
      input.contextValue = [ITEM_OUTPUT, modelDescription.primaryKeyField];

      const clickAction = new ViewSettingsAction();

      clickAction.verboseNameInput = new Input().deserializeFromStatic('value', 'Open Record');
      clickAction.actionDescription = clickActionDescription;
      clickAction.inputs = [input];

      table.rowClickAction = clickAction;
    } else if (options.detailPopup) {
      const openPopupAction = new OpenPopupAction();

      openPopupAction.popup = options.detailPopup;

      // clickLinkAction.type = SegueType.Page;
      // clickLinkAction.page = options.detailUniqueName;

      const clickActionDescription = new ActionDescription();

      clickActionDescription.type = ActionType.OpenPopup;
      clickActionDescription.openPopupAction = openPopupAction;

      // const input = new Input();
      //
      // input.name = 'ID';
      // input.valueType = InputValueType.Context;
      // input.contextValue = [ITEM_OUTPUT, modelDescription.primaryKeyField];

      const clickAction = new ViewSettingsAction();

      clickAction.verboseNameInput = new Input().deserializeFromStatic('value', 'Open Record');
      clickAction.actionDescription = clickActionDescription;
      // clickAction.inputs = [input];

      table.rowClickAction = clickAction;
    }

    if (options.createUniqueName) {
      const createLinkAction = new LinkAction();

      createLinkAction.type = SegueType.Page;
      createLinkAction.page = options.createUniqueName;

      const createActionDescription = new ActionDescription();

      createActionDescription.type = ActionType.Link;
      createActionDescription.linkAction = createLinkAction;

      const createAction = new ViewSettingsAction();

      createAction.verboseNameInput = new Input().deserializeFromStatic(
        'value',
        `Add ${capitalize(modelDescription.verboseName)}`
      );
      createAction.actionDescription = createActionDescription;
      createAction.icon = 'plus';
      createAction.pinned = true;
      createAction.style = TintStyle.Primary;
      createAction.tint = 'bright-blue';

      table.actions = [createAction, ...table.actions];
    }

    const deleteActionId = [modelDescription.resource, modelDescription.autoActionUniqueName('delete')].join('.');
    const deleteAction = table.modelActions.find(item => item.sharedActionDescription == deleteActionId);

    if (deleteAction) {
      const successActionDescription = new ActionDescription();
      const elementAction = ['elements', list.uid, '0', 'update_data'];

      successActionDescription.type = ActionType.ElementAction;
      successActionDescription.elementAction = elementAction;

      const successAction = new ViewSettingsAction();

      successAction.verboseNameInput = new Input().deserializeFromStatic('value', 'Update Data');
      successAction.actionDescription = successActionDescription;

      deleteAction.onSuccessActions = [successAction];
    }

    list.layouts = [table];

    return list;
  }
}
